import React, { useState, useEffect } from "react";
import { Card, Col, Row, Dropdown, ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { BookOpen, ChevronRight, MoreHorizontal } from "lucide-react";
import { fetchWithAuth } from "../../../api-client/APIClient";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import DottedOutlineTextCard from "../../shared-components/DottedOutlineTextCard";
import { Webhook } from "../../../models/Entities";
import { showSuccessToast } from "../../shared-components/Toasts";
import { SectionHeaderWrapper } from "../../shared-components/MergeLayouts";
import MergeModal from "../../shared-components/MergeModal";
import {
  DOCS_WEBHOOKS_MERGE_TO_YOU_PATH,
  DOCS_WEBHOOKS_SECURITY_PATH,
  INITIAL_AND_ANY_SYNC_GUIDE,
  navigateToAddWebhookPage,
  navigateToEditIndividualWebhookPage,
  openInNewTab,
} from "../../../router/RouterUtils";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import { NewWebhooksDisabledNoticeCard } from "./WebhooksRestrictionsNotices";
import { Alert, Button, SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { HookEvent } from "./ConfigurationAddEditWebhooksPage/enums";
import WebhookRow from "./ConfigurationAddEditWebhooksPage/components/WebhookRow";

const ConfigurationWebhookEmittersPage = () => {
  const MAX_SHOW_COLLAPSED_WEBHOOKS_COUNT = 5;
  const history = useHistory();
  const [webhooks, setWebhooks] = useState<Array<Webhook>>();
  const [webhookKey, setWebhookKey] = useState<string>("Loading...");
  const [isShowingAllWebhooks, setIsShowingAllWebhooks] = useState<boolean>(false);
  const [isSignatureBeingRegenerated, setIsSignatureBeingRegenerated] = useState<boolean>(false);
  const [isSignatureBeingRegeneratedLoading, setIsSignatureBeingRegeneratedLoading] =
    useState<boolean>(false);

  const { productRestrictions, orgBillingPlan } = useProductRestrictions();

  const hasWebhooks = webhooks !== undefined && webhooks.length > 0;

  const onlyNewWebhooksAreDisabled =
    !productRestrictions?.webhooks_enabled &&
    hasWebhooks &&
    webhooks.find((webhook) => webhook.event !== HookEvent.ACCOUNT_SYNCED_INITIAL_HOOK);

  useEffect(() => {
    fetchWebhooks();
    fetchWebhookKey();
  }, []);

  const fetchWebhooks = () => {
    fetchWithAuth({
      path: "/integrations/webhooks/targets",
      method: "GET",
      onResponse: (data) => {
        setWebhooks(data);
      },
    });
  };

  const fetchWebhookKey = () => {
    fetchWithAuth({
      path: "/integrations/configuration/webhooks",
      method: "GET",
      onResponse: (data) => {
        setWebhookKey(data.webhook_key);
      },
    });
  };

  const regenerateWebhookKey = () => {
    setIsSignatureBeingRegeneratedLoading(true);
    fetchWithAuth({
      path: "/integrations/configuration/webhooks",
      method: "PATCH",
      onResponse: (data) => {
        setWebhookKey(data.webhook_key);
        setIsSignatureBeingRegenerated(false);
        setIsSignatureBeingRegeneratedLoading(false);
        showSuccessToast("Successfully regenerated webhook key!");
      },
    });
  };

  return (
    <>
      <MergeModal
        show={isSignatureBeingRegenerated}
        title="Regenerate Signature"
        centered
        bodyClassName="modal-extra-narrow overflow-hidden"
        dialogClassName="modal-extra-narrow"
        onHide={() => setIsSignatureBeingRegenerated(false)}
      >
        <span className="text-center text-base">
          This will replace previous signatures and you will need to update any existing
          webhook-related request verification in your API.
        </span>
        <div className="flex w-full flex-row mt-4">
          <Button
            fullWidth={true}
            color="white"
            size="md"
            className="mr-2"
            onClick={() => setIsSignatureBeingRegenerated(false)}
          >
            Cancel
          </Button>
          <Button
            color="red"
            size="md"
            fullWidth={true}
            isLoading={isSignatureBeingRegeneratedLoading}
            onClick={regenerateWebhookKey}
          >
            Regenerate
          </Button>
        </div>
      </MergeModal>

      {orgBillingPlan && onlyNewWebhooksAreDisabled && (
        <div className="flex flex-row">
          <div className="flex flex-col">
            <NewWebhooksDisabledNoticeCard orgBillingPlan={orgBillingPlan} />
          </div>
        </div>
      )}
      <div className="flex flex-row min-w-0">
        <div className="flex flex-col min-w-0">
          <SectionHeaderWrapper
            title="Merge webhooks"
            subtitle="Trigger POST requests to a specified URL when you want to be notified of a change in your integrations."
            headerRightHandContent={
              <SmallWhiteButton
                onClick={() => openInNewTab(DOCS_WEBHOOKS_MERGE_TO_YOU_PATH)}
                leftIcon={<BookOpen size={12} />}
              >
                Webhook Docs
              </SmallWhiteButton>
            }
          >
            <Alert className="mb-6 mt-6" color="yellow" showWarningIcon>
              <div>
                We will be deprecating our <b>initial sync</b> and{" "}
                <b>any sync notification webhooks</b> on <b>April 1, 2024</b>. We highly recommend
                using a combination of our new <b>account linked</b> and{" "}
                <b>Common Model synced webhooks</b>.{" "}
                <a href={INITIAL_AND_ANY_SYNC_GUIDE} target="_blank">
                  Learn more
                </a>
              </div>
            </Alert>
          </SectionHeaderWrapper>

          <div className="w-full flex flex-col bg-white shadow-md rounded-[10px] mb-8 min-w-0">
            <div className="flex flex-row px-6 py-5 items-center justify-between border-b border-gray-10 min-w-0">
              <h4 className="">Webhooks</h4>
              <Button
                color="blue"
                size="sm"
                variant="plus"
                onClick={() => navigateToAddWebhookPage(history)}
              >
                Webhook
              </Button>
            </div>
            {webhooks && webhooks.length === 0 ? (
              <EmptyStateWrapper title="No webhooks" />
            ) : !webhooks ? (
              Array(5)
                .fill(null)
                .map((_, index) => (
                  <WebhookRow isLoading={true} key={index} isLastWebhook={index === 4} />
                ))
            ) : (
              webhooks
                .slice(
                  0,
                  isShowingAllWebhooks ? webhooks.length : MAX_SHOW_COLLAPSED_WEBHOOKS_COUNT,
                )
                .map((webhook: Webhook, index) => {
                  const isLastWebhook = webhooks.length < 5 && webhooks.length === index + 1;
                  return (
                    <WebhookRow
                      isLastWebhook={isLastWebhook}
                      isLoading={false}
                      webhook={webhook}
                      key={webhook.id}
                      onClick={() => navigateToEditIndividualWebhookPage(history, webhook.id)}
                    />
                  );
                })
            )}
            {webhooks && webhooks.length > 5 && (
              <div
                onClick={() => setIsShowingAllWebhooks(!isShowingAllWebhooks)}
                className="flex flex-row items-center font-semibold justify-center text-blue-40 hover:text-blue-60 px-6 py-4 cursor-pointer hover:bg-[#f9fbfd] rounded-b-[10px]"
              >
                {isShowingAllWebhooks ? (
                  <>View less</>
                ) : (
                  <>View more ({webhooks.length - MAX_SHOW_COLLAPSED_WEBHOOKS_COUNT})</>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-row mb-10">
        <div className="w-full flex flex-col bg-white shadow-md rounded-[10px]">
          <div className="flex flex-row px-6 py-5 items-center justify-between border-b border-gray-10">
            <h4>Security Signature</h4>
            <a
              className="text-sm flex flex-row items-center justify-center flex-nowrap"
              href={DOCS_WEBHOOKS_SECURITY_PATH}
              target="_blank"
            >
              Learn about webhook security <ChevronRight className="ml-1" size={10} />
            </a>
          </div>
          <div className="flex flex-col px-6 py-5 text-left">
            <div className="text base mb-4">
              <b>Important:</b> Your API should verify incoming POST requests with this signature
              for authenticity.
            </div>
            <DottedOutlineTextCard
              className="text-gray-50 bg-gray-0"
              text={webhookKey}
              backgroundColor={{ r: 242, g: 244, b: 247 }}
              iconSize={16}
              isOutlined={false}
              onClickRegenerate={() => setIsSignatureBeingRegenerated(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfigurationWebhookEmittersPage;
