import React, { useEffect, useRef, useState } from "react";
import omit from "lodash/omit";
import { Typeahead, TypeaheadModel, TypeaheadProps } from "react-bootstrap-typeahead";
import styled from "styled-components";
import useOnClickOutside from "use-onclickoutside";

/**
 * Force the placeholder text to match the gray placeholder text color from the ".form-control" class.
 */
const FORM_CONTROL_GRAY = "#B0B7C3";

const PositionRelative = styled.div`
  position: relative;
`;

interface StyledTypeaheadProps<T extends TypeaheadModel> extends TypeaheadProps<T> {
  includeChevronDown: boolean;
  includeSearchIcon: boolean;
  isDarkBar: boolean;
  removeInput: boolean;
  hasInput: boolean;
  disabled?: boolean;
}

const StyledTypeahead = styled((props: StyledTypeaheadProps<any>) => (
  <Typeahead {...omit(props, "$includeChevronDown")} />
))`
  input::placeholder {
    color: ${({ isDarkBar }) =>
      isDarkBar ? "var(--gray60, #737982);" : `${FORM_CONTROL_GRAY} !important;`}
    font-size: 14px;
    line-height: 24px;
  }

  .rbt-token {
    display: ${({ removeInput }) => (removeInput ? "none" : "inline-block")} !important;
  }

  input {
    font-size: 14px;
    line-height: 24px;
  }

  .dropdown-item:hover {
    background: var(--lm-gray-0, #f2f4f7) !important;
  }
  .rbt-input-multi.form-control[disabled] {
background-color: #FFFFFF !important;
opacity: 0.35 !important;
cursor: pointer !important;
  }
  
  .form-control {
    border: 0px;
    background-color: ${({ isDarkBar }) => (isDarkBar ? "var(--gray0, #F2F4F7);" : "#ffffff;")}
      ${({ isDarkBar }) =>
        !isDarkBar
          ? "box-shadow: 0px 1.5px 4px -1px rgba(0, 0, 0, 0.16), 0px 0px 0px 0.5px rgba(220, 226, 234, 0.20);"
          : ""}

      ${({ includeSearchIcon }) => (includeSearchIcon ? "padding-left: 40px !important;" : "")};
  }

  .rbt-input-wrapper {
    position: relative;  // Add this to ensure the pseudo-element is positioned inside

  @media (min-width: 476px) {
    ${({ removeInput, placeholder, hasInput }) =>
      removeInput && !hasInput
        ? `
        &::before {
          content: "${placeholder || "Search..."}";  
          position: absolute;
          left: 0;
          top: 45%;
          transform: translateY(-50%);
          color: ${FORM_CONTROL_GRAY};
          font-size: 14px;
          line-height: 24px;
          pointer-events: none;  // Ensure it doesn't interfere with user interactions
        }

      `
        : ""}
      }

    // Ensure the chevron does not overlap the contents.
    ${({ includeChevronDown }) => (includeChevronDown ? "padding-right: 30px !important;" : "")}
  }
`;

const ChevronDown = styled(
  ({
    className,
    onClick,
    disabled,
  }: {
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
  }) => <span onClick={onClick} className={`fe fe-chevron-down ${className}`} />,
)`
  position: absolute;
  font-size: 12px;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #000000;
  opacity: ${({ disabled }) => (disabled ? "0.35" : "1")};
`;

const SearchIcon = styled(({ className }: { className?: string }) => (
  <span className={`fe fe-search ${className}`} />
))`
  position: absolute;
  font-size: 12px;
  z-index: 1;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
  color: #000000;
`;

interface Props<T extends TypeaheadModel> extends TypeaheadProps<T> {
  includeChevronDown?: boolean;
  includeSearchIcon?: boolean;
  isDarkBar?: boolean;
  removeInput?: boolean;
  /**
   * Error message to display under the Typeahead.
   */
  error?: string;
}

const MergeTypeahead = <T extends TypeaheadModel>({
  onChange,
  options,
  id,
  disabled,
  multiple,
  selected,
  autoFocus,
  positionFixed,
  labelKey,
  inputProps,
  removeInput = false,
  placeholder,
  isLoading,
  onMenuToggle,
  renderMenu,
  renderMenuItemChildren,
  includeChevronDown,
  includeSearchIcon = false,
  isDarkBar = false,
  error,
}: Props<T>) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [hasInput, setHasInput] = useState(false);

  const handleChevronClick = () => {
    if (disabled) {
      return;
    }
    setMenuOpen((prevState) => !prevState);
  };

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  return (
    <>
      <PositionRelative onClick={handleChevronClick} ref={ref}>
        {includeSearchIcon !== false && <SearchIcon />}
        <StyledTypeahead
          hasInput={hasInput}
          onChange={onChange}
          options={options}
          removeInput={removeInput}
          onInputChange={(text) => {
            setHasInput(!!text);
          }}
          id={id || "typeahead"}
          // Default `multiple` to true.
          disabled={disabled}
          multiple={multiple === undefined ? true : multiple}
          selected={selected}
          autoFocus={autoFocus}
          positionFixed={positionFixed}
          onMenuToggle={onMenuToggle}
          labelKey={labelKey}
          inputProps={{
            className: "merge-typeahead-input",
            ...(inputProps || { autoComplete: "none" }),
          }}
          placeholder={placeholder || "Search..."}
          isLoading={isLoading}
          renderMenu={renderMenu}
          renderMenuItemChildren={renderMenuItemChildren}
          includeChevronDown={includeChevronDown !== false}
          includeSearchIcon={includeSearchIcon}
          isDarkBar={isDarkBar}
          open={menuOpen}
        />
        {includeChevronDown !== false && <ChevronDown disabled={disabled} />}
      </PositionRelative>
      {error && <div className="invalid-feedback forced-feedback">{error}</div>}
    </>
  );
};

export default MergeTypeahead;
