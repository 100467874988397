import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import { palette, spectrum } from "../../styles/theme";

type LoadingProps = {
  className?: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  type?: "button" | "submit" | "reset";
};
function LoadingButton(props: LoadingProps) {
  return (
    <button
      type={props.type ? props.type : "submit"}
      className={classNames("btn", props.className)}
      disabled
      onClick={props.onClick}
    >
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
      <span className="deprecated-ml-2">{props.text}</span>
    </button>
  );
}

type NormalProps = {
  className?: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  id?: string;
};

function NormalButton(props: NormalProps) {
  return (
    <button
      className={classNames("btn", props.className)}
      type={props.type ? props.type : "submit"}
      onClick={props.onClick}
      disabled={props.disabled}
      id={props.id}
    >
      {props.text}
    </button>
  );
}

type SpinnerProps = {
  isLoading: boolean;
  className?: string;
  onClick?: (e?: any) => void;
  text: JSX.Element | string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
  id?: string;
};

const SpinnerButton = (props: SpinnerProps) =>
  props.isLoading ? <LoadingButton {...props} /> : <NormalButton {...props} />;

export const BlackSpinnerButton = styled(SpinnerButton)`
  color: #fff;
  background-color: ${palette.black};
  border-color: ${palette.black};

  :hover {
    color: #fff;
    background-color: ${spectrum.gray80};
    border-color: ${spectrum.gray70};
  }
  :focus {
    color: #fff;
    background-color: ${spectrum.gray80};
    border-color: ${spectrum.gray70};
    box-shadow: 0 0 0 0.15rem rgba(54, 71, 92, 0.5);
  }
  :disabled {
    color: #fff;
    background-color: ${palette.black};
    border-color: ${palette.black};
  }
  :not(:disabled):not(.disabled).active,
  :not(:disabled):not(.disabled):active,
  .show > &.dropdown-toggle {
    color: #fff;
    background-color: ${spectrum.gray70};
    border-color: ${spectrum.gray80};
  }
  :not(:disabled):not(.disabled).active:focus,
  :not(:disabled):not(.disabled):active:focus,
  .show > &.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.15rem rgba(54, 71, 92, 0.5);
  }
`;

export default SpinnerButton;
