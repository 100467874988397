import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CommonModelOverrideForm from "./CommonModelOverrideForm";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingMetaResponse,
  LinkedAccount,
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
} from "../../../../../../models/Entities";
import { Loader } from "react-bootstrap-typeahead";
import useCategoryComponentSchema from "../../../../../../hooks/useCategoryComponentSchema";
import CommonModelOverrideInstanceTargetCard from "./CommonModelOverrideInstance";
import MergeToIntegrationMappingBar from "../MergeToIntegrationMappingBar";
import { Plus } from "lucide-react";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../../router/RouterUtils";
const CommonModelOverrideContainer = styled.div`
  box-shadow:
    0px 3px 12px -3px rgba(0, 0, 0, 0.12),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
`;

const getCommonModelsWithRemoteData = (
  commonModelOverrideConfigs: Array<FieldMappingCommonModelConfiguration>,
) => {
  const commonModelsWithRemoteDataEnabled: Set<string> = new Set();
  commonModelOverrideConfigs.forEach((commonModelConfig: FieldMappingCommonModelConfiguration) => {
    if (commonModelConfig.has_remote_data_enabled) {
      commonModelsWithRemoteDataEnabled.add(commonModelConfig.common_model_name);
    }
  });
  return commonModelsWithRemoteDataEnabled;
};

const filterForTargetsWithRemoteDataEnabled = (
  overriddenCommonModelTargets: Array<OverriddenCommonModelInstance | OverriddenCommonModelTarget>,
  commonModelsWithRemoteDataEnabled: Set<string>,
) => {
  return overriddenCommonModelTargets.filter(
    (overriddenCommonModelTarget: OverriddenCommonModelInstance | OverriddenCommonModelTarget) => {
      return commonModelsWithRemoteDataEnabled.has(overriddenCommonModelTarget.common_model_name);
    },
  );
};

type CommonModelOverridesSectionProps = {
  commonModelOverrideConfigs: Array<FieldMappingCommonModelConfiguration>;
  hasFieldMappingsLoaded: boolean;
  customMappingMetaResponse: FieldMappingMetaResponse | undefined;
  linkedAccount: LinkedAccount;
  overriddenCommonModelTargets: Array<OverriddenCommonModelTarget>;
  overriddenCommonModelInstances: Array<OverriddenCommonModelInstance>;
};
const CommonModelOverridesSection = ({
  commonModelOverrideConfigs,
  hasFieldMappingsLoaded,
  customMappingMetaResponse,
  linkedAccount,
  overriddenCommonModelTargets,
  overriddenCommonModelInstances,
}: CommonModelOverridesSectionProps) => {
  const [isCommonModelOverrideFormOpen, setIsCommonModelOverrideFormOpen] =
    useState<boolean>(false);
  const { schemas } = useCategoryComponentSchema(linkedAccount.category);
  const [isCustomMappingMetaResponseLoading, setIsCustomMappingMetaResponseLoading] =
    useState<boolean>(true);

  const [commonModelOverrides, setCommonModelOverrides] = useState<OverriddenCommonModelInstance[]>(
    overriddenCommonModelInstances,
  );

  const [commonModelOverrideTargets, setCommonModelOverrideTargets] = useState<
    Array<OverriddenCommonModelInstance | OverriddenCommonModelTarget>
  >(overriddenCommonModelTargets);

  useEffect(() => {
    setCommonModelOverrides(overriddenCommonModelInstances);
  }, [overriddenCommonModelInstances]);

  useEffect(() => {
    setCommonModelOverrideTargets(overriddenCommonModelTargets);
  }, [overriddenCommonModelTargets]);

  useEffect(() => {
    setIsCustomMappingMetaResponseLoading(
      customMappingMetaResponse == undefined || Object.keys(schemas).length == 0,
    );
  }, [customMappingMetaResponse, schemas]);

  const commonModelsWithRemoteDataEnabled: Set<string> = commonModelOverrideConfigs
    ? getCommonModelsWithRemoteData(commonModelOverrideConfigs)
    : new Set();

  const avaialbleModelAndFieldOverrides = isCustomMappingMetaResponseLoading
    ? undefined
    : customMappingMetaResponse?.available_model_and_field_overrides;

  const filteredTargets = filterForTargetsWithRemoteDataEnabled(
    commonModelOverrideTargets,
    commonModelsWithRemoteDataEnabled,
  );

  const doOverridesExist = filteredTargets.length > 0 || commonModelOverrides.length > 0;
  return (
    <div className="d-flex flex-column mt-4">
      <div className="h-2 rounded-t-lg bg-blue-10" />
      <CommonModelOverrideContainer className="rounded-b-xl">
        <div className="p-6 pt-3 pb-5 border-b border-gray-10">
          <h4 className="title-sm">Common Model overrides</h4>
        </div>
        <div className="">
          {hasFieldMappingsLoaded ? (
            <>
              {doOverridesExist && (
                <>
                  <div className="mb-4 pl-6 pr-6 pt-5">
                    <MergeToIntegrationMappingBar
                      integrationName={linkedAccount.integration.name}
                      integrationSquareImage={linkedAccount.integration.square_image}
                    />
                  </div>
                  {commonModelOverrides.map(
                    (modelOverrideInstance: OverriddenCommonModelInstance) => {
                      return (
                        <CommonModelOverrideInstanceTargetCard
                          linkedAccountID={linkedAccount.id}
                          setCommonModelOverrides={setCommonModelOverrides}
                          setCommonModelOverrideTargets={setCommonModelOverrideTargets}
                          modelOverrideID={modelOverrideInstance.id}
                          key={modelOverrideInstance.id}
                          targetID={modelOverrideInstance?.override_common_model_target?.id || null}
                          overridden_field={modelOverrideInstance.overridden_field}
                          overridden_field_definition={
                            modelOverrideInstance?.overridden_field_definition || null
                          }
                          common_model_name={modelOverrideInstance.common_model_name}
                          field_traversal_path={modelOverrideInstance?.field_traversal_path || null}
                          remote_endpoint_path={modelOverrideInstance?.remote_endpoint_path || null}
                          is_integration_wide={modelOverrideInstance?.is_integration_wide || null}
                          customMappingMetaResponse={customMappingMetaResponse}
                          display_name={modelOverrideInstance?.display_name}
                          origin_type={modelOverrideInstance?.origin_type}
                          integration_name={linkedAccount.integration.name}
                          is_integration_wide_override_mapping={
                            modelOverrideInstance?.is_integration_wide_override_mapping || null
                          }
                        />
                      );
                    },
                  )}
                  {filteredTargets.map((overrideTarget) => {
                    return (
                      <CommonModelOverrideInstanceTargetCard
                        linkedAccountID={linkedAccount.id}
                        setCommonModelOverrides={setCommonModelOverrides}
                        setCommonModelOverrideTargets={setCommonModelOverrideTargets}
                        modelOverrideID={overrideTarget.id}
                        targetID={
                          "override_common_model_target" in overrideTarget
                            ? overrideTarget.override_common_model_target?.id
                            : null
                        }
                        key={overrideTarget.id}
                        overridden_field={overrideTarget.overridden_field}
                        overridden_field_definition={
                          overrideTarget?.overridden_field_definition || null
                        }
                        common_model_name={overrideTarget.common_model_name}
                        field_traversal_path={
                          "field_traversal_path" in overrideTarget
                            ? overrideTarget?.field_traversal_path
                            : null
                        }
                        remote_endpoint_path={
                          "remote_endpoint_path" in overrideTarget
                            ? overrideTarget.remote_endpoint_path
                            : null
                        }
                        is_integration_wide={
                          "is_integration_wide" in overrideTarget
                            ? overrideTarget.is_integration_wide
                            : null
                        }
                        customMappingMetaResponse={customMappingMetaResponse}
                        display_name={
                          "display_name" in overrideTarget ? overrideTarget.display_name : null
                        }
                        origin_type={
                          "origin_type" in overrideTarget ? overrideTarget.origin_type : null
                        }
                        integration_name={linkedAccount.integration.name}
                        is_integration_wide_override_mapping={
                          "is_integration_wide_override_mapping" in overrideTarget
                            ? overrideTarget.is_integration_wide_override_mapping
                            : null
                        }
                      />
                    );
                  })}
                </>
              )}
              <div className={`pl-6 pb-6 ${doOverridesExist ? "" : "mt-5"}`}>
                {commonModelsWithRemoteDataEnabled.size > 0 ? (
                  <>
                    {isCommonModelOverrideFormOpen && avaialbleModelAndFieldOverrides && (
                      <CommonModelOverrideForm
                        setIsCommonModelOverrideFormOpen={setIsCommonModelOverrideFormOpen}
                        linkedAccountID={linkedAccount.id}
                        endUserName={linkedAccount.end_user.organization_name}
                        customMappingOptions={customMappingMetaResponse}
                        schemas={schemas!}
                        avaialbleModelAndFieldOverrides={avaialbleModelAndFieldOverrides}
                        commonModelsWithRemoteDataEnabled={commonModelsWithRemoteDataEnabled}
                        setCommonModelOverrides={setCommonModelOverrides}
                        allOverrideTargets={[
                          ...commonModelOverrides,
                          ...commonModelOverrideTargets,
                        ]}
                      />
                    )}
                    <div className="pr-6">
                      <div
                        onClick={() => {
                          if (!isCustomMappingMetaResponseLoading) {
                            setIsCommonModelOverrideFormOpen(true);
                          }
                        }}
                        className={`p-2.5 rounded-md bg-gray-0 d-flex align-items-center justify-content-center text-gray-70 font-inter ${
                          isCustomMappingMetaResponseLoading
                            ? "opacity-50 cursor-not-allowed"
                            : "opacity-100 font-semibold hover:cursor-pointer"
                        }`}
                      >
                        {isCustomMappingMetaResponseLoading ? (
                          <>
                            <Loader bsSize="small" />
                            <div className="ml-2">Loading origin fields</div>
                          </>
                        ) : (
                          <>
                            <Plus size={16} />{" "}
                            <div className="ml-[3px]"> Common Model override</div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div>
                    <a href={`${CONFIGURATION_COMMON_MODELS_PATH}/${linkedAccount.category}`}>
                      Enable Remote Data
                    </a>{" "}
                    for the Common Models you want to override.
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="pt-5 pb-6 px-6 flex flex-row gap-x-[68px]">
              <div className="rounded bg-gray-10 w-full h-6" />{" "}
              <div className="rounded bg-gray-10 w-full h-6" />{" "}
            </div>
          )}
        </div>
      </CommonModelOverrideContainer>
    </div>
  );
};

export default CommonModelOverridesSection;
