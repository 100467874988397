import React, { useState } from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { LinkedAccount } from "../../../../models/Entities";
import { palette } from "../../../../styles/theme";
import { PricingWarningBanner } from "../../../shared-components/Banners";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared-components/MergeLayouts";
import { showSuccessToast } from "../../../shared-components/Toasts";
import LinkedAccountTestAccountConversionModal from "./LinkedAccountTestAccountConversionModal";
import Stack from "./Stack";
import { Button } from "@merge-api/merge-javascript-shared";

type Props = {
  linkedAccount: LinkedAccount;
  updateLinkedAccount: (account: LinkedAccount) => void;
  guardrailsEnforced: boolean;
};

const Subtitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: ${palette.graphite};
`;

const StyledPricingWarning = styled(PricingWarningBanner)`
  margin-top: 8px;
  margin-bottom: 32px;
`;

const LinkedAccountTypeToggleSwitch = ({
  linkedAccount,
  updateLinkedAccount,
  guardrailsEnforced,
}: Props) => {
  const [showConversionModal, setShowConversionModal] = useState(false);

  const convertToProduction = () =>
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}/update/test`,
      method: "PATCH",
      body: {
        is_test_account: false,
      },
      onResponse: () => {
        updateLinkedAccount(linkedAccount);
        showSuccessToast(`Converted to Production Linked Account!`);
      },
    });

  return (
    <Card
      className="deprecated-mb-4 deprecated-px-4 deprecated-pb-4"
      style={{ paddingTop: "16px" }}
    >
      <SectionHeaderWrapper
        title="Convert to Production Linked Account"
        titleHeading={TitleHeading.H2}
        style={{ marginBottom: "-8px" }}
      >
        <>
          <Subtitle>
            Production Linked Accounts count towards billing and sync regularly on an interval
            wheareas Test Linked Accounts allow for easier development but can only be synced
            manually through this dashboard.
          </Subtitle>
          <Subtitle className="deprecated-mt-3 deprecated-mb-4">
            Conversion is irreversible!
          </Subtitle>
        </>
      </SectionHeaderWrapper>
      {guardrailsEnforced && (
        <StyledPricingWarning>
          Please upgrade your plan to continue adding Production Linked Accounts.
        </StyledPricingWarning>
      )}
      <Stack>
        <Button
          disabled={guardrailsEnforced}
          color="danger"
          size="md"
          onClick={() => setShowConversionModal(true)}
        >
          Convert to Production Linked Account
        </Button>

        <LinkedAccountTestAccountConversionModal
          isModalOpen={showConversionModal}
          convertToProduction={convertToProduction}
          closeModal={() => setShowConversionModal(false)}
        />
      </Stack>
    </Card>
  );
};

export default LinkedAccountTypeToggleSwitch;
