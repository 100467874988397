import { Badge, SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { LinkedAccount } from "../../../../../../models/Entities";
import React from "react";
import { ArrowLeftRight, Library, MessageSquare } from "lucide-react";
import {
  navigateToAPITesterWithLinkedAccount,
  openInNewTab,
} from "../../../../../../router/RouterUtils";
import { useIntercom } from "react-use-intercom";
import SkeletonLoader from "../../../../../shared-components/SkeletonLoader";
import { LinkedAccountStatuses } from "../../../../../../constants";
import AccountTypeBadge from "../../AccountTypeBadge";
import { IntegrationIssue } from "../../../../IntegrationsManagementEntities";
import { useHistory } from "react-router-dom";

interface TitleContainerProps {
  linkedAccount: LinkedAccount | null;
  isOnOverviewPage: boolean | undefined;
  isOnIssuesPage: boolean;
  ongoingIntegrationIssue: IntegrationIssue | null;
}

const LinkedAccountTitleContainer: React.FC<TitleContainerProps> = ({
  linkedAccount,
  ongoingIntegrationIssue,
  isOnIssuesPage,
  isOnOverviewPage,
}) => {
  const { show } = useIntercom();
  const history = useHistory();
  const url = `https://help.merge.dev/en/?q=${linkedAccount?.integration?.name}`;
  const errorBadge =
    (isOnOverviewPage || isOnIssuesPage) && ongoingIntegrationIssue ? (
      <Badge
        color={
          isOnOverviewPage && ongoingIntegrationIssue.error_description == "Missing Permission"
            ? "amber"
            : "red"
        }
        size="lg"
      >
        {isOnOverviewPage ? ongoingIntegrationIssue.error_description : "Ongoing Issue"}
      </Badge>
    ) : undefined;

  const linkedAccountBadge =
    linkedAccount && linkedAccount.status == LinkedAccountStatuses.RELINK_NEEDED ? (
      <AccountTypeBadge
        className="leading-24 whitespace-nowrap"
        account={linkedAccount}
        isRelinkNeeded
        isFullText
      />
    ) : undefined;

  const rightNavLinksComponent = (
    <div className="shadow-sm flex flex-row w-fit h-fit rounded">
      <SmallWhiteButton
        className="rounded-r-none"
        onClick={() => navigateToAPITesterWithLinkedAccount(history, linkedAccount)}
        leftIcon={<ArrowLeftRight size={12} />}
        noShadow={true}
      >
        API Tester
      </SmallWhiteButton>
      <SmallWhiteButton
        className="border-l border-gray-10 rounded-l-none rounded-r-none"
        onClick={() => openInNewTab(url)}
        leftIcon={<Library size={12} />}
        noShadow={true}
      >
        Help Center
      </SmallWhiteButton>
      <SmallWhiteButton
        className="border-l border-gray-10 rounded-l-none"
        onClick={() => show()}
        leftIcon={<MessageSquare size={12} />}
        noShadow={true}
      >
        Support
      </SmallWhiteButton>
    </div>
  );

  return (
    <div className="flex items-center justify-between flex-wrap gap-y-4">
      <div className="flex items-center flex-grow d-flex gap-3 min-w-[250px] text-[28px]">
        {linkedAccount ? (
          <div className="text-truncate-flex flex-shrink-1">
            {linkedAccount?.end_user?.organization_name}
          </div>
        ) : (
          <SkeletonLoader width={160} height={24} borderRadius={4} />
        )}
        <ArrowLeftRight className="flex-shrink-0" />

        {linkedAccount ? (
          <div className="flex-shrink-0">{linkedAccount?.integration?.name}</div>
        ) : (
          <SkeletonLoader width={160} height={24} borderRadius={4} />
        )}
        {linkedAccountBadge || errorBadge}
      </div>
      {rightNavLinksComponent ?? undefined}
    </div>
  );
};

export default LinkedAccountTitleContainer;
