import * as Sentry from "@sentry/react";
import Cookies from "universal-cookie";
import { UserWithBillingPlan } from "../components/context/AppContext";
import { showErrorToast, showInfoToast } from "../components/shared-components/Toasts";
import { SEC_IN_YEAR, SEC_IN_DAY, FieldMappingSource } from "../constants";
import {
  LinkedAccount,
  User,
  CommonModelToggle,
  CateogryScopeMap,
  CommonModelField,
  CommonModelFieldMap,
} from "../models/Entities";
import { LOGIN_PATH } from "../router/RouterUtils";
import { createFieldMappingOptions } from "../components/integrations-management/linked-accounts/detail-page/field-mappings/CreateLinkedAccountFieldMappingPage";
import { CSRF_COOKIE_NAME } from "../hooks/useFetchCSRFToken";

export interface UserSuccessData {
  token: string;
  user: User;
  created: boolean;
  organization: any;
}
export interface FormErrorData {
  [key: string]: string[];
}

export interface MultipleFormErrorData {
  [index: number]: FormErrorData;
}

export interface PaginatedAPIResponse<T> {
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface SSOLoginData {
  sso_url: string;
}

export enum Tenancy {
  SingleTenant,
  NAMultiTenant,
  EUMultiTenant,
  Local,
}

export const VALID_TENANT_URLS = [
  "https://api.merge.dev",
  "https://api-eu.merge.dev",
  "https://api-sandbox.merge.dev",
  "https://api-oyster-eu.merge.dev",
  "https://api-tripactions-eu.merge.dev",
  "https://api-develop.merge.dev",
  "http://localhost:8000",
];

const AUTH_TOKEN_INDICATOR = "authentication_token_indicator";

/**
 * This generic `Result` type enables an API promise to return either a result or an error with one return
 * value. Use it everywhere possible with a `Promise` wrapped around it so our API client calls can become:
 * ```
 * const result = await fetchDataFromAPI(inputInfo)
 * if (result.status === 'success') {
 *     ...
 * } else {
 *     ...
 * }
 * ```
 */
export type Result<ResultType, ErrorType = Response | undefined> =
  | {
      status: "success";
      data: ResultType;
    }
  | { status: "error"; error: ErrorType };

export const API_DOMAIN = (() => {
  switch (process.env.REACT_APP_MERGE_ENV) {
    case "PRODUCTION":
      const base_url = process.env.REACT_APP_BASE_API_URL;
      if (!base_url) {
        Sentry.captureMessage("Base API URL not found for production dashboard.");
      }
      return base_url;
    case "SANDBOX":
      return "https://api-sandbox.merge.dev";
    case "DEVELOP":
      return "https://api-develop.merge.dev";
    case "LOCAL":
    default:
      return "http://localhost:8000";
  }
})();

export const getTenancy = (): Tenancy => {
  const mergeEnv = process.env.REACT_APP_MERGE_ENV;
  if (!mergeEnv || mergeEnv == "LOCAL") {
    return Tenancy.Local;
  }

  const baseAPIURL = process.env.REACT_APP_BASE_API_URL;
  if (baseAPIURL == "https://api.merge.dev") {
    return Tenancy.NAMultiTenant;
  }

  if (baseAPIURL == "https://api-eu.merge.dev") {
    return Tenancy.EUMultiTenant;
  }

  return Tenancy.SingleTenant;
};

export const apiURLForPath = (path: string): string => {
  const baseURL = `${API_DOMAIN}/api`;
  if (path.charAt(0) != "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

export const apiURLForPathWithOverrideUrl = (url: string, path: string): string => {
  const baseURL = `${url}/api`;
  if (path.charAt(0) != "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

export const fetchWithoutAuth = ({
  path,
  method = "GET",
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method?: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}): Promise<void> => {
  const url = apiURLForPath(path);
  return fetchWithoutAuthHelper({
    url,
    method,
    headers,
    body,
    onResponse,
    onError,
  });
};

export const fetchWithoutAuthWithBaseAPIUrlOverride = ({
  path,
  method = "GET",
  headers = {},
  body,
  overrideBaseAPIUrl,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  overrideBaseAPIUrl: string;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  const url = apiURLForPathWithOverrideUrl(overrideBaseAPIUrl, path);
  fetchWithoutAuthHelper({
    url,
    method,
    headers,
    body,
    onResponse,
    onError,
  });
};

export const fetchWithoutAuthHelper = async ({
  url,
  method,
  headers = {},
  body,
  onResponse,
  onError,
}: {
  url: string;
  method: string;
  headers: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  const cookies = new Cookies();
  const CSRFToken = await cookies.get(CSRF_COOKIE_NAME);

  const updatedHeaders: { [key: string]: string } = {
    ...headers,
    "Content-Type": "application/json;charset=UTF-8",
    "X-CSRFToken": CSRFToken,
  };

  try {
    const response = await fetch(url, {
      method,
      headers: updatedHeaders,
      body: body ? JSON.stringify(body) : null,
      // credentials: "include", @jacten https://app.asana.com/0/0/1205585288298583/f
    });

    if (!response.ok) {
      throw response;
    }

    const responseJson = await response.json();
    onResponse(responseJson);
  } catch (error: any) {
    if (onError) {
      onError(error && "json" in error ? error : undefined);
    }
  }
};

export const fetchWithAuth = async <DataType extends any = any>({
  path,
  method = "GET",
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: DataType) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  let updatedHeaders: { [key: string]: string } | Headers = headers || {};
  let updatedBody: any = body && method !== "GET" ? JSON.stringify(body) : null;
  const cookies = new Cookies();
  const authToken = await cookies.get("authentication_token");
  const CSRFToken = await cookies.get(CSRF_COOKIE_NAME);

  let hasFile = false;
  for (const key in body) {
    if (body[key] instanceof Blob) {
      hasFile = true;
    }
  }

  if (hasFile) {
    updatedHeaders = new Headers();
    for (const key in headers) {
      updatedHeaders.append(key, headers[key]);
    }

    updatedBody = null;
    if (body) {
      updatedBody = new FormData();
      for (const key in body) {
        updatedBody.append(key, body[key]);
      }
    }
    if (authToken) {
      updatedHeaders.append("Authorization", `Token ${authToken}`);
    }
    if (CSRFToken) {
      updatedHeaders.append("X-CSRFToken", CSRFToken);
    }
  } else {
    if (authToken) {
      updatedHeaders.Authorization = `Token ${authToken}`;
    }
    if (CSRFToken) {
      updatedHeaders["X-CSRFToken"] = CSRFToken;
    }
    updatedHeaders["Content-Type"] = "application/json;charset=UTF-8";
  }

  const url = apiURLForPath(path);

  try {
    const response = await fetch(url, {
      method,
      headers: updatedHeaders,
      body: updatedBody,
      // credentials: "include", @jacten https://app.asana.com/0/0/1205585288298583/f
    });

    if (response.status === 403) {
      const data = await response.json();

      if (["Invalid token.", "Token has expired."].includes(data.detail)) {
        removeAuthTokenAndUserType();
        window.location.href = LOGIN_PATH;
      }

      throw response;
    }

    if (!response.ok) {
      throw response;
    }

    const data = response.status === 204 ? {} : await response.json();
    onResponse(data);
  } catch (error: any) {
    if (onError) {
      onError(error && "json" in error ? error : undefined);
    }
  }
};

export const fetchCurrentUser = (setUser: (response: any) => void) => {
  fetchWithAuth({
    path: "/users/me",
    method: "GET",
    onResponse: (data: UserWithBillingPlan) => {
      const cookies = new Cookies();
      cookies.set("user_type", data.type, { maxAge: SEC_IN_YEAR, secure: true });
      setUser(data);
    },
  });
};

export const fetchLinkedAccountFieldMappingInstances = (
  linkedAccountID: string,
  setFieldMappingInstances: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/${linkedAccountID}/field-mapping-instances`,
    method: "GET",
    onResponse: (data: any) => {
      setFieldMappingInstances(data);
    },
  });
};

export const fetchFieldMappingInstance = (
  fieldMappingInstanceID: string,
  setFieldMappingInstance: (response: any) => void,
  callBack?: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mapping-instance/${fieldMappingInstanceID}`,
    method: "GET",
    onResponse: (data: any) => {
      setFieldMappingInstance(data);
      if (callBack) {
        callBack();
      }
    },
  });
};

export const editFieldMappingInstance = (
  fieldMappingInstanceID: string,
  editedFieldMappingInstance: EditFieldMappingInstanceProps,
  onResponse: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mapping-instance/${fieldMappingInstanceID}`,
    body: editedFieldMappingInstance,
    method: "PATCH",
    onResponse,
  });
};

export const fetchIntegrationWideFieldMappingOptions = (
  integrationId: string,
  fieldMappingTargetId: string,
  commonModel: string,
  setFieldMappingOptions: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/field-mappings/${integrationId}/${fieldMappingTargetId}/meta`,
    method: "GET",
    onResponse: (data: any) => {
      setFieldMappingOptions(createFieldMappingOptions(data.available_field_mappings, commonModel));
    },
    onError: () => {
      showErrorToast("Unable to Fetch Field Mapping Options");
    },
  });
};

export const fetchIntegrationWideOverrideOptions = (
  integrationId: string,
  overrideTargetID: string,
  commonModel: string,
  setFieldMappingOptions: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/field-mappings/${integrationId}/${overrideTargetID}/meta?is_common_model_override=True`,
    method: "GET",
    onResponse: (data: any) => {
      setFieldMappingOptions(data.available_field_mappings);
    },
    onError: () => {
      showErrorToast("Unable to Fetch Field Mapping Options");
    },
  });
};

export const deleteLinkedAccountFieldMapping = (
  fieldMappingID: string,
  refreshFieldMappings: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/${fieldMappingID}/delete`,
    method: "DELETE",
    onResponse: (_: any) => {
      refreshFieldMappings();
    },
  });
};

export type NewFieldMappingProps = {
  linked_account_id: string;
  common_model_id: string;
  field_key: string;
  origin_type: string;
  field_traversal_path: Array<string>;
  field_description?: string;
  configured_by: FieldMappingSource;
  create_for_organization: boolean;
  field_mapping_target_id?: string;
  api_endpoint_id: string;
  display_name: string;
};

export type NewIntegrationWideFieldMapping = {
  integration_id: string;
  organization_id: string;
  common_model_id: string;
  field_key: string;
  origin_type: string;
  field_traversal_path: Array<string>;
  field_description?: string;
  configured_by: FieldMappingSource;
  create_for_organization: boolean;
  field_mapping_target_id?: string;
  api_endpoint_id: string;
  display_name: string;
  enable_linked_account_level_overrides?: boolean;
};

export type EditFieldMappingInstanceProps = {
  field_mapping_instance_id: string;
  field_traversal_path: Array<string>;
  field_description?: string;
  api_endpoint_id: string;
  display_name: string;
  enable_linked_account_level_overrides?: boolean;
  origin_type: string;
};
export const createLinkedAccountFieldMapping = (
  body: NewFieldMappingProps,
  onResponse: (data?: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/create`,
    method: "POST",
    body,
    onResponse: (data: any) => {
      onResponse(data.field_mapping_instance_id);
    },
  });
};

export const createIntegrationWideFieldMapping = (
  body: NewIntegrationWideFieldMapping,
  isLoading: () => void,
  handleFieldMappingInstanceID?: (id: string) => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/field-mappings/create`,
    method: "POST",
    body,
    onResponse: (data: any) => {
      isLoading();
      handleFieldMappingInstanceID!(data?.field_mapping_instance_id);
    },
    onError: () => showErrorToast("Unable to create Field Mapping Instance"),
  });
};

const isNewAuthTokenMethod = (): boolean => {
  const cookies = new Cookies();
  return !!cookies.get(AUTH_TOKEN_INDICATOR);
};

export const getAuthToken = (): string | null => {
  const cookies = new Cookies();
  // currently we pass this cookie in a way where the frontend and backend can both set and get it - however
  // in the new work we will pass the cookie from the backend in a way where the frontend cant get it (httponly)
  // for this reason passing a second cookie that the frontend can read that only contains an indicator of if the
  // auth token was passed or not
  const cookieName = isNewAuthTokenMethod() ? AUTH_TOKEN_INDICATOR : "authentication_token";
  const authToken: string | null = cookies.get(cookieName);
  return authToken || null;
};

export const hasAuthToken = () => {
  return getAuthToken() !== null;
};

export const getCookieDomainForEnv = () =>
  (process.env.REACT_APP_MERGE_ENV == "PRODUCTION" ||
    process.env.REACT_APP_MERGE_ENV == "DEVELOP") &&
  !process.env.REACT_APP_MERGE_PROD_FROM_LOCAL
    ? ".merge.dev"
    : undefined;

export const setAuthTokenAndUserType = (authToken: string, userType: string) => {
  const cookieDomain = getCookieDomainForEnv();
  const cookies = new Cookies();
  // TODO (Ankit): Remove this after testing - we shouldnt need to manually set this cookie anymore
  if (!isNewAuthTokenMethod()) {
    cookies.set("authentication_token", authToken, {
      maxAge: SEC_IN_DAY,
      domain: cookieDomain,
      path: "/",
      secure: true,
      sameSite: "lax",
    });
  }
  cookies.set("user_type", userType, {
    maxAge: SEC_IN_YEAR,
    secure: true,
    path: "/",
    domain: cookieDomain,
  });
};

export const setUserType = (userType: string) => {
  const cookies = new Cookies();
  const cookieDomain = getCookieDomainForEnv();

  cookies.set("user_type", userType, {
    maxAge: SEC_IN_YEAR,
    secure: true,
    path: "/",
    domain: cookieDomain,
  });
};

export const removeAuthTokenAndUserType = () => {
  const cookies = new Cookies();
  const cookieDomain = getCookieDomainForEnv();

  if (isNewAuthTokenMethod()) {
    cookies.remove(AUTH_TOKEN_INDICATOR, { path: "/", domain: cookieDomain });
  }

  cookies.remove("authentication_token", { path: "/", domain: cookieDomain });
  cookies.remove("user_type", { path: "/", domain: cookieDomain });
  cookies.remove(CSRF_COOKIE_NAME, { path: "/", domain: cookieDomain });
};

export const onLogout = ({ onError }: { onError: () => void }) =>
  fetchWithAuth({
    path: "/users/logout",
    method: "POST",
    onResponse: () => {
      removeAuthTokenAndUserType();
      window.location.href = LOGIN_PATH;
    },
    onError,
  });

const getCommonModelFieldsMap = (fields: CommonModelField[]) => {
  return fields.reduce((fieldsMap: CommonModelFieldMap, field: CommonModelField) => {
    return {
      ...fieldsMap,
      [field.field_name]: {
        isEnabled: field.is_enabled,
      },
    };
  }, {});
};

export const createScopeMap = (commonModelToggles: CommonModelToggle[]) => {
  const commonModelTogglesMap = commonModelToggles.reduce(
    (commonModelTogglesMap: CateogryScopeMap, commonModelToggle: CommonModelToggle) => {
      const commonModelFieldsMap = getCommonModelFieldsMap(commonModelToggle.fields);
      return {
        ...commonModelTogglesMap,
        [commonModelToggle.name]: {
          actions: commonModelToggle.enabled_model_actions,
          capabilities: commonModelToggle.model_capabilities,
          fields: commonModelFieldsMap,
        },
      };
    },
    {},
  );
  return commonModelTogglesMap;
};

export const getLinkedAccountCommonModelTogglesMap = (
  linkedAccount: LinkedAccount,
  setLinkedAccountLevelCommonModelScopes: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/common-model-toggles/${linkedAccount.category}?linked_account_id=${linkedAccount.id}`,
    method: "GET",
    onResponse: (commonModelToggles) => {
      setLinkedAccountLevelCommonModelScopes(createScopeMap(commonModelToggles));
    },
  });
};

export const getOrgCommonModelTogglesMap = (
  category: string,
  setOrgLevelCommonModelScopes: (response: any) => void,
) => {
  fetchWithAuth({
    path: `integrations/common-model-toggles/${category}`,
    method: "GET",
    onResponse: (commonModelToggles) => {
      setOrgLevelCommonModelScopes(createScopeMap(commonModelToggles));
    },
  });
};

export const toggleRedactUnmappedData = (
  enabled: boolean,
  organizationId?: string,
  linkedAccountId?: string,
  onResponse?: (response: {
    feature_enabled_for_organization?: boolean;
    feature_enabled_for_linked_account?: boolean;
  }) => void,
  onError?: (response: Response | undefined) => void,
) => {
  fetchWithAuth({
    path: `integrations/redact-unmapped-data/toggle`,
    method: "POST",
    body: {
      organization_id: organizationId,
      linked_account_id: linkedAccountId,
      enabled,
    },
    onResponse: (res: {
      feature_enabled_for_organization?: boolean;
      feature_enabled_for_linked_account?: boolean;
    }) => {
      if (onResponse) onResponse(res);
    },
    onError: (err) => {
      if (onError) onError(err);
    },
  });
};

export const postUpgradeInterest = (
  user: User,
  requestedPlanUpgrade: boolean,
  setRequestedPlanUpgrade: (response: any) => void,
) => {
  if (!requestedPlanUpgrade) {
    fetchWithAuth({
      path: `/users/request-upgrade`,
      method: "PATCH",
      body: { user_email: user.email, time_request: Date.now() },
      onResponse: () => {
        showInfoToast(
          "Successfully requested Plan Upgrade. The Merge team will be in touch shortly!",
        );
        setRequestedPlanUpgrade(true);
      },
      onError: () => {
        showErrorToast("Failed to indicate interest in upgrade. Please try again.");
      },
    });
  }
};
