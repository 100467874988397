import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { deleteLinkedAccountFieldMapping } from "../../../../../api-client/APIClient";
import MergeModal from "../../../../shared-components/MergeModal";
import SpinnerButton from "../../../../shared-components/SpinnerButton";
import useLinkedAccountCustomMappingsContext from "./context/useLinkedAccountFieldMappingsContext";
import { deleteFieldMappingTarget } from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import { showErrorToast, showSuccessToast } from "../../../../shared-components/Toasts";
import styled from "styled-components";

const DeleteButton = styled(SpinnerButton)`
  width: 80%;
  margin-bottom: 10px;
`;
const CancelButton = styled(Button)`
  width: 80%;
`;

type Props = {
  headerText?: string;
  linkedAccountID?: string;
  fieldMappingID?: string;
  onHide: () => void;
  showModal: boolean;
  handlePostDelete?: () => void;
  text?: string;
  isFieldMappingTargetDelete?: boolean;
  fieldMappingTargetID?: string;
};

const DeleteFieldMappingModal_OLD = ({
  headerText,
  fieldMappingID,
  onHide,
  showModal,
  handlePostDelete,
  text,
  isFieldMappingTargetDelete = false,
  fieldMappingTargetID,
}: Props) => {
  const [isFieldMappingDeletionConfirmationChecked, setIsFieldMappingDeletionConfirmationChecked] =
    useState(false);
  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();

  const [isDeleting, setIsDeleting] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <MergeModal
      centered
      show={showModal}
      bodyClassName="modal-narrow overflow-hidden"
      dialogClassName="modal-narrow"
      onHide={onHide}
      title={headerText || "Delete Mapping"}
      ref={modalRef}
    >
      <span className="text-center">
        {text || "This action cannot be undone"}
        <br />
        <br />
        Do you wish to continue?
        <Form.Group className="d-flex deprecated-mt-4 deprecated-mb-4">
          <Form.Check
            type="checkbox"
            id="field-mapping-deletion-checkbox"
            checked={isFieldMappingDeletionConfirmationChecked}
            onChange={() =>
              setIsFieldMappingDeletionConfirmationChecked(
                !isFieldMappingDeletionConfirmationChecked,
              )
            }
            label={<b>I understand that this is an irreversible action.</b>}
          />
        </Form.Group>
        <div className="w-100 text-center">
          <DeleteButton
            disabled={!isFieldMappingDeletionConfirmationChecked}
            isLoading={isDeleting}
            className="modal-button btn-danger"
            text={isFieldMappingTargetDelete ? "Delete Target Field" : "Delete Field Mapping"}
            type="submit"
            onClick={() => {
              setIsDeleting(true);
              if (isFieldMappingTargetDelete) {
                deleteFieldMappingTarget({
                  fieldMappingTargetID: fieldMappingTargetID!,
                  onResponse: () => showSuccessToast("successfully deleted Target"),
                  onError: () => showErrorToast("Could not delete Field Mapping Target"),
                });
              } else {
                deleteLinkedAccountFieldMapping(fieldMappingID!, refreshCustomMappings);
              }
              setIsDeleting(false);
              if (handlePostDelete) {
                handlePostDelete();
              }
              onHide();
            }}
          />
        </div>
        <div className="w-100 text-center">
          <CancelButton variant="white" className="80%modal-button" onClick={onHide}>
            Cancel
          </CancelButton>
        </div>
      </span>
    </MergeModal>
  );
};

export default DeleteFieldMappingModal_OLD;
