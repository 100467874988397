import {
  SmallWhiteButton,
  Alert,
  getDefinitionForModelName,
  getModelDescription,
  hydratedParametersFromModelDefinition,
} from "@merge-api/merge-javascript-shared";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Result } from "../../../api-client/APIClient";
import {
  fetchCommonModelsFromCategory,
  updateCommonModel,
} from "../../../api-client/categories/CommonModelTogglesAPIClient";
import { fetchComponentSchemasForCategory } from "../../../api-client/categories/ComponentSchemasAPIClient";
import { CUSTOM_OBJECT_COMMON_MODEL_NAMES } from "../../../constants";
import { EnabledAction } from "../../../models/CommonModel";
import { APICategory } from "../../../models/Entities";
import { displayNameForAPICategory } from "../../../models/Helpers";
import { apiDocsPathForCategory, openInNewTab } from "../../../router/RouterUtils";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import ErrorFallback from "../../shared-components/ErrorFallback/ErrorFallback";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import CommonModelToggle from "./CommonModelToggle";
import { Schemas } from "./CommonModelToggleTypes";
import { createSortedModelsMap, SortedModelsMap } from "./CommonModelUtils";
import { BookOpen } from "lucide-react";

type ConfigurationCommonModelsPageProps = {
  category: APICategory;
  showUpsellModal: boolean;
  setShowUpsellModal: (isOpen: boolean) => void;
};

const MinWidthCol = styled(Col)`
  min-width: 300px;
`;

/**
 * Shows the Common Models under an API category and allows the user to enable/disable the syncing
 * of that particular model.
 */
const ConfigurationCommonModelsPage = ({
  category,
  showUpsellModal,
  setShowUpsellModal,
}: ConfigurationCommonModelsPageProps) => {
  const displayName = displayNameForAPICategory(category);
  const [commonModels, setCommonModels] = useState<SortedModelsMap>({});
  const [schemas, setSchemas] = useState<Schemas>({});
  const [hasError, setHasError] = useState(false);
  const { productRestrictions } = useProductRestrictions();

  /**
   * Gets the Common Models on page load and sets them in the UI
   */
  useEffect(() => {
    fetchCommonModelsFromCategory(category)
      .then((result) => {
        if (result.status === "success") {
          return createSortedModelsMap(result.data);
        } else {
          setHasError(true);
          return null;
        }
      })
      .then((commonModelsMap) => {
        if (commonModelsMap) {
          fetchComponentSchemasForCategory(category)
            .then((result) => {
              if (result.status === "success") {
                setSchemas(
                  Object.fromEntries(
                    Object.keys(commonModelsMap).map((modelName) => {
                      const definition = getDefinitionForModelName(modelName, result.data);
                      return [
                        modelName,
                        definition
                          ? {
                              description: getModelDescription(definition),
                              parameters: Object.fromEntries(
                                hydratedParametersFromModelDefinition(result.data, definition).map(
                                  (parameter) => [
                                    parameter.name,
                                    {
                                      name: parameter.name,
                                      in: parameter.in,
                                      type: parameter.type,
                                      description: parameter.description,
                                    },
                                  ],
                                ),
                              ),
                            }
                          : undefined,
                      ];
                    }),
                  ),
                );
              }
            })
            .finally(() => {
              setCommonModels(commonModelsMap);
            });
        }
      });
  }, []);

  const updateEnabledStatus = useCallback(
    (
      modelName: string,
      fieldName: string | null,
      enabledActions: Array<EnabledAction>,
    ): Promise<Result<void>> => {
      const relatingField = (fieldName && commonModels[modelName].fields[fieldName]) || undefined;
      const relatedModelName = relatingField?.related_to;
      const relatedModel = !relatedModelName ? undefined : commonModels[relatedModelName];
      const isRelatedToSubobject = relatedModel && !relatedModel.has_endpoints;
      const shouldToggleRelatedModelInsteadOfField =
        relatedModelName && isRelatedToSubobject && relatedModel.name !== modelName;
      const transmittedFieldName = shouldToggleRelatedModelInsteadOfField
        ? undefined
        : fieldName ?? undefined;
      return updateCommonModel({
        category: category,
        modelName: shouldToggleRelatedModelInsteadOfField ? relatedModelName : modelName,
        fieldName: transmittedFieldName,
        enabledActions: enabledActions,
      }).then((result) => {
        if (result.status === "success") {
          setCommonModels(createSortedModelsMap(result.data));
          return { status: "success", data: undefined };
        } else {
          return result;
        }
      });
    },
    [commonModels],
  );

  const docsButton = (
    <SmallWhiteButton
      onClick={() => openInNewTab(apiDocsPathForCategory(category))}
      leftIcon={<BookOpen size={12} />}
    >
      <>Merge {displayName} Docs</>
    </SmallWhiteButton>
  );

  return (
    <Row>
      <MinWidthCol>
        <SectionHeaderWrapper
          title={`${displayName} Common Models`}
          subtitle={`Customize the syncing of Merge's standardized data model across ${displayName} platforms.`}
          titleHeading={TitleHeading.H1}
          button={docsButton}
        >
          <>
            <Row className="deprecated-mt-4">
              <Col>
                <Alert className="mb-6 mt-2" color="yellow" showWarningIcon>
                  <div className="text-sm">
                    If you change your sync settings below, you are responsible for notifying
                    existing end users about changes to their scope permissions. These settings will
                    not apply to passthrough requests.
                  </div>
                </Alert>
              </Col>
            </Row>
            <div className="deprecated-mt-2">
              {hasError ? (
                <ErrorFallback />
              ) : Object.keys(commonModels).length === 0 ? (
                <EmptyStateWrapper isSpinner />
              ) : (
                Object.values(commonModels)
                  .filter((model) => model.has_endpoints)
                  .filter((model) => !CUSTOM_OBJECT_COMMON_MODEL_NAMES.includes(model.name))
                  .map((model) => (
                    <CommonModelToggle
                      key={model.name}
                      models={commonModels}
                      schemas={schemas}
                      modelName={model.name}
                      productRestrictions={productRestrictions}
                      updateEnabledStatus={updateEnabledStatus}
                      showUpsellModal={showUpsellModal}
                      setShowUpsellModal={setShowUpsellModal}
                    />
                  ))
              )}
            </div>
          </>
        </SectionHeaderWrapper>
      </MinWidthCol>
    </Row>
  );
};

export default ConfigurationCommonModelsPage;
