import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Col, Row, Form, Table } from "react-bootstrap";
import DottedOutlineTextCard from "../../shared-components/DottedOutlineTextCard";
import { fetchWithAuth } from "../../../api-client/APIClient";
import MergeModal from "../../shared-components/MergeModal";
import { showErrorToast } from "../../shared-components/Toasts";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { DOCS_AUTHENTICATION_PATH, TEST_LINKED_ACCOUNTS_PATH } from "../../../router/RouterUtils";
import { SmallTextMutedParagraph } from "../../shared-components/MergeText";
import styled from "styled-components";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { Trash } from "lucide-react";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import { RefreshCw } from "react-feather";
import IconGrayTextCard from "../../shared-components/IconGrayTextCard";
import { formatDate } from "../../../models/Helpers";

type APIKeyInfo = {
  id: string;
  key_type: string;
  created_at: string;
};

type APIKey = APIKeyInfo & {
  key: string;
};

type RemoteAPIKeyInfo = APIKeyInfo & {
  name: string;
  is_test: boolean;
};

type APIKeyData = [RemoteAPIKeyInfo];

const CustomHeader = styled.div`
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
`;

const RemoteKeyName = styled.div`
  font-size: 14px;
  line-height: 24px;
`;

const StyledTable = styled.div`
  border-width: 0.5px;
  border-style: solid;
  font-size: 12px;
  border-color: var(--lm-gray-20, #dce2ea);

  && tr:first-child {
    border-top-width: 0;
  }
  && tr {
    font-size: 12px;
    line-height: 22px;
    border-style: solid;
    border-top-width: 0.5px;
    border-color: var(--lm-gray-20, #dce2ea);
  }
  && td {
    border: 0;
  }
`;

const ConfigurationAPIKeysPage = () => {
  const [remoteAPIKeyData, setRemoteAPIKeyData] = useState<APIKeyData | undefined>();
  const [keyBeingRegenerated, setKeyBeingRegenerated] = useState<"production" | undefined>();
  const [regenerateConfirmInputText, setRegenerateConfirmInputText] = useState("");
  const [isLoadingRegenerateKey, setIsLoadingRegenerateKey] = useState(false);

  const [generatedAPIKey, setGeneratedAPIKey] = useState<APIKey | undefined>();
  const [keyBeingDeleted, setKeyBeingDeleted] = useState<APIKeyInfo | undefined>();
  const [isShowingNewRemoteKeyModal, setIsShowingNewRemoteKeyModal] = useState(false);
  const [isShowingAddRemoteKeyModal, setIsShowingAddRemoteKeyModal] = useState(false);
  const [isShowingConfirmDeleteModal, setIsShowingConfirmDeleteModal] = useState(false);
  const [newRemoteKeyName, setNewRemoteKeyName] = useState("");
  const [newRemoteKeyIsTest, setNewRemoteKeyIsTest] = useState(false);
  const [newRemoteKeyIsProd, setNewRemoteKeyIsProd] = useState(false);
  const [isLoadingNewRemoteKey, setIsLoadingNewRemoteKey] = useState(false);

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/remote-keys",
      method: "GET",
      onResponse: (data) => {
        setRemoteAPIKeyData(data);
      },
    });
  }, []);

  const regenerateAPIKey = useCallback(() => {
    setIsLoadingRegenerateKey(true);
    setNewRemoteKeyIsProd(true);
    fetchWithAuth({
      path: `/integrations/keys/regenerate-production-key`,
      method: "POST",
      onResponse: (generatedProdAPIKey: APIKey) => {
        setGeneratedAPIKey(generatedProdAPIKey);
        setIsShowingNewRemoteKeyModal(true);
        setKeyBeingRegenerated(undefined);
        setIsLoadingRegenerateKey(false);
        setNewRemoteKeyName("");
      },
      onError: () => {
        showErrorToast(
          `Failed to regenerate your organization's ${keyBeingRegenerated} key. Please try again.`,
        );
        setIsLoadingRegenerateKey(false);
        setKeyBeingRegenerated(undefined);
        setNewRemoteKeyName("");
      },
    });
  }, [keyBeingRegenerated, remoteAPIKeyData]);

  const createRemoteKey = useCallback(
    (e: any) => {
      e.preventDefault();
      setIsLoadingNewRemoteKey(true);
      fetchWithAuth({
        path: "/integrations/remote-key",
        method: "POST",
        body: { name: newRemoteKeyName, is_test: newRemoteKeyIsTest },
        onResponse: (generatedRemoteAPIKey: APIKey) => {
          setGeneratedAPIKey(generatedRemoteAPIKey);
          setIsShowingNewRemoteKeyModal(true);
          fetchWithAuth({
            path: "/integrations/remote-keys",
            method: "GET",
            onResponse: (data) => {
              setRemoteAPIKeyData(data);
              setIsShowingAddRemoteKeyModal(false);
              setIsLoadingNewRemoteKey(false);
              setNewRemoteKeyName("");
            },
          });
        },
      });
    },
    [newRemoteKeyName, newRemoteKeyIsTest],
  );

  const emptyState = (isTest: boolean) => {
    return (
      <tr key={`remote-key-0`}>
        <td>
          <RemoteKeyName className="pt-0.5 pb-0.5">
            You have no {isTest ? "test" : "remote production"} access keys. Add an access key by
            clicking the button below.
          </RemoteKeyName>
        </td>
      </tr>
    );
  };

  const getKeyRows = (isTest: boolean) => {
    const keyData = remoteAPIKeyData?.filter((remote_key) => remote_key.is_test == isTest) ?? [];
    if (keyData.length > 0) {
      return keyData.map((remote_key, index) => {
        return (
          <tr key={`remote-key-${index}`}>
            <td style={{ width: "80%" }} className="font-weight-bold">
              <RemoteKeyName>{remote_key.name}</RemoteKeyName>
            </td>
            <td>
              <>
                <span className="text-gray-50 mr-2">Created</span>
                {formatDate(remote_key.created_at, "MMM DD, YYYY", false)}
              </>
            </td>
            <td>
              <SmallWhiteButton
                variant="singleIcon"
                onClick={() => {
                  setKeyBeingDeleted(remote_key);
                  setIsShowingConfirmDeleteModal(true);
                }}
              >
                <Trash size={16} />
              </SmallWhiteButton>
            </td>
          </tr>
        );
      });
    } else {
      return emptyState(isTest);
    }
  };

  const getKeyTable = (isTest: boolean) => {
    return (
      <StyledTable className={"rounded-table-corner table-responsive mb-4"}>
        <Table className="table-nowrap mb-0">
          <tbody>{getKeyRows(isTest)}</tbody>
        </Table>
      </StyledTable>
    );
  };

  const deleteRemoteKey = () => {
    if (!keyBeingDeleted) {
      return;
    }
    fetchWithAuth({
      path: `/integrations/remote-key/${keyBeingDeleted.id}`,
      method: "DELETE",
      onResponse: () => {
        setKeyBeingDeleted(undefined);
        setIsShowingConfirmDeleteModal(false);
        fetchWithAuth({
          path: "/integrations/remote-keys",
          method: "GET",
          onResponse: (data) => {
            setRemoteAPIKeyData(data);
          },
        });
      },
    });
  };

  const capitalize = (s: string | undefined) => {
    if (!!s && s.length > 0) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
      return s;
    }
  };

  const keyType = newRemoteKeyIsTest ? "Test" : newRemoteKeyIsProd ? "Production" : "Remote";
  const modalTitle = keyBeingRegenerated
    ? `Regenerate ${capitalize(keyBeingRegenerated)} Access Token`
    : `${isShowingAddRemoteKeyModal ? "Add" : "New"} ${keyType} Access Key`;

  return (
    <>
      <MergeModal
        show={isShowingAddRemoteKeyModal || isShowingNewRemoteKeyModal || !!keyBeingRegenerated}
        title={modalTitle}
        centered
        bodyClassName="overflow-hidden"
        onHide={() => {
          setIsShowingAddRemoteKeyModal(false);
          setIsShowingNewRemoteKeyModal(false);
          setKeyBeingRegenerated(undefined);
          setGeneratedAPIKey(undefined);
          setNewRemoteKeyIsProd(false);
          setNewRemoteKeyIsTest(false);
        }}
      >
        {!!keyBeingRegenerated ? (
          <>
            <Row>
              <Col>
                <p>
                  Regenerating will permanently delete any existing {keyBeingRegenerated} tokens
                  currently in use.
                </p>
                <b>
                  Type "<span className="red">{keyBeingRegenerated}</span>" below to confirm.
                </b>
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  className="form-control trigger-input-value w-100 deprecated-mt-3"
                  placeholder={keyBeingRegenerated}
                  onChange={(e) => setRegenerateConfirmInputText(e.currentTarget.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SpinnerButton
                  className="btn-block deprecated-mt-4 btn-danger"
                  disabled={regenerateConfirmInputText !== keyBeingRegenerated}
                  text="Delete and regenerate token"
                  isLoading={isLoadingRegenerateKey}
                  onClick={regenerateAPIKey}
                />

                <Button
                  variant="link"
                  className="btn-block deprecated-mt-2 border-0 text-black font-medium"
                  onClick={() => setKeyBeingRegenerated(undefined)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        ) : isShowingAddRemoteKeyModal ? (
          <>
            {newRemoteKeyIsTest ? (
              <p>
                This key only provides access to <b>Test Linked Accounts</b> in your Merge instance.
              </p>
            ) : (
              <p>
                This key provides full access to <b>Production Linked Accounts</b> in your Merge
                instance.
              </p>
            )}

            <Form>
              <Form.Control
                placeholder={`Key name ${newRemoteKeyName}`}
                className="deprecated-mt-3"
                onChange={(e) => setNewRemoteKeyName(e.currentTarget.value)}
              />
              <SpinnerButton
                className="btn-block deprecated-mt-3 btn-dark"
                disabled={newRemoteKeyName === ""}
                text="Create"
                isLoading={isLoadingNewRemoteKey}
                type="submit"
                onClick={(e) => createRemoteKey(e)}
              />
              <Button
                variant="link"
                className="btn-block deprecated-mt-2 border-0 text-black font-medium"
                onClick={() => {
                  setKeyBeingRegenerated(undefined);
                  setNewRemoteKeyName("");
                  setIsShowingAddRemoteKeyModal(false);
                  setIsShowingNewRemoteKeyModal(false);
                }}
              >
                Cancel
              </Button>
            </Form>
          </>
        ) : (
          isShowingNewRemoteKeyModal && (
            <>
              <p>Copy your access key below and record it somewhere safe.</p>
              <b>
                You will <span className="red">not</span> be able to recover this key after closing
                this window.
              </b>
              <DottedOutlineTextCard
                text={generatedAPIKey?.key ?? "Loading..."}
                isSecret={!!generatedAPIKey}
                isOutlined={false}
                iconSize={16}
              />
            </>
          )
        )}
      </MergeModal>

      <MergeModal
        show={isShowingConfirmDeleteModal}
        title="Delete Access Key"
        centered
        bodyClassName="modal-extra-narrow overflow-hidden"
        dialogClassName="modal-extra-narrow"
        onHide={() => setIsShowingConfirmDeleteModal(false)}
      >
        <p>
          This action cannot be undone.
          <br />
          Do you wish to continue?
        </p>
        <Button
          variant="danger"
          className="btn-block"
          onClick={() => {
            deleteRemoteKey();
          }}
        >
          Delete key
        </Button>
        <Button
          variant="link"
          className="btn-block text-black font-medium"
          onClick={() => {
            setIsShowingConfirmDeleteModal(false);
          }}
        >
          Cancel
        </Button>
      </MergeModal>

      <Row className="mb-5">
        <Col>
          <SectionHeaderWrapper
            title="Organization Secrets"
            subtitle={
              <>
                <p className="deprecated-mb-3">
                  Use your access keys below to authenticate requests to Merge's APIs.
                  <br />
                </p>{" "}
                <p className="deprecated-mb-3">
                  Never put plaintext secrets in your codebase. We highly suggest using a secrets
                  manager to load in secrets at runtime to your application.
                </p>
              </>
            }
            titleHeading={TitleHeading.H1}
            headerRightHandContent={
              <a
                href={DOCS_AUTHENTICATION_PATH}
                className="text-primary font-medium small d-flex align-items-center"
                target="_blank"
                rel="noreferrer"
              >
                Learn about authentication
                <i className="fe fe-chevron-right" style={{ marginLeft: 1 }} />
              </a>
            }
          />
        </Col>
      </Row>
      <Row className="deprecated-mb-5">
        <Col>
          <Card className="mb-8">
            <Card.Body>
              <Row className={"pl-3 align-items-center d-flex w-100 justify-content-between"}>
                <Col>
                  <Row>
                    <CustomHeader>Production Access Key</CustomHeader>
                    <IconGrayTextCard className="ml-sm-n3" textLength={20} />
                  </Row>
                  <Row>
                    <p className="deprecated-mt-3 mb-0 pb-2">
                      Use this key when interacting with the Merge API from your production
                      environment.
                    </p>
                  </Row>
                </Col>
                <Col className="col-xl-auto col-sm-12">
                  <SmallWhiteButton
                    className="ml-sm-n3"
                    leftIcon={<RefreshCw size={12} />}
                    onClick={() => setKeyBeingRegenerated("production")}
                  >
                    Regenerate Token
                  </SmallWhiteButton>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="w-100 mb-8">
            <Card.Body>
              <div className="d-flex">
                <div style={{ flex: 1 }}>
                  <div className="d-flex">
                    <CustomHeader>Remote Production Access Keys</CustomHeader>
                  </div>
                  <p className="deprecated-mt-3 mb-0">
                    These keys give full access to production data, and are typically used for
                    on-premise deploys.
                  </p>
                  <p className="deprecated-mt-3 mb-0">
                    They are different from your Production Access Key since they cannot be used to
                    generate additional Remote Production Access Keys.
                  </p>
                  <div className="flex w-full flex-row justify-between">
                    <p className="deprecated-mt-3 mb-0">Most organizations will not need these.</p>
                    <SmallWhiteButton
                      variant="plus"
                      onClick={() => {
                        setNewRemoteKeyIsTest(false);
                        setIsShowingAddRemoteKeyModal(true);
                      }}
                    >
                      Remote Key
                    </SmallWhiteButton>
                  </div>
                </div>
              </div>
              <div className="mt-5">{getKeyTable(false)}</div>
            </Card.Body>
          </Card>
          <Card className="w-100">
            <Card.Body>
              <div className="d-flex">
                <div style={{ flex: 1 }}>
                  <div className="d-flex">
                    <CustomHeader>Test Access Keys</CustomHeader>
                  </div>
                  <div className="flex w-full flex-row justify-between">
                    <p className="deprecated-mt-3 mb-0">
                      Using these keys, you can communicate with the Merge API for data related to{" "}
                      <Link to={TEST_LINKED_ACCOUNTS_PATH}>Test Linked Accounts</Link>.
                    </p>{" "}
                    <SmallWhiteButton
                      variant="plus"
                      onClick={() => {
                        setNewRemoteKeyIsTest(true);
                        setIsShowingAddRemoteKeyModal(true);
                      }}
                    >
                      Test Key
                    </SmallWhiteButton>
                  </div>
                </div>
              </div>
              <div className="mt-5">{getKeyTable(true)}</div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationAPIKeysPage;
