import classNames from "classnames";
import DOMPurify from "dompurify";
import { ChevronRight } from "lucide-react";
import React, { useCallback, useMemo, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { Accordion, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import { updateOrganizationIntegrationSettings } from "../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import {
  OrganizationConfigStatusField,
  OrganizationIntegrationSettings,
  PartnershipType,
  SyncFrequencyPlan,
} from "../../../models/Entities";
import { snakeCaseToSpacedCapitalized } from "../../../services";
import MergeModal from "../../shared-components/MergeModal";
import SpinnerButton from "../../shared-components/SpinnerButton";
import IntegrationSettingsConfigParametersSection from "./IntegrationSettingsConfigParametersSection";
import IntegrationSettingsRowOverview from "./IntegrationSettingsRowOverview";
import IntegrationSettingsSectionContainer from "./IntegrationSettingsSectionContainer";
import IntegrationSyncFrequencySection, {
  SmallHeader,
  VerticallyCenteredCol,
} from "./IntegrationSyncFrequencySection";
import useAppContext from "../../context/useAppContext";
import { Alert } from "@merge-api/merge-javascript-shared";

interface Props {
  organizationIntegrationSettings: OrganizationIntegrationSettings;
  onIntegrationToggle: (data: OrganizationIntegrationSettings) => void;
  initiallyExpanded: boolean;
}

const IntegrationSettingsRow = ({
  organizationIntegrationSettings,
  onIntegrationToggle,
  initiallyExpanded,
}: Props) => {
  const { user } = useAppContext();

  const integrationSettings = useMemo(
    () => organizationIntegrationSettings,
    [organizationIntegrationSettings],
  );
  const configStatusFields = integrationSettings.organization_config_status;
  const integration = useMemo(
    () => integrationSettings.integration,
    [integrationSettings.integration],
  );

  const syncPlanMap = user.organization.sync_frequency_plans;
  const syncPlanType =
    syncPlanMap?.[integrationSettings.category as string] ||
    syncPlanMap?.[SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_DEFAULT];
  const isSyncFrequencyBillingPlan = (Object as any)
    .values(SyncFrequencyPlan)
    .includes(syncPlanType);

  const MergeBaseURL = process.env.REACT_APP_BASE_API_URL || "https://api.merge.dev";

  const [fieldBeingModified, setFieldBeingModified] =
    useState<OrganizationConfigStatusField | null>(null);
  const [fieldBeingModifiedValue, setFieldBeingModifiedValue] = useState<string | Blob | null>("");
  const [isSavingFieldBeingModified, setIsSavingFieldBeingModified] = useState(false);
  const humanReadableFieldBeingModifiedName = useMemo(
    () => snakeCaseToSpacedCapitalized(fieldBeingModified?.field_name ?? ""),
    [fieldBeingModified],
  );

  // If the accordion is open
  const [isOpen, setIsOpen] = useState(initiallyExpanded);

  const handleFileUpload = useCallback(
    (value: FileList | null) => {
      const certificateFile = value ? value[0] : null;
      if (certificateFile) {
        const blob = new Blob([certificateFile], { type: certificateFile.type });
        setFieldBeingModifiedValue(blob);
      }
    },
    [setFieldBeingModifiedValue],
  );

  const handleFieldBeingModifiedSave = useCallback(
    (event: any) => {
      event.preventDefault();
      if (!fieldBeingModified) {
        return;
      }
      let body: {};
      const fieldData: { [field_name: string]: string | Blob | null } = {};
      fieldData[fieldBeingModified.field_name] = fieldBeingModifiedValue;
      if (fieldBeingModified.field_type == "file") {
        body = fieldData;
      } else {
        body = { organization_config_fields: JSON.stringify(fieldData) };
      }

      setIsSavingFieldBeingModified(true);
      updateOrganizationIntegrationSettings({
        settingsId: integrationSettings.id,
        body: body,
        onUpdate: (data: OrganizationIntegrationSettings) => {
          onIntegrationToggle(data);
          setIsSavingFieldBeingModified(false);
          setFieldBeingModified(null);
        },
      });
    },
    [fieldBeingModified, fieldBeingModifiedValue],
  );

  const getCustomerNotesByPartnership = (notes: string, partnership: PartnershipType) => {
    const partnership_placeholder = "{" + partnership + "}";
    return notes.includes(partnership_placeholder)
      ? notes.substring(
          notes.indexOf(partnership_placeholder) + partnership_placeholder.length,
          notes.lastIndexOf(partnership_placeholder),
        )
      : notes;
  };

  // Section with configuration notes for the integration
  const configurationNotesSection = integration.notes_for_customers && (
    <IntegrationSettingsSectionContainer id="notes" isPartOfMainCard>
      <div className="grid content-center px-4">
        <SmallHeader className="mb-3 inline-block text-sm">Configuration Notes</SmallHeader>
        <span
          className="text-sm text-gray-60"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              getCustomerNotesByPartnership(
                integration.notes_for_customers,
                integrationSettings.partnership_type,
              ),
            ),
          }}
        />
        {integration.name === "ADP Workforce Now" && (
          <div className="my-3 mx-1.5">
            <span className="font-semibold text-sm">Subscription Create Notification URL:</span>
            <br />
            <span className="text-sm select-all text-[#82848c]">
              {`${MergeBaseURL}/api/integrations/organization-webhook-listeners/`}
              {integrationSettings.id}
            </span>
          </div>
        )}
      </div>
    </IntegrationSettingsSectionContainer>
  );

  const { show: showIntercom } = useIntercom();

  const BetaAside = organizationIntegrationSettings.is_beta && (
    <IntegrationSettingsSectionContainer id="Beta Aside" isPartOfMainCard>
      <Alert color="blue" showWarningIcon className="mx-3">
        <p className="mb-0 text-sm">
          {" "}
          This integration is in beta. The beta flag on integrations marks newer integrations that
          we've tested extensively on our side but haven't been battle-tested by many customers. Due
          to this, more edge cases can appear, so please let us know if you hit any! Once enough
          customers use the integration over time, the beta flag is removed.{" "}
          <a className="text-blue-40 hover:text-blue-60 cursor-pointer" onClick={showIntercom}>
            Request access
          </a>
        </p>
      </Alert>
    </IntegrationSettingsSectionContainer>
  );

  // If there's a field being edited, this component shows up with ability to edit it
  const editModal = (
    <MergeModal
      show={!!fieldBeingModified}
      onHide={() => setFieldBeingModified(null)}
      title={`Set ${integration.name} ${humanReadableFieldBeingModifiedName}`}
      centered
      bodyClassName="modal-narrow overflow-hidden"
      dialogClassName="modal-narrow"
    >
      <p>{fieldBeingModified?.description}</p>
      <Form>
        {fieldBeingModified?.field_type === "string" && (
          <Form.Control
            placeholder={`Enter ${integration.name} ${humanReadableFieldBeingModifiedName}`}
            onChange={(e) => setFieldBeingModifiedValue(e.currentTarget.value)}
          />
        )}
        {fieldBeingModified?.field_type === "file" && (
          <input
            type="file"
            placeholder={`Upload ${integration.name} ${humanReadableFieldBeingModifiedName}`}
            accept={fieldBeingModified?.field_name == "SSL Certificate File" ? ".pem" : ".key,.txt"}
            onChange={(e) => handleFileUpload(e.currentTarget.files)}
          />
        )}
        <SpinnerButton
          text="Save"
          className="btn-primary btn-block deprecated-mt-4"
          type="submit"
          onClick={(e) => handleFieldBeingModifiedSave(e)}
          isLoading={isSavingFieldBeingModified}
        />
      </Form>
    </MergeModal>
  );

  /*
   *
   * IF YOU EVER ADD ANY ADDITIONAL CONTENT TO THE INNER CONTENTS OF THE ACCORDION TOGGLE, PLEASE ADD IT TO THE BELOW
   * THIS USED TO NEVER BE THE CASE, BUT WE HAVE INTEGRATIONS THAT DO NOT HAVE CONTENT NOW POST SYNC-FREQUENCY CHANG
   *
   * WE DO NOT RENDER THE CHEVRON IN THE <INTEGRATIONSETTINGSROWOVERVIEW/> COMPONENT IF THERE ISN'T CONTENT
   *
   */
  const doesNotHaveContent =
    !organizationIntegrationSettings.is_beta &&
    !configurationNotesSection &&
    isSyncFrequencyBillingPlan;

  return (
    <>
      {editModal}
      <Accordion key={integration.name} defaultActiveKey={initiallyExpanded ? "0" : undefined}>
        <Card style={{ marginBottom: "20px" }}>
          <Accordion.Toggle
            onClick={() => setIsOpen(!isOpen)}
            as={Card.Body}
            className={classNames(configStatusFields.length > 0 && "clickable")}
            eventKey="0"
            style={{ padding: "16px" }}
          >
            <IntegrationSettingsRowOverview
              settings={integrationSettings}
              onIntegrationToggle={onIntegrationToggle}
              isOpen={isOpen}
              isBeta={organizationIntegrationSettings.is_beta}
              isToggleable={organizationIntegrationSettings.is_toggleable}
              doesNotHaveContent={doesNotHaveContent}
            />
          </Accordion.Toggle>
          {BetaAside}
          {configurationNotesSection}
          {!isSyncFrequencyBillingPlan && (
            <IntegrationSyncFrequencySection
              settings={integrationSettings}
              onIntegrationToggle={onIntegrationToggle}
            />
          )}
          <IntegrationSettingsConfigParametersSection
            integrationName={integration.name}
            organizationIntegrationSettingsId={integrationSettings.id}
            isCurrentlyPartnered={
              integrationSettings.partnership_type === PartnershipType.ORGANIZATION_PARTNERSHIP
            }
            isPartnershipCheckboxVisible={integrationSettings.partnership_available}
            modifyField={setFieldBeingModified}
            configStatusFields={configStatusFields}
            onIntegrationToggle={onIntegrationToggle}
          />
        </Card>
      </Accordion>
    </>
  );
};

export default IntegrationSettingsRow;
