import { ConditionType, ConditionValueType } from "../../../../../models/Entities";

export const makeCommaList = (dataList: Array<string>, useOr?: boolean): string =>
  dataList.reduce(
    (acc, val, ind, arr) =>
      acc +
      (ind === 0
        ? ""
        : ind === arr.length - 1
        ? (arr.length > 2 ? "," : "") + ` ${useOr ? "or" : "and"} `
        : ", ") +
      val,
    "",
  );

export const toDateFromValue = (value: ConditionValueType) => {
  try {
    if (typeof value === "string") {
      return new Date(value);
    }
    return undefined;
  } catch (_) {
    return undefined;
  }
};

export const isoValueForDateTimeLocalValue = (date: Date | undefined) => {
  if (date === undefined) {
    return undefined;
  }
  try {
    // YYYY-MM-DDTHH:mm:ss
    return new Date(date.getTime() + date.getTimezoneOffset() * -60 * 1000)
      .toISOString()
      .slice(0, 19);
  } catch (_) {
    return undefined;
  }
};

export const isoValueForDateValue = (date: Date | undefined) => {
  if (date === undefined) {
    return undefined;
  }
  try {
    // YYYY-MM-DD
    return isoValueForDateTimeLocalValue(date)?.slice(0, 10);
  } catch (_) {
    return undefined;
  }
};

export const formatValueForDisplay = (type: ConditionType, value: any): string => {
  switch (type) {
    case ConditionType.DATE:
      return new Date(value + "T00:00").toLocaleDateString();
    case ConditionType.DATE_TIME:
      return new Date(value).toLocaleString();
    case ConditionType.LIST_OF_STRINGS:
      return makeCommaList(value as Array<string>, true);
    default:
      return String(value);
  }
};
