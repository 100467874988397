import React from "react";
import styled, { css } from "styled-components";
import { format } from "date-fns";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as MergeLogoWord } from "../../../../../../assets/svg/logos/merge/logo-word.svg";
import ClickableContainer from "../../../../../shared-components/ClickableContainer";
import { LOG_DIRECTIONS } from "../../../../../../constants";
import { isWebhookLogEntry, LogType } from "../../../../IntegrationsManagementEntities";

const SeparatorMiddot = () => {
  return (
    <span className="deprecated-ml-3 deprecated-mr-3">
      <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="1.25" cy="2" r="1.25" fill="#8492A6" />
      </svg>
    </span>
  );
};

const ArrowPointerSVG = styled.svg<{ $isLeftFacing: boolean }>`
  width: 12px;
  height: 14px;
  fill: none;
  ${({ $isLeftFacing }) =>
    $isLeftFacing &&
    css`
      transform: rotate(180deg);
    `};
`;

const ArrowPointer = ({ isLeftFacing = false }: { isLeftFacing?: boolean }) => {
  return (
    <ArrowPointerSVG
      viewBox={isLeftFacing ? "0 0 12 10" : "0 0 12 14"}
      $isLeftFacing={isLeftFacing}
    >
      <rect x="0.571289" y="4.28516" width="5.72222" height="3.43333" fill="#8492A6" />
      <path d="M11.443 6.00156L6.29297 0.851562V11.1516L11.443 6.00156Z" fill="#8492A6" />
    </ArrowPointerSVG>
  );
};

const ArrowBase = () => {
  return (
    <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.321289" y="0.285156" width="6.25" height="3.43333" fill="#8492A6" />
    </svg>
  );
};

const ArrowMethod = ({
  direction,
  method,
  image,
  isWebhookLog,
}: {
  direction: string;
  method: string;
  image: string;
  isWebhookLog: boolean;
}) => {
  const methodText = method.toUpperCase();
  const isOutbound = direction === LOG_DIRECTIONS.LOG_OUTBOUND;
  return (
    <>
      {isWebhookLog ? (
        isOutbound ? (
          <>
            <img src={image} className="log-header-logo p0" />
            <span className="deprecated-mr-2 deprecated-ml-2 p0 log-request-arrow">
              <ArrowPointer isLeftFacing /> {methodText} <ArrowBase />
            </span>
            <MergeLogoWord className="log-header-logo p0" />
          </>
        ) : (
          <>
            <img src={image} className="log-header-logo p0" />
            <span className="deprecated-mr-2 deprecated-ml-2 p0 log-request-arrow">
              <ArrowBase /> {methodText} <ArrowPointer />
            </span>
            <MergeLogoWord className="log-header-logo p0" />
          </>
        )
      ) : isOutbound ? (
        <>
          <MergeLogoWord className="log-header-logo p0" />
          <span className="deprecated-mr-2 deprecated-ml-2 p0 log-request-arrow">
            <ArrowBase /> {methodText} <ArrowPointer />
          </span>
          <img src={image} className="log-header-logo p0" />
        </>
      ) : (
        <>
          <img src={image} className="log-header-logo p0" />
          <span className="deprecated-mr-2 deprecated-ml-2 p0 log-request-arrow">
            <ArrowBase /> {methodText} <ArrowPointer />
          </span>
          <MergeLogoWord className="log-header-logo p0" />
        </>
      )}
    </>
  );
};

type Props = {
  title: string;
  logEntry: LogType;
  onClickExit: () => void;
};

export const LogSidePanelBanner = ({ title, logEntry, onClickExit }: Props) => {
  const screenIsSmall = useMediaQuery({ query: "(max-width: 767.98px)" });
  const isWebhookLog = isWebhookLogEntry(logEntry);

  return (
    <>
      {screenIsSmall ? (
        <>
          <div className="log-entry-side-panel-header-title deprecated-mt-3 deprecated-mb-2">
            {title}
            <span>
              <ClickableContainer onClick={() => onClickExit()}>
                <span className="fe fe-x float-right" />
              </ClickableContainer>
            </span>
          </div>
          <div className="text-uppercase text-gray-50 deprecated-mb-2">
            User: {logEntry.linked_account.end_user.organization_name} <SeparatorMiddot />{" "}
            {format(new Date(logEntry.created_at), "MMM dd, hh:mm a")}
          </div>
        </>
      ) : (
        <div className="log-entry-side-panel-banner deprecated-mt-5 deprecated-mb-4">
          <div className="text-uppercase text-gray-50">
            {`${title} `}
            {logEntry.linked_account.integration.light_grey_image && (
              <>
                <SeparatorMiddot />{" "}
                <ArrowMethod
                  direction={logEntry.direction}
                  method={logEntry.method}
                  image={logEntry.linked_account.integration.light_grey_image}
                  isWebhookLog={isWebhookLog}
                />
              </>
            )}{" "}
          </div>
          <div className="text-uppercase text-gray-50 float-right">
            User: {logEntry.linked_account.end_user.organization_name} <SeparatorMiddot />{" "}
            {format(new Date(logEntry.created_at), "MMM dd, hh:mm a")}
            <SeparatorMiddot />
            <span>
              <ClickableContainer onClick={() => onClickExit()}>
                <span className="fe fe-x float-right" />
              </ClickableContainer>
            </span>
          </div>
        </div>
      )}
    </>
  );
};
