import React, { Fragment, ReactNode } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { EllipsesToggle } from "../shared-components/MergeToggles";
import { useActiveSubtab } from "./SubtabUtils";
import SubLeftNavigationBarCard, {
  SubLeftNavigationBarCardProps,
} from "./SubLeftNavigationBarCard";

export interface Subtab {
  /**
   * If provided, a react element icon to show to the left of the label
   */
  icon?: React.ReactElement;
  label: string;
  destination: string;
  divider?: boolean;
  disabled?: boolean;
  disabled_tip_message?: string;
}

interface Props {
  subtabs?: Array<Subtab>;
  cards?: Array<SubLeftNavigationBarCardProps>;
  children: ReactNode;
}

const SubLeftTab = ({ subtab }: { subtab: Subtab }) => (
  <NavLink className="nav-link sub-nav-link" exact to={subtab.destination}>
    {subtab.icon}
    {subtab.label}
  </NavLink>
);

function SubLeftNavigationDropdown({ subtabs }: { subtabs: Array<Subtab> }) {
  const activeSubtab = useActiveSubtab(subtabs);

  return (
    <Dropdown className="nav nav-tabs border-bottom-0">
      <div className="nav-item">
        <Dropdown.Toggle id="dropdown-custom-components" as={EllipsesToggle}>
          {activeSubtab && <SubLeftTab subtab={activeSubtab as Subtab} />}
        </Dropdown.Toggle>
      </div>
      <Dropdown.Menu>
        {subtabs.map((subtab) => (
          <Dropdown.Item key={subtab.label} className="dropdown-nav-link">
            {subtab.divider && <Dropdown.Divider />}
            <SubLeftTab subtab={subtab} />
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const SubLeftNavigationBar = ({ subtabs }: { subtabs?: Array<Subtab> }) => (
  <ul className="navbar-nav sub-navbar-nav">
    {subtabs &&
      subtabs.map((subtab) => {
        return (
          <Fragment key={subtab.label}>
            {subtab.divider && (
              <li>
                <hr className="deprecated-my-3" />
              </li>
            )}
            <li className="nav-item">
              <NavLink
                className={`nav-link sub-nav-link ${subtab.disabled ? "disabled" : ""}`}
                to={subtab.destination}
              >
                {subtab.icon}
                {subtab.label}
              </NavLink>
            </li>
          </Fragment>
        );
      })}
  </ul>
);

const SubLeftNavigationBarCards = ({
  cardsProps,
}: {
  cardsProps: Array<SubLeftNavigationBarCardProps>;
}) => {
  const divider = cardsProps.length > 0 ? <hr className="dropdown-divider" /> : null;
  const cards = (
    <div>
      {cardsProps &&
        cardsProps.map((cardProps) => {
          return <SubLeftNavigationBarCard {...cardProps} />;
        })}
    </div>
  );

  return (
    <>
      {divider}
      {cards}
    </>
  );
};

const SubLeftNavigationContainer = ({ subtabs, children, cards }: Props) => {
  return (
    <Row className="justify-content-center">
      <Col className="col-12 col-lg-10 col-xl-8">
        {subtabs && (
          <Row className="nav-xl-hide">
            <Col>
              <SubLeftNavigationDropdown subtabs={subtabs} />
            </Col>
          </Row>
        )}
        <Row>
          <Col className="col-3 nav-xl-show">
            <SubLeftNavigationBar subtabs={subtabs} />
            <SubLeftNavigationBarCards cardsProps={cards ?? []} />
          </Col>
          <Col className="col-xl-9 col-12">{children}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SubLeftNavigationContainer;
