import React from "react";
import LinkedAccountsPageData from "./LinkedAccountPageData";
import LinkedAccountsPageWrapper from "./LinkedAccountsPageWrapper";

const LinkedAccountsPage = () => {
  return (
    <LinkedAccountsPageWrapper>
      <LinkedAccountsPageData isTestAccounts={false} />
    </LinkedAccountsPageWrapper>
  );
};

export default LinkedAccountsPage;
