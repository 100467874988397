import React, { useCallback, useState } from "react";
import { Card, Col, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import { StatusToggle } from "../../shared-components/MergeToggles";
import { showErrorToast } from "../../shared-components/Toasts";
import WebhookReceiverSettingsGraphic from "./WebhookReceiverSettingsGraphic";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import {
  DOCS_WEBHOOKS_MERGE_TO_YOU_PATH,
  DOCS_WEBHOOKS_THIRD_PARTIES_TO_MERGE_PATH,
  openInNewTab,
} from "../../../router/RouterUtils";
import { BookOpen } from "lucide-react";

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const ConfigurationWebhookReceiversPage = () => {
  const { user, setUser, isUserPrivileged } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const areThirdPartyWebhooksEnabled = Boolean(productRestrictions?.third_party_webhooks_enabled);
  const [isEnabled, setIsEnabled] = useState<boolean>(user.organization.enable_auto_webhook_setup);

  let disabledTooltip = undefined;
  if (!isUserPrivileged) {
    disabledTooltip = (
      <Tooltip id="toggle-auto-webhook-setup-tooltip">
        Must be an admin user to change this setting
      </Tooltip>
    );
  } else if (!areThirdPartyWebhooksEnabled) {
    disabledTooltip = (
      <Tooltip id="toggle-auto-webhook-setup-tooltip">
        Upgrade your plan to enable third party webhooks in order to unlock this feature
      </Tooltip>
    );
  }

  const updateOrganizationEnableAutoWebhookSetup = useCallback(
    (newIsEnabled: boolean) => {
      fetchWithAuth({
        path: "/organizations/toggle-enable-auto-webhook-setup",
        method: "PATCH",
        body: {
          enable_auto_webhook_setup: newIsEnabled,
        },
        onResponse: (data) => {
          fetchCurrentUser(setUser);
          setIsEnabled(data.enable_auto_webhook_setup);
        },
        onError: () => {
          showErrorToast("Something went wrong, please check your connection and try again.");
        },
      });
    },
    [setUser],
  );

  return (
    <Row>
      <Col>
        <SectionHeaderWrapper
          title="Webhooks from third parties to Merge"
          headerRightHandContent={
            <SmallWhiteButton
              onClick={() => openInNewTab(DOCS_WEBHOOKS_THIRD_PARTIES_TO_MERGE_PATH)}
              leftIcon={<BookOpen size={12} />}
            >
              Webhook Docs
            </SmallWhiteButton>
          }
        >
          <Card>
            <Card.Body>
              <FlexRow>
                <SectionHeaderWrapper
                  title="Allow automatic webhook creation"
                  subtitle={
                    <div>
                      You can manage what events are receiving updates in each Linked Account's
                      webhook page.
                    </div>
                  }
                  titleHeading={TitleHeading.H3}
                  image={<WebhookReceiverSettingsGraphic />}
                />
                <StatusToggle
                  id="webhook-receivers-toggle"
                  isEnabled={isEnabled}
                  onChange={updateOrganizationEnableAutoWebhookSetup}
                  className="deprecated-ml-2"
                  statusTextClassName="deprecated-mr-3"
                  disableSwitch={disabledTooltip !== undefined}
                  tooltip={disabledTooltip}
                />
              </FlexRow>
            </Card.Body>
          </Card>
        </SectionHeaderWrapper>
      </Col>
    </Row>
  );
};

export default ConfigurationWebhookReceiversPage;
