import React from "react";
import styled from "styled-components";
import MergeLogoMark from "../../../logs/table/MergeLogoMark";
import { OrganizationAvatar, UserAvatarSize } from "../../../../shared-components/MergeAvatars";

type MergeToIntegrationMappingBarProps = {
  integrationName: string;
  integrationSquareImage: string | undefined;
  text?: string;
};
const MergeToIntegrationMappingBar = ({
  integrationName,
  integrationSquareImage,
  text,
}: MergeToIntegrationMappingBarProps) => {
  return (
    <div className="grid grid-cols-2">
      <div className="d-flex items-center font-semibold">
        <MergeLogoMark />
        <div className="ml-2">{text || "Common Model fields"}</div>
      </div>
      <div className="d-flex items-center font-semibold ml-[34px]">
        <OrganizationAvatar
          size={UserAvatarSize.xs}
          imageURL={integrationSquareImage}
          organizationName={integrationName}
          isCircle
          className="deprecated-mr-3 d-flex"
        />
        {integrationName} origin fields
      </div>
    </div>
  );
};
export default MergeToIntegrationMappingBar;
