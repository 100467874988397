import React from "react";
import { ToggleArea } from "../../../shared/IntegrationsManagementFilter";
import { Col, Form } from "react-bootstrap";

type IssueFilterProps = {
  label: string;
  onClick: () => void;
  isSelected: boolean;
};
const IssueFilter = ({ label, onClick, isSelected }: IssueFilterProps) => {
  return (
    <ToggleArea>
      <Col className="filter-selected-text float-right issue-mute-toggle-small d-flex align-items-center justify-content-between">
        <Form.Label className="deprecated-mr-3 mb-0 issue-mute-toggle-small-left">
          {label}
        </Form.Label>
        <Form.Check
          className="issue-mute-toggle-small-right"
          type="switch"
          id={`issue-toggle-${label}`}
          checked={isSelected}
          onClick={() => {
            onClick();
          }}
        />
      </Col>
    </ToggleArea>
  );
};
export default IssueFilter;
