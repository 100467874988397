import { ChevronLeft } from "lucide-react";
import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import { CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH } from "../../../../router/RouterUtils";
import IntegrationFieldMappingsHeader from "./IntegrationFieldMappingsHeader";
import FieldMappingLinkConfigurationOptions from "./FieldMappingLinkConfigurationOptions";
import styled from "styled-components";
import FieldMappingByIntegrationTable from "./FieldMappingByIntegrationTable";
import { FieldMappingTarget, AvailableFieldMappingIntegration } from "../../../../models/Entities";

const StyledSpan = styled.span`
  font-size: 12px;
  color: #075ff7;
`;

const IntegrationFieldMappingContainer = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: column;
`;

const FieldMappingsLinkContainer = styled.div`
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px 24px;
`;

const FieldMappingByIntegrationsContainer = styled.div`
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px 24px;
`;

type ConfigurationFieldMappintTargetFieldSettingsPageProps = {
  availableIntegrations: AvailableFieldMappingIntegration[];
  fieldMappingTarget: FieldMappingTarget;
  refreshFieldMappings: () => void;
  refreshFieldMappingsAndConfigurations: () => void;
};

const ConfigurationFieldMappingsTargetFieldSettingsPage = ({
  availableIntegrations,
  fieldMappingTarget,
  refreshFieldMappings,
  refreshFieldMappingsAndConfigurations,
}: ConfigurationFieldMappintTargetFieldSettingsPageProps) => {
  return (
    <div className="mb-16" id="field-mapping-target-field-settings-page">
      <Row>
        <ClickableContainer>
          <Link to={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>
            <ChevronLeft className="deprecated-mr-2" size={12} />
            <span className="text-[12px] font-semibold">Back to Target Fields</span>
          </Link>
        </ClickableContainer>
      </Row>
      {fieldMappingTarget && (
        <IntegrationFieldMappingContainer>
          <IntegrationFieldMappingsHeader
            fieldMappingTargetID={fieldMappingTarget.id}
            commonModel={fieldMappingTarget.common_model_name}
            title={fieldMappingTarget.field_key}
            category={fieldMappingTarget.category}
            description={fieldMappingTarget.field_description}
            refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
          />
          <FieldMappingsLinkContainer>
            <FieldMappingLinkConfigurationOptions
              fieldMappingTarget={fieldMappingTarget}
              refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
            />
          </FieldMappingsLinkContainer>
          <FieldMappingByIntegrationsContainer>
            <FieldMappingByIntegrationTable
              availableIntegrations={availableIntegrations}
              fieldMappingTargetId={fieldMappingTarget.id}
              fieldMappingTargetCommonModelName={fieldMappingTarget.common_model_name}
              fieldMappingTargetCategory={fieldMappingTarget.category}
              fieldMappingTargetFieldKey={fieldMappingTarget.field_key}
              refreshFieldMappings={refreshFieldMappings}
            />
          </FieldMappingByIntegrationsContainer>
        </IntegrationFieldMappingContainer>
      )}
    </div>
  );
};
export default ConfigurationFieldMappingsTargetFieldSettingsPage;
