import React from "react";
import { Filter, Search, Key, LayoutGrid } from "lucide-react";
import {
  CONFIGURATION_LINK_AUTHENTICATION_PATH,
  CONFIGURATION_LINK_INTEGRATIONS_PATH,
  CONFIGURATION_LINK_REQUESTED_DATA_PATH,
  CONFIGURATION_LINK_SELECTIVE_SYNC,
} from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../portal/SubLeftNavigationContainer";
import {
  canSeeRequestedDataSettings,
  canSeeSelectiveSyncSettings,
} from "../ConfigurationEligibilityHelpers";
import useAppContext from "../../context/useAppContext";

/**
 * Creates a wrapper for the Link configurations page
 */
const ConfigurationLinkPageWrapper = ({
  children,
}: Pick<React.ComponentProps<"div">, "children">) => {
  const { isUserPrivileged, user } = useAppContext();

  const iconProps = { size: 16, className: "deprecated-mr-3 deprecated-mb-1" };

  const subtabs = [
    {
      label: "Integrations",
      destination: CONFIGURATION_LINK_INTEGRATIONS_PATH,
      icon: <LayoutGrid {...iconProps} />,
    },
    {
      label: "Authentication",
      destination: CONFIGURATION_LINK_AUTHENTICATION_PATH,
      icon: <Key {...iconProps} />,
    },
  ];

  if (canSeeRequestedDataSettings(user)) {
    subtabs.push({
      label: "Requested Data",
      destination: CONFIGURATION_LINK_REQUESTED_DATA_PATH,
      icon: <Search {...iconProps} />,
    });
  }

  if (canSeeSelectiveSyncSettings(isUserPrivileged, user)) {
    subtabs.push({
      label: "Selective Sync",
      destination: CONFIGURATION_LINK_SELECTIVE_SYNC,
      icon: <Filter {...iconProps} />,
    });
  }

  return <SubLeftNavigationContainer subtabs={subtabs}>{children}</SubLeftNavigationContainer>;
};

export default ConfigurationLinkPageWrapper;
