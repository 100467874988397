import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { ArrowLeftRight } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface AsyncPassthroughWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: SelectedWebhookType | undefined;
}

function AsyncPassthroughWebhook(props: AsyncPassthroughWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Async Passthrough completed"
        description="Receive an alert when an Async passthrough request has resolved."
        icon={<ArrowLeftRight size={16} />}
        onClick={() => onSelectedWebhookTypeChange(SelectedWebhookType.ASYNC_PASSTHROUGH)}
        selected={selectedWebhookType === SelectedWebhookType.ASYNC_PASSTHROUGH}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default AsyncPassthroughWebhook;
