import React, { useEffect, useState } from "react";
import {
  fetchWithAuth,
  fetchCurrentUser,
  removeAuthTokenAndUserType,
} from "../../api-client/APIClient";
import { Alert, Collapse, Row, Col, Button } from "react-bootstrap";
import { PendingInvite } from "../../models/Entities";
import SpinnerButton from "../shared-components/SpinnerButton";
import MergeModal from "../shared-components/MergeModal";
import useAppContext from "../context/useAppContext";
import { showSuccessToast } from "../shared-components/Toasts";
import { LOGIN_PATH } from "../../router/RouterUtils";
import { SmallTextMutedParagraph } from "./MergeText";
import styled from "styled-components";
import { spectrum } from "../../styles/theme";
import { WarningCard } from "./NoticeCards";

const StyledWarningAlert = styled(Alert)`
  background: ${spectrum.yellow0};
  border: 1px solid ${spectrum.yellow10};
  border-radius: 8px;
  font-size: 13px;
`;

export const WarningAlert = (props: React.ComponentProps<typeof StyledWarningAlert>) => (
  <StyledWarningAlert {...props} />
);

export function DemoDataAlert() {
  const { user, showDemoDataAlert } = useAppContext();

  return (
    <Collapse in={user.is_demo && showDemoDataAlert}>
      <WarningCard>
        <p className="small">
          Your app is in demo mode and displaying sample data to preview dashboard functionality.
        </p>
      </WarningCard>
    </Collapse>
  );
}

interface InfoAlertProps {
  includeIcon?: boolean;
  dismissable?: boolean;
  className?: string;
  children: JSX.Element | string;
}

export function InfoAlert({ includeIcon, dismissable, className, children }: InfoAlertProps) {
  return (
    <Alert key="info-alert" variant="light" dismissible={dismissable} className={className}>
      <Row className="align-items-center">
        {includeIcon && (
          <Col className="col-auto">
            <i className="fe fe-info" />
          </Col>
        )}
        <Col>{children}</Col>
      </Row>
    </Alert>
  );
}

interface OrganizationForInvite {
  id: string;
  name: string;
  saml_provider?: {
    idp_sso_url: string;
  };
  saml_is_required: boolean;
}

export function OrganizationInviteAlert() {
  const { setUser, pendingOrganizationInvites, setPendingOrganizationInvites } = useAppContext();
  const [isLoadingAcceptOrganizationInvite, setIsLoadingAcceptOrganizationInvite] =
    useState<boolean>(false);
  const [isShowingConfirmLeaveOrganizationModal, setIsShowingConfirmLeaveOrganizationModal] =
    useState<boolean>(false);
  const [selectedPendingInvite, setSelectedPendingInvite] = useState<PendingInvite | undefined>();

  useEffect(() => {
    if (!pendingOrganizationInvites) {
      fetchPendingInvites();
    }
  }, []);

  function fetchPendingInvites() {
    fetchWithAuth({
      path: `/users/me/invites`,
      method: "GET",
      onResponse: (data: any) => {
        setPendingOrganizationInvites(data.results);
      },
    });
  }

  function acceptPendingInvite(pendingInvite: PendingInvite | undefined) {
    if (pendingInvite) {
      setIsLoadingAcceptOrganizationInvite(true);
      fetchWithAuth({
        path: `/users/me/invites/${pendingInvite.id}/accept`,
        method: "POST",
        onResponse: (data: OrganizationForInvite) => {
          if (data.saml_is_required) {
            // User must log in with SAML for the org they just joined.
            removeAuthTokenAndUserType();
            window.location.href = data.saml_provider?.idp_sso_url || LOGIN_PATH;
            return;
          }
          fetchCurrentUser(setUser);
          fetchPendingInvites();
          setIsShowingConfirmLeaveOrganizationModal(false);
          setIsLoadingAcceptOrganizationInvite(false);
          showSuccessToast(`Successfully joined ${pendingInvite.organization.name}!`);
        },
      });
    }
  }

  function rejectPendingInvite(pendingInvite: PendingInvite) {
    fetchWithAuth({
      path: `/users/me/invites/${pendingInvite.id}/reject`,
      method: "POST",
      onResponse: () => {
        fetchPendingInvites();
        showSuccessToast(`Successfully rejected ${pendingInvite.organization.name}'s invite!`);
      },
    });
  }

  return (
    <>
      <MergeModal
        show={!!isShowingConfirmLeaveOrganizationModal}
        title="Confirm Leave Organization"
        centered
        bodyClassName="modal-extra-narrow overflow-hidden"
        dialogClassName="modal-extra-narrow"
        onHide={() => setIsShowingConfirmLeaveOrganizationModal(false)}
      >
        <Row>
          <Col>
            <b>Are you sure you want to exit this organization?</b>
            <SmallTextMutedParagraph className="deprecated-mt-3">
              If you are the only user in this organization you will need to contact us to be
              re-added.
            </SmallTextMutedParagraph>
          </Col>
        </Row>
        <Row>
          <Col>
            <SpinnerButton
              className="btn-block deprecated-mt-4 btn-danger"
              text="Leave organization"
              isLoading={isLoadingAcceptOrganizationInvite}
              onClick={() => acceptPendingInvite(selectedPendingInvite)}
            />

            <Button
              variant="link"
              className="btn-block deprecated-mt-2 border-0 text-gray-50"
              onClick={() => (
                setIsShowingConfirmLeaveOrganizationModal(false),
                setSelectedPendingInvite(undefined)
              )}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </MergeModal>

      {pendingOrganizationInvites?.map((pendingInvite: PendingInvite) => (
        <Alert key={pendingInvite.id} className="bg-white btn-selected deprecated-my-4">
          <Row>
            <Col className="my-auto">
              <b>{pendingInvite.organization.name}</b> invited you to join their organization.
            </Col>
            <Col className="col-auto">
              <Button
                size="sm"
                variant="primary"
                onClick={() => (
                  setIsShowingConfirmLeaveOrganizationModal(true),
                  setSelectedPendingInvite(pendingInvite)
                )}
              >
                Accept invite
              </Button>
              <Button
                size="sm"
                variant="white"
                className="deprecated-ml-2"
                onClick={() => rejectPendingInvite(pendingInvite)}
              >
                Reject invite
              </Button>
            </Col>
          </Row>
        </Alert>
      ))}
    </>
  );
}
