import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import { fetchWithAuth, onLogout, postUpgradeInterest } from "../../api-client/APIClient";
import { ReactComponent as Logo } from "../../assets/svg/logos/merge/logo.svg";
import { palette } from "../../styles/theme";
import { Box, Globe, Key, FileSearch, Settings2, Library, Gem, BookOpen } from "lucide-react";
import styled from "styled-components";
import { ReactComponent as Sparkles } from "../../assets/svg/sparkles.svg";
import {
  API_TESTER_PATH,
  BILLING_PATH,
  CONFIGURATION_PATH,
  ONBOARDING_PATH,
  ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
  ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
  ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
  ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
  ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
  ONBOARDING_ADVANCED_WEBHOOKS_PATH,
  // ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH,
  ONBOARDING_ADVANCED_METADATA_PATH,
  ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
  // ONBOARDING_ADVANCED_SENSITIVE_DATA_PATH,
  // ONBOARDING_ADVANCED_TROUBLESHOOTING_PATH,
  CONFIGURATION_INTEGRATIONS_PATH,
  CONFIGURATION_COMMON_MODELS_PATH,
  DASHBOARD_PATH,
  DOCS_BASE_PATH,
  ISSUES_PATH,
  LINKED_ACCOUNTS_PATH,
  LOGIN_PATH,
  LOGS_PATH,
  MERGE_ADMIN_PATH,
  MERGE_HELP_CENTER_PATH,
  PROFILE_PATH,
  API_KEYS_PATH,
} from "../../router/RouterUtils";
import useAppContext from "../context/useAppContext";
import { UserAvatar, UserAvatarSize } from "../shared-components/MergeAvatars";
import { showErrorToast } from "../shared-components/Toasts";
import { HeaderPretitle } from "../shared-components/MergeText";
import LiveWaitingIndicator from "../shared-components/LiveWaitingIndicator";
import { DashboardOnboardingStatus } from "../get-started/DashboardOnboardingWrapper";
import { INVOICE_FE_DISABLED_STATUSES } from "../../models/Entities";
import { isRouteDisabled } from "../shared-components/utils/SharedComponentUtils";
import useCommandKPressed from "../../hooks/useCommandKPressed";
import MergeMendableInPlace from "../mendable/MergeMendableInPlace";
import UpsellButton from "../shared-components/UpsellButton";
import { isBillingPlanFreeLaunch } from "../../components/settings/billing/BillingUtils";
import MergeFlagWrapper from "../../components/shared-components/MergeFlagWrapper";
import { MergeFlagFeature } from "../../components/shared-components/hooks/useMergeFlag";
import { userEventTracker } from "../settings/billing/components/DashboardBanners";

type Props = {
  isAnnouncementBannerVisible: boolean;
};

const StyledHeaderPretitle = styled(HeaderPretitle)`
  overflow: visible;
`;

const Divider = styled.hr`
  height: 0;
  margin: 0.75rem 0;
  overflow: hidden;
  border-top: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
`;

const ShadowNavLink = styled(NavLink)`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  transition:
    box-shadow 100ms linear,
    background 100ms linear,
    color 100ms linear;

  && {
    &.nav-link.active {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      background: #f5f8ff;
    }
    &.nav-link:hover {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
  }
`;

const StyledNavUL = styled.ul`
  margin-bottom: 32px;
`;

const HotKeyContainer = styled.div`
  border-radius: 23px;
  border: 0.5px solid var(--lm-gray-20, #dce2ea);
  line-height: 16px !important;
`;
interface DisablableNavLinkProps extends NavLinkProps {
  disabled?: boolean;
  children: React.ReactNode;
}

export const DisablableNavLink = ({ disabled, children, ...props }: DisablableNavLinkProps) => {
  return (
    <>
      <NavLink className={`nav-link ${disabled ? "disabled" : ""}`} {...props}>
        {children}
      </NavLink>
    </>
  );
};

function LeftNavigationBar({ isAnnouncementBannerVisible }: Props) {
  const { setUser, user, setShowDemoDataAlert, requestedPlanUpgrade, setRequestedPlanUpgrade } =
    useAppContext();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const [showMendableModal, setShowMendableModal] = useState(false);
  const dashboardDisabledForBilling =
    user.organization.invoice_overdue_status &&
    INVOICE_FE_DISABLED_STATUSES.includes(user.organization.invoice_overdue_status);

  const [fetchedOnboardingStatus, setFetchedOnboardingStatus] =
    useState<DashboardOnboardingStatus | null>(null);

  const shouldHomePageDefaultToDashboard =
    (fetchedOnboardingStatus?.has_made_request &&
      fetchedOnboardingStatus?.has_successfully_linked_account &&
      fetchedOnboardingStatus?.has_opened_production_link &&
      fetchedOnboardingStatus?.has_pulled_common_model_data) ??
    false;

  const commandKPressed = useCommandKPressed();
  useEffect(() => {
    if (commandKPressed) {
      setShowMendableModal(true);
    }
  }, [commandKPressed]);

  const isLoadingOnboardingStatus = !fetchedOnboardingStatus;

  useEffect(() => {
    fetchWithAuth({
      path: "/users/me/onboarding-status",
      method: "GET",
      onResponse: (data: DashboardOnboardingStatus) => {
        setFetchedOnboardingStatus(data);
      },
    });
  }, []);

  function toggleUserDemoStatus(status: boolean) {
    const formData = {
      is_demo: status,
    };

    fetchWithAuth({
      path: "/users/me",
      method: "PATCH",
      body: formData,
      onResponse: (data) => {
        setUser(data.user);
        setShowDemoDataAlert(status);
      },
    });
  }

  const logout = () =>
    onLogout({ onError: () => showErrorToast(`Failed to logout. Please try again.`) });

  return (
    <>
      {
        <MergeMendableInPlace
          showMendableModal={showMendableModal}
          setShowMendableModal={setShowMendableModal}
        />
      }
      <nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light" id="sidebar">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#sidebarCollapse"
            aria-controls="sidebarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowMobileNav(!showMobileNav)}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <NavLink className="left-nav-logo" exact to="/">
            <Logo />
          </NavLink>

          <div className="navbar-user d-md-none">
            <div className="dropdown">
              <a
                href="#"
                id="sidebarIcon"
                className="dropdown-toggle"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => setShowMobileDropdown(!showMobileDropdown)}
              >
                <UserAvatar user={user} size={UserAvatarSize.sm} />
              </a>

              <div
                className={classNames("dropdown-menu", "dropdown-menu-right", {
                  show: showMobileDropdown,
                })}
                aria-labelledby="sidebarIcon"
              >
                <Link to={PROFILE_PATH} className="dropdown-item d-flex align-items-center">
                  <i className="fe fe-settings" style={{ marginRight: 10 }} />
                  Settings
                </Link>
                <hr className="dropdown-divider" />
                <NavLink
                  className="dropdown-item d-flex align-items-center"
                  to={LOGIN_PATH}
                  onClick={logout}
                >
                  <i className="fe fe-log-out" style={{ marginRight: 10 }} />
                  Logout
                </NavLink>
              </div>
            </div>
          </div>

          <div
            className={classNames("collapse", "navbar-collapse", "pb-0", {
              show: showMobileNav,
              "fit-announcement-banner": isAnnouncementBannerVisible,
            })}
            id="sidebarCollapse"
          >
            {!isLoadingOnboardingStatus && !shouldHomePageDefaultToDashboard && (
              <StyledNavUL className="navbar-nav">
                <li className="nav-item">
                  <ShadowNavLink
                    className="nav-link"
                    to={ONBOARDING_PATH}
                    isActive={(_, location) => {
                      const path = location.pathname;

                      return (
                        [
                          ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
                          ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
                          ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
                          ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
                          ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
                          ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
                          ONBOARDING_ADVANCED_WEBHOOKS_PATH,
                          ONBOARDING_ADVANCED_METADATA_PATH,
                          ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
                        ].includes(path) ||
                        (!isLoadingOnboardingStatus &&
                          !shouldHomePageDefaultToDashboard &&
                          path == "/")
                      );
                    }}
                  >
                    <i className="fe fe-map" /> Get Started
                    <i
                      className="text-right mx-auto"
                      style={{ display: "flex", alignItems: "center" }}
                    />
                    {shouldHomePageDefaultToDashboard ? (
                      <LiveWaitingIndicator color="green" />
                    ) : (
                      <LiveWaitingIndicator color="red" />
                    )}
                  </ShadowNavLink>
                </li>
              </StyledNavUL>
            )}
            <ul className="navbar-nav h-100">
              <StyledHeaderPretitle className="deprecated-mx-3 deprecated-px-3">
                Overview
              </StyledHeaderPretitle>

              <li className="nav-item">
                <DisablableNavLink
                  exact
                  to={DASHBOARD_PATH}
                  disabled={isRouteDisabled(user, DASHBOARD_PATH)}
                  isActive={(_, location) => {
                    const path = location.pathname;

                    return (
                      path == DASHBOARD_PATH ||
                      (!isLoadingOnboardingStatus &&
                        shouldHomePageDefaultToDashboard &&
                        !isRouteDisabled(user, DASHBOARD_PATH) &&
                        path == "/")
                    );
                  }}
                >
                  <i className="fe fe-bar-chart-2" /> Dashboard
                </DisablableNavLink>
              </li>

              <li className="nav-item">
                <DisablableNavLink
                  to={LINKED_ACCOUNTS_PATH}
                  disabled={isRouteDisabled(user, LINKED_ACCOUNTS_PATH)}
                >
                  <i className="fe fe-link" /> Linked Accounts
                </DisablableNavLink>
              </li>

              <li className="nav-item">
                <DisablableNavLink to={LOGS_PATH} disabled={isRouteDisabled(user, LOGS_PATH)}>
                  <FileSearch
                    color={isRouteDisabled(user, LOGS_PATH) ? "#939aa5" : palette.black}
                    size={16}
                  />{" "}
                  Logs
                </DisablableNavLink>
              </li>

              <li className="nav-item">
                <DisablableNavLink disabled={isRouteDisabled(user, ISSUES_PATH)} to={ISSUES_PATH}>
                  <i className="fe fe-alert-circle" /> Issues
                </DisablableNavLink>
              </li>

              <StyledHeaderPretitle className="deprecated-mx-3 deprecated-px-3 deprecated-pt-2">
                Configure
              </StyledHeaderPretitle>

              <li className="nav-item">
                <DisablableNavLink
                  disabled={isRouteDisabled(user, CONFIGURATION_INTEGRATIONS_PATH)}
                  to={CONFIGURATION_INTEGRATIONS_PATH}
                >
                  <Globe
                    color={
                      isRouteDisabled(user, CONFIGURATION_INTEGRATIONS_PATH)
                        ? "#939aa5"
                        : palette.black
                    }
                    size={16}
                  />{" "}
                  Integrations
                </DisablableNavLink>
              </li>

              <li className="nav-item">
                <DisablableNavLink
                  disabled={isRouteDisabled(user, CONFIGURATION_COMMON_MODELS_PATH)}
                  to={CONFIGURATION_COMMON_MODELS_PATH}
                >
                  <Box
                    color={
                      isRouteDisabled(user, CONFIGURATION_COMMON_MODELS_PATH)
                        ? "#939aa5"
                        : palette.black
                    }
                    size={16}
                  />{" "}
                  Common Models
                </DisablableNavLink>
              </li>

              <li className="nav-item">
                <DisablableNavLink
                  disabled={isRouteDisabled(user, CONFIGURATION_PATH)}
                  to={CONFIGURATION_PATH}
                >
                  <Settings2
                    color={isRouteDisabled(user, CONFIGURATION_PATH) ? "#939aa5" : palette.black}
                    size={16}
                  />{" "}
                  Advanced
                </DisablableNavLink>
              </li>

              <StyledHeaderPretitle className="mx-3 px-3 pt-2">Resources</StyledHeaderPretitle>
              {!isLoadingOnboardingStatus && shouldHomePageDefaultToDashboard && (
                <li className="nav-item">
                  <DisablableNavLink
                    disabled={isRouteDisabled(user, ONBOARDING_PATH)}
                    to={ONBOARDING_PATH}
                    isActive={(_, location) => {
                      const path = location.pathname;

                      return (
                        path == ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH ||
                        path == ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH ||
                        path == ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH ||
                        path == ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH
                      );
                    }}
                  >
                    <i className="fe fe-map" /> Get Started
                    <i
                      className="text-right mx-auto"
                      style={{ display: "flex", alignItems: "center" }}
                    />
                    {!isRouteDisabled(user, ONBOARDING_PATH) && (
                      <>
                        {shouldHomePageDefaultToDashboard ? (
                          <LiveWaitingIndicator color="green" />
                        ) : (
                          <LiveWaitingIndicator color="red" />
                        )}
                      </>
                    )}
                  </DisablableNavLink>
                </li>
              )}
              <li className="nav-item">
                <DisablableNavLink
                  disabled={isRouteDisabled(user, API_KEYS_PATH)}
                  to={API_KEYS_PATH}
                >
                  <Key
                    color={isRouteDisabled(user, API_KEYS_PATH) ? "#939aa5" : palette.black}
                    size={16}
                  />{" "}
                  API Keys
                </DisablableNavLink>
              </li>
              <li className="nav-item">
                <DisablableNavLink
                  disabled={isRouteDisabled(user, API_TESTER_PATH)}
                  to={API_TESTER_PATH}
                >
                  <i className="fe fe-code" /> API Tester
                </DisablableNavLink>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  setShowMendableModal(true);
                }}
              >
                <div className="nav-link">
                  <Sparkles />
                  <span>Ask AI</span>
                  <span className="text-right ml-auto">
                    <HotKeyContainer className="ml-2.5 pl-1.5 pr-1.5 text-gray-60 text-xs">
                      ⌘K
                    </HotKeyContainer>
                  </span>
                </div>
              </li>

              <MergeFlagWrapper
                feature={MergeFlagFeature.FEATURE_DISCOVERY_ENABLED}
                organizationId={user.organization.id}
              >
                {isBillingPlanFreeLaunch(
                  user.organization.organization_billing_plan?.billing_plan,
                ) && (
                  <li className="nav-item mt-auto flex items-center  mb-3">
                    <UpsellButton
                      className="mr-3 ml-3"
                      onClick={() => {
                        postUpgradeInterest(user, requestedPlanUpgrade, setRequestedPlanUpgrade);
                        userEventTracker({
                          event_name_input: "upsell_button_left_nav",
                          impressions_input: 0,
                          dismisses_input: 0,
                          clicks_input: 1,
                        });
                      }}
                      buttonContent={
                        requestedPlanUpgrade ? (
                          <span>
                            <Gem size={10} className="mr-1 mb-1" />
                            Upgrade requested
                          </span>
                        ) : (
                          <span>
                            <Gem size={10} className="mr-1 mb-1" />
                            Request upgrade
                          </span>
                        )
                      }
                      buttonColor={requestedPlanUpgrade ? "fadedGradientGold" : "gradientGold"}
                      hasAdvancedHoverState={false}
                      borderRadius="6px"
                      buttonIsSelected={requestedPlanUpgrade}
                      id="upsell_button_left_nav"
                    />
                  </li>
                )}
              </MergeFlagWrapper>
            </ul>

            <div className="navbar-user d-none d-md-flex" id="sidebarUser">
              <div className="dropup">
                <a
                  href="#"
                  id="sidebarIconCopy"
                  className="dropdown-toggle left-nav-dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="user-preview">
                    <UserAvatar user={user} size={UserAvatarSize.sm} />
                    <div className="user-preview-text">
                      <div className="username">{user.name ? user.name : user.email}</div>
                      <StyledHeaderPretitle className="mb-0 truncate" style={{ maxWidth: 120 }}>
                        {user.organization?.name ? user.organization?.name : null}
                      </StyledHeaderPretitle>
                    </div>
                  </div>
                  <i className="fe fe-chevron-right" />
                </a>

                <div className="dropdown-menu" aria-labelledby="sidebarIconCopy">
                  <Link
                    to={dashboardDisabledForBilling ? BILLING_PATH : PROFILE_PATH}
                    className="dropdown-item d-flex align-items-center"
                  >
                    <i className="fe fe-settings mr-2.5" />
                    Settings
                  </Link>
                  <a
                    href={DOCS_BASE_PATH}
                    className="dropdown-item d-flex align-items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <BookOpen size={12} className="mr-2.5 mb-1" />
                      Docs
                    </span>
                    <i className="fe fe-arrow-up-right text-right ml-auto" />
                  </a>
                  <a
                    href={MERGE_HELP_CENTER_PATH}
                    className="dropdown-item d-flex align-items-center"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      <Library size={12} className="mr-2.5 mb-1" />
                      Help Center
                    </span>
                    <i className="fe fe-arrow-up-right text-right ml-auto" />
                  </a>
                  {user.is_staff && (
                    <>
                      <a
                        href={MERGE_ADMIN_PATH}
                        className="dropdown-item d-flex align-items-center"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fe fe-grid" style={{ marginRight: 10 }} />
                        Admin
                      </a>
                    </>
                  )}
                  <li className="flex dropdown-item items-center -mb-3">
                    <label
                      className="w-full flex justify-between items-center cursor-pointer"
                      htmlFor="demo-data-toggle"
                    >
                      <div className="flex items-center">
                        <i className="fe fe-bar-chart mr-2" />
                        Demo Data
                      </div>
                      <span>
                        <Form.Check
                          type="switch"
                          id="demo-data-toggle"
                          name="demo-data-toggle"
                          onChange={() => toggleUserDemoStatus(!user.is_demo)}
                          checked={user.is_demo}
                        />
                      </span>
                    </label>
                  </li>
                  <hr className="dropdown-divider" />
                  <NavLink
                    className="dropdown-item d-flex align-items-center"
                    to={LOGIN_PATH}
                    onClick={logout}
                  >
                    <i className="fe fe-log-out" style={{ marginRight: 10 }} />
                    Logout
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default LeftNavigationBar;
