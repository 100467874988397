import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CookieSettings from "./CookieSettings";
import * as serviceWorker from "./serviceWorker";
//@ts-ignore
import TagManager from "./react-gtm-module-nonce";

CookieSettings.initialize();

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
// @ts-ignore
gtag("js", new Date());
// @ts-ignore
gtag("config", "UA-164577142-1");

const nonce = document.getElementById("csp-policy")?.getAttribute("nonce-id") || "";

const tagManagerArgs = {
  gtmId: "GTM-WZ3TTB4",
  nonce: nonce,
};

TagManager.initialize(tagManagerArgs);

if (process.env.REACT_APP_SENTRY_RELEASE) {
  Sentry.init({
    dsn: "https://dd995e6d617d4e7ba16c3b02da283493@o469727.ingest.sentry.io/5593679",
    environment: process.env.REACT_APP_MERGE_ENV,
    autoSessionTracking: true,
    integrations: [new Sentry.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
