import React, { useState, Dispatch, SetStateAction } from "react";
import { ArrowLeft } from "lucide-react";
import MergeTypeahead from "../../../../../shared-components/MergeTypeahead";
import { Box, User } from "lucide-react";
import FieldMappingDropdownItem from "../FieldMappingDropdownItem";
import { createFieldMappingOptions } from "../CreateLinkedAccountFieldMappingPage";
import {
  FieldMappingTargetContainer,
  IconContainer,
  LinkedAccountFieldMappingContainer,
} from "../FieldMappingInstanceCard";
import {
  FieldMappingCreationAndEditDict,
  FieldMappingMetaResponse,
  OverriddenCommonModelInstance,
  ModelAndFieldOverrideDict,
  FieldMappingOptions,
  MergeTypeaheadOption,
  FieldDefinition,
  FieldMappingCommonModelConfiguration,
} from "../../../../../../models/Entities";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import CommonModelFieldDropdown from "./CommonModelFieldDropdown";
import { createOverriddenCommonModelInstance } from "../../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import SaveOverrideModal from "./SaveOverrideModal";
import { showErrorToast } from "../../../../../shared-components/Toasts";
import { Schemas, Schema } from "../../../../../configuration/common-models/CommonModelToggleTypes";
import { getFieldsWithExistingTargets } from "../../../../../configuration/integrations/field-mappings/ConfigurationCommonModelOverridesSection";
const getFieldOverrideOptions = (
  selectedModel: string,
  avaialbleModelAndFieldOverrides: ModelAndFieldOverrideDict,
  modelSchemas: Schema,
  fieldsWithExistingTargets: Set<string>,
) => {
  const availableModelOverrideFields =
    avaialbleModelAndFieldOverrides[selectedModel]["available_fields"];
  return availableModelOverrideFields.reduce((acc: FieldDefinition[], modelField: string) => {
    if (fieldsWithExistingTargets.has(modelField)) {
      return acc;
    }
    const parameters = modelSchemas?.parameters! || {};
    const isFieldInfoAvailable = modelField in parameters ? true : false;
    const fieldInfo = {
      description: isFieldInfoAvailable ? parameters[modelField].description : undefined,
      type: isFieldInfoAvailable ? parameters[modelField].type : undefined,
    };
    acc.push({
      title: modelField,
      type: fieldInfo?.type || "String",
      description: fieldInfo?.description || "",
    });
    return acc;
  }, []);
};
const getModelAndFieldOverrideOptions = (
  modelAndFieldOverrides: ModelAndFieldOverrideDict | undefined,
  commonModelsWithRemoteDataEnabled: Set<string>,
) => {
  if (!modelAndFieldOverrides) {
    return [];
  }
  return Object.keys(modelAndFieldOverrides).reduce(
    (acc: MergeTypeaheadOption[], modelAndFieldOverride) => {
      const commonModelName = modelAndFieldOverrides[modelAndFieldOverride]["common_model_name"];
      if (commonModelsWithRemoteDataEnabled.has(commonModelName)) {
        acc.push({
          id: modelAndFieldOverride,
          label: modelAndFieldOverrides[modelAndFieldOverride]["common_model_name"],
        });
      }
      return acc;
    },
    [],
  );
};

export const filterFieldMappingOptions = (
  selectedModel: string,
  fieldMappingOptions: FieldMappingOptions,
  selectedFieldType: any,
) => {
  if (!selectedFieldType) {
    return [];
  }
  const fieldMappingDropdownOptions = createFieldMappingOptions(fieldMappingOptions, selectedModel);

  return fieldMappingDropdownOptions.filter((fieldMappingOption) => {
    return (
      fieldMappingOption.type.toLowerCase() == selectedFieldType.toLowerCase() ||
      (fieldMappingOption.type.toLowerCase() == "string" &&
        selectedFieldType.toLowerCase() == "enum")
    );
  });
};
type CommonModelOverrideFormProps = {
  linkedAccountID: string;
  endUserName: string;
  setIsCommonModelOverrideFormOpen: Dispatch<SetStateAction<boolean>>;
  customMappingOptions: FieldMappingMetaResponse | undefined;
  avaialbleModelAndFieldOverrides: ModelAndFieldOverrideDict;
  schemas: Schemas;
  commonModelsWithRemoteDataEnabled: Set<string>;
  setCommonModelOverrides: Dispatch<SetStateAction<OverriddenCommonModelInstance[]>>;
  allOverrideTargets: any;
};
const CommonModelOverrideForm = ({
  linkedAccountID,
  endUserName,
  setIsCommonModelOverrideFormOpen,
  customMappingOptions,
  avaialbleModelAndFieldOverrides,
  schemas,
  commonModelsWithRemoteDataEnabled,
  setCommonModelOverrides,
  allOverrideTargets,
}: CommonModelOverrideFormProps) => {
  const [selectedCommonModel, setSelectedCommonModel] = useState<MergeTypeaheadOption | undefined>(
    undefined,
  );
  const [selectedField, setSelectedField] = useState<any | undefined>(undefined);
  const [selectedOverride, setSelectedOverride] = useState<
    FieldMappingCreationAndEditDict | undefined
  >(undefined);

  const [isConfirmModalShown, setIsConfirmModalShown] = useState<boolean>(false);

  const availableFieldMappings = customMappingOptions?.available_field_mappings;

  const avaialbleOverrideModelOptions = getModelAndFieldOverrideOptions(
    avaialbleModelAndFieldOverrides,
    commonModelsWithRemoteDataEnabled,
  );

  const isFieldOptionEnabled = selectedCommonModel ? true : false;
  const isOverrideOptionEnabled = selectedCommonModel && selectedField ? true : false;

  const isFormReadyForSave = selectedCommonModel && selectedField && selectedOverride;

  const fieldsWithExistingTargets = selectedCommonModel
    ? getFieldsWithExistingTargets(allOverrideTargets, selectedCommonModel.label)
    : new Set();

  const modelSchemas = selectedCommonModel
    ? selectedCommonModel.label in schemas
      ? schemas[selectedCommonModel.label]
      : undefined
    : undefined;

  const onModalSave = () => {
    if (selectedOverride && selectedField && selectedCommonModel) {
      const data = {
        linked_account_id: linkedAccountID,
        common_model_id: selectedCommonModel.id,
        overridden_field: selectedField.title,
        field_traversal_path: selectedOverride.traversal_path.split("."),
        api_endpoint_id: selectedOverride?.api_endpoint_id,
        display_name: selectedOverride?.display_name,
        origin_type: selectedOverride.type,
      };

      const onSuccess = (data: OverriddenCommonModelInstance) => {
        setCommonModelOverrides((prevOverrides: OverriddenCommonModelInstance[]) => [
          ...prevOverrides,
          data,
        ]);
        setIsConfirmModalShown(false);
        setIsCommonModelOverrideFormOpen(false);
      };

      createOverriddenCommonModelInstance(data, onSuccess, () => {
        showErrorToast("Unable to create Common Model override");
      });
    }
  };

  const onModalClose = () => {
    setIsConfirmModalShown(false);
  };
  return (
    <>
      {isConfirmModalShown && (
        <SaveOverrideModal
          onHide={onModalClose}
          onConfirm={onModalSave}
          text={`Are you sure you want to override the Merge default values for this Common Model field? You will no longer be able to POST data to this field. This will affect all data for ${endUserName}.`}
        />
      )}
      <LinkedAccountFieldMappingContainer className="mb-6">
        <FieldMappingTargetContainer className="d-flex flex-column">
          <div className="pl-5 pr-5 pt-4 pb-4">
            <MergeTypeahead
              options={avaialbleOverrideModelOptions}
              multiple={false}
              placeholder="Select model..."
              onChange={(selectedModel) => {
                if (selectedModel?.length > 0) {
                  setSelectedCommonModel(selectedModel[0]);
                } else {
                  setSelectedCommonModel(undefined);
                  setSelectedField(undefined);
                }
              }}
              renderMenuItemChildren={(commonModelOption) => {
                return (
                  <div className="font-mono text-gray-90 font-medium ">
                    {commonModelOption.label}
                  </div>
                );
              }}
            />
            <div className="mt-3">
              <MergeTypeahead
                options={
                  isFieldOptionEnabled
                    ? getFieldOverrideOptions(
                        selectedCommonModel!.id,
                        avaialbleModelAndFieldOverrides,
                        modelSchemas,
                        fieldsWithExistingTargets as Set<string>,
                      )
                    : []
                }
                onChange={(selectedField) => {
                  selectedField.length > 0
                    ? setSelectedField(selectedField[0])
                    : setSelectedField(undefined);
                }}
                selected={selectedField ? [selectedField!] : []}
                multiple={false}
                disabled={!isFieldOptionEnabled}
                isDarkBar={!isFieldOptionEnabled}
                includeChevronDown={isFieldOptionEnabled}
                placeholder={selectedCommonModel ? "Select a model..." : "Select a field"}
                labelKey={(fieldOption) => fieldOption.title}
                renderMenuItemChildren={(fieldOption) => {
                  return (
                    <CommonModelFieldDropdown
                      fieldName={fieldOption.title}
                      fieldType={fieldOption.type}
                      fieldDescription={fieldOption.description}
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="bg-blue-0 text-blue-40 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg leading-[14px] mt-auto">
            <Box size={12} className="mr-2.5" />
            Common Model field
          </div>
        </FieldMappingTargetContainer>
        <IconContainer>
          <ArrowLeft size={20} />
        </IconContainer>
        <FieldMappingTargetContainer className="d-flex flex-column">
          <div className="pl-5 pr-5 pt-4 pb-4 flex-grow">
            <div className="cursor-not-allowed">
              <MergeTypeahead
                id="typeahead-override"
                options={
                  isOverrideOptionEnabled && availableFieldMappings
                    ? filterFieldMappingOptions(
                        selectedCommonModel!.label,
                        availableFieldMappings,
                        selectedField.type,
                      )
                    : []
                }
                onChange={(selectedOverride) => {
                  selectedOverride.length > 0
                    ? setSelectedOverride(selectedOverride[0])
                    : setSelectedOverride(undefined);
                }}
                selected={selectedOverride ? [selectedOverride] : []}
                disabled={!isOverrideOptionEnabled}
                isDarkBar={!isOverrideOptionEnabled}
                includeChevronDown={isOverrideOptionEnabled}
                placeholder={
                  selectedCommonModel && selectedField
                    ? "Search fields..."
                    : "Select a model and field..."
                }
                multiple={false}
                labelKey={(option) => {
                  return option?.display_name ?? "";
                }}
                renderMenuItemChildren={(fieldMapping) => {
                  return <FieldMappingDropdownItem fieldMapping={fieldMapping} />;
                }}
              />
            </div>
            <div className="flex items-center justify-content-end mt-3">
              <div
                className="mr-2 font-semibold text-sm hover:cursor-pointer bg-transparent"
                onClick={() => {
                  setIsCommonModelOverrideFormOpen(false);
                }}
              >
                Cancel
              </div>
              <SmallWhiteButton
                disabled={!isFormReadyForSave}
                onClick={() => {
                  if (isFormReadyForSave) {
                    setIsConfirmModalShown(true);
                    // create new linked account mapping
                  }
                }}
              >
                Save
              </SmallWhiteButton>
            </div>
          </div>
          <div className="bg-yellow-0 text-yellow-50 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg leading-[14px]">
            <User size={12} className="mr-2.5" />
            Linked Account-specific mapping
          </div>
        </FieldMappingTargetContainer>
        <div className="w-6"></div>
      </LinkedAccountFieldMappingContainer>
    </>
  );
};
export default CommonModelOverrideForm;
