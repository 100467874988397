import React from "react";

interface Props {
  choices: Array<{ name: string; id: string }>;
  currentValue: any;
  placeholder: string;
  onChange: (e: any) => void;
}

const Dropdown = ({ choices, currentValue, onChange, placeholder }: Props) => (
  <select className="custom-select" onChange={onChange} value={currentValue ?? ""}>
    <option value="" disabled hidden>
      {placeholder}
    </option>
    {choices.map((choice) => (
      <option key={choice.id} value={choice.id}>
        {choice.name}
      </option>
    ))}
  </select>
);

export default Dropdown;
