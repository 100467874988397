// TODO: Rename this file as "Asides.tsx" and make the color and icon a prop

// TODO @elton: Deduplicate code into merge-design-system https://app.asana.com/0/1201041540145493/1201478234575549/f

import React from "react";
import styled from "styled-components";
import { Gem, Webhook, Info, AlertTriangle } from "lucide-react";
import { spectrum } from "../../styles/theme";

const ShadedArea = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  background: ${spectrum.gray0};
  border: none;
  box-shadow: none;
  border-radius: 8px;
  min-height: 56px;
  padding: 16px 20px;
  color: ${spectrum.gray90};
  margin: 8px 0 24px 0;

  a {
    color: inherit;
    font-weight: bold;
  }

  a:hover {
    color: inherit;
    text-decoration: underline;
  }

  p {
    margin-bottom: 8px;
  }

  p:last-child {
    margin-bottom: 0px;
  }
`;

const BlueShadedArea = styled(ShadedArea)`
  background: var(--blue0);
  color: var(--gray90);
`;

const YellowShadedArea = styled(ShadedArea)`
  background: var(--yellow0);
  color: var(--gray90);
`;

// TODO: Align with color system and rename to be generic
const UpgradeShadedArea = styled(ShadedArea)`
  background: #f6f8fb;
  color: var(--gray90);
`;

const PinkShadedArea = styled(ShadedArea)`
  background: var(--pink0);
  color: var(--gray90);
  border-radius: 8px;
  padding: 12px 16px;
  gap: 16px;
  margin-top: 0;
  margin-bottom: 0;
`;

const IndigoShadedArea = styled(ShadedArea)`
  background: ${spectrum.indigo0};
  color: var(--gray90);
`;

const InfoIcon = styled.div.attrs({ className: "fe fe-info" })`
  margin-right: 16px;
`;

const WarningIcon = styled.div.attrs({ className: "fe fe-alert-triangle" })`
  margin-right: 16px;
  color: #ca8f00;
`;

const GemIcon = styled(Gem)`
  margin-right: 0px;
  color: #d62578;
`;

const WebhookIcon = styled(Webhook)`
  margin-right: 16px;
`;

interface Props {
  className?: string;
  children?: string | JSX.Element | JSX.Element[];
  showsIcon?: boolean;
  style?: React.CSSProperties;
  color?: string;
}

export const NoticeCard = ({ className, children, style, showsIcon = true }: Props) => (
  <ShadedArea className={className} style={style}>
    {showsIcon && <InfoIcon role="img" aria-label="info" />}
    {children}
  </ShadedArea>
);

// TODO: Rename all other shaded area "Cards" to "Asides"
export const BlueAside = ({ className, children, style, showsIcon = true }: Props) => (
  <BlueShadedArea className={className} style={style}>
    {showsIcon && (
      <div style={{ marginRight: 16 }}>
        <Info size={16} color="var(--blue40)" />
      </div>
    )}
    {children}
  </BlueShadedArea>
);

export const IndigoAside = ({ className, children, style, showsIcon = true }: Props) => (
  <IndigoShadedArea className={className} style={style}>
    {showsIcon && (
      <div style={{ marginRight: 16 }}>
        <Info size={16} color="var(--indigo40)" />
      </div>
    )}
    {children}
  </IndigoShadedArea>
);

// TODO: Rename as "YellowAside" and make icon more generic
export const WarningCard = ({ className, children, style, showsIcon = true }: Props) => (
  <YellowShadedArea className={className} style={style}>
    {showsIcon && (
      <div style={{ marginRight: 16 }}>
        <AlertTriangle size={16} color="var(--yellow50)" />
      </div>
    )}
    {children}
  </YellowShadedArea>
);

export const UpgradeCard = ({ className, children, style, showsIcon = true }: Props) => (
  <UpgradeShadedArea className={className} style={style}>
    {showsIcon && <WarningIcon role="img" aria-label="info" />}
    {children}
  </UpgradeShadedArea>
);

// TODO: use "IndigoAside"
export const AutoWebhookSetupCard = ({
  className,
  children,
  style,
  showsIcon = true,
  color,
}: Props) => {
  const newStyle = color ? { color, ...style } : style;
  return (
    <IndigoShadedArea className={className} style={newStyle}>
      {showsIcon && <WebhookIcon role="img" size={20} />}
      {children}
    </IndigoShadedArea>
  );
};
