import classNames from "classnames";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import DeprecatedH1 from "../../deprecated/DeprecatedH1";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH3 from "../../deprecated/DeprecatedH3";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

export enum TitleHeading {
  H1 = "H1",
  H2 = "H2",
  H3 = "H3",
  H4 = "H4",
}

const TitleH1 = styled(DeprecatedH1)`
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0;
`;

const TitleH2 = styled(DeprecatedH2)`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0;
`;

const TitleH3 = styled(DeprecatedH3)`
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 0;
`;

const TitleH4 = styled(DeprecatedH4)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;
`;

const Subtitle = styled.p<{ $titleHeading: TitleHeading }>`
  margin-bottom: 0;

  ${({ $titleHeading }) =>
    (() => {
      switch ($titleHeading) {
        case TitleHeading.H1:
          return `
            font-size: 14px;
            line-height: 24px;
          `;
        case TitleHeading.H2:
        case TitleHeading.H3:
        case TitleHeading.H4:
        default:
          return `
            font-size: 14px;
            line-height: 24px;
          `;
      }
    })()};
`;

interface SectionHeaderWrapperProps {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  headerRightHandContent?: string | JSX.Element;
  titleHeading?: TitleHeading;
  button?: JSX.Element;
  className?: string;
  buttonClassName?: string;
  style?: React.CSSProperties;
  children?: JSX.Element;
  image?: JSX.Element;
}

export const SectionTitle = ({
  title,
  titleHeading,
}: Pick<SectionHeaderWrapperProps, "title" | "titleHeading">) => {
  switch (titleHeading) {
    case TitleHeading.H2:
      return <TitleH2>{title}</TitleH2>;
    case TitleHeading.H3:
      return <TitleH3>{title}</TitleH3>;
    case TitleHeading.H4:
      return <TitleH4>{title}</TitleH4>;
    default:
      return <TitleH1>{title}</TitleH1>;
  }
};

export function SectionHeaderWrapper({
  title,
  subtitle,
  headerRightHandContent,
  titleHeading,
  button,
  children,
  className,
  buttonClassName,
  style,
  image,
}: SectionHeaderWrapperProps) {
  const titleHeadingOrDefault = titleHeading || TitleHeading.H1;
  return (
    <>
      <div
        className={classNames(className, "d-flex justify-content-between align-items-center")}
        style={style}
      >
        {image && <div className="deprecated-pl-2 deprecated-pr-4">{image}</div>}
        <div style={{ flex: 1 }}>
          <div
            className={classNames(`d-flex justify-content-between align-items-center`, {
              "deprecated-mb-4": !subtitle && !button,
              "deprecated-mb-3": subtitle,
              "mb-0": button && !subtitle,
            })}
          >
            <SectionTitle title={title} titleHeading={titleHeadingOrDefault} />
            {headerRightHandContent ? headerRightHandContent : null}
          </div>
          {subtitle && <Subtitle $titleHeading={titleHeadingOrDefault}>{subtitle}</Subtitle>}
        </div>
        {button && <div className={classNames(buttonClassName, `deprecated-ml-6`)}>{button}</div>}
      </div>
      {children && (
        <Row>
          <Col>{children}</Col>
        </Row>
      )}
    </>
  );
}
