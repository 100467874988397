import React from "react";
import styled from "styled-components";

const TBody = styled.tbody`
  && {
    background: #f9fafb;
    color: #121314;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: right;
  }
`;

const TH = styled.th`
  && {
    padding-bottom: 4px;
    color: #8492a6;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0;
    text-align: right;
    text-transform: none;
  }
`;

const TR = styled.tr`
  text-align: left;
`;

interface Props {
  productionLinkedAccountCount: number;
  testLinkedAccountCount: number;
}

const LinkedAccountUsageTable = ({
  productionLinkedAccountCount,
  testLinkedAccountCount,
}: Props) => {
  return (
    <table className="w-100">
      <thead>
        <TH className="text-left">Current Quantity</TH>
      </thead>
      <TBody>
        <TR key="production-linked-accounts-count">
          <td colSpan={2}>
            <ul className="leaders my-0">
              <li>
                <span>
                  <strong>Production Linked Account Count</strong>
                </span>
                <span>{productionLinkedAccountCount}</span>
              </li>
            </ul>
          </td>
        </TR>
        <TR key="test-linked-accounts-count">
          <td>
            <ul className="leaders my-0">
              <li>
                <span>
                  <strong>Test Linked Account Count</strong>
                </span>
                <span>{testLinkedAccountCount}</span>
              </li>
            </ul>
          </td>
        </TR>
      </TBody>
    </table>
  );
};

export default LinkedAccountUsageTable;
