import React, { useEffect, useRef, useState } from "react";
import { SelectedWebhookType } from "../../enums";
import LinkedAccountIssuesWebhook from "./components/LinkedAccountIssuesWebhook";
import FirstSyncWebhook from "./components/FirstSyncWebhook";
import AnySyncWebhook from "./components/AnySyncWebhook";
import AsyncPassthroughWebhook from "./components/AsyncPassthroughWebhook";
import ChangedDataWebhook from "./components/ChangedDataWebhook";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import { useWebhookOptions } from "../../hooks/useWebhookOptions";
import WebhookSelectLoading from "./components/WebhookSelectLoading";
import CommonModelSyncWebhook from "./components/CommonModelSyncWebhook";
import LinkedAccountLinkedWebhook from "./components/LinkedAccountLinkedWehook";
import uniq from "lodash-es/uniq";
import { APICategory } from "@merge-api/merge-javascript-shared";
import LinkedAccountSyncedWebhook from "./components/LinkedAccountSyncedWebhook";

interface WebhookTypeSelectProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: SelectedWebhookType | undefined;
  isLoading: boolean;
  enabled: boolean | undefined;

  // common model select props

  selectedCategoryOption: APICategory | "all";
  onSelectedCategoryOptionTypeChange: (selectedCategoryOption: APICategory | "all") => void;

  selectedSyncCommonModels: string[];
  selectedSyncCommonModelEvents: string[];

  selectedChangedDataCommonModels: string[];
  selectedChangedDataCommonModelEvents: string[];

  control: Control<FieldValues>;
  errors: FieldErrors;

  onSelectedChangedDataCommonModelsChange: (selectedChangedDataCommonModels: string[]) => void;
  onSelectedChangedDataCommonModelEventsChange: (
    selectedChangedDataCommonModelEvents: string[],
  ) => void;

  onSelectedSyncCommonModelsChange: (selectedSyncCommonModels: string[]) => void;
  onSelectedSyncCommonModelEventsChange: (selectedSyncCommonModelEvents: string[]) => void;
}

function WebhookTypeSelect(props: WebhookTypeSelectProps) {
  const {
    selectedCategoryOption,
    onSelectedCategoryOptionTypeChange,
    isLoading,
    onSelectedWebhookTypeChange,
    selectedWebhookType,
    enabled,
    selectedSyncCommonModels,
    selectedSyncCommonModelEvents,
    selectedChangedDataCommonModels,
    selectedChangedDataCommonModelEvents,
    control,
    errors,
    onSelectedSyncCommonModelsChange,
    onSelectedSyncCommonModelEventsChange,
    onSelectedChangedDataCommonModelsChange,
    onSelectedChangedDataCommonModelEventsChange,
  } = props;

  const { isIssuesWebhookEnabled, modelToCategoryMap } = useWebhookOptions();

  // handling deprecated webhooks

  const [hasFirstSyncBeenSelected, setHasFirstSyncBeenSelected] = useState<boolean>(false);

  const [hasAnySyncBeenSelected, setHasAnySyncBeenSelected] = useState<boolean>(false);

  const changedDataCommonModelsFromEvents = uniq(
    selectedChangedDataCommonModels.map((event) => event.split(".")[0]),
  );

  const syncCommonModelsFromEvents = uniq(
    selectedSyncCommonModelEvents.map((event) => event.split(".")[0]),
  );

  // additional state for category selection, and logic for processing those commonModels based on category selected

  useEffect(() => {
    if (selectedWebhookType === SelectedWebhookType.FIRST_SYNC) {
      setHasFirstSyncBeenSelected(true);
    }

    if (selectedWebhookType === SelectedWebhookType.ANY_SYNC) {
      setHasAnySyncBeenSelected(true);
    }
  }, [selectedWebhookType]);

  return (
    <div className="mt-5">
      <h5>Emit webhook for...</h5>
      {isLoading ? (
        <WebhookSelectLoading />
      ) : (
        <div>
          <LinkedAccountLinkedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
          />
          <LinkedAccountSyncedWebhook
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
          />
          {hasFirstSyncBeenSelected && (
            <FirstSyncWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            />
          )}
          {hasAnySyncBeenSelected && (
            <AnySyncWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            />
          )}
          <CommonModelSyncWebhook
            onSelectedCategoryOptionTypeChange={onSelectedCategoryOptionTypeChange}
            selectedCategoryOption={selectedCategoryOption}
            changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
            syncCommonModelsFromEvents={syncCommonModelsFromEvents}
            modelToCategoryMap={modelToCategoryMap}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            control={control}
            errors={errors}
            selectedCommonModels={selectedSyncCommonModels}
            selectedCommonModelEvents={selectedSyncCommonModelEvents}
            onSelectedCommonModelsChange={onSelectedSyncCommonModelsChange}
            onSelectedCommonModelEventsChange={onSelectedSyncCommonModelEventsChange}
          />
          {isIssuesWebhookEnabled && (
            <LinkedAccountIssuesWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            />
          )}
          {enabled && (
            <AsyncPassthroughWebhook
              selectedWebhookType={selectedWebhookType}
              onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            />
          )}
          <ChangedDataWebhook
            onSelectedCategoryOptionTypeChange={onSelectedCategoryOptionTypeChange}
            selectedCategoryOption={selectedCategoryOption}
            changedDataCommonModelsFromEvents={changedDataCommonModelsFromEvents}
            syncCommonModelsFromEvents={syncCommonModelsFromEvents}
            modelToCategoryMap={modelToCategoryMap}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            control={control}
            errors={errors}
            selectedCommonModels={selectedChangedDataCommonModels}
            selectedCommonModelEvents={selectedChangedDataCommonModelEvents}
            onSelectedCommonModelsChange={onSelectedChangedDataCommonModelsChange}
            onSelectedCommonModelEventsChange={onSelectedChangedDataCommonModelEventsChange}
          />
        </div>
      )}
    </div>
  );
}

export default WebhookTypeSelect;
