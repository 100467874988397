import { AlertTriangle, Plus } from "lucide-react";
import React, { useCallback, useMemo } from "react";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import {
  ConditionOperatorSchema,
  ConditionType,
  LinkedAccount,
  SelectiveSyncCondition,
  SelectiveSyncConditionSchema,
} from "../../../../../models/Entities";
import { navigateToLinkedAccountDetailPage } from "../../../../../router/RouterUtils";
import { palette } from "../../../../../styles/theme";
import Spinner from "../../../../shared-components/Spinner";
import { showErrorToast } from "../../../../shared-components/Toasts";
import { EditConditionDropdowns } from "./SelectiveSyncEditConditionRow";
import {
  AddConditionButton,
  AndBadge,
  Banner,
  BodyContainer,
  ButtonContainer,
  CancelButton,
  CenteredContainer,
  EditConditionRow,
  FooterContainer,
  HeaderContainer,
  Heading,
  SaveButton,
  SubTitle,
  Title,
  W800Container,
} from "./SelectiveSyncStyles";

const pluralize = require("pluralize");

const SelectiveSyncCreateOrEditPage = ({ linkedAccount }: { linkedAccount: LinkedAccount }) => {
  const createEmptyCondition = () => {
    return {
      condition_schema_id: "",
      common_model: commonModel,
      native_name: "",
      field_name: "",
      operator: undefined,
      value: undefined,
      can_fetch_filter_options: undefined,
    };
  };
  const history = useHistory();
  var { commonModel } = useParams<{ commonModel: string }>();
  // Get condition schema
  const [selectiveSyncConditionsSchemaMap, setSelectiveSyncConditionsSchemaMap] = useState<{
    [schemaId: string]: SelectiveSyncConditionSchema;
  }>({});
  const [modifiedConditions, setModifiedConditions] = useState<SelectiveSyncCondition[]>([]);
  const [otherModelConditions, setOtherModelConditions] = useState<SelectiveSyncCondition[]>([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false);
  const [hasExistingConditions, setHasExistingConditions] = useState<boolean>(false);

  const availableSchemaIDsToOperators: Record<string, ConditionOperatorSchema[]> = useMemo(() => {
    const usedSchemaOperators = modifiedConditions.reduce(
      (accum: Record<string, Set<string>>, condition) => {
        if (!accum[condition.condition_schema_id]) {
          accum[condition.condition_schema_id] = new Set();
        }
        if (condition.operator) {
          accum[condition.condition_schema_id].add(condition.operator);
        }
        return accum;
      },
      {},
    );
    return Object.fromEntries(
      Object.entries(selectiveSyncConditionsSchemaMap)
        .filter(([_, schema]) => schema.common_model === commonModel)
        .map(([id, schema]) => [
          id,
          schema.operators.filter(
            (operatorSchema) =>
              !operatorSchema.is_unique || !usedSchemaOperators[id]?.has(operatorSchema.operator),
          ),
        ])
        .filter(([_, operators]) => operators.length > 0),
    );
  }, [commonModel, modifiedConditions, selectiveSyncConditionsSchemaMap]);

  const isNewConditionAvailable: boolean = useMemo(() => {
    return Object.keys(availableSchemaIDsToOperators).length > 0;
  }, [availableSchemaIDsToOperators]);

  useEffect(() => {
    // API CALL
    fetchWithAuth({
      path: `integrations/selective-sync/configurations/linked-account/${linkedAccount.id}`,
      method: "GET",
      onResponse: (
        data: Array<{
          id: string;
          linked_account_conditions: SelectiveSyncCondition[];
        }>,
      ) => {
        // API returns list of condition groups, which each are a list of conditions
        const arrObj: SelectiveSyncCondition[] = [];
        const otherCommonModelsArray: SelectiveSyncCondition[] = [];
        for (const conditionGroup of data) {
          for (const condition of conditionGroup?.linked_account_conditions || []) {
            if (condition.common_model === commonModel) {
              arrObj.push(condition);
            } else {
              otherCommonModelsArray.push(condition);
            }
          }
        }
        const hasExistingConditions = arrObj.length > 0;
        setHasExistingConditions(hasExistingConditions);
        setModifiedConditions(hasExistingConditions ? arrObj : [createEmptyCondition()]);
        setOtherModelConditions(otherCommonModelsArray);
      },
    });
  }, []);

  useEffect(() => {
    linkedAccount &&
      fetchWithAuth({
        path: `integrations/selective-sync/meta/${linkedAccount.integration.slug}/${linkedAccount.category}`,
        method: "GET",
        onResponse: (data: { results: Array<SelectiveSyncConditionSchema> }) => {
          const results = data.results;
          setSelectiveSyncConditionsSchemaMap(
            Object.fromEntries(
              results
                .filter((schema) => schema.common_model === commonModel)
                .map((schema) => [schema.id, schema]),
            ),
          );
        },
      });
  }, [linkedAccount]);

  useEffect(() => {
    // Check if any conditions without (name operator value) set
    setIsSaveEnabled(
      modifiedConditions.every((val) => {
        const isDefined = !!val.common_model && !!val.operator && !!val.value;
        switch (selectiveSyncConditionsSchemaMap[val.condition_schema_id]?.condition_type) {
          case ConditionType.LIST_OF_STRINGS:
            return isDefined && (val.value as Array<string>).every((value) => !!value);
        }
        return isDefined;
      }),
    );
  }, [modifiedConditions, selectiveSyncConditionsSchemaMap]);

  const updateCondition = useCallback(
    (newCondition: SelectiveSyncCondition, index?: number) => {
      const updatedValue = [...modifiedConditions];
      updatedValue.splice(index !== undefined ? index : modifiedConditions.length, 1, newCondition);
      setModifiedConditions(updatedValue);
    },
    [modifiedConditions, setModifiedConditions],
  );

  const deleteCondition = useCallback(
    (index: number) => {
      const updatedValue = [...modifiedConditions];
      updatedValue.splice(index, 1);
      setModifiedConditions(updatedValue);
    },
    [modifiedConditions, setModifiedConditions],
  );

  const exitWithoutSave = () => {
    navigateToLinkedAccountDetailPage(history, linkedAccount);
  };

  const selectiveSyncConditionToRequestBodyData = (condition: SelectiveSyncCondition) => {
    return {
      condition_schema_id: condition.condition_schema_id,
      operator: condition.operator,
      value: condition.value,
    };
  };

  const formatConditionsForSave = (conditions: SelectiveSyncCondition[]) => {
    return {
      sync_configurations: [
        {
          linked_account_conditions: [
            ...conditions.map((condition) => selectiveSyncConditionToRequestBodyData(condition)),
            ...otherModelConditions.map((condition) =>
              selectiveSyncConditionToRequestBodyData(condition),
            ),
          ],
        },
      ],
    };
  };

  const saveAndExit = () => {
    // API Call
    fetchWithAuth({
      path: `integrations/selective-sync/configurations/linked-account/${linkedAccount.id}`,
      method: "PUT",
      body: formatConditionsForSave(modifiedConditions),
      onResponse: () => {
        navigateToLinkedAccountDetailPage(history, linkedAccount);
      },
      onError: () => {
        showErrorToast("Failed to save selective sync condition.");
      },
    });
  };

  return (
    <CenteredContainer>
      <W800Container>
        <div>
          <HeaderContainer>
            <Title>
              {hasExistingConditions ? "Edit" : "Create"} {commonModel} Selective Sync
            </Title>
            <SubTitle>
              Selections may affect syncing of dependent or related data. Only certain fields
              support Selective Sync.
            </SubTitle>
          </HeaderContainer>
          <BodyContainer>
            <Heading>
              Sync <strong>{pluralize(commonModel, true)}</strong> that match all of the following
              conditions:
            </Heading>
            {modifiedConditions?.map((condition, ind, arr) => (
              <React.Fragment key={ind}>
                <EditConditionRow>
                  <EditConditionDropdowns
                    linkedAccountID={linkedAccount.id}
                    key={ind}
                    integrationName={linkedAccount.integration.name}
                    condition={condition}
                    updateCondition={(updatedCondition) => updateCondition(updatedCondition, ind)}
                    schemaMap={selectiveSyncConditionsSchemaMap}
                    availableSchemaIDsToOperators={availableSchemaIDsToOperators}
                    deleteCondition={() => deleteCondition(ind)}
                  />
                </EditConditionRow>
                {ind !== arr.length - 1 ? (
                  <AndBadge className="deprecated-pl-4">And</AndBadge>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )) || <Spinner />}
            {isNewConditionAvailable && (
              <Row className="w-100">
                <div className="col-11 p-0">
                  <AddConditionButton onClick={() => updateCondition(createEmptyCondition())}>
                    <Plus color={palette.slate} width={15} height={15} />
                  </AddConditionButton>
                </div>
              </Row>
            )}
          </BodyContainer>
        </div>
        <div>
          <FooterContainer>
            <Row className="w-100">
              <div className="col-11 p-0">
                <Banner>
                  <AlertTriangle className="deprecated-pr-3" color="#D9A800" size="32px" />
                  You are responsible for notifying your end users regarding updated sync settings.
                </Banner>
                <ButtonContainer>
                  <CancelButton onClick={() => exitWithoutSave()}>Cancel</CancelButton>
                  <SaveButton
                    className={isSaveEnabled ? "isEnabled" : "isDisabled"}
                    onClick={() => {
                      isSaveEnabled && saveAndExit();
                    }}
                  >
                    Save
                  </SaveButton>
                </ButtonContainer>
              </div>
            </Row>
          </FooterContainer>
        </div>
      </W800Container>
    </CenteredContainer>
  );
};

export default SelectiveSyncCreateOrEditPage;
