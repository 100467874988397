import React, { useEffect, useState } from "react";
import { fetchLinkedAccountFieldMappingInstances } from "../../../../../api-client/APIClient";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingTargetsAndFieldMappingInstances,
} from "../../../../../models/Entities";
import { getFieldMappingConfigurations } from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import { Props as MetadataPageProps } from "../../../shared/MetadataPageLayout";
import LinkedAccountFieldMappingsContextProvider from "./context/LinkedAccountFieldMappingsContextProvider";
import FieldMappings from "./FieldMappings";
import ReferenceMetadataPageLayout from "../../../shared/ReferenceMetadataPageLayout";

type Props = Pick<MetadataPageProps, "linkedAccount"> & {
  /**
   * If the linked account is null, use this to fetch data
   */
  id: string;
  categoryCommonModels: Array<string>;
};

/**
 * Shows existing and available custom mappings for a linked account
 */
const FieldMappingsPage = ({ linkedAccount, categoryCommonModels }: Props) => {
  const [fieldMappingTargetsAndInstances, setFieldMappingTargetsAndInstances] =
    useState<FieldMappingTargetsAndFieldMappingInstances>({
      field_mapping_instances: [],
      field_mapping_targets: [],
      overridden_common_model_instances: [],
      overridden_common_model_targets: [],
    });
  const [fieldMappingConfigurations, setFieldMappingConfigurations] = useState<
    FieldMappingCommonModelConfiguration[] | null
  >(null);

  const [fetchCounter, setFetchCounter] = useState<number>(0);
  const refreshFieldMappings = () => {
    setFetchCounter(fetchCounter + 1);
  };

  useEffect(() => {
    if (linkedAccount) {
      fetchLinkedAccountFieldMappingInstances(linkedAccount.id, setFieldMappingTargetsAndInstances);
      getFieldMappingConfigurations({
        category: linkedAccount.category,
        onFetch: (response: FieldMappingCommonModelConfiguration[]) => {
          setFieldMappingConfigurations(response);
        },
        onError: () => {},
      });
    }
  }, [linkedAccount, fetchCounter]);

  return (
    <ReferenceMetadataPageLayout linkedAccount={linkedAccount}>
      {linkedAccount && (
        <LinkedAccountFieldMappingsContextProvider refreshFieldMappings={refreshFieldMappings}>
          <FieldMappings
            categoryCommonModels={categoryCommonModels}
            linkedAccount={linkedAccount}
            fieldMappingInstances={fieldMappingTargetsAndInstances.field_mapping_instances}
            fieldMappingTargets={fieldMappingTargetsAndInstances.field_mapping_targets}
            fieldMappingConfigurations={fieldMappingConfigurations}
            overriddenCommonModelTargets={
              fieldMappingTargetsAndInstances.overridden_common_model_targets
            }
            overriddenCommonModelInstances={
              fieldMappingTargetsAndInstances.overridden_common_model_instances
            }
          />
        </LinkedAccountFieldMappingsContextProvider>
      )}
    </ReferenceMetadataPageLayout>
  );
};

export default FieldMappingsPage;
