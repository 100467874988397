import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import MergeModal from "../../../shared-components/MergeModal";
import SpinnerButton from "../../../shared-components/SpinnerButton";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  deleteFieldMapping: () => void;
};

const FieldMappingDeletionModal = ({ isModalOpen, closeModal, deleteFieldMapping }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <MergeModal
      centered
      show={isModalOpen}
      bodyClassName="modal-narrow overflow-hidden"
      dialogClassName="modal-narrow"
      onHide={closeModal}
      title="Delete Field Mapping"
      ref={modalRef}
    >
      <span className="text-center">
        This action cannot be undone.
        <br />
        <br />
        Do you wish to continue?
        <br />
        <br />
        <div className="w-100 text-center">
          <Button variant="white" className="deprecated-mr-3 modal-button" onClick={closeModal}>
            Cancel
          </Button>
          <SpinnerButton
            isLoading={isDeleting}
            className="modal-button btn-danger"
            text="Delete"
            type="submit"
            onClick={() => {
              setIsDeleting(true);
              deleteFieldMapping();
            }}
          />
        </div>
      </span>
    </MergeModal>
  );
};

export default FieldMappingDeletionModal;
