import cx from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useFieldArray, useForm, Controller } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { APIEndpointMethod, LinkedAccount, LinkedAccountStatus } from "../../../models/Entities";
import { useQuery } from "../../../router/RouterUtils";
import useAppContext from "../../context/useAppContext";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import DropdownFormField from "../../shared-components/form-field/DropdownFormField";
import { ResponseCodeBadge } from "../../shared-components/MergeBadges";
import MergeCodeBlock from "../../shared-components/MergeCodeBlock";
import { CardHeaderTitle, SmallTextMutedParagraph } from "../../shared-components/MergeText";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { showErrorToast } from "../../shared-components/Toasts";
import RequestHeaderFormRow, {
  createPlaceholderRow,
  HEADERS_FIELD_NAME,
  RequestHeader,
} from "./RequestHeaderFormRow";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";

const StyledLink = styled.a`
  color: #eeeeee;
  text-decoration: underline;

  &:hover {
    color: #eeeeee;
    text-decoration: underline;
  }
`;

const IntegrationsManagementRequestRunnerPage = () => {
  const { user, isUserPrivileged } = useAppContext();
  const { control, handleSubmit, register, errors, getValues, clearErrors } = useForm();

  // These fields are used as the request headers the user has added. Each object is a RequestHeader
  const {
    fields: requestHeaders,
    append: appendRequestHeader,
    remove: removeRequestHeader,
  } = useFieldArray<RequestHeader>({
    control,
    name: HEADERS_FIELD_NAME,
  });

  // Make sure we have exactly one placeholder at all times
  useEffect(() => appendRequestHeader(createPlaceholderRow()), []);

  const [baseAPIURL, setBaseAPIURL] = useState<undefined | string>();
  const [responseCode, setResponseCode] = useState<number>();
  const [responseBody, setResponseBody] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [linkedAccountID, setLinkedAccountID] = useState("");
  const [selectedLinkedAccount, setSelectedLinkedAccount] = useState<LinkedAccount[]>([]);
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[]>([]);
  const [method, setMethod] = useState();

  const [responseHeaders, setResponseHeaders] = useState<Record<string, string>>({});
  const isMobile = useMediaQuery({ query: "(max-width: 767.98px)" });

  const responseHeadersLength = useMemo(
    () => Object.keys(responseHeaders).length,
    [responseHeaders],
  );
  const hasResponseHeaders = useMemo(
    () => (responseHeaders && responseHeadersLength > 0) ?? false,
    [responseHeaders],
  );

  // Map the array of headers into a dictionary for backend, ignoring headers that aren't set
  const getCurrentHeaders = useCallback(() => {
    const currentHeaders: Record<string, string> = {};
    (getValues()[HEADERS_FIELD_NAME] ?? []).forEach(({ name, value }: RequestHeader) => {
      if (name && value) {
        currentHeaders[name] = value;
      }
    });
    return currentHeaders;
  }, [getValues]);
  useEffect(() => {
    setLinkedAccounts([]);
    fetchWithAuth({
      path: `/integrations/api-tester-linked-accounts?status=${LinkedAccountStatus.COMPLETE}&page_size=2000`,
      method: "GET",
      onResponse: (data) => {
        setLinkedAccounts((linkedAccounts) => [...linkedAccounts, ...data.results]);
      },
    });
    fetchWithAuth({
      path: `/integrations/api-tester-linked-accounts?status=${LinkedAccountStatus.COMPLETE}&is_test_account=true&page_size=500`,
      method: "GET",
      onResponse: (data) => {
        setLinkedAccounts((linkedAccounts) => [...data.results, ...linkedAccounts]);
      },
    });
  }, [user.is_demo]);

  function setBaseURL(value: string) {
    for (let x = 0; x < linkedAccounts.length; x++) {
      if (linkedAccounts[x].id == value) {
        setBaseAPIURL(
          linkedAccounts[x].override_base_api_url
            ? linkedAccounts[x].override_base_api_url
            : linkedAccounts[x].integration.base_api_url,
        );
        break;
      }
    }
  }

  const query = useQuery();
  useEffect(() => {
    const filteredLinkedAccounts = linkedAccounts.filter(
      (currLinkedAccount) => currLinkedAccount.id === (query.get("linked_account_id") ?? ""),
    );
    setSelectedLinkedAccount(filteredLinkedAccounts);
    setLinkedAccountID(filteredLinkedAccounts?.[0]?.id);
    control.setValue("linkedAccountID", filteredLinkedAccounts?.[0]?.id);
    setBaseURL(filteredLinkedAccounts.length > 0 ? filteredLinkedAccounts[0].id : "");
  }, [linkedAccounts]);

  const onSubmit = (data: { endpoint: string; data: string }) => {
    if (user.is_demo) {
      return;
    }
    setIsLoading(true);
    setResponseBody("");
    setResponseCode(undefined);
    setResponseHeaders({});

    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccountID}/test-request`,
      method: "POST",
      body: {
        method: method,
        endpoint: data.endpoint,
        data: data.data,
        headers: getCurrentHeaders(),
      },
      onResponse: (
        responseData:
          | { error: string }
          | {
              status: string;
              body: string;
              status_code: number;
              headers: Record<string, string>;
            },
      ) => {
        setIsLoading(false);
        if ("error" in responseData) {
          showErrorToast(responseData.error);
        } else {
          setResponseHeaders(responseData.headers);
          setResponseCode(responseData.status_code);
          try {
            setResponseBody(JSON.stringify(JSON.parse(responseData.body), null, 2));
          } catch (err) {
            setResponseBody(responseData.body);
          }
        }
      },
      onError: () => {
        showErrorToast("Oops! Looks like we encountered an error. Please try again.");
        setIsLoading(false);
      },
    });
  };

  /**
   * Creates a row for the request headers section of the form. Creates a KV pair row for each header and its current value, plus
   * an additional row at the bottom for a perpetual new row to add a new header.
   */
  const requestHeadersFormSection = (
    <Row>
      <Col xs="12">
        <Form.Group controlId={HEADERS_FIELD_NAME}>
          <Form.Label className="deprecated-mb-3 font-semibold">Request Headers</Form.Label>
          {requestHeaders.map((requestHeader, index) => (
            <RequestHeaderFormRow
              key={requestHeader.id}
              requestHeader={requestHeader}
              isLastRow={index === requestHeaders.length - 1}
              index={index}
              append={appendRequestHeader}
              remove={removeRequestHeader}
              control={control}
              errors={errors}
              getValues={getValues}
            />
          ))}
        </Form.Group>
      </Col>
    </Row>
  );

  // Splits the headers into two columns for readability (becomes one on mobile)
  const responseHeadersColumns = useMemo(() => {
    const splitPoint = Math.floor(responseHeadersLength / 2 + 1);
    return [
      Object.keys(responseHeaders).slice(0, splitPoint),
      Object.keys(responseHeaders).slice(splitPoint),
    ];
  }, [responseHeaders]);

  /**
   * This section appears between the status and body if there are response headers, so it needs dividers around
   * it and a header for the body that appears after it. Uses the same header style as status for consistency. Puts
   * the headers into two columns.
   */
  const responseHeadersSection = hasResponseHeaders && (
    <>
      <hr />
      <DeprecatedH4>Headers</DeprecatedH4>
      <Row>
        {responseHeadersColumns.map((headersColumnKeys) => (
          <Col key={headersColumnKeys[0]} md="6">
            {headersColumnKeys.map((headerKey) => (
              <div key={headerKey}>
                <div className="log-entry-section-key-text mb-0">{headerKey}</div>
                <p className="deprecated-mb-3">{responseHeaders[headerKey]}</p>
              </div>
            ))}
          </Col>
        ))}
      </Row>
      <hr />
      <DeprecatedH4>Body</DeprecatedH4>
    </>
  );

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs="12">
            <Card>
              <Card.Header>
                <CardHeaderTitle>Request</CardHeaderTitle>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs="12">
                    <Form.Group controlId="linkedAccountID">
                      <Form.Label className="deprecated-mb-3 font-semibold">
                        Linked Account*
                      </Form.Label>
                      <Controller
                        control={control}
                        name="linkedAccountID"
                        rules={{
                          required: true,
                        }}
                        defaultValue=""
                        render={({ onChange }) => {
                          return (
                            <Typeahead
                              clearButton
                              labelKey={(option) =>
                                `${option.integration.name}` +
                                " - " +
                                `${option.end_user.organization_name}`
                              }
                              id="typeahead"
                              options={linkedAccounts}
                              inputProps={{ autoComplete: "none" }}
                              placeholder="Select a Linked Account"
                              selected={selectedLinkedAccount}
                              onChange={(linkedAccounts) => {
                                const linkedAccount = linkedAccounts[0];
                                onChange(linkedAccount);
                                if (linkedAccounts.length === 0) {
                                  setSelectedLinkedAccount([]);
                                }
                                if (linkedAccount) {
                                  setSelectedLinkedAccount([linkedAccount]);
                                  setLinkedAccountID(linkedAccount.id);
                                  setBaseURL(linkedAccount.id);
                                }
                              }}
                              isInvalid={errors.linkedAccountID}
                              renderMenuItemChildren={(linkedAccount) => {
                                return (
                                  <>
                                    {linkedAccount.integration.name +
                                      " - " +
                                      linkedAccount.end_user.organization_name}
                                    <span className="badge bg-secondary-soft deprecated-ml-3 deprecated-mb-1">
                                      {linkedAccount.is_test_account ? "Test" : "Production"}
                                    </span>
                                    <br />
                                    <SmallTextMutedParagraph className="text-uppercase mb-0">
                                      {linkedAccount.category}
                                    </SmallTextMutedParagraph>
                                  </>
                                );
                              }}
                            />
                          );
                        }}
                      />
                      <div
                        className={cx({
                          "is-invalid": errors.linkedAccountID,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose a linked account.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                {requestHeadersFormSection}
                <Row>
                  <Col xs="12" lg="3">
                    <Form.Group controlId="method">
                      <Form.Label className="mb-0 font-semibold">Method*</Form.Label>
                      <DropdownFormField
                        {...(register("method", { validate: () => !!method }) as any)}
                        placeholder="Select a method"
                        currentValue={method}
                        onChange={(e) => {
                          setMethod(e.target.value);
                          clearErrors("method");
                        }}
                        choices={Object.values(APIEndpointMethod).map((method) => {
                          switch (method) {
                            case APIEndpointMethod.GET:
                              return { name: "GET", id: method };
                            case APIEndpointMethod.OPTIONS:
                              return { name: "OPTIONS", id: method };
                            case APIEndpointMethod.HEAD:
                              return { name: "HEAD", id: method };
                            case APIEndpointMethod.PUT:
                              return { name: "PUT", id: method };
                            case APIEndpointMethod.PATCH:
                              return { name: "PATCH", id: method };
                            case APIEndpointMethod.POST:
                              return { name: "POST", id: method };
                            case APIEndpointMethod.DELETE:
                              return { name: "DELETE", id: method };
                            default:
                              throw "Step Type Not Found";
                          }
                        })}
                        className={cx({
                          "is-invalid": errors.method,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please choose a method.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {isMobile && (
                    <Col className="ml-0" xs="12">
                      <Form.Group controlId="mobileBaseUrl">
                        <Form.Label className="font-semibold">API Base URL</Form.Label>
                        <InputGroup.Prepend className="base-api-url-prepend">
                          <InputGroup.Text className="base-api-url-text">
                            {baseAPIURL ? <b>{baseAPIURL}</b> : <i>Select Linked Account</i>}
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                      </Form.Group>
                    </Col>
                  )}
                  <Col className="ml-0">
                    <Form.Group controlId="endpoint">
                      <Form.Label className="font-semibold">
                        Endpoint Path*
                        <OverlayTrigger
                          placement="top"
                          trigger="hover"
                          delay={{ show: 100, hide: 1000 }}
                          overlay={
                            <Tooltip id="no-keys-in-codebase-tooltip">
                              Enter the third-party platform's endpoint. You can find which
                              endpoints Merge uses{" "}
                              <StyledLink
                                href="https://docs.merge.dev/integrations"
                                target="_blank"
                              >
                                here
                              </StyledLink>
                              .
                            </Tooltip>
                          }
                        >
                          <i className="deprecated-ml-2 fe fe-info text-gray-50" />
                        </OverlayTrigger>
                      </Form.Label>
                      <InputGroup>
                        {baseAPIURL && !isMobile && (
                          <InputGroup.Prepend className="base-api-url-prepend">
                            <InputGroup.Text className="base-api-url-text overflow-hidden">
                              <b>{baseAPIURL}</b>
                            </InputGroup.Text>
                          </InputGroup.Prepend>
                        )}
                        <Form.Control
                          as="input"
                          name="endpoint"
                          placeholder={baseAPIURL ? "/route/params" : ""}
                          ref={register({ required: true, minLength: 1 })}
                          className={cx("form-control", {
                            "is-invalid": errors.endpoint,
                          })}
                        />
                        <Form.Control.Feedback type="invalid">
                          A valid endpoint path is required.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <Form.Group className="mt-0" controlId="data">
                      <Form.Label className="font-semibold">Request Body</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="data"
                        ref={register()}
                        className={cx("form-control", {
                          "is-invalid": errors.data,
                        })}
                      />
                      <Form.Control.Feedback type="invalid">
                        Valid data is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Col xs="12" lg="3">
                    {!user.is_demo && isUserPrivileged ? (
                      <SpinnerButton
                        text="Send request"
                        isLoading={isLoading}
                        className="btn btn-primary btn-block"
                      />
                    ) : (
                      <OverlayTrigger
                        overlay={
                          <Tooltip className="step-card-full-name-tooltip" id="tooltip">
                            {user.is_demo
                              ? "Turn off demo data on the left to enable this feature!"
                              : "You must be an admin or developer to make test requests."}
                          </Tooltip>
                        }
                        placement="top"
                      >
                        <Button variant="primary" className="btn-block">
                          Send request
                        </Button>
                      </OverlayTrigger>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <Card className="card-fill">
              <Card.Header className="mb-0">
                <CardHeaderTitle>Response</CardHeaderTitle>
              </Card.Header>
              <Card.Body className="mt-0">
                {responseCode ? (
                  <>
                    <DeprecatedH4>
                      Status:{" "}
                      <span>
                        <ResponseCodeBadge responseCode={responseCode} />
                      </span>
                    </DeprecatedH4>
                    {responseHeadersSection}
                    <MergeCodeBlock
                      showLineNumbers
                      customStyle={{
                        lineHeight: "4px",
                      }}
                      textToCopy={responseBody || "The remote API returned an empty body."}
                    >
                      {responseBody || "The remote API returned an empty body."}
                    </MergeCodeBlock>
                  </>
                ) : (
                  <>
                    {isLoading ? (
                      <EmptyStateWrapper isSpinner />
                    ) : (
                      <EmptyStateWrapper title="No response" />
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default IntegrationsManagementRequestRunnerPage;
