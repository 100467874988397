import React from "react";
import styled from "styled-components";
import { palette } from "../../../../../styles/theme";
import MergeModal from "../../../../shared-components/MergeModal";

const DarkButton = styled.div`
  background: ${palette.black};
  border-radius: 6px;
  color: ${palette.white};
  text-align: center;
  justify-content: center;
  padding: 12px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

const LightButton = styled.div`
  background: ${palette.white};
  border-radius: 6px;
  text-align: center;
  justify-content: center;
  padding: 12px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

const ModalContainer = styled.div`
  font-size: 14px;
  line-height: 24px;
  padding: 24px;
`;

const TextBox = styled.div`
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

type Props = {
  onClickMethod: () => void;
  isShown: boolean;
  onHide: () => void;
};

export const UseSyncCreditModal = ({ onClickMethod, isShown, onHide }: Props) => {
  return (
    <MergeModal
      bodyClassName="p-0 overflow-hidden"
      title="Resync data"
      show={isShown}
      onHide={onHide}
      size="sm"
    >
      <ModalContainer>
        <TextBox>
          <div>This will use one of your sync credits.</div>
          <div>Do you wish to continue?</div>
        </TextBox>
        <DarkButton onClick={onClickMethod}>Resync data</DarkButton>
        <LightButton onClick={onHide}>Cancel</LightButton>
      </ModalContainer>
    </MergeModal>
  );
};
