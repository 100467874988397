import React, { useState } from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { HTTPMethodBadge, ResponseCodeBadge } from "../../../../../shared-components/MergeBadges";
import DeprecatedH2 from "../../../../../../deprecated/DeprecatedH2";

type Props = {
  title: string;
  subtitle: string;
  image?: JSX.Element;
  request_method?: string;
  response_status?: number;
  hideBorderBottom?: boolean;
};

export const LogSidePanelBodySectionHeader = ({
  title,
  subtitle,
  image,
  request_method,
  response_status,
  hideBorderBottom = false,
}: Props) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState<boolean>(false);

  const copyElement = () => {
    // Copy subtitle to clipboard
    setHasCopiedToClipboard(true);
  };

  return (
    <Card.Header
      className="log-card-header"
      {...(hideBorderBottom && { style: { borderBottom: "none" } })}
    >
      <Row className="log-entry-side-panel-header">
        {image && (
          <Col className="col-auto">
            <div className="log-card-header-image">{image}</div>
          </Col>
        )}
        <Col className="overflow-auto">
          <header>
            <DeprecatedH2 className="deprecated-mb-1 log-entry-side-panel-header-title">
              {title}
              <span>
                {response_status && (
                  <ResponseCodeBadge
                    className="float-right log-entry-side-panel-header-badge"
                    responseCode={response_status}
                  />
                )}
                {request_method && (
                  <HTTPMethodBadge className="float-right" method={request_method} />
                )}
              </span>
            </DeprecatedH2>
          </header>
          <div className="float-left">
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 0 }}
              overlay={
                <Tooltip id="log-url-copy">{hasCopiedToClipboard ? "Copied!" : "Copy"}</Tooltip>
              }
            >
              <CopyToClipboard text={subtitle} onCopy={() => copyElement()}>
                <i className="fe fe-copy deprecated-mr-3 cursor-pointer" />
              </CopyToClipboard>
            </OverlayTrigger>
          </div>
          <p className="log-entry-side-panel-header-subtitle" title={subtitle}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">{subtitle}</Tooltip>}
            >
              <span>{subtitle}</span>
            </OverlayTrigger>
          </p>
        </Col>
      </Row>
    </Card.Header>
  );
};
