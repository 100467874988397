import React from "react";
import styled from "styled-components";

const CheckboxLabel = styled.label`
  && {
    font-size: 14px;
    line-height: 24px;
    margin-left: 9px;
  }
`;

const NoMarginTopInput = styled.input`
  && {
    margin-top: 5px;
  }
`;

interface CheckboxProps {
  checked?: boolean;
  id: string;
  label: string;
  onClick: () => void;
}

const Checkbox = ({ checked, id, label, onClick }: CheckboxProps) => {
  return (
    <div className="form-check">
      <NoMarginTopInput
        className="form-check-input"
        type="checkbox"
        id={id}
        onClick={onClick}
        checked={checked === true}
        onChange={() => null}
      />
      <CheckboxLabel className="form-check-label" htmlFor="allEvents">
        {label}
      </CheckboxLabel>
    </div>
  );
};

export default Checkbox;
