import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { MoreHorizontal } from "lucide-react";
import React from "react";
import { Dropdown } from "react-bootstrap";

/*
 * TO-DO:
 * Add this to merge-javascript-shared WITHOUT using react-bootstrap
 */

const customToggle = React.forwardRef(
  (
    {
      onClick,
    }: {
      children: React.ReactNode;
      onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    },
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <div
      ref={ref}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick(event);
      }}
    >
      <SmallWhiteButton className="relative" variant="singleIcon">
        <MoreHorizontal size={16} />
      </SmallWhiteButton>
    </div>
  ),
);

type MoreHorizontalPopoverProps = {
  menuOptions: JSX.Element;
};
const MoreHorizontalPopover = ({ menuOptions }: MoreHorizontalPopoverProps) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={customToggle} />
      <Dropdown.Menu alignRight>{menuOptions}</Dropdown.Menu>
    </Dropdown>
  );
};
export default MoreHorizontalPopover;
