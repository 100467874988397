import React, { useEffect, useState } from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import uniq from "lodash/uniq";

import {
  stringRemoveSpaces,
  removeValueFromStringArray,
  abbreviationForAPICategory,
  colorForAPICategory,
} from "../../../../../services";
import MergeTypeahead from "../../../../shared-components/MergeTypeahead";
import ChangedDataCommonModelCard from "./WebhookTypeSelect/components/ChangedDataCommonModelCard";
import { SelectedWebhookType } from "../enums";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import { CategoryMap } from "../hooks/useWebhookOptions";
import { APICategory, Badge } from "@merge-api/merge-javascript-shared";
import { startCase } from "lodash";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
} from "../../../../../models/Helpers";
import ReactSelectDropdown from "../../../../shared-components/ReactSelectDropdown";
import SyncCommonModelCard from "./WebhookTypeSelect/components/SyncCommonModelCard";

interface CommonModelSelectProps {
  selectedCategoryOption: APICategory | "all";
  onSelectedCategoryOptionTypeChange: (selectedCategoryOption: APICategory | "all") => void;
  selectedWebhookType: SelectedWebhookType | undefined;
  modelToCategoryMap: CategoryMap;
  selectedCommonModels: string[];
  selectedCommonModelEvents: string[];
  changedDataCommonModelsFromEvents: string[];
  syncCommonModelsFromEvents: string[];
  control: Control<FieldValues>;
  errors: FieldErrors;
  onSelectedCommonModelsChange: (selectedCommonModels: string[]) => void;
  onSelectedCommonModelEventsChange: (selectedCommonModelEvents: string[]) => void;
}

function CommonModelSelect(props: CommonModelSelectProps) {
  const {
    selectedCategoryOption,
    onSelectedCategoryOptionTypeChange,
    changedDataCommonModelsFromEvents,
    syncCommonModelsFromEvents,
    selectedWebhookType,
    modelToCategoryMap,
    selectedCommonModels,
    selectedCommonModelEvents,
    control,
    errors,
    onSelectedCommonModelsChange,
    onSelectedCommonModelEventsChange,
  } = props;

  // hooks
  const { productRestrictions, orgBillingPlan } = useProductRestrictions();

  // event handlers
  const deselectCommonModel = (commonModel: string) => {
    updateSelectedCommonModels(selectedCommonModels.filter((m) => m !== commonModel));
  };

  // additional state for category selection, and logic for processing those commonModels based on category selected

  const getCommonModelsForCategory = (category: string) => {
    if (category === "all" || undefined) {
      return [];
    }
    return Object.entries(modelToCategoryMap)
      .filter(([model, modelCategory]) => modelCategory === category)
      .map(([model]) => model);
  };

  const commonModelsForSelectedCategory = getCommonModelsForCategory(selectedCategoryOption);

  // state for handling which type of webhook selected

  const isCommonModelSync =
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_SELECT ||
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_ANY;

  const isChangedDataSelect =
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT;
  const isSyncSelect = selectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_SELECT;

  // dropdown
  const dropDownOptions = [
    { value: "all", label: "All categories" },
    ...INTEGRATION_CATEGORY_LIST.map((category) => ({
      value: category,
      label: displayNameForAPICategory(category),
    })),
  ];

  /**
   * Update the arrays of selectedCommonModels and selectedCommonModelEvents.
   * `commonModels` is the new list of selectedCommonModels (just their names).
   * Identify and handle model names that were deselected (removed), and identify and handle
   * model names that were selected (added).
   */
  const updateSelectedCommonModels = (commonModels: string[]) => {
    const newlyAddedCommonModels = commonModels.filter((m) => !selectedCommonModels.includes(m));

    // Compose array of events to add for each newly added Common Model.
    const addedEvents: string[] = [];
    // TODO: add ".removed" when deletes are implemented
    const defaultCommonModelEvents = isCommonModelSync
      ? [".synced"]
      : [".added", ".changed", ".removed"];
    newlyAddedCommonModels.forEach((commonModel) =>
      addedEvents.push(
        ...defaultCommonModelEvents.map(
          (defaultEvent) => `${stringRemoveSpaces(commonModel)}${defaultEvent}`,
        ),
      ),
    );
    // Remove events for Common Models that are not in commonModels (ie, that were removed).
    const updatedSelectedEvents = selectedCommonModelEvents.filter((e) =>
      commonModels.map((m) => stringRemoveSpaces(m)).includes(e.split(".")[0]),
    );

    // For newly added Common Models, add the default event types for that model.
    updatedSelectedEvents.push(...addedEvents);

    onSelectedCommonModelsChange(commonModels);
    onSelectedCommonModelEventsChange(uniq(updatedSelectedEvents));
  };

  // when a common model is selected or deselected, update associated events
  const updateSelectedEvents = (checked: boolean, value: string) => {
    if (checked) {
      onSelectedCommonModelEventsChange([...selectedCommonModelEvents, value]);
    } else {
      onSelectedCommonModelEventsChange(
        removeValueFromStringArray(selectedCommonModelEvents, value),
      );
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center w-full mb-4">
        <ReactSelectDropdown
          className="w-[255px] mr-3 h-10"
          dropDownOptions={dropDownOptions}
          onChange={(newValue) => {
            onSelectedCategoryOptionTypeChange(newValue?.value as APICategory);
          }}
          placeholder="Select category..."
          value={
            selectedCategoryOption
              ? {
                  value: selectedCategoryOption,
                  label:
                    selectedCategoryOption === "all"
                      ? "All categories"
                      : displayNameForAPICategory(selectedCategoryOption),
                }
              : undefined
          }
        />
        <div className="w-full mb-0">
          <Controller
            className="w-full"
            control={control}
            name="event"
            render={() => (
              <MergeTypeahead
                id="typeahead"
                multiple
                positionFixed
                removeInput={true}
                disabled={selectedCategoryOption === "all" ? true : false}
                selected={selectedCommonModels}
                options={commonModelsForSelectedCategory}
                inputProps={{ autoComplete: "none" }}
                placeholder="Select Common Model..."
                onChange={(selectedModelNames) => {
                  updateSelectedCommonModels(selectedModelNames);
                  onSelectedCommonModelsChange(selectedModelNames);
                }}
                isInvalid={false}
                error={errors.event?.message}
                renderMenuItemChildren={(commonModel) => {
                  return (
                    <div className="flex flex-row items-center py-1.5">
                      <div className="!text-black text-md">{startCase(commonModel)}</div>
                      <Badge
                        className="ml-1.5"
                        color={colorForAPICategory(modelToCategoryMap[commonModel] as APICategory)}
                      >
                        {abbreviationForAPICategory(modelToCategoryMap[commonModel] as APICategory)}
                      </Badge>
                    </div>
                  );
                }}
              />
            )}
          />
        </div>
      </div>
      {isChangedDataSelect && (
        <div className="flex content-around flex-wrap cursor-pointer">
          {changedDataCommonModelsFromEvents?.map((commonModel) => (
            <ChangedDataCommonModelCard
              modelToCategoryMap={modelToCategoryMap}
              key={commonModel}
              commonModelName={commonModel}
              selectedEvents={selectedCommonModelEvents}
              onRemove={() => deselectCommonModel(commonModel)}
              onCheckboxChange={(isChecked, value) => updateSelectedEvents(isChecked, value)}
              deletedWebhookEnabled={
                (productRestrictions?.webhooks_enabled &&
                  (orgBillingPlan?.deletion_detection_enabled ||
                    productRestrictions?.third_party_webhooks_enabled)) ||
                false
              }
            />
          ))}
        </div>
      )}
      {isSyncSelect && (
        <div className="flex flex-row content-around gap-x-3 gap-y-3 flex-wrap cursor-pointer">
          {syncCommonModelsFromEvents?.map((commonModel) => (
            <SyncCommonModelCard
              modelToCategoryMap={modelToCategoryMap}
              key={commonModel}
              commonModelName={commonModel}
              onRemove={() => deselectCommonModel(commonModel)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default CommonModelSelect;
