import React from "react";
import MergeModal from "../../shared-components/MergeModal";
import { Button, Row } from "react-bootstrap";

type Props = {
  onClickMethod: () => void;
  isShown: boolean;
  onHide: () => void;
};

export const DisableTwoFactorAuthRequireModal = ({ onClickMethod, isShown, onHide }: Props) => {
  return (
    <MergeModal
      bodyClassName="p-0 overflow-hidden"
      title="Disable 2FA Enforcement?"
      show={isShown}
      onHide={onHide}
      size="sm"
    >
      <div className="align-items-center deprecated-mx-5 deprecated-my-5">
        <Row>
          <span>
            Are you sure you want to disable 2FA enforcement for all organization members?
          </span>
        </Row>
        <br />
        <Row className="align-items-center">
          <Button variant="dark" onClick={onClickMethod} className="btn-block">
            <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>Disable 2FA enforcement</span>
          </Button>
        </Row>
        <Row className="align-items-center deprecated-pt-3">
          <Button variant="white" onClick={onHide} className="btn-block border-0">
            Cancel
          </Button>
        </Row>
      </div>
    </MergeModal>
  );
};
