import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
} from "../../../../../models/Entities";
import MergeTypeahead from "../../../../shared-components/MergeTypeahead";
import LinkedAccountCommonModelFieldMappingOverview from "./LinkedAccountCommonModelFieldMappingOverview";
import { WarningCard } from "../../../../shared-components/NoticeCards";
import { FieldMappingsDocsButton } from "../../../../configuration/integrations/field-mappings/ConfigurationFieldMappingsPageContainer";
import { SectionHeaderWrapper, TitleHeading } from "../../../../shared-components/MergeLayouts";
import { LinkedAccountStatuses } from "../../../../../constants";
import { getFieldMappingOptions } from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import FieldMappingUpsellModal from "../../../../configuration/integrations/field-mappings/ConfigurationFieldMappingsUpsellModal";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import CommonModelOverridesSection from "./common-model-overrides/CommonModelOverridesSection";
import useAppContext from "../../../../context/useAppContext";

type FieldMappingsProps = {
  fieldMappingInstances: Array<FieldMappingInstance>;
  fieldMappingTargets: Array<FieldMappingTarget>;
  fieldMappingConfigurations: Array<FieldMappingCommonModelConfiguration> | null;
  overriddenCommonModelTargets: Array<OverriddenCommonModelTarget>;
  overriddenCommonModelInstances: Array<OverriddenCommonModelInstance>;
  linkedAccount: LinkedAccount;
  categoryCommonModels: Array<string>;
};

const FieldMappingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
`;

const FieldMappingsSubtitleText = styled.p`
  font-size: 14px;
  line-height: 24px;
`;

const MarginTopBottomWarningCard = styled(WarningCard)`
  margin: 0px;
`;

const MarginBottomDivContainerForTypeAhead = styled.div`
  margin-bottom: 16px;
`;

const MODAL_TOP_VALUE = "0px";

/**
 * Shows existing and available custom mappings for a linked account
 */
const FieldMappings = ({
  fieldMappingInstances,
  fieldMappingTargets,
  fieldMappingConfigurations,
  overriddenCommonModelTargets,
  overriddenCommonModelInstances,
  linkedAccount,
  categoryCommonModels,
}: FieldMappingsProps) => {
  const { user } = useAppContext();
  const isOverrideCommonModelsEnabled = user?.is_override_common_models_enabled || false;
  const [customMappingMetaResponse, setCustomMappingMetaResponse] = useState<
    FieldMappingMetaResponse | undefined
  >();

  const { productRestrictions } = useProductRestrictions();
  const [showUpsellModal, setShowUpsellModal] = useState(
    !productRestrictions?.are_custom_fields_enabled,
  );

  const [selectedCommonModels, setSelectedCommonModels] = useState<Array<string>>([]);

  useEffect(() => {
    linkedAccount &&
      getFieldMappingOptions({
        linkedAccountID: linkedAccount.id,
        onFetch: (data) => {
          setCustomMappingMetaResponse(data);
        },
        onError: () => {},
      });
  }, [linkedAccount]);

  const fieldMappingsByCommonModel = [...fieldMappingInstances, ...fieldMappingTargets].reduce(
    (
      accumulatingObj: { [key: string]: Array<FieldMappingInstance | FieldMappingTarget> },
      fieldMapping: FieldMappingInstance | FieldMappingTarget,
    ) => {
      const commonModelName = fieldMapping.common_model_name;
      accumulatingObj[commonModelName] = [
        ...(accumulatingObj[commonModelName] ?? []),
        fieldMapping,
      ];

      return accumulatingObj;
    },
    {},
  );

  const hasFieldMappingsLoaded = fieldMappingConfigurations !== null;

  const commonModelsToMappingConfigs = fieldMappingConfigurations
    ? fieldMappingConfigurations
        .sort((a, b) => a.common_model_name.localeCompare(b.common_model_name))
        .reduce(
          (
            accum: { [common_model_id: string]: FieldMappingCommonModelConfiguration },
            mapping_target,
          ) => {
            accum[`${mapping_target.common_model_name}`] = mapping_target;
            return accum;
          },
          {},
        )
    : null;

  const CommonModelSearchBar = () => {
    return (
      <MarginBottomDivContainerForTypeAhead>
        <MergeTypeahead
          id="typeahead"
          multiple
          includeSearchIcon={true}
          includeChevronDown={false}
          selected={selectedCommonModels}
          options={categoryCommonModels.filter(searchableCommonModels)}
          inputProps={{ autoComplete: "none" }}
          placeholder="Search Common Models..."
          onChange={(selectedModelNames) => {
            setSelectedCommonModels(selectedModelNames);
          }}
          isInvalid={false}
          error=""
        />
      </MarginBottomDivContainerForTypeAhead>
    );
  };

  const searchableCommonModels = (commonModelName: string) => {
    if (commonModelsToMappingConfigs === null) {
      return true;
    }

    return Object.keys(commonModelsToMappingConfigs).includes(commonModelName);
  };

  const filterCommonModels = (commonModelName: string) => {
    if (commonModelsToMappingConfigs === null) {
      return null;
    }

    return selectedCommonModels.length === 0
      ? Object.keys(commonModelsToMappingConfigs).includes(commonModelName)
      : selectedCommonModels.includes(commonModelName) &&
          Object.keys(commonModelsToMappingConfigs).includes(commonModelName);
  };

  if (
    linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED ||
    linkedAccount.status === LinkedAccountStatuses.INCOMPLETE
  ) {
    const warningText = `Field Mapping is not available when ${
      linkedAccount.status === LinkedAccountStatuses.RELINK_NEEDED
        ? "account relink is needed"
        : "the linked account is incomplete"
    }.`;
    return (
      <FieldMappingContainer>
        <SectionHeaderWrapper
          className="mb-4"
          title="Field Mapping"
          titleHeading={TitleHeading.H1}
          headerRightHandContent={FieldMappingsDocsButton}
        />
        <>
          <MarginTopBottomWarningCard showsIcon>{warningText}</MarginTopBottomWarningCard>
        </>
      </FieldMappingContainer>
    );
  }

  return (
    <>
      {!productRestrictions?.are_custom_fields_enabled && (
        <FieldMappingUpsellModal
          showUpsellModal={showUpsellModal}
          setShowUpsellModal={setShowUpsellModal}
          modalTopValue={MODAL_TOP_VALUE}
        />
      )}
      <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
        <FieldMappingContainer>
          <SectionHeaderWrapper
            className="mb-4"
            title="Field Mapping"
            titleHeading={TitleHeading.H1}
            subtitle={
              <>
                <FieldMappingsSubtitleText className="mb-2">
                  Create mappings between <strong>target fields</strong> on your Merge Common Models
                  and your users' {linkedAccount?.integration?.name} <strong>origin fields</strong>.
                </FieldMappingsSubtitleText>
                <FieldMappingsSubtitleText className="mb-2">
                  Any changes to data made here will be updated in the next sync.
                </FieldMappingsSubtitleText>
              </>
            }
            headerRightHandContent={FieldMappingsDocsButton}
          />
          <CommonModelSearchBar />
          {isOverrideCommonModelsEnabled && categoryCommonModels.length > 0 && (
            <CommonModelOverridesSection
              commonModelOverrideConfigs={fieldMappingConfigurations!}
              hasFieldMappingsLoaded={hasFieldMappingsLoaded}
              customMappingMetaResponse={customMappingMetaResponse}
              linkedAccount={linkedAccount}
              overriddenCommonModelTargets={overriddenCommonModelTargets}
              overriddenCommonModelInstances={overriddenCommonModelInstances}
            />
          )}
          {hasFieldMappingsLoaded
            ? categoryCommonModels.filter(filterCommonModels).map((modelName, index) => (
                <>
                  <LinkedAccountCommonModelFieldMappingOverview
                    key={index}
                    commonModelName={modelName}
                    linkedAccount={linkedAccount}
                    fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                    fieldMappingConfig={commonModelsToMappingConfigs?.[modelName] ?? null}
                    customMappingMetaResponse={customMappingMetaResponse}
                    hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                  />
                </>
              ))
            : categoryCommonModels.map((modelName, index) => (
                <>
                  <LinkedAccountCommonModelFieldMappingOverview
                    key={index}
                    commonModelName={modelName}
                    linkedAccount={linkedAccount}
                    fieldMappings={fieldMappingsByCommonModel[modelName] ?? []}
                    fieldMappingConfig={commonModelsToMappingConfigs?.[modelName] ?? null}
                    customMappingMetaResponse={customMappingMetaResponse}
                    hasFieldMappingsLoaded={hasFieldMappingsLoaded}
                  />
                </>
              ))}
        </FieldMappingContainer>
      </div>
    </>
  );
};

export default FieldMappings;
