import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  ISSUES_PATH,
  navigateToIssuesPage,
  getLinkedAccountIssuesPathForId,
} from "../../../../router/RouterUtils";
import useAppContext from "../../../context/useAppContext";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import { IssuesStatusBadge, StatusBadge } from "../../../shared-components/MergeBadges";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import {
  IntegrationIssue,
  IntegrationIssueActionLogEntryAction,
  APIRequestLogEntry,
  IntegrationIssueActor,
  IntegrationIssueStatus,
} from "../../IntegrationsManagementEntities";
import EndUserMetadata from "../../shared/EndUserMetadata";
import MetadataPair from "../../shared/MetadataPair";
import MetadataPairWithLogo from "../../shared/MetadataPairWithLogo";
import {
  updateIntegrationIssueStatus,
  fetchLogEntryByID,
} from "../../utils/IntegrationsManagementAPIClient";
import { getIssueUpdatedAt, isIssueMuted } from "../../utils/IntegrationsManagementUtils";
import { IntegrationIssueCommentModal } from "../IntegrationIssueModals";
import IntegrationManagementIndividualIssueActivity from "./IntegrationManagementIndividualIssueActivity";
import IntegrationManagementIndividualIssueDescription from "./IntegrationManagementIndividualIssueDescription";
import IntegrationManagementIndividualIssueDescriptionWithErrorDetails from "./IntegrationManagementIndividualIssueDescriptionWithErrorDetails";
import IndividualIssueMuteButton from "./IntegrationsManagementIndividualIssueMuteButton";
import { DATE_FORMAT } from "../../../../constants";
import APIRequestLogEntrySidePanel from "../../logs/side-panel/APIRequestLogSidePanel";
import { ChevronLeft } from "lucide-react";

const Section = styled.div`
  padding: 0 0 10px 0;
  &:not(:first-child) {
    padding: 10px 0;
  }
  min-width: 0;
`;

const ResolvedIssueBanner = styled.div`
  justify-content: center;
  align-items: center;
  padding: 8px 6px 6px;
  gap: 10px;
  min-width: 0;
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  background: #ebfcfa;
  border-radius: 4px;

  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  color: #1a9b87;
`;

const Chevron = styled(ChevronLeft)`
  transform: translate(0px, -1px);
`;

export const getResolvedBy = (integrationIssue: IntegrationIssue) => {
  if (integrationIssue?.metadata) {
    for (let i = integrationIssue.metadata.length - 1; i >= 0; i--) {
      const action = integrationIssue.metadata[i];
      if (action.action == IntegrationIssueActionLogEntryAction.RESOLVE) {
        switch (action.actor_type) {
          case IntegrationIssueActor.AUTO_ACTOR:
            return "Auto-resolved";
          case IntegrationIssueActor.USER_ACTOR:
            return `Resolved by ${action.user_name || action.user_email}`;
          default:
            return "Resolved";
        }
      }
    }
  }
  return "Resolved";
};

type IntegrationsManagementIndividualIssuePageProps = {
  issue: IntegrationIssue;
  integrationIssueLogs: APIRequestLogEntry[];
};
const IntegrationsManagementIndividualIssuePage = ({
  issue,
  integrationIssueLogs,
}: IntegrationsManagementIndividualIssuePageProps) => {
  const [integrationIssue, setIntegrationIssue] = useState<IntegrationIssue>(issue);

  const [isCommentModalShown, setIsCommentModalShown] = useState<boolean>(false);
  const { user } = useAppContext();
  const history = useHistory<any>();
  const [isDemoState, setIsDemoState] = useState<boolean>(user.is_demo);
  const [resolveLoading, setResolveLoading] = useState<boolean>(false);

  const { integrationIssueID } = useParams<{
    integrationIssueID: string;
  }>();
  const isFromLinkedAccountPage = history?.location?.state?.from == "linked_account_page";
  const isResolved = integrationIssue?.status === IntegrationIssueStatus.RESOLVED;

  const isMuted = integrationIssue ? isIssueMuted(integrationIssue) : false;

  const hasErrorDetails =
    integrationIssue?.metadata?.some(
      (metadata_element) => metadata_element.user_facing_error_detail,
    ) ?? false;

  const updateStatus = (status: IntegrationIssueStatus) => {
    if (user.is_demo) {
      const issue = JSON.parse(JSON.stringify(integrationIssue));
      issue.status = status;
      setIntegrationIssue(issue);
      showSuccessToast("Issue marked as " + status.toLowerCase() + ".");
    } else {
      setResolveLoading(true);
      updateIntegrationIssueStatus({
        integrationIssueID,
        status,
        onSuccess: (data: IntegrationIssue) => {
          setIntegrationIssue(data);
          setResolveLoading(false);
          showSuccessToast("Issue marked as " + status.toLowerCase() + ".");
        },
        onError: () => {
          showErrorToast("Issue resolution failing.");
        },
      });
    }
  };

  function clickResolveButton() {
    if (isResolved) {
      updateStatus(IntegrationIssueStatus.ONGOING);
    } else {
      updateStatus(IntegrationIssueStatus.RESOLVED);
    }
  }

  useEffect(() => {
    setIsDemoState(user.is_demo);
  }, []);

  useEffect(() => {
    if (isDemoState != user.is_demo) {
      navigateToIssuesPage(history);
    }
  }, [user.is_demo]);

  const integration = integrationIssue?.linked_account.integration &&
    integrationIssue.linked_account.category && (
      <Section>
        <MetadataPairWithLogo
          type="Integration"
          text={integrationIssue.linked_account.integration.name}
          logoSrc={integrationIssue.linked_account.integration.square_image}
          category={integrationIssue.linked_account.category}
        />
      </Section>
    );

  const status =
    integrationIssue?.status &&
    (integrationIssue.status === IntegrationIssueStatus.RESOLVED ? (
      <Section style={{ minWidth: 0 }}>
        <ResolvedIssueBanner>{getResolvedBy(integrationIssue)}</ResolvedIssueBanner>
      </Section>
    ) : (
      <Section>
        <IssuesStatusBadge
          className="deprecated-p-2 text-center w-100"
          status={integrationIssue.status}
          muted={isMuted}
        />
      </Section>
    ));

  const updatedDate = integrationIssue && (
    <Section>
      <MetadataPair
        label="Updated"
        data={new Date(getIssueUpdatedAt(integrationIssue)).toLocaleDateString(
          undefined,
          DATE_FORMAT,
        )}
      />
    </Section>
  );

  const createdDate = integrationIssue?.first_incident_time && (
    <Section>
      <MetadataPair
        label="Created"
        data={new Date(integrationIssue.first_incident_time).toLocaleDateString(
          undefined,
          DATE_FORMAT,
        )}
      />
    </Section>
  );

  return (
    <PortalPageHeaderWrapper
      title={
        <>
          {integrationIssue && (
            <>
              {integrationIssue.error_description}
              {isMuted && <span className="fe fe-volume-x deprecated-ml-3 deprecated-mr-2" />}
              <IssuesStatusBadge
                className="deprecated-ml-2 font-size-50"
                status={integrationIssue.status}
                muted={isMuted}
              />
              {user.is_demo && (
                <StatusBadge status="Demo" className="deprecated-ml-3 font-size-50" />
              )}
            </>
          )}
        </>
      }
      isMaxWidthEnabled
      subtitle={
        <>
          {integrationIssue && (
            <div className="align-items-center">
              <ClickableContainer>
                <Link
                  to={
                    isFromLinkedAccountPage
                      ? getLinkedAccountIssuesPathForId(integrationIssue?.linked_account.id)
                      : ISSUES_PATH
                  }
                  className="font-semibold blue-text small align-items-center"
                >
                  <Chevron size="12" className="deprecated-mr-2" />
                  {isFromLinkedAccountPage ? "Back to Linked Account" : "Back to Issues"}
                </Link>
              </ClickableContainer>
              {isResolved != undefined && (
                <Button
                  className="float-right"
                  variant="white"
                  size="sm"
                  onClick={clickResolveButton}
                >
                  {resolveLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm deprecated-mr-2"
                        role="status"
                        aria-hidden="true"
                      />
                      {isResolved ? "Reopening" : "Resolving"}
                    </>
                  ) : (
                    <>
                      <span className="fe fe-check deprecated-mr-2" />
                      {isResolved ? "Resolved" : "Resolve Issue"}
                    </>
                  )}
                </Button>
              )}
              <IndividualIssueMuteButton
                integrationIssue={integrationIssue}
                onIssueUpdate={setIntegrationIssue}
              />
            </div>
          )}
        </>
      }
    >
      <>
        <Row>
          <Col xs="12" md="9" lg="9" xl="9">
            {hasErrorDetails ? (
              <IntegrationManagementIndividualIssueDescriptionWithErrorDetails
                integrationIssue={integrationIssue}
              />
            ) : (
              <IntegrationManagementIndividualIssueDescription
                integrationIssue={integrationIssue}
              />
            )}
            <IntegrationManagementIndividualIssueActivity
              integrationIssue={integrationIssue}
              issueLogs={integrationIssueLogs}
            />
          </Col>
          <Col xs="12" md="3" lg="3" xl="3">
            {integrationIssue && (
              <>
                {integrationIssue.status == IntegrationIssueStatus.RESOLVED ? (
                  <>
                    {status}
                    {integration}
                  </>
                ) : (
                  <>
                    {integration}
                    {status}
                  </>
                )}

                {updatedDate}
                {createdDate}
                <hr />

                <Section>
                  <EndUserMetadata endUser={integrationIssue.linked_account.end_user} />
                </Section>

                <Section>
                  <Button
                    variant="white"
                    size="sm"
                    className="font-semibold w-100 deprecated-p-2"
                    onClick={() => setIsCommentModalShown(true)}
                  >
                    <span className="fe fe-message-square deprecated-mr-2" />
                    {integrationIssue && integrationIssue.comment_count} Comments
                  </Button>
                </Section>
              </>
            )}
          </Col>
        </Row>
        <IntegrationIssueCommentModal
          integrationIssue={integrationIssue}
          isShown={isCommentModalShown}
          setIsShown={setIsCommentModalShown}
        />
        <Route
          exact
          path={`${ISSUES_PATH}/:integrationIssueID/log-entry/:logID`}
          render={() => <APIRequestLogEntrySidePanel fetchAPIRequestLog={fetchLogEntryByID} />}
        />
      </>
    </PortalPageHeaderWrapper>
  );
};

export default IntegrationsManagementIndividualIssuePage;
