import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { RefreshCcwIcon } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface FirstSyncWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: SelectedWebhookType | undefined;
}

function FirstSyncWebhook(props: FirstSyncWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Initial sync completed"
        description="Receive an alert when the first sync of a Linked Account is completed."
        icon={<RefreshCcwIcon size={16} />}
        onClick={() => onSelectedWebhookTypeChange(SelectedWebhookType.FIRST_SYNC)}
        selected={selectedWebhookType === SelectedWebhookType.FIRST_SYNC}
        deprecated={true}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default FirstSyncWebhook;
