import { X } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styled, { css } from "styled-components";
import type {
  DateLogFilter,
  DropdownLogFilter,
  EditableTextLogFilter,
  ReadonlyLogFilter,
} from "../../../models/LogFilter";
import { Comparator, LogDirection, WebhookType } from "../../../models/LogFilter";
import { palette, spectrum } from "../../../styles/theme/colors";
import createCommaSeparatedElements from "../utils/createCommaSeparatedElements";
import isNonNull from "../utils/isNonNull";
import moveSelectionToEnd from "../utils/moveSelectionToEnd";
import nodeHasTarget from "../utils/nodeHasTarget";
import type { Dates, OpenDatePickerModal } from "../utils/useModals";
import EditableLine from "./EditableLine";
import type { DisplayType } from "../logs/modals/DatePickerModal";
import SearchDropdownMenu from "./SearchDropdownMenu";
import SearchPillContainer from "./SearchPillContainer";

type NonDropdownProps = {
  findChoicesMatchingSearchString?: never;
};

type NonDateProps = {
  setDates?: never;
  openDatePicker?: never;
};

type InteractiveProps = {
  /**
   * A function that deletes this search pill itself, called when the x
   * button is clicked.
   */
  deleteSearchPill: () => void;

  /**
   * Mutates filter comparators if needed
   */
  setCurrentComparators?: (comparators: ReadonlyLogFilter["currentComparators"]) => void;

  /**
   * Mutates filter options if needed
   */
  setCurrentFilterOptions?: (options: ReadonlyLogFilter["currentFilterOptions"]) => void;

  /**
   * Optional notifier for clicks/other things that cause the dropdown
   * to open or the editable text to start editing.
   */
  onUserInteraction?: () => void;

  /**
   * Notifies specifically when the editable text is clicked to start
   * editing
   */
  onEditableTextMouseDown?: () => void;
};

type ReadonlyProps = ReadonlyLogFilter &
  NonDropdownProps &
  NonDateProps & {
    /**
     * No mutation/focusing possible
     */

    deleteSearchPill?: never;
    setCurrentComparators?: never;
    setCurrentFilterOptions?: never;
    onUserInteraction?: never;
    onEditableTextMouseDown?: never;
  };

type DropdownProps = DropdownLogFilter &
  InteractiveProps &
  NonDateProps & {
    /**
     * Should update the `currentFilterOptions` with new data by filtering
     * down the options based on a search string. User input will be
     * debounced, but the parent that passes this object is responsible for
     * making network calls/cancelling calls that are no longer needed.
     * Optional if we get all the results to start with and don't need
     * to load more individually.
     */
    findChoicesMatchingSearchString?: (searchString: string) => void;
  };

type EditableProps = EditableTextLogFilter & InteractiveProps & NonDateProps & NonDropdownProps;

type DateProps = DateLogFilter &
  InteractiveProps &
  NonDropdownProps & {
    /**
     * Mutates the dates themselves
     */
    setDates: (dates: Dates) => void;

    /**
     * Date log filters need a way to open the date picker
     */
    openDatePicker: OpenDatePickerModal;
  };

export type Props = {
  /**
   * Highlighted pills show up as blue, non-highlighted appear gray
   */
  isHighlighted?: boolean;

  /**
   * Controlled state from a parent, if needed, for either editing or
   * dropdown state
   */
  isOpenOrFocused?: boolean;
} & (ReadonlyProps | DropdownProps | EditableProps | DateProps);

// Hover and padding for either side of the container - also truncates
const ButtonSection = styled.div.attrs({ className: "d-flex align-items-center" })<{
  $isRight?: boolean;
  $isHidden?: boolean;
  $isReadonly: boolean;
}>`
  transition:
    transform 0.1s ease,
    padding 0.1s ease,
    background-color 0.1s;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({ $isReadonly }) =>
    !$isReadonly &&
    css`
      cursor: pointer;
      &:hover,
      &:active,
      &:focus {
        background-color: ${spectrum.gray20};
      }
    `}
  ${({ $isRight }) =>
    $isRight
      ? css`
          padding: 9px 10px;
          margin-left: -4px;
          z-index: 1;
        `
      : css`
          padding: 9px 10px;
        `};
  ${({ $isHidden }) =>
    $isHidden &&
    css`
      transform: scaleX(0);
      padding: 0;
    `}
`;

// Starts out hidden, animates width if visible
const AnimatedDots = styled.span<{ $isVisible: boolean }>`
  transition:
    transform 0.2s ease,
    max-width 0.2s ease;
  display: inline-block;
  transform: scaleX(0);
  max-width: 0;
  ${({ $isVisible }) =>
    $isVisible &&
    css`
      transform: initial;
      max-width: 20px;
    `}
`;

/**
 * Shows a gray or blue search pill with a filter name and the currently
 * selected values for that filter. Has a certain type of data, exposed
 * via `type`. Starts out showing the comparator choices if no comparator is chosen
 */
const SearchPill = ({
  isHighlighted = false,
  isOpenOrFocused,
  deleteSearchPill,
  name,
  type,
  currentComparators: externalFilterComparators,
  setCurrentComparators: externalSetCurrentComparators,
  currentFilterOptions: externalFilterOptions,
  setCurrentFilterOptions: externalSetCurrentFilterOptions,
  findChoicesMatchingSearchString,
  onUserInteraction,
  onEditableTextMouseDown,
  openDatePicker,
  dates,
  setDates,
}: Props) => {
  const isReadonly = type === "readonly";
  const isEditable = type === "editableText";

  // Use external values to create, then set like normal
  const [currentComparators, rawSetCurrentComparators] = useState(externalFilterComparators);
  const [currentFilterOptions, rawSetCurrentFilterOptions] = useState(externalFilterOptions);
  const setCurrentComparators = useMemo(
    () => externalSetCurrentComparators ?? rawSetCurrentComparators,
    [externalSetCurrentComparators],
  );
  const setCurrentFilterOptions = useMemo(
    () => externalSetCurrentFilterOptions ?? rawSetCurrentFilterOptions,
    [externalSetCurrentFilterOptions],
  );

  // The currently selected comparator if we have one
  const currentComparator = useMemo(
    () => Object.entries(currentComparators).find(([, meta]) => meta.isEnabled)?.[1]?.displayName,
    [currentComparators],
  );

  const isEditableWithComparator = isEditable && !!currentComparator;
  const isDateWithComparator = type === "date" && !!currentComparator;

  // Updates internally when external dependencies change
  useEffect(() => {
    if (externalFilterComparators !== currentComparators) {
      rawSetCurrentComparators(externalFilterComparators);
    }
  }, [currentComparators, externalFilterComparators]);

  // Updates internally when external dependencies change
  useEffect(() => {
    if (externalFilterOptions !== currentFilterOptions) {
      rawSetCurrentFilterOptions(externalFilterOptions);
    }
  }, [currentFilterOptions, externalFilterOptions]);

  // Used to unbold an existing value if editable
  const [isUserEditing, setIsUserEditing] = useState(
    (isEditableWithComparator && isOpenOrFocused) ?? false,
  );
  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(
    (!isReadonly && !isEditableWithComparator && !isDateWithComparator && isOpenOrFocused) ?? false,
  );
  const enabledFilterOptionsLength = useMemo(
    () => Object.entries(currentFilterOptions).filter(([, meta]) => meta.isEnabled).length,
    [currentFilterOptions],
  );
  const currentFilterOptionsLength = useMemo(
    () => Object.entries(currentFilterOptions).length,
    [currentFilterOptions],
  );

  // Shows ... if there's no comparator set or no values or the user is actively editing
  const showsDots = useMemo(
    () => !currentComparator || enabledFilterOptionsLength < 1 || isUserEditing,
    [enabledFilterOptionsLength, currentComparator, isUserEditing],
  );

  // If we have editable content, this is used for editing it
  const editableRef = useRef<HTMLDivElement>(null);

  // The menu itself when the dropdown shows
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  // The whole dropdown element surrounding the menu + toggle
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Focus the dropdown menu when opening it so we can use keyboard to navigate + close it when clicking outside the menu while open
  useEffect(() => {
    if (!isDropdownOpen) {
      return;
    }

    const closeDropdown = (event: MouseEvent) => {
      if (!nodeHasTarget(event.target, dropdownRef.current)) {
        setIsDropdownOpen(false);
      }
    };
    dropdownMenuRef.current?.focus();

    // HAS to be in a requestAnimationFrame or it immediately decides to close itself
    requestAnimationFrame(() => window.addEventListener("mousedown", closeDropdown));
    return () => window.removeEventListener("mousedown", closeDropdown);
  }, [isDropdownOpen]);

  // If anything is "on", call the handler for user interaction
  useEffect(() => {
    if (isUserEditing || isDropdownOpen) {
      onUserInteraction?.();
    }
  }, [isUserEditing, isDropdownOpen, onUserInteraction]);

  // Prevents editing from being applied to non-editable ones
  const startEditing = useCallback(() => setIsUserEditing(true), []);

  // Moves the cursor to the end of the editable ref and gives focus
  const moveFocusToEnd = useCallback(() => {
    if (editableRef.current === document.activeElement || !editableRef.current) {
      return;
    }
    startEditing();
    moveSelectionToEnd(editableRef);
  }, [startEditing]);

  // Opens the date picker with current options
  const showDatePicker = useCallback(() => {
    setIsDropdownOpen(false);
    const basicDisplayType = ((): DisplayType => {
      switch (currentComparator) {
        // Is before or is after needs a date + time, otherwise show just date
        case Comparator.GREATER_THAN:
        case Comparator.GREATER_THAN_OR_EQUAL:
        case Comparator.LESS_THAN:
        case Comparator.LESS_THAN_OR_EQUAL:
          return "datetime";
        default:
          return "date";
      }
    })();
    const displayType = dates?.end && dates?.start ? "range" : basicDisplayType;
    if (!setDates || !dates) {
      throw new TypeError("Missing dates data");
    }
    return openDatePicker?.({ displayType, setCurrentFilterOptions, setDates, dates });
  }, [currentComparator, dates, openDatePicker, setCurrentFilterOptions, setDates]);

  // Updates on external updates
  useEffect(() => {
    if (isOpenOrFocused === undefined) {
      return;
    }
    if (isEditableWithComparator) {
      setIsUserEditing(isOpenOrFocused);
    } else if (!isReadonly && !isDateWithComparator) {
      setIsDropdownOpen(isOpenOrFocused);
    } else if (isDateWithComparator && isOpenOrFocused) {
      showDatePicker();
    }
  }, [isDateWithComparator, isEditableWithComparator, isOpenOrFocused, isReadonly, showDatePicker]);

  // Colors depend on number of current values / highlighted status
  const backgroundColor = isHighlighted ? palette.white : spectrum.slate0;
  const foregroundColor = useMemo(() => {
    if (isHighlighted) {
      return palette.blue;
    }
    return showsDots ? palette.slate : palette.black;
  }, [isHighlighted, showsDots]);

  /**
   * "Translates" values to a human-readable string from the comparator
   * heavy text it has in some cases. Also translates "INBOUND"/"OUTBOUND"
   * to human readable text too.
   */
  const localizeChoice = useCallback(
    (choice: string) => {
      switch (choice) {
        case Comparator.CONTAINS:
          return "contains";
        case Comparator.NOT_CONTAINS:
          return "does not contain";
        case Comparator.EQUAL:
          return "is";
        case Comparator.NOT_EQUAL:
          return "is not";
        case Comparator.GREATER_THAN:
          return type === "date" ? "is after" : "is above";
        case Comparator.GREATER_THAN_OR_EQUAL:
          return type === "date" ? "is after or on" : "is above or equal to";
        case Comparator.LESS_THAN:
          return type === "date" ? "is before" : "is below";
        case Comparator.LESS_THAN_OR_EQUAL:
          return type === "date" ? "is before or on" : "is below or equal to";
        case LogDirection.INBOUND:
          return "Inbound";
        case LogDirection.OUTBOUND:
          return "Outbound";
        case WebhookType.CHANGED_DATA:
          return "Changed Data";
        case WebhookType.SYNC_NOTIFICATION:
          return "Sync Notification";
        case WebhookType.RECEIVING_WEBHOOK:
          return "Receiving Webhook";
        default:
          return choice;
      }
    },
    [type],
  );

  // Returns either a ... or the current items, separated by commas if appropriate
  const textValues = useMemo(() => {
    if (!currentComparator) {
      return "...";
    }

    const onlyEnabledFilterOptions = Object.entries(currentFilterOptions)
      .map(([_, meta]) => (meta.isEnabled ? meta.displayName : null))
      .filter(isNonNull);

    // Formats ranged dates so it doesn't say "X or Y" and instead is "X - Y"
    if (dates && onlyEnabledFilterOptions.length === 2) {
      const joinedOptions = onlyEnabledFilterOptions.join(" - ");
      return <strong>{joinedOptions}</strong>;
    }

    const strongifiedElements = onlyEnabledFilterOptions.map((choice) => (
      <strong key={choice as string}>{localizeChoice(choice as string)}</strong>
    ));

    // If the user can edit and there's a value, show it
    if (isEditable && onlyEnabledFilterOptions.length > 0) {
      return (
        <>
          {/* This allows the ... to animate in when the user starts typing */}
          <AnimatedDots $isVisible={isUserEditing}>...</AnimatedDots>
          &nbsp;
          <EditableLine
            editableRef={editableRef}
            formattedValue={createCommaSeparatedElements(strongifiedElements)}
            setCurrentFilterOptions={setCurrentFilterOptions}
            isUserEditing={isUserEditing}
            startEditing={startEditing}
            endEditing={() => setIsUserEditing(false)}
            onMouseDown={() => {
              onUserInteraction?.();
              onEditableTextMouseDown?.();
            }}
          />
        </>
      );
    }

    return createCommaSeparatedElements(strongifiedElements);
  }, [
    currentComparator,
    currentFilterOptions,
    dates,
    isEditable,
    localizeChoice,
    isUserEditing,
    setCurrentFilterOptions,
    startEditing,
    onUserInteraction,
    onEditableTextMouseDown,
  ]);

  // Creates dropdown choices out of all options or all comparator choices as needed
  const currentDropdownOptions = useMemo(
    () => (currentComparator ? currentFilterOptions : currentComparators),
    [currentComparator, currentFilterOptions, currentComparators],
  );

  const toggleDropdown = () => setIsDropdownOpen((value) => !value);

  // Called when any item in the dropdown is selected
  const selectDropdownOption = useCallback(
    (eventKey: string | null) => {
      if (type !== "multiSelectDropdown") {
        setIsDropdownOpen(false);
      }

      // No comparator? set one
      if (!currentComparator) {
        setCurrentComparators(
          Object.fromEntries(
            Object.entries(currentComparators).map(([key, meta]) => [
              key,
              { displayName: meta.displayName, isEnabled: key === eventKey },
            ]),
          ),
        );
        return;
      }

      // If we don't allow multiSelect, just set the current values to only have the event key selected
      if (type !== "multiSelectDropdown") {
        setCurrentFilterOptions(
          Object.fromEntries(
            Object.entries(currentFilterOptions).map(([key, meta]) => [
              key,
              { displayName: meta.displayName, isEnabled: key === eventKey },
            ]),
          ),
        );
        return;
      }

      // With multiSelect, toggle selection for the event key, but keep the rest the same
      setCurrentFilterOptions(
        Object.fromEntries(
          Object.entries(currentFilterOptions).map(([key, meta]) => [
            key,
            {
              displayName: meta.displayName,
              isEnabled: key === eventKey ? !meta.isEnabled : meta.isEnabled,
            },
          ]),
        ),
      );
    },
    [
      currentFilterOptions,
      currentComparators,
      currentComparator,
      setCurrentFilterOptions,
      setCurrentComparators,
      type,
    ],
  );

  // Either we move focus when clicking or we show the dropdown or date picker
  const clickHandler = useMemo(() => {
    if (isEditableWithComparator) {
      return moveFocusToEnd;
    }
    if (isDateWithComparator) {
      return showDatePicker;
    }
    return toggleDropdown;
  }, [isDateWithComparator, isEditableWithComparator, moveFocusToEnd, showDatePicker]);

  // Make sure to override the dropdown for the editing case
  return (
    <Dropdown
      style={{ maxWidth: "100%" }}
      onSelect={selectDropdownOption}
      show={!isEditableWithComparator && !isReadonly && isDropdownOpen}
      ref={dropdownRef}
    >
      <Dropdown.Toggle
        as={SearchPillContainer}
        className="d-flex align-items-center"
        $color={foregroundColor}
        $backgroundColor={backgroundColor}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={isReadonly ? undefined : clickHandler}
      >
        <ButtonSection $isReadonly={isReadonly}>
          {name}
          {currentComparator ? ` ${localizeChoice(currentComparator as string)}` : ""}
          {!showsDots && !isEditable ? <>&nbsp;{textValues}</> : textValues}
          {isEditableWithComparator && textValues === "..." && (
            <>
              &nbsp;
              <EditableLine
                editableRef={editableRef}
                setCurrentFilterOptions={setCurrentFilterOptions}
                isUserEditing={isUserEditing}
                startEditing={startEditing}
                endEditing={() => setIsUserEditing(false)}
                onMouseDown={() => {
                  onUserInteraction?.();
                  onEditableTextMouseDown?.();
                }}
              />
            </>
          )}
        </ButtonSection>
        <ButtonSection
          $isRight
          $isHidden={isReadonly || !isHovered}
          $isReadonly={isReadonly}
          onClick={(event) => {
            if (isReadonly) {
              return;
            }
            event.preventDefault();
            event.stopPropagation();
            setIsDropdownOpen(false);
            deleteSearchPill?.();
          }}
        >
          <X size={12} color={palette.black} />
        </ButtonSection>
      </Dropdown.Toggle>
      <Dropdown.Menu
        ref={dropdownMenuRef}
        as={SearchDropdownMenu}
        localizeChoice={localizeChoice}
        allowMultiSelect={type === "multiSelectDropdown" && !!currentComparator}
        currentOptions={currentDropdownOptions}
        selectOption={selectDropdownOption}
        closeMenu={() => setIsDropdownOpen(false)}
        focusMenu={() => dropdownMenuRef.current?.focus()}
        findChoicesMatchingSearchString={
          currentComparator && currentFilterOptionsLength > 5
            ? findChoicesMatchingSearchString
            : undefined
        }
      />
    </Dropdown>
  );
};

export default SearchPill;
