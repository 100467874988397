import { Globe, User } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingCreationAndEditDict,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../models/Entities";
import { CodeText } from "../../../../shared-components/MergeText";
import { EllipsesToggle } from "../../../../shared-components/MergeToggles";
import DeleteFieldMappingModal from "./DeleteFieldMappingModal";
import MergeTypeahead from "../../../../shared-components/MergeTypeahead";
import { createFieldMappingOptions } from "./CreateLinkedAccountFieldMappingPage";
import {
  EditFieldMappingInstanceProps,
  NewFieldMappingProps,
  createLinkedAccountFieldMapping,
  editFieldMappingInstance,
  fetchFieldMappingInstance,
} from "../../../../../api-client/APIClient";
import { FieldMappingSource } from "../../../../../constants";
import { capitalizeFirstLetter, SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { palette } from "../../../../../styles/theme";
import { parseFieldMappingExample } from "./helpers";
import { ArrowLeft } from "lucide-react";
type Props = {
  fieldMapping: FieldMappingInstance;
  linkedAccount: LinkedAccount;
  fieldMappingConfig: FieldMappingCommonModelConfiguration;
  fieldMappingMetaResponse: FieldMappingMetaResponse | undefined;
  common_model: string;
  newMapping?: boolean;
  newMappingSaved?: (instance: FieldMappingInstance) => void;
  cancelNewMapping?: () => void;
  originOptionsAvailable?: boolean;
};

export const DropDownOptionColumn1 = styled.div`
  width: 70px;
  align-items: start;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--lm-gray-50);
`;

export const SFMonoText = styled.span`
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
    monospace;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${palette.black};
`;

export const OriginTypeBadge = styled.span`
  background: var(--gray0);
  white-space: nowrap;
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
    monospace;
  font-size: 11px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: 0em;
  color: var(--lm-gray-70);
  border-radius: 4px;
  gap: 10px;
`;

export const LinkedAccountFieldMappingContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 0.5fr 4fr 0.1fr;
`;

export const DropdownItem = styled.div`
  row-gap: 4px;
  .api-info {
    color: var(--gray50);
  }

  &:hover {
    .api-info {
      color: black;
    }
  }
`;

export const FieldMappingInfoCard = styled.div`
  border-radius: 8px 8px 0px 0px !important;
  padding: 16px 24px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  min-height: 74px;
`;

const FieldMappingInfoCardDisplayContainer = styled(FieldMappingInfoCard)`
  flex-grow: 1;
`;

export const FieldMappingTargetContainer = styled.div`
  border: 0.5px solid rgba(220, 226, 234, 1);
  border-radius: 8px 8px 8px 8px;
  width: inherit;
`;

export const FieldMappingInfoTopRow = styled.div`
  overflow-wrap: anywhere;
`;

export const IconContainer = styled.div`
  justify-self: center;
  align-self: center;
  padding: 16px 24px;
`;

type TargetMessageProps = {
  backgroundColor: string;
  fontColor: string;
};

const StyledFormControl = styled(Form.Control)`
  border-radius: 6px;
  background: #fff;
  border: 0px;
  box-shadow:
    0px 1.5px 4px -1px rgba(0, 0, 0, 0.16),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
`;

export const StyledSmallTextMutedParagraph = styled.p`
  color: var(--lm-gray-60);
  font-family: Inter;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 300px;
  overflow-wrap: anywhere;
`;

export const StyledDropdownAPIEndpointPath = styled.div`
  color: rgba(147, 154, 165, 1);
  font-family: Inter;
  font-style: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  overflow-wrap: anywhere;
  width: 80%;

  ::hover {
    color: black;
  }
`;

export const StyledBoldSmallTextMutedSpan = styled.span`
  color: var(--lm-gray-60);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

export const StyledSmallTextMutedSpan = styled.span`
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: var(--lm-gray-60);
`;

export const TargetMessage = styled.div<TargetMessageProps>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.fontColor};
  font-weight: 400;
  font-size: 10px;
  padding: 10px 24px;
  border-radius: 0 0 8px 8px;
`;

const DropdownContainer = styled.div`
  margin-left: 8px;
  width: 17px;
`;

const StyledCancelButton = styled.div`
  border-radius: 4px;
  background: var(--white, #fff);
  color: rgba(17, 19, 23, 1);
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
const StyledSaveButton = styled.div<{ disabled: boolean }>`
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? 0.3499999940395355 : 1)};
  background: var(--white, #fff);
  color: rgba(17, 19, 23, 1);
  /* App/Small */
  box-shadow:
    0px 1.5px 4px -1px rgba(0, 0, 0, 0.16),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const ButtonText = styled.span`
  color: var(--lm-slate-90);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;

export const hideTooltipOnScroll = (show: boolean) => {
  if (show) {
    const scrollableElements = [document.querySelector("[role='listbox']"), document];

    scrollableElements.forEach((element) => {
      element?.addEventListener("scroll", (event) => {
        const tooltip = document.querySelector("[role='tooltip']");
        if (tooltip) {
          tooltip.className = "d-none";
        }
      });
    });
  }
};

const FieldMappingInstanceCard = ({
  fieldMapping: fieldMapping,
  linkedAccount,
  fieldMappingConfig,
  fieldMappingMetaResponse,
  common_model,
  newMapping = false,
  cancelNewMapping = () => {},
  newMappingSaved = () => {},
  originOptionsAvailable,
}: Props) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [creatingNewMapping, setCreatingNewMapping] = useState<boolean>(newMapping);
  const [fieldIsEmpty, setFieldIsEmpty] = useState<boolean>(true);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [typeaheadLoading, setTypeaheadLoading] = useState<boolean>(false);

  const remoteDataEnabled = fieldMappingConfig.has_remote_data_enabled;

  const [editedFieldMappingKey, setEditedFieldMappingKey] = useState("");
  const [editedFieldMappingDescription, setEditedFieldMappingDescription] = useState("");
  const [addToOrganizationFieldMappings, setAddToOrganizationFieldMappings] =
    useState<boolean>(false);
  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict[]>([]);
  const [fieldMappingTarget, setFieldMappingTarget] = useState<FieldMappingTarget | null>(null);
  const [fieldMappingInstance, setFieldMappingInstance] =
    useState<FieldMappingInstance>(fieldMapping);
  const fieldMappingMetaOptions = fieldMappingMetaResponse?.available_field_mappings;

  const fieldMappingTargetID =
    fieldMappingInstance.is_integration_wide && fieldMappingInstance.field_mapping_target !== null
      ? fieldMappingInstance.field_mapping_target.id
      : undefined;

  const [fieldMappingInstanceID, setFieldMappingInstanceID] = useState<string | undefined>(
    fieldMapping.is_integration_wide && fieldMapping.field_mapping_target !== null
      ? undefined
      : fieldMapping.id,
  );

  const isEditDisabled =
    !remoteDataEnabled ||
    (originOptionsAvailable != undefined && !originOptionsAvailable) ||
    (fieldMappingInstance.is_integration_wide &&
      !fieldMappingInstance.enable_linked_account_level_overrides);

  const updateFieldMappingTarget = () => {
    if (fieldMappingTargetID && fieldMappingTarget == null) {
      setFieldMappingTarget(fieldMappingInstance.field_mapping_target ?? null);
    }
  };
  updateFieldMappingTarget();

  const fetchAndSetFieldMappingInstanceInfo = (onResponse: () => void) => {
    if (fieldMappingInstanceID && fieldMappingInstance == null) {
      fetchFieldMappingInstance(fieldMappingInstanceID, setFieldMappingInstance);
    }

    onResponse();
  };
  fetchAndSetFieldMappingInstanceInfo(() => {});

  useEffect(() => {
    if (creatingNewMapping == false && newMapping) {
      newMappingSaved(fieldMappingInstance);
    }
  }, [fieldMappingInstance]);

  const setandFetchFieldMappingInstance = (id: string) => {
    setFieldMappingInstanceID(id);
    setCreatingNewMapping(false);
    fetchFieldMappingInstance(id, setFieldMappingInstance, delayedLoading);
  };

  useEffect(() => {
    if (fieldMappingInstance && fieldMappingOptions) {
      const currentOption = fieldMappingOptions.find(
        (fieldMappingOption) =>
          fieldMappingOption.traversal_path.split(".").toString() ===
          fieldMappingInstance.field_traversal_path.toString(),
      );
      if (currentOption) {
        setOriginFields([currentOption]);
      }
    }
  }, [fieldMappingInstance, fieldMappingMetaOptions]);

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, common_model));
    }
  }, [fieldMappingMetaOptions]);

  const createFieldMapping = (
    passedOrigin: FieldMappingCreationAndEditDict[] | undefined = undefined,
  ) => {
    const origin = passedOrigin ? passedOrigin : originFields;

    if (origin.length == 1) {
      const body: NewFieldMappingProps = {
        linked_account_id: linkedAccount.id,
        common_model_id: `${linkedAccount.category}.${common_model}`,
        field_key: fieldMappingTarget?.field_key ?? editedFieldMappingKey,
        field_traversal_path: origin[0].traversal_path.split("."),
        create_for_organization: addToOrganizationFieldMappings,
        configured_by: FieldMappingSource.ORGANIZATION,
        ...(fieldMappingTarget?.field_description ?? editedFieldMappingDescription
          ? {
              field_description:
                fieldMappingTarget?.field_description ?? editedFieldMappingDescription,
            }
          : {}),
        ...(fieldMappingTargetID ? { field_mapping_target_id: fieldMappingTargetID } : {}),
        api_endpoint_id: origin[0].api_endpoint_id,
        display_name: origin[0].display_name,
        origin_type: origin[0].type,
      };

      createLinkedAccountFieldMapping(body, setandFetchFieldMappingInstance);
    }
  };

  const delayedLoading = () => {
    setTimeout(() => {
      setIsEditing(false);
      setTypeaheadLoading(false);
    }, 300);
  };

  const fetchAndUpdateFieldMappingInstance = () => {
    fetchFieldMappingInstance(fieldMappingInstanceID!, setFieldMappingInstance, delayedLoading);
  };

  const updateCustomMappingInstance = (originFields: any) => {
    if (fieldMappingInstanceID && originFields.length == 1) {
      const body: EditFieldMappingInstanceProps = {
        field_traversal_path: originFields[0].traversal_path.split("."),
        field_mapping_instance_id: fieldMappingInstanceID,
        api_endpoint_id: originFields[0].api_endpoint_id,
        origin_type: originFields[0].type,
        display_name: originFields[0].display_name,
        field_description: fieldMappingInstance?.field_description ?? editedFieldMappingDescription,
      };
      editFieldMappingInstance(fieldMappingInstanceID!, body, () => {
        fetchAndUpdateFieldMappingInstance();
      });
    }
  };

  useEffect(() => {
    if (fieldMappingInstance && fieldMappingOptions) {
      const currentOption = fieldMappingOptions.find(
        (fieldMappingOption) =>
          fieldMappingOption.traversal_path.split(".").toString() ===
          fieldMappingInstance.field_traversal_path.toString(),
      );
      if (currentOption) {
        setOriginFields([currentOption]);
      }
    }
  }, [fieldMappingInstance, fieldMappingMetaOptions]);

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, common_model));
    }
  }, [fieldMappingMetaOptions]);

  useEffect(() => {
    if (editedFieldMappingKey && originFields.length > 0) {
      setFieldIsEmpty(false);
    }
  }, [editedFieldMappingKey, originFields]);

  const FieldMappingTargetMessage = ({ id }: { id: string | undefined }) =>
    fieldMappingInstance?.field_mapping_target == null ? (
      <TargetMessage
        backgroundColor="#fff9e6"
        fontColor="#d9a800"
        className="flex flex-row items-center pl-5 pr-5"
      >
        <div className="d-flex mr-2">
          <User size={12} />
        </div>
        <div className="d-flex text-[10px] leading-[14px]">
          Linked Account-specific target field
        </div>
      </TargetMessage>
    ) : (
      <TargetMessage
        backgroundColor="#FFF7ED"
        fontColor="#F97316"
        className="flex flex-row items-center justify-between pl-5 pr-5 text-[10px] leading-[14px]"
      >
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex mr-2">
            <Globe size={12} />
          </div>
          <div className="d-flex ">Organization-wide target field</div>
        </div>
        {id !== undefined ? (
          <div>
            <a href={`/configuration/field-mappings/target-fields/${id}/settings/`}>View</a>
          </div>
        ) : (
          <></>
        )}
      </TargetMessage>
    );

  const FieldMappingInstanceMessage = ({ id }: { id?: string | undefined }) =>
    fieldMappingInstance?.is_integration_wide ? (
      <TargetMessage
        backgroundColor="#FFF7ED"
        fontColor="#F97316"
        className="d-flex flex-row align-items-center justify-content-between pl-5 pr-5"
      >
        <div className="d-flex flex-row align-items-center">
          <div className="d-flex mr-2">
            <Globe size={12} />
          </div>
          <div className="d-flex text-[10px] leading-[14px]">
            Organization-wide {linkedAccount?.integration?.name || "integration's"} mapping
          </div>
        </div>
      </TargetMessage>
    ) : (
      <TargetMessage
        backgroundColor="#fff9e6"
        fontColor="#d9a800"
        className="d-flex flex-row align-items-center"
      >
        <div className="d-flex mr-2">
          <User size={12} />
        </div>
        <div className="d-flex text-[10px] leading-[14px]">Linked Account-specific mapping</div>
      </TargetMessage>
    );

  // The dropdown logic below was a little complicated but basically:
  // 1. Render dropdown only if the field mapping is not int-wide OR it has linked account level overrides, and
  // if it's not the case that it's integration wide and remote data/shallow runs arent enabled.
  // 2. Render edit only if remote data was enabled. The edit page will either route to a linked account edit
  // if the rendered instance is a non int wide mapping or an override if its an int wide mapping
  // 3. Only show delete if it's not an integration wide mapping. cant delete from this page

  if (creatingNewMapping) {
    return (
      <LinkedAccountFieldMappingContainer>
        <FieldMappingTargetContainer className="d-flex flex-column">
          <FieldMappingInfoCard className="mb-0 h-100">
            <div className="d-flex flex-column justify-content-between w-100">
              <StyledFormControl
                name="target"
                type="text"
                placeholder="Field Name"
                defaultValue={""}
                onChange={(e: any) => setEditedFieldMappingKey(e.target.value)}
                className="mb-3"
              />
              <StyledFormControl
                name="target"
                type="text"
                placeholder="Description (Optional)"
                defaultValue={""}
                onChange={(e: any) => setEditedFieldMappingDescription(e.target.value)}
              />
              <div className="deprecated-mt-4" />
            </div>
          </FieldMappingInfoCard>
          <FieldMappingTargetMessage id={fieldMappingInstance?.field_mapping_target?.id} />
        </FieldMappingTargetContainer>
        <IconContainer>
          <ArrowLeft size={20} />
        </IconContainer>
        <div className="d-flex w-100">
          <FieldMappingTargetContainer className="d-flex flex-column">
            <div className="h-100">
              <div className="mx-5 my-4">
                <MergeTypeahead
                  id="typeahead"
                  disabled={
                    fieldMappingMetaOptions === undefined || fieldMappingOptions.length === 0
                  }
                  multiple={false}
                  onMenuToggle={hideTooltipOnScroll}
                  //@ts-ignore
                  selected={originFields}
                  options={fieldMappingOptions}
                  labelKey={(option: FieldMappingCreationAndEditDict) => option?.display_name ?? ""}
                  inputProps={{ autoComplete: "none" }}
                  isLoading={fieldMappingMetaOptions === undefined || typeaheadLoading}
                  placeholder={
                    fieldMappingMetaOptions === undefined
                      ? `Loading ${linkedAccount.integration.name} field info...`
                      : fieldMappingOptions.length > 0
                      ? "Search Fields..."
                      : "No fields found."
                  }
                  onChange={(selectedFieldMappings) => {
                    setOriginFields(selectedFieldMappings);
                  }}
                  isInvalid={false}
                  error={""}
                  includeChevronDown={
                    fieldMappingMetaOptions !== undefined && fieldMappingOptions.length > 0
                  }
                  renderMenuItemChildren={(fieldMapping) => {
                    const fieldMappingExample = parseFieldMappingExample(fieldMapping.value);

                    return (
                      <>
                        <DropdownItem className="d-flex flex-column justify-content-between align-content-center w-100 dropdown-menu-options">
                          <div className="d-flex flex-row align-items-start">
                            <DropDownOptionColumn1 className="d-flex align-items-center mt-0.5">
                              field:
                            </DropDownOptionColumn1>

                            <div
                              id="origin-field"
                              className=" w-100 text-break white-space-wrap truncate-container"
                            >
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id="tooltip-top"
                                    style={{
                                      width: "fit-content",
                                      height: "fit-content",
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <div className="d-flex flex-row">
                                        <div className="d-flex mr-1">
                                          <span>field:</span>
                                        </div>
                                        <div className="d-flex text-break white-space-wrap text-align-start font-weight-bold">
                                          <span>{fieldMapping.display_name}</span>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="d-flex flex-row">
                                          <div className="d-flex mr-1">
                                            <span>type:</span>
                                          </div>
                                          <div className="d-flex font-weight-bold text-align-start">
                                            {fieldMapping.type}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <SFMonoText className="d-inline mr-2">
                                  {fieldMapping.display_name}
                                </SFMonoText>
                              </OverlayTrigger>

                              {fieldMapping.type && (
                                <OriginTypeBadge className="d-inline-block text-break px-1">
                                  {capitalizeFirstLetter(fieldMapping.type)}
                                </OriginTypeBadge>
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <DropDownOptionColumn1>example:</DropDownOptionColumn1>
                            <div className="d-flex flex-row w-100 text-truncate">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-top">{fieldMappingExample}</Tooltip>}
                              >
                                <SFMonoText className="api-info text-truncate">
                                  {fieldMappingExample}
                                </SFMonoText>
                              </OverlayTrigger>
                            </div>
                          </div>
                          <div className="d-flex flex-row">
                            <DropDownOptionColumn1>endpoint:</DropDownOptionColumn1>
                            <div className="d-flex flex-row w-100 text-truncate">
                              <StyledDropdownAPIEndpointPath className="small api-info text-truncate">
                                {fieldMapping.api_endpoint_path}
                              </StyledDropdownAPIEndpointPath>
                            </div>
                          </div>
                        </DropdownItem>
                      </>
                    );
                  }}
                />
              </div>
              <div className="d-flex flex-row justify-content-end mr-5">
                <StyledCancelButton onClick={cancelNewMapping} className="mr-2">
                  <ButtonText>Cancel</ButtonText>
                </StyledCancelButton>
                <StyledSaveButton disabled={fieldIsEmpty} onClick={() => createFieldMapping()}>
                  <ButtonText>Save</ButtonText>
                </StyledSaveButton>
              </div>
            </div>

            <FieldMappingInstanceMessage />
          </FieldMappingTargetContainer>
        </div>
        <div className="w-6"></div>
      </LinkedAccountFieldMappingContainer>
    );
  }
  return (
    <LinkedAccountFieldMappingContainer>
      <DeleteFieldMappingModal
        fieldMappingID={fieldMappingInstance.id}
        showModal={showDeleteModal}
        linkedAccountID={linkedAccount.id}
        onHide={() => setShowDeleteModal(false)}
      />
      <FieldMappingTargetContainer className="d-flex flex-column">
        <FieldMappingInfoCard className="mb-0 h-100 align-items-start">
          <div className="d-flex flex-column w-100">
            <FieldMappingInfoTopRow className="d-flex flex-row align-items-center flex-wrap mb-2">
              <CodeText isBold fontSize="14px">
                {fieldMappingInstance.field_key}
              </CodeText>
            </FieldMappingInfoTopRow>
            <div>
              <div className="text-gray-50 text-[12px] mb-0 text-ellipsis overflow-hidden overflow-wrap-anywhere">
                {fieldMappingInstance.field_description}
              </div>
            </div>
          </div>
        </FieldMappingInfoCard>
        <FieldMappingTargetMessage id={fieldMappingInstance?.field_mapping_target?.id} />
      </FieldMappingTargetContainer>
      <IconContainer>
        <ArrowLeft size={20} />
      </IconContainer>
      <div className="d-flex w-100">
        <FieldMappingTargetContainer className="d-flex flex-column">
          {isEditing ? (
            <div className="mx-5 my-4 h-100">
              <MergeTypeahead
                id="typeahead"
                disabled={fieldMappingMetaOptions === undefined || fieldMappingOptions.length === 0}
                multiple={false}
                //@ts-ignore
                selected={originFields}
                options={fieldMappingOptions}
                labelKey={(option: FieldMappingCreationAndEditDict) => option?.display_name ?? ""}
                inputProps={{ autoComplete: "none" }}
                isLoading={fieldMappingMetaOptions === undefined || typeaheadLoading}
                placeholder={
                  fieldMappingMetaOptions === undefined
                    ? `Loading ${linkedAccount.integration.name} field info...`
                    : fieldMappingOptions.length > 0
                    ? "Search Fields..."
                    : "No fields found."
                }
                onMenuToggle={hideTooltipOnScroll}
                onChange={(selectedFieldMappings) => {
                  setOriginFields(selectedFieldMappings);
                }}
                isInvalid={false}
                error={""}
                includeChevronDown={
                  fieldMappingMetaOptions !== undefined && fieldMappingOptions.length > 0
                }
                renderMenuItemChildren={(fieldMapping) => {
                  const fieldMappingExample = parseFieldMappingExample(fieldMapping.value);

                  return (
                    <>
                      <DropdownItem className="d-flex flex-column justify-content-between align-content-center w-100 dropdown-menu-options">
                        <div className="d-flex flex-row align-items-start">
                          <DropDownOptionColumn1 className="d-flex align-items-center mt-0.5">
                            field:
                          </DropDownOptionColumn1>

                          <div
                            id="origin-field"
                            className=" w-100 text-break white-space-wrap truncate-container"
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip
                                  id="tooltip-top"
                                  style={{
                                    width: "fit-content",
                                    height: "fit-content",
                                  }}
                                >
                                  <div className="d-flex flex-column">
                                    <div className="d-flex flex-row">
                                      <div className="d-flex mr-1">
                                        <span>field:</span>
                                      </div>
                                      <div className="d-flex text-break white-space-wrap text-align-start font-weight-bold">
                                        <span>{fieldMapping.display_name}</span>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="d-flex flex-row">
                                        <div className="d-flex mr-1">
                                          <span>type:</span>
                                        </div>
                                        <div className="d-flex font-weight-bold text-align-start">
                                          {fieldMapping.type}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Tooltip>
                              }
                            >
                              <SFMonoText className="d-inline mr-2">
                                {fieldMapping.display_name}
                              </SFMonoText>
                            </OverlayTrigger>

                            {fieldMapping.type && (
                              <OriginTypeBadge className="d-inline-block text-break px-1">
                                {capitalizeFirstLetter(fieldMapping.type)}
                              </OriginTypeBadge>
                            )}
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <DropDownOptionColumn1>example:</DropDownOptionColumn1>
                          <div className="d-flex flex-row w-100 text-truncate">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="tooltip-top">{fieldMappingExample}</Tooltip>}
                            >
                              <SFMonoText className="api-info text-truncate">
                                {fieldMappingExample}
                              </SFMonoText>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <DropDownOptionColumn1>endpoint:</DropDownOptionColumn1>
                          <div className="d-flex flex-row w-100 text-truncate">
                            <StyledDropdownAPIEndpointPath className="small api-info text-truncate">
                              {fieldMapping.api_endpoint_path}
                            </StyledDropdownAPIEndpointPath>
                          </div>
                        </div>
                      </DropdownItem>
                    </>
                  );
                }}
              />
              <div className="flex items-center justify-content-end mt-3">
                <div
                  className="mr-2 font-semibold text-sm hover:cursor-pointer"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </div>
                <SmallWhiteButton
                  disabled={originFields ? false : true}
                  onClick={() => {
                    if (originFields) {
                      setTypeaheadLoading(true);

                      // if we are creating a linked account override for an integration wide field mapping we should be creating a new linked account field mapping instance
                      if (fieldMappingInstance?.is_integration_wide) {
                        createFieldMapping(originFields);
                      } else {
                        updateCustomMappingInstance(originFields);
                      }
                    }
                  }}
                >
                  Save
                </SmallWhiteButton>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-row align-items-start w-100 h-100">
              <FieldMappingInfoCardDisplayContainer className="mb-0">
                <div className="d-flex flex-column justify-content-between align-content-center w-100">
                  <FieldMappingInfoTopRow className="d-flex flex-row align-items-baseline flex-wrap mb-2">
                    <div className="d-flex mr-3">
                      <CodeText isBold fontSize="14px" className="mb-0">
                        {fieldMappingInstance.display_name}
                      </CodeText>
                    </div>
                    {fieldMappingInstance.origin_type && (
                      <div className="d-flex">
                        <div className="mb-0 text-[12px] text-gray-50">
                          {capitalizeFirstLetter(fieldMappingInstance.origin_type)}
                        </div>
                      </div>
                    )}
                  </FieldMappingInfoTopRow>
                  <div className="d-flex flex-row align-content-center">
                    <StyledSmallTextMutedParagraph className="small mb-0">
                      {fieldMappingInstance.remote_endpoint_method && (
                        <StyledBoldSmallTextMutedSpan className="mb-0 mr-3 text-gray-50 text-[10px] font-semibold">
                          {fieldMappingInstance.remote_endpoint_method}
                        </StyledBoldSmallTextMutedSpan>
                      )}
                      <span className="text-gray-50 text-[12px]">
                        {fieldMappingInstance.remote_endpoint_path}
                      </span>
                    </StyledSmallTextMutedParagraph>
                  </div>
                </div>
              </FieldMappingInfoCardDisplayContainer>
            </div>
          )}
          <FieldMappingInstanceMessage id={fieldMappingInstance?.field_mapping_target?.id} />
        </FieldMappingTargetContainer>
      </div>
      <Dropdown className="m-auto pl-1 pr-1">
        <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
          <i className="fe fe-more-vertical black" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="right" className="dropdown-ellipses dropdown-toggle">
          <Dropdown.Item
            className={`ellipses-dropdown-item dropdown-item text-sm hover:bg-gray-10 ${
              isEditDisabled ? "text-gray" : "text-black"
            }`}
            onClick={() => setIsEditing(true)}
            disabled={isEditDisabled}
          >
            Edit Mapping
          </Dropdown.Item>
          <Dropdown.Item
            className={`ellipses-dropdown-item dropdown-item text-red-50 text-sm hover:bg-gray-10 ${
              fieldMappingInstance.is_integration_wide ? "opacity-[0.35]" : ""
            }`}
            onClick={() => setShowDeleteModal(true)}
            disabled={fieldMappingInstance.is_integration_wide}
          >
            Delete Mapping
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* )} */}
    </LinkedAccountFieldMappingContainer>
  );
};

export default FieldMappingInstanceCard;
