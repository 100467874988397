import React, { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import MergeModal from "../../../shared-components/MergeModal";
import stripePromise from "./StripePromise";
import { Stripe } from "@stripe/stripe-js";
import BankAccountSetupForm from "../BankAccountSetupForm";

type Props = {
  show?: boolean;
  onHide: () => void;
};

const AddBankAccountModal = (props: Props) => {
  const [stripe, setStripe] = useState<Stripe | null | undefined>(null);

  stripePromise.then((value) => setStripe(value));

  return (
    <MergeModal title="Add Bank Account" show={props.show ?? false} onHide={props.onHide}>
      {stripe !== undefined ? (
        <Elements stripe={stripe}>
          <BankAccountSetupForm onHide={props.onHide} />
        </Elements>
      ) : (
        "Failed to load Stripe. Please refresh the page to try again."
      )}
    </MergeModal>
  );
};

export default AddBankAccountModal;
