import React, { ReactComponentElement, useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import styled from "styled-components";
import { palette, spectrum } from "../../../styles/theme";
import MergeModal from "../../shared-components/MergeModal";
import cx from "classnames";
import { useController, useForm } from "react-hook-form";
import { fetchWithAuth, FormErrorData } from "../../../api-client/APIClient";
import { BlackSpinnerButton } from "../../shared-components/SpinnerButton";
import { showErrorToast } from "../../shared-components/Toasts";
import { SmallWhiteButton, APICategory } from "@merge-api/merge-javascript-shared";
import SearchDropdownMenu from "../searchbar/SearchDropdownMenu";
import nodeHasTarget from "../utils/nodeHasTarget";
import DottedOutlineTextCard from "../../shared-components/DottedOutlineTextCard";
import { apiCategoryFromString, displayNameForAPICategory } from "../../../models/Helpers";
import DeprecatedH5 from "../../../deprecated/DeprecatedH5";
import { Link, Wand } from "lucide-react";
import { ReactComponent as MagicLinkHatSVG } from "../../../../src/components/get-started/src/MagicLinkHat.svg";
import useAppContext from "../../context/useAppContext";

const defaultCategories = Object.fromEntries(
  Object.keys(APICategory).map((category) => [category, false]),
) as Record<APICategory, boolean>;

const getSelectedCategories = (categories: Record<APICategory, boolean>): APICategory[] =>
  Object.entries(categories).flatMap(([category, isSelected]) =>
    isSelected ? [category as APICategory] : [],
  );

const StyledButton = styled(Button)`
  gap: 2px;
  background: #dde2fe;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  padding: 6px 10px 6px 16px;
  margin-top: 15px;
  margin-right: 20px;
  color: rgba(54, 37, 214, 1);
  border: rgba(221, 226, 254, 1);
  &:focus,
  &:active {
    background: #dde2fe;
    border: rgba(221, 226, 254, 1);
    color: rgba(54, 37, 214, 1);
    outline: 0;
    box-shadow: none;
  }
  &:hover {
    outline: 0;
    box-shadow: none;
    background: ${spectrum.indigo10};
    border: ${spectrum.indigo10};
    color: rgba(54, 37, 214, 1);
  }
  && {
    &:not(:disabled):not(.disabled):active {
      background: ${spectrum.indigo10};
      border: ${spectrum.indigo10};
      color: rgba(54, 37, 214, 1);
    }
  }
`;

const StyledWand = styled(Wand)`
  color: ${palette.black};
`;

const MinHeightDiv = styled.div`
  min-height: 610px;
`;

const StyledModal = styled(MergeModal)`
  .modal-content {
    min-height: 720px;
    width: 375px;
    padding: 24px;
    border-radius: 12px;
  }
  .modal-header {
    border-bottom: 0px;
    height: 32px;
    margin-bottom: 24px;
    align-items: flex-start;
    padding: 0px;
    .modal-title {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .modal-body {
    padding: 0px;
    min-height: 610px;
  }
`;

const StyledSpan = styled.span`
  color: #000;
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
  margin-bottom: 9px;
`;

const StyledLabel = styled.span`
  color: #000;
  font-size: 14px;
  font-family: Inter;
  line-height: 24px;
  margin-bottom: 9px;
  margin-left: 8px;
`;

const StyledMagicLinkHat = styled(MagicLinkHatSVG)`
  width: 327px;
  height: 293px;
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Content = styled(Col)`
  max-width: 335px;
  font-size: 12px;
  line-height: 22px;

  p {
    color: ${palette.slate};
  }

  .expiration-label {
    color: ${palette.gray};
  }

  .form-label {
    font-weight: 600;
  }

  .dropdown-toggle {
    font-weight: 400;
    font-size: 14px;
    color: ${palette.placeholder};
  }

  .btn-link {
    color: ${palette.black};
    font-weight: 400;
  }

  .dashed-outline-rounded-container {
    background-image: none;
  }

  .dropup .dropdown-menu {
    width: unset;
  }

  .form-control.is-invalid {
    #categoriesToggle {
      border-color: var(--red);
    }
  }
`;

const MaxWidthForm = styled(Form)`
  max-width: 335px;
  display: flex;
  flex-direction: column;
  min-height: 578.5px;
  justify-content: space-between;
`;

const MagicLinkOnboardingForm = styled(Form)`
  display: flex;
  flex-direction: column;
  min-height: 610px;
  justify-content: space-between;
`;

const OverflowPreventionToggle = styled(Dropdown.Toggle)`
  width: 335px;
`;

const OverflowPreventionDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface AddProductionLinkedAccountButtonProps {
  isGetStarted?: boolean;
}

const AddProductionLinkedAccountButton = ({
  isGetStarted,
}: AddProductionLinkedAccountButtonProps) => {
  const { user } = useAppContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [endUserOrganizationName, setEndUserOrganizationName] = useState<string | undefined>(
    undefined,
  );
  const [url, setURL] = useState<string | undefined>(undefined);
  const [wasDropdownOpened, setWasDropdownOpened] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, handleSubmit, errors, control, reset } = useForm({
    mode: "onBlur",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [disableOnboardingScreen, setDisableOnboardingScreen] = useState<boolean>(
    user.disable_magic_link_onboarding,
  );
  const [dismissOnboardingScreen, setDismissOnboardingScreen] = useState<boolean>(
    user.disable_magic_link_onboarding,
  );
  // The menu itself when the dropdown shows
  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  // The whole dropdown element surrounding the menu + toggle
  const {
    field: {
      ref: dropdownRef,
      value: categories,
      onChange: setCategories,
      onBlur,
      ...dropdownProps
    },
  } = useController({
    name: "categories",
    control,
    rules: {
      validate: (categories: Record<APICategory, boolean>) =>
        Object.values(categories).find((value) => value) || false,
    },
    defaultValue: defaultCategories,
  });

  const selectDropdownOption = useCallback(
    (eventKey: string | null) => {
      setCategories(
        Object.fromEntries(
          Object.entries(categories).map(([key, value]) => [
            key,
            key === eventKey ? !value : value,
          ]),
        ) as Record<APICategory, boolean>,
      );
    },
    [categories, setCategories],
  );

  useEffect(() => {
    if (!isDropdownOpen) {
      return;
    }

    const closeDropdown = (event: MouseEvent) => {
      if (!nodeHasTarget(event.target, dropdownRef.current)) {
        setIsDropdownOpen(false);
      }
    };

    // HAS to be in a requestAnimationFrame or it immediately decides to close itself
    requestAnimationFrame(() => window.addEventListener("mousedown", closeDropdown));
    return () => window.removeEventListener("mousedown", closeDropdown);
  }, [isDropdownOpen]);

  const onDisableMagicLinkOnboarding = useCallback(() => {
    setIsLoading(true);

    fetchWithAuth({
      path: `/users/me/disable-magic-link-onboarding`,
      method: "PATCH",
      onResponse: (data: any) => {
        if (!data?.success) {
          showErrorToast("An unkown error has occurred. Please try again later.");
        } else {
          setDisableOnboardingScreen(data.success);
        }
        setIsLoading(false);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            let wasToastShown = false;
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                showErrorToast(data[field_name][0]);
                wasToastShown = true;
              }
            }
            if (!wasToastShown) {
              showErrorToast("An unknown error has occurred. Please try again later.");
            }
          });
        } else {
          showErrorToast("A network error has occurred. Please try again.");
        }
        setIsLoading(false);
      },
    });
  }, []);

  const onSubmit = useCallback(
    (data: {
      uniqueIdentifier: string;
      email: string;
      organizationName: string;
      categories: Record<APICategory, boolean>;
    }) => {
      setIsLoading(true);
      const formData = {
        end_user_origin_id: data.uniqueIdentifier,
        end_user_organization_name: data.organizationName,
        end_user_email_address: data.email,
        categories: getSelectedCategories(data.categories),
        should_create_magic_link_url: true,
        link_expiry_mins: 10080,
      };
      fetchWithAuth({
        path: `/integrations/create-link-token`,
        method: "POST",
        body: formData,
        onResponse: (data: any) => {
          if (!data?.magic_link_url) {
            showErrorToast("An unkown error has occurred. Please try again later.");
          } else {
            setEndUserOrganizationName(formData.end_user_organization_name);
            setURL(data.magic_link_url);
          }
          setIsLoading(false);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              let wasToastShown = false;
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  showErrorToast(data[field_name][0]);
                  wasToastShown = true;
                }
              }
              if (!wasToastShown) {
                showErrorToast("An unknown error has occurred. Please try again later.");
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    },
    [setIsLoading],
  );

  const magicLinkOnboarding = (
    <>
      <MinHeightDiv className="justify-content-between d-flex flex-column">
        <MagicLinkOnboardingForm>
          <Row>
            <StyledSpan>
              Merge’s{" "}
              <a href="https://docs.merge.dev/guides/magic-link/" target="_blank" rel="noreferrer">
                Magic Link
              </a>{" "}
              allows you to deliver an in-browser Merge Link experience and create Production Linked
              Accounts without any frontend code.
            </StyledSpan>

            <StyledSpan>
              Send your users a secure URL to authorize their integrations in production.{" "}
            </StyledSpan>
          </Row>
          <Row>
            <StyledMagicLinkHat />
          </Row>
          <div className="d-flex justify-content-center flex-column">
            <Row>
              <StyledSpan>
                Learn how to <a href="https://docs.merge.dev/get-started/link/">embed Merge Link</a>{" "}
                in your app directly.
              </StyledSpan>
            </Row>
            <Row className="align-items-center">
              {" "}
              <Form.Check
                className="deprecated-mb-3 mr-1 d-flex align-items-center"
                type="checkbox"
                id="disableOnboardingScreen"
                value="disableOnboardingScreen"
                onClick={() => setDisableOnboardingScreen(!disableOnboardingScreen)}
                checked={disableOnboardingScreen}
              />{" "}
              <StyledLabel>Don't show this screen again</StyledLabel>{" "}
            </Row>
            {/* <Row>
              <Form.Check
                className="deprecated-mb-3 mr-1 d-flex align-items-center"
                type="checkbox"
                id="disableOnboardingScreen"
                value="disableOnboardingScreen"
                label={<StyledLabel>Don’t show this screen again</StyledLabel>}
                onClick={() => setDisableOnboardingScreen(!disableOnboardingScreen)}
                checked={disableOnboardingScreen}
              />
            </Row> */}
          </div>
          <Row>
            <BlackSpinnerButton
              onClick={() => {
                if (disableOnboardingScreen) {
                  onDisableMagicLinkOnboarding();
                }
                setDismissOnboardingScreen(true);
              }}
              text="Next"
              isLoading={false}
              className="btn-block"
            />
          </Row>
        </MagicLinkOnboardingForm>
      </MinHeightDiv>
    </>
  );

  const form = !url && (
    <>
      <MinHeightDiv className="justify-content-between d-flex flex-column">
        <Row>
          <h5>Enter your end user's information:</h5>
        </Row>
        <Row>
          <MaxWidthForm onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-5">
              <Form.Group controlId="organizationName" className="mb-6">
                <Form.Label>
                  <h6>Organization Name</h6>
                </Form.Label>
                <Form.Control
                  as="input"
                  name="organizationName"
                  type="text"
                  placeholder="Organization Name"
                  ref={register({ required: true, minLength: 1 })}
                  className={cx({
                    "is-invalid": errors.organizationName,
                  })}
                />

                <Form.Control.Feedback type="invalid" className="position-absolute">
                  Please enter a valid organization name.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email" className="mb-6">
                <Form.Label>
                  {" "}
                  <h6>Email</h6>
                </Form.Label>
                <Form.Text className="text-gray-50">
                  For identification purposes — this will not cause any emails to be sent.
                </Form.Text>
                <Form.Control
                  as="input"
                  name="email"
                  type="input"
                  placeholder="Email"
                  ref={register({
                    required: true,
                    validate: (value) => {
                      const input = document.createElement("input");

                      input.type = "email";
                      input.required = true;
                      input.value = value;

                      const result =
                        typeof input.checkValidity === "function"
                          ? input.checkValidity() || input.validationMessage
                          : /\S+@\S+\.\S+/.test(value);
                      input.remove();
                      return result;
                    },
                  })}
                  className={cx({
                    "is-invalid": errors.email,
                  })}
                />

                <Form.Control.Feedback type="invalid" className="position-absolute">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="categories" className="mb-6">
                <Form.Label>
                  {" "}
                  <h6>Categories</h6>
                </Form.Label>
                <Form.Control
                  as={Dropdown}
                  ref={dropdownRef}
                  onSelect={selectDropdownOption}
                  show={isDropdownOpen}
                  className={cx("p-0 border-0", { "is-invalid": errors.categories })}
                  drop="up"
                  onBlur={() => !(wasDropdownOpened && isDropdownOpen) && onBlur()}
                  {...dropdownProps}
                >
                  <OverflowPreventionToggle
                    id="categoriesToggle"
                    className="d-flex justify-content-between w-100"
                    aria-haspopup="true"
                    aria-expanded={isDropdownOpen}
                    variant="white"
                    onClick={() => {
                      setIsDropdownOpen((value) => !value);
                      setWasDropdownOpened(true);
                    }}
                    data-toggle="dropdown"
                  >
                    <OverflowPreventionDiv>
                      {(wasDropdownOpened &&
                        getSelectedCategories(categories)
                          .map(displayNameForAPICategory)
                          .join(", ")) ||
                        "Select..."}
                    </OverflowPreventionDiv>
                  </OverflowPreventionToggle>
                  <Dropdown.Menu
                    as={SearchDropdownMenu}
                    aria-labelledby="categoriesToggle"
                    ref={dropdownMenuRef}
                    allowMultiSelect
                    localizeChoice={(choice: string) =>
                      displayNameForAPICategory(apiCategoryFromString(choice))
                    }
                    currentOptions={categories}
                    selectOption={selectDropdownOption}
                    closeMenu={() => setIsDropdownOpen(false)}
                    focusMenu={() => dropdownMenuRef.current?.focus()}
                  />
                </Form.Control>
                <Form.Control.Feedback type="invalid" className="position-absolute">
                  Please select at least one category.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="uniqueIdentifier" className="mb-6">
                <Form.Label>
                  {" "}
                  <h6>End User Origin ID</h6>
                </Form.Label>
                <Form.Text className="text-gray-50">
                  Uniquely identifies a Linked Account i.e. a specific customer of yours for a given
                  integration.{" "}
                  <a
                    href="https://help.merge.dev/en/articles/8032943-end-user-origin-id-best-practices"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more
                  </a>{" "}
                </Form.Text>
                <Form.Control
                  as="input"
                  name="uniqueIdentifier"
                  type="text"
                  placeholder="End User Origin ID"
                  ref={register({ required: true, minLength: 1 })}
                  className={cx({
                    "is-invalid": errors.uniqueIdentifier,
                  })}
                />

                <Form.Control.Feedback type="invalid" className="position-absolute">
                  Please enter a valid unique identifier.
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <Row className="d-flex justify-content-center">
              <Col>
                <BlackSpinnerButton
                  text="Generate URL"
                  isLoading={isLoading}
                  className="btn-block"
                />
              </Col>
            </Row>
          </MaxWidthForm>
        </Row>
      </MinHeightDiv>
    </>
  );

  const urlDisplay = url && (
    <MinHeightDiv className="justify-content-between d-flex flex-column">
      <div>
        <Row>
          <StyledSpan>
            Send this URL to your user to authorize their integrations in production.
          </StyledSpan>
        </Row>
        <Row>
          <DeprecatedH5 className="deprecated-mt-2 deprecated-mb-3">
            URL for {endUserOrganizationName}
          </DeprecatedH5>
        </Row>
        <Row>
          <DottedOutlineTextCard text={url} className="w-100" isUrl={true} />
        </Row>
        <Row>
          <p className="expiration-label deprecated-mt-3">
            This URL will be shown once and will expire after 7 days.
          </p>
        </Row>
      </div>
      <Row className="d-flex justify-content-center">
        <BlackSpinnerButton
          text="Finish"
          isLoading={false}
          onClick={() => setIsModalOpen(false)}
          className="btn-block"
        />
      </Row>
    </MinHeightDiv>
  );

  const modalContent = () => {
    if (!dismissOnboardingScreen) {
      return magicLinkOnboarding;
    } else {
      return !url ? form : urlDisplay;
    }
  };

  return (
    <>
      <StyledModal
        show={isModalOpen}
        title="Magic Link"
        onHide={() => setIsModalOpen(false)}
        dialogClassName=""
        fitContent
        backdrop={url ? "static" : true}
        iconLeft={<StyledWand size={24} />}
      >
        <Content>{modalContent()}</Content>
      </StyledModal>

      {isGetStarted ? (
        <StyledButton
          onClick={() => {
            setEndUserOrganizationName(undefined);
            setURL(undefined);
            setWasDropdownOpened(false);
            reset();
            setIsModalOpen(true);
          }}
        >
          Create Magic Link via URL
        </StyledButton>
      ) : (
        <SmallWhiteButton
          onClick={() => {
            setEndUserOrganizationName(undefined);
            setURL(undefined);
            setWasDropdownOpened(false);
            reset();
            setIsModalOpen(true);
          }}
          leftIcon={<Link size={12} />}
        >
          Create Production Linked Account
        </SmallWhiteButton>
      )}
    </>
  );
};

export default AddProductionLinkedAccountButton;
