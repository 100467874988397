import React, { useMemo } from "react";
import styled from "styled-components";
import { LinkedAccountWebhookReceivers, WebhookReceiver } from "../../../../../models/Entities";
import { palette } from "../../../../../styles/theme";
import {
  MustUseAutoWebhookSetupNoticeCard,
  ThirdPartyWebhooksUpsellNoticeCard,
} from "../../../../configuration/webhooks/WebhooksRestrictionsNotices";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import { SectionHeaderWrapper, TitleHeading } from "../../../../shared-components/MergeLayouts";
import { Props as MetadataPageProps } from "../../../shared/MetadataPageLayout";
import WebhookEventsTable from "./WebhookEventsTable";
import useAppContext from "../../../../context/useAppContext";
import MaybeDisable from "../../../../shared-components/MaybeDisable";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { DOCS_WEBHOOKS_PATH, openInNewTab } from "../../../../../router/RouterUtils";
import { BookOpen } from "lucide-react";

type Props = Pick<MetadataPageProps, "linkedAccount"> & {
  linkedAccountID: string;
  /**
   * The webhooks data source
   */
  accountReceivers: LinkedAccountWebhookReceivers | null;

  /**
   * Takes one receiver at an index and updates it
   */
  updateReceiverAtIndex: (receiver: WebhookReceiver, index: number) => void;
};

const Section = styled.div`
  padding: 0 0 56px 0;
  &:not(:first-child) {
    padding: 56px 0;
    border-top: 1px solid ${palette.border};
  }
`;

const HeaderExplanation = styled.p`
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 32px;
`;

const WebhooksPage = ({
  linkedAccount,
  accountReceivers,
  updateReceiverAtIndex,
  linkedAccountID,
}: Props) => {
  const { user } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const integrationName = useMemo(() => linkedAccount?.integration.name ?? "", [linkedAccount]);

  const isUsingAutoWebhookSetup = Boolean(
    user.organization.enable_auto_webhook_setup &&
      linkedAccount?.integration?.is_auto_webhook_setup_enabled,
  );

  const isPlanWithThirdPartyWebhooks = Boolean(productRestrictions?.third_party_webhooks_enabled);

  // If the integration supports Auto Webhook Setup then manual setup is not supported.
  const showMustEnableAutoWebhookSetup =
    isPlanWithThirdPartyWebhooks &&
    linkedAccount?.integration.is_auto_webhook_setup_enabled &&
    !user.organization.enable_auto_webhook_setup;

  const isWebhookEventsTableDisabled =
    !isPlanWithThirdPartyWebhooks || showMustEnableAutoWebhookSetup;

  const headerContents = (
    <SectionHeaderWrapper
      title={<>{integrationName} Webhooks</>}
      titleHeading={TitleHeading.H1}
      subtitle={
        <HeaderExplanation>
          Configure {integrationName} to send real-time data updates to Merge for this Linked
          Account.
        </HeaderExplanation>
      }
      button={
        <SmallWhiteButton
          onClick={() => openInNewTab(DOCS_WEBHOOKS_PATH)}
          leftIcon={<BookOpen size={12} />}
        >
          Webhook Docs
        </SmallWhiteButton>
      }
    />
  );

  // If webhooks are disabled in ProductRestrictions, then we show an upsell to upgrade their organization's plan.
  // Otherwise, if the integration only supports auto webhook creation but the customer
  // doesn't have that setting enabled, then instruct them on how to do so.
  const webhooksUpsellOrEnableSetting = !isPlanWithThirdPartyWebhooks ? (
    <div style={{ marginBottom: "36px" }}>
      <ThirdPartyWebhooksUpsellNoticeCard />
    </div>
  ) : showMustEnableAutoWebhookSetup ? (
    <div style={{ marginBottom: "36px" }}>
      <MustUseAutoWebhookSetupNoticeCard integrationName={integrationName} />
    </div>
  ) : null;

  // Show detailed info if there's an upsell for webhooks to make the page more full
  return (
    <Section>
      {headerContents}
      {webhooksUpsellOrEnableSetting}
      <MaybeDisable disable={isWebhookEventsTableDisabled}>
        <WebhookEventsTable
          accountReceivers={accountReceivers}
          updateReceiverAtIndex={updateReceiverAtIndex}
          integrationName={integrationName}
          linkedAccountId={linkedAccountID}
          isUsingAutoWebhookSetup={isUsingAutoWebhookSetup}
        />
      </MaybeDisable>
    </Section>
  );
};

export default WebhooksPage;
