import React, { useCallback } from "react";
import { Card, Col, Row, Tooltip } from "react-bootstrap";
import useAppContext from "../../context/useAppContext";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared-components/Toasts";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import { StatusToggle } from "../../shared-components/MergeToggles";
import { useLocation } from "react-router-dom";

const ConfigurationCommonModelsSettings = () => {
  const { user, setUser } = useAppContext();
  const location = useLocation();
  const shouldShowRequestedData = user.organization?.should_show_requested_data_in_linking_flow;
  const canDisableRequestedData = !user.organization.show_selective_sync;

  const updateShowingRequestedData = useCallback(
    (newIsEnabled: boolean) => {
      fetchWithAuth({
        path: "/organizations/toggle-show-requested-data",
        method: "PATCH",
        body: {
          should_show_requested_data_in_linking_flow: newIsEnabled,
        },
        onResponse: () => {
          fetchCurrentUser(setUser);
        },
        onError: () => {
          showErrorToast("Something went wrong, please check your connection and try again.");
        },
      });
    },
    [setUser],
  );

  const tooltip = canDisableRequestedData ? undefined : (
    <Tooltip id="toggle-requested-data-tooltip">
      <div className="deprecated-my-2">
        Cannot <b>disable</b> the Requested Data Screen when Selective Sync is <b>enabled</b>.
      </div>
    </Tooltip>
  );

  const toggleDisabledData = (
    <StatusToggle
      id="toggle-requested-data"
      isEnabled={shouldShowRequestedData}
      disableSwitch={!canDisableRequestedData}
      onChange={updateShowingRequestedData}
      statusTextClassName="deprecated-mr-3"
      statusTextStyle={{ fontSize: "13px" }}
      tooltip={tooltip}
    />
  );

  return (
    <>
      <Row>
        <Col>
          <SectionHeaderWrapper
            title={location.pathname.includes("link") ? "Requested Data" : "Settings"}
          >
            <Card>
              <Card.Body>
                <SectionHeaderWrapper
                  title="Show Requested Data Screen In Linking Flow"
                  subtitle={
                    <>Display what data the integration will access in the third-party platform.</>
                  }
                  titleHeading={TitleHeading.H3}
                  button={toggleDisabledData}
                />
              </Card.Body>
            </Card>
          </SectionHeaderWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationCommonModelsSettings;
