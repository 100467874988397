import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import MergeModal from "../../../shared-components/MergeModal";
import { Button } from "@merge-api/merge-javascript-shared";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  convertToProduction: () => void;
};

const LinkedAccountTestAccountConversionModal = ({
  isModalOpen,
  closeModal,
  convertToProduction,
}: Props) => {
  const [
    isLinkedAccountConversionConfirmationChecked,
    setisLinkedAccountConversionConfirmationChecked,
  ] = useState(false);

  const [isConverting, setIsConverting] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <MergeModal
      centered
      show={isModalOpen}
      bodyClassName="modal-narrow overflow-hidden"
      dialogClassName="modal-narrow"
      onHide={closeModal}
      title="Are you sure you want to convert this?"
      ref={modalRef}
    >
      <span className="text-center">
        This is an irreversible action. Once the account is converted to a Production Linked
        Account, a new Account Token will be generated.
        <br />
        <br />
        Only <b>Admin</b> users will be able to access the token from the dashboard.
        <Form.Group className="d-flex align-items-center deprecated-mt-4 deprecated-mb-4">
          <Form.Check
            type="checkbox"
            id="linked-account-deletion-checkbox"
            checked={isLinkedAccountConversionConfirmationChecked}
            onChange={() =>
              setisLinkedAccountConversionConfirmationChecked(
                !isLinkedAccountConversionConfirmationChecked,
              )
            }
          />
          <b className="ml-1">I understand that this is an irreversible action.</b>
        </Form.Group>
        <div className="w-100 text-center d-flex">
          <Button fullWidth color="white" size="md" className="mr-2" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            fullWidth
            color="red"
            size="md"
            disabled={!isLinkedAccountConversionConfirmationChecked}
            onClick={() => {
              setIsConverting(true);
              convertToProduction();
            }}
            isLoading={isConverting}
          >
            Convert
          </Button>
        </div>
      </span>
    </MergeModal>
  );
};

export default LinkedAccountTestAccountConversionModal;
