import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Stack from "../../shared/Stack";
import { LinkedAccount, LinkedAccountWebhookReceivers } from "../../../../../models/Entities";
import {
  navigateToLinkedAccountDetailWebhooksPathForId,
  navigateToLinkedAccountFieldMappings,
} from "../../../../../router/RouterUtils";
import ConfigurationWidgetCard from "./ConfigurationWidgetCard";
import RedactUnmappedDataWidgetCard from "./RedactUnmappedDataWidgetCard";
import { ChevronRight } from "lucide-react";
import SkeletonLoader from "../../../../shared-components/SkeletonLoader";

const ConfigurationWrapper = styled.div`
  background-color: #f5f8ff;
  padding: 12px 8px 8px 8px;
  border: 0.5px solid #d4def2;
  border-radius: 12px;
  margin-bottom: 20px;
`;

const ConfigurationTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 6px;
  padding-left: 4px;
`;

const ConfigurationTileContainer = styled.div`
  gap: 8px;
`;

const getEnabledReceivers = (webhooksRecievers: LinkedAccountWebhookReceivers) => {
  return webhooksRecievers?.webhook_receivers.reduce((enabledWebhooks, webhookReceiver) => {
    if (webhookReceiver.is_active) {
      enabledWebhooks += 1;
    }
    return enabledWebhooks;
  }, 0);
};
type ConfigurationWidgetProps = {
  linkedAccount: LinkedAccount | null;
  webhookReceivers: LinkedAccountWebhookReceivers | null;
  showFieldMappingsRow: boolean;
  showWebhooksRow: boolean;
  showRedactUnmappedDataRow: boolean;
};
const ConfigurationWidget = ({
  linkedAccount,
  webhookReceivers,
  showFieldMappingsRow,
  showWebhooksRow,
  showRedactUnmappedDataRow,
}: ConfigurationWidgetProps) => {
  const history = useHistory();

  return (
    <Stack $isVertical>
      <ConfigurationWrapper>
        <ConfigurationTitle>Configuration</ConfigurationTitle>
        {linkedAccount ? (
          <ConfigurationTileContainer className="d-flex flex-column">
            {showFieldMappingsRow && (
              <ConfigurationWidgetCard
                cardTitle={"Field Mapping"}
                cardSubtitle={"Map " + linkedAccount?.integration?.name + " fields to Merge"}
                onClick={() => {
                  navigateToLinkedAccountFieldMappings(history, linkedAccount.id);
                }}
                includeRightChevron={true}
              />
            )}
            {showWebhooksRow && webhookReceivers && (
              <ConfigurationWidgetCard
                cardTitle={"Webhooks"}
                cardSubtitle={"Get real-time updates"}
                onClick={() => {
                  navigateToLinkedAccountDetailWebhooksPathForId(history, linkedAccount.id);
                }}
                includeRightChevron={true}
                smallGreyBoxChildren={getEnabledReceivers(webhookReceivers)}
              />
            )}
            {showRedactUnmappedDataRow && (
              <RedactUnmappedDataWidgetCard linkedAccount={linkedAccount} />
            )}
          </ConfigurationTileContainer>
        ) : (
          <div className="flex flex-column gap-y-5">
            <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
              <SkeletonLoader width={128} height={12} borderRadius={2} className="my-[5px]" />
              <ChevronRight size={12} />
            </div>
            <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
              <SkeletonLoader width={80} height={12} borderRadius={2} className="my-[5px]" />
              <ChevronRight size={12} />
            </div>
            <div className="flex flex-row items-center justify-between rounded-lg shadow-sm px-4 py-2 bg-white">
              <SkeletonLoader width={112} height={12} borderRadius={2} className="my-[5px]" />
              <ChevronRight size={12} />
            </div>
          </div>
        )}
      </ConfigurationWrapper>
    </Stack>
  );
};

export default ConfigurationWidget;
