import React from "react";
import {
  BillingGuardrailsStatus,
  InvoiceNonpaymentStatus,
  LinkingFlowAuthenticationMethodPreference,
  Organization,
  PendingInvite,
  PreexistingProductionLinkedAccountPreference,
  User,
  UserType,
} from "../../models/Entities";
import { OrganizationBillingPlan } from "../settings/billing/BillingModels";

export interface OrganizationWithBillingPlan extends Organization {
  organization_billing_plan?: Omit<OrganizationBillingPlan, "organization">;
  billing_guardrails_status?: BillingGuardrailsStatus;
  invoice_overdue_status?: InvoiceNonpaymentStatus;
  production_linked_account_count?: number;
  static_production_linked_account_count?: number;
  test_linked_account_count?: number;
  invoice_due_date?: string;
  redact_unmapped_data_enabled?: boolean;
}
export interface UserWithBillingPlan extends User {
  organization: OrganizationWithBillingPlan;
}

export interface AppContextState {
  user: UserWithBillingPlan;
  setUser: (user: UserWithBillingPlan) => void;
  isUserPrivileged: boolean;
  showDemoDataAlert: boolean;
  setShowDemoDataAlert: (showDemoDataAlert: boolean) => void;
  pendingOrganizationInvites: PendingInvite[] | undefined;
  setPendingOrganizationInvites: (pendingInvite: PendingInvite[]) => void;
  requestedPlanUpgrade: boolean;
  setRequestedPlanUpgrade: (requestedPlanUpgrade: boolean) => void;
}

const AppContext = React.createContext<AppContextState>({
  user: {
    id: 0,
    name: "",
    avatar: "",
    organization: {
      id: "",
      name: "",
      logo: "",
      auto_enable_new_integrations: true,
      enable_auto_webhook_setup: false,
      linking_flow_authentication_method_preference:
        LinkingFlowAuthenticationMethodPreference.LOGIN_DEFAULT_WITH_MANUAL_FALLBACK,
      organization_billing_plan: undefined,
      billing_guardrails_status: undefined,
      invoice_overdue_status: undefined,
      production_linked_account_count: undefined,
      test_linked_account_count: undefined,
      is_mfa_required: false,
      should_show_requested_data_in_linking_flow: true,
      preexisting_production_linked_account_preference:
        PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER,
      redact_unmapped_data_enabled: undefined,
    },
    email: "",
    is_staff: false,
    is_demo: false,
    type: UserType.member,
    is_two_factor_enabled: true,
    is_log_search_enabled: false,
    is_webhook_search_enabled: false,
    terms_agreement_datetime: "",
    intercom_hmac: "",
    allowed_invite_roles: [],
    is_billing_notice_enabled: true,
    is_selective_sync_enabled: false,
    is_g2_survey_enabled: false,
    is_integration_wide_field_mappings_enabled: false,
    g2_banner_dismiss_count: 0,
    g2_banner_last_dismiss_date: "",
    disable_magic_link_onboarding: false,
  },
  setUser: () => {},
  isUserPrivileged: false,
  showDemoDataAlert: true,
  setShowDemoDataAlert: () => {},
  pendingOrganizationInvites: undefined,
  setPendingOrganizationInvites: () => {},
  requestedPlanUpgrade: false,
  setRequestedPlanUpgrade: () => {},
});

export default AppContext;
