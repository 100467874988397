import React, { useCallback, useState } from "react";
import { Card, Col, Row, Tooltip } from "react-bootstrap";
import useAppContext from "../../context/useAppContext";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared-components/Toasts";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import styled from "styled-components";
import { StatusToggle } from "../../shared-components/MergeToggles";
import { BlueBetaBadge } from "../../shared-components/MergeBadges";

export const SelectiveSyncGradientContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 56px;

  background: linear-gradient(
    288.92deg,
    rgba(7, 95, 247, 0.42) 10.03%,
    rgba(128, 173, 250, 0) 123.14%
  );
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
`;

export const SelectiveSyncCardCutout = styled.div`
  width: 21.33px;
  height: 40.96px;

  background: #ffffff;
  border-radius: 0.682667px;

  padding-top: 8px;
`;

const ConfigurationSelectiveSyncSettingsPage = () => {
  const { user, setUser } = useAppContext();
  const [showSelectiveSync, setShowSelectiveSync] = useState<boolean | undefined>(
    user.organization?.show_selective_sync,
  );
  const canShowSelectiveSync = user.organization.should_show_requested_data_in_linking_flow;

  const updateEnableOrganizationSelectiveSync = useCallback(
    ({ showSelectiveSync }: { showSelectiveSync?: boolean }) => {
      fetchWithAuth({
        path: "/organizations/toggle-show-selective-sync",
        method: "PATCH",
        body: {
          show_selective_sync: showSelectiveSync,
        },
        onResponse: (data) => {
          fetchCurrentUser(setUser);
          setShowSelectiveSync(data.show_selective_sync);
        },
        onError: () => {
          showErrorToast("Something went wrong, please check your connection and try again.");
        },
      });
    },
    [setUser],
  );

  const tooltip = (
    <Tooltip id="toggle-selective-sync-tooltip">
      <div className="deprecated-my-2">
        Cannot <b>enable</b> Selective Sync when the Requested Data Screen is <b>disabled</b>.
      </div>
    </Tooltip>
  );

  return (
    <>
      <Row>
        <Col>
          <SectionHeaderWrapper title="Selective Sync">
            <Card>
              <Card.Body>
                <SectionHeaderWrapper
                  title={
                    <>
                      Show Selective Sync In Link <BlueBetaBadge />
                    </>
                  }
                  subtitle="Allow your end users to select what data is synced from third-party platforms through Link."
                  titleHeading={TitleHeading.H3}
                  button={
                    <StatusToggle
                      id="toggle-show-selective-sync"
                      isEnabled={!!showSelectiveSync}
                      onChange={(newIsEnabled) =>
                        updateEnableOrganizationSelectiveSync({ showSelectiveSync: newIsEnabled })
                      }
                      statusTextClassName="deprecated-mr-3"
                      statusTextStyle={{ fontSize: "13px" }}
                      disableSwitch={!canShowSelectiveSync}
                      tooltip={canShowSelectiveSync ? undefined : tooltip}
                    />
                  }
                  image={
                    <SelectiveSyncGradientContainer>
                      <SelectiveSyncCardCutout />
                    </SelectiveSyncGradientContainer>
                  }
                />
              </Card.Body>
            </Card>
          </SectionHeaderWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationSelectiveSyncSettingsPage;
