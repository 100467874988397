import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { spectrum } from "../../../styles/theme";
import { APICategory, UserType } from "../../../models/Entities";
import useAppContext from "../../context/useAppContext";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import { CardHeaderTitle, SmallTextMutedParagraph } from "../../shared-components/MergeText";
import { showSuccessToast } from "../../shared-components/Toasts";
import {
  fetchOrgBillingPlan,
  getPaymentMethods,
  updateDefaultPaymentMethod,
} from "./BillingAPIClient";
import {
  BankAccount,
  BillingPlan,
  BillingPlanTier,
  CurrentCategoryCharge,
  CreditCard,
  OrganizationBillingPlan,
  PaymentMethod,
} from "./BillingModels";
import {
  formatSyncFrequncyPlans,
  getBillingPlanTitle,
  isBillingPlanFreePlan,
  isBillingPlanLaunchPlan,
  parseCategoryUnitCountsFromBillingPlan,
  /**
   * Shared CSS elements between old and new Billing Pages.
   */
  CurrentModelUsageCard,
  CurrentModelUsageCardBody,
} from "./BillingUtils";
import PaymentMethodRow from "./components/BillingPaymentMethodRow";
import CommonModelChargeTable from "./components/CommonModelChargeTable";
import AddBankAccountModal from "./components/AddBankAccountModal";
import DeletePaymentMethodModal from "./components/DeletePaymentMethodModal";
import PricingPlanTitlePopover from "./components/PricingPlanTitlePopover";
import OldBillingPage from "./OldBillingPage";
import { Contact, DollarSign, Ticket, UserPlus, Users, Folder } from "lucide-react";
import { ReactComponent as MarketingAutomationIcon } from "../../../assets/svg/marketing-automation-feather.svg";
import { HeaderPretitle } from "../../shared-components/MergeText";
import { SmallWhiteButton, Spinner } from "@merge-api/merge-javascript-shared";
import { SectionHeaderWrapper } from "../../shared-components/MergeLayouts";
import SkeletonLoader from "../../shared-components/SkeletonLoader";
import clsx from "clsx";

const PlanNameText = styled.div`
  background: linear-gradient(85.42deg, #0c57da 9.77%, #669bf9 89.78%), ${spectrum.gray90};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const WhiteRect = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  gap: 2px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 12px;

  width: 100%;
  height: 32px;

  border: 1px solid ${spectrum.gray10};
  border-radius: 39px;
`;

const GreyRect = styled.rect`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  gap: 2px;

  width: 100%;
  height: 32px;

  /* Gray/Gray 0 */

  background: ${spectrum.gray0};
  border-radius: 39px;

  margin-left: 10px;
  margin-right: 10px;
`;

const SyncPlanGreyRect = styled(GreyRect)`
  justify-content: center;
`;

const LinkedAccountGreyRect = styled(GreyRect)`
  justify-content: space-between;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  padding-left: 12px;

  /* Gray/Gray 90 */
  color: ${spectrum.gray90};

  .short-text {
    display: none;
  }

  @media (max-width: 1160px) {
    .short-text {
      display: inline-block;
    }
    .full-text {
      display: none;
    }
  }
`;

const BillingPlanText = styled.text`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;

  /* Gray/Gray 90 */
  color: ${spectrum.gray90};
`;

const CategoryText = styled(BillingPlanText)`
  padding-left: 7px;
`;

const HighlightedNumberText = styled(BillingPlanText)`
  color: ${spectrum.blue40};
`;

const DenominatorNumberText = styled(BillingPlanText)`
  padding-left: 3px;
`;

const SyncPlanText = styled(BillingPlanText)`
  justify-content: space-between;
  align-items: center;
`;

const CardHeading = styled(BillingPlanText)`
  justify-content: space-between;

  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 12px;
`;

const PlanCardHeading = styled(CardHeading)`
  margin-bottom: 8px;
  padding-inline-end: 1em;
`;

const IndentedCardHeading = styled(PlanCardHeading)`
  padding-left: 12px;
  padding-bottom: 8px;
`;

function BillingPage() {
  const [showingAddPaymentMethodModal, setShowingAddPaymentMethodModal] = useState(false);
  const [paymentMethodDeletionTarget, setPaymentMethodDeletionTarget] = useState<
    PaymentMethod | undefined
  >();
  const [paymentMethods, setPaymentMethods] = useState<null | PaymentMethod[]>(null);
  const [isLoadingCreditCards, setIsLoadingCreditCards] = useState(false);
  const [defaultPaymentMethodBeingSet, setDefaultPaymentMethodBeingSet] =
    useState<null | PaymentMethod>(null);
  const [currentOrgBillingPlan, setCurrentOrgBillingPlan] = useState<
    OrganizationBillingPlan | undefined
  >();

  const [hasDataLoaded, setHasDataLoaded] = useState<boolean>(false);
  const [commonModelPrices, setCommonModelPrices] = useState<CurrentCategoryCharge[] | undefined>();
  const { user } = useAppContext();

  const onBillingPlanFetch = (billingPlan: OrganizationBillingPlan) => {
    setCurrentOrgBillingPlan(billingPlan);
    setCommonModelPrices(parseCategoryUnitCountsFromBillingPlan(billingPlan));
  };

  const trialPlan = {
    name: "Merge Beta Plan",
    is_active: true,
    plan_tier: BillingPlanTier.BILLING_PLAN_TIER_LAUNCH,
    monthly_price_cents: 0,
    api_request_price_cents: 0,
    scrape_price_cents: 0,
    webhook_price_cents: 0,
    free_api_requests: 10000,
    free_scrapes: -1,
    free_webhooks: -1,
  } as BillingPlan;

  const myTrialPlan = {
    billing_plan: trialPlan,
    start_date: format(new Date(), "M/d/yyyy"),
    end_date: format(new Date(2021, 5, 1), "M/d/yyyy"),
  } as OrganizationBillingPlan;

  useEffect(() => {
    fetchOrgBillingPlan({
      onFetch: onBillingPlanFetch,
      // TEMPORARY - For now Orgs that have no plan are still on the trial period
      onError: () => {
        setCurrentOrgBillingPlan(myTrialPlan);
      },
    });

    if (user.type === UserType.admin_with_billing) {
      fetchPaymentMethods(false);
    }
  }, []);

  const organizePaymentMethods = (
    bankAccounts: BankAccount[],
    creditCards: CreditCard[],
  ): PaymentMethod[] => {
    const arr: PaymentMethod[] = [...bankAccounts, ...creditCards]
      .sort((a, b) => a.created_at.localeCompare(b.created_at))
      .sort((a, _) => (a.is_default_method ? -1 : 1));
    return arr;
  };

  const fetchPaymentMethods = (forceRefreshFromStripe: boolean) => {
    setIsLoadingCreditCards(true);
    getPaymentMethods({
      forceRefreshStripe: forceRefreshFromStripe,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setIsLoadingCreditCards(false);
      },
    });
  };

  const setDefaultPaymentMethod = (paymentMethod: PaymentMethod) => {
    setDefaultPaymentMethodBeingSet(paymentMethod);
    updateDefaultPaymentMethod({
      paymentMethod: paymentMethod,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setDefaultPaymentMethodBeingSet(null);
        showSuccessToast("Successfully updated your default payment method!");
      },
    });
  };

  const isFreePlan = isBillingPlanFreePlan(currentOrgBillingPlan?.billing_plan);
  const isLaunchPlan = isBillingPlanLaunchPlan(currentOrgBillingPlan?.billing_plan);

  const billingLACount = user.organization.billed_category_linked_account_count;
  const totalLACount = user.organization.static_production_linked_account_count;
  const totalPurchasedLinkedAccounts = user.organization.purchased_prod_linked_accounts;
  const categoryCount = Object.keys(billingLACount || {}).length;

  const isOrgSingleCategoryUser = categoryCount < 2;
  const syncPlanMap = user.organization.sync_frequency_plans || {};
  const defaultSyncPlan = syncPlanMap["DEFAULT"];

  const startDate = user.organization.organization_billing_plan?.start_date || "";
  const startDateObject = new Date(startDate);
  const newBillingPlanStartDate = new Date("2023-04-01T00:00:00Z");

  /**
   * Remove the Flag logic when we're ready for launch.
   */
  const isOldBillingPlan = startDateObject < newBillingPlanStartDate;

  // all data that is related to loading states

  useEffect(() => {
    if (!isLoadingCreditCards && currentOrgBillingPlan && commonModelPrices && paymentMethods) {
      setHasDataLoaded(true);
    }
    // in the case where a non-admin user accesses the page, we'll want to remove the isLoadingCreditCards and paymentMethods checks since they won't return any information
    if (user.type !== UserType.admin_with_billing) {
      if (currentOrgBillingPlan && commonModelPrices) {
        setHasDataLoaded(true);
      }
    }
  }, [isLoadingCreditCards, currentOrgBillingPlan, commonModelPrices, paymentMethods]);

  function formatCategoryName(categoryName: string) {
    switch (categoryName) {
      case APICategory.hris:
        return (
          <>
            <Users size={12} />
            <CategoryText>HRIS</CategoryText>
          </>
        );
      case APICategory.crm:
        return (
          <>
            <Contact size={12} />
            <CategoryText>CRM</CategoryText>
          </>
        );
      case APICategory.ats:
        return (
          <>
            <UserPlus size={12} />
            <CategoryText>ATS</CategoryText>
          </>
        );
      case APICategory.ticketing:
        return (
          <>
            <Ticket size={12} />
            <CategoryText>Ticketing</CategoryText>
          </>
        );
      case APICategory.accounting:
        return (
          <>
            <DollarSign size={12} />
            <CategoryText>Accounting</CategoryText>
          </>
        );
      case APICategory.filestorage:
        return (
          <>
            <Folder size={12} />
            <CategoryText>File Storage</CategoryText>
          </>
        );
      case APICategory.mktg:
        return (
          <>
            <MarketingAutomationIcon height={12} width={12} />
            <CategoryText>Marketing Automation</CategoryText>
          </>
        );
    }
    return "";
  }

  if (isOldBillingPlan || isFreePlan || isLaunchPlan) {
    return <OldBillingPage />;
  } else {
    return (
      <>
        <AddBankAccountModal
          show={showingAddPaymentMethodModal}
          onHide={() => {
            setShowingAddPaymentMethodModal(false);
            fetchPaymentMethods(true);
          }}
        />
        <DeletePaymentMethodModal
          paymentMethod={paymentMethodDeletionTarget}
          onHide={() => {
            setPaymentMethodDeletionTarget(undefined);
            fetchPaymentMethods(true);
          }}
        />
        <SectionHeaderWrapper title="Billing" />
        <Row className="justify-content-between">
          <Col className="col-12 col-lg-3">
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <PlanCardHeading>Current Plan</PlanCardHeading>
                    <div className="billing-plan-type">
                      <PlanNameText className=" pt-2 billing-plan-name font-semibold text-[24px] leading-[32px]">
                        {currentOrgBillingPlan && hasDataLoaded ? (
                          getBillingPlanTitle(currentOrgBillingPlan)
                        ) : (
                          <SkeletonLoader width={169} height={24} borderRadius={4} />
                        )}
                      </PlanNameText>
                    </div>
                  </Col>

                  {commonModelPrices && commonModelPrices.length > 0 && (
                    <Col xl="7">
                      <CurrentModelUsageCard>
                        <CurrentModelUsageCardBody>
                          <CommonModelChargeTable
                            keyPrefix="common-model-charges-current-"
                            commonModelCharges={commonModelPrices}
                            organizationBillingPlan={currentOrgBillingPlan}
                            isLaunchPlan={isLaunchPlan}
                          />
                        </CurrentModelUsageCardBody>
                      </CurrentModelUsageCard>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Row className="align-items-center">
                  <div className="mr-3 px-3 my-n1 text-[10px] leading-[14px] text-gray-50 font-semibold">
                    ACTIVE PRODUCTION
                  </div>
                  <div className="w-100 justify-content-between d-flex">
                    <IndentedCardHeading>Linked Accounts</IndentedCardHeading>

                    <PricingPlanTitlePopover>
                      <>
                        Your plan includes {totalPurchasedLinkedAccounts} Production Linked
                        Accounts. If you exceed this number you will incur overage charges. Reach
                        out to <a href="support@merge.dev">support@merge.dev</a> with any questions.
                      </>
                    </PricingPlanTitlePopover>
                  </div>

                  {isOrgSingleCategoryUser &&
                    Object.entries(billingLACount ?? {}).map(([category, obj]) => (
                      <LinkedAccountGreyRect>
                        <div>{formatCategoryName(category)}</div>
                        {!hasDataLoaded ? (
                          <Spinner size={4} className="text-gray-40 !border-2" />
                        ) : (
                          <HighlightedNumberText>
                            {obj}{" "}
                            <DenominatorNumberText>
                              / {totalPurchasedLinkedAccounts}
                            </DenominatorNumberText>
                          </HighlightedNumberText>
                        )}
                      </LinkedAccountGreyRect>
                    ))}
                  {!isOrgSingleCategoryUser &&
                    Object.entries(billingLACount ?? {}).map(([category, obj]) => (
                      <WhiteRect>
                        <div>{formatCategoryName(category)}</div>
                        {!hasDataLoaded ? (
                          <Spinner size={4} className="text-gray-40 !border-2" />
                        ) : (
                          <HighlightedNumberText>{obj}</HighlightedNumberText>
                        )}
                      </WhiteRect>
                    ))}
                  {!isOrgSingleCategoryUser && (
                    <LinkedAccountGreyRect>
                      <span className="full-text">All Categories</span>
                      <span className="short-text">All</span>
                      {!hasDataLoaded ? (
                        <Spinner size={4} className="text-gray-40 !border-2" />
                      ) : (
                        <HighlightedNumberText>
                          {totalLACount}
                          <DenominatorNumberText>
                            / {totalPurchasedLinkedAccounts}
                          </DenominatorNumberText>
                        </HighlightedNumberText>
                      )}
                    </LinkedAccountGreyRect>
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Row className="align-items-center">
                  <div className="billing-plan-type">
                    <IndentedCardHeading>Sync Plan</IndentedCardHeading>
                  </div>

                  <SyncPlanGreyRect>
                    {!hasDataLoaded ? (
                      <Spinner size={4} className="text-gray-40 !border-2" />
                    ) : (
                      <SyncPlanText>{formatSyncFrequncyPlans(defaultSyncPlan)}</SyncPlanText>
                    )}
                  </SyncPlanGreyRect>
                  {commonModelPrices && commonModelPrices.length > 0 && (
                    <Col xl="7">
                      <CurrentModelUsageCard>
                        <CurrentModelUsageCardBody>
                          <CommonModelChargeTable
                            keyPrefix="common-model-charges-current-"
                            commonModelCharges={commonModelPrices}
                            organizationBillingPlan={currentOrgBillingPlan}
                            isLaunchPlan={isLaunchPlan}
                          />
                        </CurrentModelUsageCardBody>
                      </CurrentModelUsageCard>
                    </Col>
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col className="col-12 col-lg-9">
            <>
              <Card>
                <Card.Header>
                  <Row className="align-items-center">
                    <Col>
                      <CardHeaderTitle>Payment Methods</CardHeaderTitle>
                    </Col>
                    <Col className="col-auto">
                      {user.type === UserType.admin_with_billing ? (
                        <SmallWhiteButton
                          variant="plus"
                          onClick={() => setShowingAddPaymentMethodModal(true)}
                        >
                          Payment method
                        </SmallWhiteButton>
                      ) : (
                        <OverlayTrigger
                          overlay={
                            <Tooltip className="step-card-full-name-tooltip" id="tooltip">
                              You must be an admin to add payment methods
                            </Tooltip>
                          }
                          placement="top"
                        >
                          <div>
                            <SmallWhiteButton variant="plus">Payment method</SmallWhiteButton>
                          </div>
                        </OverlayTrigger>
                      )}
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="list-group list-group-flush px-6">
                    {!hasDataLoaded ? (
                      <div>
                        {Array(5)
                          .fill(null)
                          .map((_, index) => (
                            <div
                              key={index}
                              className={clsx("flex flex-row justify-between items-center py-5", {
                                "border-b border-gray-10": index !== 4,
                              })}
                            >
                              <SkeletonLoader width={238} height={12} borderRadius={2} />
                              <SkeletonLoader width={182} height={12} borderRadius={2} />
                            </div>
                          ))}
                      </div>
                    ) : user.type !== UserType.admin_with_billing ? (
                      <EmptyStateWrapper title="You must be an admin to view payment methods" />
                    ) : paymentMethods && paymentMethods.length > 0 ? (
                      paymentMethods.map((paymentMethod) => (
                        <PaymentMethodRow
                          paymentMethod={paymentMethod}
                          billingPlan={currentOrgBillingPlan}
                          updateBillingPlan={setCurrentOrgBillingPlan}
                          onClickSetDefault={() => setDefaultPaymentMethod(paymentMethod)}
                          settingDefault={defaultPaymentMethodBeingSet === paymentMethod}
                          setPaymentMethodDeletionTarget={setPaymentMethodDeletionTarget}
                        />
                      ))
                    ) : (
                      <EmptyStateWrapper title="No payment methods" />
                    )}
                  </div>
                </Card.Body>
              </Card>
            </>
          </Col>
        </Row>

        <SmallTextMutedParagraph className="text-center deprecated-mb-5">
          Tired of painless integrations?{" "}
          <a href="mailto:hello@merge.dev" className="red">
            Contact us to cancel your plan.
          </a>
        </SmallTextMutedParagraph>
      </>
    );
  }
}

export default BillingPage;
