import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { Form } from "react-bootstrap";
import { APICategory, Button } from "@merge-api/merge-javascript-shared";
import uniq from "lodash/uniq";

import {
  fetchWithAuth,
  FormErrorData,
  MultipleFormErrorData,
} from "../../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../../shared-components/Toasts";
import { navigateToWebhooksConfigurationPage } from "../../../../router/RouterUtils";
import { SelectedWebhookType, PageMode, HookEvent } from "./enums";
import { AddEditWebhooksPageHeader, WebhookUrlInput } from "./components";
import NewWebhookTypeSelect from "./components/WebhookTypeSelect/WebhookTypeSelect";
import useAppContext from "../../../context/useAppContext";
import { MergeFlagFeature, useMergeFlag } from "../../../shared-components/hooks/useMergeFlag";

type RouteParams = {
  webhookID: string;
};

const ConfigurationEditWebhooksPage = () => {
  // hooks
  const history = useHistory();
  const { webhookID } = useParams<RouteParams>();
  const { register, handleSubmit, errors, setError, control, trigger } = useForm();
  const { user } = useAppContext();
  const { enabled } = useMergeFlag({
    // lifted up from MergeFlag wrapper to smooth out loading states
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_ASYNC_PASSTHROUGH,
    organizationId: user.organization.id,
  });
  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [displayTarget, setDisplayTarget] = useState<string>();
  const [selectedWebhookType, setSelectedWebhookType] = useState<SelectedWebhookType | undefined>();

  // common model select specific state
  const [selectedCategoryOption, setSelectedCategory] = useState<APICategory | "all">("all");
  const [selectedSyncCommonModels, setSelectedSyncCommonModels] = useState<Array<string>>([]);
  const [selectedSyncCommonModelEvents, setSelectedSyncCommonModelEvents] = useState<Array<string>>(
    [],
  );
  const [selectedChangedDataCommonModels, setSelectedChangedDataCommonModels] = useState<
    Array<string>
  >([]);
  const [selectedChangedDataCommonModelEvents, setSelectedChangedDataCommonModelEvents] = useState<
    Array<string>
  >([]);

  // derived state
  const mode = webhookID ? PageMode.EDIT : PageMode.ADD;

  // state for handling which type of webhook selected
  const isCommonModelChangedData =
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT ||
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY;
  const isCommonModelSync =
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_SELECT ||
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_ANY;

  // prop loading state

  const skeletonLoadingState = isPageLoading && mode === PageMode.EDIT && enabled === undefined;

  // event handlers
  const onSelectedWebhookTypeChange = (selectedWebhookType: SelectedWebhookType) =>
    setSelectedWebhookType(selectedWebhookType);
  const onSelectedCategoryOptionTypeChange = (selectedCategoryOption: APICategory | "all") =>
    setSelectedCategory(selectedCategoryOption);
  const onTargetChange = (target: string) => setDisplayTarget(target);

  const onSelectedSyncCommonModelsChange = (selectedSyncCommonModels: string[]) =>
    setSelectedSyncCommonModels(selectedSyncCommonModels);
  const onSelectedSyncCommonModelEventsChange = (selectedSyncCommonModelEvents: string[]) =>
    setSelectedSyncCommonModelEvents(selectedSyncCommonModelEvents);
  const onSelectedChangedDataCommonModelsChange = (selectedChangedDataCommonModels: string[]) =>
    setSelectedChangedDataCommonModels(selectedChangedDataCommonModels);
  const onSelectedChangedDataCommonModelEventsChange = (
    selectedChangedDataCommonModelEvents: string[],
  ) => setSelectedChangedDataCommonModelEvents(selectedChangedDataCommonModelEvents);

  const target = "https://" + displayTarget;

  // setup state for EDIT mode
  const fetchWebhook = useCallback(() => {
    fetchWithAuth({
      path: `/integrations/webhooks/${webhookID}`,
      method: "GET",
      onResponse: (data) => {
        setDisplayTarget(data.target.replace("https://", ""));
        if (data.events.length === 0) {
          // Nothing selected.
          setSelectedWebhookType(undefined);
        } else if (data.events.includes(HookEvent.ACCOUNT_SYNCED_HOOK)) {
          // Sync Notifications -> "Any Sync" is selected.
          setSelectedWebhookType(SelectedWebhookType.ANY_SYNC);
        } else if (data.events.includes(HookEvent.ACCOUNT_LINKED_HOOK)) {
          // Account Linked webhook selected
          setSelectedWebhookType(SelectedWebhookType.LINKED);
        } else if (data.events.includes(HookEvent.ACCOUNT_SYNCED_INITIAL_HOOK)) {
          // Sync Notifications -> "First Sync" is selected.
          setSelectedWebhookType(SelectedWebhookType.FIRST_SYNC);
        } else if (data.events.includes(HookEvent.ACCOUNT_FULLY_SYNCED_INITIAL_HOOK)) {
          // Sync Notifications -> "First Sync" is selected.
          setSelectedWebhookType(SelectedWebhookType.FIRST_SYNC);
        } else if (
          // Issues Create -> "Issues"  is selected.
          data.events[0].includes(HookEvent.ISSUES_CREATED) ||
          data.events[0].includes(HookEvent.ISSUES_RESOLVED)
        ) {
          setSelectedWebhookType(SelectedWebhookType.ISSUES);
        } else if (
          // Async Passthrough Resolved -> "Async Passthrough" is selected.
          data.events[0].includes(HookEvent.ASYNC_PASSTHROUGH_RESOLVED)
        ) {
          setSelectedWebhookType(SelectedWebhookType.ASYNC_PASSTHROUGH);
        } else if (data.events[0] === HookEvent.ALL_COMMON_MODEL_CHANGED_DATA_EVENTS) {
          // Changed Data -> "Anything" is selected.
          setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);
          setSelectedCategory("all");
        } else if (data.events[0] === HookEvent.ALL_COMMON_MODEL_SYNC_EVENTS) {
          // Common Model Sync -> "Anything" is selected.
          setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);
          setSelectedCategory("all");
        } else if (
          data.events.some(
            (event: string) =>
              event.endsWith(".added") || event.endsWith(".changed") || event.endsWith(".removed"),
          )
        ) {
          // Changed Data -> various data types are selected if any include .added, .removed, .changed
          setSelectedChangedDataCommonModelEvents(data.events);
          setSelectedChangedDataCommonModels(uniq(data.events.map((e: string) => e.split(".")[0])));
          setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT);
          setSelectedCategory(APICategory.hris);
        } else if (data.events.some((event: string) => event.endsWith(".synced"))) {
          // Common Model Sync -> various data types are selected if any include .synced
          setSelectedSyncCommonModelEvents(data.events);
          setSelectedSyncCommonModels(uniq(data.events.map((e: string) => e.split(".")[0])));
          setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT);
          setSelectedCategory(APICategory.hris);
        }
        setIsPageLoading(false);
      },
    });
  }, [webhookID]);

  // state management for switching categories in edit/add mode

  const isSyncSelected =
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_SELECT ||
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_SYNC_ANY;

  const isChangedDataSelected =
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT ||
    selectedWebhookType === SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY;

  useEffect(() => {
    if (isSyncSelected) {
      if (selectedCategoryOption === "all") {
        setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_SYNC_ANY);
      } else {
        setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_SYNC_SELECT);
      }
    }
    if (isChangedDataSelected) {
      if (selectedCategoryOption === "all") {
        setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY);
      } else {
        setSelectedWebhookType(SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT);
      }
    }
  }, [selectedCategoryOption, selectedWebhookType]);

  // effects
  // inititalize
  useEffect(() => {
    if (webhookID) {
      fetchWebhook();
    } else {
      setIsPageLoading(false);
    }
  }, [fetchWebhook, webhookID]);

  const selectedEvents: string[] = useMemo(() => {
    switch (selectedWebhookType) {
      case SelectedWebhookType.FIRST_SYNC:
        return [HookEvent.ACCOUNT_FULLY_SYNCED_INITIAL_HOOK];
      case SelectedWebhookType.ANY_SYNC:
        return [HookEvent.ACCOUNT_SYNCED_HOOK];
      case SelectedWebhookType.LINKED:
        return [HookEvent.ACCOUNT_LINKED_HOOK];
      case SelectedWebhookType.ASYNC_PASSTHROUGH:
        return [HookEvent.ASYNC_PASSTHROUGH_RESOLVED];
      case SelectedWebhookType.ISSUES:
        return [HookEvent.ISSUES_CREATED, HookEvent.ISSUES_RESOLVED];

      // common model changed data
      case SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_ANY:
        return [HookEvent.ALL_COMMON_MODEL_CHANGED_DATA_EVENTS];
      case SelectedWebhookType.COMMON_MODEL_CHANGED_DATA_SELECT:
        return selectedChangedDataCommonModelEvents;

      // common model sync
      case SelectedWebhookType.COMMON_MODEL_SYNC_ANY:
        return [HookEvent.ALL_COMMON_MODEL_SYNC_EVENTS];
      case SelectedWebhookType.COMMON_MODEL_SYNC_SELECT:
        return selectedSyncCommonModelEvents;
      default:
        return [];
    }
  }, [selectedSyncCommonModelEvents, selectedChangedDataCommonModelEvents, selectedWebhookType]);

  const onSubmit = () => {
    if (selectedWebhookType === undefined) {
      showErrorToast("Please select a webhook trigger.");
      return;
    }
    setIsLoading(true);

    //  filters out events between common model sync and changed data : this is an additional check to ensure we never couple these webhooks together
    let filteredEvents = [...selectedEvents];

    if (isCommonModelChangedData) {
      filteredEvents = filteredEvents.filter((event) => !event.endsWith(".synced"));
    }

    if (isCommonModelSync) {
      const unwantedEndings = [".added", ".changed", ".removed"];
      filteredEvents = filteredEvents.filter(
        (event) => !unwantedEndings.some((ending) => event.endsWith(ending)),
      );
    }

    const formData = {
      target,
      events: filteredEvents,
    };

    const handleError = (err: Response | undefined) => {
      if (err) {
        err.json().then((data: MultipleFormErrorData | FormErrorData) => {
          const formErrorData: FormErrorData =
            (data as MultipleFormErrorData)[0] === undefined
              ? (data as FormErrorData)
              : (data as MultipleFormErrorData)[0];

          if (formErrorData["non_field_errors"]) {
            showErrorToast(formErrorData["non_field_errors"][0]);
          } else {
            showErrorToast("Something went wrong, please check your selections and try again.");
          }
          for (const field_name in formErrorData) {
            setError(field_name, { message: formErrorData[field_name][0] });
          }
        });
      } else {
        showErrorToast("Something went wrong, please check your selections and try again.");
      }
      setIsLoading(false);
    };

    const handleResponse = () => {
      showSuccessToast("Successfully saved webhook!");
      navigateToWebhooksConfigurationPage(history);
      setIsLoading(false);
    };

    if (mode === PageMode.EDIT) {
      fetchWithAuth({
        path: `/integrations/webhooks/${webhookID}`,
        method: "PATCH",
        body: formData,
        onResponse: handleResponse,
        onError: handleError,
      });
    } else {
      fetchWithAuth({
        path: "/integrations/webhooks",
        method: "POST",
        body: formData,
        onResponse: handleResponse,
        onError: handleError,
      });
    }
  };

  return (
    <>
      <AddEditWebhooksPageHeader webhookID={webhookID} mode={mode} />
      <div className="flex flex-col rounded-[10px] shadow-md px-6 py-5 mt-8 mb-64 bg-white">
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <WebhookUrlInput
            isLoading={skeletonLoadingState}
            register={register}
            errors={errors}
            onTargetChange={onTargetChange}
            displayTarget={displayTarget}
            target={target}
            trigger={trigger}
            selectedWebhookType={selectedWebhookType}
            selectedEvents={selectedEvents}
            setError={setError}
          />
          <NewWebhookTypeSelect
            isLoading={skeletonLoadingState}
            onSelectedCategoryOptionTypeChange={onSelectedCategoryOptionTypeChange}
            selectedCategoryOption={selectedCategoryOption}
            selectedWebhookType={selectedWebhookType}
            onSelectedWebhookTypeChange={onSelectedWebhookTypeChange}
            selectedSyncCommonModels={selectedSyncCommonModels}
            selectedSyncCommonModelEvents={selectedSyncCommonModelEvents}
            selectedChangedDataCommonModels={selectedChangedDataCommonModels}
            selectedChangedDataCommonModelEvents={selectedChangedDataCommonModelEvents}
            enabled={enabled}
            control={control}
            errors={errors}
            onSelectedSyncCommonModelsChange={onSelectedSyncCommonModelsChange}
            onSelectedSyncCommonModelEventsChange={onSelectedSyncCommonModelEventsChange}
            onSelectedChangedDataCommonModelsChange={onSelectedChangedDataCommonModelsChange}
            onSelectedChangedDataCommonModelEventsChange={
              onSelectedChangedDataCommonModelEventsChange
            }
          />
          <div className="border-b border-gray-10 -ml-6 -mr-6 mt-6 mb-5" />
          <div className="flex flex-row items-center flex-nowrap">
            {mode === PageMode.EDIT ? (
              <Button isLoading={isLoading} disabled={skeletonLoadingState} size="sm" color="blue">
                Save
              </Button>
            ) : (
              <Button
                disabled={!selectedWebhookType}
                isLoading={isLoading}
                size="sm"
                color="blue"
                variant="plus"
              >
                Webhook
              </Button>
            )}{" "}
            <div
              onClick={() => navigateToWebhooksConfigurationPage(history)}
              className="ml-4 text-sm font-semibold cursor-pointer"
            >
              Cancel
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ConfigurationEditWebhooksPage;
