import React from "react";
import { APIRequestLogEntry } from "../../IntegrationsManagementEntities";
import { fetchLogEntryResponseBody } from "../../utils/IntegrationsManagementAPIClient";
import { LogSidePanel } from "./shared-components/LogSidePanel";

type Props = {
  fetchAPIRequestLog: (props: {
    logID: string;
    onSuccess: (data: APIRequestLogEntry) => void;
    onError: (error: Response | undefined) => void;
  }) => void;
};

const APIRequestLogEntrySidePanel = ({ fetchAPIRequestLog }: Props) => {
  return (
    <LogSidePanel
      title="API Request Log"
      fetchLog={fetchAPIRequestLog}
      fetchResponseBody={fetchLogEntryResponseBody}
    />
  );
};

export default APIRequestLogEntrySidePanel;
