import React from "react";
import { ChevronRight } from "lucide-react";
import { useHistory, Link } from "react-router-dom";
import { navigateToIndividualCommonModelOverrideTargetPage } from "../../../../router/RouterUtils";
import { OverriddenCommonModelTarget } from "../../../../models/Entities";
import { capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
type ConfigurationCommonModelOverrideTargetRowProps = {
  commonModelOverrideTarget: OverriddenCommonModelTarget;
};
const ConfigurationCommonModelOverrideTargetRow = ({
  commonModelOverrideTarget,
}: ConfigurationCommonModelOverrideTargetRowProps) => {
  const history = useHistory();
  const totalIntWideOverrides = commonModelOverrideTarget.total_int_wide_overrides || 0;
  return (
    <div
      className="pt-5 pb-5 border-b border-gray-10 cursor-pointer"
      onClick={() => {
        navigateToIndividualCommonModelOverrideTargetPage(history, commonModelOverrideTarget.id);
      }}
    >
      <div className="d-flex items-center pl-6 pr-6">
        <div className="d-flex flex-column ">
          <div className="d-flex flex-wrap">
            <div className="font-mono mr-2">{commonModelOverrideTarget.overridden_field}</div>
            <div className="font-semibold mr-2 font-mono text-[13px]">
              - <span className="font-mono">{commonModelOverrideTarget.common_model_name}</span>
            </div>
            <div className="font-mono pl-1 pr-1 bg-gray-0 text-gray-70 rounded text-truncate text-[11px]">
              {capitalizeFirstLetter(
                commonModelOverrideTarget.overridden_field_definition?.type || "",
              )}
            </div>
          </div>
          <div className="text-gray-50 text-sm">
            {commonModelOverrideTarget.overridden_field_definition?.description.split(".")[0]}.
          </div>
        </div>
        <div className="ml-auto d-flex items-center">
          <div
            className={`text-xs font-semibold ${
              totalIntWideOverrides == 0 ? "text-gray-50" : "text-black"
            }`}
          >
            {totalIntWideOverrides} Overrides
          </div>
          <div className="ml-6 text-gray-60">
            <ChevronRight size={12} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationCommonModelOverrideTargetRow;
