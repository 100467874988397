import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import MaybeDisable from "../../shared-components/MaybeDisable";
import { HeaderPretitle } from "../../shared-components/MergeText";
import { EllipsesToggle } from "../../shared-components/MergeToggles";
import { useActiveSubtab } from "../../portal/SubtabUtils";
import DeprecatedH1 from "../../../deprecated/DeprecatedH1";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import AddProductionLinkedAccountButton from "./AddProductionLinkedAccountButton";
import AddTestLinkedAccountButton from "./AddTestLinkedAccountButton";
import { FormErrorData, fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared-components/Toasts";
import { usePaginatedRequest } from "../../shared-components/hooks/usePaginatedRequest";
import { LinkedAccount } from "../../../models/Entities";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  /**
   * The big black title, required
   */
  title: string | JSX.Element;

  /**
   * A smaller pretitle that appears stacked above the title if present
   */
  pretitle?: string | JSX.Element;

  /**
   * A description or short subtitle that appears below the title if present
   */
  subtitle?: string | JSX.Element;

  /**
   * If present, renders a badge directly to the right of the title
   */
  badge?: string | JSX.Element;

  /**
   * If present, renders content to the far right of the title
   */
  rightElement?: JSX.Element;

  /**
   * Provides tab titles & icons & components that appear as subnavigation
   * under the title/description
   */
  subtabs?: Array<Subtab>;
  indicator?: JSX.Element;
  isLinkedAccountPortalView?: boolean;
};
interface HeaderBarInterface {
  hasPretitle: boolean;
  isLinkedAccountPortalView: boolean;
}

const Container = styled.div<HeaderBarInterface>`
  padding: 40px 0px 24px;
  ${(props) => props.hasPretitle && `padding: 24px 0`}
  ${(props) => props.isLinkedAccountPortalView && `padding: 16px 0 20px`}
`;

const Title = styled(DeprecatedH1)`
  font-size: 28px;
  line-height: 36px;
  margin: 0;
`;

const Subtitle = styled(DeprecatedH4)`
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  letter-spacing: 0;
  margin: 12px 0 0;
`;

const HoverOverDiv = styled.div<{ $hasPadding: boolean }>`
  display: inline-block;
  width: 100%;
  ${({ $hasPadding }) =>
    $hasPadding &&
    css`
      padding-top: 1px;
    `};
  &:hover {
    cursor: pointer;
  }
`;

const IndicatorContainer = styled.span`
  height: 16px;
`;
export type Subtab =
  | {
      label: JSX.Element;
      destination: undefined;
      disabled?: boolean;
      disabled_tip_message?: string;
      icon?: React.ReactElement;
      indicator?: JSX.Element;
    }
  | {
      label: string;
      destination: string;
      disabled?: boolean;
      disabled_tip_message?: string;
      icon?: React.ReactElement;
      indicator?: JSX.Element;
    };

/**
 * Takes a subtab and returns proper html based on disabled
 * and tooltip options
 */
const SubtabLink = ({ subtab }: { subtab: Subtab }) => (
  <MaybeDisable
    disable={subtab.disabled}
    tooltip={subtab.disabled_tip_message}
    className="nav-item"
  >
    <div className="d-flex align-items-center">
      {subtab.destination ? (
        <NavLink to={subtab.destination} className="nav-link">
          {subtab.icon} {subtab.label}{" "}
          {subtab.indicator && (
            <IndicatorContainer className="deprecated-ml-2">{subtab.indicator}</IndicatorContainer>
          )}
        </NavLink>
      ) : (
        <div className="nav-link">
          {subtab.icon} {subtab.label}{" "}
          {subtab.indicator && <IndicatorContainer>{subtab.indicator}</IndicatorContainer>}
        </div>
      )}
    </div>
  </MaybeDisable>
);

const TitleStack = styled.div.attrs({ className: "d-flex align-items-center" })`
  gap: 16px;
`;

const Spacer = styled.span`
  flex-grow: 1;
`;

const RightElement = styled.div`
  align-self: flex-end;
`;

const LinkedAccountsHeaderBar = ({
  className,
  pretitle,
  title,
  subtitle,
  badge,
  rightElement,
  subtabs,
  isLinkedAccountPortalView,
}: Props) => {
  // Only changes if the subtabs/location does
  const activeSubtab = useActiveSubtab(subtabs);
  const [linkToken, setLinkToken] = useState<string | undefined>();
  const isTestAccounts = activeSubtab?.destination?.includes("test-accounts");

  // Make sure the rendered content in here doesn't change when other data does
  const subtabElements = useMemo(
    () =>
      subtabs && (
        <div>
          <ul className="nav nav-tabs border-bottom-0 nav-lg-show">
            {subtabs.map((subtab) => (
              <li key={(subtab.destination ?? subtab.label) as string}>
                <SubtabLink subtab={subtab} />
              </li>
            ))}
          </ul>
          <Dropdown className="nav nav-tabs border-bottom-0 nav-lg-hide">
            <Dropdown.Toggle id="dropdown-custom-components" as={EllipsesToggle}>
              {activeSubtab && <SubtabLink subtab={activeSubtab as Subtab} />}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {subtabs.map((subtab) => (
                <Dropdown.Item
                  key={(subtab.destination ?? subtab.label) as string}
                  className="dropdown-nav-link"
                >
                  <SubtabLink subtab={subtab} />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      ),
    [activeSubtab, subtabs],
  );

  const fetchCreateTestLinkToken = () => {
    fetchWithAuth({
      path: "/integrations/create-test-link-token",
      method: "POST",
      onResponse: (data) => {
        setLinkToken(data.link_token);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          showErrorToast(
            "Unable to initialize new test Linked Accounts, please check your connection and try again.",
          );
        } else {
          showErrorToast(
            "Unable to initialize new test Linked Accounts, please check your connection and try again.",
          );
        }
      },
    });
  };

  useEffect(() => {
    if (isTestAccounts) {
      fetchCreateTestLinkToken();
    }
  }, [isTestAccounts]);

  const { onRefetch } = usePaginatedRequest<LinkedAccount>({
    rootPath: "/integrations/linked-accounts",
  });

  const CreateLinkedAccountButton = () => {
    if (isTestAccounts) {
      if (linkToken) {
        return (
          <>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-top">
                  Test Linked Accounts do not count towards billing and can only be synced manually
                  through this dashboard.
                </Tooltip>
              }
            >
              <HoverOverDiv $hasPadding>
                <AddTestLinkedAccountButton
                  linkToken={linkToken}
                  onNewLinkedAccount={() => {
                    onRefetch();
                    fetchCreateTestLinkToken();
                  }}
                />
              </HoverOverDiv>
            </OverlayTrigger>
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return <AddProductionLinkedAccountButton />;
    }
  };

  return (
    <Container
      className={className}
      hasPretitle={!!pretitle}
      isLinkedAccountPortalView={isLinkedAccountPortalView || false}
    >
      <div className="custom-header-border">
        <div className="custom-header-body">
          <div>
            {pretitle && <HeaderPretitle>{pretitle}</HeaderPretitle>}
            {badge || rightElement ? (
              <TitleStack>
                <Title>{title}</Title>
                {badge}
                <Spacer />
                <RightElement>{rightElement}</RightElement>
              </TitleStack>
            ) : (
              <Title>{title}</Title>
            )}
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          {subtabElements}
          <div className="h-100 p-2">
            <CreateLinkedAccountButton />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LinkedAccountsHeaderBar;
