import classNames from "classnames";
import React from "react";
import { Badge } from "react-bootstrap";
import { APICategory, HTTPMethod } from "../../models/Entities";
import { apiCategoryFromString } from "../../models/Helpers";
import { snakeCaseToFirstLetterUpperCase } from "../../services";
import { palette, spectrum } from "../../styles/theme";
import { IntegrationIssueStatus } from "../integrations-management/IntegrationsManagementEntities";
import { MergeChargeStatus } from "../settings/billing/BillingModels";
import HttpStatusCode from "./utils/HttpStatusCode";
import styled from "styled-components";

interface IssuesStatusBadgeProps {
  status: IntegrationIssueStatus;
  muted?: boolean;
  className?: string;
}

export const IssuesStatusBadge = ({ status, muted, className }: IssuesStatusBadgeProps) => {
  return (
    <span
      className={classNames(
        "badge font-medium",
        muted
          ? "badge-soft-secondary"
          : status == IntegrationIssueStatus.ONGOING
          ? "badge-soft-danger"
          : "badge-soft-success",
        className,
      )}
    >
      {status.charAt(0).toUpperCase() + status.substr(1).toLowerCase()}
    </span>
  );
};

type ResponseStatusBadgeProps = {
  responseCode: number | string;
  className?: string;
};

export const ResponseStatusBadge = ({ responseCode, className }: ResponseStatusBadgeProps) => {
  const isOKResponse = responseCode === 200 || responseCode == "200 OK";
  const text = isOKResponse ? "200 OK" : responseCode + " Error";

  return (
    <span
      className={classNames(
        "badge",
        isOKResponse ? "badge-soft-success" : "badge-soft-danger",
        className,
      )}
    >
      {text}
    </span>
  );
};

type ResponseCodeBadgeProps = {
  responseCode: number;
  className?: string;
};

export const ResponseCodeBadge = ({ responseCode, className }: ResponseCodeBadgeProps) => {
  const isOKResponse = responseCode >= 200 && responseCode <= 299;
  const text =
    responseCode === 200
      ? "200 OK"
      : String(responseCode) + " " + snakeCaseToFirstLetterUpperCase(HttpStatusCode[responseCode]);

  return (
    <span
      className={classNames(
        "badge",
        isOKResponse ? "badge-soft-success" : "badge-soft-danger",
        className,
      )}
    >
      <b>{text}</b>
    </span>
  );
};

type ActiveStatusBadgeProps = {
  status: boolean;
  className?: string;
};

export const ActiveStatusBadge = ({ status, className }: ActiveStatusBadgeProps) => {
  return (
    <>
      {(() => {
        switch (status) {
          case false:
            return (
              <span className={classNames("badge badge-soft-dark", className)}>Not active</span>
            );
          case true:
            return (
              <span className={classNames("badge badge-soft-success", className)}>Active</span>
            );
          default:
            return <span className={classNames("badge badge-light", className)}>{status}</span>;
        }
      })()}
    </>
  );
};

type StatusBadgeProps = {
  status: string | JSX.Element;
  className?: string;
};

export const StatusBadge = ({ status, className }: StatusBadgeProps) => {
  return (
    <>
      {(() => {
        switch (status) {
          case "Not Started":
            return (
              <span className={classNames("badge badge-soft-dark", className)}>Not Started</span>
            );
          case "In Progress":
            return (
              <span className={classNames("badge badge-soft-warning", className)}>In Progress</span>
            );
          case "Complete":
            return (
              <span className={classNames("badge badge-soft-success", className)}>Complete</span>
            );
          case "Demo":
            return <span className={classNames("badge badge-soft-warning", className)}>Demo</span>;
          default:
            return <span className={classNames("badge badge-light", className)}>{status}</span>;
        }
      })()}
    </>
  );
};

type MergeChargeStatusBadgeProps = {
  status?: MergeChargeStatus | null;
  className?: string;
};
export const MergeChargeStatusBadge = ({ status, className }: MergeChargeStatusBadgeProps) => {
  if (!status) return null;
  switch (status) {
    case MergeChargeStatus.CHARGE_STATUS_FAILURE:
      return <span className={classNames("badge badge-soft-danger", className)}>Failed</span>;
    case MergeChargeStatus.CHARGE_STATUS_OVERDUE:
      return <span className={classNames("badge badge-soft-danger", className)}>Overdue</span>;
    case MergeChargeStatus.CHARGE_STATUS_SUSPENDED:
      return <span className={classNames("badge badge-soft-dark", className)}>Suspended</span>;
    case MergeChargeStatus.CHARGE_STATUS_PENDING:
      return <span className={classNames("badge badge-soft-danger", className)}>Not paid</span>;
    case MergeChargeStatus.CHARGE_STATUS_SUCCESS:
      return <span className={classNames("badge badge-soft-success", className)}>Paid</span>;
    case MergeChargeStatus.CHARGE_STATUS_WAIVED:
      return <span className={classNames("badge badge-soft-success", className)}>Waived</span>;
    default:
      return null;
  }
};

type BlueprintStatusBadgeProps = {
  status: string | JSX.Element;
  className?: string;
};

export const BlueprintStatusBadge = ({ status, className }: BlueprintStatusBadgeProps) => {
  return (
    <>
      {(() => {
        switch (status) {
          case "DRAFT":
            return <span className={classNames("badge badge-soft-dark", className)}>Draft</span>;
          case "PUBLISHED":
            return (
              <span className={classNames("badge badge-soft-success", className)}>Published</span>
            );
          case "INCOMPLETE":
            return (
              <span className={classNames("badge badge-soft-warning", className)}>Incomplete</span>
            );
          case "UNMAPPED":
            return (
              <span className={classNames("badge badge-soft-danger", className)}>Unmapped</span>
            );
          default:
            return <span className={classNames("badge badge-light", className)}>{status}</span>;
        }
      })()}
    </>
  );
};

type BlueprintRunnerTestRunStatusBadgeProps = {
  exitCode: number;
};

export const BlueprintRunnerTestRunStatusBadge = ({
  exitCode,
}: BlueprintRunnerTestRunStatusBadgeProps) => {
  return (
    <>
      {exitCode === 200 ? (
        <Badge variant="success" className="deprecated-ml-2">
          Success!
        </Badge>
      ) : (
        <Badge variant="danger" className="deprecated-ml-2">
          Error
        </Badge>
      )}
    </>
  );
};

type HTTPMethodBadgeProps = {
  method: HTTPMethod | string;
  className?: string;
};

export const HTTPMethodBadge = ({ method, className }: HTTPMethodBadgeProps) => {
  let color: string | undefined = undefined;
  let backgroundColor: string | undefined = undefined;
  switch (method) {
    case HTTPMethod.GET:
      color = palette.blue;
      backgroundColor = spectrum.blue0;
      break;
    case HTTPMethod.POST:
      color = palette.pink;
      backgroundColor = spectrum.pink0;
      break;
    case HTTPMethod.PUT:
      color = palette.green;
      backgroundColor = spectrum.teal0;
      break;
    case HTTPMethod.DELETE:
      color = palette.red;
      backgroundColor = spectrum.red0;
      break;
    case HTTPMethod.PATCH:
      color = palette.black;
      backgroundColor = spectrum.gray0;
      break;
    case HTTPMethod.OPTIONS:
      color = palette.purple;
      backgroundColor = spectrum.purple0;
      break;
    case HTTPMethod.HEAD:
      color = palette.yellow;
      backgroundColor = spectrum.yellow0;
      break;
    case "FETCH":
      color = palette.blue;
      backgroundColor = spectrum.blue0;
      break;
  }

  return (
    <Badge
      style={{ color, backgroundColor }}
      className={classNames(`http-method-badge font-weight-bold`, className)}
    >
      <b>{method}</b>
    </Badge>
  );
};

/**
 * Badge for things like "Beta", "Deprecated", etc.
 */
const ApiStatusTagBaseSpan = styled.span`
  border-radius: 4px;
  margin: 0px 8px;
  padding: 2px 5px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 600;
`;

/*
 * Color and background are missing from the above 'APIStatusTagBaseSpan', they are added in the below component dependent on what you want to show
 */

const BetaTagSpan = styled(ApiStatusTagBaseSpan)`
  color: ${spectrum.pink50};
  background: ${spectrum.pink0};
`;
interface BetaBadgeProps {
  style?: Record<string, unknown>;
}
export const BetaBadge = ({ style }: BetaBadgeProps) => (
  <BetaTagSpan style={style}>Beta</BetaTagSpan>
);

const BlueBetaTagSpan = styled(ApiStatusTagBaseSpan)`
  color: ${spectrum.blue40};
  background: ${spectrum.blue0};
`;
export const BlueBetaBadge = ({ style }: BetaBadgeProps) => (
  <BlueBetaTagSpan style={style}>Beta</BlueBetaTagSpan>
);

const NewTagSpan = styled(ApiStatusTagBaseSpan)`
  color: ${spectrum.teal50};
  background: ${spectrum.green0};
`;

export const NewBadge = () => <NewTagSpan>New</NewTagSpan>;

const ComingSoonSpan = styled(ApiStatusTagBaseSpan)`
  color: ${spectrum.slate70};
  background: ${spectrum.slate0};
  margin: 0;
  width: 87px;
  height: 20px;
`;

export const ComingSoonBadge = () => <ComingSoonSpan>Coming soon</ComingSoonSpan>;

type CommonModelBadgeProps = {
  commonModel: string;
  color?: string;
  background?: string;
};

export const CommonModelBadge = ({ commonModel, color, background }: CommonModelBadgeProps) => {
  let style = "light";
  const category = apiCategoryFromString(commonModel.split(".")[0]);
  if (!color && !background) {
    switch (category) {
      case APICategory.hris:
        style = "soft-primary";
        break;
      case APICategory.ats:
        style = "soft-secondary";
        break;
      case APICategory.accounting:
        style = "soft-success";
        break;
      case APICategory.ticketing:
        style = "soft-danger";
        break;
      case APICategory.crm:
        style = "soft-warning";
        break;
      case APICategory.mktg:
        style = "soft-warning";
        break;
      case APICategory.filestorage:
        style = "soft-warning";
        break;
      default:
        // If this fails to compile, add the category above
        ((_: never) => {})(category);
    }
  }

  return (
    <Badge
      variant={style}
      style={{ color: color, background: background }}
      className="deprecated-mr-2"
    >
      {commonModel}
    </Badge>
  );
};

interface CustomBadgeProps {
  text: string;
  style?: string;
}

export const CustomBadge = ({ text, style }: CustomBadgeProps) => {
  if (!style) {
    style = "light";
  }
  return (
    <Badge variant={style} className="deprecated-mr-2">
      {text}
    </Badge>
  );
};

type OperationTypeBadgeProps = { operationType: string };
export const OperationTypeBadge = ({ operationType }: OperationTypeBadgeProps) => {
  return <Badge variant="light">{operationType}</Badge>;
};
