import React from "react";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared-components/MergeLayouts";
import {
  getLinkedAccountDetailAPIRequestLogsSettingsPathForId,
  getLinkedAccountDetailWebhookLogsSettingsPathForId,
} from "../../../../router/RouterUtils";
import SubtabToggle from "../../../shared-components/form-field/SubtabToggle";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import WebhookLogsPage from "../../logs/pages/WebhooksLogsPage";
import { Props as MetadataPageProps } from "../../shared/MetadataPageLayout";
import LogsPage from "../../logs/pages/LogsPage";
import { Subtab } from "../../../portal/HeaderBar";
import styled from "styled-components";

type Props = Pick<MetadataPageProps, "linkedAccount"> & {
  /**
   * If the linked account is null, use this to fetch data
   */
  id: string;
};

const MarginLeftSubtabToggle = styled(SubtabToggle)`
  margin-left: 20px;
`;

const MarginBottomSectionHeader = styled(SectionHeaderWrapper)`
  margin-bottom: 24px;
`;

/**
 * Shows a few settings for the linked account itself. Always guaranteed to have content if there's a linked account
 */
const LinkedAccountLogsTab = ({ id, linkedAccount }: Props) => {
  const location = useLocation();
  const subtitle = location.pathname.startsWith(
    getLinkedAccountDetailAPIRequestLogsSettingsPathForId(id),
  )
    ? "View API request logs related to this Linked Account."
    : "View webhook logs related to this Linked Account.";

  const subTabs: Subtab[] = [
    {
      label: "API Requests",
      destination: getLinkedAccountDetailAPIRequestLogsSettingsPathForId(id),
    },
    {
      label: "Webhooks",
      destination: getLinkedAccountDetailWebhookLogsSettingsPathForId(id),
    },
  ];
  return (
    <>
      <MarginBottomSectionHeader
        title={
          <div className="d-flex justify-content-center align-items-center">
            Logs <MarginLeftSubtabToggle isRounded subtabs={subTabs} />{" "}
          </div>
        }
        titleHeading={TitleHeading.H1}
        subtitle={subtitle}
      >
        <Switch>
          <Route
            path={getLinkedAccountDetailAPIRequestLogsSettingsPathForId(id)}
            render={(props) => <LogsPage {...props} linkedAccount={linkedAccount} />}
          />

          <Route
            path={getLinkedAccountDetailWebhookLogsSettingsPathForId(id)}
            render={(props) => <WebhookLogsPage {...props} linkedAccount={linkedAccount} />}
          />

          <Redirect to={getLinkedAccountDetailAPIRequestLogsSettingsPathForId(id)} />
        </Switch>
      </MarginBottomSectionHeader>
    </>
  );
};

export default LinkedAccountLogsTab;
