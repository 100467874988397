import React from "react";
import styled from "styled-components";
import { EnabledAction } from "../../../models/CommonModel";
import CenteredVerticalLineCol from "./CenteredVerticalLineCol";
import { IsDescriptionShowingMap, Schema } from "./CommonModelToggleTypes";
import { ModelWithSortedFields } from "./CommonModelUtils";
import FieldToggle, { Disableability, RemoteDataToggle, StyledMultiSwitch } from "./FieldToggle";

interface SubobjectFieldsGroupProps {
  model: ModelWithSortedFields;
  schema?: Schema;
  canToggleFields: boolean;
  changeState: (
    modelName: string,
    fieldName: string,
    enabledActions: Array<EnabledAction>,
  ) => Promise<void>;
  isDescriptionShowingForField: IsDescriptionShowingMap[string] | undefined;
  setIsDescriptionShowingForField: (fieldName: string, isDescriptionShowing: boolean) => void;
}

const StyledFieldToggle = styled(FieldToggle)`
  ${StyledMultiSwitch} {
    margin-right: -5px;
  }
  margin-left: -12px;
`;

const StyledRemoteDataToggle = styled(RemoteDataToggle)`
  margin-left: -12px;

  ${CenteredVerticalLineCol} {
    margin-left: 0px;
  }
`;

const SubobjectFieldsGroup = ({
  model,
  schema,
  canToggleFields,
  changeState,
  isDescriptionShowingForField,
  setIsDescriptionShowingForField,
}: SubobjectFieldsGroupProps) => {
  const remoteDataField = model.fields["remote_data"];

  return (
    <>
      {Object.values(model.fields)
        .filter((field) => field.field_name != "remote_data")
        .map((field, index, arr) => {
          return (
            <StyledFieldToggle
              key={model.name + "-" + field.field_name}
              displayName={field.field_name}
              isEnabled={field.is_enabled}
              type={schema?.parameters[field.field_name]?.type}
              description={schema?.parameters[field.field_name]?.description}
              disableability={
                canToggleFields
                  ? Disableability.DISABLEABLE
                  : Disableability.DISABLEABLE_WITH_UPGRADE
              }
              iconColumnWidthInPixels={48}
              verticalLineConfig={index === arr.length - 1 ? "top-half" : "all"}
              showDescription={isDescriptionShowingForField?.[field.field_name]}
              setShowDescription={(showDescription) => {
                setIsDescriptionShowingForField(field.field_name, showDescription);
              }}
              changeState={(enabledActions) =>
                changeState(model.name, field.field_name, enabledActions)
              }
              className="w-100"
            />
          );
        })}
      {remoteDataField && (
        <StyledRemoteDataToggle
          key={model.name + "-" + remoteDataField.field_name}
          displayName={remoteDataField.field_name}
          isEnabled={remoteDataField.is_enabled}
          type={schema?.parameters["remote_data"]?.type}
          disableability={
            canToggleFields ? Disableability.DISABLEABLE : Disableability.DISABLEABLE_WITH_UPGRADE
          }
          iconColumnWidthInPixels={48}
          changeState={(enabledActions) =>
            changeState(model.name, remoteDataField.field_name, enabledActions)
          }
          verticalLineConfig="none"
          className="deprecated-mb-2 deprecated-mr-3 w-100"
        />
      )}
    </>
  );
};

export default SubobjectFieldsGroup;
