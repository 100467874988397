import React, { useState } from "react";
import styled from "styled-components";
import {
  getFormattedEventDistanceFromNow,
  getIssueUpdatedAt,
} from "../../../utils/IntegrationsManagementUtils";
import { useHistory } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { navigateToIndividualIssuePage } from "../../../../../router/RouterUtils";
import { snakeCaseToFirstLetterUpperCase } from "../../../../../services";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { MessageSquare, Check, VolumeX, ChevronRight, Volume1 } from "lucide-react";
import { updateIntegrationIssueStatus } from "../../../utils/IntegrationsManagementAPIClient";
import { IntegrationIssueStatus } from "../../../IntegrationsManagementEntities";
import { showSuccessToast, showErrorToast } from "../../../../shared-components/Toasts";
import { IntegrationIssueCommentModal } from "../../../issues/IntegrationIssueModals";
import { muteIntegrationIssue } from "../../../utils/IntegrationsManagementAPIClient";
import { getLastActionMessage } from "../../../issues/IntegrationsManagementIssuesPage";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";
import { isIssueMuted } from "../../../utils/IntegrationsManagementUtils";
type IssueStatusProps = {
  status: string;
};
const IssueStatusText = styled.td<IssueStatusProps>`
  color: ${(props) => (props.status === IntegrationIssueStatus.ONGOING ? "#E62837" : "#1A9B87")};
  font-weight: 500;
`;

const ShortcutContainer = styled.div`
  gap: 12px;
`;

const ClickableIssueRow = styled.tr`
  cursor: pointer;
`;
type IssuePageProps = {
  linkedAccountIssue: IntegrationIssue;
};

export const getMuteFor1DayTime = () => {
  const now = new Date();
  now.setHours(24);
  return now;
};
const IssuesPageRow = ({ linkedAccountIssue }: IssuePageProps) => {
  const history = useHistory();
  const [integrationIssue, setIntegrationIssue] = useState(linkedAccountIssue);
  const [isCommentModalShown, setIsCommentModalShown] = useState(false);

  const isMuted = isIssueMuted(integrationIssue);
  return (
    <ClickableIssueRow
      onClick={() => {
        if (!isCommentModalShown) {
          navigateToIndividualIssuePage(history, integrationIssue.id, "linked_account_page");
        }
      }}
    >
      <td colSpan={2} className="font-medium">
        {integrationIssue.error_description}
      </td>
      <IssueStatusText status={integrationIssue.status}>
        {snakeCaseToFirstLetterUpperCase(integrationIssue.status)}
      </IssueStatusText>
      <td className="text-gray-50">
        {getLastActionMessage(integrationIssue.last_action) === "None"
          ? "–"
          : getLastActionMessage(integrationIssue.last_action)}
      </td>
      <td className="text-gray-50">
        {getFormattedEventDistanceFromNow(getIssueUpdatedAt(integrationIssue))}
      </td>
      <td className="text-gray-50">
        {getFormattedEventDistanceFromNow(integrationIssue.first_incident_time)}
      </td>
      <td>
        <ShortcutContainer className="d-flex align-items-center justify-content-start">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="issues-comment">View comments</Tooltip>}
          >
            <div>
              <SmallWhiteButton variant="singleIcon">
                <MessageSquare
                  size={16}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsCommentModalShown(!isCommentModalShown);
                  }}
                />
              </SmallWhiteButton>
            </div>
          </OverlayTrigger>
          {integrationIssue.status === IntegrationIssueStatus.ONGOING && (
            <>
              {isMuted ? (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="issues-mute">Unmute ongoing issue</Tooltip>}
                >
                  <div>
                    <SmallWhiteButton variant="singleIcon">
                      <Volume1
                        size={16}
                        onClick={(e) => {
                          e.stopPropagation();
                          muteIntegrationIssue({
                            integrationIssueID: integrationIssue.id,
                            muted_until: undefined,
                            mute_incident_count: undefined,
                            onSuccess: (updatedIntegrationIssue) => {
                              setIntegrationIssue(updatedIntegrationIssue);
                              showSuccessToast("Successfully unmuted issue");
                            },
                            onFailure: () => {
                              showErrorToast("Error occurred while muting issue");
                            },
                          });
                        }}
                      />
                    </SmallWhiteButton>
                  </div>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="issues-mute">Mute ongoing issue</Tooltip>}
                >
                  <div>
                    <SmallWhiteButton variant="singleIcon">
                      <VolumeX
                        size={16}
                        onClick={(e) => {
                          e.stopPropagation();
                          muteIntegrationIssue({
                            integrationIssueID: integrationIssue.id,
                            muted_until: getMuteFor1DayTime(),
                            onSuccess: (updatedIntegrationIssue) => {
                              setIntegrationIssue(updatedIntegrationIssue);
                              showSuccessToast("Successfully muted issue for 24 hours");
                            },
                            onFailure: () => {
                              showErrorToast("Error occurred while muting issue");
                            },
                          });
                        }}
                      />
                    </SmallWhiteButton>
                  </div>
                </OverlayTrigger>
              )}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="issues-resolve">Resolve ongoing issue</Tooltip>}
              >
                <div>
                  <SmallWhiteButton variant="singleIcon">
                    <Check
                      size={16}
                      onClick={(e) => {
                        e.stopPropagation();
                        updateIntegrationIssueStatus({
                          integrationIssueID: integrationIssue.id,
                          status: IntegrationIssueStatus.RESOLVED,
                          onSuccess: (updatedIntegrationIssue) => {
                            setIntegrationIssue(updatedIntegrationIssue);
                            showSuccessToast("Updated Integration Issue");
                          },
                          onError: () => {
                            showErrorToast("Unable To Update Integration Issue");
                          },
                        });
                      }}
                    />
                  </SmallWhiteButton>
                </div>
              </OverlayTrigger>
            </>
          )}
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="issue-navigate">See more details</Tooltip>}
          >
            <div
              className="ml-auto"
              onClick={() => {
                navigateToIndividualIssuePage(history, integrationIssue.id, "linked_account_page");
              }}
            >
              <ChevronRight size={12} />
            </div>
          </OverlayTrigger>
        </ShortcutContainer>
      </td>
      <IntegrationIssueCommentModal
        integrationIssue={integrationIssue}
        isShown={isCommentModalShown}
        setIsShown={setIsCommentModalShown}
      />
    </ClickableIssueRow>
  );
};
export default IssuesPageRow;
