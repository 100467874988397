import { CalendarDays, ChevronsUp, RefreshCw } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import {
  fetchOrganizationIntegrationSettings,
  toggleEnableCategoryIntegrations,
} from "../../../api-client/organization/OrganizationIntegrationSettingsAPIClient";
import {
  APICategory,
  OrganizationIntegrationSettings,
  SyncFrequencyPlan,
} from "../../../models/Entities";
import { displayNameForAPICategory } from "../../../models/Helpers";
import {
  CONFIGURATION_LINK_PATH,
  getForceResyncDocsPathForCategory,
  PROD_LINKED_ACCOUNTS_PATH,
} from "../../../router/RouterUtils";
import { palette } from "../../../styles/theme";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import ConfigurationIntegrationsList from "./ConfigurationIntegrationsList";
import { ReactComponent as ManualSyncIcon } from "../../../assets/svg/manual-sync-icon.svg";
import useAppContext from "../../context/useAppContext";
import { SmallWhiteButton, Link } from "@merge-api/merge-javascript-shared";
import { isBillingPlanFreeLaunch } from "../../settings/billing/BillingUtils";
import { BillingPlan } from "../../settings/billing/BillingModels";
import ConfigurationIntegrationsPageWrapper from "./ConfigurationIntegrationsPageWrapper";
import UpsellModal from "../../../components/shared-components/UpsellModal";
import { ReactComponent as FDSyncFrequency } from "../../../assets/svg/feature-discovery-sync-frequency.svg";
import SyncFrequencyUpsellModal from "./ConfigurationIntegrationSyncFrequencyUpsellModal";

const SyncFrequencyBadge = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 4px 8px;
  align-items: center;
  color: ${palette.white};
`;

const LINKED_ACCOUNT_PATH = "https://app.merge.dev/linked-accounts/accounts";
const FORCE_RESYNC_API_PATH = "https://docs.merge.dev/ticketing/force-resync/";

const getSyncPlanSubtitleForPlanType = (
  category: APICategory,
  categoryDisplayName: string,
  syncPlanType: SyncFrequencyPlan,
  org_billing_plan: BillingPlan | undefined,
  showUpsellModal: boolean,
  setShowUpsellModal: (isOpen: boolean) => void,
) => {
  switch (syncPlanType) {
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST_WITH_DELETED_DATA:
      return (
        <>
          Your <b>Highest with Deleted Data Sync Plan</b> syncs data at the highest frequency
          offered by each {categoryDisplayName} integration.
        </>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST:
      return (
        <>
          Your <b>Highest Sync Plan</b> syncs data at the highest frequency offered by each{" "}
          {categoryDisplayName} integration.
        </>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST_MANUAL:
      return (
        <>
          Your <b>Highest Manual Sync Plan</b> enables you to resync your data in each{" "}
          <Link href={LINKED_ACCOUNT_PATH}>Linked Account's</Link> page or{" "}
          <Link href={FORCE_RESYNC_API_PATH}>via API</Link>.
        </>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_DAILY:
      return (
        <>
          Your <b>Daily Sync Plan</b> syncs data every 24 hours.
          {isBillingPlanFreeLaunch(org_billing_plan) && (
            <Link
              href="#"
              onClick={() => {
                setShowUpsellModal(true);
              }}
            >
              {" "}
              Change your sync frequency.
            </Link>
          )}
        </>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_QUARTERLY:
      return (
        <>
          <div>
            Your <b>Quarterly Sync Plan</b> grants you <b>6 sync credits</b> annually per Linked
            Account.
          </div>
          <div>
            Resync your data in each{" "}
            <Link href={PROD_LINKED_ACCOUNTS_PATH}>Linked Account's page</Link> or{" "}
            <Link href={getForceResyncDocsPathForCategory(category)}>via API</Link> using these sync
            credits.
          </div>
        </>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_MONTHLY:
      return (
        <>
          <div>
            Your <b>Monthly Sync Plan</b> grants you <b>16 sync credits</b> annually per Linked
            Account.
          </div>
          <div>
            Resync your data in each{" "}
            <Link href={PROD_LINKED_ACCOUNTS_PATH}>Linked Account's page</Link> or{" "}
            <Link href={getForceResyncDocsPathForCategory(category)}>via API</Link> using these sync
            credits.
          </div>
        </>
      );
    default:
      return <></>;
  }
};

const getSyncPlanBadgeForPlanType = (syncPlanType: SyncFrequencyPlan) => {
  const iconProps = { size: 14, color: palette.white };
  switch (syncPlanType) {
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST_WITH_DELETED_DATA:
      return (
        <SyncFrequencyBadge $color="#F97316">
          <ChevronsUp {...iconProps} />
          Highest
        </SyncFrequencyBadge>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST:
      return (
        <SyncFrequencyBadge $color="#F97316">
          <ChevronsUp {...iconProps} />
          Highest
        </SyncFrequencyBadge>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST_MANUAL:
      return (
        <SyncFrequencyBadge $color="#939AA5">
          <ManualSyncIcon {...iconProps} />
          Manual
        </SyncFrequencyBadge>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_DAILY:
      return (
        <SyncFrequencyBadge $color="#FBBF24">
          <CalendarDays {...iconProps} />
          Daily
        </SyncFrequencyBadge>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_MONTHLY:
      return (
        <SyncFrequencyBadge $color="#939AA5">
          <ManualSyncIcon {...iconProps} />
          Manual
        </SyncFrequencyBadge>
      );
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_QUARTERLY:
      return (
        <SyncFrequencyBadge $color="#939AA5">
          <ManualSyncIcon {...iconProps} />
          Manual
        </SyncFrequencyBadge>
      );
    default:
      return <></>;
  }
};

type Props = {
  category: APICategory;
};

const ConfigurationEnabledIntegrationsPage = ({ category }: Props) => {
  const [organizationIntegrationsSettings, setOrganizationIntegrationsSettings] = useState<
    Array<OrganizationIntegrationSettings> | undefined
  >();

  const { user } = useAppContext();
  const syncPlanMap = user.organization.sync_frequency_plans;
  const syncPlanType =
    syncPlanMap?.[category as string] ||
    syncPlanMap?.[SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_DEFAULT];
  const isSyncFrequencyPlan = (Object as any).values(SyncFrequencyPlan).includes(syncPlanType);

  const [showUpsellModal, setShowUpsellModal] = useState(false);

  useEffect(() => {
    fetchOrganizationIntegrationSettings({
      onlyEnabled: false,
      onFetch: (data) => {
        setOrganizationIntegrationsSettings(data);
      },
    });
  }, []);

  function enableCategoryIntegrations(is_enabled: boolean) {
    toggleEnableCategoryIntegrations({
      category: category,
      isEnabled: is_enabled,
      onToggle: (data: Array<OrganizationIntegrationSettings>) => {
        showSuccessToast(
          "Successfully " +
            (is_enabled
              ? "enabled all integrations!"
              : "disabled all integrations without a Linked Account!"),
        );
        setOrganizationIntegrationsSettings(data);
      },
      onError: () => {
        showErrorToast(
          "Unable to " +
            (is_enabled
              ? "enabled all integrations."
              : "disabled all integrations without a Linked Account."),
        );
      },
    });
  }

  return (
    <>
      <SyncFrequencyUpsellModal
        showUpsellModal={showUpsellModal}
        setShowUpsellModal={setShowUpsellModal}
      />

      <ConfigurationIntegrationsPageWrapper
        category={category}
        opacity={showUpsellModal ? "0.5" : "1"}
      >
        <Row>
          <Col>
            <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
              <SectionHeaderWrapper
                title={<>Configure {displayNameForAPICategory(category)} Integrations</>}
                subtitle={
                  <div className="mt-6">
                    {isSyncFrequencyPlan && syncPlanType !== undefined && (
                      <div
                        className="p-4 md:p-6 flex flex-row items-center justify-between rounded-lg mb-7"
                        style={{ backgroundColor: `${palette.lightGray}` }}
                      >
                        <div>
                          <div className="flex flex-row items-center font-inter font-semibold text-lg leading-6 space-x-2.5 pb-2">
                            <RefreshCw size={16} />
                            <div>{displayNameForAPICategory(category)} Sync Frequency</div>
                          </div>
                          <div className="small">
                            {getSyncPlanSubtitleForPlanType(
                              category,
                              displayNameForAPICategory(category),
                              syncPlanType!,
                              user.organization.organization_billing_plan?.billing_plan,
                              showUpsellModal,
                              setShowUpsellModal,
                            )}
                          </div>
                        </div>
                        {getSyncPlanBadgeForPlanType(syncPlanType)}
                      </div>
                    )}
                    <div className="flex flex-row justify-between">
                      <div>
                        Toggle and reorder integrations below to customize your{" "}
                        <Link href={CONFIGURATION_LINK_PATH} className="">
                          <b>Merge Link</b>
                        </Link>
                        .
                      </div>
                      <div className="d-flex">
                        <SmallWhiteButton
                          className="mr-2"
                          onClick={() => enableCategoryIntegrations(true)}
                        >
                          Enable all
                        </SmallWhiteButton>
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 0, hide: 0 }}
                          overlay={
                            <Tooltip id="disable-integrations-tooltip">
                              Only disables integrations without active Linked Accounts.
                            </Tooltip>
                          }
                        >
                          <div>
                            <SmallWhiteButton
                              className="mr-2"
                              onClick={() => enableCategoryIntegrations(false)}
                            >
                              Disable all
                            </SmallWhiteButton>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                }
                titleHeading={TitleHeading.H1}
              >
                <Row className="deprecated-mt-4">
                  <Col>
                    {organizationIntegrationsSettings ? (
                      <ConfigurationIntegrationsList
                        key={category}
                        category={category}
                        organizationIntegrationsSettings={organizationIntegrationsSettings}
                      />
                    ) : (
                      <EmptyStateWrapper isSpinner />
                    )}
                  </Col>
                </Row>
              </SectionHeaderWrapper>
            </div>
          </Col>
        </Row>
      </ConfigurationIntegrationsPageWrapper>
    </>
  );
};

export default ConfigurationEnabledIntegrationsPage;
