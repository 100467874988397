import * as H from "history";
import { useLocation } from "react-router-dom";
import { APICategory, LinkedAccount } from "../models/Entities";
import { compressToEncodedURIComponent } from "lz-string";
import { serializeFiltersForURL } from "../components/integrations-management/searchbar/SearchBar";

type History = H.History<unknown>;

export const MERGE_APP_PATH = "https://app.merge.dev";
export const MERGE_APP_EU_PATH = "https://app-eu.merge.dev";
export const ROOT_PATH = "/";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const LOGIN_WITH_SSO_PATH = "/login/send-sso-url";
export const SAML_NO_ACCOUNT_FOUND = "/sso/saml/no-account-found";
export const PASSWORD_RESET_PATH = "/password/reset";
export const PASSWORD_SEND_RESET_PATH = "/password/send-reset";
export const RESPONSIBLE_DISCLOSURE_PATH = "/responsible-disclosure";
export const OAUTH_CALLBACK_PATH = "/oauth/callback";
export const SAML_RELAY_PATH = "/sso/saml/relay";
export const DASHBOARD_PATH = "/dashboard";
export const ONBOARDING_PATH = "/get-started";
export const SYNC_SANDBOX_PATH = `/sync-sandbox-data`;
export const SEND_API_REQUEST_PATH = `/send-api-request`;
export const INTEGRATE_SYNCED_DATA_PATH = `/integrate-synced-data`;
export const ADD_LINK_TO_APP_PATH = `/add-link-to-app`;
export const SYNCING_DATA_PATH = `/syncing-data`;
export const MERGE_WRITES_PATH = `/merge-writes`;
export const WEBHOOKS_PATH = `/webhooks`;
export const CUSTOM_FIELDS_PATH = `/custom-fields`;
export const METADATA_PATH = `/metadata`;
export const SUPPLEMENTAL_DATA_PATH = `/supplemental-data`;
export const SENSITIVE_DATA_PATH = `/sensitive-data`;
export const TROUBLESHOOTING_PATH = `/troubleshooting`;
export const ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH = `${ONBOARDING_PATH}${SYNC_SANDBOX_PATH}`;
export const ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH = `${ONBOARDING_PATH}${SEND_API_REQUEST_PATH}`;
export const ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH = `${ONBOARDING_PATH}${INTEGRATE_SYNCED_DATA_PATH}`;
export const ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH = `${ONBOARDING_PATH}${ADD_LINK_TO_APP_PATH}`;
export const ONBOARDING_ADVANCED_SYNCING_DATA_PATH = `${ONBOARDING_PATH}${SYNCING_DATA_PATH}`;
export const ONBOARDING_ADVANCED_MERGE_WRITES_PATH = `${ONBOARDING_PATH}${MERGE_WRITES_PATH}`;
export const ONBOARDING_ADVANCED_WEBHOOKS_PATH = `${ONBOARDING_PATH}${WEBHOOKS_PATH}`;
export const ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH = `${ONBOARDING_PATH}${CUSTOM_FIELDS_PATH}`;
export const ONBOARDING_ADVANCED_METADATA_PATH = `${ONBOARDING_PATH}${METADATA_PATH}`;
export const ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH = `${ONBOARDING_PATH}${SUPPLEMENTAL_DATA_PATH}`;
export const ONBOARDING_ADVANCED_SENSITIVE_DATA_PATH = `${ONBOARDING_PATH}${SENSITIVE_DATA_PATH}`;
export const ONBOARDING_ADVANCED_TROUBLESHOOTING_PATH = `${ONBOARDING_PATH}${TROUBLESHOOTING_PATH}`;
export const LINKED_ACCOUNTS_PATH = "/linked-accounts";
export const PROD_LINKED_ACCOUNTS_PATH = "/linked-accounts/accounts";
export const TEST_LINKED_ACCOUNTS_PATH = "/linked-accounts/test-accounts";
export const LINKED_ACCOUNT_DETAIL_PATH = `${LINKED_ACCOUNTS_PATH}/account`;
export const LINKED_ACCOUNT_DETAIL_ROUTE = `${LINKED_ACCOUNT_DETAIL_PATH}/:id`;
export const LOGS_PATH = "/logs";
export const API_REQUESTS_LOGS_PATH = `${LOGS_PATH}/api-requests`;
export const WEBHOOKS_LOGS_PATH = `${LOGS_PATH}/webhooks`;
export const ISSUES_PATH = "/issues";
export const API_TESTER_PATH = "/api-tester";
export const CONFIGURATION_PATH = "/configuration";
export const CONFIGURATION_WEBHOOKS_PATH = `${CONFIGURATION_PATH}/webhooks`;
export const CONFIGURATION_WEBHOOKS_EMITTERS_PATH = `${CONFIGURATION_WEBHOOKS_PATH}/emitters`;
export const CONFIGURATION_WEBHOOKS_EMITTERS_ADD_PATH = `${CONFIGURATION_WEBHOOKS_EMITTERS_PATH}/add`;
export const CONFIGURATION_WEBHOOKS_RECEIVERS_PATH = `${CONFIGURATION_WEBHOOKS_PATH}/receivers`;
export const CONFIGURATION_FIELD_MAPPINGS_PATH = `${CONFIGURATION_PATH}/field-mappings`;
export const CONFIGURATION_CUSTOM_OBJECTS_PATH = `${CONFIGURATION_PATH}/custom-objects`;
export const CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH = `${CONFIGURATION_FIELD_MAPPINGS_PATH}/target-fields`;
export const CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_PATH = `${CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}/:id`;
export const CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH = `${CONFIGURATION_FIELD_MAPPINGS_PATH}/target-fields/:id/settings`;
export const CONFIGURATION_FIELD_MAPPINGS_COMMON_MODEL_OVERRIDES_PATH = `${CONFIGURATION_FIELD_MAPPINGS_PATH}/common-model-overrides/:id`;
export const CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_INTEGRATION_CREATE_PATH = `${CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}/:id/:integrationID/create`;
export const CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH = `${CONFIGURATION_FIELD_MAPPINGS_PATH}/linked-accounts`;
export const CONFIGURATION_ADVANCED_PATH = `${CONFIGURATION_PATH}/advanced`;
export const CONFIGURATION_ADVANCED_CUSTOM_FIELDS_PATH = `${CONFIGURATION_PATH}/field-mappings/linked-accounts`;
export const CONFIGURATION_INTEGRATIONS_PATH = `/integrations`;
export const CONFIGURATION_COMMON_MODELS_PATH = `/common-models`;
export const CONFIGURATION_ADVANCED_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH = `${CONFIGURATION_PATH}/field-mappings/linked-accounts`;
export const CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH = `${CONFIGURATION_PATH}/field-mappings/target-fields`;
export const CONFIGURATION_ADVANCED_SELECTIVE_SYNC_PATH = `${CONFIGURATION_ADVANCED_PATH}/selective-sync`;
export const CONFIGURATION_ADVANCED_SELECTIVE_SYNC_SETTINGS_PATH = `${CONFIGURATION_ADVANCED_SELECTIVE_SYNC_PATH}/settings`;
export const CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH = `${CONFIGURATION_INTEGRATIONS_PATH}/custom-integrations`;
export const CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_ADD_PATH = `${CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH}/add`;
export const CONFIGURATION_LINK_PATH = `${CONFIGURATION_PATH}/link`;
export const CONFIGURATION_LINK_ERROR_MESSAGES = `${CONFIGURATION_LINK_PATH}/error-messages`;
export const CONFIGURATION_LINK_SELECTIVE_SYNC = `${CONFIGURATION_LINK_PATH}/selective-sync`;
export const CONFIGURATION_LINK_REQUESTED_DATA_PATH = `${CONFIGURATION_LINK_PATH}/requested-data`;
export const CONFIGURATION_LINK_AUTHENTICATION_PATH = `${CONFIGURATION_LINK_PATH}/authentication`;
export const CONFIGURATION_LINK_INTEGRATIONS_PATH = `${CONFIGURATION_LINK_PATH}/integrations`;
export const API_KEYS_PATH = "/keys";

// settings
export const PROFILE_PATH = "/profile";
export const SECURITY_PATH = "/security";
export const NOTIFICATIONS_SETTINGS_PATH = "/notifications";
export const ORGANIZATION_PATH = "/organization";
export const AUDIT_TRAIL_PATH = "/audit-trail";
export const BILLING_PATH = "/billing";
export const REFERRALS_PATH = "/referrals";
export const SETTINGS_PATHS = [
  PROFILE_PATH,
  SECURITY_PATH,
  ORGANIZATION_PATH,
  AUDIT_TRAIL_PATH,
  BILLING_PATH,
  NOTIFICATIONS_SETTINGS_PATH,
  REFERRALS_PATH,
];

// Tab/Route Disable Constants
export const OVERDUE_INVOICE_DISABLED_ROUTES = [
  DASHBOARD_PATH,
  ONBOARDING_PATH,
  ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
  ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
  ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
  ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
  LINKED_ACCOUNTS_PATH,
  LOGS_PATH,
  ISSUES_PATH,
  API_TESTER_PATH,
  CONFIGURATION_PATH,
  PROFILE_PATH,
  SECURITY_PATH,
  ORGANIZATION_PATH,
  AUDIT_TRAIL_PATH,
  NOTIFICATIONS_SETTINGS_PATH,
  REFERRALS_PATH,
];

// EXTERNAL LINKS

export const LANDING_PAGE_PATH = "https://www.merge.dev";
export const GET_IN_TOUCH_PATH = `${LANDING_PAGE_PATH}/get-in-touch/`;
export const TOS_PATH = `${LANDING_PAGE_PATH}/terms/`;
export const CHANGELOG_PATH = `${LANDING_PAGE_PATH}/changelog`;
export const PRIVACY_POLICY_PATH = `${LANDING_PAGE_PATH}/privacy-policy/`;
export const SUBSCRIBER_AGREEMENT_PATH = `${LANDING_PAGE_PATH}/subscriber-agreement/`;
export const DOCS_BASE_PATH = `${LANDING_PAGE_PATH}/docs`;
export const DOCS_SDK_PATH = `${DOCS_BASE_PATH}/sdk`;
export const DOCS_METADATA_PATH = `${DOCS_BASE_PATH}/basics/integration-metadata`;
export const DOCS_AUTHENTICATION_PATH = `${DOCS_BASE_PATH}/basics/authentication`;
export const DOCS_WEBHOOKS_PATH = `${DOCS_BASE_PATH}/basics/webhooks`;
export const DOCS_WEBHOOKS_MERGE_TO_YOU_PATH = `${DOCS_BASE_PATH}/basics/webhooks/merge-to-you`;
export const DOCS_WEBHOOKS_THIRD_PARTIES_TO_MERGE_PATH = `${DOCS_BASE_PATH}/basics/webhooks/third-parties-to-merge`;
export const DOCS_SYNCING_DATA_PATH = `${DOCS_BASE_PATH}/basics/syncing-data`;
export const DOCS_WEBHOOK_EMITTERS_PATH = `${DOCS_WEBHOOKS_PATH}/merge-to-you`;
export const DOCS_WEBHOOK_RECEIVERS_PATH = `${DOCS_WEBHOOKS_PATH}/third-parties-to-merge`;
export const DOCS_WEBHOOKS_SECURITY_PATH = `${DOCS_WEBHOOKS_PATH}#security`;

export const DOCS_LINKING_FLOW_BASE_PATH_DO_NOT_USE_ON_ITS_OWN = `${DOCS_BASE_PATH}/linking-flow`;
export const DOCS_LINKING_FLOW_GET_STARTED_PATH = `${DOCS_LINKING_FLOW_BASE_PATH_DO_NOT_USE_ON_ITS_OWN}/get-started/`;
export const DOCS_LINKING_FLOW_SINGLE_INTEGRATION_PATH = `${DOCS_LINKING_FLOW_BASE_PATH_DO_NOT_USE_ON_ITS_OWN}/single-integration/`;
export const DOCS_MAGIC_LINK_PATH = `${DOCS_BASE_PATH}/guides/magic-link`;
export const DOCS_FIELD_MAPPINGS_PATH = `${DOCS_BASE_PATH}/supplemental-data/field-mappings/overview/`;
export const DOCS_FIELD_MAPPINGS_TARGET_FIELDS_PATH = `${DOCS_BASE_PATH}/supplemental-data/field-mappings/target-fields/`;

export const DOCS_CUSTOM_OBJECTS_PATH = `${DOCS_BASE_PATH}/guides/custom-objects/`;
export const DOCS_API_REF_CUSTOM_OBJECTS_PATH = `${DOCS_BASE_PATH}/crm/custom-objects/`;
export const DOCS_API_REF_CUSTOM_OBJECT_CLASSES_PATH = `${DOCS_BASE_PATH}/crm/custom-object-classes/`;
export const DOCS_API_REF_ASSOCIATIONS_PATH = `${DOCS_BASE_PATH}/crm/associations/`;
export const DOCS_API_REF_ASSOCIATION_TYPES_PATH = `${DOCS_BASE_PATH}/crm/association-types/`;

export const DOCS_REMOTE_DATA_PATH = `${DOCS_BASE_PATH}/supplemental-data/remote-data`;
export const DOCS_PASSTHROUGH_REQUESTS_PATH = `${DOCS_BASE_PATH}/supplemental-data/passthrough`;

export const DOCS_WRITES_MERGE_WRITES = `${DOCS_BASE_PATH}/guides/merge-writes/writes`;
export const DOCS_WRITES_RELATED_AND_NESTED_WRITES = `${DOCS_BASE_PATH}/guides/merge-writes/writes/related-and-nested`;
export const DOCS_WRITES_PROGRAMMATIC_WRITES = `${DOCS_BASE_PATH}/guides/merge-writes/programmatic`;
export const DOCS_WRITES_PROGRAMMATIC_NESTED_WRITES = `${DOCS_BASE_PATH}/guides/merge-writes/programmatic/nested`;
export const DOCS_WRITES_TEMPLATES_AND_CONDITIONAL_FIELDS = `${DOCS_BASE_PATH}/guides/merge-writes/programmatic/templates-conditional`;

export const INITIAL_AND_ANY_SYNC_GUIDE =
  "http://help.merge.dev/en/articles/8426389-webhook-migration-guide-coming-soon";
export const MERGE_ADMIN_PATH = "https://admin.merge.dev";
export const MERGE_HELP_CENTER_PATH = "https://help.merge.dev";
export const MERGE_HOW_YOUR_INVOICES_ARE_CALCULATED =
  "https://help.merge.dev/en/articles/5518444-how-are-my-invoices-calculated";
export const MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH =
  "https://help.merge.dev/en/articles/6641605-self-serve-with-merge-free-and-launch-plans-faq#h_098ba7572b";

export const MERGE_BILLING_SELF_SERVE_AFTER_APRIL =
  "https://help.merge.dev/en/articles/6641605-self-serve-with-merge-free-and-launch-plans-faq";

export const getDocsPathForCategory = (category: APICategory) =>
  `https://www.merge.dev/docs/${category}#introduction`;

export const getForceResyncDocsPathForCategory = (category: APICategory) =>
  `${DOCS_BASE_PATH}/${category}/force-resync/#sync_status_resync_create`;

export const getDocsPathForIntegration = (integrationSlug: string, category: APICategory) =>
  `${DOCS_BASE_PATH}/integrations/${category}/${integrationSlug}`;
//merge.dev/docs/linking-flow/get-started/

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

// HELPERS

export const appendQueryParamsHelper = (
  basePath: string,
  queryParams?: Partial<{ [key: string]: string }>,
) =>
  basePath +
  (queryParams
    ? "?" +
      Object.keys(queryParams)
        .map((key) => (queryParams[key] ? `${key}=${queryParams[key]}` : ""))
        .join("&")
    : "");

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useQuery = () => new URLSearchParams(useLocation().search);

export const navigateHelper = (history: History, path: string) => history.push(path);

// QUERY PARAM TYPES

type LogsTableQueryParams = Partial<{
  integration: string;
  end_user__organization_name: string;
  end_user__organization_id: string;
}>;

type IssuesTableQueryParams = Partial<{
  linked_account__integration__name: string;
  linked_account__end_user__organization_name: string;
}>;

type APITesterPageParams = Partial<{
  linked_account_id: string;
}>;

type LinkedAccountsTableQueryParams = Partial<{
  integration: string;
  complete_production_duplicates_of: string;
}>;

type SignUpQueryParams = Partial<{
  token: string;
  create_user_token: string;
  create_user_token_error: string;
  email: string;
}>;
// GETS

export const getLogsFilterString = (queryParams?: LogsTableQueryParams) => {
  const rawString = `{"end_user_ids/0":{"options":${serializeFiltersForURL([
    queryParams?.end_user__organization_id || "",
    queryParams?.end_user__organization_name || "",
  ])},"comparators":${serializeFiltersForURL([
    "CONTAINS",
    "CONTAINS",
  ])}},"integration_ids/1":{"options":${serializeFiltersForURL([
    queryParams?.integration || "",
    queryParams?.integration || "",
  ])},"comparators":${serializeFiltersForURL(["CONTAINS", "CONTAINS"])}}}`;
  return compressToEncodedURIComponent(rawString);
};

export const getLogsTablePath = (queryParams?: LogsTableQueryParams) =>
  appendQueryParamsHelper(API_REQUESTS_LOGS_PATH, { filters: getLogsFilterString(queryParams) });

export const getProductionLinkedAccountsTablePath = (
  queryParams?: LinkedAccountsTableQueryParams,
) => appendQueryParamsHelper(PROD_LINKED_ACCOUNTS_PATH, queryParams);

export const getTestLinkedAccountsTablePath = (queryParams?: LinkedAccountsTableQueryParams) =>
  appendQueryParamsHelper(TEST_LINKED_ACCOUNTS_PATH, queryParams);

export const getIndividualIssuePath = (issueID: string) => `${ISSUES_PATH}/${issueID}`;

export const getFilteredIssuesPath = (queryParams?: IssuesTableQueryParams) =>
  appendQueryParamsHelper(ISSUES_PATH, queryParams);

export const getEditIndividualCustomIntegrationPath = (customIntegrationID: string) =>
  `${CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH}/${customIntegrationID}/edit`;

export const getEditIndividualWebhookPath = (webhookID: string) =>
  `${CONFIGURATION_WEBHOOKS_EMITTERS_PATH}/${webhookID}/edit`;

export const getIssueLogEntrySidePanelPath = (issueID: string, logID: string) =>
  `${ISSUES_PATH}/${issueID}/log-entry/${logID}`;

export const getSignUpPath = (queryParams?: SignUpQueryParams) =>
  appendQueryParamsHelper(SIGNUP_PATH, queryParams);

export const getIntegrationConfigurationPathForIndividualCategory = (category: APICategory) =>
  `${CONFIGURATION_INTEGRATIONS_PATH}/${category.toLowerCase()}`;

export const commonModelsConfigurationPathForIndividualCategory = (category: APICategory) =>
  `${CONFIGURATION_COMMON_MODELS_PATH}/${category.toLowerCase()}`;

export const getCustomFieldConfigurationPathForIndividualCategory = (category: APICategory) =>
  `${CONFIGURATION_FIELD_MAPPINGS_PATH}/${category.toLowerCase()}`;

export const apiDocsPathForCategory = (category: APICategory) =>
  `${DOCS_BASE_PATH}/${category.toLowerCase()}`;

export const getLinkedAccountDetailPathForId = (id: string) =>
  `${LINKED_ACCOUNT_DETAIL_PATH}/${id}`;

export const getAPITesterPathForLinkedAccount = (queryParams?: APITesterPageParams) =>
  appendQueryParamsHelper(API_TESTER_PATH, queryParams);

export const getLinkedAccountDetailOverviewPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/overview`;

export const getLinkedAccountApiRequestLogsPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/logs/api-requests`;

export const getLinkedAccountWebhookLogsPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/logs/webhooks`;

export const getLinkedAccountDetailDataPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/data`;

export const getCreateCommonModelFieldMappingForID = (
  id: string,
  common_model: string,
  queryParams: {
    fieldMappingTargetID?: string;
    fieldMappingInstanceID?: string;
  },
) =>
  appendQueryParamsHelper(
    `${getLinkedAccountDetailPathForId(id)}/custom-mappings/create/${common_model}`,
    queryParams,
  );

export const getLinkedAccountDetailWebhooksPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/webhooks`;

export const getLinkedAccountDetailAPIRequestLogsSettingsPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}${API_REQUESTS_LOGS_PATH}`;

export const getLinkedAccountDetailWebhookLogsSettingsPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}${WEBHOOKS_LOGS_PATH}`;

export const getLinkedAccountDetailLogsSettingsPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}${LOGS_PATH}`;

export const getLinkedAccountDetailFieldMappingsPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/custom-mappings`;

export const getLinkedAccountIssuesPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/issues`;

export const getLinkedAccountDetailSettingsPathForId = (id: string) =>
  `${getLinkedAccountDetailPathForId(id)}/settings`;

export const getFieldMappingSettingsPathForId = (id: string) => {
  return `${CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH}/${id}/settings`;
};

export const getCreatedIntegrationWideFieldMapping = (
  fieldMappingTargetId: string,
  integrationId: string,
) => {
  return `${CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH}/${fieldMappingTargetId}/${integrationId}/create`;
};

export const getSelectiveSyncCreateOrEditConditionPathForId = (id: string, common_model: string) =>
  `${getLinkedAccountDetailPathForId(id)}/overview/selective-sync/${common_model}/edit`;

export const getIndividualCommonModelOverrideTargetPath = (id: string) =>
  `${CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH}/common-model-overrides/${id}/settings`;
// NAVIGATES

export const navigateToHomePage = (history: History) => navigateHelper(history, ROOT_PATH);

export const navigateToSignUpPage = (history: History, queryParams?: SignUpQueryParams) =>
  navigateHelper(history, getSignUpPath(queryParams));

export const navigateToLinkedAccountFieldMappings = (history: History, linkedAccountID: string) =>
  navigateHelper(history, getLinkedAccountDetailFieldMappingsPathForId(linkedAccountID));

export const navigateToFieldMappingSettings = (history: History, targetFieldID: any) =>
  navigateHelper(history, getFieldMappingSettingsPathForId(targetFieldID));

export const navigateToConfigurationFieldMappingTargets = (history: History) =>
  navigateHelper(history, CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH);

export const navigateToCreateCommonModelFieldMapping = (
  history: History,
  linkedAccountID: string,
  commonModel: string,
  existingTargetInformation: {
    fieldMappingTargetID?: string;
    fieldMappingInstanceID?: string;
  },
) =>
  navigateHelper(
    history,
    getCreateCommonModelFieldMappingForID(linkedAccountID, commonModel, existingTargetInformation),
  );

export const navigateToAddIntegrationFieldMapping = (
  history: History,
  fieldMappingTargetID: string,
  integrationID: string,
) => {
  const path = getCreatedIntegrationWideFieldMapping(fieldMappingTargetID, integrationID);
  navigateHelper(history, path);
};
export const navigateToWebhooksConfigurationPage = (history: History) =>
  navigateHelper(history, CONFIGURATION_WEBHOOKS_PATH);

export const navigateToCustomIntegrationsConfigurationPage = (history: History) =>
  navigateHelper(history, CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH);

export const navigateToAddWebhookPage = (history: History) =>
  navigateHelper(history, CONFIGURATION_WEBHOOKS_EMITTERS_ADD_PATH);

export const navigateToSyncSandboxData = (history: History) =>
  navigateHelper(history, ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH);

export const navigateToSendAPIRequest = (history: History) =>
  navigateHelper(history, ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH);

export const navigateToIntegrateSyncedData = (history: History) =>
  navigateHelper(history, ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH);

export const navigateToConfigureIntegrationsPage = (history: History, category: APICategory) =>
  navigateHelper(history, getIntegrationConfigurationPathForIndividualCategory(category));

export const navigateToAddLinkToApp = (history: History) =>
  navigateHelper(history, ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH);

export const navigateToOnboardingSyncingData = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_SYNCING_DATA_PATH);

export const navigateToOnboardingMergeWrites = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_MERGE_WRITES_PATH);

export const navigateToOnboardingWebhooks = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_WEBHOOKS_PATH);

export const navigateToOnboardingCustomFields = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH);

export const navigateToOnboardingMetadata = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_METADATA_PATH);

export const navigateToOnboardingSupplementalData = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH);

export const navigateToOnboardingSensitiveData = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_SENSITIVE_DATA_PATH);

export const navigateToOnboardingTroubleshooting = (history: History) =>
  navigateHelper(history, ONBOARDING_ADVANCED_TROUBLESHOOTING_PATH);

export const navigateToEditIndividualCustomIntegrationPage = (
  history: History,
  customIntegrationID: string,
) => navigateHelper(history, getEditIndividualCustomIntegrationPath(customIntegrationID));

export const navigateToIssuesPage = (history: History) => navigateHelper(history, ISSUES_PATH);

export const navigateToIndividualIssuePage = (
  history: History,
  issueID: string,
  prevPage?: string,
) => history.push(getIndividualIssuePath(issueID), { from: prevPage ? prevPage : null });

export const navigateToLogsTablePage = (history: History, queryParams?: LogsTableQueryParams) =>
  navigateHelper(history, getLogsTablePath(queryParams));

// Use redirect from LINKED_ACCOUNTS_PATH to PROD_LINKED_ACCOUNTS_PATH to clear query params
export const navigateToLinkedAccountsTablePage = (history: History) =>
  navigateHelper(history, LINKED_ACCOUNTS_PATH);

export const navigateToLinkedAccountLogsPage = (history: History, account: LinkedAccount) =>
  navigateHelper(history, getLinkedAccountApiRequestLogsPathForId(account.id));

export const navigateToLinkdAccountLogsPageById = (
  history: History,
  accountID: string,
  logID: string,
) => {
  const logPath = `${getLinkedAccountApiRequestLogsPathForId(accountID)}/${logID}`;
  navigateHelper(history, logPath);
};
export const navigateToProductionLinkedAccountsTablePage = (
  history: History,
  queryParams?: LinkedAccountsTableQueryParams,
) => navigateHelper(history, getProductionLinkedAccountsTablePath(queryParams));

export const navigateToTestLinkedAccountsTablePage = (
  history: History,
  queryParams?: LinkedAccountsTableQueryParams,
) => navigateHelper(history, getTestLinkedAccountsTablePath(queryParams));

export const navigateToLinkedAccountDetailPage = (history: History, account: LinkedAccount) =>
  navigateHelper(history, getLinkedAccountDetailOverviewPathForId(account.id));

export const navigateToEditIndividualWebhookPage = (history: History, webhookID: string) =>
  navigateHelper(history, getEditIndividualWebhookPath(webhookID));

export const navigateToIssueLogEntrySidePanel = (
  history: History,
  issueID: string,
  logID: string,
) => navigateHelper(history, getIssueLogEntrySidePanelPath(issueID, logID));

export const navigateToLoginPage = (history: History) => navigateHelper(history, LOGIN_PATH);

export const navigateToSelectiveSyncCreateOrEditConditionPage = (
  history: History,
  account: LinkedAccount,
  common_model: string,
) =>
  navigateHelper(history, getSelectiveSyncCreateOrEditConditionPathForId(account.id, common_model));

export const navigateToAPITesterWithLinkedAccount = (
  history: History,
  linkedAccount: LinkedAccount | null,
) => {
  navigateHelper(
    history,
    getAPITesterPathForLinkedAccount({
      linked_account_id: linkedAccount?.id ?? "",
    }),
  );
};

export const navigateToLinkedAccountDetailDataPage = (
  history: History,
  linkedAccountId: string,
) => {
  navigateHelper(history, getLinkedAccountDetailDataPathForId(linkedAccountId));
};

export const navigateToLogsPageById = (history: History, logId: string) => {
  navigateHelper(history, `${API_REQUESTS_LOGS_PATH}/${logId}`);
};

export const navigateToFailedLinkedAccountLogsPage = (
  history: History,
  linkedAccountId: string,
) => {
  const rawString = `{"response_codes/0": {"options": [["201",{"displayName": 201,"isEnabled": true}],["200",{"displayName": 200,"isEnabled": true}]],"comparators": [["NOT_EQUAL",{"displayName": "NOT_EQUAL","isEnabled": true
}]]}}`;

  const filteredUrl = appendQueryParamsHelper(
    getLinkedAccountDetailAPIRequestLogsSettingsPathForId(linkedAccountId),
    { filters: compressToEncodedURIComponent(rawString) },
  );

  navigateHelper(history, filteredUrl);
};

export const navigateToLinkedAccountDetailWebhooksPathForId = (
  history: History,
  linkedAccountId: string,
) => {
  navigateHelper(history, getLinkedAccountDetailWebhooksPathForId(linkedAccountId));
};

export const navigateToIndividualCommonModelOverrideTargetPage = (
  history: History,
  commonModelOverrideTargetID: string,
) => {
  navigateHelper(
    history,
    `${CONFIGURATION_FIELD_MAPPINGS_PATH}/common-model-overrides/${commonModelOverrideTargetID}`,
  );
};
