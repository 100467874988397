import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../../../services";

import MergeModal from "../../../shared-components/MergeModal";
import SpinnerButton from "../../../shared-components/SpinnerButton";
import { showErrorToast } from "../../../shared-components/Toasts";
import { postPayInvoice } from "../BillingAPIClient";
import { MergeInvoice, MergeChargeStatus, PaymentMethod, isCreditCard } from "../BillingModels";
import { formatCentsNearestCent } from "../BillingUtils";

const PaymentPrompt = styled.p`
  margin: 0 0 24px 0;
`;

const PrimaryButton = styled(SpinnerButton)`
  && {
    font-weight: 600;
  }
`;

const SecondaryButton = styled(SpinnerButton)`
  && {
    background: none;
    border: none;
    color: #12263f;
    font-weight: normal;
  }
`;

type Props = {
  onHide: () => void;
  invoice?: MergeInvoice;
  defaultPaymentMethod?: PaymentMethod;
};
const PayInvoiceModal = ({ onHide, invoice, defaultPaymentMethod }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  if (!invoice || !defaultPaymentMethod) {
    return null;
  }
  const onPostPayInvoiceError = () => {
    setIsLoading(false);
    showErrorToast(
      "There was an error processing your payment method. Please try again later or contact support.",
    );
  };
  const onPostPayInvoiceSuccess = (data: MergeInvoice) => {
    if (data.status !== MergeChargeStatus.CHARGE_STATUS_SUCCESS) {
      // If the resulting MergeCharge does not have status SUCCESS, treat it as an error.
      return onPostPayInvoiceError();
    }
    setIsLoading(false);
    onHide();
  };
  const submitPayment = () => {
    setIsLoading(true);
    postPayInvoice({
      invoiceId: invoice.id,
      onSuccess: onPostPayInvoiceSuccess,
      onError: onPostPayInvoiceError,
    });
  };
  return (
    <MergeModal title="Pay Invoice" show={invoice !== undefined} onHide={onHide} size="sm">
      <PaymentPrompt>
        Do you want to pay your invoice amount of{" "}
        <strong>{formatCentsNearestCent(invoice.total_charge_amount)}</strong> with your default{" "}
        <strong>
          {isCreditCard(defaultPaymentMethod)
            ? capitalizeFirstLetter(defaultPaymentMethod.brand)
            : "Account"}
        </strong>{" "}
        ending in <strong>{defaultPaymentMethod.last4}</strong>?
      </PaymentPrompt>
      <PrimaryButton
        className="btn-primary btn-block"
        text={`Pay ${formatCentsNearestCent(invoice.total_charge_amount)}`}
        isLoading={isLoading}
        onClick={() => submitPayment()}
      />
      <SecondaryButton
        className="btn-block"
        text="Cancel"
        isLoading={false}
        onClick={() => onHide()}
      />
    </MergeModal>
  );
};
export default PayInvoiceModal;
