import React from "react";
import { Col, Row } from "react-bootstrap";
import { Wrench, Globe } from "lucide-react";
import {
  MERGE_HOW_YOUR_INVOICES_ARE_CALCULATED,
  PROD_LINKED_ACCOUNTS_PATH,
  TEST_LINKED_ACCOUNTS_PATH,
} from "../../../router/RouterUtils";
import LinkedAccountsHeaderBar from "./LinkedAccountsHeaderBar";

type Props = { children: JSX.Element | JSX.Element[] };

function LinkedAccountsPageWrapper(props: Props) {
  return (
    <Row className="justify-content-center">
      <Col className="col-12 col-lg-10 col-xl-8">
        <Row>
          <Col>
            <LinkedAccountsHeaderBar
              title="Linked Accounts"
              subtitle={<>Integrations between your users&rsquo; accounts and third-party apps.</>}
              subtabs={[
                {
                  label: "Production Linked Accounts",
                  destination: PROD_LINKED_ACCOUNTS_PATH,
                  icon: <Globe size={16} className="deprecated-mr-3" />,
                },
                {
                  label: "Test Linked Accounts",
                  destination: TEST_LINKED_ACCOUNTS_PATH,
                  icon: <Wrench size={16} className="deprecated-mr-3" />,
                },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default LinkedAccountsPageWrapper;
