import React from "react";
import { APICategory } from "../../../models/Entities";
import { Row, Col } from "react-bootstrap";
import HeaderBar from "../../portal/HeaderBar";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
  lucideIconForAPICategory,
} from "../../../models/Helpers";
import {
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH,
  getIntegrationConfigurationPathForIndividualCategory,
} from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../portal/SubLeftNavigationContainer";

type ConfigurationIntegrationsPageWrapperProps = {
  children: JSX.Element | JSX.Element[];
  category?: APICategory;
  opacity?: string;
};

function ConfigurationIntegrationsPageWrapper({
  children,
  category,
  opacity = "1",
}: ConfigurationIntegrationsPageWrapperProps) {
  return (
    <Row className="justify-content-center">
      <Col className="col-12 col-lg-10 col-xl-8">
        <HeaderBar
          title="Integrations"
          subtitle={
            <div>
              <p className="mb-1">Manage the integrations you make available to your users.</p>

              <p className="mb-0">
                See which fields are supported for each integration and compare coverage across
                platforms in our{" "}
                <a
                  href={`https://docs.merge.dev/integrations/${
                    category ?? `hris`
                  }/supported-fields`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Docs
                </a>
                .
              </p>
            </div>
          }
        />
        <div style={{ opacity }}>
          <SubLeftNavigationContainer
            subtabs={[
              ...INTEGRATION_CATEGORY_LIST.map((category: APICategory) => ({
                label: displayNameForAPICategory(category),
                destination: getIntegrationConfigurationPathForIndividualCategory(category),
                icon: lucideIconForAPICategory(category, "md", "deprecated-mr-3 deprecated-mb-1"),
              })),
              {
                label: "Custom Integrations",
                destination: CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH,
                icon: <span className="fe fe-file-plus deprecated-mb-1" />,
              },
            ]}
          >
            {children}
          </SubLeftNavigationContainer>
        </div>
      </Col>
    </Row>
  );
}

export default ConfigurationIntegrationsPageWrapper;
