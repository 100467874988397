import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  useAccordionToggle,
} from "react-bootstrap";
import styled from "styled-components";
import { FormErrorData, Result } from "../../../api-client/APIClient";
import { palette } from "../../../styles/theme";
import { ProductRestrictions } from "../../settings/billing/BillingModels";
import { showErrorToast } from "../../shared-components/Toasts";
import CenteredVerticalLineCol from "./CenteredVerticalLineCol";
import {
  getRelatedFieldFromField,
  getRelatedModelFromField,
  ModelWithSortedFields,
  SortedFieldsMap,
  SortedModelsMap,
} from "./CommonModelUtils";
import FieldToggle, {
  Disableability,
  Enableability,
  RemoteDataToggle,
  StyledMultiSwitch,
} from "./FieldToggle";
import AnimatedChevron from "./AnimatedChevron";
import SubobjectFieldsGroup from "./SubobjectFieldsGroup";
import {
  createUpdatedIsDescriptionShowingMap,
  IsDescriptionShowingMap,
  Schemas,
} from "./CommonModelToggleTypes";
import Markdown from "markdown-to-jsx";
import { EnabledAction } from "../../../models/CommonModel";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import { Lock } from "lucide-react";

interface CommonModelToggleProps {
  /**
   * We call this to update the Common Model or Common Model field enabled status on the backend to a new value
   *
   * @param modelName The name of the model
   * @param fieldName The field of the model, or `null` if the model itself is being modified
   * @param isEnabled Whether the model or field should be enabled or not
   */
  updateEnabledStatus: (
    modelName: string,
    fieldName: string | null,
    enabledActions: Array<EnabledAction>,
  ) => Promise<Result<void>>;
  models: SortedModelsMap;
  schemas: Schemas;
  /**
   * The model to show here
   */
  modelName: string;
  /**
   * Product restrictions for the org
   */
  productRestrictions?: ProductRestrictions;
  /**
   * The state maintained for showing the Upsell Modal
   */
  showUpsellModal?: boolean;
  setShowUpsellModal?: (isOpen: boolean) => void;
}

// Has a background on hover
const HoverableRow = styled(Row).attrs({ className: "flex-nowrap ml-0 mr-0" })``;

const MaybeCenteredVerticalLineCol = styled(CenteredVerticalLineCol)<{ $showLine: boolean }>`
  ${({ $showLine }) => ($showLine ? "background-size: 1px 50%" : "background-size: 1px 0%")};

  background-position: center 44px;
`;

const CardBody = styled(Card.Body)`
  padding: 0px;
`;

const IconContainer = styled.div.attrs({
  className: "avatar-sm avatar-title rounded-circle",
})`
  margin: 24px 25px;
  color: ${palette.black};
`;

const Title = styled(DeprecatedH4)<{ $hasDescription: boolean }>`
  font-size: 20px;
  line-height: 28px;
  color: ${palette.black};
  ${({ $hasDescription }) =>
    $hasDescription && `border-bottom: 0.5px dashed ${palette.gray}; cursor: help;`}
`;

const OverlayText = styled(Markdown)`
  font-size: 12px;
  line-height: 16px;

  code {
    color: ${palette.white};
    padding: unset;
    border-radius: unset;
    background: unset;
    border: unset;
  }
`;

const TooltipWithoutMaxWidth = styled(Tooltip)`
  & {
    .tooltip-inner {
      max-width: 100%;
    }
  }
`;

const Fields = styled.div`
  background-color: #fcfdff;
`;

const RelationLink = styled.a<{ $shrinkTopMargin: boolean | undefined }>`
  transition: margin-top 0.3s;
  ${({ $shrinkTopMargin }) => $shrinkTopMargin && "margin-top: -6px;"}
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 24px;
`;

const AlphabeticalOrderRow = styled(Row).attrs({ className: "font-semibold ml-0 mr-0" })`
  padding: 9px 24px 20px;
  color: ${palette.placeholder};
  font-size: 12px;
`;

interface FieldStatusRowProps {
  isExpanded: boolean;
  fields: SortedFieldsMap;
}

const FieldStatusStyledRow = styled(Row).attrs({
  className: "font-semibold deprecated-pl-3 deprecated-pr-3",
})`
  font-size: 12px;
  line-height: 18px;
`;

interface ToggleProps {
  setIsExpanded: (expanded: boolean) => void;
  children: JSX.Element;
  eventKey: string;
}

const ToggleButton = styled.div`
  padding: 8px 12px;
  border-top: 1px solid ${palette.border};

  cursor: pointer;
  background-color: #f3f5f8;
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${palette.border};
  }
  &:active {
    background-color: ${palette.empty};
  }
`;

function ToggleRow({ setIsExpanded, children, eventKey }: ToggleProps) {
  const currentEventKey = useContext(AccordionContext);
  const onClick = useAccordionToggle(eventKey, () => setIsExpanded(currentEventKey !== eventKey));

  return <ToggleButton onClick={onClick}>{children}</ToggleButton>;
}
const FieldDescriptionCol = styled(Col).attrs({ className: "col-auto" })``;

const EnabledFieldDescriptionCol = styled(FieldDescriptionCol)`
  color: ${palette.black};
`;

const DisabledFieldDescriptionCol = styled(FieldDescriptionCol)`
  color: ${palette.placeholder};
`;

const CollapseFieldsCol = styled(Col).attrs({
  className: "d-flex justify-content-end deprecated-mr-2",
})`
  color: ${palette.graphite};
`;

const PaddedChevron = styled(AnimatedChevron)`
  margin-left: 3px;
`;

const ALL_MODEL_OPTIONS = {
  disabled: {
    text: (
      <>
        <Lock size={12} className="mr-1 mb-0.5" /> Disabled
      </>
    ),
    id: [].join(","),
    enabledActions: [],
    selectedColor: palette.empty,
    backgroundColor: palette.white,
    borderColor: palette.border,
  },
  read: {
    text: "Read",
    id: [EnabledAction.READ].join(","),
    enabledActions: [EnabledAction.READ],
    selectedColor: palette.black,
    backgroundColor: palette.white,
    borderColor: palette.border,
  },
  readwrite: {
    text: "Read+Write",
    id: [EnabledAction.READ, EnabledAction.WRITE].join(","),
    enabledActions: [EnabledAction.READ, EnabledAction.WRITE],
    selectedColor: palette.black,
    backgroundColor: palette.white,
    borderColor: palette.border,
  },
  write: {
    text: "Write",
    id: [EnabledAction.WRITE].join(","),
    enabledActions: [EnabledAction.WRITE],
    selectedColor: palette.black,
    backgroundColor: palette.white,
    borderColor: palette.border,
  },
};

const FieldStatusRow = ({ isExpanded, fields }: FieldStatusRowProps) => {
  let enabledCount = 0,
    disabledCount = 0;
  Object.values(fields).forEach((field) => {
    if (field.is_enabled) {
      enabledCount += 1;
    } else {
      disabledCount += 1;
    }
  });
  const pluralizeField = (count: number) => count.toString() + (count === 1 ? " field" : " fields");
  return (
    <FieldStatusStyledRow>
      <EnabledFieldDescriptionCol>
        {pluralizeField(enabledCount)} enabled
      </EnabledFieldDescriptionCol>
      <DisabledFieldDescriptionCol>
        {pluralizeField(disabledCount)} disabled
      </DisabledFieldDescriptionCol>
      <CollapseFieldsCol>
        {isExpanded ? "Collapse fields" : "Configure fields"}
        <PaddedChevron $isUp={isExpanded} />
      </CollapseFieldsCol>
    </FieldStatusStyledRow>
  );
};

const getModelOptions = (commonModel: ModelWithSortedFields) => {
  const options = [];
  options.push(ALL_MODEL_OPTIONS["disabled"]);
  if (commonModel.model_capabilities.includes(EnabledAction.READ)) {
    options.push(ALL_MODEL_OPTIONS["read"]);
  }
  if (commonModel.model_capabilities.includes(EnabledAction.WRITE)) {
    options.push(ALL_MODEL_OPTIONS["readwrite"]);
    options.push(ALL_MODEL_OPTIONS["write"]);
  }
  return options;
};

/**
 * Creates a card for toggling a Common Model under the configuration pages
 */
const CommonModelToggle = ({
  updateEnabledStatus,
  modelName,
  models,
  schemas,
  productRestrictions,
  showUpsellModal,
  setShowUpsellModal,
}: CommonModelToggleProps) => {
  const [_, setIsSaving] = useState(false);
  const [isDescriptionShowingMap, setIsDescriptionShowingMap] = useState<IsDescriptionShowingMap>(
    {},
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const commonModel = models[modelName];
  const canToggleFields = !!productRestrictions?.are_toggles_enabled;

  useEffect(
    () => setIsDescriptionShowingMap({}),
    [!isExpanded || !commonModel.enabled_model_actions.length],
  );

  useEffect(() => {
    const hash = window.location.hash;

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  // Calls our API with a new value for the Common Model
  const changeState = async (
    modelName: string,
    fieldName: string | null,
    enabledActions: Array<EnabledAction>,
  ) => {
    setIsSaving(true);
    const result = await updateEnabledStatus(modelName, fieldName, enabledActions);
    if (result.status === "error") {
      let isGenericError = !result.error;
      if (result.error) {
        if (result.error.status === 403) {
          showErrorToast(
            commonModel.is_permanently_disabled
              ? "Common model is permanently disabled and cannot be re-enabled."
              : "Toggles are only available for customers on our Grow and Expand plans.",
          );
        } else {
          const data: FormErrorData = await result.error.json();
          if (data.non_field_errors) {
            showErrorToast(data.non_field_errors[0]);
          } else {
            isGenericError = true;
          }
        }
      }
      if (isGenericError) {
        showErrorToast("Something went wrong, please check your connection and try again.");
      }
    }
    setIsSaving(false);
  };
  const schema = schemas[modelName];
  const remoteDataField = commonModel.fields["remote_data"];

  const title = (
    <Title className="mb-0" $hasDescription={!!schema?.description}>
      {commonModel.name}
    </Title>
  );

  const isCommonModelEnabled = commonModel.enabled_model_actions.length > 0;

  return (
    <Card className="overflow-hidden" id={commonModel.name}>
      <CardBody>
        <Accordion activeKey={isCommonModelEnabled ? "0" : undefined}>
          <HoverableRow>
            <MaybeCenteredVerticalLineCol
              className="col-auto pl-0 pr-0"
              $showLine={isCommonModelEnabled && isExpanded}
            >
              <IconContainer>
                <i className="fe fe-list" />
              </IconContainer>
            </MaybeCenteredVerticalLineCol>
            <Col className="my-auto deprecated-pr-5">
              <Row>
                <Col className="d-flex pl-0 my-auto">
                  {schema?.description ? (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 100, hide: 0 }}
                      overlay={
                        <TooltipWithoutMaxWidth>
                          <OverlayText className="font-semibold deprecated-my-1 deprecated-mx-1">
                            {schema?.description}
                          </OverlayText>
                        </TooltipWithoutMaxWidth>
                      }
                    >
                      <div>{title}</div>
                    </OverlayTrigger>
                  ) : (
                    title
                  )}
                </Col>
                <Col className="d-flex col-auto pr-0 my-auto flex-grow-1 justify-content-end">
                  <Form>
                    <StyledMultiSwitch
                      options={getModelOptions(commonModel).map((option) => {
                        // option.id == "" is intended to isolate the Disabled button in the switch
                        if (canToggleFields && option.id == "") {
                          option.text = "Disabled";
                        }
                        return option;
                      })}
                      selectedID={commonModel.enabled_model_actions.sort().join(",")}
                      onSelectOption={(option) => {
                        if (!canToggleFields && option.id == "") {
                          if (setShowUpsellModal) {
                            setShowUpsellModal(true);
                          }
                          return;
                        }
                        changeState(
                          modelName,
                          null,
                          option?.enabledActions as Array<EnabledAction>,
                        );
                      }}
                    />
                  </Form>
                </Col>
              </Row>
            </Col>
          </HoverableRow>
          <Accordion.Collapse eventKey="0">
            <Accordion>
              <Accordion.Collapse eventKey="0">
                <Fields>
                  {Object.values(commonModel.fields)
                    .filter((field) => field.field_name !== "remote_data")
                    .map((field, index, arr) => {
                      const relatedModelName = getRelatedModelFromField(field);
                      const isNonSelfRelated =
                        relatedModelName && relatedModelName !== commonModel.name;
                      const relatedObject = !relatedModelName
                        ? undefined
                        : models[relatedModelName];
                      const relatedSubobject =
                        relatedObject && !relatedObject.has_endpoints && relatedObject;
                      const isRelatedToEnabledObject = !!(
                        relatedObject && relatedObject.enabled_model_actions.length
                      );
                      const disableability =
                        isNonSelfRelated && !relatedSubobject && !isRelatedToEnabledObject
                          ? Disableability.NOT_DISABLEABLE
                          : canToggleFields
                          ? Disableability.DISABLEABLE
                          : Disableability.DISABLEABLE_WITH_UPGRADE;
                      const relatedFieldName = getRelatedFieldFromField(field);
                      const parameter = schema?.parameters[field.field_name];
                      return (
                        <FieldToggle
                          key={field.field_name}
                          isEnabled={field.is_enabled}
                          displayName={field.field_name}
                          type={parameter?.type}
                          description={
                            relatedSubobject
                              ? schemas[relatedObject.name]?.description
                              : parameter?.description
                          }
                          disableability={disableability}
                          showBorderAboveIcon={index === 0}
                          showBorderAboveContent
                          verticalLineConfig={index === arr.length - 1 ? "top-half" : "all"}
                          showDescription={
                            isDescriptionShowingMap[commonModel.name]?.[field.field_name]
                          }
                          setShowDescription={(showDescription) => {
                            setIsDescriptionShowingMap(
                              createUpdatedIsDescriptionShowingMap(
                                isDescriptionShowingMap,
                                commonModel.name,
                                field.field_name,
                                showDescription,
                              ),
                            );
                          }}
                          changeState={(enabledActions) =>
                            changeState(
                              relatedSubobject ? relatedSubobject.name : commonModel.name,
                              relatedSubobject ? null : field.field_name,
                              relatedSubobject && enabledActions.length
                                ? relatedSubobject.model_capabilities
                                : enabledActions,
                            )
                          }
                        >
                          {isNonSelfRelated &&
                            (relatedSubobject ? (
                              <SubobjectFieldsGroup
                                model={relatedSubobject}
                                schema={schemas[relatedSubobject.name]}
                                canToggleFields={canToggleFields}
                                changeState={changeState}
                                isDescriptionShowingForField={
                                  isDescriptionShowingMap[relatedSubobject.name]
                                }
                                setIsDescriptionShowingForField={(
                                  fieldName,
                                  isDescriptionShowing,
                                ) =>
                                  setIsDescriptionShowingMap(
                                    createUpdatedIsDescriptionShowingMap(
                                      isDescriptionShowingMap,
                                      relatedSubobject.name,
                                      fieldName,
                                      isDescriptionShowing,
                                    ),
                                  )
                                }
                              />
                            ) : (
                              (!isRelatedToEnabledObject || relatedFieldName) && (
                                <RelationLink
                                  href={"#" + relatedModelName}
                                  $shrinkTopMargin={
                                    !isDescriptionShowingMap[commonModel.name]?.[field.field_name]
                                  }
                                >
                                  {relatedObject?.enabled_model_actions.length ? (
                                    <>
                                      Toggling this field also toggles the{" "}
                                      <code>{relatedFieldName}</code> field on the{" "}
                                      <code>{relatedModelName}</code> model
                                    </>
                                  ) : (
                                    <>
                                      Enable the <code>{relatedModelName}</code> model to enable
                                      toggling this field
                                    </>
                                  )}
                                </RelationLink>
                              )
                            ))}
                        </FieldToggle>
                      );
                    })}
                  {remoteDataField && (
                    <RemoteDataToggle
                      key={remoteDataField.field_name}
                      displayName={remoteDataField.field_name}
                      isEnabled={remoteDataField.is_enabled}
                      type={schema?.parameters["remote_data"]?.type}
                      enableability={
                        productRestrictions?.are_toggles_enabled ||
                        productRestrictions?.are_remote_data_toggles_enabled
                          ? Enableability.ENABLEABLE
                          : Enableability.ENABLEABLE_WITH_UPGRADE
                      }
                      verticalLineConfig="none"
                      changeState={(enabledActions) =>
                        changeState(commonModel.name, remoteDataField.field_name, enabledActions)
                      }
                      className="deprecated-my-2 deprecated-mx-3"
                    />
                  )}
                  <AlphabeticalOrderRow>Fields in alphabetical order</AlphabeticalOrderRow>
                </Fields>
              </Accordion.Collapse>
              <ToggleRow setIsExpanded={setIsExpanded} eventKey="0">
                <FieldStatusRow isExpanded={isExpanded} fields={commonModel.fields} />
              </ToggleRow>
            </Accordion>
          </Accordion.Collapse>
        </Accordion>
      </CardBody>
    </Card>
  );
};

export default CommonModelToggle;
