import React, { useState } from "react";
import { Button } from "react-bootstrap";
import MergeModal from "../../shared-components/MergeModal";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { fetchCurrentUser, toggleRedactUnmappedData } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";

type Props = {
  isRedactionCurrentlyEnabledForOrg: boolean;
  organizationId: string;
  isModalOpen: boolean;
  onHide: () => void;
  onConfirmResponse: () => void;
};

const RedactUnmappedDataModal = ({
  isRedactionCurrentlyEnabledForOrg,
  organizationId,
  isModalOpen,
  onHide,
  onConfirmResponse,
}: Props) => {
  const { setUser } = useAppContext();
  const [isToggling, setIsToggling] = useState(false);

  const _onConfirmResponse = () => {
    // Once the setting is toggled, we need to refetch the user to get the updated redaction status.
    // Else, the data in the AppContext's user will go stale until the user is refetched (e.g. via a page refresh).
    fetchCurrentUser(setUser);

    setIsToggling(false);
    onConfirmResponse();
  };

  const _onConfirmError = () => {
    setIsToggling(false);
    onConfirmResponse();
  };

  return (
    <MergeModal
      centered
      show={isModalOpen}
      bodyClassName="modal-narrow overflow-hidden"
      dialogClassName="modal-narrow"
      onHide={onHide}
      title={
        isRedactionCurrentlyEnabledForOrg
          ? "Disable redacting unmapped data"
          : "Enable redacting unmapped data"
      }
    >
      <span className="text-center">
        {"This will apply to all existing and new Linked Accounts. Do you wish to continue?"}
        <div className={`w-100 text-center mt-6`}>
          <SpinnerButton
            isLoading={isToggling}
            className="modal-button btn-dark w-100"
            text={"Confirm"}
            type="submit"
            onClick={() => {
              setIsToggling(true);
              toggleRedactUnmappedData(
                !isRedactionCurrentlyEnabledForOrg,
                organizationId,
                undefined,
                _onConfirmResponse,
                _onConfirmError,
              );
            }}
          />
        </div>
        <div className="w-100 text-center">
          <Button variant="link" className="mt-2 border-0 text-black medium" onClick={onHide}>
            Cancel
          </Button>
        </div>
      </span>
    </MergeModal>
  );
};

export default RedactUnmappedDataModal;
