import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useForm } from "react-hook-form";
import { fetchWithAuth, FormErrorData } from "../../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../../shared-components/Toasts";
import Dropzone from "../../../shared-components/Dropzone";
import SpinnerButton from "../../../shared-components/SpinnerButton";
import { Link } from "react-router-dom";
import { APICategory } from "../../../../models/Entities";
import { Card, Col, Row, Form, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { CustomIntegration, SetupStep } from "../../../../models/Entities";
import Spinner from "../../../shared-components/Spinner";
import {
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH,
  navigateToCustomIntegrationsConfigurationPage,
} from "../../../../router/RouterUtils";
import INTEGRATION_CATEGORY_LIST, { displayNameForAPICategory } from "../../../../models/Helpers";
import { SmallTextMutedParagraph } from "../../../shared-components/MergeText";
import DeprecatedH2 from "../../../../deprecated/DeprecatedH2";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";

type RouteParams = {
  customIntegrationID: string;
};

function ConfigurationEditCustomIntegrations() {
  const history = useHistory();
  const { customIntegrationID } = useParams<RouteParams>();
  const { register, handleSubmit, errors } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [customIntegration, setCustomIntegration] = useState<null | CustomIntegration>();
  const [customIntegrationSetupSteps, setCustomIntegrationSetupSteps] = useState<SetupStep[]>([]);
  const [customIntegrationCompanyLogo, setCustomIntegrationCompanyLogo] = useState<null | Blob>();
  const [customIntegrationCompanySquareLogo, setCustomIntegrationCompanySquareLogo] =
    useState<null | Blob>();
  const [customIntegrationIsActive, setCustomIntegrationIsActive] = useState<Boolean>(false);

  useEffect(() => {
    if (customIntegrationID) {
      fetchWithAuth({
        path: `/integrations/custom/${customIntegrationID}`,
        method: "GET",
        onResponse: (data) => {
          setCustomIntegration(data);
          setCustomIntegrationSetupSteps(data.steps);
          setCustomIntegrationIsActive(data.active);
        },
      });
    } else {
      addEmptySetupStep(0);
    }
  }, []);

  function addEmptySetupStep(index: number) {
    const newSetupStep: SetupStep = {
      id: String(index),
      step_number: index,
      title: "",
      description: "",
    };

    const cloneCustomIntegrationSetupSteps = [...customIntegrationSetupSteps];
    cloneCustomIntegrationSetupSteps.push(newSetupStep);
    setCustomIntegrationSetupSteps(cloneCustomIntegrationSetupSteps);
  }

  function deleteSetupStep(index: number) {
    if (customIntegrationSetupSteps.length == 1) {
      showErrorToast("Each custom integration must have at least one step.");
      return;
    }
    const cloneCustomIntegrationSetupSteps = [...customIntegrationSetupSteps];
    cloneCustomIntegrationSetupSteps.splice(index, 1);
    cloneCustomIntegrationSetupSteps.forEach(function (setupStep, index) {
      setupStep.step_number = index;
    });
    setCustomIntegrationSetupSteps(cloneCustomIntegrationSetupSteps);
  }

  const onSubmit = (data: { name: string; active: boolean; category: string }) => {
    setIsLoading(true);
    const formData = {
      name: data.name,
      image: customIntegrationCompanyLogo,
      square_image: customIntegrationCompanySquareLogo,
      active: data.active,
      category: data.category,
      steps: JSON.stringify(customIntegrationSetupSteps),
    };
    if (customIntegrationID) {
      fetchWithAuth({
        path: `/integrations/custom/${customIntegrationID}`,
        method: "PATCH",
        body: formData,
        onResponse: (data: any) => {
          showSuccessToast(`Successfully edited ${data.name} integration!`);
          setIsLoading(false);
          navigateToCustomIntegrationsConfigurationPage(history);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  showErrorToast(data[field_name][0]);
                }
                if (field_name === "steps") {
                  showErrorToast("Please add a title for every step.");
                }
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    } else {
      fetchWithAuth({
        path: "/integrations/custom",
        method: "POST",
        body: formData,
        onResponse: () => {
          showSuccessToast(`Successfully added ${data.name} integration!`);
          setIsLoading(false);
          navigateToCustomIntegrationsConfigurationPage(history);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  showErrorToast(data[field_name][0]);
                }
                if (field_name === "steps") {
                  showErrorToast("Please add a title for every step.");
                }
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    }
  };

  function handleLogoUpload(files: Array<any>) {
    const logoFile = files[0];
    const blob = new Blob([logoFile], { type: logoFile.type });
    setCustomIntegrationCompanyLogo(blob);
  }

  function handleSquareLogoUpload(files: Array<any>) {
    const logoFile = files[0];
    const blob = new Blob([logoFile], { type: logoFile.type });
    setCustomIntegrationCompanySquareLogo(blob);
  }

  return (
    <>
      <Row>
        <Col>
          <DeprecatedH2 className="deprecated-mb-4">{`${
            customIntegrationID ? "Edit" : "Add"
          } Custom Integration`}</DeprecatedH2>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="name">
              <Form.Label>Integration company name*</Form.Label>
              <Form.Control
                as="input"
                name="name"
                type="text"
                ref={register({ required: true, minLength: 1 })}
                className={cx({
                  "is-invalid": errors.name,
                })}
                defaultValue={customIntegration ? customIntegration.name : ""}
              />

              <Form.Control.Feedback type="invalid">
                Please enter a valid company name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="switch"
                id="active"
                name="active"
                onChange={() => setCustomIntegrationIsActive(!customIntegrationIsActive)}
                ref={register()}
                checked={customIntegrationIsActive ? true : false}
                label="Is this custom integration active?*"
              />
            </Form.Group>
            <Form.Group controlId="stepTitle">
              <Form.Label>What category is this custom integration in?*</Form.Label>
              <Form.Control
                as="select"
                name="category"
                className="custom-select"
                ref={register({ required: true })}
                defaultValue={customIntegration ? customIntegration.category : ""}
                custom
              >
                {INTEGRATION_CATEGORY_LIST.map((category: APICategory) => (
                  <option value={category}>{displayNameForAPICategory(category)}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="logo">
              <Form.Label>Integration company logo*</Form.Label>
              <Form.Text className="text-gray-50">
                We recommend uploading a logo with a transparent background to keep the design
                consistent.
              </Form.Text>
              <Dropzone upload={handleLogoUpload} />
            </Form.Group>
            <Form.Group controlId="squareLogo">
              <Form.Label>Integrations company square logo*</Form.Label>
              <Form.Text className="text-gray-50">
                We recommend uploading a square logo to keep the design consistent.
              </Form.Text>
              <Dropzone upload={handleSquareLogoUpload} />
            </Form.Group>
            <hr className="deprecated-my-5" />
            <DeprecatedH2 className="deprecated-mb-4">{`${
              customIntegrationID ? "Edit" : "Add"
            } Instructions`}</DeprecatedH2>
            <Row>
              <Col>
                {customIntegrationSetupSteps.length > 0 ? (
                  <>
                    {customIntegrationSetupSteps?.map((setupStep, index) => (
                      <ConfigurationCustomIntegrationSetupStep
                        key={setupStep.id}
                        setupStep={setupStep}
                        index={index}
                        onDelete={() => deleteSetupStep(index)}
                      />
                    ))}

                    <Button
                      variant="light"
                      className="btn-block btn-light"
                      onClick={() =>
                        addEmptySetupStep(
                          customIntegrationSetupSteps ? customIntegrationSetupSteps.length : 0,
                        )
                      }
                    >
                      Add step
                    </Button>
                  </>
                ) : (
                  <Spinner />
                )}
              </Col>
            </Row>
            <hr className="deprecated-my-5" />
            <Row>
              <Col>
                <SpinnerButton
                  text="Save changes"
                  isLoading={isLoading}
                  className="btn-block btn-primary"
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row className="text-center deprecated-mt-3 deprecated-mb-4">
        <Col>
          <Link to={CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH} className="text-gray-50">
            Cancel changes
          </Link>
        </Col>
      </Row>
    </>
  );
}

type ConfigurationCustomIntegrationSetupStepProps = {
  setupStep: SetupStep;
  index: number;
  onDelete: () => void;
};

export function ConfigurationCustomIntegrationSetupStep(
  props: ConfigurationCustomIntegrationSetupStepProps,
) {
  const { register, errors } = useForm();

  return (
    <Card className="card-inactive">
      <Card.Body>
        <Row className="deprecated-mb-3">
          <Col>
            <DeprecatedH3>Step {props.index + 1}</DeprecatedH3>
          </Col>
          <Col className="col-auto">
            <button type="button" className="close deprecated-pb-4" onClick={props.onDelete}>
              <span aria-hidden="true">&times;</span>
            </button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="stepTitle" className="">
              <Form.Label>Step title*</Form.Label>
              <Form.Control
                as="input"
                name="stepTitle"
                ref={register({ required: true, minLength: 1 })}
                className={cx({
                  "is-invalid": errors.stepTitle,
                })}
                defaultValue={props.setupStep.title}
                onChange={(e) => (props.setupStep.title = e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a title for every step.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="stepDescription" className="deprecated-mb-5">
              <Form.Label>Step description</Form.Label>
              <SmallTextMutedParagraph className="mb-0">
                You can add HTML here to customize the description.
              </SmallTextMutedParagraph>
              <Form.Control
                as="textarea"
                name="stepDescription"
                ref={register()}
                defaultValue={props.setupStep.description}
                onChange={(e) => (props.setupStep.description = e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ConfigurationEditCustomIntegrations;
