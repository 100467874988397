import React from "react";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { SmallTextMutedParagraph } from "../MergeText";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";

export interface FormFieldCommonProps {
  subtitle?: string | JSX.Element;
  title?: string;
  example?: string;
  valueType?: string;
  choices?: any;
  className?: string;
}

interface Props extends FormFieldCommonProps {
  children: JSX.Element | (JSX.Element | false | undefined)[];
}

function FormField({ children, example, subtitle, title, valueType, choices, className }: Props) {
  return (
    <div className={className || "form-group deprecated-mb-5"}>
      <div className="d-flex align-items-center text-align-center deprecated-mb-2">
        {title && <DeprecatedH4 className="mb-0">{title}</DeprecatedH4>}
        {valueType && (
          <Badge variant="soft-secondary" className="deprecated-ml-2">
            {valueType
              ? valueType === "url"
                ? "URL"
                : valueType.charAt(0).toUpperCase() + valueType.slice(1)
              : ""}
          </Badge>
        )}
      </div>
      {subtitle && (
        <SmallTextMutedParagraph className="mb-0">
          {subtitle}
          {choices && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 100, hide: 0 }}
              overlay={
                <Tooltip id="choices-mapping-tooltip">
                  If you are hard-coding a value, include one of the following:
                  {choices.map((choice: string) => " " + choice)}
                </Tooltip>
              }
            >
              <i className="deprecated-ml-2 text-gray-50 text-right fe fe-info float-right" />
            </OverlayTrigger>
          )}
        </SmallTextMutedParagraph>
      )}
      {example && <SmallTextMutedParagraph>{example}</SmallTextMutedParagraph>}
      {children}
    </div>
  );
}

export default FormField;
