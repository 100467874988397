import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import useAppContext from "../../../context/useAppContext";
import { palette } from "../../../../styles/theme";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared-components/MergeLayouts";
import Stack from "./Stack";
import { Button } from "@merge-api/merge-javascript-shared";

type Props = {
  onClick: () => void;
};

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${palette.graphite};
`;

const LinkedAccountDeleteSwitch = ({ onClick }: Props) => {
  const { user } = useAppContext();
  return (
    <Card className="p-5">
      <SectionHeaderWrapper
        title="Delete Linked Account"
        titleHeading={TitleHeading.H2}
        style={{ marginBottom: "-8px" }}
      >
        <>
          <Subtitle>
            After deleting this Linked Account, you’ll be notified once deletion of all associated
            data - including synced data models - is complete.
          </Subtitle>
          <Subtitle className="deprecated-mt-3 deprecated-mb-4">Deletion is irreversible!</Subtitle>
        </>
      </SectionHeaderWrapper>
      <Stack>
        {user.is_demo ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="resync-tooltip">
                Deleting Linked Accounts is not available in demo state!
              </Tooltip>
            }
          >
            <div>
              <Button size="md" color="danger">
                Delete Linked Account
              </Button>
            </div>
          </OverlayTrigger>
        ) : (
          <Button size="md" color="danger" onClick={onClick}>
            Delete Linked Account
          </Button>
        )}
      </Stack>
    </Card>
  );
};

export default LinkedAccountDeleteSwitch;
