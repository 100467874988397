import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  hasPrevious: boolean;
  hasNext: boolean;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
}

const NoBorderShadowButton = styled(Button)`
  border-color: transparent !important;
  box-shadow:
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.35),
    0px 1.5px 4px -1px rgba(0, 0, 0, 0.16);
`;

const PaginationFooter = ({ hasPrevious, hasNext, onPreviousClick, onNextClick }: Props) => {
  return (
    <Row className="deprecated-mb-5">
      <Col>
        <div className="d-flex justify-content-around bd-highlight">
          <div>
            <NoBorderShadowButton variant="white" onClick={onPreviousClick} disabled={!hasPrevious}>
              <ChevronLeft />
            </NoBorderShadowButton>
          </div>

          <div>
            <NoBorderShadowButton variant="white" onClick={onNextClick} disabled={!hasNext}>
              <ChevronRight />
            </NoBorderShadowButton>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default PaginationFooter;
