import React from "react";
import classNames from "classnames";
import styled from "styled-components";
import {
  IntegrationIssueMetadata,
  CommonModelInformation,
} from "../../../IntegrationsManagementEntities";
import CommonModelMissingFieldCell from "./CommonModelMissingFieldCell";
import { Schemas } from "../../../../configuration/common-models/CommonModelToggleTypes";
import { getCommonModelName } from "../../../utils/IntegrationsManagementUtils";
import { spectrum } from "../../../../../styles/theme";
import { navigateToIndividualIssuePage } from "../../../../../router/RouterUtils";
type IntegrationIssueMetadataCardContainerProps = {
  isLastRow: boolean;
  shouldHover: boolean;
};
const IntegrationIssueMetadataCardContainer = styled.div<IntegrationIssueMetadataCardContainerProps>`
  &:hover {
    background: ${(props) => (props.shouldHover ? "#f9fbfd" : "")};
    cursor: ${(props) => (props.shouldHover ? "pointer" : "")};
  }
  border-bottom: ${(props) => (props.isLastRow ? "" : "0.5px solid var(--lm-gray-20, #dce2ea)")};
`;

const ErrorDescriptionText = styled.div`
  font-weight: 400;
  line-height: 22px;
`;

const TitleContainer = styled.div`
  gap: 8px;
`;

type IntegrationIssueMetadataCardProps = {
  issueMetadata: IntegrationIssueMetadata;
  categorySchema: Schemas;
  isLastIndex: boolean;
  onClick?: () => void;
  showPreview: boolean;
};

const IntegrationIssueMetadataCard = ({
  issueMetadata,
  showPreview,
  categorySchema,
  isLastIndex,
  onClick,
}: IntegrationIssueMetadataCardProps) => {
  const commonModelsWithIssues = issueMetadata?.common_model_information || [];
  return (
    commonModelsWithIssues && (
      <IntegrationIssueMetadataCardContainer
        className={classNames("d-flex flex-column p-3", showPreview ? "" : "pt-2")}
        isLastRow={isLastIndex}
        shouldHover={onClick ? true : false}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      >
        {issueMetadata.user_facing_error_detail && (
          <ErrorDescriptionText className="text-sm">
            {issueMetadata.user_facing_error_detail}
          </ErrorDescriptionText>
        )}
        <div className="mt-1.5 mb-1.5 text-xs text-gray-50">
          Impacted Merge Common Models and Fields
        </div>
        <div className="d-flex align-items-center">
          <TitleContainer className="d-flex flex-wrap align-items-center">
            {commonModelsWithIssues.map((commonModel: CommonModelInformation) => {
              const commonModelName = getCommonModelName(commonModel.common_model_name);
              return (
                <CommonModelMissingFieldCell
                  commonModel={commonModelName}
                  missingFields={commonModel.missing_fields}
                  modelSchema={categorySchema[commonModelName]}
                />
              );
            })}
          </TitleContainer>
        </div>
      </IntegrationIssueMetadataCardContainer>
    )
  );
};
export default IntegrationIssueMetadataCard;
