import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Info } from "react-feather";

interface Props {
  text: string;
  iconSize?: number;
  iconStyle?: React.CSSProperties;
  iconClassName?: string;
}

const InfoIconWithTooltip = ({ text, iconSize, iconStyle, iconClassName }: Props) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={text}>{text}</Tooltip>}>
      <Info size={iconSize ?? 12} style={iconStyle} className={iconClassName} />
    </OverlayTrigger>
  );
};

export default InfoIconWithTooltip;
