import React from "react";
import styled from "styled-components";
import { Tooltip, OverlayTrigger, Button, Card, Col, Row, Image } from "react-bootstrap";
import { ChevronRight } from "react-feather";
import { LinkedIntegration, APICategory } from "../../models/Entities";
import { useHistory } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { navigateToProductionLinkedAccountsTablePage } from "../../router/RouterUtils";
import classNames from "classnames";
import { spectrum } from "../../styles/theme";
import INTEGRATION_CATEGORY_LIST, { fullDisplayNameForAPICategory } from "../../models/Helpers";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH5 from "../../deprecated/DeprecatedH5";

const Title = styled(DeprecatedH2)`
  margin-bottom: 6px;
`;

const Subtitle = styled.p.attrs({ className: "small" })`
  margin-top: 8px;
  margin-bottom: 1.2rem;
`;

interface Props {
  integrations: LinkedIntegration[] | undefined;
}

const LinkedIntegrationsScreen = (props: Props) => {
  return (
    <Card>
      <Card.Body className="card-body-bottom">
        <Title>Linked Integrations</Title>
        <Subtitle>Integrations with at least one Linked Account.</Subtitle>
        <div>
          {INTEGRATION_CATEGORY_LIST.map((category) => (
            <div key={category}>
              <hr className="linked-accounts-separator" />
              <LinkedIntegrationsCategoryColumn
                category={category}
                key={category}
                description={fullDisplayNameForAPICategory(category)}
                integrations={props.integrations}
              />
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
};

const LinkedIntegrationsCategoryColumn = ({
  category,
  description,
  integrations,
}: {
  category: APICategory;
  description: string;
  integrations: LinkedIntegration[] | undefined;
}) => {
  const categoryIntegrations = integrations?.filter(
    (integration) =>
      integration.categories
        ?.map((integrationCategory) => integrationCategory?.toLowerCase())
        .includes(category),
  );

  const history = useHistory();

  return (
    <Row>
      <Col xs={12} sm={6} className="d-flex deprecated-mb-3">
        {categoryIntegrations && categoryIntegrations.length > 0 ? (
          <div className="dashboard-number-header mb-0">{categoryIntegrations.length}</div>
        ) : (
          <div className="gray dashboard-number-header mb-0">-</div>
        )}
        <DeprecatedH5 className="deprecated-ml-3 text-gray-700 font-weight-normal linked-accounts-description">
          {description}
        </DeprecatedH5>
      </Col>

      <Col xs={12} sm={6}>
        {categoryIntegrations ? (
          categoryIntegrations.length > 0 ? (
            categoryIntegrations.map((integration: LinkedIntegration) => (
              <Row className="deprecated-mb-2" key={integration.id}>
                <Col>
                  <OverlayTrigger
                    key={integration.id}
                    placement="top"
                    overlay={<Tooltip id="tooltip-top">Go to Linked Accounts</Tooltip>}
                  >
                    <Button
                      variant="outline-secondary"
                      className="text-left hoverable-white d-flex align-items-center justify-content-between"
                      block
                      onClick={() => {
                        navigateToProductionLinkedAccountsTablePage(history, {
                          integration: integration.name,
                        });
                      }}
                    >
                      <div className="d-flex align-items-center font-normal small">
                        <Image
                          src={integration.square_image}
                          width={20}
                          height={20}
                          className="deprecated-mr-3"
                          rounded
                        />
                        {integration.name}
                      </div>
                      <ChevronRight size={13} />
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            ))
          ) : (
            <div className="h-100 d-flex align-items-center">
              <DeprecatedH5 className="gray font-weight-light">No linked integrations</DeprecatedH5>
            </div>
          )
        ) : (
          <Row className="deprecated-mb-2">
            <Col>
              <Button variant="outline-secondary" className="text-left hoverable-white" block>
                <Row>
                  <Col>
                    <ContentLoader
                      speed={1.4}
                      width={100}
                      height={15}
                      viewBox="0 0 100 15"
                      backgroundColor={spectrum.gray0}
                      foregroundColor={spectrum.gray10}
                    >
                      <rect x="0" y="0" rx="3" ry="3" width="100" height="15" />
                    </ContentLoader>
                  </Col>
                  <Col className="col-auto">
                    <ChevronRight size={13} />
                  </Col>
                </Row>
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default LinkedIntegrationsScreen;
