import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { fetchWithAuth, FormErrorData } from "../../../../api-client/APIClient";
import { LinkedAccount, LinkedAccountStatus } from "../../../../models/Entities";
import { showErrorToast } from "../../../shared-components/Toasts";
import { PaddingLessCol } from "./LeftSideBarSpacing";
import CustomAddTestLinkedAccountButton from "../SyncSandBox/CustomAddTestLinkedAccountButton";
import useAppContext from "../../../context/useAppContext";
import { isBillingPlanFreeLaunch } from "../../../settings/billing/BillingUtils";
import useProductRestrictions from "../../../shared-components/hooks/useProductRestrictions";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

export interface TileWithImageProps {
  img: React.ReactElement;
  title: string;
  subtitle?: JSX.Element;
  stepNumber: number;
  linkedAccounts: LinkedAccount[] | null;
  numberOfAccounts?: number;
  setNumberOfAccounts: Dispatch<SetStateAction<number | undefined>>;
  setLinkedAccounts: Dispatch<SetStateAction<LinkedAccount[]>>;
  setLinkedAccount: Dispatch<SetStateAction<LinkedAccount | null>>;
}

export const TitleText = styled(DeprecatedH6)`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-top: 56px;
  margin-bottom: 17px;
  align-items: center;
  color: #121314;
`;

const MobileResponsiveText = styled(DeprecatedH6)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #121314;
  margin-bottom: 0px;
  margin-left: 17px;
  margin-top: 11px;
`;

const MobileResponsiveContainer = styled.div`
  display: none;
  @media (max-width: 1000px) {
    background-color: #f2f4f7;
    width: 100%;
    height: 42px;
    display: flex;
  }
`;

const MainContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  min-height: 136px;
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  grid-template-areas:
    "image description"
    "....... button";
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "image"
      "description"
      "button";
  }
`;

const DescriptionContainer = styled.div`
  padding: 16px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
`;

const TitleCtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

const Title = styled(DeprecatedH4)`
  width: 100%;
  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: var(--gray90);
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.div`
  width: 100%;
  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #000000;
  margin: 0;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Image = styled.div`
  margin-left: 22px;
  grid-area: image;
  svg {
    width: 100px;
    height: 100px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1167px) {
    display: flex;
    align-items: left;
    justify-content: left;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: button;
  padding: 12px 20px;
`;

type DescriptionProps = Pick<React.ComponentProps<"div">, "className"> & {
  title: string;
  subtitle?: JSX.Element;
};

const Description = ({ title, subtitle, className }: DescriptionProps) => (
  <DescriptionContainer className={className}>
    <TitleCtn>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleCtn>
  </DescriptionContainer>
);

const StyledDescription = styled(Description)`
  grid-area: description;
`;

const CustomTileStep = ({
  img,
  title,
  subtitle,
  stepNumber,
  linkedAccounts,
  numberOfAccounts,
  setNumberOfAccounts,
  setLinkedAccount,
  setLinkedAccounts,
}: TileWithImageProps) => {
  const [linkToken, setLinkToken] = useState<string | undefined>();
  const { user } = useAppContext();
  const { orgBillingPlan } = useProductRestrictions();
  const [orgHasReachedMaxLinkedAccounts, setOrgHasReachedMaxLinkedAccounts] = useState(false);
  const [renderOrgPlan, setRenderOrgPlan] = useState(false);

  /*
   *  First check to see if our API call has returned our list of Linked Accounts (numberOfAccounts is set to undefined until then)
   *  If they are not undefined, sets state for if the Org is launch and has reached max link accounts
   *  Sets the state of orgHasReachedMaxLinkedAccounts, and sets renderOrgPlan to true
   *  If orgHasReachedMaxLinkedAccounts isn't true, and renderOrgPlan is true, calls fetchCreateTestLinkToken
   *
   */

  useEffect(() => {
    if (numberOfAccounts !== undefined) {
      setOrgHasReachedMaxLinkedAccounts(
        isBillingPlanFreeLaunch(user.organization.organization_billing_plan?.billing_plan) &&
          numberOfAccounts >= (orgBillingPlan?.product_restrictions?.test_accounts ?? 3),
      );
      setRenderOrgPlan(true);

      if (!orgHasReachedMaxLinkedAccounts && renderOrgPlan) {
        fetchCreateTestLinkToken();
      }
    }
  }, [numberOfAccounts, renderOrgPlan]);

  /*
   *  Fetches a create-test-link-token to create a linked account
   */

  const fetchCreateTestLinkToken = () => {
    if (!orgHasReachedMaxLinkedAccounts) {
      fetchWithAuth({
        path: "/integrations/create-test-link-token",
        method: "POST",
        onResponse: (data) => {
          setLinkToken(data.link_token);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            showErrorToast(
              "Unable to initialize new test Linked Accounts, please check your connection and try again. test",
            );
          } else {
            showErrorToast(
              "Unable to initialize new test Linked Accounts, please check your connection and try again.",
            );
          }
        },
      });
    }
  };

  const fetchNewLinkedAccounts = () => {
    fetchWithAuth({
      path: `/integrations/linked-accounts?status=${LinkedAccountStatus.COMPLETE}&is_test_account=true&ignore_demo_data=true&page_size=100`,
      method: "GET",
      onResponse: (data) => {
        setLinkedAccounts(data.results);
        if (linkedAccounts) {
          const firstLinkedAccount = data.results[0];
          setLinkedAccount(firstLinkedAccount);
          setNumberOfAccounts(data.results.length); //causes re-render of useEffect
        }
      },
    });
  };

  /*
   *  Scrolls to SyncOverview tab once linked account is added
   */

  const syncOverview = document.getElementById("sync-overview");

  const ScrollToSyncOverview = () => {
    syncOverview?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <PaddingLessCol>
      <MainContainer>
        <MobileResponsiveContainer>
          <MobileResponsiveText>Step {stepNumber}</MobileResponsiveText>
        </MobileResponsiveContainer>
        <GridContainer>
          <Image>{img}</Image>
          <StyledDescription title={title} subtitle={subtitle} />
          <ButtonContainer>
            {linkToken && !orgHasReachedMaxLinkedAccounts && (
              <CustomAddTestLinkedAccountButton
                linkToken={linkToken}
                onNewLinkedAccount={() => {
                  fetchNewLinkedAccounts();
                  ScrollToSyncOverview();
                }}
              />
            )}
            {linkToken && !orgHasReachedMaxLinkedAccounts ? (
              <div className="text-gray-50 text-sm font-medium">
                This button will create a Free Test Linked Account. You can set up Production Linked
                Accounts later.
              </div>
            ) : (
              <div className="text-gray-50 text-sm font-medium">
                You have reached the maximum number of Test Linked Accounts.
              </div>
            )}
          </ButtonContainer>
        </GridContainer>
      </MainContainer>
    </PaddingLessCol>
  );
};

export default CustomTileStep;
