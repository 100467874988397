import classNames from "classnames";
import { format } from "date-fns";
import React, { useState } from "react";
import { ResponseCodeBadge } from "../../../shared-components/MergeBadges";
import { useHistory } from "react-router-dom";
import {
  OverlayTrigger,
  Tooltip,
  Col,
  Row,
  Alert,
  Button,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { showErrorToast } from "../../../shared-components/Toasts";
import MergeCodeBlock from "../../../shared-components/MergeCodeBlock";
import MergeModal from "../../../shared-components/MergeModal";
import EmptyStateWrapper from "../../../shared-components/EmptyStateWrapper";
import { APIRequestLogEntry, IntegrationIssue } from "../../IntegrationsManagementEntities";
import { getAPIRequestPath } from "../../../../models/Helpers";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import useAppContext from "../../../context/useAppContext";
import ContentLoader from "react-content-loader";
import {
  navigateToIssueLogEntrySidePanel,
  navigateToLogsTablePage,
} from "../../../../router/RouterUtils";
import { spectrum } from "../../../../styles/theme";
import { SmallTextMutedParagraph } from "../../../shared-components/MergeText";
import { LinkedAccountStatus } from "../../../../models/Entities";
import styled from "styled-components";
import DeprecatedH2 from "../../../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";

type Props = {
  integrationIssue: IntegrationIssue | undefined;
  issueLogs: APIRequestLogEntry[] | undefined;
};

const FontSize = styled(DeprecatedH4)`
  font-size: 14px;
  line-height: 24px;
`;

const IntegrationManagementIndividualIssueActivity = ({ integrationIssue, issueLogs }: Props) => {
  const history = useHistory();
  const { user, isUserPrivileged } = useAppContext();
  const [isRequestTestResultsModalShown, setIsRequestTestResultsModalShown] =
    useState<boolean>(false);
  const [testedLog, setTestedLog] = useState<APIRequestLogEntry>();
  const [responseCode, setResponseCode] = useState<number>();
  const [responseBody, setResponseBody] = useState<string>("");

  const testIssueIntegration = (log: APIRequestLogEntry) => {
    if (integrationIssue) {
      const path = getAPIRequestPath(log.url, integrationIssue.linked_account.integration);
      setIsRequestTestResultsModalShown(true);
      setResponseBody("");
      setResponseCode(undefined);
      fetchWithAuth({
        path: `/integrations/linked-accounts/${log.linked_account.id}/test-request`,
        method: "POST",
        body: { method: log.method, endpoint: path, body: log.request_body },
        onResponse: (responseData: { status: string; status_code: number; body: string }) => {
          setResponseCode(responseData.status_code);
          setTestedLog(log);
          try {
            setResponseBody(JSON.stringify(JSON.parse(responseData.body), null, 2));
          } catch (err) {
            setResponseBody(responseData.body);
          }
        },
        onError: () => {
          showErrorToast("Oops! Looks like we encountered an error. Please try again.");
          setIsRequestTestResultsModalShown(false);
        },
      });
    }
  };

  return (
    <>
      <Row>
        <Col>
          {/* setting this padding a little larger due to the shadows and rounded borders around it it looked odd */}
          <DeprecatedH2 className="pl-0 pr-6">
            Activity
            {issueLogs &&
              issueLogs.length > 0 &&
              integrationIssue?.linked_account.status !== LinkedAccountStatus.INCOMPLETE && (
                <ClickableContainer>
                  <span
                    className="float-right text-gray-50 small deprecated-mt-1"
                    onClick={() => {
                      navigateToLogsTablePage(history, {
                        integration: integrationIssue?.linked_account.integration.name,
                        end_user__organization_name:
                          integrationIssue?.linked_account.end_user.organization_name,
                      });
                    }}
                  >
                    View all logs
                  </span>
                </ClickableContainer>
              )}
          </DeprecatedH2>
        </Col>
      </Row>
      <ListGroup variant="flush" className="list-group-activity">
        {issueLogs && issueLogs?.length == 0 && (
          <EmptyStateWrapper
            title={
              isUserPrivileged
                ? "No activity"
                : "You must be an admin or developer to view activity"
            }
          />
        )}
        {integrationIssue && issueLogs ? (
          issueLogs.map((log) => (
            <Button
              variant="link"
              className="text-black p-0"
              onClick={() => navigateToIssueLogEntrySidePanel(history, integrationIssue.id, log.id)}
            >
              <ClickableContainer>
                <ListGroupItem key={log.id} className="list-group-item pb-0 pt-0 pl-0">
                  <Alert className="card">
                    <Row>
                      <Col className="col-1 d-flex align-items-center justify-content-center">
                        <DeprecatedH2 className="mb-0">
                          {log.response_code === 200 ? (
                            <span className="fe fe-zap green" />
                          ) : (
                            <span className="fe fe-zap-off red" />
                          )}
                        </DeprecatedH2>
                      </Col>
                      <Col className="col-8">
                        <Row>
                          <Col>
                            <FontSize className="deprecated-mb-2 d-flex">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip-top">{log.url}</Tooltip>}
                              >
                                <span
                                  className="d-inline-block text-truncate"
                                  style={{ maxWidth: "95%" }}
                                >
                                  {log.url}
                                </span>
                              </OverlayTrigger>
                              {isUserPrivileged && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={<Tooltip id="tooltip-top">Retry request</Tooltip>}
                                >
                                  <span
                                    className="deprecated-ml-3 p-0"
                                    onClick={
                                      user?.is_demo
                                        ? () => (
                                            setResponseBody(`{ "demo": "Sample JSON response!" }`),
                                            setResponseCode(log.response_code),
                                            setTestedLog(log),
                                            setIsRequestTestResultsModalShown(true)
                                          )
                                        : () => testIssueIntegration(log)
                                    }
                                  >
                                    <ClickableContainer>
                                      <span className="fe fe-rotate-cw text-gray-50" />
                                    </ClickableContainer>
                                  </span>
                                </OverlayTrigger>
                              )}
                            </FontSize>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="align-items-center">
                            <span className="small d-flex">
                              <span
                                className={classNames(
                                  "header-pretitle font-medium d-inline-block",
                                  log.response_code === 200 ? "green" : "red",
                                )}
                              >
                                {log.response_code} {log.response_code === 200 ? "OK" : "ERROR"}
                              </span>
                              <span
                                className="d-inline-block text-truncate text-gray-50 bg-transparent border-0 deprecated-ml-2"
                                style={{ maxWidth: "70%" }}
                              >
                                {log.response_body}
                              </span>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-3 text-right d-flex align-items-center justify-content-end">
                        <SmallTextMutedParagraph className="deprecated-mr-4 mb-0 small">
                          {format(new Date(log.created_at), "MMM dd HH:mm:ss")}
                        </SmallTextMutedParagraph>
                        {isUserPrivileged && (
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-top">Review log details</Tooltip>}
                          >
                            <ClickableContainer>
                              <span className="fe fe-chevron-right" />
                            </ClickableContainer>
                          </OverlayTrigger>
                        )}
                      </Col>
                    </Row>
                  </Alert>
                </ListGroupItem>
              </ClickableContainer>
            </Button>
          ))
        ) : (
          <>
            {[0, 1, 2, 3].map((count) => (
              <ListGroupItem key={count} className="pb-0 pt-0">
                <Alert className="card">
                  <ContentLoader
                    speed={1.4}
                    width={414}
                    height={45}
                    viewBox="0 0 414 45"
                    backgroundColor={spectrum.gray0}
                    foregroundColor={spectrum.gray10}
                  >
                    <rect x="54" y="6" rx="3" ry="3" width="360" height="14" />
                    <rect x="54" y="27" rx="3" ry="3" width="120" height="14" />
                    <circle cx="18" cy="23" r="15" />
                  </ContentLoader>
                </Alert>
              </ListGroupItem>
            ))}
          </>
        )}
      </ListGroup>

      <RequestTestResultsModal
        log={testedLog}
        responseCode={responseCode}
        responseBody={responseBody}
        isShown={isRequestTestResultsModalShown}
        setIsShown={setIsRequestTestResultsModalShown}
      />
    </>
  );
};

type RequestTestResultsModalProps = {
  log?: APIRequestLogEntry;
  responseCode: number | undefined;
  responseBody: string;
  isShown: boolean;
  setIsShown: (value: boolean) => void;
};

export const RequestTestResultsModal = ({
  log,
  responseCode,
  responseBody,
  isShown,
  setIsShown,
}: RequestTestResultsModalProps) => {
  return (
    <MergeModal title="Retry API Request Results" show={isShown} onHide={() => setIsShown(false)}>
      {responseCode ? (
        <>
          <Section>
            <TextField name="URL" value={log?.url} />
            <TextField name="Direction" value={log?.direction} />
            <TextField name="Method" value={log?.method} />
          </Section>
          <Section>
            <TextField name="Headers" value={log?.request_headers} />
            <TextField name="Body" value={log?.request_body} />
            <TextField name="Status" value={undefined}>
              <ResponseCodeBadge responseCode={responseCode} />
            </TextField>
          </Section>
          <DeprecatedH4 className="deprecated-mt-3 deprecated-mb-3">Response</DeprecatedH4>
          <MergeCodeBlock
            showLineNumbers
            customStyle={{
              lineHeight: "4px",
              height: "200px",
            }}
          >
            {responseBody || "The remote API returned an empty body."}
          </MergeCodeBlock>
        </>
      ) : (
        <EmptyStateWrapper isSpinner />
      )}
    </MergeModal>
  );
};

const Section = ({ children }: { children: JSX.Element | (JSX.Element | undefined)[] }) => (
  <>
    <div className="deprecated-mb-3">{children}</div>
    <hr />
  </>
);

const TextField = ({
  name,
  value,
  children,
}: {
  name: string;
  value: string | object | undefined | null;
  children?: JSX.Element;
}) => {
  const text =
    !value || (typeof value === "string" && value.length == 0)
      ? "None"
      : typeof value === "string" && !children
      ? value
      : children;
  return (
    <div className="d-flex text-left align-items-center justify-content-start deprecated-mt-2">
      <DeprecatedH4 className="mb-0 text-align-start w-1/4">{name}</DeprecatedH4>
      <p className="mb-0 text-break w-3/4">{text}</p>
    </div>
  );
};

export default IntegrationManagementIndividualIssueActivity;
