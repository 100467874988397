import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  CONFIGURATION_FIELD_MAPPINGS_PATH,
  CONFIGURATION_WEBHOOKS_PATH,
  CONFIGURATION_WEBHOOKS_EMITTERS_ADD_PATH,
  CONFIGURATION_WEBHOOKS_EMITTERS_PATH,
  CONFIGURATION_WEBHOOKS_RECEIVERS_PATH,
  CONFIGURATION_LINK_PATH,
  CONFIGURATION_LINK_SELECTIVE_SYNC,
  CONFIGURATION_LINK_REQUESTED_DATA_PATH,
  CONFIGURATION_LINK_AUTHENTICATION_PATH,
  CONFIGURATION_LINK_INTEGRATIONS_PATH,
  CONFIGURATION_CUSTOM_OBJECTS_PATH,
} from "../../router/RouterUtils";
import ConfigurationPageWrapper from "./ConfigurationPageWrapper";
import ConfigurationEditWebhooksPage from "./webhooks/ConfigurationAddEditWebhooksPage/ConfigurationAddEditWebhooksPage";
import ConfigurationWebhookEmittersPage from "./webhooks/ConfigurationWebhookEmittersPage";
import ConfigurationWebhooksPageWrapper from "./webhooks/ConfigurationWebhooksPageWrapper";
import ConfigurationSelectiveSyncSettingsPage from "./selective-sync/ConfigurationSelectiveSyncSettingsPage";
import useAppContext from "../context/useAppContext";
import ConfigurationWebhookReceiversPage from "./webhooks/ConfigurationWebhookReceiversPage";
import ConfigurationLinkPageWrapper from "./link/ConfigurationLinkPageWrapper";
import ConfigurationCommonModelsSettings from "./common-models/ConfigurationCommonModelsSettings";
import ConfigurationIntegrationsSettings from "./integrations/settings/ConfigurationIntegrationsSettings";
import ConfigurationLinkIntegrationSettingsPage from "./link/ConfigurationLinkIntegrationSettingsPage";
import {
  canSeeRequestedDataSettings,
  canSeeSelectiveSyncSettings,
} from "./ConfigurationEligibilityHelpers";

import ConfigurationFieldMappingsPageContainer from "./integrations/field-mappings/ConfigurationFieldMappingsPageContainer";
import ConfigurationSyncingCustomObjects from "./custom-objects/ConfigurationSyncingCustomObjects";
import useProductRestrictions from "../shared-components/hooks/useProductRestrictions";

const ConfigurationTab = () => {
  const { productRestrictions } = useProductRestrictions();
  const { isUserPrivileged, user } = useAppContext();

  const webhooksRoutes = (
    <Route path={CONFIGURATION_WEBHOOKS_PATH}>
      <ConfigurationWebhooksPageWrapper>
        <Switch>
          <Route
            exact
            path={CONFIGURATION_WEBHOOKS_EMITTERS_PATH}
            component={ConfigurationWebhookEmittersPage}
          />
          <Route
            exact
            path={[
              CONFIGURATION_WEBHOOKS_EMITTERS_ADD_PATH,
              `${CONFIGURATION_WEBHOOKS_EMITTERS_PATH}/:webhookID/edit`,
            ]}
            component={ConfigurationEditWebhooksPage}
          />
          <Route
            exact
            path={CONFIGURATION_WEBHOOKS_RECEIVERS_PATH}
            component={ConfigurationWebhookReceiversPage}
          />
          <Redirect to={CONFIGURATION_WEBHOOKS_EMITTERS_PATH} />
        </Switch>
      </ConfigurationWebhooksPageWrapper>
    </Route>
  );

  const linkRoutes = (
    <Route path={CONFIGURATION_LINK_PATH}>
      <ConfigurationLinkPageWrapper>
        <Switch>
          <Route
            exact
            path={CONFIGURATION_LINK_INTEGRATIONS_PATH}
            component={ConfigurationLinkIntegrationSettingsPage}
          />
          <Route
            exact
            path={CONFIGURATION_LINK_AUTHENTICATION_PATH}
            component={ConfigurationIntegrationsSettings}
          />
          {canSeeRequestedDataSettings(user) && (
            <Route
              exact
              path={CONFIGURATION_LINK_REQUESTED_DATA_PATH}
              component={ConfigurationCommonModelsSettings}
            />
          )}
          {canSeeSelectiveSyncSettings(isUserPrivileged, user) && (
            <Route
              exact
              path={CONFIGURATION_LINK_SELECTIVE_SYNC}
              component={ConfigurationSelectiveSyncSettingsPage}
            />
          )}
          <Redirect to={CONFIGURATION_LINK_INTEGRATIONS_PATH} />
        </Switch>
      </ConfigurationLinkPageWrapper>
    </Route>
  );

  return (
    <ConfigurationPageWrapper>
      <Switch>
        {linkRoutes}
        {webhooksRoutes}
        {isUserPrivileged && (
          <Route
            path={CONFIGURATION_FIELD_MAPPINGS_PATH}
            component={ConfigurationFieldMappingsPageContainer}
          />
        )}
        {isUserPrivileged && productRestrictions?.are_custom_objects_enabled && (
          <Route
            path={CONFIGURATION_CUSTOM_OBJECTS_PATH}
            component={ConfigurationSyncingCustomObjects}
          />
        )}
        <Redirect to={CONFIGURATION_LINK_INTEGRATIONS_PATH} />
      </Switch>
    </ConfigurationPageWrapper>
  );
};

export default ConfigurationTab;
