import isUndefined from "lodash/isUndefined";
import isObjectLike from "lodash/isObjectLike";

/**
 * Takes field mapping value and transforms it to use an example
 */
export const parseFieldMappingExample = (fieldMappingValue: any) => {
  // if undefined, which usually would occur just for CustomFields, we have no value to use an example so we state as unavailable.
  if (isUndefined(fieldMappingValue)) {
    return "preview unavailable";
  }

  return isObjectLike(fieldMappingValue)
    ? JSON.stringify(fieldMappingValue)
    : String(fieldMappingValue);
};
