import { ArrowLeftRight, Plus } from "lucide-react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../models/Entities";
import { navigateToCreateCommonModelFieldMapping } from "../../../../../router/RouterUtils";
import { StyledSmallTextMutedParagraph } from "./FieldMappingInstanceCard";
import { CodeText } from "../../../../shared-components/MergeText";
import DeleteFieldMappingModal_OLD from "./DeleteFieldMappingModal_old";

type Props = {
  fieldMappingTarget: FieldMappingTarget;
  linkedAccount: LinkedAccount;
  fieldMappingConfig: FieldMappingCommonModelConfiguration;
};

const LinkedAccountFieldMappingContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr 4fr;
`;

const FieldMappingInfoCard = styled.div`
  box-shadow: 0px 1px 0px #eaeef3;
  border-radius: 8px 8px 0 0 !important;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 3px;
  border-radius: 8px;
`;

const FieldMappingInfoTopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const AddNewFieldMappingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 3px;
  background: #f5f8ff;
  border-radius: 8px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  justify-self: center;
  align-self: center;
  padding: 16px 24px;
`;

const FieldMappingTargetMessage = styled.div`
  background-color: #fff7ed;
  color: #f97316;
  font-weight: 400;
  font-size: 10px;
  padding: 10px 24px;
  border-radius: 0 0 8px 8px;
`;

const AddNewFieldMappingText = styled.div`
  color: #697387;
  font-weight: 600;
  font-size: 14px;
`;

const FieldMappingTargetContainer = styled.div`
  box-shadow:
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.35),
    0px 3px 12px -3px rgba(0, 0, 0, 0.12);
  border-radius: 8px 8px 8px 8px;
`;

const MappingSpacer = styled.div`
  margin-left: 8px;
  width: 17px;
`;

export const FieldMappingTargetCardElement = ({
  fieldKey,
  fieldDescription,
}: {
  fieldKey: string;
  fieldDescription: string;
}) => (
  <FieldMappingTargetContainer className="d-flex flex-column">
    <FieldMappingInfoCard className="mb-0">
      <div className="d-flex flex-column justify-content-between w-100">
        <FieldMappingInfoTopRow>
          <CodeText isBold>{fieldKey}</CodeText>
        </FieldMappingInfoTopRow>
        <StyledSmallTextMutedParagraph className="mb-0">
          {fieldDescription}
        </StyledSmallTextMutedParagraph>
      </div>
    </FieldMappingInfoCard>
    <FieldMappingTargetMessage>Organization-wide target field</FieldMappingTargetMessage>
  </FieldMappingTargetContainer>
);

export const FieldMappingTargetCardElementWithoutTag = ({
  fieldKey,
  fieldDescription,
}: {
  fieldKey: string;
  fieldDescription: string;
}) => (
  <FieldMappingTargetContainer className="d-flex flex-column">
    <FieldMappingInfoCard className="mb-0">
      <div className="d-flex flex-column justify-content-between w-100">
        <FieldMappingInfoTopRow>
          <CodeText isBold>{fieldKey}</CodeText>
        </FieldMappingInfoTopRow>
        <StyledSmallTextMutedParagraph className="mb-0">
          {fieldDescription}
        </StyledSmallTextMutedParagraph>
      </div>
    </FieldMappingInfoCard>
  </FieldMappingTargetContainer>
);

const FieldMappingTargetCard_OLD = ({
  fieldMappingTarget: fieldMappingTarget,
  linkedAccount,
}: Props) => {
  const { common_model_name, field_key, field_description, id } = fieldMappingTarget;
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const history = useHistory();
  const integrationName = linkedAccount?.integration?.name || "";
  const AddNewFieldMappingInstanceCard = () => (
    <div className="d-flex">
      <AddNewFieldMappingContainer
        className="w-100"
        onClick={() =>
          navigateToCreateCommonModelFieldMapping(history, linkedAccount.id, common_model_name, {
            fieldMappingTargetID: fieldMappingTarget.id,
          })
        }
      >
        <div className="d-flex flex-column flex-grow-1">
          <AddNewFieldMappingText className="d-flex align-items-center justify-content-center">
            <Plus className="deprecated-mr-1" size={12} />
            {integrationName} Mapping
          </AddNewFieldMappingText>
        </div>
      </AddNewFieldMappingContainer>
      <MappingSpacer />
    </div>
  );

  return (
    <LinkedAccountFieldMappingContainer className="row">
      <DeleteFieldMappingModal_OLD
        fieldMappingID={id}
        showModal={showDeleteModal}
        linkedAccountID={linkedAccount.id}
        onHide={() => setShowDeleteModal(false)}
      />
      <FieldMappingTargetCardElement fieldKey={field_key} fieldDescription={field_description} />
      <IconContainer>
        <ArrowLeftRight size={18} />
      </IconContainer>
      <AddNewFieldMappingInstanceCard />
    </LinkedAccountFieldMappingContainer>
  );
};

export default FieldMappingTargetCard_OLD;
