import { isFeatureEnabled } from "../../router/ComponentForFeature";
import { UserWithBillingPlan } from "../context/AppContext";
import { BillingPlanTier } from "../settings/billing/BillingModels";

export function canSeeSelectiveSyncSettings(
  isUserPrivileged: boolean,
  user: UserWithBillingPlan,
): boolean {
  return isUserPrivileged && isFeatureEnabled("is_selective_sync_enabled", user);
}

export function canSeeRequestedDataSettings(user: UserWithBillingPlan): boolean {
  const plansCanNotToggleRequestedDataScreen = [
    BillingPlanTier.BILLING_PLAN_TIER_FREE,
    BillingPlanTier.BILLING_PLAN_TIER_LAUNCH,
  ];

  const plan_tier = user.organization?.organization_billing_plan?.billing_plan?.plan_tier;
  return plan_tier != null ? !plansCanNotToggleRequestedDataScreen.includes(plan_tier) : false;
}
