import React, { useCallback, useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Location } from "history";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

// THIRD PARTIES
import * as KoalaSDK from "@getkoala/browser";
import * as Sentry from "@sentry/react";
import { IntercomProvider, useIntercom } from "react-use-intercom";

// UTILITIES
import {
  fetchCurrentUser,
  fetchWithAuth,
  getAuthToken,
  hasAuthToken,
} from "../api-client/APIClient";
import { APICategory, INVOICE_FE_DISABLED_STATUSES } from "../models/Entities";
import INTEGRATION_CATEGORY_LIST from "../models/Helpers";

import {
  getIntegrationConfigurationPathForIndividualCategory,
  API_TESTER_PATH,
  BILLING_PATH,
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_ADD_PATH,
  CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH,
  CONFIGURATION_INTEGRATIONS_PATH,
  CONFIGURATION_COMMON_MODELS_PATH,
  CONFIGURATION_PATH,
  DASHBOARD_PATH,
  ONBOARDING_PATH,
  ISSUES_PATH,
  LINKED_ACCOUNTS_PATH,
  LOGIN_PATH,
  LOGIN_WITH_SSO_PATH,
  LOGS_PATH,
  NOTIFICATIONS_SETTINGS_PATH,
  ORGANIZATION_PATH,
  AUDIT_TRAIL_PATH,
  PASSWORD_RESET_PATH,
  PASSWORD_SEND_RESET_PATH,
  PROFILE_PATH,
  REFERRALS_PATH,
  RESPONSIBLE_DISCLOSURE_PATH,
  SAML_NO_ACCOUNT_FOUND,
  SAML_RELAY_PATH,
  SECURITY_PATH,
  SIGNUP_PATH,
  useQuery,
  API_KEYS_PATH,
  SETTINGS_PATHS,
} from "./RouterUtils";

// CONTEXT
import { UserWithBillingPlan } from "../components/context/AppContext";
import AppContextProvider from "../components/context/AppContextProvider";

// WRAPPERS
import LeftNavigationBar from "../components/portal/LeftNavigationBar";
import PortalPageContainer from "../components/portal/PortalPageContainer";
import PortalPageHeaderWrapper from "../components/portal/PortalPageHeaderWrapper";

// AUTH
import SignUpScreen from "../components/authentication/signup/SignUpScreen";
import Login from "../components/authentication/login/Login";
import ResetPasswordScreen from "../components/authentication/login/ResetPasswordScreen";
import SendResetPasswordScreen from "../components/authentication/login/SendResetPasswordScreen";
import SendSsoUrlScreen from "../components/authentication/login/SendSsoUrlScreen";
import SamlNoAccountFound from "../components/authentication/saml/SamlNoAccountFound";
import SamlRedirectScreen from "../components/authentication/saml/SamlRedirectScreen";

// ONBOARDING
import DashboardOnboarding from "../components/get-started/DashboardOnboarding";

// DASHBOARD
import Dashboard from "../components/dashboard/Dashboard";

// CONFIGURE
import IntegrationsManagementIndividualIssuePageV2 from "../components/integrations-management/issues/detail-page/IntegrationsManagementIndividualIssuePageV2";
import IntegrationsManagementIssuesPage from "../components/integrations-management/issues/IntegrationsManagementIssuesPage";
import LinkedAccountsTab from "../components/integrations-management/linked-accounts/LinkedAccountsTab";

import ConfigurationIntegrationsPageWrapper from "../components/configuration/integrations/ConfigurationIntegrationsPageWrapper";
import ConfigurationIntegrationsPage from "../components/configuration/integrations/ConfigurationIntegrationsPage";
import ConfigurationCustomIntegrations from "../components/configuration/integrations/custom-integrations/ConfigurationCustomIntegrations";
import ConfigurationEditCustomIntegrations from "../components/configuration/integrations/custom-integrations/ConfigurationEditCustomIntegrations";

import ConfigurationCommonModelsFullPage from "../components/configuration/common-models/ConfigurationCommonModelsFullPage";
import ConfigurationTab from "../components/configuration/ConfigurationTab";

// DEVELOP
import APIKeysPage from "../components/integrations-management/api-keys/APIKeysPage";
import IntegrationsManagementRequestRunnerPage from "../components/integrations-management/api-tester/IntegrationsManagementRequestRunnerPage";

// ACCOUNT SETTINGS
import BillingPage from "../components/settings/billing/BillingPage";
import NotificationSettingsPage from "../components/settings/notifications/NotificationSettingsPage";
import OrganizationPage from "../components/settings/organization/OrganizationPage";
import Profile from "../components/settings/profile/Profile";
import Referrals from "../components/settings/Referrals";
import SecurityPage from "../components/settings/security/SecurityPage";
import ErrorFallback from "../components/shared-components/ErrorFallback/ErrorFallback";
import ResponsibleDisclosureScreen from "../components/static-pages/ResponsibleDisclosureScreen";

// COMPONENTS
import AnnouncementBanner from "../components/portal/announcements/AnnouncementBanner";
import AddPaymentMethodModal from "../components/settings/billing/components/AddPaymentMethodModal";
import UpdatedTermsModal from "../components/portal/announcements/UpdatedTermsModal";

import { DashboardOnboardingStatus } from "../components/get-started/DashboardOnboardingWrapper";
import { BannerToShow } from "../components/settings/billing/components/DashboardBanners";
import { isRouteDisabled } from "../components/shared-components/utils/SharedComponentUtils";
import ComponentForFeature, { isFeatureEnabled } from "./ComponentForFeature";
import G2SurveyModal from "../components/portal/announcements/G2SurveyModal";
import { isBillingPlanLaunchPlan } from "../components/settings/billing/BillingUtils";
import AuditLogsPage from "../components/settings/audit-logs/AuditLogsPage";
import MergeFlagWrapper from "../components/shared-components/MergeFlagWrapper";
import { MergeFlagFeature } from "../components/shared-components/hooks/useMergeFlag";
import SettingsPage from "../components/settings/SettingsPage";
import GatedRoute from "./GatedRoute";

const getModalForIdentifier = (modalIdentifier: null | string) => {
  // Query string driven modals - use for flows
  switch (modalIdentifier) {
    case "addPaymentMethod":
      return <AddPaymentMethodModal onHide={() => {}} />;
    default:
      return null;
  }
};

const removeTrailingSlash = (pathOrUrl: string) =>
  pathOrUrl.endsWith("/") && pathOrUrl.length > 1
    ? pathOrUrl.substring(0, pathOrUrl.length - 1)
    : pathOrUrl;

type AppContainerProps = {
  isAnnouncementBannerVisible?: boolean;
  isPricingBannerVisible: boolean;
  topResize: number;
};

const AppContainer = styled.div<AppContainerProps>`
  position: fixed;
  ${(props) => (props.isPricingBannerVisible ? `top: ${props.topResize}px;` : `top: 0px;`)}
  right: 0;
  bottom: 0;
  left: 0;
`;

const G2SurveyModalWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  z-index: 1000;
  max-width: 1009px;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 24px;
    width: 60%;
  }
`;

const localItemKey = `dismiss-banner-pricing-update-20210901`; // replace key for future announcements
const bannerInvalidDate = 1633071599000; // September 30, 2021, 23:59:59
const INTERCOM_APP_ID = "ybk0pfck";
const KOALA_ID = "pk_5b619f5c14b073c46da98601ea9303da4594";

const integrationsRoutes = (
  <Route path={CONFIGURATION_INTEGRATIONS_PATH}>
    <Switch>
      {INTEGRATION_CATEGORY_LIST.map((category: APICategory) => (
        <Route
          key={category}
          exact
          path={getIntegrationConfigurationPathForIndividualCategory(category)}
          render={() => <ConfigurationIntegrationsPage category={category} />}
        />
      ))}
      <Route
        exact
        path={CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH}
        render={() => (
          <ConfigurationIntegrationsPageWrapper>
            <ConfigurationCustomIntegrations />
          </ConfigurationIntegrationsPageWrapper>
        )}
      />
      <Route
        exact
        path={CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_ADD_PATH}
        render={() => (
          <ConfigurationIntegrationsPageWrapper>
            <ConfigurationEditCustomIntegrations />
          </ConfigurationIntegrationsPageWrapper>
        )}
      />
      <Route
        exact
        path={`${CONFIGURATION_INTEGRATIONS_CUSTOM_INTEGRATIONS_PATH}/:customIntegrationID/edit`}
        render={() => (
          <ConfigurationIntegrationsPageWrapper>
            <ConfigurationEditCustomIntegrations />
          </ConfigurationIntegrationsPageWrapper>
        )}
      />
      <Redirect
        to={getIntegrationConfigurationPathForIndividualCategory(INTEGRATION_CATEGORY_LIST[0])}
      />
    </Switch>
  </Route>
);

// Provides a route with configuration for Common Models underneath it
const commonModelsRoutes = (
  <Route path={CONFIGURATION_COMMON_MODELS_PATH}>
    <ConfigurationCommonModelsFullPage />
  </Route>
);

type PortalProps = {
  user: UserWithBillingPlan | undefined;
  setUser: (user: UserWithBillingPlan) => void;
};

const PortalContainer = ({ user, setUser }: PortalProps) => {
  const pricingBannerRef = useRef<HTMLInputElement>(null);
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });

  const [isAnnouncementBannerVisible, setIsAnnouncementBannerVisible] = useState<boolean>(false);
  const [bannerHeight, setBannerHeight] = useState<number | null>(null); // 56 px is the default height of the banner
  const [hasBannerLoaded, setBannerHasLoaded] = useState<boolean>(false);

  const [fetchedOnboardingStatus, setFetchedOnboardingStatus] =
    useState<DashboardOnboardingStatus | null>(null);

  const shouldHomePageDefaultToDashboard =
    (fetchedOnboardingStatus?.has_made_request &&
      fetchedOnboardingStatus?.has_successfully_linked_account &&
      fetchedOnboardingStatus?.has_opened_production_link &&
      fetchedOnboardingStatus?.has_pulled_common_model_data) ??
    false;

  const isLoadingOnboardingStatus = !fetchedOnboardingStatus;

  //hook to determine size of banner to properly adjust AppContainer upon resize
  useEffect(() => {
    const updateHeight = () => {
      if (!pricingBannerRef.current) {
        setBannerHasLoaded(!hasBannerLoaded);
        return;
      }
      setBannerHeight(pricingBannerRef.current.getBoundingClientRect().height);
    };
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [hasBannerLoaded]);

  useEffect(() => {
    fetchWithAuth({
      path: "/users/me/onboarding-status",
      method: "GET",
      onResponse: (data: DashboardOnboardingStatus) => {
        setFetchedOnboardingStatus(data);
      },
    });
  }, []);

  useEffect(() => {
    const item = localStorage.getItem(localItemKey);
    const nowInMs = new Date().valueOf();
    if (!item && nowInMs < bannerInvalidDate && isDesktopOrTablet) {
      setIsAnnouncementBannerVisible(true);
    }
  }, [isDesktopOrTablet]);

  const { update } = useIntercom();

  useEffect(() => {
    if (user) {
      update({
        userId: user.email,
        userHash: user.intercom_hmac,
      });

      window?.Vitally.init(
        "ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lJMU5UWm1OV0U1WmkxbE5UZGtMVFJpT0RVdE9ESmtNUzAyT1dVd01ETTBOekUzWVRFaUxDSmhjR2tpT2lKa05tVmlNMlU1WVMwME5ETmtMVFJpTVdZdFlUVmxaUzAyTm1NeFlUUmxNVGswTWpjaUxDSnpaV01pT2lJMllUSTNPR0ZoWXlKOS5uVTRkS1NiMkZrX0d1dGtqbmtEYzBHMW8wLXA3TkxZbVJ3OHlPeHVWNnV3Og==",
      );
      window?.Vitally?.account({
        accountId: user?.organization.id, // A unique ID assigned by your codebase to each account (i.e. a database ID)
        traits: {
          name: user?.organization.name, // A 'name' trait is required
          // Add any other traits here you'd like to attach to your accounts in Vitally
        },
      });

      window?.Vitally?.user({
        userId: user.email, // A unique ID assigned by your codebase to each user (i.e. a database ID)
        accountId: user?.organization?.id, // Same account ID used in Vitally.account above - used to associate the user to the account
        traits: {
          name: user.name,
          email: user.email,
          // Add any other traits here you'd like to attach to your users in Vitally
        },
      });

      window?.Vitally?.nps("survey", {
        productName: "Merge", // Specify the name of your product. It will be injected into the question: "👋 there! Quick question - how likely are you to recommend {productName} to others?"
        pimaryColor: "#121314",
      });
    }
  }, [user]);

  const dismissAnnouncementBanner = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const now = new Date().valueOf();
    localStorage.setItem(localItemKey, `${now}`); // retain dismissal timestamp for banner use case flexibility
    setIsAnnouncementBannerVisible(false);
  };

  if (!hasAuthToken()) {
    return <Redirect to={LOGIN_PATH} />;
  }

  if (!user) {
    return null;
  }

  /// /////////////////////////////
  /// /// Pricing Banner Methods & Constants  //////
  /// /////////////////////////////

  const isLaunchPlan = isBillingPlanLaunchPlan(
    user.organization.organization_billing_plan?.billing_plan,
  );

  const showUpgradedBanner = user.show_upgrade_billing_plan_banner && isLaunchPlan;

  const bannerStatus =
    showUpgradedBanner ||
    user.organization.invoice_overdue_status ||
    user.organization.billing_guardrails_status ||
    null;

  const showAnyBanner =
    bannerStatus !== null &&
    user.organization.production_linked_account_count !== undefined &&
    !user.is_pricing_banner_dismissed;

  /**
   * The core difference between production_linked_account_count and static_production_linked_account_count is one is
   * our moving average, while the other is the actual number that currently exists
   */
  const numberOfLinkedAccounts = user.organization.production_linked_account_count;
  const numberOfStaticLinkedAccounts = user.organization.static_production_linked_account_count;
  const dashboardDisabledForBilling =
    user.organization.invoice_overdue_status &&
    INVOICE_FE_DISABLED_STATUSES.includes(user.organization.invoice_overdue_status);

  // const isInvoiceDueDateNotNull = user.organization.invoice_due_date !== null;
  function formatInvoiceDueDate(dateString?: string): string {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { month: "long", day: "numeric" };
    return `due ${date.toLocaleDateString("en-US", options)}`;
  }

  function isInvoiceDueDateNull(string: string | undefined) {
    return string === null;
  }

  function dismissPricingBanner() {
    fetchWithAuth({
      path: "/users/me",
      method: "PATCH",
      body: {
        is_pricing_banner_dismissed: true,
      },
      onResponse: (data) => {
        setUser(data.user);
      },
    });
  }

  /// /////////////////////////////
  /// /// G2 MODAL BELOW  //////
  /// /////////////////////////////

  /**
   * Takes currentDate and lastDismissed date, compares the two to calculate a day difference
   * Per post-sales instructions, if the feature flag is enabled for the org we'll want these banners to appear a week after dismissal,
   * with a maximum of 4 dismissals.
   */
  const currentDateTime = new Date();
  const lastDismissedDate = new Date(user.g2_banner_last_dismiss_date) ?? null;
  const timeDiff = currentDateTime.getTime() - lastDismissedDate.getTime();
  const daysDiff = timeDiff / (1000 * 3600 * 24);

  const renderTimeBanner = daysDiff >= 7 && user.g2_banner_dismiss_count < 4;

  const displayBanner = isFeatureEnabled("is_g2_survey_enabled", user) && renderTimeBanner;

  // NOTE: do not remove div with ref={pricingBannerRef} - this takes the height of the banner and applies it to AppContext's height to move the entire dash down as the banner grows in size
  return (
    <>
      <AppContextProvider user={user} setUser={setUser}>
        <>
          <BannerToShow
            showAnyBanner={showAnyBanner}
            bannerStatus={bannerStatus}
            showUpgradedBanner={showUpgradedBanner}
            numberOfLinkedAccounts={numberOfLinkedAccounts}
            numberOfStaticLinkedAccounts={numberOfStaticLinkedAccounts}
            isInvoiceDueDateNull={isInvoiceDueDateNull(user.organization.invoice_due_date)}
            invoiceDueDate={formatInvoiceDueDate(user.organization.invoice_due_date)}
            pricingBannerRef={pricingBannerRef}
            dismiss={dismissPricingBanner}
          />
          {isAnnouncementBannerVisible && (
            <AnnouncementBanner dismissAnnouncementBanner={dismissAnnouncementBanner} />
          )}
        </>
        <AppContainer isPricingBannerVisible={showAnyBanner} topResize={bannerHeight ?? 56}>
          <LeftNavigationBar isAnnouncementBannerVisible={showAnyBanner} />
          <PortalPageContainer>
            <Sentry.ErrorBoundary
              onError={(error) => {
                if (process.env.REACT_APP_MERGE_ENV !== "PRODUCTION") {
                  console.error(error);
                }
              }}
              fallback={({ error }) => <ErrorFallback error={error} />}
            >
              {!isLoadingOnboardingStatus && (
                <Route
                  exact
                  path="/"
                  component={
                    shouldHomePageDefaultToDashboard ? () => Dashboard() : DashboardOnboarding
                  }
                />
              )}
              <Route exact path={DASHBOARD_PATH} component={() => Dashboard()} />

              <Route path={ONBOARDING_PATH} component={DashboardOnboarding} />

              <GatedRoute path={LINKED_ACCOUNTS_PATH} component={LinkedAccountsTab} />

              {!isRouteDisabled(user, LOGS_PATH) && !isRouteDisabled(user, ISSUES_PATH) && (
                <Switch>
                  <Route
                    path={LOGS_PATH}
                    children={({ match }: { match: any }) => (
                      <ComponentForFeature feature="is_webhook_search_enabled" match={match} />
                    )}
                  />
                  <Route
                    path={`${ISSUES_PATH}/:integrationIssueID`}
                    children={() => <IntegrationsManagementIndividualIssuePageV2 />}
                  />
                </Switch>
              )}

              <GatedRoute exact path={ISSUES_PATH}>
                <PortalPageHeaderWrapper title="Issues" isMaxWidthEnabled>
                  <IntegrationsManagementIssuesPage />
                </PortalPageHeaderWrapper>
              </GatedRoute>

              <GatedRoute exact path={API_KEYS_PATH}>
                <Row className="justify-content-center">
                  <Col className="col-12 col-lg-10 col-xl-8">
                    <PortalPageHeaderWrapper
                      title="API Keys"
                      subtitle="Access your production and development keys."
                      isMaxWidthEnabled
                    >
                      <APIKeysPage />
                    </PortalPageHeaderWrapper>
                  </Col>
                </Row>
              </GatedRoute>

              <GatedRoute exact path={API_TESTER_PATH}>
                <PortalPageHeaderWrapper
                  title="API Tester"
                  subtitle="Test the APIs of supported third-party platforms."
                  isMaxWidthEnabled
                >
                  <IntegrationsManagementRequestRunnerPage />
                </PortalPageHeaderWrapper>
              </GatedRoute>

              {/* End Integrations Management Pages */}

              {integrationsRoutes}
              {commonModelsRoutes}

              <GatedRoute path={CONFIGURATION_PATH} component={ConfigurationTab} />

              {/* Settings/Profile Pages */}
              <Route path={SETTINGS_PATHS}>
                <SettingsPage>
                  <>
                    <GatedRoute exact path={PROFILE_PATH} component={Profile} />
                    <GatedRoute exact path={SECURITY_PATH} component={SecurityPage} />
                    <GatedRoute exact path={ORGANIZATION_PATH} component={OrganizationPage} />
                    <MergeFlagWrapper
                      feature={MergeFlagFeature.MERGE_FLAG_ENABLE_AUDIT_LOG}
                      organizationId={user.organization.id}
                    >
                      <GatedRoute exact path={AUDIT_TRAIL_PATH} component={AuditLogsPage} />
                    </MergeFlagWrapper>

                    <GatedRoute
                      exact
                      path={NOTIFICATIONS_SETTINGS_PATH}
                      component={NotificationSettingsPage}
                    />
                    <GatedRoute exact path={REFERRALS_PATH} component={Referrals} />
                    <GatedRoute exact path={BILLING_PATH} component={BillingPage} />
                  </>
                </SettingsPage>
              </Route>

              {/* Reroute if the rest of dashboard is disabled */}
              {dashboardDisabledForBilling ? <Redirect to={BILLING_PATH} /> : null}
            </Sentry.ErrorBoundary>
            {displayBanner && (
              <Row className="justify-content-center">
                <G2SurveyModalWrapper>
                  <G2SurveyModal renderBanner={displayBanner} />
                </G2SurveyModalWrapper>
              </Row>
            )}
          </PortalPageContainer>
        </AppContainer>
        <UpdatedTermsModal />
      </AppContextProvider>
    </>
  );
};

const AppRouter = () => {
  const [user, setUser] = useState<UserWithBillingPlan>();
  const location = useLocation<Location>();
  const authToken = getAuthToken();

  // Don't attempt to load user on these pages.
  // Note that the Login page is excluded because the Login page will attempt to load user
  // and will redirect into the app on success, and will stay on login page on 403.
  const unauthenticatedPagePaths = [LOGIN_WITH_SSO_PATH, SAML_NO_ACCOUNT_FOUND, SAML_RELAY_PATH];

  const pathIsUnauthenticated = useCallback(
    (path?: string) => {
      if (!path) return false;
      const unauthPathsWithoutSlashes = unauthenticatedPagePaths.map(removeTrailingSlash);
      const currentPathWithoutSlash = removeTrailingSlash(path);
      return unauthPathsWithoutSlashes.includes(currentPathWithoutSlash);
    },
    [unauthenticatedPagePaths],
  );

  useEffect(() => {
    if (!user && !pathIsUnauthenticated(location.pathname)) {
      fetchCurrentUser(setUser);
    }
  }, [authToken, user, location.pathname, pathIsUnauthenticated]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({ email: user.email, id: `${user.id}` });
    }
  }, [user]);

  const fsLoaded = "FS" in window;
  useEffect(() => {
    if (user && fsLoaded && process.env.REACT_APP_MERGE_ENV === "PRODUCTION") {
      // @ts-ignore
      window.FS.identify(user.id?.toString(), {
        displayName: user.name,
        email: user.email,
      });
    }
  }, [user, fsLoaded]);

  useEffect(() => {
    if (user && process.env.REACT_APP_MERGE_ENV === "PRODUCTION") {
      KoalaSDK.load({
        project: KOALA_ID,
      }).then((koalaSDK) => {
        koalaSDK?.identify({ email: user.email, name: user.name });
      });
    }
  }, [user]);

  const query = useQuery();

  return (
    <>
      {query.get("modal") ? getModalForIdentifier(query.get("modal")) : null}
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <Switch>
          <Route exact path={SAML_RELAY_PATH} component={SamlRedirectScreen} />
          <Route exact path={PASSWORD_SEND_RESET_PATH} component={SendResetPasswordScreen} />
          <Route exact path={RESPONSIBLE_DISCLOSURE_PATH} component={ResponsibleDisclosureScreen} />
          <Route path={PASSWORD_RESET_PATH} render={() => <ResetPasswordScreen />} />
          <Route exact path={LOGIN_PATH} render={() => <Login setUser={setUser} />} />
          <Route exact path={SIGNUP_PATH} render={() => <SignUpScreen setUser={setUser} />} />
          <Route exact path={LOGIN_WITH_SSO_PATH} component={SendSsoUrlScreen} />
          <Route exact path={SAML_NO_ACCOUNT_FOUND} render={() => <SamlNoAccountFound />} />
          <Route render={() => <PortalContainer user={user} setUser={setUser} />} />
        </Switch>
      </IntercomProvider>
    </>
  );
};

export default AppRouter;
