import React from "react";
import { format } from "date-fns";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { FileText, RefreshCw, Trash } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { LinkedAccount, LinkedAccountStatus, PauseReason } from "../../../models/Entities";
import { displayNameForAPICategory } from "../../../models/Helpers";
import {
  navigateToLinkedAccountDetailPage,
  navigateToLinkedAccountLogsPage,
} from "../../../router/RouterUtils";
import { snakeCaseToFirstLetterUpperCase } from "../../../services";
import { spectrum } from "../../../styles/theme";
import IntegrationNameAndLogo from "../../configuration/integrations/IntegrationNameAndLogo";
import useAppContext from "../../context/useAppContext";
import ClickableContainer from "../../shared-components/ClickableContainer";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import MergeTable from "../../shared-components/MergeTable";
import MergeText, { TextType } from "../../shared-components/MergeText";
import { StatusBadge } from "../../shared-components/MergeBadges";
import { DuplicateAccountBadge } from "./shared/DuplicateLinkedAccountBanner";
import InfoIconWithTooltip from "../../shared-components/InfoIconWithTooltip";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";

const StyledTableCell = styled.td`
  && {
    padding: 0;
  }
`;

type StyledTableCellLinkProps = {
  noLeftPadding: boolean;
};
const StyledTableCellLink = styled(Link)<StyledTableCellLinkProps>`
  color: inherit;
  padding: 1rem;
  padding-left: ${(props) => (props.noLeftPadding ? "0px !important" : "1rem")};
  display: flex;
  &:hover {
    color: inherit;
  }
`;

const LargerFontTD = styled.td`
  &&& {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }
`;

// TO-DO: replace with --gray50 once design system stylesheet has been imported
const DateCell = styled.td`
  color: #939aa5;
`;

// TO-DO: replace with --gray40 once design system stylesheet has been imported
const IntegrationCell = styled.td`
  color: #b0b7c3;
  font-weight: 500;
`;

const ShortcutHeader = styled.th`
  margin-right: 68px !important;
`;

/**
 * Returns a table cell that links to a `linkedAccount`. May not link,
 * depending on the the cell needs. A click on the cell stops propagation
 * so that we don't also trigger the row's click handler with an in-cell
 * click on the link.
 */
export const LinkableTableCell = ({
  children,
  linkedAccount,
  toPath,
  noLeftPadding = false,
  ...rest
}: React.ComponentPropsWithoutRef<"td"> & {
  linkedAccount: LinkedAccount | null;
  toPath: string;
  noLeftPadding?: boolean;
}) => {
  const { user } = useAppContext();
  if (!linkedAccount || user.is_demo) {
    return <td {...rest}>{children}</td>;
  }
  return (
    <StyledTableCell {...rest} onClick={(event) => event.stopPropagation()}>
      <StyledTableCellLink to={toPath} noLeftPadding>
        {children}
      </StyledTableCellLink>
    </StyledTableCell>
  );
};

type LinkedAccountTableProps = {
  forceResync: (linkedAccount: LinkedAccount) => void;
  isLoading: boolean;
  linkedAccounts?: LinkedAccount[];
  isTestAccounts: boolean;
  setLinkedAccountToDelete: (linkedAccount: LinkedAccount) => void;
};

function LinkedAccountTable(props: LinkedAccountTableProps) {
  const { forceResync, isLoading, linkedAccounts, setLinkedAccountToDelete } = props;

  const { user, isUserPrivileged } = useAppContext();
  const history = useHistory();

  const header = (
    <>
      <th scope="col" className="linked-account-table-user-column">
        Organization
      </th>
      <th scope="col">App</th>
      <th scope="col">Category</th>
      <th scope="col">Linked</th>
      <ShortcutHeader scope="col" className="d-flex justify-content-end">
        Shortcuts
      </ShortcutHeader>
    </>
  );

  const linkedAccountStatus = (linkedAccount: LinkedAccount) => {
    const status =
      linkedAccount.status == LinkedAccountStatus.RELINK_NEEDED
        ? linkedAccount.status
        : linkedAccount.completed_at ?? linkedAccount.status;

    switch (status) {
      case LinkedAccountStatus.COMPLETE:
        return (
          <MergeText type={TextType.SUCCESS}>
            {snakeCaseToFirstLetterUpperCase(LinkedAccountStatus.COMPLETE)}
          </MergeText>
        );
      case LinkedAccountStatus.INCOMPLETE:
        if (linkedAccount.pause_reason === PauseReason.PRICING_PLAN_LIMIT) {
          return (
            <>
              <MergeText type={TextType.DANGER}>Not Synced</MergeText>
              <InfoIconWithTooltip
                text="Please upgrade your plan to continue syncing data from this account."
                iconStyle={{ color: "#E62837", position: "relative", top: "3px", left: "3px" }}
              />
            </>
          );
        }
        return <MergeText type={TextType.WARNING}>Incomplete Link</MergeText>;
      case LinkedAccountStatus.RELINK_NEEDED:
        return (
          <div className="d-flex align-items-center">
            <MergeText type={TextType.DANGER}>
              {snakeCaseToFirstLetterUpperCase(LinkedAccountStatus.RELINK_NEEDED)}
            </MergeText>

            {linkedAccount.status_detail && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`${linkedAccount.id}`}>{linkedAccount.status_detail}</Tooltip>
                }
              >
                <i className="fe fe-info text-gray-50 deprecated-ml-2" />
              </OverlayTrigger>
            )}
          </div>
        );
      default:
        return format(new Date(linkedAccount.completed_at), "MMM dd - hh:mm a");
    }
  };

  // This is an array containing all the rows for Linked Accounts if they exist
  const linkedAccountsRows =
    linkedAccounts &&
    linkedAccounts?.length > 0 &&
    linkedAccounts.map((linkedAccount) => (
      <React.Fragment key={linkedAccount.id}>
        <tr
          className="table-link"
          key={linkedAccount.id}
          style={{ borderBottomWidth: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            navigateToLinkedAccountDetailPage(history, linkedAccount);
          }}
        >
          <LargerFontTD>
            {user.is_demo && <StatusBadge status="Demo" className="deprecated-mr-3" />}
            {linkedAccount?.is_duplicate && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="duplicate-linked-account-detected">
                    Duplicate Linked Account Detected
                  </Tooltip>
                }
              >
                <DuplicateAccountBadge size={16} className="deprecated-mr-3 my-auto" />
              </OverlayTrigger>
            )}
            {linkedAccount.end_user.organization_name}
          </LargerFontTD>
          <LargerFontTD>
            <IntegrationNameAndLogo integration={linkedAccount.integration} />
          </LargerFontTD>
          <IntegrationCell>{displayNameForAPICategory(linkedAccount.category)}</IntegrationCell>
          <DateCell>{linkedAccountStatus(linkedAccount)}</DateCell>
          <td className="text-right d-flex align-items-center justify-content-end">
            {isUserPrivileged && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="related-logs-tooltip">View related logs</Tooltip>}
              >
                <div className="mr-2">
                  <SmallWhiteButton
                    variant="singleIcon"
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      navigateToLinkedAccountLogsPage(history, linkedAccount);
                    }}
                  >
                    <FileText size={16} />
                  </SmallWhiteButton>
                </div>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="resync-tooltip">
                  {user.is_demo
                    ? "Deleting Linked Accounts is not available in demo state!"
                    : "Delete linked account"}
                </Tooltip>
              }
            >
              <div className="mr-2">
                <SmallWhiteButton
                  variant="singleIcon"
                  onClick={(event: { stopPropagation: () => void }) => {
                    if (user.is_demo) {
                      return;
                    }

                    event.stopPropagation();
                    setLinkedAccountToDelete(linkedAccount);
                  }}
                >
                  <Trash size={16} />
                </SmallWhiteButton>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="resync-tooltip">
                  {linkedAccount.completed_at
                    ? "Force resync"
                    : "Force resync is not available for incomplete Linked Accounts!"}
                </Tooltip>
              }
            >
              <div>
                <SmallWhiteButton
                  variant="singleIcon"
                  onClick={(event: { stopPropagation: () => void }) => {
                    event.stopPropagation();
                    if (!linkedAccount.completed_at) {
                      return;
                    }
                    forceResync(linkedAccount);
                  }}
                >
                  <RefreshCw size={16} />
                </SmallWhiteButton>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">Review linked account details</Tooltip>}
            >
              <ClickableContainer>
                <span className="black fe fe-chevron-right deprecated-ml-3" />
              </ClickableContainer>
            </OverlayTrigger>
          </td>
        </tr>
      </React.Fragment>
    ));

  const content = (
    <>
      {!isLoading && linkedAccounts ? (
        linkedAccounts.length ? (
          linkedAccountsRows
        ) : (
          <tr>
            <td colSpan={5}>
              <EmptyStateWrapper isTable title="No Linked Accounts" />
            </td>
          </tr>
        )
      ) : (
        Array.from({ length: 25 }).map((_, i) => (
          <tr key={`linked-account-skel-row-${i}`} className="table-row">
            <td className="text-gray-50">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td className="text-gray-50">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </td>
            <td className="text-gray-50">
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td />
          </tr>
        ))
      )}
    </>
  );

  return <MergeTable header={header} content={content} hasMorePaddingOnFirstElement />;
}

export default LinkedAccountTable;
