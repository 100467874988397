import React, { useState, useRef, Dispatch, SetStateAction } from "react";
import IntegrationNameAndLogo from "../IntegrationNameAndLogo";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";
import FieldMappingDropdownChild from "./FieldMappingDropdownChild";
import {
  FieldMappingCreationAndEditDict,
  CreateOverriddenCommonModelInstanceDict,
  OverriddenCommonModelInstance,
  FieldMappingOptions,
} from "../../../../models/Entities";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
import useOnClickOutside from "use-onclickoutside";
import {
  StyledTypeaheadDiv,
  FitDiv,
  MinHeightDiv,
  TruncatedOriginField,
  AddFieldMappingButton,
  StyledMethodDiv,
  StyledEndpointV2,
  StyledDash,
} from "./FieldMappingByIntegrationRow";
import { fetchIntegrationWideOverrideOptions } from "../../../../api-client/APIClient";
import MoreHorizontalPopover from "../../../shared-components/MoreHorizontalPopover";
import {
  deleteOverriddenCommonModelInstance,
  editOverriddenCommoModelInstance,
} from "./utils/FieldMappingUtils";
import { filterFieldMappingOptions } from "../../../integrations-management/linked-accounts/detail-page/field-mappings/common-model-overrides/CommonModelOverrideForm";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import { Plus } from "lucide-react";
type CommonModelOverrideByIntegrationRowProps = {
  commonModelOverrideTargetID: string;
  commonModelOverrideTargetType: string | null;
  integrationID: string;
  organizationID: string;
  commonModelOverrideCommonModelName: string;
  name: string;
  squareImage: string | undefined;
  commonModelInstanceID: string | null;
  originField: string | null;
  remoteEndpointPath: string | null;
  display_name: string | null;
  setIsSaveOverrideModalOpen: Dispatch<SetStateAction<boolean>>;
  setOverrideCommonModelToSave: Dispatch<
    SetStateAction<CreateOverriddenCommonModelInstanceDict | undefined>
  >;
  fieldTraversalPath: string[] | null;
  deleteInstance: (id: string) => void;
  updateCommonModelInstance: (id: string, updatedInstance: OverriddenCommonModelInstance) => void;
};
const CommonModelOverrideByIntegrationRow = ({
  commonModelOverrideTargetID,
  commonModelOverrideTargetType,
  integrationID,
  organizationID,
  commonModelOverrideCommonModelName,
  name,
  squareImage,
  commonModelInstanceID,
  originField,
  fieldTraversalPath,
  remoteEndpointPath,
  display_name,
  setIsSaveOverrideModalOpen,
  setOverrideCommonModelToSave,
  deleteInstance,
  updateCommonModelInstance,
}: CommonModelOverrideByIntegrationRowProps) => {
  const [isAddingOverride, setisAddingOverride] = useState(false);
  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingOptions>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict[]>([]);
  const ref = useRef<HTMLDivElement>(null);

  const fieldMappingOptionsLoading = fieldMappingOptions === undefined;
  useOnClickOutside(ref, (event) => {
    if ((event.target as HTMLElement).className !== "custom-control-label") {
      setisAddingOverride(false);
      setIsEditing(false);
      setOriginFields([]);
    }
  });

  return (
    <>
      <tr className="bg-white">
        <td className="pl-6">
          <div className="">
            <IntegrationNameAndLogo
              integration={{
                id: name,
                name: name,
                square_image: squareImage,
              }}
            />
          </div>
        </td>
        <td colSpan={isAddingOverride || isEditing ? 2 : 1}>
          {isAddingOverride || isEditing ? (
            <StyledTypeaheadDiv ref={ref}>
              <MergeTypeahead
                id="typeahead"
                disabled={fieldMappingOptionsLoading}
                multiple={false}
                positionFixed
                options={
                  fieldMappingOptions && !fieldMappingOptionsLoading
                    ? filterFieldMappingOptions(
                        commonModelOverrideCommonModelName,
                        fieldMappingOptions,
                        commonModelOverrideTargetType,
                      )
                    : []
                }
                includeChevronDown={!fieldMappingOptionsLoading}
                inputProps={{ autoComplete: "none" }}
                placeholder={"Search fields..."}
                selected={originFields}
                onChange={(selectedOriginFields) => {
                  if (selectedOriginFields.length > 0) {
                    setOriginFields(selectedOriginFields);
                    if (isAddingOverride) {
                      const commonModelPostBody = {
                        integration_id: integrationID,
                        organization_id: organizationID,
                        override_common_model_target_id: commonModelOverrideTargetID,
                        field_traversal_path: selectedOriginFields[0].traversal_path.split("."),
                        api_endpoint_id: selectedOriginFields[0].api_endpoint_id,
                        display_name: selectedOriginFields[0]?.display_name,
                        origin_type: selectedOriginFields[0].type,
                      };
                      setOverrideCommonModelToSave(commonModelPostBody);
                      setIsSaveOverrideModalOpen(true);
                    }
                    if (isEditing && commonModelInstanceID) {
                      const patchBody = {
                        api_endpoint_id: selectedOriginFields[0].api_endpoint_id,
                        field_traversal_path: selectedOriginFields[0].traversal_path.split("."),
                        display_name: selectedOriginFields[0]?.display_name,
                      };
                      editOverriddenCommoModelInstance(
                        commonModelInstanceID,
                        patchBody,
                        (upatedInstance) => {
                          updateCommonModelInstance(integrationID, upatedInstance);
                          setIsEditing(false);
                          showSuccessToast("Updated Override instance");
                        },
                        () => {
                          showErrorToast("Unable to update override Instance");
                        },
                      );
                    }
                  }
                }}
                labelKey={(option: FieldMappingCreationAndEditDict) => option?.display_name ?? ""}
                renderMenuItemChildren={(option) => (
                  <FieldMappingDropdownChild
                    originField={option.display_name}
                    originType={option.type}
                    remoteEndpointMethod={option.api_endpoint_method ?? "GET"}
                    remoteEndpointPath={option.api_endpoint_path ?? "/"}
                  />
                )}
              />
            </StyledTypeaheadDiv>
          ) : fieldTraversalPath && fieldTraversalPath.length > 0 ? (
            <MinHeightDiv>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="tooltip-top">
                    {fieldTraversalPath[fieldTraversalPath.length - 1]}
                  </Tooltip>
                }
              >
                <TruncatedOriginField className="text-[13px] font-mono leading-[24px]">
                  {fieldTraversalPath[fieldTraversalPath.length - 1]}
                </TruncatedOriginField>
              </OverlayTrigger>
            </MinHeightDiv>
          ) : (
            <AddFieldMappingButton
              className="bg-gray-0"
              onClick={() => {
                fetchIntegrationWideOverrideOptions(
                  integrationID,
                  commonModelOverrideTargetID,
                  commonModelOverrideCommonModelName,
                  setFieldMappingOptions,
                );
                setisAddingOverride(true);
              }}
            >
              <div className="d-flex items-center">
                <Plus size={12} className="font-semibold text-gray-70" />
                <span className="ml-[3px] font-medium text-gray-70 text-[12px]">
                  Common Model override
                </span>
              </div>
            </AddFieldMappingButton>
          )}
        </td>
        {!isAddingOverride && !isEditing && (
          <td width={278}>
            {remoteEndpointPath ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{remoteEndpointPath}</Tooltip>}
              >
                <FitDiv className="d-flex">
                  <StyledMethodDiv className="mr-1.5 font-semibold text-[10px] leading-[22px]">
                    {" "}
                    GET
                  </StyledMethodDiv>
                  <StyledEndpointV2 className="text-[12px] text-gray-60">
                    {remoteEndpointPath}
                  </StyledEndpointV2>
                </FitDiv>
              </OverlayTrigger>
            ) : (
              <StyledDash className="text-[14px] text-gray-40">—</StyledDash>
            )}
          </td>
        )}
        {remoteEndpointPath ? (
          <td>
            <MoreHorizontalPopover
              menuOptions={
                <>
                  <Dropdown.Item
                    className="hover:bg-gray-0 text-sm  !font-normal"
                    onSelect={() => {
                      fetchIntegrationWideOverrideOptions(
                        integrationID,
                        commonModelOverrideTargetID,
                        commonModelOverrideCommonModelName,
                        setFieldMappingOptions,
                      );
                      setIsEditing(true);
                    }}
                  >
                    {" "}
                    Edit Mapping{" "}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="hover:bg-gray-0 text-sm text-red-50 !font-normal"
                    onSelect={() => {
                      if (commonModelInstanceID) {
                        deleteOverriddenCommonModelInstance(
                          commonModelInstanceID,
                          () => {
                            deleteInstance(integrationID);
                            showSuccessToast("Deleted Common Model override");
                          },
                          () => {
                            showErrorToast("Unable to delete Common Model override");
                          },
                        );
                      }
                    }}
                  >
                    {" "}
                    Delete Mapping{" "}
                  </Dropdown.Item>
                </>
              }
            />
          </td>
        ) : (
          <td></td>
        )}
      </tr>
    </>
  );
};

export default CommonModelOverrideByIntegrationRow;
