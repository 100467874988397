import React from "react";
import { Card, Container } from "react-bootstrap";
import { MERGE_APP_PATH } from "../../router/RouterUtils";
import { CardHeaderTitle } from "../shared-components/MergeText";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";

const ResponsibleDisclosureScreen = () => {
  return (
    <Container className="deprecated-mt-4">
      <Card>
        <Card.Header>
          <CardHeaderTitle>Merge Responsible Vulnerability Disclosure Program</CardHeaderTitle>
        </Card.Header>
        <Card.Body>
          <DeprecatedH2>Promise</DeprecatedH2>
          <p>
            Merge is committed to customers and stakeholders potentially impacted by security
            vulnerabilities. We will always do our best to keep you informed about our progress on
            reported vulnerabilities.
          </p>

          <DeprecatedH2>Scope</DeprecatedH2>
          <p>
            Vulnerabilities can be submitted pertaining to our web application located at{" "}
            {MERGE_APP_PATH} and any of our APIs and exposed services.
          </p>

          <DeprecatedH2>Program Rules</DeprecatedH2>
          <ul>
            <li>
              Make a good faith effort to avoid privacy violations, destruction of data, and
              interruption or degredation of our service.
            </li>
            <li>Do not run any brute force attacks.</li>
            <li>Please provide a detailed report for each issue with reproducible steps.</li>
            <li>Phishing and other forms of social engineering are prohibited.</li>
            <li>Do not test using any Merge internal tools or employee accounts.</li>
            <li>
              Only interact with accounts you own or with the explicit written permission of the
              account holder.
            </li>
          </ul>

          <DeprecatedH2>Process</DeprecatedH2>
          <p>
            Please report vulnerabilities as soon as they are discovered to security@merge.dev.
            We'll get back to you within 3 business days.
          </p>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ResponsibleDisclosureScreen;
