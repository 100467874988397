import React, { useState } from "react";
import useAppContext from "../../context/useAppContext";
import { UserType } from "../../../models/Entities";
import { AuditLogsBlock, NoPermissions } from "./components";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import UpsellModal from "../../shared-components/UpsellModal";
import MergeTable from "../../shared-components/MergeTable";
import TableSkeletonLoader from "../../integrations-management/shared/TableSkeletonLoader";
import AuditLogsLoadingStateTable from "./components/AuditLogsLoadingStateTable";
import { ReactComponent as FDAuditLogs } from "../../../assets/svg/feature-discovery-audit-logs.svg";

const AuditLogsPage = () => {
  // hooks
  const { user } = useAppContext();
  const { productRestrictions } = useProductRestrictions();

  const [showUpsellModal, setShowUpsellModal] = useState(true);

  // derived state
  const isAdmin = user.type === UserType.admin_with_billing;
  const auditLogEnabled = productRestrictions?.audit_log_enabled;

  const MODAL_TOP_VALUE = "24px";
  const MODAL_WIDTH_VALUE = "476px";

  if (!auditLogEnabled) {
    return (
      <div>
        <UpsellModal
          modalTitle="Audit Trail"
          customImage={<FDAuditLogs className="-ml-2 mb-6" />}
          modalBodyText={
            <>
              <p>Need to know who did what, where, and when?</p>
              <p>Track all activity for your organization with a detailed Audit Trail.</p>
              <div className="text-sm text-gray-50 mt-3 mb-6">
                Unlock this feature with our Enterprise plan.
              </div>
            </>
          }
          includeLearnMoreButton={false}
          includeModalCloseOption={false}
          showModal={showUpsellModal}
          setShowModal={setShowUpsellModal}
          modalTopValue={MODAL_TOP_VALUE}
          modalWidthValue={MODAL_WIDTH_VALUE}
          upsellButtonId="upsell_button_audit_trail"
          learnMoreButtonId="learn_more_button_audit_trail"
        />
        <div style={{ opacity: 0.5 }}>
          <AuditLogsLoadingStateTable />
        </div>
      </div>
    );
  }

  if (!isAdmin) {
    return <NoPermissions />;
  }

  return <AuditLogsBlock />;
};

export default AuditLogsPage;
