import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { IntegrationIssueMetadata } from "../../IntegrationsManagementEntities";
import { palette } from "../../../../styles/theme";
import styled from "styled-components";

const DetectedErrorsText = styled.b`
  font-size: 14px;
  line-height: 20px;
  color: ${palette.slate};
`;

interface Props {
  issueMetadata: Array<IntegrationIssueMetadata>;
}

const IssueErrorDetailsBody = ({ issueMetadata }: Props) => {
  const seenDetails: Set<String> = new Set();
  return (
    <>
      <Card.Body className="deprecated-pl-4 deprecated-pt-4">
        <DetectedErrorsText>Detected Errors</DetectedErrorsText>
        {issueMetadata.map((metadata_element) => {
          {
            if (
              metadata_element.user_facing_error_detail &&
              !seenDetails.has(metadata_element.user_facing_error_detail)
            ) {
              seenDetails.add(metadata_element.user_facing_error_detail);
              return (
                <div className="issue-details-container">
                  <Row>
                    <Col xs="11">{metadata_element.user_facing_error_detail}</Col>
                  </Row>
                </div>
              );
            } else {
              return <></>;
            }
          }
        })}
      </Card.Body>
    </>
  );
};

export default React.memo(IssueErrorDetailsBody);
