import React from "react";
import styled from "styled-components";
import useAppContext from "../../../context/useAppContext";
import { BottomBorderDiv } from "./IndividualIssueActivityV2";
import { Arrow, ArrowDirection } from "../../logs/table/DirectionCell";
import { RefreshCw } from "lucide-react";
import { ChevronRight } from "lucide-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { APIRequestLogEntry } from "../../IntegrationsManagementEntities";
import { spectrum } from "../../../../styles/theme";
import { formatDate } from "../../../../models/Helpers";

const WhiteCircle = styled.div`
  background: white;
  border-radius: 23px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const UrlContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
  font-size: 12px;
  margin-top: 2px;
`;

const StyledChevronRight = styled(ChevronRight)`
  cursor: pointer;
`;

const LogsContainer = styled.div`
  cursor: pointer;
  &:hover {
    background: ${spectrum.slate0};
  }
`;

const LogInfoContainer = styled.div`
  min-width: 0;
`;

const DateContainer = styled.div`
  white-space: nowrap;
`;
type IndividualIssueLogActivityProps = {
  issueLog: APIRequestLogEntry;
  testIssueIntegration: (log: APIRequestLogEntry, isDemo?: boolean) => void;
  isLastRow: boolean;
  setSelectedLogEntry: (id: string) => void;
};
const IndividualIssueLogActivity = ({
  issueLog,
  testIssueIntegration,
  isLastRow,
  setSelectedLogEntry,
}: IndividualIssueLogActivityProps) => {
  const { id, response_code, url, created_at, linked_account, method } = issueLog;
  const { isUserPrivileged, user } = useAppContext();
  return (
    <LogsContainer
      onClick={() => {
        setSelectedLogEntry(id);
      }}
    >
      <BottomBorderDiv
        showBottomBorder={!isLastRow}
        className="d-flex pt-3 pb-3 pr-4 pl-4 align-items-center"
      >
        <div className="text-nowrap text-xs font-medium pl-0 text-red-40 tabular-nums">
          {response_code} Error
        </div>
        <LogInfoContainer className="d-flex align-items-center ml-2 mr-3 pl-1 pr-1 pt-1 pb-1 flex-grow-1 flex-shrink-1 bg-gray-0 rounded-full">
          <img
            src={linked_account?.integration?.square_image}
            width="20"
            height="20"
            className="deprecated-rounded"
            alt=""
          />
          <div className="pl-2 pr-2">
            <Arrow
              direction={ArrowDirection.LEFT}
              tooltip={`Merge to ${linked_account?.integration?.name}`}
            />
          </div>
          <div className="text-xs text-blue mr-1 mt-0.5">{method}</div>
          <UrlContainer>{url}</UrlContainer>
          {isUserPrivileged && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">Retry request</Tooltip>}
            >
              <WhiteCircle
                className="ml-auto pr-1 pl-1 pt-1 pb-1 d-flex align-items-center justify-content-center"
                onClick={
                  user?.is_demo
                    ? (e) => {
                        e.stopPropagation();
                        testIssueIntegration(issueLog, true);
                      }
                    : (e) => {
                        e.stopPropagation();
                        testIssueIntegration(issueLog, false);
                      }
                }
              >
                <RefreshCw size={11} />
              </WhiteCircle>
            </OverlayTrigger>
          )}
        </LogInfoContainer>
        <div className="d-flex pr-0 align-items-center">
          <DateContainer className="mr-2 font-inter font-normal text-xs text-gray-50 tabular-nums">
            {formatDate(created_at)}
          </DateContainer>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top"> Review log details</Tooltip>}
          >
            <div className="mb-0.5">
              <StyledChevronRight className="text-gray" size={12} />
            </div>
          </OverlayTrigger>
        </div>
      </BottomBorderDiv>
    </LogsContainer>
  );
};
export default IndividualIssueLogActivity;
