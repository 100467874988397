import { APICategory } from "@merge-api/merge-javascript-shared";
import React, { useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import styled from "styled-components";
import { fetchCommonModelsFromCategory } from "../../../../api-client/categories/CommonModelTogglesAPIClient";
import { fetchComponentSchemasForCategory } from "../../../../api-client/categories/ComponentSchemasAPIClient";

import {
  createSortedModelsMap,
  SortedModelsMap,
} from "../../../configuration/common-models/CommonModelUtils";
import Stack from "../../../integrations-management/linked-accounts/shared/Stack";
import MergeTable from "../../../shared-components/MergeTable";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

const StyledBadgeText = styled(DeprecatedH6)`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--gray40);
`;

const StyledModelText = styled(DeprecatedH6)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray30);
`;

const SampleSyncHistoryTable = () => {
  const [commonModels, setCommonModels] = useState<SortedModelsMap>({});
  //   const [schemas, setSchemas] = useState<Schemas>({});

  /**
   * Gets the Common Models on page load and sets them in the UI
   */
  useEffect(() => {
    fetchCommonModelsFromCategory(APICategory.ats)
      .then((result) => {
        if (result.status === "success") {
          return createSortedModelsMap(result.data);
        } else {
          return null;
        }
      })
      .then((commonModelsMap) => {
        if (commonModelsMap) {
          fetchComponentSchemasForCategory(APICategory.ats).finally(() => {
            setCommonModels(commonModelsMap);
          });
        }
      });
  }, []);

  // const syncStatusRows = syncStatusesSorted?.map((syncStatus) => {
  //     return (
  //       <tr key={syncStatus.model_id}>
  //         <td>
  //           <b>{syncStatus.model_name}</b>
  //         </td>

  // Object.values(commonModels)
  //                   .filter((model) => model.has_endpoints)
  //                   .map((model) =>

  const syncStatusRows = Object.values(commonModels)
    .filter((model) => model.has_endpoints)
    .map((model) => {
      return (
        <tr key={model.name}>
          <td>
            <b>
              <StyledModelText>{model.name}</StyledModelText>
            </b>
          </td>
          <td>
            <Badge
              variant="light"
              className="d-inline-flex align-items-center justify-content-start"
            >
              <StyledBadgeText className="p-0 m-0">Awaiting connection</StyledBadgeText>
            </Badge>
          </td>
          <td>—</td>
        </tr>
      );
    });

  const header = (
    <>
      <th scope="col">Model</th>
      <th scope="col">Status</th>
      <th scope="col">Last Sync Start</th>
    </>
  );

  const content = <>{syncStatusRows}</>;

  return (
    <div>
      <Stack $isVertical className="deprecated-mb-4">
        <MergeTable header={header} content={content} />
      </Stack>
    </div>
  );
};

export default SampleSyncHistoryTable;
