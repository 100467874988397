import React, { useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Row, Col, Dropdown } from "react-bootstrap";
import { SectionHeaderWrapper } from "../../../shared-components/MergeLayouts";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";
import ConfigurationFieldMappingsPage from "./ConfigurationFieldMappingsPage";
import ErrorFallback from "../../../shared-components/ErrorFallback/ErrorFallback";
import { displayNameForAPICategory } from "../../../../models/Helpers";
import INTEGRATION_CATEGORY_LIST from "../../../../models/Helpers";

import {
  DOCS_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  openInNewTab,
} from "../../../../router/RouterUtils";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingTarget,
  OverrideModelAndFieldOptions,
  OverriddenCommonModelTarget,
} from "../../../../models/Entities";
import { APICategory } from "../../../../models/Entities";
import { SectionHeaderTitleInlineDiv } from "./utils/FieldMappingUtils";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { BookOpen } from "lucide-react";
import { Schemas } from "../../common-models/CommonModelToggleTypes";

// Goes to API docs for the category on landing page
const CategoryDropdownToggle = styled(Dropdown.Toggle)`
  max-height: 40px;
  width: 200px;
  gap: 10px;
`;

const StyledCol = styled(Col)`
  max-width: 100%;
`;

const IntegrationDocsButton = (
  <SmallWhiteButton
    onClick={() => openInNewTab(DOCS_FIELD_MAPPINGS_TARGET_FIELDS_PATH)}
    leftIcon={<BookOpen size={12} />}
  >
    Field Mapping Docs
  </SmallWhiteButton>
);

type ConfigurationFieldMappingsTargetFieldsPageProps = {
  commonModelOverrideTargets: OverriddenCommonModelTarget[] | undefined;
  commonModelOverrideOptions: OverrideModelAndFieldOptions | undefined;
  schemas: Schemas;
  setCommonModelOverrideTargets: Dispatch<
    SetStateAction<OverriddenCommonModelTarget[] | undefined>
  >;
  fieldMappingTargets: FieldMappingTarget[] | undefined;
  fieldMappingConfigurations: any;
  selectedCategory: APICategory;
  setSelectedCategory: Dispatch<SetStateAction<APICategory>>;
  refreshFieldMappingsAndConfigurations: () => void;
  commonModelNames: string[];
  updateFieldMappingTargets: (updatedFieldMappingTarget: FieldMappingTarget) => void;
  updateFieldMappingConfigurations: (
    updateFieldMappingConfigurations: FieldMappingCommonModelConfiguration,
  ) => void;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  hasError: boolean;
  setHasError: Dispatch<SetStateAction<boolean>>;
};

const ConfigurationFieldMappingsTargetFieldsPage = ({
  commonModelOverrideTargets,
  commonModelOverrideOptions,
  schemas,
  setCommonModelOverrideTargets,
  fieldMappingTargets,
  fieldMappingConfigurations,
  selectedCategory,
  setSelectedCategory,
  refreshFieldMappingsAndConfigurations,
  commonModelNames,
  updateFieldMappingTargets,
  updateFieldMappingConfigurations,
  isLoading,
  setIsLoading,
  hasError,
  setHasError,
}: ConfigurationFieldMappingsTargetFieldsPageProps) => {
  const [selectedSearchOptions, setSelectedSearchOptions] = useState<Array<string>>([]);

  return (
    <>
      <Row>
        <Col>
          <SectionHeaderWrapper
            title={<SectionHeaderTitleInlineDiv>Target Fields</SectionHeaderTitleInlineDiv>}
            subtitle={
              <>
                <p className="mb-3">
                  Add fields to your Common Models to be organization-wide targets for Field Mapping
                  in Dashboard and Link.
                </p>
                <p className="mb-3">Select a target field to add Field Mappings by integration.</p>
              </>
            }
            headerRightHandContent={IntegrationDocsButton}
          >
            <div>
              <Row className="mt-4">
                <Col>
                  {hasError ? (
                    <ErrorFallback />
                  ) : (
                    <>
                      <Row className="pb-3">
                        <StyledCol className="col-2">
                          <Dropdown>
                            <CategoryDropdownToggle variant="white" block className={"shadow p-3"}>
                              {displayNameForAPICategory(selectedCategory)}
                            </CategoryDropdownToggle>

                            <Dropdown.Menu className="dropdown-menu-search">
                              {INTEGRATION_CATEGORY_LIST.map((category) => {
                                return (
                                  <Dropdown.Item
                                    key={category}
                                    className="small"
                                    onClick={() => {
                                      setSelectedCategory(category);
                                    }}
                                  >
                                    {displayNameForAPICategory(category)}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </StyledCol>
                        <Col>
                          <MergeTypeahead
                            id="typeahead"
                            multiple
                            selected={selectedSearchOptions}
                            options={[
                              ...commonModelNames,
                              ...(fieldMappingTargets
                                ? fieldMappingTargets.map(
                                    (customMappingTarget: any) => customMappingTarget.field_key,
                                  )
                                : []),
                            ]}
                            includeSearchIcon
                            inputProps={{ autoComplete: "none" }}
                            placeholder="Search models or fields..."
                            onChange={(selectedOptions) => {
                              setSelectedSearchOptions(selectedOptions);
                            }}
                            renderMenuItemChildren={(option, index) => {
                              return (
                                <React.Fragment key={`${option}-${index}`}>{option}</React.Fragment>
                              );
                            }}
                            includeChevronDown={false}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </SectionHeaderWrapper>
        </Col>
      </Row>
      <ConfigurationFieldMappingsPage
        commonModelOverrideTargets={commonModelOverrideTargets}
        commonModelOverrideOptions={commonModelOverrideOptions}
        schemas={schemas}
        setCommonModelOverrideTargets={setCommonModelOverrideTargets}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
        fieldMappingTargets={fieldMappingTargets!}
        commonModelNames={commonModelNames}
        updateFieldMappingTargets={updateFieldMappingTargets}
        updateFieldMappingConfigurations={updateFieldMappingConfigurations}
        searchOptions={[
          ...commonModelNames,
          ...(fieldMappingTargets
            ? fieldMappingTargets.map((customMappingTarget: any) => customMappingTarget.field_key)
            : []),
        ]}
        fieldMappingConfigurations={fieldMappingConfigurations}
        isLoading={isLoading}
        selectedSearchOptions={selectedSearchOptions}
        setIsLoading={setIsLoading}
        setHasError={setHasError}
      />
    </>
  );
};

export default ConfigurationFieldMappingsTargetFieldsPage;
