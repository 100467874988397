import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { Plus } from "react-feather";
import useAppContext from "../../../context/useAppContext";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";

type Props = {
  isCreatingNewField?: boolean;
  allTargetNames: string[];
  onSave: (fieldKey: string, customFieldDescription: string) => void;
  onCancel: () => void;
};

const ConfigurationFieldMappingCardEditSection = ({
  isCreatingNewField,
  allTargetNames,
  onSave,
  onCancel,
}: Props) => {
  const [fieldMappingTargetKey, setFieldMappingTargetKey] = useState<string>("");
  const [fieldMappingTargetDescription, setFieldMappingTargetDescription] = useState<string>("");
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const { user } = useAppContext();

  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div
          className={
            isFieldMappingV2Enabled
              ? "configuration-fieldmappings-editfieldmapping-header-text-v2"
              : "configuration-fieldmappings-editfieldmapping-header-text"
          }
        >
          {isFieldMappingV2Enabled ? "Target Field Name" : "Custom Field Name"}
        </div>
        {isCreatingNewField ? (
          <div className="configuration-fieldmappings-editfieldmapping-header-text">
            You cannot edit this later
          </div>
        ) : (
          <div />
        )}
      </div>
      <Form.Control
        name="target"
        type="text"
        isInvalid={isInvalid}
        className={isFieldMappingV2Enabled ? "configuration-fieldmappings-new-target-field" : ""}
        placeholder="Name"
        defaultValue={fieldMappingTargetKey}
        onChange={(e) => {
          if (allTargetNames.includes(e.target.value)) {
            setIsInvalid(true);
          } else {
            if (isInvalid) {
              setIsInvalid(false);
            }
            setFieldMappingTargetKey(e.target.value);
          }
        }}
      />
      {isFieldMappingV2Enabled && (
        <Form.Control.Feedback type="invalid">
          Duplicate Detected: Target Field names must be unique per model.
        </Form.Control.Feedback>
      )}
      <div className="d-flex justify-content-between">
        <div
          className={
            isFieldMappingV2Enabled
              ? "configuration-fieldmappings-editfieldmapping-header-text-v2"
              : "configuration-fieldmappings-editfieldmapping-header-text"
          }
        >
          Description
        </div>
        {isCreatingNewField ? (
          <div className="configuration-fieldmappings-editfieldmapping-header-text">
            You cannot edit this later
          </div>
        ) : (
          <div />
        )}
      </div>
      <Form.Control
        name="target"
        type="text"
        className={isFieldMappingV2Enabled ? "configuration-fieldmappings-new-target-field" : ""}
        placeholder="Description"
        defaultValue={fieldMappingTargetDescription}
        onChange={(e) => setFieldMappingTargetDescription(e.target.value)}
      />
      <div className="d-flex justify-content-between align-items-center deprecated-mt-3">
        <div className="flex flex-row items-center justify-center">
          <div className="mr-2">
            <SmallWhiteButton
              disabled={!fieldMappingTargetKey || (isFieldMappingV2Enabled && isInvalid)}
              onClick={() => onSave(fieldMappingTargetKey, fieldMappingTargetDescription)}
            >
              Save
            </SmallWhiteButton>
          </div>
          <div
            className="text-sm text-black configuration-customfields-cancel-button-v2 cursor-pointer py-1.5 px-3"
            onClick={onCancel}
          >
            <div className="configuration-fieldmappings-button-text">Cancel</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigurationFieldMappingCardEditSection;
