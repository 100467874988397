import { Col, Dropdown, Form, Row, Tooltip } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette, spectrum } from "../../../../../styles/theme/colors";

const SANS_SERIF_FONT = css`
  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
`;

const FLEX_ROW = css`
  display: flex;
  flex-direction: row;
`;

const FLEX_COLUMN = css`
  display: flex;
  flex-direction: column;
`;

export const CLICKABLE = css`
  &:hover,
  &:active,
  &:focus {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  ${SANS_SERIF_FONT}
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  padding: 10px 0px 20px 0px;
`;

export const SubTitle = styled.div`
  ${SANS_SERIF_FONT}
  font-size: 14px;
  line-height: 24px;
  color: ${palette.graphite};
`;

export const Heading = styled.div`
  ${SANS_SERIF_FONT}
  font-size: 16px;
  line-height: 24px;
  color: ${palette.gray}

  padding: 0px 0px 16px 0px;
`;

export const Container = styled.div`
  ${FLEX_COLUMN}
  justify-content: space-between;
  flex-grow: 1;
`;

export const CenteredContainer = styled(Container)`
  min-width: 800px;
  align-items: center;
`;

export const W800Container = styled(Container)`
  width: 800px;
  min-height: 600px;
`;

export const HeaderContainer = styled.div`
  ${FLEX_COLUMN}
  padding: 0px 0px 40px 0px;
  justify-content: space-between;
  flex: 1;
`;

export const BodyContainer = styled.div`
  ${FLEX_COLUMN}
  padding: 0px 0px 40px 0px;
  justify-content: space-between;
  flex-grow: 1;
  gap: 20px;
`;

export const FooterContainer = styled.div`
  ${FLEX_COLUMN}
  padding: 0px 0px 20px 0px;
  justify-content: space-between;
  flex-grow: 1;
`;

export const ButtonContainer = styled.div`
  ${FLEX_ROW}
  flex-grow: 1;
  align-items: stretch;
  justify-content: flex-end;
  gap: 20px;
`;

export const CancelButton = styled.div`
  ${FLEX_COLUMN}
  ${SANS_SERIF_FONT}
  ${CLICKABLE}
  width: 120px;
  height: 40px;
  text-align: center;
  justify-content: center;
  background: ${palette.white};
  border: 1px solid ${palette.border};
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${palette.black};
`;

export const SaveButton = styled.div`
  ${FLEX_COLUMN}
  ${SANS_SERIF_FONT}
  width: 120px;
  height: 40px;
  text-align: center;
  justify-content: center;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  &.isEnabled {
    background-color: ${palette.black};
    color: ${palette.white};
    ${CLICKABLE}
  }

  &.isDisabled {
    color: ${palette.white};
    background-color: ${spectrum.gray40};
    cursor: default;
  }
`;

export const Banner = styled.div`
  ${SANS_SERIF_FONT}
  ${FLEX_ROW}
  font-size: 14px;
  margin-bottom: 20px;
  padding: 12px 20px;
  align-items: center;
  align-text: left;
  width: 100%;
  background: ${spectrum.yellow0};
  border: 1px solid ${spectrum.yellow10};
  border-radius: 6px;
`;

export const ConditionDropdownRow = styled.div`
  ${FLEX_ROW}
  align-items: stretch;
  justify-content: space-between;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  height: 48px;
  overflow: hidden;
  flex-grow: 1;
  background: ${palette.white};
  padding: 0px;
`;

export const StyledDropdownToggle = styled(Dropdown.Toggle)<{
  $isPlaceholder: boolean;
  disabled: boolean;
}>`
  ${SANS_SERIF_FONT}
  font-size: 14px;
  line-height: 20px;
  width: calc(100%);

  ${({ $isPlaceholder }) =>
    $isPlaceholder
      ? css`
          color: ${palette.gray} !important;
        `
      : css`
          color: ${palette.black} !important;
        `}

  ${({ disabled }) =>
    disabled &&
    css`
      ::after {
        display: none;
      }

      &:hover,
      &:active,
      &:focus {
        cursor: default;
      }
    `};
`;

export const AddConditionButton = styled.div`
  ${FLEX_ROW}
  ${CLICKABLE}
  justify-content: center;
  align-items: center;
  margin-top: 34px;
  padding: 10px 12px;
  height: 48px;
  background: ${spectrum.slate0};
  border-radius: 6px;

  &:hover {
    background: ${spectrum.gray0};
  }
`;

export const DeleteConditionButton = styled.div`
  ${CLICKABLE}
  color: ${palette.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteValueButton = styled.div`
  ${CLICKABLE}
  color: ${palette.gray};
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EditConditionRow = styled.div`
  ${FLEX_ROW}
`;

export const ConditionColumn = styled(Col)`
  ${FLEX_ROW}
  flex-grow: 1;
  padding: 0px;
  justify-content: center;
  align-items: center;

  &:hover &:selected &:active {
    background: ${palette.white};
  }

  .btn-white:focus {
    box-shadow: none !important;
  }

  .form-control {
    height: calc(100%);
    width: calc(100%);
  }

  .btn,
  .div,
  .form-control {
    border: none;
    border-radius: 0px;
    box-shadow: none;
  }
`;

export const AndBadge = styled.div`
  ${FLEX_COLUMN}
  ${SANS_SERIF_FONT}
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: ${palette.placeholder};
  justify-content: center;
`;

export const OrBadge = styled.div`
  ${SANS_SERIF_FONT}
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: ${palette.placeholder};
  justify-content: center;
  text-align: right;
`;

export const VerticalBarDiv = styled.div`
  height: 24px;
  width: 0px;
  color: ${spectrum.gray20};
  border-left: 1px solid ${palette.border};
  align-self: center;
  padding: 0px;
  margin: 0px;
`;

export const AddAdditionalValueRow = styled(Row)`
  align-text: right;
  white-space: nowrap;
`;

export const FlexDropdown = styled.div`
  ${FLEX_ROW}
  flex-grow: 1;
  height: calc(100%);

  .dropdown {
    flex-grow: 1;
  }

  .dropdown-toggle {
    ${FLEX_ROW}
    ${SANS_SERIF_FONT}
    align-items: center;
    height: calc(100%);
    justify-content: space-between;
    box-shadow: none;
    font-size: 14px;
    line-height: 20px;
    color: ${palette.black};
  }
`;

export const AdditionalValueRowContianer = styled.div`
  ${FLEX_ROW}
  align-items: center;
  padding: 0px;
`;

export const AdditionalValueRow = styled.div`
  ${FLEX_ROW}
  height: 48px;
  background: ${palette.white};
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  text-align: center;
  justify-content: center;
  padding: 0px;

  .form-control {
    height: calc(100%);
    width: calc(100%);
  }

  input {
    border: none;
    border-radius: 0px;
    box-shadow: none;
  }
`;

export const DatePicker = styled.input`
  width: calc(100%);
  height: calc(100%);

  &:focus-visible {
    outline: none;
  }
`;

export const AddValueButton = styled.div`
  ${CLICKABLE}
  color: ${palette.blue};
  text-align: left;
  font-size: 14px;
`;

export const HoverableSpans = styled.div`
  &:hover span {
    color: ${palette.blue} !important;
  }
`;

export const FieldNameDropdownItem = styled.span<{ $useAltText?: boolean }>`
  ${({ $useAltText }) =>
    $useAltText
      ? css`
          color: ${palette.gray};
          font-size: 11px;
        `
      : css`
          color: ${palette.black};
        `}
`;

export const HelpTextDropdownFooter = styled.div`
  ${SANS_SERIF_FONT}
  background-color: ${spectrum.gray0};
  font-size: 12px;
  color: ${palette.graphite};
  padding: 8px 10px 8px 10px;
  border-top: 1px solid ${palette.border};

  &:active &:focus &:hover {
    cursor: default;
  }

  b {
    color: ${palette.gray};
  }
`;

export const TableIconColumn = styled.td`
  display: flex;
  justify-content: right;
  padding-right: 24px !important;
`;

export const TooltipParagraph = styled.p`
  text-align: left;
`;

export const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    max-width: 500px;
  }
`;

export const SyncSelectionContainer = styled.div`
  ${FLEX_COLUMN}
  flex-grow: 1;
  max: inherit !important;
  gap: 12px;
  margin-bottom: 12px;
`;

export const AddSelectionButton = styled.div`
  ${SANS_SERIF_FONT}
  ${CLICKABLE}
  text-align: center;
  padding: 2px 8px 2px;
  gap: 8px;
  width: 116px;
  height: 24px;
  background: ${palette.white};
  border: 1px solid ${palette.border};
  box-shadow: 0px 1px 2px rgba(31, 45, 61, 0.05);
  border-radius: 4px;
`;

export const SyncConditionRowContainer = styled.div`
  ${FLEX_ROW}
  ${CLICKABLE}
  ${SANS_SERIF_FONT}
  background: ${spectrum.slate0};
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background: ${spectrum.blue0};
  }
`;

export const NoHoverRow = styled.td`
  border-top: none !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  &:hover {
    background: ${palette.white};
  }
`;

export const SyncSelectionHeader = styled.div`
  ${SANS_SERIF_FONT}
  font-weight: 600;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: ${palette.slate};
`;

export const DateContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;

  .react-datepicker__input-container > input {
    border: none;

    &:focus-visible {
      outline: none;
    }
  }
`;

export const StyledFormControl = styled(Form.Control)<{ $isPlaceholder: boolean }>`
  ${({ $isPlaceholder }) =>
    $isPlaceholder
      ? css`
          color: ${palette.gray};
        `
      : css`
          color: ${palette.black};
        `}
`;

export const ClickableRow = styled.tr`
  ${CLICKABLE}
`;

export const ClickableCell = styled.td`
  ${CLICKABLE}
`;
export const popperConfig = {
  // Make dropdown not hidden when overflow is none, need 'as const' casting for tsx
  strategy: "fixed" as const,
};
