import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { WebhookReceiver } from "../../../../../models/Entities";
import { CONFIGURATION_WEBHOOKS_RECEIVERS_PATH } from "../../../../../router/RouterUtils";
import { palette, spectrum } from "../../../../../styles/theme";
import MultiSwitch, { MultiSwitchOption } from "../../../../shared-components/MultiSwitch";
import { AutoWebhookSetupCard, NoticeCard } from "../../../../shared-components/NoticeCards";
import { showErrorToast, showSuccessToast } from "../../../../shared-components/Toasts";
import Stack from "../../shared/Stack";
import type { Props as WebhookReceiverRowProps } from "./WebhookReceiverRow";
import { Button } from "@merge-api/merge-javascript-shared";
import CopyComponentWithIcon from "../../../../shared-components/CopyComponentWithIcon";

type Props = WebhookReceiverRowProps & {
  /**
   * If true, we should focus the input ref when we show this component
   */
  isInitiallyFocused: boolean;
  width: number;
};

const INPUT_STYLES = css`
  background: #f7f8fa;
  border-radius: 6px;
`;

const IS_LISTENING_OPTION: MultiSwitchOption = {
  id: "enabled",
  text: "Enabled",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: "#11ba90",
  disableTooltip: "Add secret key to enable listener",
};

const NOT_LISTENING_OPTION: MultiSwitchOption = {
  id: "disabled",
  text: "Disabled",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: palette.slate,
};

const HANDSHAKE_OPTION: MultiSwitchOption = {
  id: "handshake",
  text: "Handshake",
  backgroundColor: palette.white,
  borderColor: palette.border,
  selectedColor: palette.slate,
};

const Container = styled(Stack)`
  margin-top: 12px;
  position: relative;
`;

const KeyContainer = styled(Container)<{ $isWhite: boolean }>`
  ${INPUT_STYLES};
  border: 1px solid ${palette.border};
  ${({ $isWhite }) =>
    $isWhite &&
    css`
      background-color: ${palette.white};
    `}
`;

const Input = styled.input<{ $hasChanges: boolean }>`
  ${INPUT_STYLES};
  padding: 10px 16px;
  font-size: 13px;
  line-height: 20px;
  font-family: var(--font-family-monospace);
  color: ${({ $hasChanges }) => ($hasChanges ? palette.black : palette.gray)};
  border: none;
`;

const ListenerSwitch = styled(MultiSwitch)`
  & .btn {
    font-size: 12px;
    padding: 6px 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
  }
`;

const SmallPaddingButton = styled(Button)`
  &&& {
    padding: 2px 8px !important;
  }
`;

interface ListenerSwitchWrapperProps {
  integrationUsesWebhookHandshake: boolean;
  currentIsActive: boolean;
  currentIsAwaitingHandshake: boolean;
  currentKeyValue?: string;
  listeningOption: MultiSwitchOption;
  onSelectOption: (option: MultiSwitchOption) => void;
}

const ListenerSwitchWrapper = ({
  integrationUsesWebhookHandshake,
  currentIsActive,
  currentIsAwaitingHandshake,
  currentKeyValue,
  listeningOption,
  onSelectOption,
}: ListenerSwitchWrapperProps) => {
  const listenerOptions = [NOT_LISTENING_OPTION];

  if (integrationUsesWebhookHandshake && !currentIsActive && !currentKeyValue) {
    listenerOptions.push(HANDSHAKE_OPTION);
  } else {
    listenerOptions.push(listeningOption);
  }

  const getSelectedId = (currentIsAwaitingHandshake: boolean, currentIsActive: boolean) => {
    let selectedId = NOT_LISTENING_OPTION.id;
    if (currentIsAwaitingHandshake) {
      selectedId = HANDSHAKE_OPTION.id;
    } else if (currentIsActive) {
      selectedId = IS_LISTENING_OPTION.id;
    }
    return selectedId;
  };

  return (
    <ListenerSwitch
      selectedID={getSelectedId(currentIsAwaitingHandshake, currentIsActive)}
      onSelectOption={onSelectOption}
      options={listenerOptions}
    />
  );
};

/**
 * Shows the expanded contents of a single row for the WebhookEventsTable,
 * where each row shows one receiver itself. Shows signature key details.
 */
const WebhookReceiverDetails = React.forwardRef<Pick<HTMLInputElement, "focus">, Props>(
  (
    {
      receiver: { key, event, is_active, is_awaiting_handshake, webhook_listener_url },
      integrationName,
      isInitiallyFocused,
      linkedAccountId,
      updateReceiver,
      integrationUsesWebhookHandshake,
      webhookInstructions,
      isUsingAutoWebhookSetup,
      width,
    },
    ref,
  ) => {
    const [currentKeyValue, setCurrentKeyValue] = useState(key);
    const [currentIsActive, setCurrentIsActive] = useState(is_active);
    const [currentIsAwaitingHandshake, setCurrentIsAwaitingHandshake] =
      useState(is_awaiting_handshake);
    const inputElement = useRef<HTMLInputElement | null>(null);
    const [originalValue, setOriginalValue] = useState(key);
    const [isEditing, setIsEditing] = useState(false);
    const [isFocused, setIsFocused] = useState(isInitiallyFocused);
    const hasChanges = useMemo(
      () => currentKeyValue !== originalValue,
      [currentKeyValue, originalValue],
    );
    const [isSaving, setIsSaving] = useState(false);
    const calculatedWidth = Math.max(510, width - 96); // takes into account padding around table and between copy icon for webhook receiver url

    // Calls the API with the saved changes
    const saveChanges = (
      keyValue: string | undefined,
      isActiveValue: boolean,
      isHandshake: boolean,
      onSuccess?: () => void,
    ) => {
      setIsSaving(true);
      fetchWithAuth({
        path:
          isHandshake && integrationUsesWebhookHandshake
            ? `/integrations/webhook-receivers/${linkedAccountId}?is_handshake=${isHandshake}`
            : `/integrations/webhook-receivers/${linkedAccountId}`,
        method: "POST",
        body: {
          event,
          key: keyValue,
          is_active: isActiveValue,
        },
        onResponse: (receiver: WebhookReceiver) => {
          setIsSaving(false);
          setIsEditing(false);
          updateReceiver(receiver);
          onSuccess?.();
        },
        onError: () => {
          setIsSaving(false);
          showErrorToast(`Couldn't update the receiver, please try again`);
        },
      });
    };

    // Allows focusing the input on demand from a parent ref
    useImperativeHandle(ref, () => ({
      focus: () => {
        inputElement.current?.focus();
      },
    }));

    const input = (
      <Input
        className="form-control"
        placeholder={`Enter secret key from ${integrationName}`}
        $hasChanges={hasChanges}
        spellCheck={false}
        onChange={(event) => setCurrentKeyValue(event.target.value)}
        onFocus={() => {
          setIsEditing(true);
          setIsFocused(true);
        }}
        onBlur={() => {
          if (!hasChanges) {
            setIsEditing(false);
          }
          setIsFocused(false);
        }}
        ref={(element) => {
          inputElement.current = element;
          if (isFocused) {
            element?.focus();
          }
        }}
        value={currentKeyValue ?? ""}
      />
    );

    const editButton = !isEditing && (
      <div
        className="text-blue-40 hover:text-blue-60 font-medium cursor-pointer mr-2"
        onClick={() => inputElement.current?.focus()}
      >
        Edit
      </div>
    );

    const saveChangesButton = hasChanges && (
      <SmallPaddingButton
        color="blue"
        size="sm"
        disabled={isSaving}
        onClick={() =>
          saveChanges(currentKeyValue, currentIsActive, currentIsAwaitingHandshake, () => {
            setOriginalValue(currentKeyValue);
            showSuccessToast(`Updated ${event}'s key`);
          })
        }
      >
        Save changes
      </SmallPaddingButton>
    );

    const cancelButton = isEditing && (
      <SmallPaddingButton
        color="white"
        size="sm"
        onClick={() => {
          setIsEditing(false);
          setCurrentKeyValue(originalValue);
        }}
      >
        Cancel
      </SmallPaddingButton>
    );

    const handleSelectOption = (option: MultiSwitchOption) => {
      let isNewlyAwaitingHandshake = false;
      let isNewlyActive = false;
      if (option.id === HANDSHAKE_OPTION.id) {
        isNewlyAwaitingHandshake = true;
      } else if (option.id === IS_LISTENING_OPTION.id) {
        isNewlyActive = true;
      }

      // We use the original value to not wipe out any local changes
      saveChanges(currentKeyValue, isNewlyActive, isNewlyAwaitingHandshake, () => {
        setCurrentIsAwaitingHandshake(isNewlyAwaitingHandshake);
        setCurrentIsActive(isNewlyActive);
        isNewlyAwaitingHandshake
          ? showSuccessToast(`${event} webhook is listening for handshake request`)
          : showSuccessToast(`Turned ${event} webhook ${isNewlyActive ? "on" : "off"}`);
      });
    };

    // The multiswitch option for listening is disabled if there's no key value
    const listeningOption = useMemo(
      () => ({ ...IS_LISTENING_OPTION, disable: (currentKeyValue?.length ?? 0) === 0 }),
      [currentKeyValue],
    );

    const autoWebhookSetupCard = (
      <AutoWebhookSetupCard className="m-0" color={spectrum.indigo50}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div>Merge automatically created this webhook in {integrationName}</div>
          <div>
            Manage this behavior in{" "}
            <a href={CONFIGURATION_WEBHOOKS_RECEIVERS_PATH}>Configuration</a>
          </div>
        </div>
      </AutoWebhookSetupCard>
    );

    const manualWebhookSetupInstructions = (
      <NoticeCard className="m-0">
        <>
          {webhookInstructions ||
            `Get a signature key from ${integrationName} to enable Merge to receive webhooks.`}
        </>
      </NoticeCard>
    );

    return (
      <>
        <div className="flex flex-column bg-lighter max-w-full">
          {isUsingAutoWebhookSetup ? autoWebhookSetupCard : manualWebhookSetupInstructions}
          {!isUsingAutoWebhookSetup && (
            <div>
              <div className="flex flex-row items-center mt-8">
                <div className="text-sm text-slate-90 font-semibold">Signature Key</div>
                <div className="ml-1 text-gray-50 leading-18 text-[12px]">(required)</div>
              </div>
              <KeyContainer
                $isWhite={isFocused}
                className="justify-content-between align-items-center pr-2"
                $gap="4px"
              >
                {input}
                {editButton}
                {cancelButton}
                {saveChangesButton}
              </KeyContainer>
            </div>
          )}
          <div className="text-sm text-slate-90 font-semibold mt-8">Merge Receiver</div>
          <div className="mt-2">
            <ListenerSwitchWrapper
              integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
              currentIsActive={currentIsActive}
              currentIsAwaitingHandshake={currentIsAwaitingHandshake}
              currentKeyValue={currentKeyValue}
              listeningOption={listeningOption}
              onSelectOption={handleSelectOption}
            />
          </div>
          <div className="text-sm text-slate-90 font-semibold mt-8 mb-1.5">Merge Receiver URL</div>
          <div className="text-sm text-gray-60 font-normal">
            Send {integrationName} webhooks to this Merge endpoint to update your user's data in
            Merge
          </div>
          <div className="mb-2 mt-3 w-full py-2.5 px-4 rounded-md bg-blue-90 flex flex-row items-center justify-between">
            <div
              className="text-white font-mono text-[13px] leading-24 truncate mr-2"
              style={{ maxWidth: `${calculatedWidth}px` }}
            >
              {webhook_listener_url || "(No URL available)"}
            </div>
            <CopyComponentWithIcon
              fontSize={16}
              text={webhook_listener_url || "(No URL available)"}
              foregroundColor={{ r: 255, g: 255, b: 255 }}
            />
          </div>
        </div>
      </>
    );
  },
);

export default WebhookReceiverDetails;
