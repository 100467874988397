import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { fetchWithAuth, fetchCurrentUser } from "../../../api-client/APIClient";
import { showSuccessToast, showErrorToast } from "../../shared-components/Toasts";
import useAppContext from "../../context/useAppContext";
import classNames from "classnames";
import { Card, Col, Row, Form } from "react-bootstrap";
import DeprecatedH6 from "../../../deprecated/DeprecatedH6";
import { Button } from "@merge-api/merge-javascript-shared";

function ChangeMeUserOrganizationForm() {
  const { setUser, user } = useAppContext();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const onSubmit = (data: { name: string }) => {
    setIsLoading(true);
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: data,
      onResponse: () => {
        showSuccessToast("Successfully updated your organization!");
        fetchCurrentUser(setUser);
        setIsLoading(false);
        setIsEditMode(false);
      },
      onError: () => {
        showErrorToast("Failed to update your organization.");
        setIsLoading(false);
        setIsEditMode(false);
      },
    });
  };

  return (
    <Card>
      <Card.Body>
        <Row className="align-items-center">
          {isEditMode ? (
            <>
              <Form onSubmit={handleSubmit(onSubmit)} className="col">
                <Row className="align-items-center">
                  <Col style={{ marginBottom: 1 }}>
                    <DeprecatedH6 className="text-uppercase text-gray-50 deprecated-mb-2">
                      Organization name
                    </DeprecatedH6>
                    <Form.Control
                      as="input"
                      name="name"
                      type="text"
                      className={classNames("form-control form-control-flush deprecatedh2 m-0 p-0")}
                      style={{ height: 22, border: 0 }}
                      ref={register({ required: true, minLength: 1 })}
                      placeholder="Enter new organization name here."
                      custom
                    />
                  </Col>
                  <Col className="col-auto">
                    <div className="d-flex">
                      <Button
                        color="blue"
                        onClick={() => handleSubmit(onSubmit)}
                        isLoading={isLoading}
                      >
                        Save organization name
                      </Button>
                      <Button className="ml-3" color="white" onClick={() => setIsEditMode(false)}>
                        Cancel changes
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </>
          ) : (
            <>
              <Col>
                <DeprecatedH6 className="text-uppercase text-gray-50 deprecated-mb-2">
                  Organization name
                </DeprecatedH6>
                <span className="deprecatedh2 deprecated-mb-0">{user.organization.name}</span>
              </Col>
              <Col className="col-auto">
                <div className="d-flex">
                  {user.type && (
                    <Button color="white" onClick={() => setIsEditMode(true)} size="sm">
                      Edit organization name
                    </Button>
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default ChangeMeUserOrganizationForm;
