import React from "react";
import { Key } from "lucide-react";
import { LOGIN_WITH_SSO_PATH } from "../../../router/RouterUtils";
import GoogleGLogo from "../../../assets/svg/logos/google/google-g-logo.svg";
import { API_DOMAIN } from "../../../api-client/APIClient";
import { Button } from "react-bootstrap";

interface Props {
  text?: string;
}

export const SignInWithGoogleLink = ({ text }: Props) => {
  const signInWithGoogleUrl = `${API_DOMAIN}/sso/social/google/login`;
  return (
    <Button href={signInWithGoogleUrl} variant="link" block className="btn-sign-in-with-button">
      <img src={GoogleGLogo} className="float-left" />
      {text ? text : "Sign in with Google"}
    </Button>
  );
};

export default SignInWithGoogleLink;

export const SignInWithSSOLink = () => {
  return (
    <Button
      href={LOGIN_WITH_SSO_PATH}
      variant="link"
      block
      className="btn-sign-in-with-button deprecated-mt-5"
    >
      <Key className="float-left" />
      Sign in with SSO
    </Button>
  );
};
