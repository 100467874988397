import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LeftSideBarCol } from "./LeftSideBarSpacing";
export interface SideBarProps {
  stepNumber: string;
  isNormalStep: boolean;
}

const Circle = styled.div`
  width: 40px;
  height: 40px;
  background: linear-gradient(318.31deg, #ffffff 10.59%, rgba(255, 255, 255, 0) 86.47%);
  box-shadow: 0px 6px 30px -2px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(4px);
  border-radius: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 0px;
  padding-right: 0px;
`;

const BorderRight = styled(Col)`
  border-right: 0px solid #d4def2;
  width: 50%;
  padding-left: 0px;
  padding-right: 0px;
`;
const BorderLeft = styled(Col)`
  border-left: 1px solid #d4def2;
  width: 50%;
  padding-left: 0px;
  padding-right: 0px;
`;

const EmptyBorderRight = styled(Col)`
  width: 50%;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
`;
const EmptyBorderLeft = styled(Col)`
  width: 50%;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
`;

const StyledRow = styled(Row)`
  align-self: stretch;
  flex: 1 0 100%;
`;

const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
  flex-wrap: wrap;
  height: 100%;
  margin: 0px;
`;

const PaddingLessCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
`;

/*
 ** Unfortunately, one of Simeon’s designs required one of the left hand column circles to match up not center-aligned with the component.
 * This component rectifies that.
 * The component I am referring to is Step #2 on the Sync Sandbox Data section
 */

const CustomLeftSideBar = ({ stepNumber, isNormalStep = true }: SideBarProps) => (
  <>
    <LeftSideBarCol className="col-2 align-items-center">
      <WrapperContainer>
        <StyledRow className="row-cols-2">
          <BorderRight>
            <div />
          </BorderRight>
          <BorderLeft>
            <div />
          </BorderLeft>
        </StyledRow>
        <StyledRow>
          <PaddingLessCol className="d-flex justify-content-center align-items-center">
            <Circle className="d-flex justify-content-center align-items-center">
              {stepNumber}
            </Circle>
          </PaddingLessCol>
        </StyledRow>
        {isNormalStep && (
          <StyledRow className="row-cols-2">
            <BorderRight>
              <div />
            </BorderRight>
            <BorderLeft>
              <div />
            </BorderLeft>
          </StyledRow>
        )}
        {!isNormalStep && (
          <StyledRow className="row-cols-2">
            <EmptyBorderRight>
              <div />
            </EmptyBorderRight>
            <EmptyBorderLeft>
              <div />
            </EmptyBorderLeft>
          </StyledRow>
        )}
        <StyledRow className="row-cols-2">
          <BorderRight>
            <div />
          </BorderRight>
          <BorderLeft>
            <div />
          </BorderLeft>
        </StyledRow>
        <StyledRow className="row-cols-2">
          <BorderRight>
            <div />
          </BorderRight>
          <BorderLeft>
            <div />
          </BorderLeft>
        </StyledRow>
        <StyledRow className="row-cols-2">
          <BorderRight>
            <div />
          </BorderRight>
          <BorderLeft>
            <div />
          </BorderLeft>
        </StyledRow>
      </WrapperContainer>
    </LeftSideBarCol>
  </>
);
export default CustomLeftSideBar;
