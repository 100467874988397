import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { LinkedAccount } from "../../../../models/Entities";
import MergeModal from "../../../shared-components/MergeModal";
import { Button } from "@merge-api/merge-javascript-shared";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  deleteLinkedAccount: () => void;
  linkedAccount: LinkedAccount;
};

const LinkedAccountDeletionModal = ({ isModalOpen, closeModal, deleteLinkedAccount }: Props) => {
  const [
    isLinkedAccountDeletionConfirmationChecked,
    setIsLinkedAccountDeletionConfirmationChecked,
  ] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <MergeModal
      centered
      show={isModalOpen}
      bodyClassName="modal-narrow overflow-hidden"
      dialogClassName="modal-narrow"
      onHide={closeModal}
      title="Are you sure you want to delete this?"
      ref={modalRef}
    >
      <span className="text-center">
        This is an irreversible action. Once the Linked Account is deleted, our Customer Support
        team will no longer be able to assist you with troubleshooting this account.
        <br></br>
        <br></br>
        After the account is deleted, you will receive a confirmation email within 24 hours.
        <Form.Group className="d-flex align-items-center deprecated-mt-4 deprecated-mb-4">
          <Form.Check
            type="checkbox"
            id="linked-account-deletion-checkbox"
            checked={isLinkedAccountDeletionConfirmationChecked}
            onChange={() =>
              setIsLinkedAccountDeletionConfirmationChecked(
                !isLinkedAccountDeletionConfirmationChecked,
              )
            }
          />
          <b className="ml-1">I understand that this is an irreversible action.</b>
        </Form.Group>
        <div className="w-100 text-center d-flex">
          <Button fullWidth={true} color="white" size="md" className="mr-2" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color="red"
            size="md"
            fullWidth={true}
            disabled={!isLinkedAccountDeletionConfirmationChecked}
            isLoading={isDeleting}
            onClick={() => {
              setIsDeleting(true);
              deleteLinkedAccount();
            }}
          >
            Delete
          </Button>
        </div>
      </span>
    </MergeModal>
  );
};

export default LinkedAccountDeletionModal;
