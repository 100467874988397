import classNames from "classnames";
import DOMPurify from "dompurify";
import { transparentize } from "polished";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import type { OptionMapping } from "../../../models/LogFilter";
import { palette } from "../../../styles/theme";
import { showWarningToast } from "../../shared-components/Toasts";
import moveSelectionToEnd from "../utils/moveSelectionToEnd";

type Props = {
  /**
   * A possible unique id for the dom element
   */
  id?: string;

  /**
   * If specified, adds a placeholder when there's no value instead of an
   * empty value.
   */
  placeholder?: string;

  /**
   * The starting value for this line of editable text with formatting
   * applied (bold, etc)
   */
  formattedValue?: React.ReactChild | React.ReactFragment;

  /**
   * Keep track of the editable item from the parent. Otherwise,
   * created here.
   */
  editableRef?: React.RefObject<HTMLDivElement>;

  /**
   * True if the user is actively typing, resets to false when the
   * user blurs/otherwise stops editing and the value settles.
   */
  isUserEditing: boolean;

  /**
   * Starts editing the content with a focus
   */
  startEditing: () => void;

  /**
   * Finishes editing by setting up anything that's necessary to change in
   * the parent
   */
  endEditing: () => void;

  /**
   * If specified, listens to changes on the text node itself, so typing
   * can be captured.
   */
  onInput?: (newValue: string | null) => void;

  /**
   * Handles presses as well as the built in handler, if needed. Return
   * true if you handle it elsewhere
   */
  onKeyPress?: (event: React.KeyboardEvent) => boolean;

  /**
   * Optional handler for notifications
   */
  onMouseDown?: (event: React.MouseEvent) => void;

  /**
   * Sets new filter options when selected
   */
  setCurrentFilterOptions: (options: OptionMapping) => void;
} & Pick<React.ComponentProps<"div">, "className">;

// The value applied when there's no formatted value - can't be null/undefined otherwise there's duplicates on first edit
const EMPTY_VALUE = "";

const Placeholder = styled.span`
  color: ${palette.placeholder};
`;

// Allows editing content - removes outline too. Height/line height is necessary to fix a Firefox sizing bug with empty text.
const Container = styled.div.attrs({
  contentEditable: true,
  suppressContentEditableWarning: true,
})<{ $hasForcedPlainText: boolean }>`
  height: 18px;
  line-height: 13px;
  cursor: text;

  overflow: hidden;
  /* Firefox */
  scrollbar-width: none;
  /* IE10 */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* Other Browsers */
  ::-webkit-scrollbar: {
    width: 0px;
    height: 0px;
    background: transparent;
  }

  max-width: 300px;
  color: ${palette.black};
  ${({ $hasForcedPlainText }) =>
    $hasForcedPlainText &&
    css`
      strong {
        font-weight: 400;
      }
    `}

  :focus {
    outline: none;
  }
  :focus-visible {
    outline: initial;
  }

  /* This hack is for Safari, since the blinking cursor is tied to the
   outline in Safari. Without it, the cursor is missing when there's no
   content */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      & {
        :focus {
          outline: auto;
          outline-offset: 4px;
          outline: 1px solid ${transparentize(0.75, palette.blue)};
        }
      }
    }
  }
`;

/**
 * A one-line piece of editable text that blurs on esc/enter,
 * and saves edits on enter. Focuses to end of content on
 * first render.
 */
const EditableLine = ({
  id,
  className,
  editableRef: parentEditableRef,
  placeholder,
  formattedValue,
  setCurrentFilterOptions,
  isUserEditing,
  startEditing,
  endEditing,
  onKeyPress,
  onInput,
  onMouseDown,
}: Props) => {
  // The original value of the text, for use in restoring
  const [originalValue, setOriginalValue] = useState<string | null>(null);
  const [shouldSaveChanges, setShouldSaveChanges] = useState(false);
  const innerEditableRef = useRef<HTMLDivElement>(null);
  const editableRef = useMemo(() => parentEditableRef ?? innerEditableRef, [parentEditableRef]);

  // Resets to the original value if needed
  const resetChanges = useCallback(() => {
    if (editableRef.current && editableRef.current.textContent !== originalValue && isUserEditing) {
      editableRef.current.textContent = originalValue ?? "";
    }
  }, [editableRef, isUserEditing, originalValue]);

  const warnOnOversizedSearchPhrases = (phrases: string[]) => {
    const longPhrase = phrases.find((phrase) => phrase.length > 100);
    if (longPhrase) {
      showWarningToast(`Search terms have a limit of 100 characters, ignoring oversized terms.`);
    }
  };

  // Actually saves the current changes and sets the options
  const saveChanges = useCallback(() => {
    const textContent = editableRef.current?.textContent;
    if (textContent && textContent.trim().length > 0 && textContent !== placeholder) {
      // We MAY have an "or" in there, or commas, or both, or weird spaces from old dom content
      const nonTruncatedPhrases = DOMPurify.sanitize(textContent)
        .replace(/\s+/g, " ")
        .trim()
        .split(",")
        .map((phrase) => phrase.split(" or "))
        .flat()
        .filter((phrase) => phrase.length > 0);
      warnOnOversizedSearchPhrases(nonTruncatedPhrases);
      const phrases = nonTruncatedPhrases.filter((phrase) => phrase.length <= 100);
      setCurrentFilterOptions(
        Object.fromEntries(
          phrases.map((phrase) => [phrase.trim(), { displayName: phrase.trim(), isEnabled: true }]),
        ),
      );
    } else {
      setCurrentFilterOptions({});
    }
    setShouldSaveChanges(false);
  }, [editableRef, placeholder, setCurrentFilterOptions]);

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent) => {
      // Modifier keys have no effect on starting editing
      if (["Meta", "Alt", "Control", "Shift"].includes(event.key) || !editableRef.current) {
        return;
      }

      // Non enter/escape/modifier keys just does the default action, and if keypress is handled elsewhere, fine
      if (onKeyPress?.(event) || !["Enter", "Escape"].includes(event.key)) {
        return;
      }

      // Enter/escape prevent default and blur, with esc resetting content
      if (event.key === "Enter") {
        setShouldSaveChanges(true);
      }
      event.preventDefault();

      // Give the React setShouldSaveChanges time to process before blurring - vital!
      requestAnimationFrame(() => editableRef.current?.blur());
    },
    [editableRef, onKeyPress],
  );

  // Saves the user edited content on blur if there's something there - splitting the phrase on "or" or "," as delimiters
  const handleBlur = useCallback(() => {
    if (shouldSaveChanges) {
      saveChanges();
    } else {
      resetChanges();
    }
    // Avoids seeing a flash of the old text when we finish editing - vital!
    requestAnimationFrame(() => endEditing());
  }, [endEditing, resetChanges, saveChanges, shouldSaveChanges]);

  // Make sure to move focus when the user starts editing to the end, and save the original value
  useEffect(() => {
    if (isUserEditing && editableRef.current) {
      setOriginalValue(editableRef.current.textContent);
      moveSelectionToEnd(editableRef);
    }
  }, [editableRef, isUserEditing]);

  // Converts the formatted value to a string while the user is editing
  const formattedOrEditableValue = isUserEditing
    ? editableRef.current?.textContent
    : formattedValue;

  const placeholderValue = placeholder ? <Placeholder>{placeholder}</Placeholder> : EMPTY_VALUE;

  const emptyOrEditableValue = isUserEditing ? EMPTY_VALUE : placeholderValue;

  return (
    <Container
      id={id}
      className={classNames(className, "d-flex align-items-center")}
      ref={editableRef}
      onFocus={startEditing}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      onMouseDown={onMouseDown}
      onInput={() => onInput?.(editableRef.current?.textContent ?? null)}
      $hasForcedPlainText={isUserEditing}
    >
      {formattedValue && formattedValue !== EMPTY_VALUE
        ? formattedOrEditableValue
        : emptyOrEditableValue}
    </Container>
  );
};

export default EditableLine;
