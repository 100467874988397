import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled, { css } from "styled-components";
import Stack from "../integrations-management/linked-accounts/shared/Stack";
import CopyComponentWithIcon from "./CopyComponentWithIcon";
import ExternalLinkWithIcon from "./ExternalLinkComponentWithIcon";
import useTimeout from "./hooks/useTimeout";
import MergeModal from "./MergeModal";

interface Color {
  r: string | number;
  g: string | number;
  b: string | number;
}

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  text: string;
  isUrl?: boolean;
  isSecret?: boolean;
  isOutlined?: boolean;
  onClickRegenerate?: () => void;
  onClickDelete?: () => void;
  backgroundColor?: Color;
  foregroundColor?: Color;
  isEmbedded?: boolean;
  style?: React.CSSProperties;
  iconSize?: number;
};

const OneLineStack = styled(Stack)`
  flex-wrap: nowrap;
`;

/**
 * Creates a background color of the right opacity (opacity should
 * be [0, 1] inclusive).
 */
const backgroundWithOpacity = (opacity: number, backgroundColor?: Color) =>
  `rgba(${backgroundColor?.r ?? 246}, ${backgroundColor?.g ?? 249}, ${
    backgroundColor?.b ?? 255
  }, ${opacity})`;

const Text = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
`;

// Fade on left and right to indicate there's more
const TextContainer = styled.div<{ $backgroundColor?: Color }>`
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 10%;
    height: 80%;
    width: 4px;
    pointer-events: none;
  }
  ${({ $backgroundColor }) => css`
    &:before {
      left: 0;
      background: linear-gradient(
        to left,
        ${backgroundWithOpacity(0, $backgroundColor)},
        ${backgroundWithOpacity(1, $backgroundColor)}
      );
    }
    &:after {
      right: 0;
      background: linear-gradient(
        to right,
        ${backgroundWithOpacity(0, $backgroundColor)},
        ${backgroundWithOpacity(1, $backgroundColor)}
      );
    }
  `}
`;

const ColoredStack = styled(Stack)<{ $backgroundColor?: Color }>`
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background: rgb(${$backgroundColor.r}, ${$backgroundColor.g}, ${$backgroundColor.b});
    `}
`;

const IconButton = styled.i<{ $iconSize?: number }>`
  cursor: pointer;
  ${({ $iconSize }) =>
    $iconSize &&
    css`
      max-width: ${$iconSize}px;
      max-height: ${$iconSize}px;
    `}
`;

const DottedOutlineTextCard = ({
  text,
  isSecret,
  onClickRegenerate,
  onClickDelete,
  className = "deprecated-mt-3",
  backgroundColor,
  foregroundColor,
  isEmbedded,
  style,
  iconSize,
  isUrl = false,
  isOutlined = true,
}: Props) => {
  const [isTextHidden, setIsTextHidden] = useState(false);
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  const [isShowingConfirmDeleteModal, setIsShowingConfirmDeleteModal] = useState(false);

  const foregroundColorCssValue = foregroundColor
    ? `rgb(${foregroundColor.r}, ${foregroundColor.g}, ${foregroundColor.b})`
    : undefined;

  useEffect(() => {
    if (isSecret) {
      setIsTextHidden(true);
    }
  }, [isSecret]);

  // Resets the copy state after 3 seconds if you want to copy again
  useTimeout(
    () => {
      setHasCopiedToClipboard(false);
    },
    hasCopiedToClipboard ? 3000 : null,
  );

  return (
    <>
      <MergeModal
        show={isShowingConfirmDeleteModal}
        title="Delete Access Key"
        centered
        bodyClassName="modal-extra-narrow overflow-hidden"
        dialogClassName="modal-extra-narrow"
        onHide={() => setIsShowingConfirmDeleteModal(false)}
      >
        <p>
          Are you sure you want to delete this remote production access key?
          <br />
          <b>This action is not reversible.</b>
        </p>
        <Button
          variant="danger"
          className="btn-block"
          onClick={() => {
            onClickDelete && onClickDelete();
            setIsShowingConfirmDeleteModal(false);
          }}
        >
          Delete key
        </Button>
        <Button
          variant="link"
          className="text-gray-50 btn-block"
          onClick={() => setIsShowingConfirmDeleteModal(false)}
        >
          Cancel
        </Button>
      </MergeModal>
      <OneLineStack
        className={classNames(
          "text-gray-700 align-items-center",
          !isEmbedded && "dashed-outline-rounded-container",
          !isOutlined && "no-outline-rounded-container",
          className,
        )}
        $gap="8px"
        style={style}
      >
        {isSecret && (
          <div className="dashed-outline-buttons-container">
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 0 }}
              overlay={
                <Tooltip id={"tooltip-eye-" + text}>
                  {isTextHidden ? "Show key" : "Hide key"}
                </Tooltip>
              }
            >
              <IconButton
                className={`fe fe-${isTextHidden ? "eye-off" : "eye"}`}
                $iconSize={iconSize}
                onClick={() => setIsTextHidden(!isTextHidden)}
              />
            </OverlayTrigger>
          </div>
        )}
        <TextContainer $backgroundColor={backgroundColor}>
          <Text style={{ color: foregroundColorCssValue }} className="overflow-hidden">
            {isTextHidden ? "•".repeat(text.length) : text}
          </Text>
        </TextContainer>
        <ColoredStack
          className="dashed-outline-buttons-container"
          $backgroundColor={backgroundColor}
          $gap="8px"
        >
          <CopyComponentWithIcon
            text={text}
            foregroundColor={foregroundColor}
            fontSize={iconSize}
          />
          {isUrl && <ExternalLinkWithIcon url={text} foregroundColor={foregroundColor} />}

          {onClickRegenerate && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 0 }}
              overlay={<Tooltip id={"tooltip-copy-" + text}>Regenerate Access Token</Tooltip>}
            >
              <IconButton
                className="fe fe-refresh-cw"
                $iconSize={iconSize}
                style={{ color: foregroundColorCssValue }}
                onClick={onClickRegenerate}
              />
            </OverlayTrigger>
          )}

          {onClickDelete && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 0 }}
              overlay={<Tooltip id={"tooltip-copy-" + text}>Delete Access Key</Tooltip>}
            >
              <IconButton
                className="fe fe-trash"
                $iconSize={iconSize}
                style={{ color: foregroundColorCssValue }}
                onClick={() => setIsShowingConfirmDeleteModal(true)}
              />
            </OverlayTrigger>
          )}
        </ColoredStack>
      </OneLineStack>
    </>
  );
};

export default DottedOutlineTextCard;
