import React from "react";
import styled from "styled-components";
import { Integration } from "../../../models/Entities";
import { APIRequestLogEntryIntegration } from "../../integrations-management/IntegrationsManagementEntities";
import { OrganizationAvatar, UserAvatarSize } from "../../shared-components/MergeAvatars";

interface Props {
  customText?: string;
  integration: Integration | APIRequestLogEntryIntegration;
}

const NameAndLogoContainer = styled.div`
  min-width: 0;
`;
const EllipsisTextDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IntegrationNameAndLogo = ({ integration, customText }: Props) => {
  return (
    <NameAndLogoContainer className="d-flex align-items-center">
      <OrganizationAvatar
        size={UserAvatarSize.xs}
        imageURL={integration.square_image}
        organizationName={integration.name}
        isCircle
        className="deprecated-mr-3 d-flex"
      />
      <EllipsisTextDiv>{customText || integration.name}</EllipsisTextDiv>
    </NameAndLogoContainer>
  );
};

export default IntegrationNameAndLogo;
