import { useEffect, useState } from "react";
import uniq from "lodash/uniq";
import startCase from "lodash/startCase";

import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { HookEvent } from "../enums";

export interface CategoryMap {
  [key: string]: string;
}

export const useWebhookOptions = () => {
  // state
  const [commonModelsOptions, setCommonModelsOptions] = useState<Array<string>>([]);
  const [modelToCategoryMap, setModelToCategoryMap] = useState<CategoryMap>({});
  const [isIssuesWebhookEnabled, setIsIssuesWebhookEnabled] = useState<boolean>(false);

  // event handlers
  const fetchOptions = () => {
    fetchWithAuth({
      path: "/integrations/configuration/webhooks/events",
      method: "GET",
      onResponse: (data) => {
        if (
          data.webhook_events.includes(HookEvent.ISSUES_CREATED) &&
          data.webhook_events.includes(HookEvent.ISSUES_RESOLVED)
        ) {
          setIsIssuesWebhookEnabled(true);
        }

        data.webhook_events = uniq(
          data.webhook_events
            // split length of 3: category.model.event
            .filter((commonModel: string) => commonModel.split(".").length === 3)
            // remove the event field (leave category.model pairing)
            .map(
              (commonModel: string) => `${commonModel.split(".")[0]}.${commonModel.split(".")[1]}`,
            )
            .sort(),
        );

        // create a map so the typeahead can display category given just the model map{cat:model}
        setModelToCategoryMap(
          data.webhook_events.reduce((accumulatingObj: Object, categoryModel: string) => {
            return {
              ...accumulatingObj,
              [categoryModel.split(".")[1]]: categoryModel.split(".")[0].toLowerCase(),
            };
          }, {}),
        );

        // options array lists all the Common Models
        setCommonModelsOptions(
          data.webhook_events.map((commonModel: string) => {
            return `${commonModel.split(".")[1]}`;
          }),
        );
      },
    });
  };

  // effects
  // initialize
  useEffect(() => {
    fetchOptions();
  }, []);

  return {
    commonModelsOptions,
    modelToCategoryMap,
    isIssuesWebhookEnabled,
  };
};
