import { Minus, Plus } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import type { ReactDatePickerProps } from "react-datepicker";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled, { css } from "styled-components";
import { palette, spectrum } from "../../../../styles/theme";
import ModalButton from "./ModalButton";
import type { ModalProps } from "./ModalProps";
import SkinnyModal from "./SkinnyModal";

/**
 * If date only, it shows a single date picker + the ability to add a range if desired.
 * With a ranged state, it shows a range picker with the ability to remove the range.
 * With the date and time version, there's no conversion to a range allowed.
 */
export type DisplayType = "date" | "datetime" | "range";

export type Props = ModalProps & {
  /**
   * Starting display type, may be mutated internally
   */
  displayType: DisplayType;

  /**
   * A callback to use when a date (and possible time) pair is selected
   */
  saveDates: (start: Date, end: Date | null) => void;

  /**
   * If exists, populates the initial start date/time fields
   */
  start?: Date;

  /**
   * If exists, populates the initial end date/time fields
   */
  end?: Date;
};

// How large each of the time segments are for the time picker
const TIME_INTERVAL_MIN = 30;

/**
 * Unique ids used below
 */
const IDS = {
  DATE_START: "datePickerModal.datestart",
  TIME_START: "datePickerModal.timestart",
  ALLDAY_START: "datePickerModal.alldaystart",
  DATE_END: "datePickerModal.dateend",
  TIME_END: "datePickerModal.timeend",
  ALLDAY_END: "datePickerModal.alldayend",
} as const;

/**
 * Type guard for casting into a date from a date or date array or null
 */
const isDate = (date: Date | [Date, Date] | null): date is Date => !!(date as Date)?.getDate;

// Overrides date picker css to make it span full width
const CustomModal = styled(SkinnyModal)<{ $isSingleColumn: boolean }>`
  & .modal-dialog {
    ${({ $isSingleColumn }) =>
      $isSingleColumn
        ? css`
            max-width: 305px;
          `
        : css`
            max-width: 442px;
          `}
  }
  /* Can't target these another way */
  & .react-datepicker-wrapper {
    display: block;
  }
  & .react-datepicker--time-only {
    border: none;
  }
  & .react-datepicker__time-container {
    border: none;
    box-shadow: 0px 6px 30px -2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
  }
  & .react-datepicker__time {
    border-radius: 6px !important;
  }
  & .react-datepicker__time-container,
  && .react-datepicker__time-box {
    width: 220px;
    text-align: left;
    font-size: 15px;
    line-height: 2;
    color: ${palette.black};
  }
  & .react-datepicker__time-list {
    height: 70px;
  }
  & .react-datepicker__time-list-item {
    height: 36px !important;
    padding: 6px 12px !important;
    &.react-datepicker__time-list-item--selected {
      background: ${palette.blue} !important;
    }
    &:hover:not(.react-datepicker__time-list-item--selected),
    &:focus,
    &:active {
      background: ${spectrum.slate0} !important;
      color: ${palette.blue};
    }
  }
  & .react-datepicker__header--time--only {
    display: none;
  }
`;

const Error = styled(Form.Text)`
  color: ${palette.red};
`;

/**
 * Customized date input field for the date fields
 */
const InputField = styled(Form.Control)<{ $isValid: boolean }>`
  width: 100%;
  ${({ $isValid }) =>
    !$isValid &&
    css`
      border-color: ${palette.red};
    `}
`;

/**
 * Left aligned column form group label
 */
const ColumnLabel = styled.strong`
  float: left;
`;

/**
 * Right aligned column button
 */
const RangeButtonGroup = styled.button`
  border: none;
  background: none;
  padding: 2px 6px;
  font-size: 12px;
  float: right;
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${palette.blue};
  &:hover {
    color: ${palette.blueHover};
  }
`;

/**
 * Adds our custom styling for the checkbox (modeled via the
 * before and after pseudos). The check image is in the :after
 */
const Checkbox = styled(Form.Check)`
  &:hover {
    label:before,
    label:after {
      background: ${spectrum.blue10};
    }
  }
  label {
    font-size: 15px;
    &:before,
    &:after {
      top: 0.1rem;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid ${palette.blue};
      background: ${palette.white};
      transition: background-color 0.2s ease;
    }
  }
  &&& {
    input:checked + label:after {
      background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 8 8'%3e%3cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
      background-size: 10px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;

/**
 * Places a divider approximately equal in height to the date fields
 */
const Divider = styled(Minus)`
  margin-top: 3rem;
`;

/**
 * A helper function to calculate if two dates are < 24 hours apart.
 * Start date is 1, end date is 2.
 */
const isUnder24HoursApart = (start: Date, end: Date, checkTime: boolean) =>
  checkTime && (end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24 < 1;

/**
 * A helper function to calculate if two dates share the same day of
 * the month and year
 */
const hasSameDay = (start: Date, end: Date, checkTime: boolean) =>
  isUnder24HoursApart(start, end, checkTime) && start.getDay() === end.getDay();

/**
 * The modal for picking a date/time (possibly including a range).
 */
const DatePickerModal = ({
  isOpen,
  closeModal,
  displayType: externalDisplayType,
  saveDates,
  start: existingStart,
  end: existingEnd,
}: Props) => {
  const today = new Date();
  const [calendarDisplayMonth, setCalendarDisplayMonth] = useState(today.getMonth());
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(existingStart ?? null);
  const [endDate, setEndDate] = useState<Date | null>(existingEnd ?? null);
  const [displayType, setDisplayType] = useState(externalDisplayType);

  // All possible form errors
  const [formError, setFormError] = useState<Record<"startDate" | "endDate", string | null>>({
    startDate: null,
    endDate: null,
  });

  // Only the form field ids as booleans
  const [values, setValues] = useState<
    Record<keyof Pick<typeof IDS, "ALLDAY_START" | "ALLDAY_END">, boolean>
  >({
    ALLDAY_START: false,
    ALLDAY_END: false,
  } as const);

  const resetErrorState = () => {
    setIsValidated(false);
    setFormError({ startDate: null, endDate: null });
  };

  /**
   * Sets start date safely (making sure to properly account for the
   * current end date if it exists/the all day end value)
   */
  const setSafeStartDate = useCallback(
    (date: Date) => {
      resetErrorState();

      if (!endDate) {
        setStartDate(date);
        return;
      }
      // This is a start date + we have an end date so set start date to a max of the end date - TIME_INTERVAL_MIN
      const maxStartDate = new Date(endDate);
      maxStartDate.setMinutes(maxStartDate.getMinutes() - TIME_INTERVAL_MIN);
      if (hasSameDay(date, endDate, !values.ALLDAY_END) && date.getTime() >= endDate.getTime()) {
        setStartDate(maxStartDate);
      } else {
        setStartDate(date);
      }
    },
    [endDate, values.ALLDAY_END],
  );

  /**
   * Sets end date safely (making sure to properly account for the
   * current start date if it exists/the all day start value)
   */
  const setSafeEndDate = useCallback(
    (date: Date) => {
      resetErrorState();

      if (!startDate) {
        setEndDate(date);
        return;
      }
      // This is an end date + we have a start date so set end date to a min of the start date + TIME_INTERVAL_MIN
      const minStartDate = new Date(startDate);
      minStartDate.setMinutes(minStartDate.getMinutes() + TIME_INTERVAL_MIN, 0, 0);
      if (
        hasSameDay(startDate, date, !values.ALLDAY_START) &&
        startDate.getTime() >= date.getTime()
      ) {
        setEndDate(minStartDate);
      } else {
        setEndDate(date);
      }
    },
    [startDate, values.ALLDAY_START],
  );

  // Changes to display type later on need to be processed
  useEffect(() => setDisplayType(externalDisplayType), [externalDisplayType]);

  // Changes to start date passed in update the component - intentionally ONLY the existingStart is dependent
  useEffect(() => {
    if (existingStart) {
      const roundedStart = new Date(existingStart);
      const min = roundedStart.getMinutes();
      roundedStart.setMinutes(min < TIME_INTERVAL_MIN ? 0 : TIME_INTERVAL_MIN, 0, 0);
      setSafeStartDate(roundedStart);
      setValues((values) => ({ ...values, ALLDAY_START: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingStart]);

  // Changes to end date passed in update the component - intentionally ONLY the existingEnd is dependent
  useEffect(() => {
    if (existingEnd) {
      const roundedEnd = new Date(existingEnd);
      const min = roundedEnd.getMinutes();
      roundedEnd.setMinutes(min < TIME_INTERVAL_MIN ? 0 : TIME_INTERVAL_MIN);
      setSafeEndDate(roundedEnd);
      setValues((values) => ({ ...values, ALLDAY_END: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingEnd]);

  // Saves both dates, handling all day settings
  const saveRangedDates = () => {
    if (!startDate || !endDate) {
      setIsValidated(false);
      setFormError({
        startDate: startDate ? null : "A start date is required",
        endDate: endDate ? null : "An end date is required",
      });
      return;
    }

    const actualStart = new Date(startDate);
    const actualEnd = new Date(endDate);
    if (values.ALLDAY_START) {
      // Move the start to midnight
      actualStart.setHours(0, 0, 0, 0);
    }
    if (values.ALLDAY_END) {
      // Move the end to midnight
      actualEnd.setHours(23, 59, 59, 999);
    }
    saveDates(actualStart, actualEnd);
    closeModal();
  };

  // Validates, saves, and closes
  const saveAndClose = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!isSaveEnabled) {
      return;
    }
    setIsSaveEnabled(false);
    switch (displayType) {
      case "date":
      case "datetime":
        if (!startDate) {
          setIsValidated(false);
          setFormError({ startDate: "A date is required", endDate: null });
        } else {
          saveDates(startDate, null);
          closeModal();
        }
        break;
      case "range":
        saveRangedDates();
    }
    setIsSaveEnabled(true);
  };

  const customInput = (isStart: boolean) => {
    return <InputField $isValid={formError[isStart ? "startDate" : "endDate"] === null} />;
  };

  /**
   * Creates most props for the ReactDatePickers
   */
  const datePickerProps = (isStart: boolean): ReactDatePickerProps => ({
    calendarClassName: "date-picker-font",
    startDate,
    endDate,
    maxDate: today,
    placeholderText: "Date",
    showMonthDropdown: true,
    showYearDropdown: true,
    dropdownMode: "select",

    // Handles all cases of date changes + make sure the dates are at least TIME_INTERVAL_MIN min apart (start and end) in order!
    onChange: (date) => {
      // Set either start/end date or both - but make sure to max/min it
      if (date === null) {
        return;
      }

      // Don't think this ever happens but it's possible - just set each
      if (!isDate(date)) {
        setSafeStartDate(date[0]);
        setSafeEndDate(date[1]);
        return;
      }

      if (isStart) {
        setSafeStartDate(date);
      } else {
        setSafeEndDate(date);
      }
    },

    // The input is a standard Form.Control
    customInput: customInput(isStart),
  });

  /**
   * Creates most props for the time pickers
   */
  const timePickerProps = (isStart: boolean): ReactDatePickerProps => ({
    ...datePickerProps(isStart),
    placeholderText: "Time",
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeIntervals: TIME_INTERVAL_MIN,
    showPopperArrow: false,
    dateFormat: "hh:mm a",
  });

  const AllDayFormGroup = ({ id }: { id: keyof typeof values }) => (
    <Form.Group className="deprecated-mt-1 deprecated-mb-2" key={id} controlId={id}>
      <Checkbox
        custom
        type="checkbox"
        id={id}
        name={id}
        label="All day"
        checked={values[id]}
        value={values[id]}
        onChange={() =>
          setValues((existing) => {
            resetErrorState();
            // Make sure to update both the values and then reset the dates safely
            if (startDate) {
              setSafeStartDate(startDate);
            }
            if (endDate) {
              setSafeEndDate(endDate);
            }
            return { ...existing, [id]: !existing[id] };
          })
        }
      />
    </Form.Group>
  );

  const startDateFormGroup = (
    <Form.Group className="deprecated-mb-3" controlId={IDS.DATE_START}>
      <ReactDatePicker
        {...datePickerProps(true)}
        selected={startDate}
        selectsStart
        maxDate={endDate ?? today}
        dayClassName={(date) =>
          date.getMonth() !== calendarDisplayMonth && date < today ? "day-disabled" : ""
        }
        onMonthChange={(date) => setCalendarDisplayMonth(date.getMonth())}
      />
      <Form.Control.Feedback type="invalid">Please provide a starting date</Form.Control.Feedback>
    </Form.Group>
  );

  // Minimum starting time is always midnight
  const minStartTime = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  // Maximum starting time is end time - TIME_INTERVAL_MIN min if they're under a day apart, otherwise 11:59:59pm
  const maxStartTime = useMemo(() => {
    const date = new Date();
    if (endDate && (!startDate || hasSameDay(startDate, endDate, true))) {
      if (values.ALLDAY_END) {
        // Last interval possible for all day end
        date.setHours(23, 60 - TIME_INTERVAL_MIN, 59, 999);
      } else {
        // Otherwise end hours are precise
        date.setHours(endDate.getHours(), endDate.getMinutes() - TIME_INTERVAL_MIN, 0, 0);
      }
      return date;
    }

    // Default is midnight
    date.setHours(23, 59, 59, 999);
    return date;
  }, [endDate, startDate, values.ALLDAY_END]);

  const startTimeFormGroup = (
    <Form.Group className="deprecated-mb-3" controlId={IDS.TIME_START}>
      <ReactDatePicker
        {...timePickerProps(true)}
        selected={startDate}
        selectsStart
        minDate={minStartTime}
        maxDate={endDate ?? today}
        minTime={minStartTime}
        maxTime={maxStartTime}
      />
      <Form.Control.Feedback type="invalid">Please provide a starting time</Form.Control.Feedback>
    </Form.Group>
  );

  const endDateFormGroup = (
    <Form.Group className="deprecated-mb-3" controlId={IDS.DATE_END}>
      <ReactDatePicker
        {...datePickerProps(false)}
        selected={endDate}
        selectsEnd
        minDate={startDate}
      />
      <Form.Control.Feedback type="invalid">Please provide an ending date</Form.Control.Feedback>
    </Form.Group>
  );

  // Minimum end time is the start date + TIME_INTERVAL_MIN min if they're under a day apart, otherwise midnight, or 12:30am if it's all day start
  const minEndTime = useMemo(() => {
    const date = new Date();
    if (startDate && (!endDate || hasSameDay(startDate, endDate, true))) {
      if (values.ALLDAY_START) {
        // All day start allows only the last interval of the day
        date.setHours(23, 59 - TIME_INTERVAL_MIN, 59, 999);
      } else {
        // Otherwise start hours are precise
        date.setHours(startDate.getHours(), startDate.getMinutes() + TIME_INTERVAL_MIN, 0, 0);
      }
      return date;
    }

    // Default is midnight
    date.setHours(0, 0, 0, 0);
    return date;
  }, [startDate, endDate, values.ALLDAY_START]);

  // Maximum end time is always 11:59:59pm
  const maxEndTime = useMemo(() => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  }, []);

  const endTimeFormGroup = (
    <Form.Group className="deprecated-mb-3" controlId={IDS.TIME_END}>
      <ReactDatePicker
        {...timePickerProps(false)}
        selected={endDate}
        selectsEnd
        minDate={startDate}
        minTime={minEndTime}
        maxTime={maxEndTime}
      />
      <Form.Control.Feedback type="invalid">Please provide an ending time</Form.Control.Feedback>
    </Form.Group>
  );

  const startTimeVisible =
    displayType === "datetime" || (displayType === "range" && !values.ALLDAY_START);

  const endTimeVisible =
    displayType === "datetime" || (displayType === "range" && !values.ALLDAY_END);

  const addRangeButtonVisible = displayType === "date";

  // Stacks all the starting date/time groups as display type calls for
  const column1 = (
    <React.Fragment key="column1">
      <Form.Label className="deprecated-mb-3 w-100 d-inline-flex justify-content-between align-items-center">
        <ColumnLabel>{displayType === "range" ? "Start Date" : "Date"}</ColumnLabel>
        {addRangeButtonVisible && (
          <RangeButtonGroup
            onClick={() => {
              resetErrorState();
              setDisplayType("range");
            }}
          >
            <Plus size={12} /> Add range
          </RangeButtonGroup>
        )}
      </Form.Label>
      {startDateFormGroup}
      {startTimeVisible && startTimeFormGroup}
      {displayType === "range" && <AllDayFormGroup id="ALLDAY_START" />}
    </React.Fragment>
  );

  // Stacks the content for the ending date/time if range type
  const column2 = displayType === "range" && (
    <React.Fragment key="column2">
      <Form.Label className="deprecated-mb-3 w-100 d-inline-flex justify-content-between align-items-center">
        <ColumnLabel>End Date</ColumnLabel>
        <RangeButtonGroup
          onClick={() => {
            resetErrorState();
            setDisplayType("date");
          }}
        >
          <Minus size={12} /> Remove
        </RangeButtonGroup>
      </Form.Label>
      {endDateFormGroup}
      {endTimeVisible && endTimeFormGroup}
      <AllDayFormGroup id="ALLDAY_END" />
    </React.Fragment>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      closeModal={closeModal}
      title="Select date"
      $isSingleColumn={displayType !== "range"}
    >
      <Form onSubmit={saveAndClose} validated={isValidated} noValidate>
        <div className="d-flex justify-content-equal w-100">
          <div className={!column2 ? "w-100" : undefined}>{column1}</div>
          {!!column2 && <Divider size={14} className="deprecated-mx-2" />}
          {!!column2 && <div>{column2}</div>}
        </div>
        {Object.values(formError).map(
          (error) =>
            error && (
              <Error key={error} className="deprecated-mt-2 deprecated-mb-3">
                {error}
              </Error>
            ),
        )}
        <ModalButton
          className="deprecated-mt-3"
          variant="dark"
          type="submit"
          disabled={!isSaveEnabled || Object.values(formError).some((value) => value !== null)}
        >
          {isSaveEnabled ? "Save" : "Saving..."}
        </ModalButton>
      </Form>
    </CustomModal>
  );
};

export default DatePickerModal;
