import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import styled from "styled-components";
import TileStep, { TitleText } from "../helpers/TileStep";
import { ReactComponent as LinkVector } from "../../src/link-over-ui.svg";
import { Wand } from "lucide-react";
import { palette, spectrum } from "../../../../styles/theme";
import LeftSideBar from "../helpers/LeftSideBar";
import LeftSideBarSpacing, { LeftSideBarCol, PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import LiveWaitingIndicator from "../../../shared-components/LiveWaitingIndicator";
import {
  DOCS_LINKING_FLOW_GET_STARTED_PATH,
  DOCS_MAGIC_LINK_PATH,
} from "../../../../router/RouterUtils";
import useDashboardOnboardingStatus from "../../utils/useDashboardOnboardingStatus";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import { FormErrorData, fetchWithAuth } from "../../../../api-client/APIClient";
import { showErrorToast } from "../../../shared-components/Toasts";
import AddProductionLinkedAccountButton from "../../../integrations-management/linked-accounts/AddProductionLinkedAccountButton";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  onNewLinkedAccount: () => void;
}

const FillerDiv = styled.div`
  height: 32px;
  margin-bottom: 17px;
`;

const MagicLinkBox = styled.div`
  border-radius: 14px;
  padding: 20px;
  word-wrap: break-word;
  font-size: 12px;
  width: 240px;
  background: #f5f8ff;
  margin-top: 56px;
  gap: 16px;
  margin-left: 32px;
`;

const MagicLinkBoxResize = styled.div`
  border-radius: 14px;
  padding: 20px;
  word-wrap: break-word;
  font-size: 12px;
  width: 100%;
  background: #f5f8ff;
  margin-top: 32px;
  gap: 16px;
`;

const StyledWand = styled(Wand)`
  color: ${palette.indigo};
`;

const SideBoxH3 = styled(DeprecatedH3)`
  display: flex;
  align-items: center;
  margin: 0px 0px 16px;
  font-size: 16px;
`;

const MagicLinkBoxCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  @media (max-width: 1329px) {
    display: none;
  }
`;

const MagicLinkBoxResizeCol = styled.div`
  display: none;
  @media (max-width: 1329px) {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
  }
`;
const StyledP = styled.p`
  margin-bottom: 9px;
`;

const RelativeContainer = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 5px;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  bottom: 20px;
`;

const MountainHolder = styled.div`
  position: absolute;
`;

const SpacingDiv = styled.div`
  height: 32px;
`;

const RowContainer = styled(Row)`
  margin: 0px;
  padding: 0px;
`;

const StyledTextForTooltip = styled.div`
  font-size: 15px;
  margin-left: 2px;
`;

/*
 ** The 1330px is selected based on the same media queries used 'DashboardOnboardingWrapper' to transition from the full sized
 *  Navigation bars to the dropdown. We use this same media query to transition the rest of the page to be more mobile responsive
 */
const ContainerCol = styled(Col)`
  @media (min-width: 1330px) {
    flex: 0 0 75%;
    max-width: 75%;
  }
`;

const Mountain = require("../../src/mountain-image.png");

const AddLinkToAppSection = () => {
  const dashboardOnboardingStatus = useDashboardOnboardingStatus();

  return (
    <>
      <RowContainer>
        <ContainerCol>
          <Row>
            <PaddingLessCol>
              <TitleText>Add Link to App</TitleText>
            </PaddingLessCol>
          </Row>
          <Row>
            <LeftSideBar stepNumber="1" isNormalStep />
            <TileStep
              img={<LinkVector />}
              title={
                <>
                  <div className="d-flex align-items-center">
                    Installation
                    <StyledTextForTooltip>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 0 }}
                        overlay={
                          <Tooltip className="deprecated-ml-10" id="send-api-request-tooltip">
                            In order to successfully complete this step, you must pass your
                            Production Access Key in as a header to get a link token.
                          </Tooltip>
                        }
                      >
                        <i className="deprecated-ml-2 fe fe-info text-gray-50" />
                      </OverlayTrigger>
                    </StyledTextForTooltip>
                  </div>
                </>
              }
              subtitle={
                <>
                  Add Merge Link to your app using{" "}
                  <a target="_blank" href={DOCS_LINKING_FLOW_GET_STARTED_PATH} rel="noreferrer">
                    this guide
                  </a>{" "}
                  in our docs.
                </>
              }
              stepNumber={1}
            />
          </Row>
          <Row>
            <LeftSideBarSpacing />
            <PaddingLessCol>
              <SpacingDiv />
            </PaddingLessCol>
          </Row>
          <Row>
            <LeftSideBar stepNumber="2" isNormalStep={false} />
            <TileStep
              img={
                <RelativeContainer>
                  <MountainHolder>
                    <img src={Mountain} height={100}></img>
                  </MountainHolder>
                  <AbsoluteDiv>
                    {dashboardOnboardingStatus?.has_opened_production_link ? (
                      <LiveWaitingIndicator color="green" />
                    ) : (
                      <LiveWaitingIndicator color="red" />
                    )}
                  </AbsoluteDiv>
                </RelativeContainer>
              }
              title="Test Run"
              subtitle={
                <>
                  <p>Once you've added Merge Link to your app, visit your domain and open it.</p>
                  Merge Link will give us the 👍 and we'll check this one off your list!
                </>
              }
              stepNumber={2}
            />
          </Row>
          <Row>
            <LeftSideBarCol className="col-2 align-items-center"></LeftSideBarCol>
            <PaddingLessCol>
              <MagicLinkBoxResizeCol>
                <MagicLinkBoxResize>
                  <SideBoxH3>
                    <StyledWand className="deprecated-mr-3" />
                    Magic Link
                  </SideBoxH3>
                  <span>
                    <StyledP>Want to start using Merge Link without writing any code?</StyledP>
                    View our{" "}
                    <a target="_blank" href={DOCS_MAGIC_LINK_PATH} rel="noreferrer">
                      Magic Link
                    </a>{" "}
                    guide to send end users a URL to authorize their integrations!
                  </span>
                  <AddProductionLinkedAccountButton isGetStarted />
                </MagicLinkBoxResize>
              </MagicLinkBoxResizeCol>
            </PaddingLessCol>
          </Row>
        </ContainerCol>
        <MagicLinkBoxCol className="col-3 offset-12">
          <Row>
            <FillerDiv />
          </Row>
          <MagicLinkBox>
            <SideBoxH3>
              <StyledWand className="deprecated-mr-3" />
              Magic Link
            </SideBoxH3>
            <span>
              <StyledP>Want to start using Merge Link without writing any code?</StyledP>
              View our{" "}
              <a target="_blank" href={DOCS_MAGIC_LINK_PATH} rel="noreferrer">
                Magic Link
              </a>{" "}
              guide to send end users a URL to authorize their integrations, instead of
              authenticating via Link in your app!
            </span>
            <AddProductionLinkedAccountButton isGetStarted />
          </MagicLinkBox>
        </MagicLinkBoxCol>
      </RowContainer>
    </>
  );
};

export default AddLinkToAppSection;
