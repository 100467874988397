import React from "react";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingTarget,
} from "../../../../models/Entities";

import ConfigurationFieldMappingCard from "./ConfigurationFieldMappingCard";
import useAppContext from "../../../context/useAppContext";

type Props = {
  category: string;
  fieldMappingsForAllCommonModels: { [common_model_id: string]: FieldMappingTarget[] };
  commonModelsToMappingConfigs: { [common_model_id: string]: FieldMappingCommonModelConfiguration };
  updateFieldMappingConfigurations: (
    updatedConfiguration: FieldMappingCommonModelConfiguration,
  ) => void;
  updateFieldMappingTargets: (updatedCustomMappingTarget: FieldMappingTarget) => void;
  refreshFieldMappingsAndConfigurations: () => void;
  setIsLoading: (isLoading: boolean) => void;
  setFieldMappingTargetToDelete: (customMappingTarget: FieldMappingTarget) => void;
  setHasError: (hasError: boolean) => void;
};

const ConfigurationFieldMappingsFieldsList = ({
  category,
  fieldMappingsForAllCommonModels,
  commonModelsToMappingConfigs,
  updateFieldMappingConfigurations,
  updateFieldMappingTargets,
  refreshFieldMappingsAndConfigurations,
  setIsLoading,
  setFieldMappingTargetToDelete,
  setHasError,
}: Props) => {
  const { user } = useAppContext();
  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);
  return (
    <div className={isFieldMappingV2Enabled ? "mt-5" : ""}>
      {Object.entries(fieldMappingsForAllCommonModels).map(([commonModelID], index) => (
        <ConfigurationFieldMappingCard
          key={`${commonModelID}-${index}`}
          category={category}
          commonModelID={commonModelID}
          fieldMappingTargets={fieldMappingsForAllCommonModels[commonModelID]}
          commonModelsToMappingConfig={commonModelsToMappingConfigs[commonModelID]}
          refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
          setIsLoading={setIsLoading}
          setFieldMappingTargetToDelete={setFieldMappingTargetToDelete}
          updateFieldMappingConfigurations={updateFieldMappingConfigurations}
          updateFieldMappingTargets={updateFieldMappingTargets}
          setHasError={setHasError}
        />
      ))}
    </div>
  );
};

export default ConfigurationFieldMappingsFieldsList;
