import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { LinkedAccount } from "../../../../models/Entities";
import { displayNameForAPICategory } from "../../../../models/Helpers";

import { SectionHeaderWrapper } from "../../../shared-components/MergeLayouts";
import {
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  getLinkedAccountDetailFieldMappingsPathForId,
  navigateToLinkedAccountFieldMappings,
} from "../../../../router/RouterUtils";
import MergeTable from "../../../shared-components/MergeTable";
import ContentLoader from "react-content-loader";
import EmptyStateWrapper from "../../../shared-components/EmptyStateWrapper";
import IntegrationsManagementFilter from "../../../integrations-management/shared/IntegrationsManagementFilter";
import IntegrationNameAndLogo from "../IntegrationNameAndLogo";
import { palette, spectrum } from "../../../../styles/theme";
import { FieldMappingsDocsButton } from "./ConfigurationFieldMappingsPageContainer";
import { Link, useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import PaginationFooter from "../../../shared-components/PaginationFooter";
import { getCursorPath } from "../../../../services";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import { BlueBetaBadge } from "../../../shared-components/MergeBadges";
import {
  SectionHeaderBadgeMarginedDiv,
  SectionHeaderTitleInlineDiv,
} from "./utils/FieldMappingUtils";
import { Alert } from "@merge-api/merge-javascript-shared";
import { LinkableTableCell } from "../../../integrations-management/linked-accounts/LinkedAccountTable";
import useAppContext from "../../../context/useAppContext";

const NoFieldMappingText = styled.div`
  color: #50545b;
`;

export const FieldMappingTableText = styled.p`
  color: var(--blue40);
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 0px !important;
`;

export const NoFieldMappingTableText = styled(FieldMappingTableText)`
  color: var(--gray50) !important;
`;

const StyledTableHeader = styled.th`
  border-bottom: 1px solid #edf2f9 !important;
`;

const ConfigurationFieldMappingsLinkedAccountTable = () => {
  const history = useHistory();
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[] | null>();
  const [paramsPath, setParamsPath] = useState<string | undefined>();
  const [isTestAccounts, setIsTestAccounts] = useState<boolean>(false);

  const [previousPageURL, setPreviousPageURL] = useState<string>();
  const [nextPageURL, setNextPageURL] = useState<string>();

  const { user } = useAppContext();
  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);

  const rootURLPath =
    "/integrations/linked-accounts?" + (isTestAccounts ? "is_test_account=true&" : "");

  const fetchLinkedAccountsWithCursor = (cursorPath?: string | undefined) => {
    setLinkedAccounts(null);

    fetchWithAuth({
      path: rootURLPath + (cursorPath ? getCursorPath(cursorPath) : paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        setNextPageURL(data.next);
        setPreviousPageURL(data.previous);
        setLinkedAccounts(data.results);
      },
    });
  };

  useEffect(() => {
    let cancelled = false;
    setLinkedAccounts(null);
    fetchWithAuth({
      path: rootURLPath + (paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        if (!cancelled) {
          setNextPageURL(data.next);
          setPreviousPageURL(data.previous);
          setLinkedAccounts(data.results);
        }
      },
    });

    return () => {
      cancelled = true;
    };
  }, [paramsPath, rootURLPath]);

  const header = (
    <>
      <th scope="col" className="linked-account-table-user-column">
        Organization
      </th>
      <th scope="col">Integration</th>
      <th scope="col">Category</th>
      <th scope="col">{isFieldMappingV2Enabled ? "Field Mappings" : ""}</th>
      {isFieldMappingV2Enabled && <StyledTableHeader scope="col"></StyledTableHeader>}
    </>
  );

  // Custom comparison function
  function compareLinkedAccounts(a: LinkedAccount, b: LinkedAccount) {
    if (a.custom_mapping_instance_count === b.custom_mapping_instance_count) {
      // If custom_mapping_instance_count is the same, sort alphabetically by name
      return a.end_user.organization_name.localeCompare(b.end_user.organization_name);
    } else {
      // Sort by custom_mapping_instance_count
      return b.custom_mapping_instance_count - a.custom_mapping_instance_count;
    }
  }

  const gatedLinkedAccounts = isFieldMappingV2Enabled
    ? linkedAccounts?.sort(compareLinkedAccounts)
    : linkedAccounts;

  const linkedAccountsRows =
    gatedLinkedAccounts &&
    gatedLinkedAccounts?.length > 0 &&
    gatedLinkedAccounts.sort(compareLinkedAccounts).map((linkedAccount) => (
      <React.Fragment key={linkedAccount.id}>
        <tr
          className="table-link"
          key={linkedAccount.id}
          style={{ borderBottomWidth: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            if (!linkedAccount.completed_at) {
              return;
            }
            navigateToLinkedAccountFieldMappings(history, linkedAccount.id);
          }}
        >
          <LinkableTableCell
            linkedAccount={linkedAccount}
            title={linkedAccount.end_user.organization_name}
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            className="linked-account-table-user-column"
            style={{ border: "0px", paddingLeft: "24px" }}
          >
            {linkedAccount.end_user.organization_name}
          </LinkableTableCell>
          <LinkableTableCell
            linkedAccount={linkedAccount}
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            <IntegrationNameAndLogo integration={linkedAccount.integration} />
          </LinkableTableCell>

          <LinkableTableCell
            linkedAccount={linkedAccount}
            className="text-gray-50"
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            {displayNameForAPICategory(linkedAccount.category)}
          </LinkableTableCell>
          <LinkableTableCell
            linkedAccount={linkedAccount}
            className="text-gray-50"
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            {isFieldMappingV2Enabled &&
              (linkedAccount.custom_mapping_instance_count > 0 ? (
                <FieldMappingTableText>
                  {linkedAccount.custom_mapping_instance_count === 1
                    ? `${linkedAccount.custom_mapping_instance_count} Field Mapping`
                    : `${linkedAccount.custom_mapping_instance_count} Field Mappings`}{" "}
                </FieldMappingTableText>
              ) : (
                <NoFieldMappingTableText> 0 Field Mappings</NoFieldMappingTableText>
              ))}
            {!isFieldMappingV2Enabled &&
              (linkedAccount.custom_mapping_instance_count > 0 ? (
                <span className={classNames("badge badge-soft-primary")}>
                  {linkedAccount.custom_mapping_instance_count === 1
                    ? `${linkedAccount.custom_mapping_instance_count} Field Mapping`
                    : `${linkedAccount.custom_mapping_instance_count} Field Mappings`}
                </span>
              ) : (
                <span className={classNames("badge badge-soft-secondary")}>
                  <NoFieldMappingText>0 Field Mappings</NoFieldMappingText>
                </span>
              ))}
          </LinkableTableCell>
          <LinkableTableCell
            linkedAccount={linkedAccount}
            className="text-gray-50"
            toPath={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
            style={{ border: "0px", paddingLeft: "16px" }}
          >
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip-top">Review linked account details</Tooltip>}
            >
              <ClickableContainer>
                <span className="fe fe-chevron-right text-gray-50 deprecated-ml-3" />
              </ClickableContainer>
            </OverlayTrigger>
          </LinkableTableCell>
        </tr>
        <tr>
          <td
            colSpan={6}
            className={classNames("py-0 bg-lighter", "linked-account-table-row-border")}
            style={{
              borderTop: "0px",
              borderBottom: `1px solid ${palette.border}`,
            }}
          ></td>
        </tr>
      </React.Fragment>
    ));

  const content = (
    <>
      {linkedAccounts ? (
        linkedAccounts.length ? (
          linkedAccountsRows
        ) : (
          <tr>
            <td colSpan={6}>
              <EmptyStateWrapper isTable title="No Linked Accounts" />
            </td>
          </tr>
        )
      ) : (
        Array.from({ length: 25 }).map((_, i) => (
          <tr key={`linked-account-skel-row-${i}`}>
            <td className="text-gray-50">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td className="text-gray-50">
              <div className="d-flex align-items-center">
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </td>
            <td className="text-gray-50">
              <ContentLoader
                speed={1.4}
                width={80}
                height={20}
                viewBox="0 0 80 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
              </ContentLoader>
            </td>
            <td />
          </tr>
        ))
      )}
    </>
  );

  return (
    <>
      <Row>
        <Col>
          <SectionHeaderWrapper
            title={
              <SectionHeaderTitleInlineDiv>
                {isFieldMappingV2Enabled ? "Linked Account Field Mappings" : "Field Mappings"}
                <SectionHeaderBadgeMarginedDiv>
                  {!isFieldMappingV2Enabled && <BlueBetaBadge />}
                </SectionHeaderBadgeMarginedDiv>
              </SectionHeaderTitleInlineDiv>
            }
            subtitle={
              <>
                <p className="deprecated-mb-3">
                  {isFieldMappingV2Enabled
                    ? "Select a Linked Account to view and modify its Field Mappings."
                    : "Select a Linked Account to modify its Field Mappings."}
                </p>
                <p className="deprecated-mb-3">
                  {isFieldMappingV2Enabled
                    ? "Add and configure your organization-wide target fields for Field Mapping in "
                    : "Configure mappable fields in "}
                  <Link to={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH}>
                    <b>Target Fields</b>
                  </Link>
                  .
                </p>
              </>
            }
            headerRightHandContent={FieldMappingsDocsButton}
          >
            <>
              {!isFieldMappingV2Enabled && (
                <Alert className="mb-6 mt-6" color="yellow" showWarningIcon>
                  Remote Data must be enabled for a given Common Model to map fields for that model.
                </Alert>
              )}
              <IntegrationsManagementFilter
                isFieldMappingsLinkedAccounts
                isTestAccounts={isTestAccounts}
                setIsTestAccounts={setIsTestAccounts}
                setParamsPath={setParamsPath}
              />
              <MergeTable header={header} content={content} hasMorePaddingOnFirstElement />
            </>
          </SectionHeaderWrapper>
          <PaginationFooter
            hasPrevious={!!previousPageURL}
            hasNext={!!nextPageURL}
            onPreviousClick={() => {
              fetchLinkedAccountsWithCursor(previousPageURL);
            }}
            onNextClick={() => {
              fetchLinkedAccountsWithCursor(nextPageURL);
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationFieldMappingsLinkedAccountTable;
