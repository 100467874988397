import React from "react";
import styled from "styled-components";
import ScopesDropdown from "../data/ScopesDropdown";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { User } from "lucide-react";
import AnimatedChevron from "../../../../configuration/common-models/AnimatedChevron";
import EnabledDisabledDiv from "./components/EnabledDisabledDiv";
import { ToggleLeftIcon, ToggleRightIcon } from "lucide-react";

const StyledDropdownItem = styled(Dropdown.Item)`
  &:hover {
    background-color: #eaeef3 !important;
  }
`;

const DropdownMenuOption = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: black;
`;

export interface LinkedAccountSettingToggleProps {
  onDropdownSelected: (eventKey: LinkedAccountSettingToggleDropdownOption) => void;
  isSettingEnabled: boolean;
  isDefaultOverridden: boolean;
  includeToggleIcon?: boolean;
}

export enum LinkedAccountSettingToggleDropdownOption {
  ENABLE = "ENABLE",
  DISABLE = "DISABLE",
  RESET = "RESET",
}

const LinkedAccountSettingToggle: React.FC<LinkedAccountSettingToggleProps> = ({
  onDropdownSelected,
  isSettingEnabled,
  isDefaultOverridden,
  includeToggleIcon = false,
}: LinkedAccountSettingToggleProps) => {
  return (
    <div className="flex items-center flex-nowrap">
      <ScopesDropdown
        menuOptions={
          <>
            <StyledDropdownItem
              eventKey={LinkedAccountSettingToggleDropdownOption.ENABLE}
              onSelect={onDropdownSelected}
            >
              <DropdownMenuOption>Enabled</DropdownMenuOption>
            </StyledDropdownItem>
            <StyledDropdownItem
              eventKey={LinkedAccountSettingToggleDropdownOption.DISABLE}
              onSelect={onDropdownSelected}
            >
              <DropdownMenuOption>Disabled</DropdownMenuOption>
            </StyledDropdownItem>
            <StyledDropdownItem
              eventKey={LinkedAccountSettingToggleDropdownOption.RESET}
              onSelect={onDropdownSelected}
            >
              <DropdownMenuOption>Reset to org default</DropdownMenuOption>
            </StyledDropdownItem>
          </>
        }
      >
        <EnabledDisabledDiv isEnabled={isSettingEnabled} className="flex items-center">
          {includeToggleIcon &&
            (isSettingEnabled ? (
              <ToggleRightIcon size={12} className="mr-2" />
            ) : (
              <ToggleLeftIcon size={12} className="mr-2" />
            ))}
          {isSettingEnabled ? "Enabled" : "Disabled"}
          <AnimatedChevron className="text-[10px] ml-1" $isUp={false} />
        </EnabledDisabledDiv>
      </ScopesDropdown>
      {isDefaultOverridden ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="overridden-scope-tooltip">
              Override of your organization's default setting
            </Tooltip>
          }
        >
          <div className="ml-3 font-medium flex items-center flex-nowrap text-[10px] leading-[16px]">
            <User className="mr-1.5" size={12} />
            Overridden
          </div>
        </OverlayTrigger>
      ) : (
        <div className="italic text-[10px] leading-[16px] ml-3 text-gray-40">Default</div>
      )}
    </div>
  );
};

export default LinkedAccountSettingToggle;
