import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Box } from "lucide-react";
import useAppContext from "../../../context/useAppContext";
import {
  createOverriddenCommonModelInstance,
  getCommonModelOverrideIntegrationInformation,
  getCommonModelOverrideTarget,
  deleteCommonModelOverrideTarget,
} from "./utils/FieldMappingUtils";
import MergeTable from "../../../shared-components/MergeTable";
import CommonModelOverrideByIntegrationRow from "./CommonModelOverrideByIntegrationRow";
import SaveOverrideModal from "../../../integrations-management/linked-accounts/detail-page/field-mappings/common-model-overrides/SaveOverrideModal";
import TableSkeletonLoader from "../../../integrations-management/shared/TableSkeletonLoader";
import {
  CreateOverriddenCommonModelInstanceDict,
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
  OverrideCommonModelIntegrationInfo,
} from "../../../../models/Entities";
import SkeletonLoader from "../../../shared-components/SkeletonLoader";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import { capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
import {
  CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  navigateToConfigurationFieldMappingTargets,
} from "../../../../router/RouterUtils";
import { ChevronLeft } from "lucide-react";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";
import MoreHorizontalPopover from "../../../shared-components/MoreHorizontalPopover";
import { Badge } from "@merge-api/merge-javascript-shared";
import { Dropdown } from "react-bootstrap";
const filterAvailableIntegrations = (availableIntegrations: any, selectedSearchOption: any) => {
  if (selectedSearchOption.length == 0 || selectedSearchOption[0] == "") {
    return availableIntegrations;
  }
  return availableIntegrations.filter(
    (availableIntegration: any) =>
      availableIntegration.integration.name.includes(selectedSearchOption[0]) ||
      (
        availableIntegration?.integration?.common_model_override_instance?.display_name ?? ""
      ).includes(selectedSearchOption[0]) ||
      (
        availableIntegration?.integration?.common_model_override_instance?.remote_endpoint_path ??
        ""
      )?.includes(selectedSearchOption[0]),
  );
};

type Props = {
  id: string;
};

type ConfigurationCommonModelOverrideSettingsProps = {
  refreshFieldMappingsAndConfigurations: () => void;
};
const ConfigurationCommonModelOverridesSettings = ({
  refreshFieldMappingsAndConfigurations,
}: ConfigurationCommonModelOverrideSettingsProps) => {
  const { id } = useParams<Props>();
  const [availableIntegrations, setAvailableIntegrations] = useState<
    OverrideCommonModelIntegrationInfo[] | undefined
  >(undefined);
  const [isSaveOverrideModalOpen, setIsSaveOverrideModalOpen] = useState<boolean>(false);
  const [overrideCommonModelToUpdate, setOverrideCommonModelToUpdate] = useState<
    CreateOverriddenCommonModelInstanceDict | undefined
  >(undefined);
  const [commonModelTarget, setCommonModelTarget] = useState<
    OverriddenCommonModelTarget | undefined
  >(undefined);
  const [selectedSearchOption, setSelectedSearchOption] = useState<Array<string>>([]);
  const { user } = useAppContext();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const history = useHistory();

  const tableHeaders = (
    <>
      <th scope="col" className="pl-6">
        Integration
      </th>
      <th scope="col">Origin Field</th>
      <th scope="col">Endpoint</th>
      <th scope="col" className="pr-6"></th>
    </>
  );
  useEffect(() => {
    getCommonModelOverrideIntegrationInformation(
      id,
      (data) => {
        setAvailableIntegrations(data);
      },
      () => {
        showErrorToast("Not able to fetch override integration informatino for target");
      },
    );

    getCommonModelOverrideTarget(
      id,
      (data) => {
        setCommonModelTarget(data);
      },
      () => {
        showErrorToast("Not Able To Fetch Target");
      },
    );
  }, [id]);

  const saveOverrideModel = (
    commonModelOverrideInstanceInfo: CreateOverriddenCommonModelInstanceDict,
  ) => {
    if (overrideCommonModelToUpdate) {
      const integrationID = commonModelOverrideInstanceInfo.integration_id;

      const onSaveSuccess = (createdInstance: OverriddenCommonModelInstance) => {
        setAvailableIntegrations((prevIntegrations) => {
          if (prevIntegrations) {
            return prevIntegrations.map((integrationInfo) => {
              const integrationData = integrationInfo.integration;
              return integrationData.id === integrationID
                ? {
                    integration: integrationData,
                    common_model_override_instance: createdInstance,
                  }
                : integrationInfo;
            });
          }
          return undefined;
        });
      };
      createOverriddenCommonModelInstance(overrideCommonModelToUpdate, onSaveSuccess, () => {});
    }
    setIsSaveOverrideModalOpen(false);
  };

  const deleteOverrideModelInstance = (integrationID: string) => {
    setAvailableIntegrations((prevIntegrations) => {
      if (prevIntegrations) {
        return prevIntegrations.map((integrationInfo) => {
          const integrationData = integrationInfo.integration;
          return integrationData.id === integrationID
            ? {
                integration: integrationData,
                common_model_override_instance: null,
              }
            : integrationInfo;
        });
      }
      return undefined;
    });
  };

  const updateCommonModelInstance = (
    integrationID: string,
    updatedInstance: OverriddenCommonModelInstance,
  ) => {
    setAvailableIntegrations((prevIntegrations) => {
      if (prevIntegrations) {
        return prevIntegrations.map((integrationInfo) => {
          const integrationData = integrationInfo.integration;
          return integrationData.id === integrationID
            ? {
                integration: integrationData,
                common_model_override_instance: updatedInstance,
              }
            : integrationInfo;
        });
      }
      return undefined;
    });
  };

  const filteredAvailableIntegrations = availableIntegrations
    ? filterAvailableIntegrations(availableIntegrations, selectedSearchOption)
    : undefined;
  const rowData =
    commonModelTarget &&
    filteredAvailableIntegrations &&
    filteredAvailableIntegrations.map((availableIntegration: any) => {
      const integration = availableIntegration["integration"];
      const commonModelInstance = availableIntegration["common_model_override_instance"];
      return (
        <CommonModelOverrideByIntegrationRow
          commonModelOverrideTargetID={id}
          commonModelOverrideTargetType={
            commonModelTarget.overridden_field_definition?.type || null
          }
          integrationID={integration.id}
          organizationID={user.organization.id}
          commonModelOverrideCommonModelName={commonModelTarget.common_model_name}
          name={integration.name}
          squareImage={integration?.square_image || undefined}
          commonModelInstanceID={commonModelInstance ? commonModelInstance.id : null}
          originField={commonModelInstance ? commonModelInstance.origin_field : null}
          remoteEndpointPath={commonModelInstance ? commonModelInstance.remote_endpoint_path : null}
          fieldTraversalPath={commonModelInstance ? commonModelInstance.field_traversal_path : null}
          display_name={
            commonModelInstance && commonModelInstance.display_name
              ? commonModelInstance.display_name
              : null
          }
          setIsSaveOverrideModalOpen={setIsSaveOverrideModalOpen}
          setOverrideCommonModelToSave={setOverrideCommonModelToUpdate}
          deleteInstance={deleteOverrideModelInstance}
          updateCommonModelInstance={updateCommonModelInstance}
        />
      );
    });

  const options: string[] = [];
  if (filteredAvailableIntegrations) {
    filteredAvailableIntegrations.forEach((availableIntegration: any) => {
      options.push(availableIntegration?.integration.name);
      if (availableIntegration?.integration?.common_model_override_instance?.display_name != null) {
        options.push(availableIntegration?.common_model_override_instance?.display_name);
      }
      if (
        availableIntegration?.integration.common_model_override_instance?.remote_endpoint_path !=
        null
      ) {
        options.push(
          availableIntegration?.integration.common_model_override_instance?.remote_endpoint_path,
        );
      }
    });
  }

  options.sort();

  return (
    <div className="mb-10">
      {isSaveOverrideModalOpen && (
        <SaveOverrideModal
          text={
            "Are you sure you want to override the Merge default values for this Common Model field? You will no longer be able to POST data to this field. This will affect all data for all the selected integration's accounts."
          }
          onHide={() => {
            setIsSaveOverrideModalOpen(false);
            setOverrideCommonModelToUpdate(undefined);
          }}
          onConfirm={() => {
            if (overrideCommonModelToUpdate) {
              saveOverrideModel(overrideCommonModelToUpdate);
            }
          }}
        />
      )}
      {showDeleteModal && (
        <SaveOverrideModal
          title={"Delete Common Model override target"}
          text={
            "This will delete all Field Mappings associated with this target field. Are you sure you want to continue?"
          }
          saveButtonText="Delete Override field"
          onHide={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            deleteCommonModelOverrideTarget(
              id,
              () => {
                showSuccessToast("Successfully deleted target");
                setShowDeleteModal(false);
                refreshFieldMappingsAndConfigurations();
                navigateToConfigurationFieldMappingTargets(history);
              },
              () => {
                showErrorToast("Unable to delete target");
              },
            );
          }}
        />
      )}
      <Link
        to={CONFIGURATION_ADVANCED_FIELD_MAPPINGS_TARGET_FIELDS_PATH}
        className="text-sm font-semibold mb-[-20px]"
      >
        <div className="d-flex items-center">
          <ChevronLeft size={12} />
          <span className="ml-1">Back to Target Fields</span>
        </div>
      </Link>
      <div className="rounded-t-lg h-2 bg-blue-10 mt-6" />
      <div className="d-flex shadow-md rounded-b-lg p-6 pt-3 pb-5 bg-white">
        <div className="d-flex flex-column flex-grow-1  ">
          <div className="d-flex items-center">
            <div className="font-mono text-xl">
              {commonModelTarget ? (
                <div className="text-gray-90 font-inte text-truncate font-semibold text-[20px]">
                  {commonModelTarget.overridden_field}
                </div>
              ) : (
                <SkeletonLoader height={14} borderRadius={0} />
              )}
            </div>
            {commonModelTarget && (
              <div className=" text-[13px] m-auto font-mono pl-1 pr-1 bg-gray-0 text-gray-90 rounded ml-2 leading-6">
                {capitalizeFirstLetter(commonModelTarget.overridden_field_definition?.type || "")}
              </div>
            )}
          </div>
          <div className="mt-2">
            <div className="d-flex items-center">
              <Box size={12} className="text-blue-40" />
              <div className="ml-1 font-semibold text-[12px]"> Common Model override</div>
              <div className="ml-2 p-[5px] pt-0 pb-0 bg-gray-0 text-gray-70 font-semibold rounded text-[12px]">
                {commonModelTarget?.common_model_name}
              </div>
              <Badge color="blue" size="lg" className="ml-2">
                {commonModelTarget?.category.toUpperCase()}
              </Badge>
            </div>
          </div>
          {commonModelTarget ? (
            <div className="text-gray-60 font-inter mt-2 text-truncate">
              {commonModelTarget.overridden_field_definition?.description}
            </div>
          ) : (
            <SkeletonLoader height={14} borderRadius={0} />
          )}
        </div>
        <div className="flex-column d-flex justify-content-center">
          <MoreHorizontalPopover
            menuOptions={
              <>
                <Dropdown.Item
                  className="hover:bg-gray-0 text-sm text-red-50 !font-normal"
                  onSelect={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  Delete override target
                </Dropdown.Item>
              </>
            }
          />
        </div>
      </div>
      <div className="d-flex flex-column shadow-md p-5 pl-0 pr-0 mt-6 rounded-[10px] bg-white">
        <div className="pl-6 pr-6">
          <div className="font-semibold text-[16px]">Overrides by integration</div>
          <div className="mt-3">
            <p className="mb-0">
              Create <strong>Common Model overrides </strong>that apply across all Linked Accounts
              of an integration for this target field.
            </p>
            <p className="mb-5">
              Any changes to data made here will be updated in each Linked Account’s next sync.
            </p>
            <span className="mb-[13px]">
              <MergeTypeahead
                id="typeahead"
                multiple={false}
                selected={selectedSearchOption}
                options={options}
                inputProps={{ autoComplete: "none" }}
                placeholder="Search integrations, mappings, or endpoints..."
                onChange={(selectedOptions) => {
                  setSelectedSearchOption(selectedOptions);
                }}
                renderMenuItemChildren={(option, index) => {
                  return <React.Fragment key={`${option}-${index}`}>{option}</React.Fragment>;
                }}
                includeChevronDown={false}
                includeSearchIcon
                isDarkBar
              />
            </span>
          </div>
        </div>
        {availableIntegrations && rowData ? (
          <div className="mt-3">
            <MergeTable
              header={tableHeaders}
              content={rowData}
              hasMarginBottom={false}
              isOverviewPage
            />
          </div>
        ) : (
          <div className="mt-3 pl-6 pr-6">
            <TableSkeletonLoader cols={3} rows={10} />
          </div>
        )}
      </div>
    </div>
  );
};
export default ConfigurationCommonModelOverridesSettings;
