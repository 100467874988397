import { CreditCard } from "lucide-react";
import { APICategory, BlueprintSpeed, Organization } from "../../../models/Entities";

export const FREE_PLAN_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL = 5;
export const LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL = 20;
export const FREE_PLAN_LINKED_ACCOUNT_LIMIT = 3;
export const LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT = 10;

export enum BillingPlanTier {
  BILLING_PLAN_TIER_FREE = "FREE",
  BILLING_PLAN_TIER_LAUNCH = "LAUNCH",
  BILLING_PLAN_TIER_GROW = "GROW",
  BILLING_PLAN_TIER_EXPAND = "EXPAND",
  BILLING_PLAN_TIER_CORE = "CORE",
  BILLING_PLAN_TIER_CORE_STARTUP = "STARTUP_CORE",
  BILLING_PLAN_TIER_PROFESSIONAL = "PROFESSIONAL",
  BILLING_PLAN_TIER_PROFESSIONAL_STARTUP = "STARTUP_PROFESSIONAL",
  BILLING_PLAN_TIER_ENTERPRISE = "ENTERPRISE",
}

export interface BillingPlan {
  id: string;
  name: string;
  is_active: boolean;
  plan_tier: BillingPlanTier;

  // Plan Pricing
  monthly_discount_cents: number;
  monthly_price_cents: number;
  api_request_price_cents: number;
  scrape_price_cents: number;
  webhook_price_cents: number;

  // Plan Caps
  free_api_requests: number;
  free_scrapes: number;
  free_webhooks: number;
  product_restrictions: {
    test_accounts: number;
  };

  /**
   * Price of each Common Model, in cents.
   * Example: { "ats.Application": 0.1, "ats.Candidate": 0.15, "hris.Employee": 50 }
   */
  common_model_prices_cents: {
    [key: string]: number;
  };
  /**
   * Price of each linked account, in cents.
   * Example: { "accounting": 2000 }
   */
  linked_account_category_prices_cents: {
    [key: string]: number;
  };
}

export interface ProductRestrictions {
  id: string;
  available_sync_speeds: BlueprintSpeed[];
  log_retention_days: number;
  test_accounts: number;
  webhooks_enabled: boolean;
  are_toggles_enabled: boolean;
  are_remote_data_toggles_enabled: boolean;
  are_custom_fields_enabled: boolean;
  are_custom_objects_enabled: boolean;
  third_party_webhooks_enabled: boolean;
  passthrough_requests_enabled: boolean;
  redact_unmapped_data_enabled: boolean;
  audit_log_enabled: boolean;
}

export interface OrganizationBillingPlan {
  id: string;
  organization: Organization;
  billing_plan: BillingPlan;
  start_date: string;
  end_date: string;
  common_models_count: Record<string, number>; // Keys are category.common_model
  linked_accounts_count: Record<string, number>; // Keys are category
  test_accounts_count: number;
  product_restrictions: ProductRestrictions;
  should_auto_charge: boolean;
  deletion_detection_enabled: boolean;
}

/**
 * The values calculated per category on a specific invoice.
 */
export interface CategoryCharge {
  category: APICategory;
  unit: string;
  priceCents: number;
  quantity?: number;
  totalCostCents?: number;
}

/**
 * The values calculated for a category with current prices and quantities.
 */
export type CurrentCategoryCharge = Omit<CategoryCharge, "totalCostCents">;

export enum PaymentMethodTypes {
  CREDIT_CARD = "CREDIT_CARD",
  BANK_ACCOUNT = "BANK_ACCOUNT",
}
export interface BankAccount {
  id: string;
  last4: string;
  is_default_method: boolean;
  created_at: string;
  bank_name: string;
}
export interface CreditCard {
  id: string;
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
  is_default_method: boolean;
  created_at: string;
}

export type PaymentMethod = BankAccount | CreditCard;

export const isCreditCard = (x: PaymentMethod): x is CreditCard => x.hasOwnProperty("brand");

export enum MergeChargeStatus {
  CHARGE_STATUS_SUCCESS = "SUCCESS",
  CHARGE_STATUS_SUSPENDED = "SUSPENDED",
  CHARGE_STATUS_PENDING = "PENDING",
  CHARGE_STATUS_FAILURE = "FAILURE",
  CHARGE_STATUS_WAIVED = "WAIVED",
  CHARGE_STATUS_OVERDUE = "OVERDUE",
}

export const PAYABLE_INVOICE_STATUSES = [
  MergeChargeStatus.CHARGE_STATUS_PENDING,
  MergeChargeStatus.CHARGE_STATUS_OVERDUE,
];

export enum DEBIT_OR_CREDIT {
  DEBIT = "DEBIT",
  CREDIT = "CREDIT",
}

export interface MergeInvoiceLineItem {
  /**
   * The name of the line item, which will be the name assigned to a Fixed Charge or the Category
   * if source is a Variable/Surplus charge item
   */
  item_name: string;

  /**
   * The description of the line item, which comes from the fixed charge used to generate it or is
   * Surplus usage. if it came from a variable charge.
   */
  description: string;

  /**
   * The recurrance category of the line item. Default MONTHLY for variable/surplus charge items
   */
  recurrence: string;

  /**
   * The formatted per-unit price of the line item. Formatted from lowest stairstep to highest stairstep,
   * like $0.10 - $0.25 when generated by a stairstepped variable item. For a discrete rate variable
   * charge this is the discrete rate setup in the item. For a fixed price item this is the total amount
   * divided by the quantity setup for the fixed rate item.
   */
  formatted_unit_price: string;

  /**
   * Formatted quantity used which is only available for a variable charge item. This would be the total
   * number of objects used which we are charging on for that particular item - if the variable charge was
   * on HRIS employees, and the customer used in the billing period. 
   * 
  
   */
  formatted_quantity_used: string;

  /**
   * Formatted quantity charged. For a fixed price item this is the quantity provided. For variabale charge items
   * this is the amount we are actually charging for the month, so the total usage in the month if no prepaid volume
   * or prepaid volume exceeded, or if no prepaid volume, the total usage in the last month.
   */

  formatted_quantity_charged: string;

  /**
   * Dollar formatted cost of line itme
   */
  formatted_amount: string;

  /**
   * Category of charge - HRIS, ATS, Ticketing, etc
   */
  object_category: string;

  /**
   * Whether the line item is a Debit or Credit on the invoice
   */
  debit_or_credit: DEBIT_OR_CREDIT;
}

/**
 * The model representing a MergeCharge, aka an Invoice.
 */
export interface MergeInvoice {
  id: string;
  api_request_cost_cents: number;
  billing_suspended: boolean;

  /**
   * Total amounts charged per Common Model, in cents.
   * Example: { "ats.Application": 0, "ats.Candidate": 15, "hris.Employee": 1050 }
   */
  common_model_costs_cents: {
    [key: string]: number;
  };
  /**
   * Quantity of each Common Model in the DB when the MergeCharge was generated.
   * Example: { "ats.Application": 0, "ats.Candidate": 100, "hris.Employee": 21 }
   */
  common_model_counts: {
    [key: string]: number;
  };
  /**
   * Price of each Common Model, in cents.
   * Example: { "ats.Application": 0.1, "ats.Candidate": 0.15, "hris.Employee": 50 }
   */
  common_model_prices_cents: {
    [key: string]: number;
  };
  /**
   * Total amounts charged per category, in cents.
   * Example: { "accounting": 4000 }
   */
  linked_account_costs_cents: {
    [key: string]: number;
  };
  /**
   * Quantity of each linked account in the DB when the MergeCharge was generated.
   * Example: { "accounting": 2 }
   */
  linked_account_counts: {
    [key: string]: number;
  };
  /**
   * Price of each linked account, in cents.
   * Example: { "accounting": 2000 }
   */
  linked_account_prices_cents: {
    [key: string]: number;
  };
  hide_logo_cost_cents: number;
  monthly_cost_cents: number;
  monthly_discount_cents: number;
  scrape_cost_cents: number;
  status: MergeChargeStatus;
  /**
   * Total charge amount, in cents.
   */
  total_charge_amount: number;
  webhook_cost_cents: number;
  charge_period_start_time: string;
  charge_period_end_time: string;
  due_date: string;
  issue_date: string;
  created_post_billing_overhaul: boolean;
  formatted_subtotal: string;
  formatted_credit: string;
  formatted_total: string;
  line_items: MergeInvoiceLineItem[];
}
