import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

const DropdownToggle = styled(Dropdown.Toggle)`
  :after {
    display: none;
  }
`;

export default DropdownToggle;
