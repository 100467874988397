import React from "react";

type CommonModelFieldDropdownProps = {
  fieldDescription: string | undefined;
  fieldType: string | undefined;
  fieldName: string;
};
const CommonModelFieldDropdown = ({
  fieldDescription,
  fieldType,
  fieldName,
}: CommonModelFieldDropdownProps) => {
  return (
    <>
      <div className="flex align-items-center">
        <div className="font-mono text-gray-90 font-medium">{fieldName}</div>
        {fieldType && (
          <div className="ml-3 bg-gray-0 text-gray-70 pl-1 pr-1 rounded font-mono text-xs">
            {fieldType}
          </div>
        )}
      </div>
      {fieldDescription && (
        <div className="text-gray-60 font-inter mt-2 text-truncate">
          {fieldDescription.split(".")[0]}.
        </div>
      )}
    </>
  );
};

export default CommonModelFieldDropdown;
