import React from "react";
import ProdMergeLinkActionButton from "./merge-link-buttons/ProdMergeLinkActionButton";
import DevMergeLinkSwitch from "./merge-link-buttons/DevMergeLinkSwitch";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  onNewLinkedAccount: () => void;
}

export const getTenantConfigForMergeLink = (): {} | { tenantConfig: { apiBaseURL: string } } => {
  if (
    process.env.REACT_APP_MERGE_ENV != "PRODUCTION" &&
    process.env.REACT_APP_MERGE_ENV != "DEVELOP"
  ) {
    return {};
  }

  const baseAPIURL = process.env.REACT_APP_BASE_API_URL;
  if (baseAPIURL == "https://api.merge.dev") {
    return {};
  }

  return {
    tenantConfig: {
      apiBaseURL: baseAPIURL,
    },
  };
};

const AddTestLinkedAccountButton = ({ linkToken, onNewLinkedAccount }: Props) => {
  return process.env.REACT_APP_BASE_API_URL === "https://api-develop.merge.dev" ? (
    <DevMergeLinkSwitch
      linkToken={linkToken}
      onNewLinkedAccount={onNewLinkedAccount}
      tenantConfig={getTenantConfigForMergeLink()}
    />
  ) : (
    <ProdMergeLinkActionButton
      linkToken={linkToken}
      onNewLinkedAccount={onNewLinkedAccount}
      tenantConfig={getTenantConfigForMergeLink()}
    />
  );
};

export default AddTestLinkedAccountButton;
