import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import SpinnerButton from "../../shared-components/SpinnerButton";
import useAppContext from "../../context/useAppContext";
import { Form } from "react-bootstrap";
import { PaymentMethodTypes } from "./BillingModels";

type Props = {
  onHide: () => void;
};

export default function CreditCardSetupForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState<string>("");

  useEffect(() => {
    fetchWithAuth({
      path: "/billing/stripe/setup-intent",
      method: "POST",
      body: {
        payment_method: PaymentMethodTypes.CREDIT_CARD,
      },
      onResponse: (data) => {
        setSetupIntentClientSecret(data.setup_intent_client_secret);
      },
    });
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (!stripe || !elements || !setupIntentClientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      showErrorToast("Oops, something went wrong! Please try again in 10 seconds.");
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      return;
    }

    const result = await stripe.confirmCardSetup(setupIntentClientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: user.name,
        },
      },
    });

    if (result.error) {
      showErrorToast(result.error.message ?? "");
      setIsLoading(false);
    } else {
      showSuccessToast("Credit card added successfully!");
      props.onHide();
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="w-100 deprecated-mt-4">
      <p>Please enter your credit card details below.</p>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <p className="text-gray-50 deprecated-mt-3">
        By submitting this information, you authorize Merge to charge your card monthly for the
        amount listed on the billing page.
      </p>
      <SpinnerButton
        className="btn-primary btn-block"
        text="Save Payment Method"
        isLoading={isLoading}
      />
    </Form>
  );
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: "var(--font-family-sans-serif),sans-serif",
      fontSmoothing: "antialiased",
      fontSize: "15px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#E63757",
      iconColor: "#E63757",
    },
  },
};
