import React from "react";
import styled from "styled-components";
import { MendableInPlace } from "@mendable/search";
import MergeModal from "../shared-components/MergeModal";
import { MENDABLE_ANON_KEY } from "../../constants";

type MergeMendableInPlaceProps = {
  showMendableModal: boolean;
  setShowMendableModal: any;
};

const StyledModal = styled(MergeModal)`
  .modal-content {
    background-color: transparent;
  }
`;
const MergeMendableInPlace = ({
  showMendableModal,
  setShowMendableModal,
}: MergeMendableInPlaceProps) => {
  return (
    <StyledModal
      show={showMendableModal}
      noPadding={false}
      bodyClassName="p-0"
      onHide={() => {
        setShowMendableModal(false);
      }}
    >
      <MendableInPlace
        askAIText={"Ask Merge AI"}
        hintText="Find or ask anything..."
        anon_key={MENDABLE_ANON_KEY}
        style={{
          darkMode: false,
          accentColor: "#123456",
        }}
        showSimpleSearch={true}
        messageSettings={{
          openInNewTabForOutsideSources: true,
          legacySourceDisplay: true,
        }}
      />
    </StyledModal>
  );
};
export default MergeMendableInPlace;
