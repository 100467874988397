export enum APICategory {
  hris = "hris",
  ats = "ats",
  accounting = "accounting",
  ticketing = "ticketing",
  crm = "crm",
  mktg = "mktg",
  filestorage = "filestorage",
}

export enum LinkedAccountStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  RELINK_NEEDED = "RELINK_NEEDED",
}

export enum SDKLanguage {
  Python = "Python",
  Ruby = "Ruby",
  NodeJS = "NodeJS",
  Go = "Go",
  Elixir = "Elixir",
  Java = "Java",
  TypeScript = "TypeScript",
}

export interface ChangelogData {
  id: string;
  title: string;
  url: string;
}

export enum CommonModelSyncStatusType {
  SYNCING = "SYNCING",
  DONE = "DONE",
  FAILED = "FAILED",
  DISABLED = "DISABLED",
  PAUSED = "PAUSED",
  PARTIALLY_SYNCED = "PARTIALLY_SYNCED",
}

export enum UserType {
  admin_with_billing = "ADMIN_WITH_BILLING",
  admin_without_billing = "ADMIN_WITHOUT_BILLING",
  member = "MEMBER",
}

export enum LinkingFlowAuthenticationMethodPreference {
  LOGIN_DEFAULT_WITH_MANUAL_FALLBACK = "LOGIN_DEFAULT_WITH_MANUAL_FALLBACK",
  LOGIN_DEFAULT_WITHOUT_MANUAL_FALLBACK = "LOGIN_DEFAULT_WITHOUT_MANUAL_FALLBACK",
  MANUAL_DEFAULT_WITH_LOGIN_FALLBACK = "MANUAL_DEFAULT_WITH_LOGIN_FALLBACK",
  MANUAL_DEFAULT_WITHOUT_LOGIN_FALLBACK = "MANUAL_DEFAULT_WITHOUT_LOGIN_FALLBACK",
}

export enum PreexistingProductionLinkedAccountPreference {
  CREATE_AS_NEW_END_USER = "CREATE_AS_NEW_END_USER",
  USE_PREEXISTING_END_USER = "USE_PREEXISTING_END_USER",
  BLOCK = "BLOCK",
}

export enum BillingGuardrailsStatus {
  BILLING_STATUS_FREE = "BILLING_STATUS_FREE",
  BILLING_STATUS_LAUNCH = "BILLING_STATUS_LAUNCH",
  BILLING_STATUS_LAUNCH_APPROACHING_GUARDRAILS = "BILLING_STATUS_LAUNCH_APPROACHING_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_NO_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_NO_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_NO_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_NO_GUARDRAILS",
  BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_GUARDRAILS = "BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_GUARDRAILS",
  BILLING_STATUS_FREE_AFTER_APRIL_2023 = "BILLING_STATUS_FREE_AFTER_APRIL_2023",
  BILLING_STATUS_LAUNCH_AFTER_APRIL_2023 = "BILLING_STATUS_LAUNCH_AFTER_APRIL_2023",
  BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT = "BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT",
  BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT = "BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT",
}

export enum InvoiceNonpaymentStatus {
  BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD = "BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD",
  BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT = "BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT",
  BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30 = "BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30",
  BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT_PAST_30 = "BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT_PAST_30",
}

export const GUARDRAILS_ENFORCED_STATUSES = [
  BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_GUARDRAILS,
  BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_GUARDRAILS,
];

export const INVOICE_FE_DISABLED_STATUSES = [
  InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT_PAST_30,
  InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30,
];

export enum SyncFrequencyPlan {
  SYNC_FREQUENCY_PLAN_HIGHEST = "SYNC_FREQUENCY_PLAN_HIGHEST",
  SYNC_FREQUENCY_PLAN_HIGHEST_WITH_DELETED_DATA = "SYNC_FREQUENCY_PLAN_HIGHEST_WITH_DELETED_DATA",
  SYNC_FREQUENCY_PLAN_HIGHEST_MANUAL = "SYNC_FREQUENCY_PLAN_HIGHEST_MANUAL",
  SYNC_FREQUENCY_PLAN_DAILY = "SYNC_FREQUENCY_PLAN_DAILY",
  SYNC_FREQUENCY_PLAN_MONTHLY = "SYNC_FREQUENCY_PLAN_MONTHLY",
  SYNC_FREQUENCY_PLAN_QUARTERLY = "SYNC_FREQUENCY_PLAN_QUARTERLY",
  SYNC_FREQUENCY_PLAN_DEFAULT = "DEFAULT",
}

export const ManualSyncCreditPlans = [
  SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_QUARTERLY,
  SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_MONTHLY,
];

export interface Organization {
  id: string;
  name: string;
  logo: string;
  auto_enable_new_integrations: boolean;
  enable_auto_webhook_setup: boolean;
  linking_flow_authentication_method_preference: LinkingFlowAuthenticationMethodPreference;
  should_show_requested_data_in_linking_flow: boolean;
  is_mfa_required: boolean;
  preexisting_production_linked_account_preference: PreexistingProductionLinkedAccountPreference;
  show_selective_sync?: boolean;
  merge_link_error_remediation_enabled?: boolean;
  sync_frequency_plans?: { [category: string]: SyncFrequencyPlan };
  billed_category_linked_account_count?: { [category: string]: number };
  purchased_prod_linked_accounts?: number;
  field_mapping_v2_org_enabled?: boolean;
}

export interface PendingInvite {
  id: string;
  email: string;
  organization: Organization;
}

export interface User {
  is_active?: boolean;
  id: number;
  name: string;
  email: string;
  avatar: string | null;
  organization: Organization;
  type: UserType;
  is_staff: boolean;
  is_demo: boolean;
  is_two_factor_enabled: boolean;
  terms_agreement_datetime: string;
  intercom_hmac: string;
  allowed_invite_roles: Array<UserType>;
  is_billing_notice_enabled: boolean;
  is_selective_sync_enabled: boolean;
  is_g2_survey_enabled: boolean;
  is_integration_wide_field_mappings_enabled: boolean;
  g2_banner_dismiss_count: number;
  g2_banner_last_dismiss_date: string;
  disable_magic_link_onboarding: boolean;

  /**
   * If the redesign of logs search is enabled - will swap the contents of /logs
   */
  is_log_search_enabled?: boolean;
  is_webhook_search_enabled?: boolean;

  is_onboarding_v2_enabled?: boolean;
  show_upgrade_billing_plan_banner?: boolean;
  is_pricing_banner_dismissed?: boolean;
  can_toggle_merge_link_error_remediation_enabled?: boolean;
  is_linked_account_scopes_dashboard_enabled?: boolean;
  is_issues_v2_enabled?: boolean;
  field_mapping_v2_user_enabled?: boolean;
  is_override_common_models_enabled?: boolean;
}

export interface OrganizationConfigStatusField {
  completed: boolean;
  description: string;
  field_name: string;
  field_type: string;
}

export interface Integration {
  id: string;
  name: string;
  notes_for_customers?: string;
  image: string;
  square_image?: string;
  dark_grey_image?: string;
  light_grey_image?: string;
  coverage: string;
  base_api_url: string;
  modified_at: string;
  active: boolean;
  categories: Array<string>;
  is_enabled?: boolean;
  is_auto_webhook_setup_enabled?: boolean;
  organization_config_status: OrganizationConfigStatusField[];
  is_in_beta: boolean;
  slug: string;
  api_documentation_url: string;
}

export type LinkedIntegration = Pick<Integration, "id" | "name" | "categories" | "square_image">;

export enum BlueprintSpeed {
  SLOW = "SLOW",
  INTERMEDIATE = "INTERMEDIATE",
  FAST = "FAST",
  CUSTOM = "CUSTOM",
}

export enum PartnershipType {
  NONE = "NONE",
  ORGANIZATION_PARTNERSHIP = "ORGANIZATION_PARTNERSHIP",
}

export interface OrganizationIntegrationSettings {
  id: string;
  organization: Organization;
  organization_config_status: OrganizationConfigStatusField[];
  category: string;
  integration: Integration;
  is_beta: boolean;
  is_enabled: boolean;
  is_toggleable: boolean;
  display_order: number | undefined;
  blueprint_speed: BlueprintSpeed;
  partnership_available: boolean;
  partnership_type: PartnershipType;
  available_blueprint_speeds: Array<BlueprintSpeed>;
}

export interface EndUser {
  id: string;
  organization: Organization;
  origin_id: string;
  email_address: string;
  organization_name: string;
  organization_logo: string;
}

export enum PauseReason {
  PRICING_PLAN_LIMIT = "PRICING_PLAN_LIMIT",
  LINKED_ACCOUNT_INACTIVITY = "LINKED_ACCOUNT_INACTIVITY",
  FAILING_SYNCS = "FAILING_SYNCS",
}
export interface LinkedAccount {
  id: string;
  integration: Integration;
  category: APICategory;
  created_at: string;
  completed_at: string;
  organization: Organization;
  end_user: EndUser;
  override_base_api_url: string;
  public_token: string;
  is_test_account: string;
  account_token: string;
  is_duplicate?: boolean | null;
  status: LinkedAccountStatus;
  status_detail?: string | null;
  pause_reason: PauseReason | null;
  custom_mapping_instance_count: number;
  total_sync_credits?: number;
  remaining_sync_credits?: number;
  sync_credits_refresh_timestamp?: string;
  sync_frequency_plan?: SyncFrequencyPlan;
  linked_account_blueprint_speed: BlueprintSpeed;
  should_redact_unmapped_data: boolean;
  redact_unmapped_data_enabled_for_org: boolean;
}

export interface LinkedAccountMetrics {
  completed_linked_accounts_count: number;
  incomplete_linked_accounts_count: number;
  relink_needed_accounts_count: number;
}

export interface Webhook {
  id: string;
  event: string;
  target: string;
}

export interface FieldMappingTarget {
  id: string;
  category: APICategory;
  common_model_id: string;
  field_key: string;
  field_description: string;
  common_model_name: string;
  weight: number;
  is_mappable_in_link: boolean;
  total_int_wide_field_mappings: number;
}

export interface IntegrationWideFieldMappingInstanceInfo {
  id: string;
  origin_field: string | undefined;
  display_name: string | undefined;
  field_description: string | undefined;
  remote_endpoint_path: string | undefined;
  enable_linked_account_level_overrides: boolean;
  field_traversal_path: Array<string>;
  remote_endpoint_method: string | undefined;
  origin_type: string | undefined;
}
export interface AvailableFieldMappingIntegration {
  integration_info: Integration;
  field_mapping_info?: IntegrationWideFieldMappingInstanceInfo;
}

export interface FieldMappingCommonModelConfiguration {
  id: string;
  common_model_name: string;
  allow_user_created_field_mapping_targets: boolean;
  has_remote_data_enabled: boolean;
}
export interface CustomIntegration {
  id: string;
  name: string;
  image: ImageBitmap;
  active: boolean;
  category: string;
}

export interface SetupStep {
  id: string;
  step_number: number;
  title: string;
  description: string;
}

export enum APIEndpointMethod {
  GET = "GET",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export interface Comment {
  id: string;
  user: User;
  body: string;
  created_at: string;
}

export interface CommonModelSyncStatus {
  is_initial_sync: boolean;
  last_sync_start: string;
  next_sync_start: string;
  model_id: string;
  model_name: string;
  status: CommonModelSyncStatusType;
  sync_reason?: string;
}

export enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
  OPTIONS = "OPTIONS",
  HEAD = "HEAD",
}

export interface WebhookReceiver {
  event: string;
  is_active: boolean;
  is_awaiting_handshake: boolean;
  key?: string;
  last_received_at?: string;
  webhook_listener_url: string | null;
}

export interface LinkedAccountWebhookReceivers {
  webhook_receivers: Array<WebhookReceiver>;
  webhook_listener_url: string | null;
  webhook_listener_instructions: string | null;
  integration_uses_webhook_handshake: boolean;
}

export type ConditionValueType = string | Array<string> | undefined;

export interface SelectiveSyncCondition {
  condition_schema_id: string;
  common_model: string;
  native_name: string | undefined;
  field_name: string | undefined;
  operator: string | undefined;
  value: ConditionValueType;
  can_fetch_filter_options: boolean | undefined;
}

export interface LinkedAccountSelectiveSyncCondition extends SelectiveSyncCondition {
  id: string;
}

export enum ConditionType {
  BOOLEAN = "BOOLEAN",
  DATE = "DATE",
  DATE_TIME = "DATE_TIME",
  INTEGER = "INTEGER",
  FLOAT = "FLOAT",
  STRING = "STRING",
  LIST_OF_STRINGS = "LIST_OF_STRINGS",
}

export interface ConditionOperatorSchema {
  operator: string;
  is_unique?: boolean;
}

export interface SelectiveSyncConditionSchema {
  id: string;
  common_model: string;
  field_name: string;
  native_name: string;
  can_fetch_filter_options: boolean;
  condition_type: ConditionType;
  operators: ConditionOperatorSchema[];
  choices?: string[];
}

export enum ConditionOperator {
  EQUALS = "EQUALS",
  NOT_EQUALS = "NOT_EQUALS",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL_TO = "GREATER_THAN_OR_EQUAL_TO",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL_TO = "LESS_THAN_OR_EQUAL_TO",
  IS_ONE_OF = "IS_ONE_OF",
  HAS_ALL_OF = "HAS_ALL_OF",
  HAS_NONE_OF = "HAS_NONE_OF",
}

export interface FieldMappingTargetsAndFieldMappingInstances {
  field_mapping_instances: Array<FieldMappingInstance>;
  field_mapping_targets: Array<FieldMappingTarget>;
  overridden_common_model_instances: Array<OverriddenCommonModelInstance>;
  overridden_common_model_targets: Array<OverriddenCommonModelTarget>;
}

export interface FieldMappingInstance {
  field_key: string;
  id: string;
  field_description: string;
  configured_by: string;
  field_traversal_path: Array<string>;
  common_model_name: string;
  field_mapping_target: FieldMappingTarget | null;
  display_name: string;
  is_integration_wide: boolean;
  enable_linked_account_level_overrides: boolean;
  remote_endpoint_path: string;
  remote_endpoint_method: string;
  origin_type: string;
}

export interface OverriddenCommonModelInstance {
  id: string;
  field_traversal_path: Array<string>;
  common_model_name: string;
  overridden_field: string;
  overridden_field_definition: FieldDefinition | null;
  override_common_model_target: OverriddenCommonModelTarget;
  remote_endpoint_path: string;
  remote_endpoint_method: string;
  origin_type: string | null;
  origin_field: string | null;
  is_integration_wide: boolean;
  is_integration_wide_override_mapping: boolean;
  display_name: string | null;
}

export type FieldDefinition = {
  title: string;
  type: string;
  description: string;
};

export interface CreateOverriddenCommonModelInstanceDict {
  integration_id?: string;
  organization_id?: string;
  linked_account_id?: string;
  common_model_id?: string;
  overridden_field?: string;
  field_traversal_path: string[];
  api_endpoint_id: string;
  override_common_model_target_id?: string;
  display_name?: string;
}

export interface EditOverriddenCommonModelInstanceDict {
  api_endpoint_id: string;
  field_traversal_path: string[];
  display_name?: string;
}

export interface CreateOrgWideCommonModelOverrideTargetDict {
  common_model_id: string;
  overridden_field: string;
}
export interface OverriddenCommonModelTarget {
  id: string;
  overridden_field: string;
  common_model_name: string;
  category: string;
  overridden_field_definition: FieldDefinition | null;
  total_int_wide_overrides: number | null;
}

export interface OverrideModelAndFieldOptions {
  [model_name: string]: {
    available_fields: string[];
    common_model_name: string;
  };
}

export interface MergeTypeaheadOption {
  id: string;
  label: string;
}
export interface OverrideCommonModelIntegrationInfo {
  integration: Integration;
  common_model_override_instance: OverriddenCommonModelInstance | null;
}
export type FieldMappingCreationAndEditDict = {
  api_endpoint_id: string;
  display_name: string;
  traversal_path: string;
  type: string;
  api_endpoint_path: string;
  api_endpoint_method: string;
  value: string;
};

export type FieldMappingAPIEndpointToFieldsMap = {
  [api_endpoint_id: string]: { [traversal_path: string]: FieldMappingCreationAndEditDict };
};
// dict of common_model -> api_endpoint_id -> {field_path: type}
export type FieldMappingOptions = {
  [common_model_name: string]: FieldMappingAPIEndpointToFieldsMap;
};

export type ModelAndFieldOverrideDict = {
  [common_model_id: string]: {
    available_fields: string[];
    common_model_name: string;
  };
};
export type FieldMappingMetaResponse = {
  available_field_mappings: FieldMappingOptions;
  api_documentation_url: string | undefined;
  available_model_and_field_overrides: ModelAndFieldOverrideDict | null;
};

export type Log = {
  id: string;
  linked_account: LinkedAccount;
  method: string;
  response_code: number;
  created_at: string;
  url: string;
};

export type IntegrationIssueMetadata = {
  action: string;
  status_code: number;
  user_facing_error_detail: string;
};

export type IntegrationIssue = {
  id: string;
  error_description: string;
  metadata: IntegrationIssueMetadata[];
};

export type CommonModelToggle = {
  name: string;
  enabled_model_actions: string[];
  fields: CommonModelField[];
  model_capabilities: string[];
};

export type CateogryScopeMap = {
  [key: string]: CommonModelScopeMap;
};

export type CommonModelScopeMap = {
  actions: string[];
  capabilities: string[];
  fields: CommonModelFieldMap;
};

export type CommonModelField = {
  field_name: string;
  is_enabled: boolean;
};
export type CommonModelFieldMap = {
  [key: string]: {
    isEnabled: boolean;
  };
};

export type CommonModelFieldLevelMapping = {
  isEnabled: boolean;
};

// Audit Logs
export enum AuditLogEventType {
  CREATED_REMOTE_PRODUCTION_API_KEY = "CREATED_REMOTE_PRODUCTION_API_KEY",
  DELETED_REMOTE_PRODUCTION_API_KEY = "DELETED_REMOTE_PRODUCTION_API_KEY",
  CREATED_TEST_API_KEY = "CREATED_TEST_API_KEY",
  DELETED_TEST_API_KEY = "DELETED_TEST_API_KEY",
  REGENERATED_PRODUCTION_API_KEY = "REGENERATED_PRODUCTION_API_KEY",
  INVITED_USER = "INVITED_USER",
  DEACTIVATED_USER = "DEACTIVATED_USER",
  ACTIVATED_USER = "ACTIVATED_USER",
  UPDATED_ROLE_FOR_USER = "UPDATED_ROLE_FOR_USER",
  TWO_FACTOR_AUTH_ENABLED = "TWO_FACTOR_AUTH_ENABLED",
  TWO_FACTOR_AUTH_DISABLED = "TWO_FACTOR_AUTH_DISABLED",
  DELETED_LINKED_ACCOUNT = "DELETED_LINKED_ACCOUNT",
  CHANGED_SCOPES = "CHANGED_SCOPES",
  CHANGED_PERSONAL_INFORMATION = "CHANGED_PERSONAL_INFORMATION",
  CHANGED_ORGANIZATION_SETTINGS = "CHANGED_ORGANIZATION_SETTINGS",
  ENABLED_INTEGRATION = "ENABLED_INTEGRATION",
  DISABLED_INTEGRATION = "DISABLED_INTEGRATION",
  DISABLED_CATEGORY = "DISABLED_CATEGORY",
  ENABLED_CATEGORY = "ENABLED_CATEGORY",
  CHANGED_PASSWORD = "CHANGED_PASSWORD",
  RESET_PASSWORD = "RESET_PASSWORD",
}

export enum AuditLogRole {
  ADMIN = "ADMIN",
  DEVELOPER = "DEVELOPER",
  MEMBER = "MEMBER",
  MERGE_TEAM = "MERGE_TEAM",
}

export type AuditLogEvent = {
  id: string;
  created_at: Date;
  user_name?: string;
  user_email?: string;
  role: AuditLogRole;
  event_description: string;
  event_type: AuditLogEventType;
  ip_address: string;
};
