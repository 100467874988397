import React from "react";
import MergeDatePicker from "../../../shared-components/MergeDatePicker";
import { EventTypePicker } from "../components";
import { AuditLogEventType } from "../../../../models/Entities";
import UserPicker from "../../../shared-components/UserPicker/UserPicker";
import styled from "styled-components";

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  gap: 16px;

  > div {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

type AuditLogsFiltersProps = {
  startDate: Date | null;
  endDate: Date | null;
  eventType: AuditLogEventType | null;
  userId: number | null;
  onFilterChange: (key: "startDate" | "endDate" | "eventType" | "userId") => (value: any) => void;
};

const AuditLogsFilters = ({
  startDate,
  endDate,
  eventType,
  userId,
  onFilterChange,
}: AuditLogsFiltersProps) => {
  return (
    <StyledRow>
      {/* user filter */}
      <UserPicker userId={userId} onUserIdChange={onFilterChange("userId")} />

      {/* eventType filter*/}
      <EventTypePicker eventType={eventType} onEventTypeChange={onFilterChange("eventType")} />

      {/* date filter */}
      <MergeDatePicker
        selectedStartDate={startDate}
        selectedEndDate={endDate}
        onStartDateSet={onFilterChange("startDate")}
        onEndDateSet={onFilterChange("endDate")}
      />
    </StyledRow>
  );
};

export default AuditLogsFilters;
