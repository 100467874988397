import React from "react";
import styled from "styled-components";

type Props = {
  originField: string;
  originType: string;
  remoteEndpointMethod: string;
  remoteEndpointPath: string;
};

const StyledCol = styled.div`
  flex-direction: column;
  gap: 8px;
  height: 84px;
  &:hover {
    background: var(--gray0, #f2f4f7) !important;
  }
  justify-content: center;
`;

const StyledOriginField = styled.div`
  color: #000;
  font-family:
    "SF Mono",
    SFMono-Regular,
    ui-monospace,
    "DejaVu Sans Mono",
    Menlo,
    Consolas monospace;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`;

const StyledOriginType = styled.div`
  color: var(--gray60, #737982);
  font-family:
    "SF Mono",
    SFMono-Regular,
    ui-monospace,
    "DejaVu Sans Mono",
    Menlo,
    Consolas monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

const StyledMethod = styled.div`
  color: var(--gray60, #737982);
  font-family:
    "SF Mono",
    SFMono-Regular,
    ui-monospace,
    "DejaVu Sans Mono",
    Menlo,
    Consolas monospace;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const StyledPath = styled.div`
  color: var(--gray60, #737982);
  font-family:
    "SF Mono",
    SFMono-Regular,
    ui-monospace,
    "DejaVu Sans Mono",
    Menlo,
    Consolas monospace;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TopRow = styled.div`
  gap: 12px;
`;

const BottomRow = styled.div`
  gap: 6px;
`;

const FieldMappingDropdownChild = ({
  originField,
  originType,
  remoteEndpointMethod,
  remoteEndpointPath,
}: Props) => {
  return (
    <StyledCol className="d-flex w-100 px-3 py-2">
      <TopRow className="d-flex">
        <StyledOriginField>{originField}</StyledOriginField>
        <StyledOriginType className="mt-0.5">{originType}</StyledOriginType>
      </TopRow>
      <BottomRow className="d-flex">
        <StyledMethod>{remoteEndpointMethod}</StyledMethod>
        <StyledPath>{remoteEndpointPath}</StyledPath>
      </BottomRow>
    </StyledCol>
  );
};

export default FieldMappingDropdownChild;
