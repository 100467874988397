import React, { useEffect, useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { VolumeX } from "react-feather";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { LinkedAccount, LinkedAccountMetrics } from "../../../models/Entities";
import { navigateToLinkedAccountsTablePage, useQuery } from "../../../router/RouterUtils";
import { spectrum, palette } from "../../../styles/theme";
import useAppContext from "../../context/useAppContext";
import IntegrationsManagementFilter from "../shared/IntegrationsManagementFilter";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import { DemoDataAlert, WarningAlert } from "../../shared-components/MergeAlerts";
import PaginationFooter from "../../shared-components/PaginationFooter";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import LinkedAccountDeletionModal from "./shared/LinkedAccountDeletionModal";
import OrganizationBillingPlanSideCard from "./shared/OrganizationBillingPlanSideCard";
import { DuplicateAccountBadge } from "./shared/DuplicateLinkedAccountBanner";
import MaybeDisable from "../../shared-components/MaybeDisable";
import Stack from "./shared/Stack";
import { useMemo } from "react";
import { ReactComponent as FilterIcon } from "../../../assets/svg/filter-custom.svg";
import { LinkedAccountStatuses } from "../../../constants";
import { InputCol } from "../shared/IntegrationsManagementFilter";
import { usePaginatedRequest } from "../../shared-components/hooks/usePaginatedRequest";
import LinkedAccountTable from "./LinkedAccountTable";

type Props = {
  isTestAccounts: boolean;
};

type ColoredTextProps = {
  textColor: string;
};

const ColoredText = styled.p<ColoredTextProps>`
  color: ${(props) => props.textColor};
  font-size: 10px;
  font-weight: 400;
`;

type LinkedAccountTypeCardCountProps = {
  isSelected: boolean;
  showOutline: boolean;
};
const LinkedAccountTypeCardCount = styled.div<LinkedAccountTypeCardCountProps>`
  background-color: ${palette.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow:
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2),
    0px 3px 12px -3px rgba(0, 0, 0, 0.12);
  padding: 12px 12px 6px !important;
  cursor: pointer;
  opacity: ${(props) => (props.isSelected ? "1" : 0.35)};
  border: ${(props) => (props.showOutline ? "1px solid #075FF7" : "")};
  &:hover {
    opacity: 1 !important;
  }
`;

const LinkedAccountMetricsRow = styled(Row)`
  margin-bottom: 24px;
}
`;

const LinkedAccountMetric = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 1.5;
  max-height: 48px;
`;

const ToolTipText = styled.p`
  line-height: 1.5;
`;

const getLinkedAccountParams = (selectedStatus: string) => {
  return selectedStatus !== "ALL" ? `status=${selectedStatus}` : "";
};

const LinkedAccountsPageData = ({ isTestAccounts }: Props) => {
  const history = useHistory();

  const { user } = useAppContext();
  const { productRestrictions, orgBillingPlan } = useProductRestrictions();

  const [linkedAccountMetrics, setLinkedAccountMetrics] = useState<LinkedAccountMetrics>();
  const [hoveredStatus, setHoveredStatus] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState(LinkedAccountStatuses.ALL);
  const [paramsPath, setParamsPath] = useState<string | undefined>();
  const [linkedAccountsBeingResynced, setLinkedAccountsBeingResynced] = useState<
    Set<LinkedAccount>
  >(new Set());
  const [linkedAccountToDelete, setLinkedAccountToDelete] = useState<LinkedAccount | undefined>();
  const isLinkedAccountDeletionModalOpen = linkedAccountToDelete != undefined;
  const query = useQuery();

  const completeProductionDuplicatesOf = useMemo<string | null>(
    () => query.get("complete_production_duplicates_of"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const showDuplicatesOnly = !!completeProductionDuplicatesOf;

  const testAccountPath = isTestAccounts ? "&is_test_account=true" : "";
  const linkedAccountsParamsPath = useMemo(
    () => `${getLinkedAccountParams(selectedStatus)}&${paramsPath}${testAccountPath}`,
    [paramsPath, selectedStatus, testAccountPath],
  );

  const {
    results: linkedAccounts,
    hasNext,
    hasPrevious,
    onNext,
    onPrevious,
    onRemove,
    onRefetch,
    hasInitialized,
    isLoading,
  } = usePaginatedRequest<LinkedAccount>({
    rootPath: "/integrations/linked-accounts",
    paramsPath: linkedAccountsParamsPath,
  });

  useEffect(() => {
    let cancelled = false; // prevent race condition

    fetchWithAuth({
      path: "/integrations/linked-accounts/detailed-counts?" + linkedAccountsParamsPath,
      method: "GET",
      onResponse: (data: LinkedAccountMetrics) => {
        if (!cancelled) {
          setLinkedAccountMetrics(data);
        }
      },
    });

    return () => {
      cancelled = true;
    };
  }, [paramsPath, selectedStatus, linkedAccountsParamsPath]);

  // refetch if demo state changes
  useEffect(() => {
    hasInitialized && onRefetch();
  }, [user.is_demo, onRefetch, hasInitialized]);

  const forceResync = (linkedAccount: LinkedAccount) => {
    setLinkedAccountsBeingResynced(new Set(linkedAccountsBeingResynced).add(linkedAccount));
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}/force-resync`,
      method: "POST",
      onResponse: (_) => {
        const newSet = new Set(linkedAccountsBeingResynced);
        newSet.delete(linkedAccount);
        setLinkedAccountsBeingResynced(newSet);
        showSuccessToast(
          `Resync queued for ${linkedAccount.end_user.organization_name} integration with ${linkedAccount.integration.name}.`,
        );
      },
      onError: () => {
        const newSet = new Set(linkedAccountsBeingResynced);
        newSet.delete(linkedAccount);
        setLinkedAccountsBeingResynced(newSet);
        showErrorToast("Failed to force resync. Please try again.");
      },
    });
  };

  const deleteLinkedAccount = () => {
    if (linkedAccountToDelete && linkedAccounts) {
      fetchWithAuth({
        path: `/integrations/linked-accounts/${linkedAccountToDelete.id}`,
        method: "DELETE",
        onResponse: () => {
          const newSet = new Set(linkedAccountsBeingResynced);
          newSet.delete(linkedAccountToDelete);
          setLinkedAccountsBeingResynced(newSet);
          showSuccessToast("Deleted linked account!");
          setLinkedAccountToDelete(undefined);
          const indexToRemove = linkedAccounts.findIndex(
            (linkedAccount) => linkedAccount.id == linkedAccountToDelete.id,
          );
          onRemove(indexToRemove);
        },
        onError: () => {
          showErrorToast("Failed to delete linked account. Please try again.");
        },
      });
    }
  };

  const shouldDisplayTestAccountsSideCard =
    isTestAccounts && productRestrictions?.test_accounts && linkedAccounts;

  const handleStatusSelect = (status: string) => {
    setSelectedStatus(selectedStatus === status ? LinkedAccountStatuses.ALL : status);
  };

  return (
    <>
      {linkedAccountToDelete && (
        <LinkedAccountDeletionModal
          isModalOpen={isLinkedAccountDeletionModalOpen}
          deleteLinkedAccount={deleteLinkedAccount}
          closeModal={() => setLinkedAccountToDelete(undefined)}
          linkedAccount={linkedAccountToDelete}
        />
      )}
      <DemoDataAlert />
      {showDuplicatesOnly && (
        <WarningAlert className="d-flex align-items-center">
          <DuplicateAccountBadge className="flex-shrink-0" size={16} />
          <div className="deprecated-ml-4 deprecated-mr-2 deprecated-my-2">
            <p>
              <b>These Linked Accounts are configured using the same credentials.</b>
            </p>
            If this is intentional, press "Ignore" to dismiss warnings for these accounts.
            Otherwise, delete unwanted Linked Accounts to avoid unnecessary syncing.
          </div>
          <Button
            className="ml-auto"
            color="white"
            variant="white"
            size="sm"
            onClick={() => {
              fetchWithAuth({
                path: `/integrations/linked-accounts/${completeProductionDuplicatesOf}/ignore-duplicates`,
                method: "POST",
                onResponse: () => {
                  showSuccessToast("Successfully ignored accounts with these credentials!");
                  navigateToLinkedAccountsTablePage(history);
                },
                onError: () => {
                  showErrorToast("Failed to ignore accounts with these credentials.");
                },
              });
            }}
          >
            <Stack className="align-items-center">
              <VolumeX className="m-0 p-0" size={12} />
              <span className="deprecated-ml-2">Ignore</span>
            </Stack>
          </Button>
        </WarningAlert>
      )}
      <MaybeDisable disable={showDuplicatesOnly}>
        <IntegrationsManagementFilter
          isLinkedAccounts
          setParamsPath={setParamsPath}
          isTestAccounts={isTestAccounts}
        />
      </MaybeDisable>
      <LinkedAccountMetricsRow className="no-gutters" style={{ minWidth: "100%" }}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="completed-linked-accounts">See all Linked</Tooltip>}
        >
          <InputCol lg="4" md="6" sm="12">
            <LinkedAccountTypeCardCount
              className="deprecated-p-4"
              isSelected={[LinkedAccountStatuses.COMPLETE, LinkedAccountStatuses.ALL].includes(
                selectedStatus,
              )}
              showOutline={LinkedAccountStatuses.COMPLETE == selectedStatus}
              onClick={() => {
                handleStatusSelect(LinkedAccountStatuses.COMPLETE);
              }}
              onMouseEnter={() => {
                setHoveredStatus(LinkedAccountStatuses.COMPLETE);
              }}
              onMouseLeave={() => {
                setHoveredStatus("");
              }}
            >
              <div className="d-flex align-items-center mb-0" style={{ color: "#1A9B87" }}>
                <ColoredText textColor={spectrum.teal50} className="mb-0 mr-auto">
                  Linked
                </ColoredText>
                {LinkedAccountStatuses.COMPLETE == selectedStatus &&
                LinkedAccountStatuses.COMPLETE == hoveredStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Remove Filter
                  </ColoredText>
                ) : LinkedAccountStatuses.COMPLETE == selectedStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Filtered
                  </ColoredText>
                ) : LinkedAccountStatuses.COMPLETE == hoveredStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Filter
                  </ColoredText>
                ) : (
                  <FilterIcon />
                )}
              </div>
              <LinkedAccountMetric>
                {linkedAccountMetrics?.completed_linked_accounts_count ? (
                  linkedAccountMetrics.completed_linked_accounts_count
                ) : (
                  <>
                    <span className="gray dashboard-number-header">-</span>
                  </>
                )}
              </LinkedAccountMetric>
            </LinkedAccountTypeCardCount>
          </InputCol>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="incomplete-linked-accounts">See all Incomplete Links</Tooltip>}
        >
          <InputCol lg="4" md="6" sm="12">
            <LinkedAccountTypeCardCount
              className="deprecated-p-4"
              isSelected={[LinkedAccountStatuses.INCOMPLETE, LinkedAccountStatuses.ALL].includes(
                selectedStatus,
              )}
              showOutline={LinkedAccountStatuses.INCOMPLETE == selectedStatus}
              onClick={() => {
                handleStatusSelect(LinkedAccountStatuses.INCOMPLETE);
              }}
              onMouseEnter={() => {
                setHoveredStatus(LinkedAccountStatuses.INCOMPLETE);
              }}
              onMouseLeave={() => {
                setHoveredStatus("");
              }}
            >
              <div className="d-flex align-items-center mb-0">
                <ColoredText textColor={spectrum.yellow40} className="mb-0 mr-auto">
                  Incomplete Link
                </ColoredText>
                {LinkedAccountStatuses.INCOMPLETE == selectedStatus &&
                LinkedAccountStatuses.INCOMPLETE == hoveredStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Remove Filter
                  </ColoredText>
                ) : LinkedAccountStatuses.INCOMPLETE == selectedStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Filtered
                  </ColoredText>
                ) : LinkedAccountStatuses.INCOMPLETE == hoveredStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Filter
                  </ColoredText>
                ) : (
                  <FilterIcon />
                )}
              </div>
              <LinkedAccountMetric>
                {linkedAccountMetrics?.incomplete_linked_accounts_count ? (
                  linkedAccountMetrics.incomplete_linked_accounts_count
                ) : (
                  <>
                    <span className="gray dashboard-number-header">-</span>
                  </>
                )}
              </LinkedAccountMetric>
            </LinkedAccountTypeCardCount>
          </InputCol>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="relink-needed-linked-accounts">See all Relink Needed</Tooltip>}
        >
          <InputCol lg="4" md="6" sm="12">
            <LinkedAccountTypeCardCount
              isSelected={[LinkedAccountStatuses.RELINK_NEEDED, LinkedAccountStatuses.ALL].includes(
                selectedStatus,
              )}
              showOutline={LinkedAccountStatuses.RELINK_NEEDED == selectedStatus}
              className="deprecated-p-4"
              onClick={() => {
                handleStatusSelect(LinkedAccountStatuses.RELINK_NEEDED);
              }}
              onMouseEnter={() => {
                setHoveredStatus(LinkedAccountStatuses.RELINK_NEEDED);
              }}
              onMouseLeave={() => {
                setHoveredStatus("");
              }}
            >
              <div className="d-flex align-items-center mb-0">
                <ColoredText textColor={spectrum.red40} className="mb-0 mr-auto">
                  Relink Needed
                </ColoredText>
                {LinkedAccountStatuses.RELINK_NEEDED == selectedStatus &&
                LinkedAccountStatuses.RELINK_NEEDED == hoveredStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Remove Filter
                  </ColoredText>
                ) : LinkedAccountStatuses.RELINK_NEEDED == selectedStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Filtered
                  </ColoredText>
                ) : LinkedAccountStatuses.RELINK_NEEDED == hoveredStatus ? (
                  <ColoredText textColor={spectrum.blue40} className="mb-0">
                    Filter
                  </ColoredText>
                ) : (
                  <FilterIcon />
                )}
              </div>
              <LinkedAccountMetric>
                {linkedAccountMetrics?.relink_needed_accounts_count ? (
                  linkedAccountMetrics.relink_needed_accounts_count
                ) : (
                  <>
                    <span className="gray dashboard-number-header">-</span>
                  </>
                )}
              </LinkedAccountMetric>
            </LinkedAccountTypeCardCount>
          </InputCol>
        </OverlayTrigger>
      </LinkedAccountMetricsRow>

      <Row>
        <Col md={shouldDisplayTestAccountsSideCard ? 10 : 12}>
          <LinkedAccountTable
            linkedAccounts={linkedAccounts}
            isLoading={isLoading}
            forceResync={forceResync}
            isTestAccounts={isTestAccounts}
            setLinkedAccountToDelete={setLinkedAccountToDelete}
          />
        </Col>
        {shouldDisplayTestAccountsSideCard && orgBillingPlan && (
          <Col md="2" className="pl-0">
            <OrganizationBillingPlanSideCard billingPlan={orgBillingPlan} />
          </Col>
        )}
      </Row>

      <PaginationFooter
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onPreviousClick={onPrevious}
        onNextClick={onNext}
      />
    </>
  );
};

export default LinkedAccountsPageData;
