import React from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import styled, { css } from "styled-components";
import { EnabledAction } from "../../../models/CommonModel";
import { palette } from "../../../styles/theme";
import MultiSwitch from "../../shared-components/MultiSwitch";
import CenteredVerticalLineCol, { VerticalLineConfig } from "./CenteredVerticalLineCol";
import AnimatedChevron from "./AnimatedChevron";
import MaybeDisable from "../../shared-components/MaybeDisable";
import classNames from "classnames";
import Markdown from "markdown-to-jsx";

export enum Disableability {
  DISABLEABLE,
  NOT_DISABLEABLE,
  DISABLEABLE_WITH_UPGRADE,
}

export enum Enableability {
  ENABLEABLE,
  ENABLEABLE_WITH_UPGRADE,
}

export interface FieldToggleProps {
  displayName: string;
  isEnabled: boolean;
  type?: string;
  description?: string;
  children?: React.ReactNode;
  /** Defaults to "DISABLEABLE". */
  disableability?: Disableability;
  /** Defaults to "ENABLEABLE". */
  enableability?: Enableability;
  /** Defaults to 70.8. */
  iconColumnWidthInPixels?: number;
  showBorderAboveIcon?: boolean;
  showBorderAboveContent?: boolean;
  verticalLineConfig: VerticalLineConfig;
  showDescription?: boolean;
  setShowDescription?: (isExpanded: boolean) => void;
  changeState: (enabledActions: Array<EnabledAction>) => Promise<void>;
  className?: string;
}

const TopBorder = css`
  border-top: 1px solid ${palette.border};
`;

export const BorderedCenteredVerticalLineCol = styled(CenteredVerticalLineCol)<{
  $showBorder?: boolean;
}>`
  ${({ $showBorder }) => $showBorder && TopBorder};
`;

export const XSIconContainer = styled.div.attrs({
  className: "avatar-xs avatar-title rounded-circle",
})<{
  $iconColumnWidthInPixels: number;
}>`
  margin: 0px ${({ $iconColumnWidthInPixels }) => ($iconColumnWidthInPixels / 2).toString() + "px"};
`;

export const mapTypeToFeatherIconClassName = (type: string | undefined) => {
  if (type?.endsWith("[]")) {
    return "fe-list";
  }
  switch (type) {
    case "Object":
      return "fe-list";
    case "Array":
      return "fe-menu";
    case "Number":
      return "fe-hash";
    default:
      return "fe-italic";
  }
};

const FieldRow = styled(Row).attrs({ className: "flex-nowrap" })`
  margin: 0px;
`;

const BorderedFieldRowContent = styled(Row)<{
  $showBorder: boolean;
}>`
  ${({ $showBorder }) => $showBorder && TopBorder}

  min-height: 60px;
  justify-content: flex-end;
`;

const ClickableRow = styled(Row)<{
  $isClickable: boolean;
}>`
  ${({ $isClickable }) => $isClickable && "cursor: pointer"};
`;

const FieldName = styled.div`
  font-weight: 600;
  font-size: 13px;
`;

const PaddedChevron = styled(AnimatedChevron)`
  margin: 0 3px;
`;

const TypeName = styled.div`
  margin: auto 6px;
  font-size: 12px;
  color: ${palette.slate};
`;

const FIELD_OPTIONS = [
  {
    text: "Disabled",
    id: "disabled",
    selectedColor: palette.empty,
    backgroundColor: palette.white,
    borderColor: palette.border,
    disableTooltip:
      "Field toggles are only available for customers on our Professional and Enterprise plans",
  },
  {
    text: "Enabled",
    id: "enabled",
    selectedColor: palette.black,
    backgroundColor: palette.white,
    borderColor: palette.border,
    disableTooltip:
      "Field toggles are only available for customers on our Professional and Enterprise plans",
  },
];

export const StyledMultiSwitch = styled(MultiSwitch)`
  margin-right: 8px;

  button {
    font-size: 12px;
    line-height: 16px;
  }
`;

const DescriptionMarkdown = styled(Markdown)`
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  color: ${palette.slate};

  code {
    color: ${palette.black};
    padding: unset;
    border-radius: unset;
    background: unset;
    border: unset;
  }
`;

/**
 * Ensure elements can be interacted with even if negative margins are used
 */
const ChildrenRow = styled(Row)`
  position: relative;
`;

const FieldToggle = ({
  displayName,
  isEnabled,
  type,
  description,
  children,
  disableability = Disableability.DISABLEABLE,
  enableability = Enableability.ENABLEABLE,
  iconColumnWidthInPixels = 70.8 /* width of IconContainer - width of avatar-xs */,
  showBorderAboveIcon,
  showBorderAboveContent,
  verticalLineConfig,
  showDescription,
  setShowDescription,
  changeState,
  className,
}: FieldToggleProps) => {
  const shouldShowChevron = !!(description && setShowDescription);

  return (
    <FieldRow className={className}>
      <BorderedCenteredVerticalLineCol
        className="col-auto px-0"
        $verticalLineConfig={verticalLineConfig}
        $showBorder={showBorderAboveIcon}
      >
        <Row className="mx-0 h-100">
          <Col className="px-0 my-auto">
            <XSIconContainer $iconColumnWidthInPixels={iconColumnWidthInPixels}>
              <i className={classNames("fe", mapTypeToFeatherIconClassName(type))} />
            </XSIconContainer>
          </Col>
        </Row>
      </BorderedCenteredVerticalLineCol>
      <Col className="pr-0 deprecated-mr-3">
        <BorderedFieldRowContent $showBorder={showBorderAboveContent}>
          <Col className="deprecated-py-3 deprecated-pr-4 pl-0 my-auto">
            <Row className="mx-0">
              <Col className="px-0 col-auto">
                <ClickableRow
                  className="mx-0 flex-nowrap"
                  $isClickable={shouldShowChevron}
                  onClick={
                    shouldShowChevron ? () => setShowDescription(!showDescription) : undefined
                  }
                >
                  <FieldName className="my-auto">{displayName}</FieldName>
                  {shouldShowChevron && <PaddedChevron $isUp={!!showDescription} />}
                  {type && <TypeName>{type}</TypeName>}
                </ClickableRow>
              </Col>
            </Row>
            {description && (
              <Accordion activeKey={showDescription ? "0" : undefined}>
                <Accordion.Collapse eventKey="0">
                  <DescriptionMarkdown className="mx-0 deprecated-pt-3">
                    {description}
                  </DescriptionMarkdown>
                </Accordion.Collapse>{" "}
              </Accordion>
            )}
          </Col>
          <Col className="deprecated-py-3 deprecated-pr-4 pl-0 col-auto my-auto">
            <MaybeDisable
              hasPadding={false}
              disable={disableability === Disableability.NOT_DISABLEABLE}
            >
              <StyledMultiSwitch
                options={FIELD_OPTIONS.map((option) => {
                  return {
                    ...option,
                    disable:
                      (option.id === "disabled" &&
                        disableability === Disableability.DISABLEABLE_WITH_UPGRADE) ||
                      (option.id === "enabled" &&
                        enableability === Enableability.ENABLEABLE_WITH_UPGRADE),
                  };
                })}
                selectedID={isEnabled ? "enabled" : "disabled"}
                onSelectOption={(option) => {
                  if (option.disable) {
                    return;
                  }
                  changeState(
                    option.id === "enabled" ? [EnabledAction.READ, EnabledAction.WRITE] : [],
                  );
                }}
              />
            </MaybeDisable>
          </Col>
        </BorderedFieldRowContent>
        {children && <ChildrenRow>{children}</ChildrenRow>}
      </Col>
    </FieldRow>
  );
};

export const RemoteDataToggle = styled(FieldToggle)`
  ${CenteredVerticalLineCol} {
    margin-left: -13px;
  }
  ${StyledMultiSwitch} {
    margin-right: -5px;
  }

  background-color: #f7f8f9;
  border: 1px solid ${palette.border};
  border-radius: 8px;
`;

export default FieldToggle;
