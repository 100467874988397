import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { RefreshCcwIcon } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface AnySyncWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: SelectedWebhookType | undefined;
}

function AnySyncWebhook(props: AnySyncWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Any sync completed"
        description="Receive an alert when any sync of a Linked Account is complete."
        icon={<RefreshCcwIcon size={16} />}
        onClick={() => onSelectedWebhookTypeChange(SelectedWebhookType.ANY_SYNC)}
        selected={selectedWebhookType === SelectedWebhookType.ANY_SYNC}
        deprecated={true}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default AnySyncWebhook;
