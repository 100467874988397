import React, { useState } from "react";
import styled from "styled-components";
import { LinkedAccount } from "../../../../../models/Entities";
import Stack from "../../shared/Stack";
import CopyComponentWithIcon from "../../../../shared-components/CopyComponentWithIcon";
import { Key, EyeOff, Layers, ChevronRight, Library, ArrowUpRight, Eye } from "lucide-react";
import ClickableContainer from "../../../../shared-components/ClickableContainer";
import {
  DOCS_BASE_PATH,
  getDocsPathForCategory,
  getDocsPathForIntegration,
  DOCS_SYNCING_DATA_PATH,
  MERGE_HELP_CENTER_PATH,
} from "../../../../../router/RouterUtils";
import IntegrationNameAndLogo from "../../../../configuration/integrations/IntegrationNameAndLogo";
import useAppContext from "../../../../context/useAppContext";
import SkeletonLoader from "../../../../shared-components/SkeletonLoader";

type OverviewColumnProps = {
  linkedAccount: LinkedAccount | null;
};

const OverviewSection = styled.div`
  padding: 6px 8px 8px 8px;
  border: 0.5px solid var(--lm-gray-20, #dce2ea);
  border-radius: 6px;
  background: #f5f7f9;
  font-weight: 600;
  font-size: 12px;
`;

const SectionTitle = styled.div`
  margin-bottom: 6px;
  padding-left: 4px;
`;

const ReferenceSectionTitleContainer = styled.div`
  font-size: 16px;
  padding: 12px 16px;
  border-bottom: 0.5px solid #dce2ea;
`;
const DarkSection = styled.div`
  background: #eaeef3;
  border: 0.5px solid #dce2ea;
  border-radius: 4px;
  padding: 3px 8px;
`;

const LinkedAccountIDText = styled.div`
  font-weight: 400;
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
    monospace;
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 9px;
`;

const AccountTokenText = styled.div`
  font-weight: 400;
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
    monospace;
  font-size: 8px;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 9px;
`;

const StyledLibrary = styled(Library)`
  margin-bottom: 4px;
`;

const StyledEyeOff = styled(EyeOff)`
  cursor: pointer;
`;

const StyledEye = styled(Eye)`
  cursor: pointer;
`;
type ReferenceSectionContainerProps = {
  showBorder: boolean;
};
const ReferenceSectionContainer = styled.div<ReferenceSectionContainerProps>`
  padding: 16px;
  border-bottom: ${(props) => (props.showBorder ? "0.5px solid #dce2ea" : "")};
`;

const MergeDocsTitleContainer = styled.div`
  margin-bottom: 8px;
`;
const IconContainer = styled.div`
  margin-right: 7px;
`;
const StyledChevronRight = styled(ChevronRight)`
  margin-left: 5px;
`;

const OverviewColumn = ({ linkedAccount }: OverviewColumnProps) => {
  const [isAccountTokenHidden, setIsAccountTokenHidden] = useState(true);
  const { isUserPrivileged } = useAppContext();
  return (
    <Stack $isVertical $gap="20px">
      {linkedAccount ? (
        <>
          <OverviewSection>
            <SectionTitle>Linked Account ID</SectionTitle>
            <DarkSection className="d-flex justify-content-between">
              <LinkedAccountIDText>{linkedAccount.id}</LinkedAccountIDText>
              <CopyComponentWithIcon text={linkedAccount.id} />
            </DarkSection>
          </OverviewSection>
          {isUserPrivileged && (
            <OverviewSection>
              <SectionTitle className="d-flex align-items-center">
                <IconContainer>
                  <Key size={12} />
                </IconContainer>
                <div>Account Token</div>
              </SectionTitle>
              <DarkSection className="d-flex align-items-center">
                {isAccountTokenHidden ? (
                  <>
                    <IconContainer>
                      <StyledEyeOff
                        size={12}
                        onClick={() => {
                          setIsAccountTokenHidden(!isAccountTokenHidden);
                        }}
                      />
                    </IconContainer>
                    <AccountTokenText>
                      {"•".repeat(linkedAccount?.account_token?.length)}
                    </AccountTokenText>
                  </>
                ) : (
                  <>
                    <IconContainer>
                      <StyledEye
                        size={12}
                        onClick={() => {
                          setIsAccountTokenHidden(!isAccountTokenHidden);
                        }}
                      />
                    </IconContainer>
                    <AccountTokenText>{linkedAccount?.account_token}</AccountTokenText>
                  </>
                )}
                <CopyComponentWithIcon text={linkedAccount?.account_token} />
              </DarkSection>
            </OverviewSection>
          )}
          <OverviewSection className="p-0">
            <ReferenceSectionTitleContainer>Reference</ReferenceSectionTitleContainer>
            <ReferenceSectionContainer className="d-flex flex-column" showBorder>
              <MergeDocsTitleContainer className="d-flex align-items-center">
                <IconContainer>
                  <Layers size={12} />
                </IconContainer>
                <ClickableContainer
                  onClick={() => {
                    window.open(DOCS_BASE_PATH, "_blank");
                  }}
                >
                  <div className="d-flex align-items-center">
                    <div>Merge Docs</div>
                    <StyledChevronRight size={12} />
                  </div>
                </ClickableContainer>
              </MergeDocsTitleContainer>
              <a
                className="text-[10px] leading-[24px] w-fit font-medium"
                href={getDocsPathForCategory(linkedAccount.category)}
                target="_blank"
              >
                API Reference
              </a>
              <a
                className="text-[10px] leading-[24px] w-fit font-medium"
                href={getDocsPathForIntegration(
                  linkedAccount.integration?.slug,
                  linkedAccount.category,
                )}
                target="_blank"
              >
                {linkedAccount.integration?.name}
              </a>
              <a
                className="text-[10px] leading-[24px] w-fit font-medium"
                href={DOCS_SYNCING_DATA_PATH}
                target="_blank"
              >
                Syncing Data
              </a>
            </ReferenceSectionContainer>
            <ReferenceSectionContainer
              className="d-flex flex-column"
              showBorder={linkedAccount.integration?.api_documentation_url !== undefined}
            >
              <MergeDocsTitleContainer className="d-flex align-items-center mb-0">
                <IconContainer>
                  <StyledLibrary size={14} />
                </IconContainer>
                <ClickableContainer
                  onClick={() => {
                    window.open(
                      `${MERGE_HELP_CENTER_PATH}/en/?q=${linkedAccount.integration?.slug}`,
                      "_blank",
                    );
                  }}
                >
                  <div className="d-flex align-items-center mb-0">
                    <div>Help Center</div>
                    <StyledChevronRight size={12} />
                  </div>
                </ClickableContainer>
              </MergeDocsTitleContainer>
            </ReferenceSectionContainer>
            {linkedAccount.integration?.api_documentation_url && (
              <ClickableContainer
                onClick={() => {
                  window.open(linkedAccount.integration.api_documentation_url, "_blank");
                }}
              >
                <ReferenceSectionContainer
                  className="d-flex justify-content-between align-items-center"
                  showBorder={false}
                >
                  <IntegrationNameAndLogo
                    integration={linkedAccount.integration}
                    customText={`${linkedAccount.integration?.name} Docs`}
                  />

                  <ArrowUpRight size={12} />
                </ReferenceSectionContainer>
              </ClickableContainer>
            )}
          </OverviewSection>
        </>
      ) : (
        <div className="flex flex-column gap-y-5">
          <div className="bg-[#f5f7f9] rounded-xl border border-gray-10 px-3 py-4 flex items-center justify-start">
            <SkeletonLoader width={127} height={12} borderRadius={2} className="my-0.5" />
          </div>
          <div className="bg-[#f5f7f9] rounded-xl border border-gray-10 px-3 py-4 flex items-center justify-start">
            <SkeletonLoader width={172} height={12} borderRadius={2} className="my-0.5" />
          </div>
          <div className="bg-[#f5f7f9] rounded-xl border border-gray-10 px-3 py-4 flex items-center justify-start">
            <SkeletonLoader width={149} height={12} borderRadius={2} className="my-0.5" />
          </div>
        </div>
      )}
    </Stack>
  );
};
export default OverviewColumn;
