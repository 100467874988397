import React from "react";
import {
  abbreviationForAPICategory,
  colorForAPICategory,
  stringRemoveSpaces,
} from "../../../../../../../services";
import MaybeDisable from "../../../../../../shared-components/MaybeDisable";
import { X } from "lucide-react";
import { APICategory, Badge, Checkbox } from "@merge-api/merge-javascript-shared";
import { CategoryMap } from "../../../hooks/useWebhookOptions";
import startCase from "lodash-es/startCase";

interface Props {
  commonModelName: string;
  selectedEvents: string[];
  onRemove?: () => void;
  onCheckboxChange: (isChecked: boolean, value: string) => void;
  deletedWebhookEnabled: boolean;
  modelToCategoryMap: CategoryMap;
}
const ChangedDataCommonModelCard = ({
  commonModelName,
  selectedEvents,
  onRemove,
  onCheckboxChange,
  modelToCategoryMap,
  deletedWebhookEnabled,
}: Props) => {
  const isChecked = (eventName: string) =>
    selectedEvents.includes(stringRemoveSpaces(commonModelName) + eventName);

  const handleCheckboxChange = (eventName: string) => (isChecked: boolean) => {
    onCheckboxChange(isChecked, stringRemoveSpaces(commonModelName) + eventName);
  };

  return (
    <div className="mb-3 px-4 py-3 flex lg:flex-row cursor-default flex-col lg:items-center items-start justify-between border border-gray-10 rounded-lg w-full bg-white">
      <div className="flex flex-row justify-center items-center">
        <X
          onClick={onRemove}
          className="cursor-pointer mr-4 hover:text-gray-70 transition-colors duration-300"
          size={12}
        />
        <div className="text-gray-70 font-semibold mr-1.5 whitespace-nowrap">
          {startCase(commonModelName)}
        </div>
        <Badge
          className="ml-1.5"
          size="sm"
          color={colorForAPICategory(modelToCategoryMap[commonModelName] as APICategory)}
        >
          {abbreviationForAPICategory(modelToCategoryMap[commonModelName] as APICategory)}
        </Badge>
      </div>
      <div className="flex flex-row justify-center items-center lg:mt-0 mt-3">
        <Checkbox
          onChange={handleCheckboxChange(".changed")}
          defaultChecked={isChecked(".changed")}
          className="lg:ml-6"
          color="blue"
          label="Created"
        />
        <Checkbox
          onChange={handleCheckboxChange(".added")}
          defaultChecked={isChecked(".added")}
          className="ml-6"
          color="blue"
          label="Updated"
        />
        <MaybeDisable
          className="flex items-center justify-center"
          disable={!deletedWebhookEnabled}
          tooltip={`
                Checking the 'Deleted' option will allow your organization to receive deletion webhooks from Merge if your organization has either the Deletion Detection or the Receiving Webhooks feature enabled. Reach out to us on Intercom to turn on either feature.
                  `}
          inline
          hasPadding={false}
        >
          <Checkbox
            onChange={handleCheckboxChange(".removed")}
            defaultChecked={isChecked(".removed")}
            className="ml-6"
            color="blue"
            label="Deleted"
            disabled={!deletedWebhookEnabled}
          />
        </MaybeDisable>
      </div>
    </div>
  );
};

export default ChangedDataCommonModelCard;
