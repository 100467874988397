import React from "react";
import styled from "styled-components";
import { ArrowRight, X } from "react-feather";

const Banner = styled.a`
  background-color: #473ee5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  width: 100vw;
  height: 48px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;

  p {
    color: #ffffff;
    text-decoration: none;
    margin: 0;
  }
`;

const CallToAction = styled.div.attrs({ className: "d-flex align-items-center" })`
  padding: 16px 24px;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #ffffff;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  p {
    margin-right: 8px;
  }

  svg {
    transition: transform 0.15s ease-out;
    transform: translateX(0);
  }

  &:hover {
    color: #ffffff;

    p {
      color: inherit;
      text-decoration: underline;
    }

    svg {
      transform: translateX(2px);
    }
  }
`;

const VerticalLine = styled.div`
  width: 1.33px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.2);
`;

const DismissButton = styled.button`
  padding: 8px 8px;
  margin: 0 16px;
  background-color: rgba(255, 255, 255, 0);
  border: 0;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.12s ease-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

type Props = {
  dismissAnnouncementBanner: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const AnnouncementBanner = ({ dismissAnnouncementBanner }: Props) => (
  <Banner href="https://www.merge.dev/pricing" target="_blank" rel="noreferrer">
    <p>Merge updated its pricing plans on September 1st, 2021!</p>
    <div className="d-flex align-items-center">
      <CallToAction>
        <p>View pricing plans</p>
        <ArrowRight color="white" size="16px" />
      </CallToAction>
      <VerticalLine />
      <DismissButton onClick={dismissAnnouncementBanner}>
        <X color="white" size="16px" />
      </DismissButton>
    </div>
  </Banner>
);

export default AnnouncementBanner;
