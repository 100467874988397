import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import { IssueDescription } from "../../../../constants";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../../../styles/theme";
import DeprecatedH2 from "../../../../deprecated/DeprecatedH2";

type Props = {
  integrationIssue: IntegrationIssue | undefined;
};

export const getDescription = (integrationIssue: IntegrationIssue) => {
  switch (integrationIssue?.error_description) {
    case IssueDescription.MISSING_PERMISSION:
      return "Error making API requests due to missing permissions.";
    case IssueDescription.BAD_API_KEY:
      return `The API Credentials used to link your system are now invalid. Please go through the authentication flow again and relink your ${integrationIssue?.linked_account?.integration?.name} account.`;
    case IssueDescription.HIT_RATE_LIMIT:
      return "Error making API requests due to hitting the rate limit.";
    case IssueDescription.INVALID_LOGIN_CREDENTIALS:
      return "Error accessing provider due to expired login credentials.";
    case IssueDescription.EXPIRED_MFA_AUTHENTICATION:
      return "Error accessing provider due to expired multi-factor authentication credentials.";
    default:
      return "Error making API requests.";
  }
};
const IntegrationManagementIndividualIssueDescription = ({ integrationIssue }: Props) => {
  return (
    <Row className="deprecated-mb-3 pl-0 pr-5">
      <Col>
        <Card>
          <Card.Body>
            <DeprecatedH2 className="deprecated-mb-2">Description</DeprecatedH2>
            <p className="mb-0">
              {integrationIssue ? (
                getDescription(integrationIssue)
              ) : (
                <ContentLoader
                  speed={1.4}
                  width={250}
                  height={40}
                  viewBox="0 0 250 40"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="2" y="4" rx="3" ry="3" width="250" height="14" />
                  <rect x="2" y="25" rx="3" ry="3" width="250" height="14" />
                </ContentLoader>
              )}
            </p>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default IntegrationManagementIndividualIssueDescription;
