import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import MergeTable from "../../../shared-components/MergeTable";
import FieldMappingByIntegrationRow from "./FieldMappingByIntegrationRow";
import { showSuccessToast, showErrorToast } from "../../../shared-components/Toasts";
import { deleteLinkedAccountFieldMapping } from "../../../../api-client/APIClient";
import { getAvailableIntegrationsForFieldMapping } from "./utils/FieldMappingUtils";
import {
  AvailableFieldMappingIntegration,
  IntegrationWideFieldMappingInstanceInfo,
} from "../../../../models/Entities";
import InfoIconWithTooltip from "../../../shared-components/InfoIconWithTooltip";
import useAppContext from "../../../context/useAppContext";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";
const FieldMappingsTableTitle = styled.div`
  color: #121314;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const FieldMappingsTableSubtitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin-top: 9px;
`;

const FieldMappingsTableContainerHeader = styled.div`
  margin-bottom: 20px;
`;

type Props = {
  id: any;
};

type FieldMappingByIntegrationTableProps = {
  fieldMappingTargetId: string;
  availableIntegrations: AvailableFieldMappingIntegration[];
  fieldMappingTargetCommonModelName: string;
  fieldMappingTargetCategory: string;
  fieldMappingTargetFieldKey: string;
  refreshFieldMappings: () => void;
};

const FieldMappingByIntegrationTable = ({
  fieldMappingTargetId,
  availableIntegrations,
  fieldMappingTargetCommonModelName,
  fieldMappingTargetCategory,
  fieldMappingTargetFieldKey,
  refreshFieldMappings,
}: FieldMappingByIntegrationTableProps) => {
  const { id } = useParams<Props>();
  const { user } = useAppContext();
  const [selectedSearchOption, setSelectedSearchOption] = useState<Array<string>>([]);
  const [updatedAvailableIntegrations, setUpdatedAvailableIntegrations] =
    useState<AvailableFieldMappingIntegration[]>(availableIntegrations);

  useEffect(() => setUpdatedAvailableIntegrations(availableIntegrations), [availableIntegrations]);

  const updateAvailableIntegrations = (
    integrationID: string,
    fieldMappingInstanceId: string,
    originField: string,
    remoteEndpointPath: string,
    remoteEndpointMethod: string,
    originType: string,
    isLinkedAccountOverrideEnabled: boolean,
    traversalPath: Array<string>,
  ) => {
    const currAvailableIntegrations = updatedAvailableIntegrations;
    const currIndex =
      currAvailableIntegrations.findIndex(
        (element) => element.integration_info.id === integrationID,
      ) ?? -1;
    if (currIndex >= 0 && currAvailableIntegrations.length) {
      const currIntegration = currAvailableIntegrations[currIndex];
      const newFieldMappingInfo: IntegrationWideFieldMappingInstanceInfo = {
        id: fieldMappingInstanceId,
        origin_field: originField,
        display_name: originField,
        field_description: undefined,
        remote_endpoint_path: remoteEndpointPath,
        enable_linked_account_level_overrides: isLinkedAccountOverrideEnabled,
        field_traversal_path: traversalPath,
        remote_endpoint_method: remoteEndpointMethod,
        origin_type: originType,
      };
      currIntegration.field_mapping_info = newFieldMappingInfo;
      currAvailableIntegrations[currIndex] = currIntegration;
      setUpdatedAvailableIntegrations([...currAvailableIntegrations]);
    }
  };

  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);
  const tableHeaders = (
    <>
      <th scope="col">Integration</th>
      <th scope="col">Origin Field</th>
      <th scope="col">Endpoint</th>
      <th scope="col">
        Overrides
        <InfoIconWithTooltip
          text="Allow overrides to this Field Mapping in Dashboard and Link for each Linked Account"
          iconClassName="deprecated-ml-1 text-gray-50"
        />
      </th>
    </>
  );

  const handleFieldMappingDelete = (avaialbleIntegration: AvailableFieldMappingIntegration) => {
    const { field_mapping_info } = avaialbleIntegration;
    deleteLinkedAccountFieldMapping(field_mapping_info!.id, () => {
      getAvailableIntegrationsForFieldMapping({
        fieldMappingTargetID: fieldMappingTargetId,
        onFetch: () => {
          refreshFieldMappings();
        },
        onError: () => {
          showErrorToast("Unable to Delete Field Mapping");
        },
      });
      showSuccessToast("Successfully Deleted Field Mapping");
    });
  };

  const filteredAvailableIntegrations =
    selectedSearchOption.length == 0 || selectedSearchOption[0] == ""
      ? updatedAvailableIntegrations
      : updatedAvailableIntegrations.filter(
          (availableIntegration) =>
            availableIntegration.integration_info.name.includes(selectedSearchOption[0]) ||
            (availableIntegration?.field_mapping_info?.display_name ?? "").includes(
              selectedSearchOption[0],
            ) ||
            (availableIntegration?.field_mapping_info?.remote_endpoint_path ?? "")?.includes(
              selectedSearchOption[0],
            ),
        );
  const options: string[] = [];
  filteredAvailableIntegrations.forEach(
    (availableIntegration: AvailableFieldMappingIntegration) => {
      options.push(availableIntegration?.integration_info.name);
      if (availableIntegration?.field_mapping_info?.display_name != null) {
        options.push(availableIntegration?.field_mapping_info?.display_name);
      }
      if (availableIntegration?.field_mapping_info?.remote_endpoint_path != null) {
        options.push(availableIntegration?.field_mapping_info?.remote_endpoint_path);
      }
    },
  );
  options.sort();

  const rowData = (
    <>
      {filteredAvailableIntegrations.map(
        (availableIntegration: AvailableFieldMappingIntegration) => {
          return (
            <FieldMappingByIntegrationRow
              fieldMappingTargetID={id}
              fieldMappingTargetCommonModelName={fieldMappingTargetCommonModelName}
              fieldMappingInstanceId={availableIntegration?.field_mapping_info?.id}
              fieldMappingTargetCategory={fieldMappingTargetCategory}
              fieldMappingTargetFieldKey={fieldMappingTargetFieldKey}
              integrationID={availableIntegration.integration_info.id}
              name={availableIntegration.integration_info.name}
              squareImage={availableIntegration.integration_info.square_image}
              originField={availableIntegration.field_mapping_info?.display_name}
              remoteEndpointPath={availableIntegration?.field_mapping_info?.remote_endpoint_path}
              remoteEndpointMethod={
                availableIntegration?.field_mapping_info?.remote_endpoint_method
              }
              originType={availableIntegration?.field_mapping_info?.origin_type}
              isLinkedAccountOverrideEnabled={
                availableIntegration?.field_mapping_info?.enable_linked_account_level_overrides
              }
              handleDelete={() => {
                handleFieldMappingDelete(availableIntegration);
              }}
              updateAvailableIntegrations={updateAvailableIntegrations}
            />
          );
        },
      )}
    </>
  );

  return (
    <div className="d-flex flex-column">
      <FieldMappingsTableContainerHeader>
        <FieldMappingsTableTitle>Field Mappings by integration</FieldMappingsTableTitle>
        <FieldMappingsTableSubtitle>
          Create Field Mappings that apply across all Linked Accounts of an integration for this
          target field.
        </FieldMappingsTableSubtitle>
        {isFieldMappingV2Enabled && (
          <FieldMappingsTableSubtitle>
            Any changes to data made here will be updated in each Linked Account's next sync.
          </FieldMappingsTableSubtitle>
        )}
      </FieldMappingsTableContainerHeader>
      {isFieldMappingV2Enabled && (
        <span className="mb-5">
          <MergeTypeahead
            id="typeahead"
            multiple={false}
            selected={selectedSearchOption}
            options={options}
            inputProps={{ autoComplete: "none" }}
            placeholder="Search integrations, mappings, or endpoints..."
            onChange={(selectedOptions) => {
              setSelectedSearchOption(selectedOptions);
            }}
            renderMenuItemChildren={(option, index) => {
              return <React.Fragment key={`${option}-${index}`}>{option}</React.Fragment>;
            }}
            includeChevronDown={false}
            includeSearchIcon
            isDarkBar
          />
        </span>
      )}
      {filteredAvailableIntegrations && (
        <div>
          <MergeTable
            header={tableHeaders}
            content={rowData}
            hasMarginBottom={false}
            isOverviewPage
          />
        </div>
      )}
    </div>
  );
};
export default FieldMappingByIntegrationTable;
