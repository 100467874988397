import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExternalLink } from "lucide-react";
import styled from "styled-components";

interface Color {
  r: string | number;
  g: string | number;
  b: string | number;
}

const ExternalLinkIconStyled = styled.div`
  margin-left: auto;
  font-size: 12px;
`;

type Props = {
  url: string;
  foregroundColor?: Color;
};

const ExternalLinkWithIcon = ({ foregroundColor, url }: Props) => {
  const foregroundColorCssValue = foregroundColor
    ? `rgb(${foregroundColor.r}, ${foregroundColor.g}, ${foregroundColor.b})`
    : undefined;

  const IconButton = styled(ExternalLink)`
    box-sizing: border-box;
    max-width: 12px;
    max-height: 12px;
    ${foregroundColorCssValue && `color: ${foregroundColorCssValue}`}
  `;

  return (
    <ExternalLinkIconStyled className="text-gray-50">
      <OverlayTrigger
        placement="top"
        delay={{ show: 0, hide: 0 }}
        overlay={<Tooltip id={"tooltip-external-link-" + url}>{"Open in new tab"}</Tooltip>}
      >
        <span>
          <a href={url} target="_blank" color="#95aac9">
            <IconButton className="text-gray-50 fe fe-copy cursor-pointer" />
          </a>
        </span>
      </OverlayTrigger>
    </ExternalLinkIconStyled>
  );
};

export default ExternalLinkWithIcon;
