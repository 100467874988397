import { useCallback, useEffect, useState } from "react";
import { fetchWithAuth } from "../../../api-client/APIClient";

export enum MergeFlagFeature {
  MERGE_FLAG_ENABLE_ASYNC_PASSTHROUGH = "MERGE_FLAG_ENABLE_ASYNC_PASSTHROUGH", // TODO: remove https://app.asana.com/0/1202262938305017/1204912151514558/f
  MERGE_FLAG_ENABLE_AUDIT_LOG = "MERGE_FLAG_ENABLE_AUDIT_LOG", // TODO: remove https://app.asana.com/0/1205176270638090/1205278951203580/f
  MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND = "MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND", // TODO: remove https://app.asana.com/0/1205174382712375/1205328852055066/f
  FEATURE_DISCOVERY_ENABLED = "FEATURE_DISCOVERY_ENABLED", // TODO: remove https://app.asana.com/0/1202262938305017/1205494370724397/f
}

export type UseMergeFlagProps = {
  feature: MergeFlagFeature;
  organizationId?: string;
  linkedAccountId?: string;
  integrationId?: string;
};

/**
 * Hook for checking if merge flag is enabled. Requires one (and only one)
 * of organizationId, linkedAccountId, or integrationId
 *
 * If you just want to hide UI can use `<MergeFlagWrapper />`
 */
export const useMergeFlag = ({
  feature,
  linkedAccountId,
  organizationId,
  integrationId,
}: UseMergeFlagProps) => {
  // state
  const [enabled, setEnabled] = useState<boolean | undefined>(undefined);
  const [enabledForLinkedAccount, setEnabledForLinkedAccount] = useState<boolean | undefined>(
    undefined,
  );
  const [enabledForOrganization, setEnabledForOrganization] = useState<boolean | undefined>(
    undefined,
  );
  const [enabledForIntegration, setEnabledForIntegration] = useState<boolean | undefined>(
    undefined,
  );

  // event handlers
  const loadMergeFlagPermissions = useCallback(() => {
    fetchWithAuth({
      path: `integrations/merge-flags/${feature}/enabled`,
      method: "POST",
      body: {
        organizationId,
        integrationId,
        linkedAccountId,
      },
      onResponse: (res: {
        feature_enabled_for_organization: boolean;
        feature_enabled_for_integration: boolean;
        feature_enabled_for_linked_account: boolean;
      }) => {
        if (
          (organizationId && res.feature_enabled_for_organization) ||
          (integrationId && res.feature_enabled_for_integration) ||
          (linkedAccountId && res.feature_enabled_for_linked_account)
        ) {
          setEnabled(true);
        } else {
          setEnabled(false);
        }
        setEnabledForLinkedAccount(res.feature_enabled_for_linked_account ?? undefined);
        setEnabledForOrganization(res.feature_enabled_for_organization ?? undefined);
        setEnabledForIntegration(res.feature_enabled_for_integration ?? undefined);
      },
      onError: (err) => {
        console.error(err);
      },
    });
  }, [integrationId, linkedAccountId, organizationId, feature]);

  // effects
  // initialize
  useEffect(() => {
    loadMergeFlagPermissions();
  }, [loadMergeFlagPermissions]);

  return {
    enabled,
    enabledForLinkedAccount,
    enabledForOrganization,
    enabledForIntegration,
  };
};
