import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { LinkedAccount } from "../../../../../models/Entities";
import MergeDatePicker from "../../../../shared-components/MergeDatePicker";
import MergeTable from "../../../../shared-components/MergeTable";
import IssueFilter from "./IssuesFilter";
import { fetchIntegrationIssuesForLinkedAccount } from "../../../utils/IntegrationsManagementAPIClient";
import EmptyStateWrapper from "../../../../shared-components/EmptyStateWrapper";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import IssuesPageRow from "./issuesPageRow";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";

type IssuesPageProps = {
  linkedAccount: LinkedAccount | null;
};

const IssuesFilterContainer = styled(Row)`
  gap: 16px;
  align-items: center;
`;

const IssuesPage = ({ linkedAccount }: IssuesPageProps) => {
  const [isMutedShown, setIsMutedShown] = useState(true);
  const [isResolvedShown, setIsResolvedShown] = useState(true);

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [linkedAccountIntegrationIssues, setLinkedAccountIntegrationIssues] = useState<
    IntegrationIssue[] | undefined
  >(undefined);
  const [isFetchingIssues, setIsFetchingIssues] = useState(false);

  const getParamsPath = () => {
    const paramsPath = new URLSearchParams();
    if (isMutedShown) {
      paramsPath.append("include_muted", "true");
    }
    if (!isResolvedShown) {
      paramsPath.append("status", "ONGOING");
    }

    if (selectedStartDate != null) {
      const startDateString = new Date(selectedStartDate).toISOString();
      paramsPath.append("start_date", startDateString);
    }

    if (selectedEndDate != null) {
      const endDateString = new Date(selectedEndDate).toISOString();

      paramsPath.append("end_date", endDateString);
    }

    return paramsPath;
  };

  useEffect(() => {
    if (!linkedAccount) {
      return;
    }
    setIsFetchingIssues(true);
    const paramsPath = getParamsPath();
    fetchIntegrationIssuesForLinkedAccount({
      linkedAccountId: linkedAccount.id,
      paramsPath: paramsPath,
      onSuccess: (data) => {
        setLinkedAccountIntegrationIssues(data.results);
        setIsFetchingIssues(false);
      },
    });
  }, [isMutedShown, isResolvedShown, selectedStartDate, selectedEndDate]);

  const headers = (
    <>
      <th scope="col" colSpan={2}>
        Issue
      </th>
      <th scope="col">Status</th>
      <th scope="col">Recent Activity</th>
      <th scope="col">Updated</th>
      <th scope="col">Created</th>
      <th scope="col">Shortcuts</th>
    </>
  );

  return (
    <div>
      <IssuesFilterContainer className="mt-3">
        <Col>
          <MergeDatePicker
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
            onStartDateSet={setSelectedStartDate}
            onEndDateSet={setSelectedEndDate}
          />
        </Col>
        <Col>
          <IssueFilter
            label="Hide Muted"
            isSelected={!isMutedShown}
            onClick={() => {
              setIsMutedShown(!isMutedShown);
            }}
          />
        </Col>
        <Col>
          <IssueFilter
            label="Hide Resolved"
            isSelected={!isResolvedShown}
            onClick={() => {
              setIsResolvedShown(!isResolvedShown);
            }}
          />
        </Col>
      </IssuesFilterContainer>
      <div className="mt-6">
        <MergeTable
          header={headers}
          content={
            <>
              {!isFetchingIssues && linkedAccountIntegrationIssues ? (
                linkedAccountIntegrationIssues.length > 0 ? (
                  linkedAccountIntegrationIssues.map((linkedAccountIssue: IntegrationIssue) => {
                    return <IssuesPageRow linkedAccountIssue={linkedAccountIssue} />;
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="p-0">
                      <EmptyStateWrapper isTable title="No issues" />
                    </td>
                  </tr>
                )
              ) : (
                <TableSkeletonLoader />
              )}
            </>
          }
        />
      </div>
    </div>
  );
};
export default IssuesPage;
