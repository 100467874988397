import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import { fetchWithAuth } from "../../../api-client/APIClient";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import SpinnerButton from "../../shared-components/SpinnerButton";
import useAppContext from "../../context/useAppContext";
import { Modal } from "react-bootstrap";
import { PaymentMethodTypes } from "./BillingModels";
import styled from "styled-components";
import { PaymentMethod } from "@stripe/stripe-js";
import MergeText, { SmallTextMutedParagraph } from "../../shared-components/MergeText";

type Props = {
  onHide: () => void;
};

const CardHeaderText = styled(MergeText)`
  && {
    font-size: 18px;
    line-height: 36px;
    margin-bottom: 3px;
  }
`;

const ModalBody = styled(Modal.Body)`
  margin-bottom: 0;
  padding: 25px 0 0 0;
`;

const ErrorHeaderText = styled.b`
  color: #f14150;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export default function BankAccountSetupForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [linkFailedByExit, setLinkFailedByExit] = useState(false);
  const [linkFailedUnknownReason, setLinkFailedUnknownReason] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [setupIntentClientSecret, setSetupIntentClientSecret] = useState<string>("");
  const [bankAccount, setBankAccount] = useState<PaymentMethod.UsBankAccount | null | undefined>(
    undefined,
  );
  useEffect(() => {
    fetchWithAuth({
      path: "/billing/stripe/setup-intent",
      method: "POST",
      body: {
        payment_method: PaymentMethodTypes.BANK_ACCOUNT,
      },
      onResponse: (data) => {
        setSetupIntentClientSecret(data.setup_intent_client_secret);
      },
    });
  }, []);

  const openStripeConnect = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (!stripe || !elements || !setupIntentClientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      showErrorToast("Oops, something went wrong!  Please try again in 10 seconds.");
      return;
    }
    setIsLoading(true);
    stripe
      .collectBankAccountForSetup({
        clientSecret: setupIntentClientSecret,
        params: {
          payment_method_type: "us_bank_account",
          payment_method_data: {
            billing_details: {
              name: user.organization.name,
              email: user.email,
            },
          },
        },
        expand: ["payment_method"],
      })
      .then(({ setupIntent, error }) => {
        if (error || !setupIntent) {
          setLinkFailedUnknownReason(true);
          setIsLoading(false);
        } else if (setupIntent.status === "requires_payment_method") {
          // This error is when user exited during linkage
          setLinkFailedByExit(true);
          setIsLoading(false);
        } else if (setupIntent.status === "requires_confirmation") {
          // We collected an account - possibly instantly verified, but possibly
          // manually-entered. Display payment method details and mandate text
          // to the customer and confirm the intent once they accept
          // the mandate.
          // Note: Stripe's type definition of US bank account is somehow outdated despite being on latest package. Garbage.
          if (setupIntent?.payment_method && !(typeof setupIntent.payment_method === "string")) {
            setBankAccount(setupIntent?.payment_method?.us_bank_account);
          }
          setShowConfirmation(true);
          setIsLoading(false);
        }
      });
  };

  const confirmBankAccount = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();

    if (!stripe || !elements || !setupIntentClientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      showErrorToast("Oops, something went wrong!  Please try again in 10 seconds.");
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmUsBankAccountSetup(setupIntentClientSecret);

    if (result.error) {
      showErrorToast(result.error.message ?? "");
      setIsLoading(false);
    } else {
      showSuccessToast("Bank account added successfully!");
      props.onHide();
    }
  };

  return (
    <ModalBody>
      {showConfirmation && bankAccount ? (
        <>
          <CardHeaderText>
            You have sucessfully linked your{" "}
            <b>
              {(bankAccount as any).bank_name} account ending in {(bankAccount as any).last4}.
            </b>
          </CardHeaderText>
          <SmallTextMutedParagraph className="deprecated-pt-3">
            By clicking <b>Save Payment Method,</b> you authorize Merge to debit the bank account
            specified above for any amount owed for charges arising from your use of Merge’s
            services and/or purchase of products from Merge, pursuant to Merge’s website and terms,
            until this authorization is revoked. You may amend or cancel this authorization at any
            time by providing notice to Merge with 30 (thirty) days notice.
          </SmallTextMutedParagraph>
          <SpinnerButton
            className="btn-primary btn-block"
            text="Save Payment Method"
            isLoading={isLoading}
            onClick={confirmBankAccount}
          />
        </>
      ) : (
        <>
          {linkFailedByExit && (
            <ErrorContainer className="form-group alert alert-warning-pale" role="alert">
              <ErrorHeaderText>Linking Error</ErrorHeaderText>
              Please try connecting your bank account again.
            </ErrorContainer>
          )}
          {linkFailedUnknownReason && (
            <ErrorContainer className="form-group alert alert-warning-pale" role="alert">
              <ErrorHeaderText>Unknown Error</ErrorHeaderText>
              Please try connecting your bank account again or contact us for support.
            </ErrorContainer>
          )}
          <SpinnerButton
            className="btn-primary btn-block"
            text="Connect Bank Account"
            isLoading={isLoading}
            onClick={openStripeConnect}
          />
        </>
      )}
    </ModalBody>
  );
}
