import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "../../../../styles/theme";
import { WarningCard } from "../../../shared-components/NoticeCards";
import ModalButton from "./ModalButton";
import type { ModalProps } from "./ModalProps";
import SkinnyModal from "./SkinnyModal";

export type Props = ModalProps & {
  /**
   * Saves changes async, passing back the user-provided name of the search
   */
  saveChanges: (searchName: string) => Promise<boolean>;

  /**
   * If we have an existing name, we're in "edit mode" instead of "create
   * mode". Functionally the same but changes the copy.
   */
  existingName?: string;
};

// Unique ID for the form control
const NAME_ID = "savedSearchModal.name";

const Error = styled(Form.Text)`
  color: ${palette.red};
`;

/**
 * Shows a modal for saving a search, or editing it, with space for a user-
 * created name.
 */
const SavedSearchModal = ({ isOpen, existingName, closeModal, saveChanges }: Props) => {
  const [isSaveEnabled, setIsSaveEnabled] = useState(true);
  const [isValidated, setIsValidated] = useState(false);
  const [values, setValues] = useState<Record<string, string>>({
    [NAME_ID]: existingName ?? "",
  });
  const [hasExternalError, setHasExternalError] = useState(false);

  const isEditVersion = existingName && existingName.length > 0;
  const title = isEditVersion ? "Edit Saved Search" : "Save Search";
  const formFieldLabel = isEditVersion ? "Name" : "What do you want to name your search?";
  const saveButtonTitle = isEditVersion ? "Save changes" : "Save";

  // Changes the text, saves, then closes the modal
  const saveAndClose = async (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    setIsValidated(true);
    event.preventDefault();
    event.stopPropagation();
    if (isSaveEnabled && form.checkValidity()) {
      setIsValidated(false);
      setIsSaveEnabled(false);
      try {
        const success = await saveChanges(values[NAME_ID]);
        setIsSaveEnabled(true);
        if (success) {
          closeModal();
        } else {
          setHasExternalError(true);
        }
      } catch {
        setIsSaveEnabled(true);
        setHasExternalError(true);
      }
    }
  };

  // When the modal opens, set the value to the latest value of existingName
  useEffect(() => {
    if (existingName && isOpen) {
      setValues((currentValues) => ({ ...currentValues, [NAME_ID]: existingName }));
    }
  }, [isOpen, existingName]);

  return (
    <SkinnyModal isOpen={isOpen} closeModal={closeModal} title={title}>
      <Form onSubmit={saveAndClose} validated={isValidated} noValidate>
        <Form.Group className="deprecated-mb-4" controlId={NAME_ID}>
          <Form.Label>{formFieldLabel}</Form.Label>
          <Form.Control
            type="text"
            placeholder="Name"
            required
            autoFocus
            value={values[NAME_ID]}
            onChange={(event) => {
              const value = event.target.value;
              setHasExternalError(false);
              return setValues((currentValues) => ({ ...currentValues, [NAME_ID]: value }));
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please choose a name for your search.
          </Form.Control.Feedback>
        </Form.Group>
        <WarningCard showsIcon={false}>
          This saved search will be visible to everyone in your organization with access to logs.
        </WarningCard>
        {hasExternalError && (
          <Error className="deprecated-mt-2 deprecated-mb-3">
            Something went wrong saving your search. Please try again.
          </Error>
        )}
        <ModalButton variant="dark" type="submit" disabled={!isSaveEnabled} className="">
          {isSaveEnabled ? saveButtonTitle : "Saving..."}
        </ModalButton>
      </Form>
    </SkinnyModal>
  );
};

export default SavedSearchModal;
