import { useCallback, useEffect, useRef, useState } from "react";

import { getCursorPath } from "../../../services";
import { fetchWithAuth } from "../../../api-client/APIClient";

type UsePaginatedRequestProps = {
  rootPath: string;
  paramsPath?: string;
};

/**
 * Hook for handling paginated request results. Loads initial value and provideds
 * next and previous values
 */
export function usePaginatedRequest<T>(props: UsePaginatedRequestProps) {
  const { rootPath, paramsPath = "" } = props;

  // hooks
  const [previousURL, setPreviousURL] = useState<string>();
  const [nextURL, setNextURL] = useState<string>();
  const [results, setResults] = useState<T[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasInitialized, setHasInitialized] = useState(false);
  const currentPath = useRef("");

  // event handlers
  const fetchWithCursor = useCallback(
    (cursor = "") => {
      setIsLoading(true);

      const cursorPath = getCursorPath(cursor) ? `${getCursorPath(cursor)}&` : "";
      const path = `${rootPath}?${cursorPath}${paramsPath}`;

      currentPath.current = path;

      fetchWithAuth({
        path,
        method: "GET",
        onResponse: (data: { next: string; previous: string; results: T[] }) => {
          if (path !== currentPath.current) return; // prevent race condition

          setNextURL(data.next);
          setPreviousURL(data.previous);
          setResults(data.results);
          setIsLoading(false);
        },
        onError: (response) => {
          console.error(response);
          setResults([]);
          setIsLoading(false);
        },
      });
    },
    [paramsPath, rootPath],
  );

  const onPrevious = () => fetchWithCursor(previousURL);
  const onNext = () => fetchWithCursor(nextURL);

  /**
   * Allows removing an item from data w/o having to refetch. Used when deleting an item.
   */
  const onRemove = (index: number) => {
    if (!results) return;
    setResults([...results.slice(0, index), ...results.slice(index + 1)]);
  };

  // effects
  // initialize
  useEffect(() => {
    if (!hasInitialized) {
      fetchWithCursor();
      setHasInitialized(true);
    }
  }, [fetchWithCursor, hasInitialized]);

  // refetch on params path change
  useEffect(() => {
    setHasInitialized(false);
  }, [paramsPath]);

  return {
    results,
    isLoading,
    onPrevious,
    onNext,
    onRemove,
    hasInitialized,
    onRefetch: fetchWithCursor,
    hasPrevious: !!previousURL,
    hasNext: !!nextURL,
  };
}
