import styled, { css } from "styled-components";

type Props = {
  $isVertical?: boolean;
  $gap?: string;
};

/**
 * Stacks contents either horizontally or vertically with flexbox
 */
const Stack = styled.div<Props>`
  display: flex;
  ${({ $isVertical }) =>
    $isVertical &&
    css`
      flex-direction: column;
    `};
  ${({ $gap }) =>
    $gap &&
    css`
      gap: ${$gap};
    `};
`;

export default Stack;
