import React from "react";
import MergeModal from "../../shared-components/MergeModal";
import { Comments } from "../../shared-components/MergeComments";
import { IntegrationIssue } from "../IntegrationsManagementEntities";

type Props = {
  integrationIssue: IntegrationIssue | undefined;
  isShown: boolean;
  setIsShown: (value: boolean) => void;
};

export const IntegrationIssueCommentModal = ({ integrationIssue, isShown, setIsShown }: Props) => {
  return (
    <MergeModal
      bodyClassName="p-0 overflow-hidden"
      title="Comments"
      show={isShown}
      onHide={() => setIsShown(false)}
    >
      {integrationIssue && <Comments integrationIssueID={integrationIssue.id} />}
    </MergeModal>
  );
};
