import { Card, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FieldMappingTarget,
  FieldMappingCommonModelConfiguration,
} from "../../../../models/Entities";
import React, { useState } from "react";
import { Globe } from "lucide-react";

import { Plus } from "react-feather";
import ConfigurationFieldMappingCardRow from "./ConfigurationFieldMappingCardRow";
import {
  addNewFieldMappingTarget,
  patchFieldMappingConfiguration,
} from "./utils/FieldMappingUtils";
import ConfigurationFieldMappingCardEditSection from "./ConfigurationFieldMappingCardEditSection";
import styled from "styled-components";
import { apiCategoryFromString } from "../../../../models/Helpers";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../router/RouterUtils";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import useAppContext from "../../../context/useAppContext";
import { StyledMultiSwitch } from "../../common-models/FieldToggle";
import { palette } from "../../../../styles/theme";
import { Link } from "react-router-dom";
import { Button } from "@merge-api/merge-javascript-shared";

const pluralize = require("pluralize");

const CONFIG_OPTIONS = [
  {
    text: "Disabled",
    id: "disabled",
    selectedColor: palette.empty,
    backgroundColor: palette.white,
    borderColor: palette.border,
  },
  {
    text: "Enabled",
    id: "enabled",
    selectedColor: palette.black,
    backgroundColor: palette.white,
    borderColor: palette.border,
  },
];

const TooltipTextV2 = styled.b`
  color: #939aa5;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

const TooltipText = styled.b`
  font-family: var(--font-family-sans-serif);
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
`;

const RemoteDataDisabledTextContainer = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
`;
export const RemoteDataDisabledText = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
`;

export const LongLine = styled.hr`
  margin-left: -24px;
  margin-right: -24px;
`;

type Props = {
  category: string;
  commonModelID: string;
  fieldMappingTargets: FieldMappingTarget[];
  commonModelsToMappingConfig?: FieldMappingCommonModelConfiguration;
  updateFieldMappingConfigurations: (
    updatedConfiguration: FieldMappingCommonModelConfiguration,
  ) => void;
  updateFieldMappingTargets: (updatedFieldMappingTarget: FieldMappingTarget) => void;
  refreshFieldMappingsAndConfigurations: () => void;
  setIsLoading: (isLoading: boolean) => void;
  setFieldMappingTargetToDelete: (customMappingTarget: FieldMappingTarget) => void;
  setHasError: (hasError: boolean) => void;
};

const ConfigurationFieldMappingCard = ({
  category,
  commonModelID,
  fieldMappingTargets,
  commonModelsToMappingConfig,
  updateFieldMappingConfigurations,
  updateFieldMappingTargets,
  refreshFieldMappingsAndConfigurations,
  setIsLoading,
  setFieldMappingTargetToDelete,
  setHasError,
}: Props) => {
  const numFieldMappingTargets = fieldMappingTargets.length;
  const [isAddingNewFieldMappingTarget, setIsAddingNewFieldMappingTarget] = useState(false);
  const commonModelName = commonModelID.split(".")[1];
  const apiCategory = apiCategoryFromString(category);
  const { user } = useAppContext();
  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);

  const addFieldMappingTarget = (fieldKey: string, fieldDescription: string) => {
    setIsLoading(true);
    addNewFieldMappingTarget({
      body: {
        common_model_id: commonModelID,
        field_key: fieldKey,
        field_description: fieldDescription,
      },
      category: apiCategory,
      onResponse: () => {
        setIsAddingNewFieldMappingTarget(false);
        refreshFieldMappingsAndConfigurations();
      },
      onError: () => {
        setHasError(true);
        setIsAddingNewFieldMappingTarget(false);
      },
    });
  };

  const toggleCustomMappingConfigUserCreatedCustomFields = (
    config: FieldMappingCommonModelConfiguration,
    new_allow_user_created_field_mapping_targets: string,
  ) => {
    patchFieldMappingConfiguration({
      body: {
        configuration_id: config.id,
        allow_user_created_field_mapping_targets:
          new_allow_user_created_field_mapping_targets === "enabled",
      },
      category: apiCategory,
      onResponse: (response: FieldMappingCommonModelConfiguration) => {
        updateFieldMappingConfigurations(response);
      },
      onError: () => {
        setHasError(true);
      },
    });
  };

  const alphabeticalSort = (a: FieldMappingTarget, b: FieldMappingTarget) =>
    a.field_key.localeCompare(b.field_key);

  const allTargetNames: string[] = fieldMappingTargets.map((target) => target.field_key);

  return (
    <Card>
      <div className="h-2 bg-orange-20 rounded-t-lg"></div>
      <Card.Body className="py-5 pt-3">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <Globe size={16} className="text-orange-50" />
            <DeprecatedH4 className="deprecated-mb-1 ml-2">{commonModelName}</DeprecatedH4>
            {!isFieldMappingV2Enabled && (
              <span className="text-gray-50 small font-medium">
                {numFieldMappingTargets === 1
                  ? "1 custom field"
                  : `${numFieldMappingTargets} Target ${pluralize(
                      "Field",
                      numFieldMappingTargets,
                    )}`}
              </span>
            )}
          </div>
          <div>
            <Col className="d-flex align-items-center">
              {isFieldMappingV2Enabled ? (
                <TooltipTextV2 className="mr-1 mb-0 d-inline-block">
                  End user-created target fields
                </TooltipTextV2>
              ) : (
                <TooltipText className="deprecated-mr-2 mb-0 d-inline-block">
                  Allow user-created target fields
                </TooltipText>
              )}
              <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 0 }}
                overlay={
                  <Tooltip id="api-requests-include-outbound-tooltip">
                    Allow your users to create Linked Account-specific custom fields in Link
                  </Tooltip>
                }
              >
                <i
                  className={`text-right fe fe-info ${
                    isFieldMappingV2Enabled ? "text-gray-50 mr-1" : ""
                  }`}
                />
              </OverlayTrigger>
              {commonModelsToMappingConfig &&
                (isFieldMappingV2Enabled ? (
                  <span className="ml-auto field-mapping-toggle">
                    <Form.Check
                      type="switch"
                      id={`field-mapping-toggle-for` + commonModelName}
                      checked={commonModelsToMappingConfig.allow_user_created_field_mapping_targets}
                      className="small"
                      onClick={() => {
                        toggleCustomMappingConfigUserCreatedCustomFields(
                          commonModelsToMappingConfig,
                          commonModelsToMappingConfig.allow_user_created_field_mapping_targets
                            ? "disabled"
                            : "enabled",
                        );
                      }}
                    />
                  </span>
                ) : (
                  <StyledMultiSwitch
                    className="ml-4"
                    options={CONFIG_OPTIONS}
                    selectedID={
                      commonModelsToMappingConfig.allow_user_created_field_mapping_targets
                        ? "enabled"
                        : "disabled"
                    }
                    onSelectOption={(option) => {
                      toggleCustomMappingConfigUserCreatedCustomFields(
                        commonModelsToMappingConfig,
                        option.id,
                      );
                    }}
                  />
                ))}
            </Col>
          </div>
        </div>
        {fieldMappingTargets.sort(alphabeticalSort).map((customMappingTarget) => (
          <ConfigurationFieldMappingCardRow
            fieldMappingTarget={customMappingTarget}
            refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
            setFieldMappingTargetToDelete={setFieldMappingTargetToDelete}
            updateFieldMappingTargets={updateFieldMappingTargets}
            setHasError={setHasError}
            remoteDataEnabled={commonModelsToMappingConfig?.has_remote_data_enabled || false}
          />
        ))}

        <LongLine />
        {commonModelsToMappingConfig?.has_remote_data_enabled ? (
          <>
            {!isAddingNewFieldMappingTarget && (
              <Button
                color="white"
                onClick={() => setIsAddingNewFieldMappingTarget(true)}
                size="sm"
                className="mt-1"
                variant="plus"
              >
                Target Field
              </Button>
            )}
            {isAddingNewFieldMappingTarget && (
              <ConfigurationFieldMappingCardEditSection
                isCreatingNewField
                allTargetNames={allTargetNames}
                onSave={addFieldMappingTarget}
                onCancel={() => setIsAddingNewFieldMappingTarget(false)}
              />
            )}
          </>
        ) : (
          <>
            <RemoteDataDisabledTextContainer>
              <RemoteDataDisabledText>
                {isFieldMappingV2Enabled ? (
                  <>
                    <Link
                      to={
                        CONFIGURATION_COMMON_MODELS_PATH + `/${category}` + `/#${commonModelName}`
                      }
                    >
                      {" "}
                      Enable Remote Data
                    </Link>{" "}
                    to create target fields and mappings for this Common Model.
                  </>
                ) : (
                  <>
                    You must enable <a href={CONFIGURATION_COMMON_MODELS_PATH}> Remote Data</a> to
                    create target fields for this Common Model.
                  </>
                )}
              </RemoteDataDisabledText>
            </RemoteDataDisabledTextContainer>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default ConfigurationFieldMappingCard;
