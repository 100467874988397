import { ArrowLeftRight, ExternalLink } from "lucide-react";
import React, { useEffect, useState } from "react";
import { Badge, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  createLinkedAccountFieldMapping,
  fetchFieldMappingInstance,
  editFieldMappingInstance,
  NewFieldMappingProps,
  EditFieldMappingInstanceProps,
} from "../../../../../api-client/APIClient";
import { FieldMappingSource } from "../../../../../constants";
import {
  FieldMappingCreationAndEditDict,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingOptions,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../models/Entities";
import { navigateToLinkedAccountFieldMappings } from "../../../../../router/RouterUtils";
import EmptyStateWrapper from "../../../../shared-components/EmptyStateWrapper";
import MergeText, { CodeText } from "../../../../shared-components/MergeText";
import MergeTypeahead from "../../../../shared-components/MergeTypeahead";
import { WarningCard } from "../../../../shared-components/NoticeCards";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import {
  LINKED_ACCOUNTS_PATH,
  getLinkedAccountDetailPathForId,
  getLinkedAccountDetailFieldMappingsPathForId,
} from "../../../../../router/RouterUtils";
import { FieldMappingTargetCardElementWithoutTag } from "./FieldMappingTargetCard_old";

type Props = {
  linkedAccount: LinkedAccount;
  fieldMappingMetaResponse?: FieldMappingMetaResponse;
  fieldMappingTargets?: FieldMappingTarget[];
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreateFieldMappingContainer = styled(FlexColumn)`
  width: 100%;
`;

const IconContainer = styled.div`
  justify-self: center;
  padding-top: 56px;
`;

const CreateFieldMappingHeader = styled.div`
  font-weight: 600;
  font-size: 28px;
  line-height: 36px;
  /* identical to box height, or 129% */

  display: flex;
  align-items: center;

  color: #121314;
`;

const FieldMappingInfoCard = styled(FlexColumn)`
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 8px 8px 0px 0px;
  padding: 16px 24px;
  margin-top: 16px;
  margin-bottom: 16px;
  justify-content: space-between;
  min-height: 74px;
`;

const CreateFieldMappingSubtext = styled.div`
  line-height: 24px;
  white-space: pre-wrap;
  margin-top: 12px;

  display: flex;
  align-items: center;

  color: #697387;
`;

const NewFieldMappingContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr 5fr;
  margin-top: 36px;
  white-space: pre-wrap;
`;

export const SaveButtonContainer = styled.div`
  display: flex;
  float: right;
  margin-top: 220px;
`;

export const CancelButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;

  width: 120px;
  height: 40px;

  background: #ffffff;

  border: 1px solid #dce2ea;
  box-shadow: 0px 1px 2px rgba(31, 45, 61, 0.05);
  border-radius: 6px;
`;

export const SaveButton = styled(Button)<{
  disabled: boolean;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 4px;
  color: white;

  width: 120px;
  height: 40px;

  background: #121314;

  box-shadow: 0px 1px 2px rgba(31, 45, 61, 0.05);
  border-radius: 6px;

  ${({ disabled }) =>
    disabled &&
    css`
      ::after {
        display: none;
      }

      &:hover,
      &:active,
      &:focus {
        cursor: default;
        color: white;
      }
    `};
`;

const FieldNameTooltipWithoutMaxWidth = styled(Tooltip)`
  & {
    .tooltip-inner {
      max-width: 500px;
    }
  }
`;

const APIDocsLinkButton = styled(Button)`
  display: flex;
  background: var(--blue0);
  align-items: center;
  justify-content: center;
  flex-grow: 0;
`;

const APIDocsLinkBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6 16px;
`;

const APIDocsLinkText = styled.b`
  color: var(--blue40);
`;

const NavigationContainer = styled.div`
  margin-bottom: 60px;
`;

const GreyText = styled.div`
  color: #8793ab;
  font-weight: 500;
  font-size: 14px;
`;

const FieldMappingContainerHeader = styled.div`
  margin-bottom: 16px;
`;

export const createFieldMappingOptions = (
  fieldMappingOptions: FieldMappingOptions,
  commonModel: any,
) => {
  const options: FieldMappingCreationAndEditDict[] = [];
  if (Object.keys(fieldMappingOptions).includes(commonModel) && fieldMappingOptions[commonModel]) {
    Object.keys(fieldMappingOptions[commonModel])
      .sort()
      .forEach((apiEndpointId) => {
        Object.keys(fieldMappingOptions[commonModel][apiEndpointId]).forEach((traversalPath) => {
          options.push(fieldMappingOptions[commonModel][apiEndpointId][traversalPath]);
        });
      });
  }
  return options;
};

const CreateLinkedAccountFieldMappingPage = ({
  linkedAccount,
  fieldMappingMetaResponse,
  fieldMappingTargets,
}: Props) => {
  const { commonModel } = useParams<{
    commonModel: string;
    fieldMappingTargetID: string;
  }>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const fieldMappingTargetID = params.get("fieldMappingTargetID");
  const fieldMappingInstanceID = params.get("fieldMappingInstanceID");
  const history = useHistory();
  const pluralize = require("pluralize");
  const { integration } = linkedAccount;
  const [editedFieldMappingKey, setEditedFieldMappingKey] = useState("");
  const [editedFieldMappingDescription, setEditedFieldMappingDescription] = useState("");
  const [addToOrganizationFieldMappings, setAddToOrganizationFieldMappings] =
    useState<boolean>(false);
  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fieldMappingTarget, setFieldMappingTarget] = useState<FieldMappingTarget | null>(null);
  const [fieldMappingInstance, setFieldMappingInstance] = useState<FieldMappingInstance | null>(
    null,
  );
  const fieldMappingMetaOptions = fieldMappingMetaResponse?.available_field_mappings;
  const apiDocsURL = fieldMappingMetaResponse?.api_documentation_url;

  const updateFieldMappingTarget = (
    mappingTargets: FieldMappingTarget[],
    fieldMappingTargetID: string,
  ) => {
    setFieldMappingTarget(
      mappingTargets.find((mappingTarget) => mappingTarget.id === fieldMappingTargetID) ?? null,
    );
  };

  const fetchAndSetFieldMappingInstanceInfo = () => {
    if (fieldMappingInstanceID && fieldMappingInstance == null) {
      fetchFieldMappingInstance(fieldMappingInstanceID, setFieldMappingInstance);
    }
  };
  fetchAndSetFieldMappingInstanceInfo();

  useEffect(() => {
    if (fieldMappingInstance && fieldMappingOptions) {
      const currentOption = fieldMappingOptions.find(
        (fieldMappingOption) =>
          fieldMappingOption.traversal_path.split(".").toString() ===
          fieldMappingInstance.field_traversal_path.toString(),
      );
      if (currentOption) {
        setOriginFields([currentOption]);
      }
    }
  }, [fieldMappingInstance, fieldMappingMetaOptions]);

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, commonModel));
    }
  }, [fieldMappingMetaOptions]);

  useEffect(() => {
    if (fieldMappingTargets === undefined) {
      setIsLoading(true);
    } else {
      if (fieldMappingTargetID) {
        updateFieldMappingTarget(fieldMappingTargets, fieldMappingTargetID);
      }
      setIsLoading(false);
    }
  }, [fieldMappingTargets, fieldMappingTargetID]);

  const CreateFieldMappingTitle = () => (
    <CreateFieldMappingContainer>
      <CreateFieldMappingHeader>
        Map Target Fields on {pluralize(commonModel)}
      </CreateFieldMappingHeader>
      <CreateFieldMappingSubtext>
        Map a <b>{integration.name} field</b> to a new <b>{pluralize(commonModel)} target field </b>
        for this Linked Account.
      </CreateFieldMappingSubtext>
      <WarningCard showsIcon className="deprecated-mt-4">
        <div>
          <p>Remote Data must be enabled for a given Common Model to map fields for that model.</p>
          <p>
            Additionally, a complete set of available fields will not be available until at least
            one sync has fully completed.
          </p>
        </div>
      </WarningCard>
    </CreateFieldMappingContainer>
  );

  const createFieldMapping = () => {
    if (originFields.length == 1) {
      const body: NewFieldMappingProps = {
        linked_account_id: linkedAccount.id,
        common_model_id: `${linkedAccount.category}.${commonModel}`,
        field_key: fieldMappingTarget?.field_key ?? editedFieldMappingKey,
        field_traversal_path: originFields[0].traversal_path.split("."),
        create_for_organization: addToOrganizationFieldMappings,
        configured_by: FieldMappingSource.ORGANIZATION,
        ...(fieldMappingTarget?.field_description ?? editedFieldMappingDescription
          ? {
              field_description:
                fieldMappingTarget?.field_description ?? editedFieldMappingDescription,
            }
          : {}),
        ...(fieldMappingTargetID ? { field_mapping_target_id: fieldMappingTargetID } : {}),
        api_endpoint_id: originFields[0].api_endpoint_id,
        display_name: originFields[0].display_name,
        origin_type: originFields[0].type,
      };

      createLinkedAccountFieldMapping(body, () => {
        navigateToLinkedAccountFieldMappings(history, linkedAccount.id);
      });
    }
  };

  const updateCustomMappingInstance = () => {
    if (fieldMappingInstanceID && originFields.length == 1) {
      const body: EditFieldMappingInstanceProps = {
        field_traversal_path: originFields[0].traversal_path.split("."),
        field_mapping_instance_id: fieldMappingInstanceID,
        api_endpoint_id: originFields[0].api_endpoint_id,
        display_name: originFields[0].display_name,
        origin_type: originFields[0].type,
        field_description: fieldMappingInstance?.field_description ?? editedFieldMappingDescription,
      };

      editFieldMappingInstance(fieldMappingInstanceID!, body, () => {
        navigateToLinkedAccountFieldMappings(history, linkedAccount.id);
      });
    }
  };

  const MAX_FIELD_ORIGIN_PATH_LENGTH = 70;

  const backTabs = (
    <>
      <Link to={LINKED_ACCOUNTS_PATH} className="font-semibold small align-items-center">
        <span className="deprecated-mr-2">Linked Accounts</span>
        <ChevronRight className="deprecated-mr-2" size={12} />
      </Link>
      <Link
        to={getLinkedAccountDetailPathForId(linkedAccount.id)}
        className="font-semibold small align-items-center"
      >
        <span className="deprecated-mr-2">Linked Account</span>
        <ChevronRight className="deprecated-mr-2" size={12} />
      </Link>
      <Link
        to={getLinkedAccountDetailFieldMappingsPathForId(linkedAccount.id)}
        className="font-semibold small align-items-center"
      >
        <span className="deprecated-mr-2">Field Mapping</span>
        <ChevronRight className="deprecated-mr-2" size={12} />
      </Link>
      <GreyText>Map Target Field on {pluralize(commonModel)}</GreyText>
    </>
  );
  return (
    <>
      <div>
        <NavigationContainer className="d-flex">{backTabs}</NavigationContainer>
        <CreateFieldMappingTitle />
        {isLoading ? (
          <EmptyStateWrapper isSpinner />
        ) : (
          <NewFieldMappingContainer>
            <FlexColumn>
              {fieldMappingInstanceID ? (
                <FieldMappingContainerHeader>
                  Update the origin field of your existing <b>{commonModel} target field</b>:
                </FieldMappingContainerHeader>
              ) : (
                <FieldMappingContainerHeader>
                  Add a new <b>{commonModel} target field</b> to map to:
                </FieldMappingContainerHeader>
              )}
              {fieldMappingTargetID || fieldMappingInstanceID ? (
                <FieldMappingTargetCardElementWithoutTag
                  fieldKey={fieldMappingTarget?.field_key || fieldMappingInstance?.field_key || ""}
                  fieldDescription={
                    fieldMappingTarget?.field_description ||
                    fieldMappingInstance?.field_description ||
                    ""
                  }
                />
              ) : (
                <FieldMappingInfoCard>
                  <div className="d-flex justify-content-between">
                    <MergeText isBold>Field Name</MergeText>
                    <div className="configuration-fieldmappings-editfieldmapping-header-text">
                      You cannot edit this later
                    </div>
                  </div>
                  <Form.Control
                    name="target"
                    type="text"
                    placeholder="Name"
                    defaultValue={
                      fieldMappingTarget?.field_key ??
                      fieldMappingInstance?.field_key ??
                      editedFieldMappingKey
                    }
                    onChange={(e) => setEditedFieldMappingKey(e.target.value)}
                    disabled={
                      fieldMappingTarget?.field_key != null ||
                      fieldMappingInstance?.field_key != null
                    }
                  />
                  <div className="d-flex justify-content-between deprecated-mt-4">
                    <MergeText isBold>Description</MergeText>
                    <div className="configuration-fieldmappings-editfieldmapping-header-text">
                      Optional
                    </div>
                  </div>
                  <Form.Control
                    name="target"
                    type="text"
                    placeholder="Description"
                    defaultValue={
                      fieldMappingTarget?.field_description ??
                      fieldMappingInstance?.field_description ??
                      editedFieldMappingDescription
                    }
                    onChange={(e) => setEditedFieldMappingDescription(e.target.value)}
                    disabled={
                      fieldMappingTarget?.field_description != null ||
                      fieldMappingInstance?.field_description != null
                    }
                  />
                  <div className="deprecated-mt-4" />
                  <Form.Check
                    type="checkbox"
                    id="field-mapping-deletion-checkbox"
                    checked={addToOrganizationFieldMappings}
                    onChange={() =>
                      setAddToOrganizationFieldMappings(!addToOrganizationFieldMappings)
                    }
                    label={
                      <>
                        Add to my organization's <a>target fields</a>
                      </>
                    }
                  />
                </FieldMappingInfoCard>
              )}
            </FlexColumn>
            <IconContainer>
              <ArrowLeftRight size={18} />
            </IconContainer>
            <FlexColumn>
              <div className="deprecated-mb-4">
                Select the corresponding <b>{integration.name} field</b>:
              </div>
              <MergeTypeahead
                id="typeahead"
                disabled={fieldMappingMetaOptions === undefined || fieldMappingOptions.length === 0}
                multiple={false}
                //@ts-ignore
                selected={originFields}
                options={fieldMappingOptions}
                labelKey={(option: FieldMappingCreationAndEditDict) => option?.display_name ?? ""}
                inputProps={{ autoComplete: "none" }}
                isLoading={fieldMappingMetaOptions === undefined}
                placeholder={
                  fieldMappingMetaOptions === undefined
                    ? `Loading ${linkedAccount.integration.name} field info...`
                    : fieldMappingOptions.length > 0
                    ? "Search Fields..."
                    : "No fields found."
                }
                onChange={(selectedFieldMappings) => {
                  setOriginFields(selectedFieldMappings);
                }}
                isInvalid={false}
                error=""
                includeChevronDown={
                  fieldMappingMetaOptions !== undefined && fieldMappingOptions.length > 0
                }
                renderMenuItemChildren={(fieldMapping) => {
                  return (
                    <div className="d-flex justify-content-between align-items-center dropdown-menu-options">
                      {fieldMapping.display_name.length > MAX_FIELD_ORIGIN_PATH_LENGTH ? (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 0, hide: 0 }}
                          overlay={
                            <FieldNameTooltipWithoutMaxWidth id="field-mapping-display-name">
                              {fieldMapping.display_name}
                            </FieldNameTooltipWithoutMaxWidth>
                          }
                        >
                          <CodeText>
                            {fieldMapping.display_name.slice(0, MAX_FIELD_ORIGIN_PATH_LENGTH / 2) +
                              "..." +
                              fieldMapping.display_name.slice(-(MAX_FIELD_ORIGIN_PATH_LENGTH / 2))}
                          </CodeText>
                        </OverlayTrigger>
                      ) : (
                        <CodeText className="dropdown-menu-options">
                          {fieldMapping.display_name}
                        </CodeText>
                      )}
                      <Badge>{fieldMapping.type}</Badge>
                    </div>
                  );
                }}
              />
              {apiDocsURL && (
                <div className="deprecated-mt-4">
                  <APIDocsLinkButton
                    variant="link"
                    onClick={() => window.open(apiDocsURL, "_blank")}
                  >
                    <APIDocsLinkBody>
                      <APIDocsLinkText>{integration.name} API Docs</APIDocsLinkText>
                      <ExternalLink color="var(--blue40)" size="18px"></ExternalLink>
                    </APIDocsLinkBody>
                  </APIDocsLinkButton>
                </div>
              )}
            </FlexColumn>
          </NewFieldMappingContainer>
        )}
      </div>
      <SaveButtonContainer>
        <CancelButton
          size="sm"
          variant="white"
          className="individual-issue-mute-button-align deprecated-mr-3"
          onClick={() => {
            history.goBack();
          }}
        >
          Cancel
        </CancelButton>
        <SaveButton
          size="sm"
          variant="black"
          disabled={
            originFields.length !== 1 ||
            (!fieldMappingTarget && !fieldMappingInstance && editedFieldMappingKey === "")
          }
          className="individual-issue-mute-button-align"
          onClick={() => {
            if (fieldMappingInstanceID) {
              // if we are creating a linked account override for an integration wide field mapping we should be creating a new linked account field mapping instance
              if (fieldMappingInstance?.is_integration_wide) {
                createFieldMapping();
              } else {
                updateCustomMappingInstance();
              }
            } else {
              createFieldMapping();
            }
          }}
        >
          Save
        </SaveButton>
      </SaveButtonContainer>
    </>
  );
};

export default CreateLinkedAccountFieldMappingPage;
