import React, { useEffect } from "react";
import { Card, Row, Col, ListGroup } from "react-bootstrap";

import useAppContext from "../../context/useAppContext";
import { User } from "../../../models/Entities";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import OrganizationTeamMember from "./OrganizationTeamMember";
import PaginationFooter from "../../shared-components/PaginationFooter";
import { usePaginatedRequest } from "../../shared-components/hooks/usePaginatedRequest";
import TeamMembersSkeleton from "./TeamMembersSkeleton";

function OrganizationTeamMembers() {
  const { user: meUser, isUserPrivileged: isMeUserPrivileged } = useAppContext();

  const {
    results: users,
    onPrevious,
    onNext,
    hasPrevious,
    hasNext,
    onRefetch,
    isLoading,
  } = usePaginatedRequest<User>({
    rootPath: "/organizations/users",
  });

  useEffect(() => {
    onRefetch();
  }, [meUser, onRefetch]);

  return (
    <>
      <Card>
        <Card.Body>
          <SectionHeaderWrapper title="Members" titleHeading={TitleHeading.H2} />
        </Card.Body>
        <Row className="align-items-left deprecated-px-4 pb-n2 deprecated-mt-4">
          <Col className="col-5">
            <DeprecatedH4>Name</DeprecatedH4>
          </Col>
          <Col className="col-3">
            <DeprecatedH4>Role</DeprecatedH4>
          </Col>
          {isMeUserPrivileged && (
            <Col className="col-3">
              <DeprecatedH4>Two-factor authentication</DeprecatedH4>
            </Col>
          )}
          <Col className="col-1"></Col>
        </Row>
        <hr className="my-0" />
        <ListGroup variant="flush">
          {users && !isLoading ? (
            <>
              {users.map((user) => (
                <OrganizationTeamMember
                  key={user.id}
                  user={user}
                  onRefetchTeamMembers={onRefetch}
                />
              ))}
            </>
          ) : (
            <TeamMembersSkeleton />
          )}
        </ListGroup>
      </Card>

      <PaginationFooter
        hasPrevious={hasPrevious}
        hasNext={hasNext}
        onNextClick={onNext}
        onPreviousClick={onPrevious}
      />
    </>
  );
}

export default OrganizationTeamMembers;
