import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import { useMergeLink } from "@mergeapi/react-merge-link";
import React, { useState } from "react";
import { ReactComponent as MergeLink } from "../../../../../src/components/get-started/src/merge-link.svg";
import styled from "styled-components";

const StyledMergeLink = styled(MergeLink)`
  height: 12px;
  width: 12px;
`;

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  tenantConfig: {} | { tenantConfig: { apiBaseURL: string } };
  onNewLinkedAccount: () => void;
}

const ProdMergeLinkActionButton = ({ linkToken, tenantConfig, onNewLinkedAccount }: Props) => {
  const [wasSuccess, setWasSuccess] = useState(false);

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => setWasSuccess(true),
    onExit: () => wasSuccess && onNewLinkedAccount(),
    ...tenantConfig,
  });

  return (
    <SmallWhiteButton
      onClick={() => {
        setWasSuccess(false);
        open();
      }}
      disabled={!isReady}
      leftIcon={<StyledMergeLink />}
    >
      Create Test Linked Account
    </SmallWhiteButton>
  );
};

export default ProdMergeLinkActionButton;
