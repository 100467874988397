import React from "react";
import styled from "styled-components";

type EventDescriptionCellProps = {
  eventDescription: string;
};

const StyledTD = styled.td`
  min-width: 300px;
  max-width: 350px;
  overflow: visible;
  white-space: wrap !important;
  text-overflow: ellipsis;
`;

const EventDescriptionCell = ({ eventDescription }: EventDescriptionCellProps) => {
  return <StyledTD className="text-base">{eventDescription}</StyledTD>;
};

export default EventDescriptionCell;
