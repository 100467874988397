import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  children: JSX.Element;
}

const StyledPopover = styled(Popover)`
  background-color: #12263f;
  min-width: 22rem;
  color: white;
  text-align: center;

  .arrow::before {
    border-top-color: #12263f;
    right: 4px !important;
  }

  .arrow::after {
    border-top-color: #12263f;
    right: 4px !important;
  }
`;

const LaunchTooltipIcon = styled.i`
  padding-left: 5px;
  padding-bottom: 1rem;
  padding-right: 12px;
`;

const PricingPlanTitlePopover = ({ children }: Props) => {
  const [showPopover, setShowPopover] = useState(false);
  const handleOnMouseEnterPopover = () => {
    setShowPopover(true);
  };
  const handleOnMouseLeavePopover = () => {
    setShowPopover(false);
  };

  return (
    <OverlayTrigger
      show={showPopover}
      placement="top"
      delay={{ show: 100, hide: 0 }}
      overlay={
        <StyledPopover
          id="no-keys-in-codebase-tooltip"
          onMouseEnter={handleOnMouseEnterPopover}
          onMouseLeave={handleOnMouseLeavePopover}
        >
          {children}
        </StyledPopover>
      }
    >
      <LaunchTooltipIcon
        className="fe fe-info text-gray-50"
        onMouseEnter={handleOnMouseEnterPopover}
        onMouseLeave={handleOnMouseLeavePopover}
      />
    </OverlayTrigger>
  );
};

export default PricingPlanTitlePopover;
