import React from "react";
import { Contact, DollarSign, Ticket, UserPlus, Users, Folder } from "lucide-react";
import { APICategory, BlueprintSpeed, Integration, User, UserType } from "./Entities";
import { ReactComponent as MarketingAutomationIcon } from "../assets/svg/marketing-automation-feather.svg";
import moment from "moment-timezone";

// This is the source of truth for what is active.
// Changing this list changes what appears in the dashboard.
// It triggers updates in Get Started, Configuration (multiple tabs), and code snippets.
export const INTEGRATION_CATEGORY_LIST = [
  APICategory.hris,
  APICategory.ats,
  APICategory.accounting,
  APICategory.ticketing,
  APICategory.crm,
  APICategory.mktg,
  APICategory.filestorage,
];

export const displayNameForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "Marketing Automation";
    case APICategory.filestorage:
      return "File Storage";
  }
};

export const fullDisplayNameForAPICategory = (category: APICategory): string => {
  switch (category) {
    case APICategory.ats:
      return "Applicant Tracking Systems (ATS)";
    case APICategory.hris:
      return "HR and Payroll (HRIS)";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Project Management and Ticketing";
    case APICategory.crm:
      return "Customer Relationship Management (CRM)";
    case APICategory.mktg:
      return "Marketing Automation (MKTG)";
    case APICategory.filestorage:
      return "File Storage";
  }
};

export const lucideIconForAPICategory = (
  category: APICategory,
  size: "sm" | "md" | "lg" = "md",
  className = "",
): JSX.Element => {
  let sizeNumber = 16;
  switch (size) {
    case "sm":
      sizeNumber = 12;
      break;
    case "md":
      sizeNumber = 16;
      break;
    case "lg":
      sizeNumber = 24;
      break;
  }

  switch (category) {
    case APICategory.hris:
      return <Users size={sizeNumber} className={className} />;
    case APICategory.ats:
      return <UserPlus size={sizeNumber} className={className} />;
    case APICategory.accounting:
      return <DollarSign size={sizeNumber} className={className} />;
    case APICategory.ticketing:
      return <Ticket size={sizeNumber} className={className} />;
    case APICategory.crm:
      return <Contact size={sizeNumber} className={className} />;
    case APICategory.mktg:
      return (
        <MarketingAutomationIcon
          style={{
            minWidth: 16,
          }}
          height={16}
          width={16}
          className={className}
        />
      );
    case APICategory.filestorage:
      return <Folder size={sizeNumber} className={className} />;
  }
};

export const apiCategoryFromString = (categoryName: string): APICategory => {
  switch (categoryName) {
    case APICategory.hris:
      return APICategory.hris;
    case APICategory.ats:
      return APICategory.ats;
    case APICategory.accounting:
      return APICategory.accounting;
    case APICategory.ticketing:
      return APICategory.ticketing;
    case APICategory.crm:
      return APICategory.crm;
    case APICategory.mktg:
      return APICategory.mktg;
    case APICategory.filestorage:
      return APICategory.filestorage;
    default:
      return APICategory.hris;
  }
};

export const apiCategoryFromStringFallbackToProvidedString = (categoryName: string): string => {
  switch (categoryName) {
    case APICategory.hris:
    case APICategory.ats:
    case APICategory.crm:
    case APICategory.accounting:
    case APICategory.ticketing:
    case APICategory.mktg:
    case APICategory.filestorage:
      return displayNameForAPICategory(apiCategoryFromString(categoryName));
    default:
      return categoryName;
  }
};

export const displayNameForBlueprintSpeed = (blueprintSpeed: BlueprintSpeed): string => {
  switch (blueprintSpeed) {
    case BlueprintSpeed.FAST:
      return "Highest";
    case BlueprintSpeed.INTERMEDIATE:
      return "High";
    case BlueprintSpeed.SLOW:
      return "Standard";
    case BlueprintSpeed.CUSTOM:
      return "Custom";
  }
};

export function formatDate(
  dateString: string | Date,
  formatString = "MMM DD, YYYY hh:mm:ss A",
  includeTimezone = true,
) {
  const userTimezone = moment.tz.guess();
  const convertedDate = moment.utc(dateString).tz(userTimezone);

  const timezoneStr = moment.tz(userTimezone).zoneAbbr();

  const formattedDate = convertedDate.format(formatString);

  return `${formattedDate}${includeTimezone ? " " + timezoneStr : ""}`;
}

export function getAPIRequestPath(endpoint: string, integration: Integration) {
  const path = endpoint.substring(integration.base_api_url.length, endpoint.length);
  return path;
}

export const CATEGORIES_SUPPORTING_CUSTOM_FIELDS = [APICategory.hris];

export const doesUserHavePrivilege = (user: User) =>
  [UserType.admin_with_billing, UserType.admin_without_billing].includes(user.type);

export const displayNameForUserType = (userType: UserType): string => {
  switch (userType) {
    case UserType.member:
      return "Member";
    case UserType.admin_without_billing:
      return "Developer";
    case UserType.admin_with_billing:
      return "Admin";
  }
};

export default INTEGRATION_CATEGORY_LIST;
