import React, { useState, Dispatch, SetStateAction } from "react";
import {
  LinkedAccountFieldMappingContainer,
  FieldMappingTargetContainer,
  IconContainer,
} from "../FieldMappingInstanceCard";
import { Link, useHistory } from "react-router-dom";
import { AddNewFieldMappingContainer, AddNewFieldMappingText } from "../FieldMappingTargetCard";
import { Dropdown } from "react-bootstrap";
import { ArrowLeft, Box, Globe, User, Plus } from "lucide-react";
import { EllipsesToggle } from "../../../../../shared-components/MergeToggles";
import styled from "styled-components";
import { showErrorToast, showSuccessToast } from "../../../../../shared-components/Toasts";
import {
  deleteOverriddenCommonModelInstance,
  editOverriddenCommoModelInstance,
  createOverriddenCommonModelInstance,
} from "../../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import useLinkedAccountCustomMappingsContext from "../context/useLinkedAccountFieldMappingsContext";
import {
  OverriddenCommonModelInstance,
  OverriddenCommonModelTarget,
  FieldDefinition,
  FieldMappingMetaResponse,
  FieldMappingCreationAndEditDict,
} from "../../../../../../models/Entities";
import MergeTypeahead from "../../../../../shared-components/MergeTypeahead";
import { filterFieldMappingOptions } from "./CommonModelOverrideForm";
import FieldMappingDropdownItem from "../FieldMappingDropdownItem";
import { Loader } from "react-bootstrap-typeahead";
import { navigateToIndividualCommonModelOverrideTargetPage } from "../../../../../../router/RouterUtils";
import { SmallWhiteButton, capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
const MethodText = styled.div`
  font-size: 10px;
`;
const OverflowText = styled.div`
  overflow-wrap: anywhere;
`;

type CommonModelOverridInstanceProps = {
  linkedAccountID: string;
  modelOverrideID: string;
  setCommonModelOverrides: Dispatch<SetStateAction<OverriddenCommonModelInstance[]>>;
  setCommonModelOverrideTargets: Dispatch<
    SetStateAction<(OverriddenCommonModelTarget | OverriddenCommonModelInstance)[]>
  >;
  overridden_field: string;
  overridden_field_definition: FieldDefinition | null;
  common_model_name: string;
  targetID: string | null;
  field_traversal_path: string[] | null;
  remote_endpoint_path: string | null;
  is_integration_wide: boolean | null;
  is_integration_wide_override_mapping: boolean | null;
  display_name: string | null;
  origin_type: string | null;
  integration_name: string;
  customMappingMetaResponse: FieldMappingMetaResponse | undefined;
};
const CommonModelOverrideInstanceTargetCard = ({
  linkedAccountID,
  modelOverrideID,
  targetID,
  setCommonModelOverrides,
  setCommonModelOverrideTargets,
  field_traversal_path,
  common_model_name,
  overridden_field,
  overridden_field_definition,
  remote_endpoint_path,
  is_integration_wide,
  is_integration_wide_override_mapping,
  display_name,
  origin_type,
  integration_name,
  customMappingMetaResponse,
}: CommonModelOverridInstanceProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [edittedOverride, setEdittedOverride] = useState<
    FieldMappingCreationAndEditDict | undefined
  >(undefined);
  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();
  const history = useHistory();

  const availableFieldMappings = customMappingMetaResponse?.available_field_mappings;

  const onDeleteSuccess = () => {
    refreshCustomMappings();
    showSuccessToast("Sucessfully Deleted Common Model override");
  };

  const editFieldMapping = (selectedOverride: FieldMappingCreationAndEditDict) => {
    const patchBody = {
      api_endpoint_id: selectedOverride.api_endpoint_id,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      display_name: selectedOverride?.display_name,
    };

    const onEditSuccess = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverrides((prevOverrides) => {
        const indexToUpdate = prevOverrides.findIndex((override) => {
          return override.id == data.id;
        });

        prevOverrides[indexToUpdate] = data;
        return [...prevOverrides];
      });
      setIsEditing(false);
      showSuccessToast("Updated Common Model override!");
    };

    editOverriddenCommoModelInstance(modelOverrideID, patchBody, onEditSuccess, () => {
      showErrorToast("Unable to update Common Model override");
    });
  };

  const updateFieldMappingTargetWithInstance = (
    selectedOverride: FieldMappingCreationAndEditDict,
  ) => {
    const postBody = {
      linked_account_id: linkedAccountID,
      override_common_model_target_id: modelOverrideID,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      api_endpoint_id: selectedOverride.api_endpoint_id,
      display_name: selectedOverride?.display_name,
      origin_type: selectedOverride.type,
    };
    const onSuccessSave = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverrideTargets((prevOverrideTargets) => {
        return prevOverrideTargets.map((overriddeTarget) => {
          return overriddeTarget.id == modelOverrideID ? data : overriddeTarget;
        });
      });
      setIsEditing(false);
      showSuccessToast("Created Linked Account override !");
    };

    const onError = () => {
      showErrorToast("Unable To update Common Model override target");
      setIsEditing(false);
    };
    createOverriddenCommonModelInstance(postBody, onSuccessSave, onError);
  };

  const createLinkedAccountOverride = (selectedOverride: FieldMappingCreationAndEditDict) => {
    const postBody = {
      linked_account_id: linkedAccountID,
      override_common_model_target_id: targetID!,
      field_traversal_path: selectedOverride.traversal_path.split("."),
      api_endpoint_id: selectedOverride.api_endpoint_id,
      display_name: selectedOverride?.display_name,
      origin_type: selectedOverride?.type,
    };
    const onSuccessSave = (data: OverriddenCommonModelInstance) => {
      setCommonModelOverrides((prevOverrideTargets) => {
        return prevOverrideTargets.map((overriddeTarget) => {
          return overriddeTarget?.override_common_model_target?.id == targetID
            ? data
            : overriddeTarget;
        });
      });
      setIsEditing(false);
      showSuccessToast("Created Linked Account override !");
    };

    const onError = () => {
      showErrorToast("Unable To update Common Model override target");
      setIsEditing(false);
    };
    createOverriddenCommonModelInstance(postBody, onSuccessSave, onError);
  };
  return (
    <LinkedAccountFieldMappingContainer className="mb-6 pl-6">
      <FieldMappingTargetContainer className="d-flex flex-column">
        <div className="p-5 pt-4 pb-4">
          <div className="flex items-center flex-wrap">
            <div className="font-semibold font-mono text-break white-space-wrap ">
              <span>{overridden_field}</span>
            </div>
            <div
              className="ml-3 flex-1 text-sm text-gray-50
            "
            >
              {capitalizeFirstLetter(overridden_field_definition?.type || "")}
            </div>
            <div className="font-mono text-blue-40 font-medium text-sm truncate ml-1">
              {common_model_name}
            </div>
          </div>
          <div className="mt-2 text-gray-50 text-sm leading-4.5 text-ellipsis overflow-hidden overflow-wrap-anywhere">
            {overridden_field_definition?.description}
          </div>
        </div>
        <div className="bg-blue-0 text-blue-40 p-5 pt-2.5 pb-2.5 text-[10px] leading-[14px] flex align-items-center rounded-b-lg mt-auto">
          <Box size={12} className="mr-2.5" />
          Common Model field
          {(is_integration_wide ||
            is_integration_wide_override_mapping ||
            !remote_endpoint_path) && (
            <button
              className="bg-transparent ml-auto text-blue-40 pt-0 pb-0"
              onClick={() => {
                targetID = targetID ? targetID : modelOverrideID;
                navigateToIndividualCommonModelOverrideTargetPage(history, targetID);
              }}
            >
              <span className="text-[10px] font-normal">View</span>
            </button>
          )}
        </div>
      </FieldMappingTargetContainer>
      <IconContainer>
        <ArrowLeft size={20} />
      </IconContainer>
      {remote_endpoint_path ? (
        <FieldMappingTargetContainer className="d-flex flex-column">
          {isEditing ? (
            <div className="p-5 pt-4 pb-4">
              <MergeTypeahead
                multiple={false}
                options={
                  availableFieldMappings
                    ? filterFieldMappingOptions(
                        common_model_name,
                        availableFieldMappings,
                        overridden_field_definition?.type || null,
                      )
                    : []
                }
                onChange={(selectedOverride) => {
                  if (selectedOverride.length > 0) {
                    setEdittedOverride(selectedOverride[0]);
                  } else {
                    setEdittedOverride(undefined);
                  }
                }}
                placeholder={"Select override"}
                labelKey={(option) => {
                  return option?.display_name ?? "";
                }}
                renderMenuItemChildren={(fieldMapping) => {
                  return <FieldMappingDropdownItem fieldMapping={fieldMapping} />;
                }}
              />
              <div className="flex items-center justify-content-end mt-3">
                <div
                  className="mr-2 font-semibold text-sm hover:cursor-pointer"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </div>
                <SmallWhiteButton
                  className={`text-sm shadow-sm p-3 pt-1 pb-1 ml-2 rounded font-semibold ${
                    edittedOverride
                      ? "cursor-pointer"
                      : "cursor-not-allowed opacity-50 pointer-events-none"
                  }`}
                  onClick={() => {
                    if (edittedOverride) {
                      if (!is_integration_wide) {
                        editFieldMapping(edittedOverride);
                      } else {
                        createLinkedAccountOverride(edittedOverride);
                      }
                    }
                  }}
                >
                  Save
                </SmallWhiteButton>
              </div>
            </div>
          ) : (
            <div className="p-5 pt-4 pb-4">
              <div className="flex items-center">
                <OverflowText className={`font-semibold font-mono`}>
                  <span>
                    {display_name
                      ? display_name
                      : field_traversal_path &&
                        field_traversal_path.length > 0 &&
                        field_traversal_path[field_traversal_path.length - 1]}
                  </span>
                </OverflowText>
                <div
                  className="ml-3 flex-1 text-sm text-gray-50
            "
                >
                  {capitalizeFirstLetter(origin_type || "String")}
                </div>
              </div>
              <div className="mt-2 d-flex text-gray-50 whitespace-normal leading-4.5">
                <MethodText className="font-semibold text-caption">GET</MethodText>
                <OverflowText className="ml-1.5 text-sm ">{remote_endpoint_path}</OverflowText>
              </div>
            </div>
          )}
          {is_integration_wide ? (
            <div className=" basis-0 bg-orange-0 text-orange-50 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg mt-auto leading-[14px]">
              <Globe size={12} className="mr-2.5" />
              {`Organization-wide ${integration_name}  mapping`}
            </div>
          ) : (
            <div className=" basis-0 bg-yellow-0 text-yellow-50 p-5 pt-2.5 pb-2.5 text-[10px] flex align-items-center rounded-b-lg mt-auto leading-[14px]">
              <User size={12} className="mr-2.5" />
              Linked Account-specific mapping
            </div>
          )}
        </FieldMappingTargetContainer>
      ) : (
        <div>
          {/* this is integration wide override common model logic */}
          {customMappingMetaResponse == undefined ? (
            <AddNewFieldMappingContainer className="w-100 h-100" disabled={true} loading={true}>
              <Loader bsSize="small"></Loader>
              <div className="ml-2">Loading origin fields</div>
            </AddNewFieldMappingContainer>
          ) : isEditing ? (
            <FieldMappingTargetContainer>
              <div className="p-5 pt-4 pb-4">
                <MergeTypeahead
                  multiple={false}
                  options={
                    availableFieldMappings
                      ? filterFieldMappingOptions(
                          common_model_name,
                          availableFieldMappings,
                          overridden_field_definition?.type || null,
                        )
                      : []
                  }
                  onChange={(selectedOverride) => {
                    if (selectedOverride.length > 0) {
                      setEdittedOverride(selectedOverride[0]);
                    } else {
                      setEdittedOverride(undefined);
                    }
                  }}
                  placeholder={"Select override"}
                  labelKey={(option) => {
                    return option?.display_name ?? "";
                  }}
                  renderMenuItemChildren={(fieldMapping) => {
                    return <FieldMappingDropdownItem fieldMapping={fieldMapping} />;
                  }}
                />
                <div className="flex items-center justify-content-end mt-3">
                  <div
                    className="mr-2 font-semibold text-sm hover:cursor-pointer"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </div>
                  <SmallWhiteButton
                    disabled={edittedOverride ? false : true}
                    onClick={() => {
                      if (edittedOverride) {
                        updateFieldMappingTargetWithInstance(edittedOverride);
                      }
                    }}
                  >
                    Save
                  </SmallWhiteButton>
                </div>
              </div>
              <div className=" basis-0 bg-yellow-0 text-yellow-50 p-5 pt-2.5 pb-2.5 text-sm flex align-items-center rounded-b-lg mt-auto">
                <User size={12} className="mr-2.5" />
                Linked Account-specific mapping
              </div>
            </FieldMappingTargetContainer>
          ) : (
            <AddNewFieldMappingContainer
              className="w-100 h-100"
              disabled={false}
              loading={false}
              onClick={() => {
                setIsEditing(true);
              }}
            >
              <div className="d-flex flex-column flex-grow-1">
                <AddNewFieldMappingText className="d-flex align-items-center justify-content-center">
                  <Plus className="mr-[3px]" size={16} />
                  Common Model override
                </AddNewFieldMappingText>
              </div>
            </AddNewFieldMappingContainer>
          )}
        </div>
      )}
      {remote_endpoint_path ? (
        <Dropdown className="m-auto pl-1 pr-1">
          <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
            <i className="fe fe-more-vertical black" />
          </Dropdown.Toggle>
          <Dropdown.Menu align="right" className="dropdown-ellipses dropdown-toggle">
            <Dropdown.Item
              className={`ellipses-dropdown-item dropdown-item text-sm  hover:bg-gray-10 ${
                customMappingMetaResponse == undefined ? "text-gray" : "text-black"
              }`}
              onClick={() => setIsEditing(true)}
              disabled={customMappingMetaResponse == undefined}
            >
              Edit Mapping
            </Dropdown.Item>
            <Dropdown.Item
              className={`ellipses-dropdown-item dropdown-item text-red-50 text-sm hover:bg-gray-10 ${
                is_integration_wide ? "opacity-[0.35]" : ""
              }`}
              onClick={() => {
                deleteOverriddenCommonModelInstance(modelOverrideID, onDeleteSuccess, () => {
                  showErrorToast("Unable to delete Common Model override");
                });
              }}
              disabled={is_integration_wide ? true : false}
            >
              Delete Mapping
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        // space filler
        <div className="w-6"></div>
      )}
    </LinkedAccountFieldMappingContainer>
  );
};
export default CommonModelOverrideInstanceTargetCard;
