import styled from "styled-components";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import {
  APICategory,
  FieldMappingTarget,
  FieldMappingCommonModelConfiguration,
  HTTPMethod,
  FieldMappingMetaResponse,
  OverriddenCommonModelTarget,
  OverriddenCommonModelInstance,
  CreateOverriddenCommonModelInstanceDict,
  EditOverriddenCommonModelInstanceDict,
  CreateOrgWideCommonModelOverrideTargetDict,
  OverrideModelAndFieldOptions,
  OverrideCommonModelIntegrationInfo,
} from "../../../../../models/Entities";

export const getFieldMappingTargets = (props: {
  category: APICategory;
  onFetch: (response: FieldMappingTarget[]) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${props.category}`,
    method: "GET",
    onResponse: (data: FieldMappingTarget[]) => {
      props.onFetch(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getCommonModelsFromCategory = (props: {
  category: APICategory;
  onFetch: (response: FieldMappingTarget[]) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/common-model-toggles/${props.category}`,
    method: "GET",
    onResponse: (data: FieldMappingTarget[]) => {
      props.onFetch(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getFieldMappingTarget = (props: {
  fieldMappingTargetID: string;
  onFetch: (response: FieldMappingTarget) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  const { onFetch, onError, fieldMappingTargetID } = props;
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-target/${fieldMappingTargetID}`,
    method: "GET",
    onResponse: (data: FieldMappingTarget) => {
      onFetch(data);
    },
    onError: (error: Response | undefined) => {
      onError(error);
    },
  });
};

export const patchFieldMappingTarget = ({
  body,
  fieldMappingTargetID,
  onResponse,
  onError,
}: {
  body: {
    is_mappable_in_link: boolean;
    weight: number;
  };
  fieldMappingTargetID: string;
  onResponse: (updatedFieldMappingTarget: FieldMappingTarget) => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${fieldMappingTargetID}/edit`,
    method: HTTPMethod.PATCH,
    body,
    onResponse,
    onError,
  });

export const addNewFieldMappingTarget = ({
  body,
  category,
  onResponse,
  onError,
}: {
  body: {
    common_model_id?: string;
    field_key?: string;
    field_description?: string;
  };
  category: APICategory;
  onResponse: () => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${category}`,
    method: HTTPMethod.POST,
    body,
    onResponse,
    onError,
  });

export const deleteFieldMappingTarget = ({
  fieldMappingTargetID,
  onResponse,
  onError,
}: {
  fieldMappingTargetID: string;
  onResponse: () => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${fieldMappingTargetID}/edit`,
    method: HTTPMethod.DELETE,
    onResponse,
    onError,
  });

export const getFieldMappingConfigurations = (props: {
  category: APICategory;
  onFetch: (response: FieldMappingCommonModelConfiguration[]) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-configurations/${props.category}`,
    method: "GET",
    onResponse: (data: FieldMappingCommonModelConfiguration[]) => {
      props.onFetch(data);
    },
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const patchFieldMappingConfiguration = ({
  body,
  category,
  onResponse,
  onError,
}: {
  body: {
    configuration_id: string;
    allow_user_created_field_mapping_targets: boolean;
  };
  category: APICategory;
  onResponse: (updatedConfiguration: FieldMappingCommonModelConfiguration) => void;
  onError: (errorResponse: Response | undefined) => void;
}) =>
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-configurations/${category}`,
    method: HTTPMethod.PATCH,
    body,
    onResponse,
    onError,
  });

export const getFieldMappingOptions = (props: {
  linkedAccountID: string;
  onFetch: (response: FieldMappingMetaResponse) => void;
  onError: (errorResponse: Response | undefined) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/linked-account/field-mappings/${props.linkedAccountID}/meta?include_overrides_meta=True`,
    method: "GET",
    onResponse: props.onFetch,
    onError: (error: Response | undefined) => {
      props.onError(error);
    },
  });
};

export const getAvailableIntegrationsForFieldMapping = (props: {
  fieldMappingTargetID: string;
  onFetch: (response: any) => void;
  onError: (error: any) => void;
}) => {
  fetchWithAuth({
    path: `/integrations/organization/field-mapping-targets/${props.fieldMappingTargetID}/available-integrations`,
    method: "GET",
    onResponse: (data: any) => {
      props.onFetch(data);
    },
    onError: (error) => {
      props.onError(error);
    },
  });
};

export const createOverriddenCommonModelInstance = (
  body: CreateOverriddenCommonModelInstanceDict,
  onSuccess: (data: OverriddenCommonModelInstance) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `/integrations/linked-account/common-model-override-instances/create`,
    method: "POST",
    body,
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: onError,
  });
};

export const deleteOverriddenCommonModelInstance = (
  overriddenCommonModelID: string,
  onSuccess: () => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/common-model-override-instances/${overriddenCommonModelID}`,
    method: "DELETE",
    onResponse: () => {
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });
};

export const editOverriddenCommoModelInstance = (
  overriddenCommonModelID: string,
  body: EditOverriddenCommonModelInstanceDict,
  onSuccess: (data: OverriddenCommonModelInstance) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/linked-account/common-model-override-instances/${overriddenCommonModelID}`,
    body,
    method: "PATCH",
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: () => {
      onError();
    },
  });
};

// get avaialble override fields per cateogry
export const getAllOverrideModelAndFieldOptionsForCategory = (
  category: string,
  onSucess: (data: OverrideModelAndFieldOptions) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/available-common-model-overrides/${category}`,
    method: "GET",
    onResponse: (data) => {
      onSucess(data);
    },
    onError: () => {
      onError();
    },
  });
};

export const getAllOrganizationCommonModelOverrideTargetsForCategory = (
  category: string,
  onSuccess: (data: OverriddenCommonModelTarget[]) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${category}`,
    method: "GET",
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: () => {
      onError();
    },
  });
};
export const createOrgWideCommonModelOverrideTarget = (
  body: CreateOrgWideCommonModelOverrideTargetDict,
  onSuccess: (data: OverriddenCommonModelTarget) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets`,
    body,
    method: "POST",
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: () => {
      onError();
    },
  });
};

export const getCommonModelOverrideIntegrationInformation = (
  overrideCommonModelTargetId: string,
  onSuccess: (data: OverrideCommonModelIntegrationInfo[]) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${overrideCommonModelTargetId}/available-integrations`,
    method: "GET",
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: () => {
      onError();
    },
  });
};

export const getCommonModelOverrideTarget = (
  overrideCommonModelTargetId: string,
  onSuccess: (data: OverriddenCommonModelTarget) => void,
  onError: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${overrideCommonModelTargetId}`,
    method: "GET",
    onResponse: (data) => {
      onSuccess(data);
    },
    onError: () => {
      onError();
    },
  });
};

// ---------------- Legacy Methods ----------------
export const updateCustomField = ({
  body,
  category,
  method,
  onResponse,
}: {
  body: {
    custom_field_id?: string;
    common_model_id?: string;
    custom_field_key?: string;
    custom_field_description?: string;
  };
  category?: APICategory;
  onResponse: (response: { [common_model_id: string]: FieldMappingTarget[] }) => void;
  method: HTTPMethod.POST | HTTPMethod.PATCH | HTTPMethod.DELETE;
}) =>
  fetchWithAuth({
    path: `/integrations/custom-fields/${category}`,
    method,
    body,
    onResponse,
  });

export const updateCustomFieldForCommonModel = ({
  body,
  method,
  category,
  commonModelID,
  setCustomMappingTargets,
  onResponse,
}: {
  body: {
    custom_field_id?: string;
    custom_field_key?: string;
    custom_field_description?: string;
  };
  method: HTTPMethod.POST | HTTPMethod.PATCH | HTTPMethod.DELETE;
  category?: APICategory;
  commonModelID?: string;
  setCustomMappingTargets: (customMappingTargets: {
    [common_model_id: string]: FieldMappingTarget[];
  }) => void;
  onResponse: () => void;
}) =>
  updateCustomField({
    category,
    method,
    body: { ...body, common_model_id: commonModelID },
    onResponse: (customFields) => {
      setCustomMappingTargets(customFields);
      onResponse();
    },
  });

export const SectionHeaderTitleInlineDiv = styled.div`
  display: flex;
  align-items: center;
`;
export const SectionHeaderBadgeMarginedDiv = styled.div`
  margin-bottom: 8px;
`;

export const deleteCommonModelOverrideTarget = (
  overrideCommonModelTargetId: string,
  onSuccess: () => void,
  onFailure: () => void,
) => {
  fetchWithAuth({
    path: `integrations/organization/override-common-model-targets/${overrideCommonModelTargetId}`,
    method: "DELETE",
    onResponse: () => {
      onSuccess();
    },
    onError: () => {
      onFailure();
    },
  });
};
