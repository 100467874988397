import React from "react";
import { Dropdown } from "react-bootstrap";
import ClickableContainer from "../../../../shared-components/ClickableContainer";

const customToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: React.ReactNode;
      onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    },
    ref: React.Ref<HTMLSpanElement>,
  ) => (
    <ClickableContainer>
      <span
        ref={ref}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick(event);
        }}
      >
        {children}
      </span>
    </ClickableContainer>
  ),
);

type ScopesDropdownProps = {
  children: JSX.Element;
  menuOptions: JSX.Element;
};
const ScopesDropdown = ({ children, menuOptions }: ScopesDropdownProps) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={customToggle}>{children}</Dropdown.Toggle>
      <Dropdown.Menu>{menuOptions}</Dropdown.Menu>
    </Dropdown>
  );
};
export default ScopesDropdown;
