import React from "react";

import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import MaybeDisable from "../../../../shared-components/MaybeDisable";

interface RestrictedWebhookOptionWrapperProps {
  children: JSX.Element;
  asyncPassthrough?: boolean;
}

function RestrictedWebhookOptionWrapper(props: RestrictedWebhookOptionWrapperProps) {
  const { children, asyncPassthrough } = props;

  // hooks
  const { productRestrictions } = useProductRestrictions();

  // derived state
  let disable = !productRestrictions?.webhooks_enabled;
  if (asyncPassthrough) {
    disable = disable || !productRestrictions?.passthrough_requests_enabled;
  }

  return (
    <MaybeDisable
      disable={disable}
      tooltip="Adding this webhook is only available for customers on our Core, Professional, and Enterprise plans."
    >
      {children}
    </MaybeDisable>
  );
}

export default RestrictedWebhookOptionWrapper;
