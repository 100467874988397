import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette, spectrum } from "../../../styles/theme";
import Stack from "../linked-accounts/shared/Stack";

export interface Props {
  /**
   * The smaller, gray text above the datum
   */
  label: string;

  /**
   * A string to show when the label is hovered
   */
  labelHoverText?: string;

  /**
   * A feather icon element to put next to the label if specified
   */
  labelIcon?: JSX.Element;

  /**
   * A piece of text to show as the main datum for this pair - may also
   * be a custom element.
   */
  data: string | JSX.Element;

  /**
   * On occasion, we want to show some other piece of data next to the data. Provide
   * an element for that here.
   */
  accessoryElement?: JSX.Element;

  /**
   * Indicates whether it's a section header or not
   */
  isHeader?: boolean;
}

interface ComponentProps {
  /**
   * Indicates whether it's a section header or not
   */
  $isHeader?: boolean;
}

// Cross browser way to truncate to one line
const ONE_LINE_TRUNCATION = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Make sure stack doesn't overflow
 */
const OneLineStack = styled(Stack)<ComponentProps>`
  ${ONE_LINE_TRUNCATION};
  border-radius: 8px;
  padding: 8px 12px;

  ${({ $isHeader }) =>
    !$isHeader &&
    css`
      background-color: ${spectrum.blue0};
    `}
`;

/**
 * The text for the data, like "Grayscale" or "Greenhouse"
 */
const Data = styled.span<ComponentProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: ${palette.black};
  ${ONE_LINE_TRUNCATION};

  ${({ $isHeader }) =>
    !$isHeader &&
    css`
      font-weight: 400;
      font-family: var(--font-family-monospace);
    `}
`;

/**
 * Label of thing, like "Organization" or "Email address", with potential underline
 */
const Label = styled.span.attrs({ className: "font-semibold" })<{ $isUnderlined?: true }>`
  font-size: 12px;
  line-height: 18px;
  color: ${palette.gray};
  align-self: flex-start;
  ${ONE_LINE_TRUNCATION};
  ${({ $isUnderlined }) =>
    $isUnderlined &&
    css`
      text-decoration: underline;
      -webkit-text-decoration: underline dotted;
      text-decoration: underline dotted;
      cursor: help;
    `}
`;

/**
 * This pair ties a label and a piece of data together for use in the sidebar on
 * a linked account detail page.
 */
const MetadataPair = ({
  label,
  labelHoverText,
  labelIcon,
  data,
  accessoryElement,
  isHeader,
}: Props) => {
  // Horizontal stack for data + accessory, or just data or accessoryElement
  const dataElement = <Data $isHeader={isHeader}>{data}</Data>;
  const dataAndAccessoryElement = accessoryElement ? (
    <Stack $gap="4px" className="align-items-center">
      {dataElement}
      {accessoryElement}
    </Stack>
  ) : (
    dataElement
  );
  const labelElement = labelHoverText ? (
    <OverlayTrigger overlay={<Tooltip id={labelHoverText}>{labelHoverText}</Tooltip>}>
      <Label $isUnderlined>{label}</Label>
    </OverlayTrigger>
  ) : (
    <Label>{label}</Label>
  );

  const labelStack = labelIcon ? (
    <Stack $gap="8px" className="align-items-center">
      {labelIcon}
      {labelElement}
    </Stack>
  ) : (
    labelElement
  );
  return (
    <OneLineStack $isVertical $gap="2px" $isHeader={isHeader}>
      {labelStack}
      {dataAndAccessoryElement}
    </OneLineStack>
  );
};

export default MetadataPair;
