import React from "react";
import useAppContext from "../../../../context/useAppContext";
import SyncHistory from "../overview/SyncHistory";
import { LinkedAccount } from "../../../../../models/Entities";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import useCategoryComponentSchema from "../../../../../hooks/useCategoryComponentSchema";

type LinkedAccountDataPageProps = {
  linkedAccount: LinkedAccount;
};
const LinkedAccountDetailDataPage = ({ linkedAccount }: LinkedAccountDataPageProps) => {
  const { schemas } = useCategoryComponentSchema(linkedAccount.category);
  const { user, isUserPrivileged } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const includeLinkedAccountScopes =
    isUserPrivileged &&
    productRestrictions?.are_toggles_enabled &&
    user.is_linked_account_scopes_dashboard_enabled;

  return (
    <SyncHistory
      id={linkedAccount.id}
      linkedAccount={linkedAccount}
      includeLinkedAccountScopes={includeLinkedAccountScopes}
      schemas={schemas}
    />
  );
};
export default LinkedAccountDetailDataPage;
