import React from "react";

import { Badge, Card, Col, Row } from "react-bootstrap";
import LinkedAccountsChart from "./charts/LinkedAccountsChart";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

interface Props {
  linkedAccountsCount: number | undefined;
  data?: Array<{ date: string; linked_accounts_count: number }>;
}

const LinkedAccountsAnalyticsCard = ({ linkedAccountsCount, data }: Props) => {
  return (
    <Card>
      <Card.Body className="text-left overflow-auto">
        <Row>
          <Col className="text-align-left">
            <DeprecatedH4>Linked Accounts</DeprecatedH4>
            {linkedAccountsCount && linkedAccountsCount > 0 ? (
              <span className="dashboard-number-header">{linkedAccountsCount}</span>
            ) : (
              <span className="gray dashboard-number-header">-</span>
            )}
            {data != null && data[0]["linked_accounts_count"] != 0 && linkedAccountsCount && (
              <Badge variant="soft-success" className="deprecated-mb-4 deprecated-ml-2">
                {"+" +
                  Math.round((linkedAccountsCount / data[0]["linked_accounts_count"]) * 100) +
                  "%"}
              </Badge>
            )}
          </Col>

          <Col>
            <div className="w-100" style={{ height: "5rem" }}>
              <LinkedAccountsChart data={data} />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LinkedAccountsAnalyticsCard;
