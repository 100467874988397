import classNames from "classnames";
import { format } from "date-fns";
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { Card, Col, Collapse, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
// import styled from "styled-components";
import { PaginatedAPIResponse } from "../../../api-client/APIClient";
import { UserType } from "../../../models/Entities";
import { palette } from "../../../styles/theme";
import useAppContext from "../../context/useAppContext";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import { MergeChargeStatusBadge } from "../../shared-components/MergeBadges";
import { CardHeaderTitle, SmallTextMutedParagraph } from "../../shared-components/MergeText";
import PaginationFooter from "../../shared-components/PaginationFooter";
import RotatingChevronRight from "../../shared-components/RotatingChevronRight";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { showErrorToast, showSuccessToast } from "../../shared-components/Toasts";
import {
  fetchOrgBillingPlan,
  getPaymentMethods,
  getInvoices,
  updateDefaultPaymentMethod,
  getInvoicePdf,
} from "./BillingAPIClient";
import {
  BankAccount,
  BillingPlan,
  CurrentCategoryCharge,
  CreditCard,
  MergeInvoice,
  OrganizationBillingPlan,
  PaymentMethod,
  BillingPlanTier,
  PAYABLE_INVOICE_STATUSES,
  FREE_PLAN_LINKED_ACCOUNT_LIMIT,
  LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT,
} from "./BillingModels";
import {
  formatCentsNearestCent,
  getBillingPlanTitle,
  isBillingPlanFreePlan,
  isBillingPlanLaunchPlan,
  parseCategoryChargesFromInvoice,
  parseCategoryUnitCountsFromBillingPlan,
  /**
   * Shared CSS elements between old and new Billing Pages.
   */
  CurrentModelUsageCard,
  CurrentModelUsageCardBody,
  /**
   * Shared Functions between old and new Billing Pages.
   */
  getFormattedDateFromISO,
  getThirtyDaysLater,
  getDefaultPaymentMethod,
} from "./BillingUtils";
import AddPaymentMethodModal from "./components/AddPaymentMethodModal";
import PaymentMethodRow from "./components/BillingPaymentMethodRow";
import CommonModelChargeTable from "./components/CommonModelChargeTable";
import PayInvoiceModal from "./components/PayInvoiceModal";
import AddBankAccountModal from "./components/AddBankAccountModal";
import DeletePaymentMethodModal from "./components/DeletePaymentMethodModal";
import InvoiceBreakdown from "./components/InvoiceBreakdown";
import { MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH } from "../../../router/RouterUtils";
import LinkedAccountUsageTable from "./components/LinkedAccountUsageTable";
import PricingPlanTitlePopover from "./components/PricingPlanTitlePopover";
import DeprecatedH2 from "../../../deprecated/DeprecatedH2";
import DeprecatedH6 from "../../../deprecated/DeprecatedH6";
import { Button, SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import styled from "styled-components";

function OldBillingPage() {
  const [showingAddPaymentMethodModal, setShowingAddPaymentMethodModal] = useState(false);
  const [paymentMethodDeletionTarget, setPaymentMethodDeletionTarget] = useState<
    PaymentMethod | undefined
  >();
  const [payInvoiceModalTarget, setPayInvoiceModalTarget] = useState<MergeInvoice | undefined>();
  const [paymentMethods, setPaymentMethods] = useState<null | PaymentMethod[]>(null);
  const [isLoadingCreditCards, setIsLoadingCreditCards] = useState(false);
  const [invoices, setInvoices] = useState<null | MergeInvoice[]>(null);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [expandedInvoice, setExpandedInvoice] = useState<null | MergeInvoice>(null);
  const [defaultPaymentMethodBeingSet, setDefaultPaymentMethodBeingSet] =
    useState<null | PaymentMethod>(null);
  const [currentOrgBillingPlan, setCurrentOrgBillingPlan] = useState<
    OrganizationBillingPlan | undefined
  >();

  const [commonModelPrices, setCommonModelPrices] = useState<CurrentCategoryCharge[] | undefined>();
  const [previousPageURL, setPreviousPageURL] = useState<string | null>(null);
  const [nextPageURL, setNextPageURL] = useState<string | null>(null);
  const { user } = useAppContext();

  const onBillingPlanFetch = (billingPlan: OrganizationBillingPlan) => {
    setCurrentOrgBillingPlan(billingPlan);
    setCommonModelPrices(parseCategoryUnitCountsFromBillingPlan(billingPlan));
  };

  const trialPlan = {
    name: "Merge Beta Plan",
    is_active: true,
    plan_tier: BillingPlanTier.BILLING_PLAN_TIER_LAUNCH,
    monthly_price_cents: 0,
    api_request_price_cents: 0,
    scrape_price_cents: 0,
    webhook_price_cents: 0,
    free_api_requests: 10000,
    free_scrapes: -1,
    free_webhooks: -1,
  } as BillingPlan;

  const myTrialPlan = {
    billing_plan: trialPlan,
    start_date: format(new Date(), "M/d/yyyy"),
    end_date: format(new Date(2021, 5, 1), "M/d/yyyy"),
  } as OrganizationBillingPlan;

  const StyledP = styled.p`
    @media (min-width: 992px) {
      padding-bottom: 0;
    }
  `;

  useEffect(() => {
    fetchOrgBillingPlan({
      onFetch: onBillingPlanFetch,
      // TEMPORARY - For now Orgs that have no plan are still on the trial period
      onError: () => {
        setCurrentOrgBillingPlan(myTrialPlan);
      },
    });

    if (user.type === UserType.admin_with_billing) {
      fetchPaymentMethods(false);
      fetchInvoices();
    }
  }, []);

  const organizePaymentMethods = (
    bankAccounts: BankAccount[],
    creditCards: CreditCard[],
  ): PaymentMethod[] => {
    const arr: PaymentMethod[] = [...bankAccounts, ...creditCards]
      .sort((a, b) => a.created_at.localeCompare(b.created_at))
      .sort((a, _) => (a.is_default_method ? -1 : 1));
    return arr;
  };

  const fetchPaymentMethods = (forceRefreshFromStripe: boolean) => {
    setIsLoadingCreditCards(true);
    getPaymentMethods({
      forceRefreshStripe: forceRefreshFromStripe,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setIsLoadingCreditCards(false);
      },
    });
  };

  const setDefaultPaymentMethod = (paymentMethod: PaymentMethod) => {
    setDefaultPaymentMethodBeingSet(paymentMethod);
    updateDefaultPaymentMethod({
      paymentMethod: paymentMethod,
      onSuccess: (data: { bank_accounts: BankAccount[]; credit_cards: CreditCard[] }) => {
        const paymentMethods = organizePaymentMethods(data.bank_accounts, data.credit_cards);
        setPaymentMethods(paymentMethods);
        setDefaultPaymentMethodBeingSet(null);
        showSuccessToast("Successfully updated your default payment method!");
      },
    });
  };

  const fetchInvoices = (cursorURL?: string) => {
    setIsLoadingInvoices(true);
    getInvoices({
      cursorURL,
      onSuccess: (data: PaginatedAPIResponse<MergeInvoice>) => {
        setInvoices(data.results);
        setPreviousPageURL(data.previous);
        setNextPageURL(data.next);
        setIsLoadingInvoices(false);
      },
      onError: () => {
        setInvoices(null);
        setPreviousPageURL(null);
        setNextPageURL(null);
        showErrorToast("Failed to load invoices, please try again later or contact support");
        setIsLoadingInvoices(false);
      },
    });
  };

  const fetchInvoicePdf = (invoice: MergeInvoice) => {
    const win = window.open("about:blank", "_blank");
    if (win) {
      getInvoicePdf({
        invoiceId: invoice.id,
        onSuccess: (data: { invoice_url: string }) => {
          win.location.href = data.invoice_url;
          win.focus();
        },
        onError: () => {
          showErrorToast("Failed to load invoice PDF");
          win.close();
        },
      });
    } else {
      showErrorToast("Failed to load invoice PDF");
    }
  };

  const isFreePlan = isBillingPlanFreePlan(currentOrgBillingPlan?.billing_plan);
  const isLaunchPlan = isBillingPlanLaunchPlan(currentOrgBillingPlan?.billing_plan);
  const productionLinkedAccountCount = user.organization.production_linked_account_count || 0;
  const testLinkedAccountCount = user.organization.test_linked_account_count || 0;

  function getPlanSubtitle() {
    switch (currentOrgBillingPlan?.billing_plan?.plan_tier) {
      case BillingPlanTier.BILLING_PLAN_TIER_FREE:
        return `Up to ${FREE_PLAN_LINKED_ACCOUNT_LIMIT} free Production Linked Accounts. You will be automatically upgraded to the Launch Plan ($650/month) when you exceed 3 Production Linked Accounts.`;
      case BillingPlanTier.BILLING_PLAN_TIER_LAUNCH:
        return (
          <>
            {`Up to ${LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT} Production Linked Accounts for
            $650/month. You will automatically incur a $65/month prorated fee for every Production
            Linked Account past ${LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT}. `}
            <a href={MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH} rel="noreferrer" target="_blank">
              Learn more
            </a>
          </>
        );
      case BillingPlanTier.BILLING_PLAN_TIER_GROW:
        return "Premium";
      case BillingPlanTier.BILLING_PLAN_TIER_EXPAND:
        return "Enterprise";
    }
    return "";
  }

  return (
    <>
      {isFreePlan || isLaunchPlan ? (
        <AddPaymentMethodModal
          show={showingAddPaymentMethodModal}
          onHide={() => {
            setShowingAddPaymentMethodModal(false);
            fetchPaymentMethods(true);
          }}
        />
      ) : (
        <AddBankAccountModal
          show={showingAddPaymentMethodModal}
          onHide={() => {
            setShowingAddPaymentMethodModal(false);
            fetchPaymentMethods(true);
          }}
        />
      )}
      <PayInvoiceModal
        onHide={() => {
          setPayInvoiceModalTarget(undefined);
          fetchInvoices();
        }}
        invoice={payInvoiceModalTarget}
        defaultPaymentMethod={getDefaultPaymentMethod(paymentMethods)}
      />
      <DeletePaymentMethodModal
        paymentMethod={paymentMethodDeletionTarget}
        onHide={() => {
          setPaymentMethodDeletionTarget(undefined);
          fetchPaymentMethods(true);
        }}
      />
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col xl="7" className="pr-7">
              <DeprecatedH6 className="text-gray-50 deprecated-mb-2">Current plan</DeprecatedH6>
              <div className="billing-plan-type">
                <DeprecatedH2 className="billing-plan-name">
                  {currentOrgBillingPlan ? getBillingPlanTitle(currentOrgBillingPlan) : "-"}
                </DeprecatedH2>
              </div>
              <StyledP className="py-3 mb-0">
                <small className="billing-plan-subheader">{getPlanSubtitle()}</small>{" "}
              </StyledP>
            </Col>
            {(isLaunchPlan || isFreePlan) && (
              <Col xl="5">
                <CurrentModelUsageCard>
                  <CurrentModelUsageCardBody>
                    <LinkedAccountUsageTable
                      productionLinkedAccountCount={productionLinkedAccountCount}
                      testLinkedAccountCount={testLinkedAccountCount}
                    />
                  </CurrentModelUsageCardBody>
                </CurrentModelUsageCard>
              </Col>
            )}
            {!isFreePlan && !isLaunchPlan && commonModelPrices && commonModelPrices.length > 0 && (
              <Col xl="5">
                <CurrentModelUsageCard>
                  <CurrentModelUsageCardBody>
                    <CommonModelChargeTable
                      keyPrefix="common-model-charges-current-"
                      commonModelCharges={commonModelPrices}
                      organizationBillingPlan={currentOrgBillingPlan}
                      isLaunchPlan={isLaunchPlan}
                    />
                  </CurrentModelUsageCardBody>
                </CurrentModelUsageCard>
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
      {invoices ? (
        <>
          <Card>
            <Card.Header>
              <Row className="align-items-center">
                <Col>
                  <CardHeaderTitle>Payment Methods</CardHeaderTitle>
                </Col>
                <Col className="col-auto">
                  {user.type === UserType.admin_with_billing ? (
                    <Button
                      onClick={() => setShowingAddPaymentMethodModal(true)}
                      color="blue"
                      variant="plus"
                    >
                      Add Payment Method
                    </Button>
                  ) : (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="step-card-full-name-tooltip" id="tooltip">
                          You must be an admin to add payment methods
                        </Tooltip>
                      }
                      placement="top"
                    >
                      <div>
                        <Button color="blue" variant="plus">
                          Add Payment Method
                        </Button>
                      </div>
                    </OverlayTrigger>
                  )}
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <div className="list-group list-group-flush my-n3">
                {user.type !== UserType.admin_with_billing ? (
                  <EmptyStateWrapper title="You must be an admin to view payment methods" />
                ) : isLoadingCreditCards ? (
                  <EmptyStateWrapper isSpinner />
                ) : paymentMethods && paymentMethods?.length > 0 ? (
                  paymentMethods.map((paymentMethod) => (
                    <PaymentMethodRow
                      key={paymentMethod.id}
                      paymentMethod={paymentMethod}
                      billingPlan={currentOrgBillingPlan}
                      updateBillingPlan={setCurrentOrgBillingPlan}
                      onClickSetDefault={() => setDefaultPaymentMethod(paymentMethod)}
                      settingDefault={defaultPaymentMethodBeingSet === paymentMethod}
                      setPaymentMethodDeletionTarget={setPaymentMethodDeletionTarget}
                    />
                  ))
                ) : (
                  <EmptyStateWrapper title="No payment methods" />
                )}
              </div>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <CardHeaderTitle>Invoices</CardHeaderTitle>
            </Card.Header>
            <div className="table-responsive rounded-table-corner">
              <Table size="sm" className="table-nowrap card-table">
                <thead>
                  <tr>
                    <th>Invoice Date</th>
                    <th>Billing Period</th>
                    <th>Amount</th>
                    <th>Due Date</th>
                    <th>Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody className="font-size-base">
                  {user.type !== UserType.admin_with_billing ? (
                    <tr>
                      <td colSpan={6}>
                        <EmptyStateWrapper isTable title="You must be an admin to view invoices" />
                      </td>
                    </tr>
                  ) : isLoadingInvoices ? (
                    <tr>
                      <td colSpan={6}>
                        <EmptyStateWrapper isTable isSpinner />
                      </td>
                    </tr>
                  ) : isEmpty(invoices) ? (
                    <tr>
                      <td colSpan={6}>
                        <EmptyStateWrapper isTable title="No invoices" />
                      </td>
                    </tr>
                  ) : (
                    invoices.map((invoice) => (
                      <React.Fragment key={invoice.id}>
                        <tr
                          className={classNames(
                            "table-link",
                            expandedInvoice?.id === invoice.id ? "bg-lighter" : "",
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            setExpandedInvoice(expandedInvoice?.id === invoice.id ? null : invoice);
                          }}
                          style={{ borderBottomWidth: 0 }}
                        >
                          <td>
                            <strong>
                              {getFormattedDateFromISO(invoice.charge_period_start_time)}
                            </strong>
                          </td>
                          <td className="text-gray-50">
                            {invoice.created_post_billing_overhaul
                              ? getFormattedDateFromISO(invoice.issue_date)
                              : getFormattedDateFromISO(invoice.charge_period_start_time)}
                            -
                            {invoice.created_post_billing_overhaul
                              ? getFormattedDateFromISO(invoice.due_date)
                              : getFormattedDateFromISO(invoice.charge_period_end_time)}
                          </td>
                          <td className="text-gray-50">
                            {invoice.created_post_billing_overhaul
                              ? invoice.formatted_total
                              : formatCentsNearestCent(invoice.total_charge_amount)}
                          </td>
                          <td className="text-gray-50">
                            {invoice.created_post_billing_overhaul
                              ? getFormattedDateFromISO(invoice.due_date)
                              : getThirtyDaysLater(invoice.charge_period_start_time)}
                          </td>
                          <td className="text-gray-50">
                            <MergeChargeStatusBadge status={invoice.status} />
                          </td>
                          <td className="d-flex justify-content-end">
                            <div className="d-flex">
                              {PAYABLE_INVOICE_STATUSES.includes(invoice.status) && (
                                <SpinnerButton
                                  text="Pay now"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setPayInvoiceModalTarget(invoice);
                                  }}
                                  isLoading={false}
                                  className="btn-outline-secondary btn-sm"
                                  type="button"
                                />
                              )}
                              {invoice.created_post_billing_overhaul && (
                                <SmallWhiteButton
                                  variant="singleIcon"
                                  className="deprecated-ml-3"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    fetchInvoicePdf(invoice);
                                  }}
                                >
                                  <i className="fe fe-file" />
                                </SmallWhiteButton>
                              )}
                            </div>
                            <SmallWhiteButton
                              className="deprecated-ml-3"
                              variant="singleIcon"
                              onClick={() => setExpandedInvoice(invoice)}
                            >
                              <RotatingChevronRight
                                isRotatedDown={invoice.id === expandedInvoice?.id}
                              />
                            </SmallWhiteButton>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={6}
                            className={classNames(
                              "py-0 bg-lighter",
                              expandedInvoice?.id === invoice.id
                                ? ""
                                : "linked-account-table-row-border",
                            )}
                            style={{
                              borderTop: "0px",
                              borderBottom: `1px solid ${palette.border}`,
                            }}
                          >
                            <Collapse in={invoice.id === expandedInvoice?.id}>
                              <div>
                                <tr></tr>
                                {invoice.created_post_billing_overhaul ? (
                                  <InvoiceBreakdown invoice={invoice} />
                                ) : (
                                  <CommonModelChargeTable
                                    keyPrefix={`${invoice.id}-`}
                                    organizationBillingPlan={currentOrgBillingPlan}
                                    commonModelCharges={parseCategoryChargesFromInvoice(invoice)}
                                    isInvoice
                                    isLaunchPlan
                                  />
                                )}
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </Card>
          {(previousPageURL || nextPageURL) && (
            <PaginationFooter
              hasPrevious={!!previousPageURL}
              hasNext={!!nextPageURL}
              onPreviousClick={() => {
                fetchInvoices(previousPageURL || undefined);
              }}
              onNextClick={() => {
                fetchInvoices(nextPageURL || undefined);
              }}
            />
          )}
        </>
      ) : (
        <div></div>
      )}
      <SmallTextMutedParagraph className="text-center deprecated-mb-5">
        Tired of painless integrations?{" "}
        <a href="mailto:hello@merge.dev" className="red">
          Contact us to cancel your plan
        </a>
      </SmallTextMutedParagraph>
    </>
  );
}

export default OldBillingPage;
