import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import styled, { css } from "styled-components";
import { EnabledAction } from "../../models/CommonModel";
import MaybeDisable from "./MaybeDisable";

export interface MultiSwitchOption {
  text: string | JSX.Element;
  id: string;
  selectedColor: string;
  backgroundColor?: string;
  borderColor?: string;
  disable?: boolean;
  disableTooltip?: string;
  enabledActions?: Array<EnabledAction>;
}

interface MultiSwitchProps {
  options: MultiSwitchOption[];
  selectedID: string;
  onSelectOption: (option: MultiSwitchOption) => void;
  className?: string;
  keyPrefix?: string;
  isDisabled?: boolean;
  isRounded?: boolean;
}

/**
 * Describes where a button is, determining what borders/rounding of corners applies
 */
type Layout = "right" | "middle" | "left" | "alone";

/**
 * Adds a background or border color to the button, overriding the other styles on the button.
 * Resets the border radius.
 */
const StyledButton = styled(Button)<{
  $backgroundColor?: string;
  $borderColor?: string;
  $layout: Layout;
  $isRounded: boolean;
}>`
  border-radius: 0 !important;
  padding: ${({ $isRounded }) => ($isRounded ? "3px 12px" : "6px 10px")};
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor} !important;
    `};
  ${({ $borderColor }) =>
    $borderColor &&
    css`
      border: 1px solid ${$borderColor} !important;
    `};
  ${({ $layout, $isRounded }) => {
    const left = css`
      border-radius: ${$isRounded ? "14px" : "5px 0 0 5px"} !important;
    `;
    const right = css`
      border-radius: ${$isRounded ? "14px" : "0 5px 5px 0"} !important;
    `;
    switch ($layout) {
      case "alone":
        return css`
          ${left};
          ${right};
        `;
      case "left":
        return css`
          ${left};
          border-right-width: 0 !important;
        `;
      case "right":
        return right;
      case "middle":
        return css`
          border-right-width: 0 !important;
        `;
    }
  }}
`;

const StyledButtonGroup = styled(ButtonGroup)<{
  $isRounded: boolean;
}>`
  background-color: #eff1f4;
  border-radius: ${({ $isRounded }) => ($isRounded ? "14px" : "5px")};
`;

const MultiSwitch = ({
  options,
  selectedID,
  onSelectOption,
  keyPrefix,
  isRounded,
  className,
}: MultiSwitchProps) => {
  const selectedIndex = options.findIndex((option) => option.id === selectedID);

  return (
    <StyledButtonGroup $isRounded={isRounded} className={className}>
      {options.map((option, i) => {
        const isDisabled = option.disable && selectedIndex !== i;
        const isFirst = i === 0;
        const isLast = i === options.length - 1;
        const isAlone = isFirst && isLast;
        const layout: Layout = isAlone ? "alone" : isFirst ? "left" : isLast ? "right" : "middle";
        const button = (
          <StyledButton
            type="button"
            variant="secondary"
            className={
              selectedIndex === i
                ? isRounded
                  ? "multi-switch-button-selected-rounded"
                  : "multi-switch-button-selected"
                : isRounded
                ? "multi-switch-button-rounded"
                : "multi-switch-button"
            }
            $isRounded={isRounded}
            $layout={layout}
            $backgroundColor={selectedIndex === i ? option.selectedColor : option.backgroundColor}
            $borderColor={option.borderColor}
            key={i}
            onClick={() => {
              onSelectOption(option);
            }}
          >
            <b>{option.text}</b>
          </StyledButton>
        );
        return isRounded ? (
          button
        ) : (
          <MaybeDisable
            key={`${keyPrefix || ""}multi-switch-button-${i}`}
            disable={isDisabled}
            tooltip={isDisabled ? option.disableTooltip : undefined}
            hasPadding={false}
          >
            {button}
          </MaybeDisable>
        );
      })}
    </StyledButtonGroup>
  );
};

export default MultiSwitch;
