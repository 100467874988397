import React, { useCallback, useEffect, useState } from "react";
import { User, PendingInvite } from "../../models/Entities";

import AppContext, { UserWithBillingPlan } from "./AppContext";
import { useIntercom } from "react-use-intercom";
import { getBillingPlanTitle } from "../settings/billing/BillingUtils";
import { doesUserHavePrivilege } from "../../models/Helpers";

interface Props {
  children: (JSX.Element | undefined | null)[];
  user: UserWithBillingPlan;
  setUser: (user: User) => void;
}

// Wraps around App to be able to pull global state anywhere in tree via useContext
const AppContextProvider = ({ children, user, setUser }: Props) => {
  const { update } = useIntercom();
  const [showDemoDataAlert, setShowDemoDataAlert] = useState<boolean>(true);
  const [pendingOrganizationInvites, setPendingOrganizationInvites] = useState<PendingInvite[]>();
  const [requestedPlanUpgrade, setRequestedPlanUpgrade] = useState<boolean>(false);

  const isUserPrivileged = user ? doesUserHavePrivilege(user) : false;

  const updateIntercom = useCallback(update, [update]);

  useEffect(() => {
    if (user) {
      const billingPlanTitle = getBillingPlanTitle(user.organization.organization_billing_plan);

      updateIntercom({
        email: user.email,
        userId: user.email,
        customAttributes: {
          intercom_hmac: user.intercom_hmac,
        },
        name: user.name,
        avatar: {
          type: "avatar",
          imageUrl: user.avatar ?? undefined,
        },
        company: {
          name: user.organization.name,
          companyId: user.organization.name,
          plan: billingPlanTitle,
        },
      });
    }
  }, [user, updateIntercom]);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        isUserPrivileged,
        setShowDemoDataAlert,
        showDemoDataAlert,
        pendingOrganizationInvites,
        setPendingOrganizationInvites,
        setRequestedPlanUpgrade,
        requestedPlanUpgrade,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
