import classNames from "classnames";
import React from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Plus, X } from "react-feather";
import { ArrayField, Controller, UseFieldArrayMethods, UseFormMethods } from "react-hook-form";
import { palette } from "../../../styles/theme";
/**
 * Simplifies using request headers throughout the API tester
 */
export interface RequestHeader {
  /**
   * Name of the header, like "Accept", or `""` before it's fully created
   */
  name: string;

  /**
   * Current value of the header, like "application/json", or `""` before it's fully created
   */
  value: string;
}

/**
 * Uses a ton of types from the hooks we use here
 */
type Props = Pick<UseFieldArrayMethods<RequestHeader>, "append" | "remove"> &
  Pick<UseFormMethods, "errors" | "control" | "getValues"> & {
    /**
     * The request header we're displaying in the row, direct from the `useFieldArray` hook.
     */
    requestHeader: Partial<ArrayField<RequestHeader, "id">>;

    /**
     * The index of the header we're rendering here
     */
    index: number;

    /**
     * If this is a row for the last request header in the list
     */
    isLastRow: boolean;
  };

/**
 * Creates and returns a new placeholder request header
 */
export const createPlaceholderRow: () => RequestHeader = () => ({ name: "", value: "" });

/**
 * This is used as the base identifier for the errors and data stored in request headers. All fields
 * are stored in an array under this key, thanks to the magic of `useForm` in the runner page component.
 */
export const HEADERS_FIELD_NAME = "requestHeaders";

/**
 * Uses all values to make sure the given header name is unique. Empty/undefined headers
 * count as unique.
 */
const uniqueHeader = (value: string, getValues: Props["getValues"]) => {
  if (!value) {
    return true;
  }
  const allHeaders: Array<string> = getValues()[HEADERS_FIELD_NAME].map(
    ({ name }: Partial<RequestHeader>) => name,
  );
  return allHeaders.filter((header) => header === value).length === 1;
};

/**
 * Component that creates a single row for a request header from the header itself or a placeholder
 */
const RequestHeaderFormRow = ({
  requestHeader,
  isLastRow,
  index,
  append,
  remove,
  control,
  errors,
  getValues,
}: Props) => {
  // For identifying the form field for the name/value - nests them under their common header from the hook
  const fieldNameIdentifier = `${HEADERS_FIELD_NAME}.${index}.name`;
  const fieldValueIdentifier = `${HEADERS_FIELD_NAME}.${index}.value`;

  // If there's an error, it'll exist here
  const fieldError = errors[HEADERS_FIELD_NAME]?.[index];

  // Uses a Controller so we can use the field array correctly with append/remove
  const nameFieldComponent = (
    <Controller
      name={fieldNameIdentifier}
      control={control}
      defaultValue={requestHeader.name}
      rules={{
        required: !isLastRow,
        minLength: isLastRow ? 0 : 1,
        validate: { unique: (value) => uniqueHeader(value, getValues) },
      }}
      render={(field) => {
        const { onChange, ...rest } = field;
        return (
          <Form.Control
            as="input"
            placeholder={`Header #${index + 1} Name`}
            onChange={(event) => {
              // Add a new row if it's the placeholder row
              if (isLastRow) {
                append(createPlaceholderRow());
              }
              onChange(event);
            }}
            className={classNames("form-control", {
              "is-invalid": fieldError?.name,
            })}
            {...rest}
          />
        );
      }}
    />
  );

  // Mirrors the name field, but with value
  const valueFieldComponent = (
    <Controller
      name={fieldValueIdentifier}
      control={control}
      defaultValue={requestHeader.value}
      rules={{ required: !isLastRow, minLength: isLastRow ? 0 : 1 }}
      render={(field) => {
        const { onChange, ...rest } = field;
        return (
          <Form.Control
            as="input"
            placeholder={`Header #${index + 1} Value`}
            onChange={(event) => {
              // Add a new row if it's the placeholder row
              if (isLastRow) {
                append(createPlaceholderRow());
              }
              onChange(event);
            }}
            className={classNames("form-control", {
              "is-invalid": fieldError?.value,
            })}
            {...rest}
          />
        );
      }}
    />
  );

  // Stacks horizontally with the value spanning most, with errors below
  return (
    <Form.Group>
      <Row>
        <Col xs="5" lg="3">
          {nameFieldComponent}
          {fieldError?.name && (
            <div className="invalid-feedback">
              {fieldError?.name?.type === "required" &&
                "Please provide a name or delete this header"}
              {fieldError?.name?.type === "unique" &&
                "Headers must be unique, please delete one of these"}
            </div>
          )}
        </Col>
        <Col xs={{ span: true }}>
          {valueFieldComponent}
          {fieldError?.value && (
            <div className="invalid-feedback">
              {fieldError?.value?.type === "required" &&
                "Please provide a value or delete this header"}
            </div>
          )}
        </Col>
        <Col xs="auto" className="d-flex align-items-start">
          {/* Either a button that adds a new row, or one that removes the row, with buttons "centered" vertically with the form field */}
          {isLastRow ? (
            <Button
              style={{ marginTop: "6px" }}
              variant="white"
              size="sm"
              onClick={() => append(createPlaceholderRow())}
            >
              <Plus color={palette.black} size="16" />
            </Button>
          ) : (
            <Button
              style={{ marginTop: "6px" }}
              variant="white"
              size="sm"
              onClick={() => {
                remove(index);
              }}
            >
              <X color={palette.red} size="16" />
            </Button>
          )}
        </Col>
      </Row>
    </Form.Group>
  );
};

export default RequestHeaderFormRow;
