import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import classNames from "classnames";
import ClickableContainer from "../../../../shared-components/ClickableContainer";
import { XSIconContainer } from "../../../../configuration/common-models/FieldToggle";
import CenteredVerticalLineCol from "../../../../configuration/common-models/CenteredVerticalLineCol";
import { CommonModelFieldLevelMapping } from "../../../../../models/Entities";
import { mapTypeToFeatherIconClassName } from "../../../../configuration/common-models/FieldToggle";
import LinkedAccountSettingToggle, {
  LinkedAccountSettingToggleDropdownOption,
} from "../overview/LinkedAccountSettingToggle";
import AnimatedChevron from "../../../../configuration/common-models/AnimatedChevron";

const FieldRow = styled(Row).attrs({ className: "flex-nowrap" })`
  margin: 0px;
`;

const FieldTitleText = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

const FieldDescription = styled.div`
  font-size: 12px;
  color: #a6aab1;
  margin-top: 4px;
`;

const RowField = styled(Row)`
  border-top: 0.5px solid #edf2f9;
`;

const StyledCenteredVerticalLineCol = styled(CenteredVerticalLineCol)`
  width: 24px;
`;

const StyledXSIconContainer = styled(XSIconContainer)`
  margin-left: 2px;
`;
const checkIfLinkedAccountOverride = (
  orgCommonModelScope: CommonModelFieldLevelMapping,
  linkedAccountCommonModelScope: CommonModelFieldLevelMapping,
) => {
  return orgCommonModelScope.isEnabled !== linkedAccountCommonModelScope.isEnabled;
};

type ScopesFieldRowProps = {
  fieldName: string;
  fieldDescription: string | null;
  fieldType: string | undefined;
  orgLevelCommonModelFieldScope: CommonModelFieldLevelMapping;
  linkedAccountCommonModelActions: string[];
  orgLevelCommonModelActions: string[];
  linkedAccountCommonModelFieldScope: CommonModelFieldLevelMapping;
  commonModelName: string;
  isLastRow: boolean;
  changeState: (
    commonModelName: string,
    fieldName: string,
    linkedAccountCommonModelActions: string[],
  ) => void;
};

const ScopesFieldRow = ({
  fieldName,
  fieldDescription,
  fieldType,
  orgLevelCommonModelFieldScope,
  orgLevelCommonModelActions,
  linkedAccountCommonModelActions,
  linkedAccountCommonModelFieldScope,
  commonModelName,
  isLastRow,
  changeState,
}: ScopesFieldRowProps) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  const updateFieldLevelScope = (scopeKey: LinkedAccountSettingToggleDropdownOption) => {
    switch (scopeKey) {
      case LinkedAccountSettingToggleDropdownOption.ENABLE:
        changeState(commonModelName, fieldName, linkedAccountCommonModelActions);
        break;
      case LinkedAccountSettingToggleDropdownOption.RESET:
        if (orgLevelCommonModelFieldScope.isEnabled) {
          changeState(commonModelName, fieldName, orgLevelCommonModelActions);
        } else {
          changeState(commonModelName, fieldName, []);
        }
        break;
      default:
        changeState(commonModelName, fieldName, []);
        break;
    }
  };

  const _renderLinkedAccountSettingToggle = () => {
    const isLinkedAccountOverride = checkIfLinkedAccountOverride(
      orgLevelCommonModelFieldScope,
      linkedAccountCommonModelFieldScope,
    );
    const isSettingEnabled = isLinkedAccountOverride
      ? linkedAccountCommonModelFieldScope.isEnabled
      : orgLevelCommonModelFieldScope.isEnabled;

    return (
      <LinkedAccountSettingToggle
        onDropdownSelected={updateFieldLevelScope}
        isSettingEnabled={isSettingEnabled}
        isDefaultOverridden={isLinkedAccountOverride}
      />
    );
  };

  return (
    <FieldRow className="">
      <StyledCenteredVerticalLineCol
        className="col-auto px-0 ml-4"
        $verticalLineConfig={isLastRow ? "top-half" : "all"}
      >
        <Row className="mx-0 h-100">
          <Col className="px-0 my-auto">
            <StyledXSIconContainer $iconColumnWidthInPixels={24}>
              <i className={classNames("fe", mapTypeToFeatherIconClassName(fieldType))} />
            </StyledXSIconContainer>
          </Col>
        </Row>
      </StyledCenteredVerticalLineCol>
      <Col className="pr-0 deprecated-mr-3 ml-4">
        <RowField className="items-center flex-nowrap">
          <div className="flex-none pt-2 pb-2 pr-6 pl-0 my-auto min-w-fit xl:min-w-[400px] xl:w-1/4 w-1/2">
            {fieldDescription ? (
              <>
                <ClickableContainer
                  onClick={() => {
                    setIsDescriptionExpanded(!isDescriptionExpanded);
                  }}
                >
                  <div className="flex items-center">
                    <FieldTitleText>{fieldName}</FieldTitleText>
                    <AnimatedChevron className="text-[10px] ml-1" $isUp={isDescriptionExpanded} />
                    <span className="text-gray-50 ml-2">{fieldType}</span>
                  </div>
                </ClickableContainer>
                {isDescriptionExpanded && (
                  <FieldDescription className="text-wrap">{fieldDescription}</FieldDescription>
                )}
              </>
            ) : (
              <div className="flex items-center">
                <FieldTitleText>{fieldName}</FieldTitleText>
              </div>
            )}
          </div>
          <div className="flex-none xl:w-1/4 w-1/2">{_renderLinkedAccountSettingToggle()}</div>
        </RowField>
      </Col>
    </FieldRow>
  );
};
export default ScopesFieldRow;
