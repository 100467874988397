import React from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "../../styles/theme";

type StyledTableProps = {
  hasMorePaddingOnFirstElement?: boolean;
  isOverviewPage?: boolean;
  hasMarginBottom?: boolean;
};

/*
 * Generally we'll want to keep td at font-size 12px and line-height 22px, but this is table specific. There are certain rows
 * that should be different, and if so, simply override this.
 *
 * For our Issues and Logs table, we'll want 24px of margin on the first element on the left-hand side
 * For our Data Sync table, we'll want to keep 16px of margin on the left-hand side
 */

const StyledTable = styled.div<StyledTableProps>`
  box-shadow:${(props) => (props.isOverviewPage ? "" : "0px 4px 20px -4px rgba(0, 0, 0, 0.08);")} 
  background: ${palette.white};

  && th:first-child,
  && td:first-child {
    padding-left: ${({ hasMorePaddingOnFirstElement }) =>
      hasMorePaddingOnFirstElement ? "24px" : "16px"};
  }

  && th:last-child {
    margin: -2px;
    border-bottom: none;
  }
  && td:last-child {
    margin: -0.5px;
  }

  && th {
    background: ${palette.white};
    color: ${palette.black};
    font-size: 14px;
    line-height: 24px;
    border-bottom-width: 0.5px;
  }
  && td {
    font-size: 12px;
    line-height: 22px;
  }  
`;

type Props = {
  header?: JSX.Element;
  content: JSX.Element | JSX.Element[];
  hasMorePaddingOnFirstElement?: boolean;
  isOverviewPage?: boolean;
  hasMarginBottom?: boolean;
  hover?: boolean;
};

const MergeTable = ({
  header,
  content,
  hasMorePaddingOnFirstElement = false,
  isOverviewPage = false,
  hasMarginBottom = true,
  hover = true,
}: Props) => {
  return (
    <StyledTable
      className={`table-responsive rounded-table-corner fs-mask ${
        hasMarginBottom ? "deprecated-mb-5" : ""
      }`}
      hasMorePaddingOnFirstElement={hasMorePaddingOnFirstElement}
      isOverviewPage={isOverviewPage}
    >
      <Table size="sm" hover={hover} className="table-nowrap mb-0">
        {header && (
          <thead className="table-borderless">
            <tr>{header}</tr>
          </thead>
        )}
        <tbody>{content}</tbody>
      </Table>
    </StyledTable>
  );
};

export default MergeTable;
