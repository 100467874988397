import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import React, { useState } from "react";
import DevMergeLinkActionButton from "./DevMergeLinkActionButton";
import DevFilePickerActionButton from "./DevFilePickerActionButton";
import styled from "styled-components";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  tenantConfig: {} | { tenantConfig: { apiBaseURL: string } };
  onNewLinkedAccount: () => void;
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const DevMergeLinkSwitch = ({ linkToken, tenantConfig, onNewLinkedAccount }: Props) => {
  enum PickedButtonType {
    "FILEPICKER" = "FILEPICKER",
    "MERGELINK" = "MERGELINK",
    "NONE" = "NONE",
  }
  const [pickedButton, setPickedButton] = useState<PickedButtonType>(PickedButtonType.NONE);

  switch (pickedButton) {
    case PickedButtonType.NONE:
      return (
        <StyledDiv>
          <SmallWhiteButton
            className="mr-3"
            onClick={() => {
              setPickedButton(PickedButtonType.FILEPICKER);
            }}
          >
            File Picker
          </SmallWhiteButton>
          <SmallWhiteButton
            onClick={() => {
              setPickedButton(PickedButtonType.MERGELINK);
            }}
          >
            Merge Link
          </SmallWhiteButton>
        </StyledDiv>
      );
    case PickedButtonType.FILEPICKER:
      return (
        <DevFilePickerActionButton
          linkToken={linkToken}
          onNewLinkedAccount={onNewLinkedAccount}
          tenantConfig={tenantConfig}
        />
      );
    case PickedButtonType.MERGELINK:
      return (
        <DevMergeLinkActionButton
          linkToken={linkToken}
          onNewLinkedAccount={onNewLinkedAccount}
          tenantConfig={tenantConfig}
        />
      );
  }
};

export default DevMergeLinkSwitch;
