/**
 * TODO:
 * ALL COMMENTED CODE WILL BE USED IN THE UPCOMING ROLLOUT
 * ROLLOUT OF ADVANCED ONBOARDING AND WILL NOT BE REMOVED
 *
 * IT IS THERE TO GET ROUTING BOILERPLATE OUT OF THE WAY
 */

import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import styled from "styled-components";
import LiveWaitingIndicator from "../shared-components/LiveWaitingIndicator";
import DashboardOnboardingSteps from "./DashboardOnboardingSteps";
import OnboardingHeader from "./OnboardingHeader";
import {
  Link,
  ArrowLeftRight,
  Code,
  Boxes,
  RefreshCw,
  ArrowUp,
  Webhook,
  Codesandbox,
  // TextCursorInput,
  // FileLock2,
  // Stethoscope,
} from "lucide-react";
import { ReactComponent as MergeLink } from "./src/merge-link.svg";
import { HeaderPretitle } from "../shared-components/MergeText";
import useDashboardOnboardingStatus from "./utils/useDashboardOnboardingStatus";
import {
  ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
  ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
  ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
  ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
  ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
  ONBOARDING_ADVANCED_WEBHOOKS_PATH,
  // ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH,
  ONBOARDING_ADVANCED_METADATA_PATH,
  ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
  // ONBOARDING_ADVANCED_SENSITIVE_DATA_PATH,
  // ONBOARDING_ADVANCED_TROUBLESHOOTING_PATH,
  navigateToAddLinkToApp,
  navigateToIntegrateSyncedData,
  navigateToSendAPIRequest,
  navigateToSyncSandboxData,
  navigateToOnboardingSyncingData,
  navigateToOnboardingMergeWrites,
  navigateToOnboardingWebhooks,
  // navigateToOnboardingCustomFields,
  navigateToOnboardingMetadata,
  navigateToOnboardingSupplementalData,
  // navigateToOnboardingSensitiveData,
  // navigateToOnboardingTroubleshooting,
} from "../../router/RouterUtils";
import { useHistory, useLocation } from "react-router-dom";
import DashboardNavbarDropdown from "./DashboardNavbarDropdown";
import { useIntercom } from "react-use-intercom";

type Props = { children: JSX.Element | JSX.Element[] };

export interface DashboardOnboardingStatus {
  has_successfully_linked_account: boolean; // step 1
  has_pulled_common_model_data: boolean; // step 2
  has_made_request: boolean; // step 3
  has_opened_production_link: boolean; // step 4
}

const StyledMergeLink = styled(MergeLink)`
  height: 16px;
  width: 16px;
`;

/*
 ** This is the container that will hold the full-sized navigation buttons
 *  The media query is set up so navigation bar is displayed until 1330px, and will disappear at 1329px
 *  1330px is the minimum screen width before the buttons distort when being resized - if we want to lower this in the future we'll need a new design for the buttons
 *  The 'StyledDropdownContainer' will appear at 1329 px to replace it
 */

const VerticalOnboardingNav = styled.div`
  display: none;

  @media (min-width: 1330px) {
    display: block;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    align-self: flex-start;
    z-index: 1020;
    margin-right: 32px;
  }
`;

/*
 ** This is the container that will hold the drop-down button
 *  The media query is set up so the dropdown appears at 1329px, when the navigation bar (VerticalOnboardingNav) disappears
 */

const StyledDropdownContainer = styled.div`
  display: none;
  margin-bottom: 32px;

  @media (max-width: 1329px) {
    display: flex;
    flex-direction: row;
    align-self: flex-start;
  }
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Container = styled.div`
  padding-top: 40px;
  min-height: 100vh;
`;

/*
 * This StyledCol replicates the max-width settings we have enabled on our log and issues pages
 */
const StyledCol = styled(Col)`
&.col-xl-8 {
  @media (min-width: 1200px) {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media (min-width: 1400px) {
    flex: 0 0 100%;
    width: 100%;
    max-width: 1600px;
  }
`;

const StyledHeaderPreTitle = styled(HeaderPretitle)`
  margin-bottom: 12px;
`;

const IssuesFooter = styled.p`
  margin-top: 32px;
  color: var(--gray50);
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

const DashboardOnboardingWrapper = ({ children }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dashboardOnboardingStatus = useDashboardOnboardingStatus();
  const [onboardingStepCount, setOnboardingStepCount] = useState(0);
  const stepPathName = location.pathname;
  const { show } = useIntercom();

  const dashboardOnboardingArray = [
    dashboardOnboardingStatus?.has_made_request,
    dashboardOnboardingStatus?.has_successfully_linked_account,
    dashboardOnboardingStatus?.has_pulled_common_model_data,
    dashboardOnboardingStatus?.has_opened_production_link,
  ];

  // re-renders when a step is completed to update count
  useEffect(() => {
    setOnboardingStepCount(dashboardOnboardingArray.filter((value) => value).length);
  }, [
    dashboardOnboardingStatus?.has_made_request,
    dashboardOnboardingStatus?.has_successfully_linked_account,
    dashboardOnboardingStatus?.has_pulled_common_model_data,
    dashboardOnboardingStatus?.has_opened_production_link,
  ]);

  /*
   ** This is to scroll back to the top of page
   */

  const header = document.getElementById("scroll-to-row");

  useEffect(() => {
    header?.scrollIntoView({ behavior: "smooth" });
  }, [stepPathName]);

  return (
    <Row className="justify-content-center">
      <StyledCol className="col-12 col-lg-10 col-xl-8">
        <Container className="d-flex">
          <VerticalOnboardingNav>
            <StyledHeaderPreTitle>Get Started</StyledHeaderPreTitle>

            <DashboardOnboardingSteps
              stepTitle="Linked Account"
              icon={<Link size={16} />}
              lightIndicator={
                dashboardOnboardingStatus?.has_successfully_linked_account ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH}
              onClick={() => {
                navigateToSyncSandboxData(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Try Unified API"
              icon={<ArrowLeftRight size={16} />}
              lightIndicator={
                dashboardOnboardingStatus?.has_pulled_common_model_data ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH}
              onClick={() => {
                navigateToSendAPIRequest(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Connect Unified API"
              icon={<Code size={16} />}
              lightIndicator={
                dashboardOnboardingStatus?.has_made_request ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH}
              onClick={() => {
                navigateToIntegrateSyncedData(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Merge Link"
              icon={<StyledMergeLink />}
              lightIndicator={
                dashboardOnboardingStatus?.has_opened_production_link ? (
                  <LiveWaitingIndicator color="green" />
                ) : (
                  <LiveWaitingIndicator color="red" />
                )
              }
              isSelected={location.pathname === ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH}
              onClick={() => {
                navigateToAddLinkToApp(history);
              }}
            />

            <StyledHeaderPreTitle className="deprecated-mt-5">Advanced</StyledHeaderPreTitle>

            <DashboardOnboardingSteps
              stepTitle="Syncing Data"
              icon={<RefreshCw size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_SYNCING_DATA_PATH}
              onClick={() => {
                navigateToOnboardingSyncingData(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Merge Writes"
              icon={<ArrowUp size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_MERGE_WRITES_PATH}
              onClick={() => {
                navigateToOnboardingMergeWrites(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Webhooks"
              icon={<Webhook size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_WEBHOOKS_PATH}
              onClick={() => {
                navigateToOnboardingWebhooks(history);
              }}
            />
            {/* <DashboardOnboardingSteps
          stepTitle="Custom Fields"
          icon={<TextCursorInput size={16} />}
          isSelected={location.pathname === ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH}
          onClick={() => {
            navigateToOnboardingCustomFields(history);
          }}
        /> */}
            <DashboardOnboardingSteps
              stepTitle="Metadata"
              icon={<Codesandbox size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_METADATA_PATH}
              onClick={() => {
                navigateToOnboardingMetadata(history);
              }}
            />
            <DashboardOnboardingSteps
              stepTitle="Supplemental Data"
              icon={<Boxes size={16} />}
              isSelected={location.pathname === ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH}
              onClick={() => {
                navigateToOnboardingSupplementalData(history);
              }}
            />
            {/* <DashboardOnboardingSteps
          stepTitle="Sensitive Data"
          icon={<FileLock2 size={16} />}
          isSelected={location.pathname === ONBOARDING_ADVANCED_SENSITIVE_DATA_PATH}
          onClick={() => {
            navigateToOnboardingSensitiveData(history);
          }}
        /> */}
            {/* <DashboardOnboardingSteps
          stepTitle="Troubleshooting"
          icon={<Stethoscope size={16} />}
          isSelected={location.pathname === ONBOARDING_ADVANCED_TROUBLESHOOTING_PATH}
          onClick={() => {
            navigateToOnboardingTroubleshooting(history);
          }}
        /> */}

            <IssuesFooter>
              Issues?{" "}
              <a href="#" className="black" onClick={() => show()}>
                Chat with us.
              </a>
            </IssuesFooter>
          </VerticalOnboardingNav>
          <div className="flex-grow-1 flex flex-column justify-content-center self-start">
            {[
              ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
              ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
              ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
              ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
            ].includes(location.pathname) ? (
              <OnboardingHeader
                title="Get Started"
                subtitle="Rapidly add integrations to your app with our guides."
                className="px-0"
                badge={`${onboardingStepCount} / 4 COMPLETE`}
              />
            ) : (
              <OnboardingHeader
                title="Advanced"
                subtitle={
                  "Expand your app's integration capabilities with Merge's advanced features."
                }
                className="px-0"
              />
            )}
            <StyledDropdownContainer>
              <DashboardNavbarDropdown
                title={
                  <>
                    {stepPathName === ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH && (
                      <>
                        <TextContainer>
                          <Link className="deprecated-mr-3" size={16} />
                          Sync Sandbox Data
                          <div className="deprecated-ml-3 deprecated-mt-2">
                            {dashboardOnboardingStatus?.has_successfully_linked_account ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH && (
                      <>
                        <TextContainer>
                          <ArrowLeftRight className="deprecated-mr-3" size={16} />
                          Send API Request
                          <div className="deprecated-ml-3 deprecated-mt-2">
                            {dashboardOnboardingStatus?.has_pulled_common_model_data ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH && (
                      <>
                        <TextContainer>
                          <Code className="deprecated-mr-3" size={16} />
                          Integrate Synced Data
                          <div className="deprecated-ml-3 deprecated-mt-2">
                            {dashboardOnboardingStatus?.has_made_request ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH && (
                      <>
                        <TextContainer>
                          <StyledMergeLink className="deprecated-mr-3" />
                          Add Link to App
                          <div className="deprecated-ml-3 deprecated-mt-2">
                            {dashboardOnboardingStatus?.has_opened_production_link ? (
                              <LiveWaitingIndicator color="green" />
                            ) : (
                              <LiveWaitingIndicator color="red" />
                            )}
                          </div>
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_SYNCING_DATA_PATH && (
                      <>
                        <TextContainer>
                          <RefreshCw size={16} className="deprecated-mr-3" />
                          Syncing Data
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_MERGE_WRITES_PATH && (
                      <>
                        <TextContainer>
                          <ArrowUp size={16} className="deprecated-mr-3" />
                          Merge Writes
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_WEBHOOKS_PATH && (
                      <>
                        <TextContainer>
                          <Webhook size={16} className="deprecated-mr-3" />
                          Webhooks
                        </TextContainer>
                      </>
                    )}

                    {/* {stepPathName === ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH && (
                  <>
                    <TextContainer>
                      <TextCursorInput size={16} className="deprecated-mr-3" />
                      Custom Fields
                    </TextContainer>
                  </>
                )} */}

                    {stepPathName === ONBOARDING_ADVANCED_METADATA_PATH && (
                      <>
                        <TextContainer>
                          <Codesandbox size={16} className="deprecated-mr-3" />
                          Metadata
                        </TextContainer>
                      </>
                    )}

                    {stepPathName === ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH && (
                      <>
                        <TextContainer>
                          <Boxes size={16} className="deprecated-mr-3" />
                          Supplemental Data
                        </TextContainer>
                      </>
                    )}

                    {/* {stepPathName === ONBOARDING_ADVANCED_SYNCING_DATA_PATH && (
                  <>
                    <TextContainer>
                      <RefreshCw size={16} className="deprecated-mr-3" />
                      Syncing Data
                    </TextContainer>
                  </>
                )} */}

                    {/* {stepPathName === ONBOARDING_ADVANCED_SYNCING_DATA_PATH && (
                  <>
                    <TextContainer>
                      <RefreshCw size={16} className="deprecated-mr-3" />
                      Syncing Data
                    </TextContainer>
                  </>
                )} */}
                  </>
                }
              >
                <Dropdown.Item
                  key={1}
                  onClick={() => {
                    navigateToSyncSandboxData(history);
                  }}
                >
                  Sync Sandbox Data
                </Dropdown.Item>
                <Dropdown.Item
                  key={2}
                  onClick={() => {
                    navigateToSendAPIRequest(history);
                  }}
                >
                  Send API Request
                </Dropdown.Item>
                <Dropdown.Item
                  key={3}
                  onClick={() => {
                    navigateToIntegrateSyncedData(history);
                  }}
                >
                  Integrate Synced Data
                </Dropdown.Item>
                <Dropdown.Item
                  key={4}
                  onClick={() => {
                    navigateToAddLinkToApp(history);
                  }}
                >
                  Add Link to App
                </Dropdown.Item>
                <Dropdown.Item
                  key={4}
                  onClick={() => {
                    navigateToOnboardingSyncingData(history);
                  }}
                >
                  Syncing Data
                </Dropdown.Item>
                <Dropdown.Item
                  key={4}
                  onClick={() => {
                    navigateToOnboardingMergeWrites(history);
                  }}
                >
                  Merge Writes
                </Dropdown.Item>
                <Dropdown.Item
                  key={4}
                  onClick={() => {
                    navigateToOnboardingWebhooks(history);
                  }}
                >
                  Webhooks
                </Dropdown.Item>
                {/* <Dropdown.Item
              key={4}
              onClick={() => {
                navigateToOnboardingCustomFields(history);
              }}
            >
              Custom Fields
            </Dropdown.Item> */}
                <Dropdown.Item
                  key={4}
                  onClick={() => {
                    navigateToOnboardingMetadata(history);
                  }}
                >
                  Metadata
                </Dropdown.Item>
                <Dropdown.Item
                  key={4}
                  onClick={() => {
                    navigateToOnboardingSupplementalData(history);
                  }}
                >
                  Supplemental Data
                </Dropdown.Item>
                {/* <Dropdown.Item
              key={4}
              onClick={() => {
                navigateToOnboardingSensitiveData(history);
              }}
            >
              Sensitive Data
            </Dropdown.Item> */}
                {/* <Dropdown.Item
              key={4}
              onClick={() => {
                navigateToOnboardingTroubleshooting(history);
              }}
            >
              Troubleshooting
            </Dropdown.Item> */}
              </DashboardNavbarDropdown>
            </StyledDropdownContainer>

            {children}
          </div>
        </Container>
      </StyledCol>
    </Row>
  );
};

export default DashboardOnboardingWrapper;
