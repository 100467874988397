import React from "react";
import styled from "styled-components";
import { ChevronRight, Shuffle, Globe } from "lucide-react";
import {} from "../../../../router/RouterUtils";
import DeprecatedH1 from "../../../../deprecated/DeprecatedH1";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Button = styled.button`
  background: var(--blue40);
  border-radius: 6px;
  outline: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  padding: 5px 12px 5px 16px;
  gap: 2px;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus-visible {
    background: var(--blue30);
  }
`;

const CustomFields = () => {
  return (
    <Container>
      <Card>
        <DeprecatedH1 className="deprecated-mb-3">Custom Fields</DeprecatedH1>
        <DeprecatedH4 className="deprecated-mb-5 gray font-normal">
          Interact with your users&apos; custom fields through Merge&apos;s Unified API.
        </DeprecatedH4>

        <p className="deprecated-mb-5">
          There are two ways to access custom fields and other non-mapped fields within Merge&apos;s
          Unified API:
        </p>

        <ShadedArea className="deprecated-mb-4">
          <Shuffle size={16} color="var(--blue40)" style={{ marginBottom: 12 }} />
          <DeprecatedH4>Field Mapping</DeprecatedH4>
          <p className="small">
            Create custom mappings for any data attribute that isn’t already part of a Merge Common
            Model, including your user’s custom fields.
          </p>
          <p className="small">
            Define mappings for your entire organization or for each Linked Account, configurable by
            either you or your end user.
          </p>
          <Button onClick={() => window.open("", "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
        <ShadedArea>
          <Globe size={16} color="var(--blue40)" style={{ marginBottom: 12 }} />
          <DeprecatedH4>Remote Fields</DeprecatedH4>
          <p className="small">
            Programmatically access all fields not included on Merge Common Models in a standard
            shape, including metadata and custom fields.
          </p>
          <p className="small">
            Remote Fields can also be included as part of POST requests, depending on availability
            from third-party platforms.
          </p>
          <Button onClick={() => window.open("", "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
      </Card>
    </Container>
  );
};

export default CustomFields;
