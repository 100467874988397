import React from "react";
import { useState } from "react";
import classNames from "classnames";
import { Form } from "react-bootstrap";
import { updateBillingPlanAutoPlay } from "../BillingAPIClient";
import { OrganizationBillingPlan } from "../BillingModels";
import { showErrorToast } from "../../../shared-components/Toasts";

type Props = {
  shouldAutoCharge: boolean;
  updateBillingPlan: (billingPlan: OrganizationBillingPlan) => void;
};

const BillingAutoPayToggle = ({ shouldAutoCharge, updateBillingPlan }: Props) => {
  const [autoPayEnabled, setAutoPayEnabled] = useState(shouldAutoCharge);

  const toggleAutoPlay = () => {
    setAutoPayEnabled(!autoPayEnabled);
    updateBillingPlanAutoPlay({
      shouldAutoCharge: !autoPayEnabled,
      onSuccess: (billingPlan: OrganizationBillingPlan) => {
        updateBillingPlan(billingPlan);
      },
      onError: () => {
        setAutoPayEnabled(!autoPayEnabled);
        showErrorToast(
          "We were unable to enable auto-pay at this time. Please try again and if the problem persists contact Merge support.",
        );
      },
    });
  };

  return (
    <Form.Group className="auto-pay">
      <Form.Label
        className={classNames("auto-pay-label", autoPayEnabled && "auto-pay-label-enabled")}
      >
        {autoPayEnabled ? "Autopay Enabled" : "Autopay Disabled"}
      </Form.Label>
      <Form.Check
        key="auto-pay-switch"
        id="auto-pay-switch"
        type="switch"
        defaultChecked={autoPayEnabled}
        onChange={toggleAutoPlay}
      />
    </Form.Group>
  );
};

export default BillingAutoPayToggle;
