import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { WarningCard } from "../../../shared-components/NoticeCards";
import { ArrowLeftRight } from "lucide-react";
import { StatusToggle } from "../../../shared-components/MergeToggles";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import {
  SaveButtonContainer,
  CancelButton,
  SaveButton,
} from "../../../integrations-management/linked-accounts/detail-page/field-mappings/CreateLinkedAccountFieldMappingPage";
import { fetchIntegrationWideFieldMappingOptions } from "../../../../api-client/APIClient";
import { FieldMappingCreationAndEditDict } from "../../../../models/Entities";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";
import { createIntegrationWideFieldMapping } from "../../../../api-client/APIClient";
import {
  NewIntegrationWideFieldMapping,
  EditFieldMappingInstanceProps,
} from "../../../../api-client/APIClient";
import useAppContext from "../../../context/useAppContext";
import { navigateToFieldMappingSettings } from "../../../../router/RouterUtils";
import { FieldMappingSource } from "../../../../constants";
import { editFieldMappingInstance } from "../../../../api-client/APIClient";
import {
  AvailableFieldMappingIntegration,
  FieldMappingTarget,
} from "../../../../../src/models/Entities";
import InfoIconWithTooltip from "../../../shared-components/InfoIconWithTooltip";
import DeprecatedH1 from "../../../../deprecated/DeprecatedH1";

const StyledHeader = styled(DeprecatedH1)`
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
`;

const StyledSubHeader = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 24px;
`;

const StyledCard = styled.div`
  padding: 16px 24px;
  gap: 8px;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 400px;
`;

const CommonModelName = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: "Inter";
  font-style: normal;
`;

const TargetFieldKey = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-top: 9px;
`;

const TargetFieldDescription = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #737982;
  margin-top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocsContainer = styled.p`
  padding: 6px 12px 6px 16px;
  display: inline-block;
  gap: 8px;
  box-shadow: 0px 1px 2px rgba(31, 45, 61, 0.05);
  border-radius: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #075ff7;
  background-color: #f5f8ff;
  margin-top: 24px;
`;

const ToggleContainer = styled.div`
  margin-top: 24px;
`;

const ToggleText = styled.div`
  font-size: 11px;
  font-weight: 400;
`;

type AddOrEditIntegrationWideFieldMappingPageProps = {
  availableIntegrations: AvailableFieldMappingIntegration[];
  fieldMappingTarget: FieldMappingTarget;
  fetchAvailableIntegrations: () => void;
};

type Props = {
  integrationID: string;
};
const AddorEditIntegrationWideFieldMappingPage = ({
  availableIntegrations,
  fieldMappingTarget,
  fetchAvailableIntegrations,
}: AddOrEditIntegrationWideFieldMappingPageProps) => {
  const { integrationID } = useParams<Props>();
  const { user } = useAppContext();
  const history = useHistory();

  const [isLinkedAccountOverridesEnabled, setIsLinkedAccountOverridesEnabled] = useState(false);
  const [fieldMappingOptions, setFieldMappingOptions] =
    useState<FieldMappingCreationAndEditDict[]>();

  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict[]>();

  const selectedIntegration = availableIntegrations.find(
    (availableIntegration: AvailableFieldMappingIntegration) => {
      return availableIntegration.integration_info.id === integrationID;
    },
  );

  useEffect(() => {
    if (selectedIntegration?.field_mapping_info) {
      setIsLinkedAccountOverridesEnabled(
        selectedIntegration.field_mapping_info.enable_linked_account_level_overrides,
      );

      if (fieldMappingOptions) {
        const currentOption = fieldMappingOptions.find(
          (fieldMappingOption) =>
            fieldMappingOption.traversal_path.split(".").toString() ===
            selectedIntegration.field_mapping_info?.field_traversal_path.toString(),
        );
        if (currentOption) {
          setOriginFields([currentOption]);
        }
      }
    }
  }, [selectedIntegration, fieldMappingOptions]);

  useEffect(() => {
    fetchIntegrationWideFieldMappingOptions(
      integrationID,
      fieldMappingTarget.id,
      fieldMappingTarget.common_model_name,
      setFieldMappingOptions,
    );
  }, []);

  const createIntegrationFieldMapping = () => {
    if (originFields?.length == 1) {
      const body: NewIntegrationWideFieldMapping = {
        integration_id: integrationID,
        organization_id: user.organization.id,
        common_model_id: `${fieldMappingTarget.category}.${fieldMappingTarget.common_model_name}`,
        field_key: fieldMappingTarget?.field_key,
        field_traversal_path: originFields[0].traversal_path.split("."),
        create_for_organization: true,
        field_mapping_target_id: fieldMappingTarget.id,
        configured_by: FieldMappingSource.ORGANIZATION,
        api_endpoint_id: originFields[0].api_endpoint_id,
        display_name: originFields[0].display_name,
        origin_type: originFields[0].type,
        enable_linked_account_level_overrides: isLinkedAccountOverridesEnabled,
      };
      createIntegrationWideFieldMapping(body, () => {
        showSuccessToast("Successfully Added Field Mapping");
        fetchAvailableIntegrations();
        navigateToFieldMappingSettings(history, fieldMappingTarget.id);
      });
    } else {
      showErrorToast("Please select an origin field");
    }
  };

  const editIntegrationFieldMapping = () => {
    if (originFields?.length == 1 && selectedIntegration) {
      const body: EditFieldMappingInstanceProps = {
        field_mapping_instance_id: selectedIntegration.field_mapping_info!.id,
        field_traversal_path: originFields[0].traversal_path.split("."),
        api_endpoint_id: originFields[0].api_endpoint_id,
        display_name: originFields[0].display_name,
        origin_type: originFields[0].type,
        enable_linked_account_level_overrides: isLinkedAccountOverridesEnabled,
      };
      editFieldMappingInstance(selectedIntegration.field_mapping_info!.id, body, () => {
        showSuccessToast("Successfully edited Field Mapping");
        fetchAvailableIntegrations();
        navigateToFieldMappingSettings(history, fieldMappingTarget.id);
      });
    } else {
      showErrorToast("Please select an origin field");
    }
  };

  return selectedIntegration ? (
    <div>
      <div className="d-flex flex-column">
        <div className="d-flex">
          <StyledHeader>
            {selectedIntegration?.field_mapping_info ? "Edit" : "Add"} Field Mapping For{" "}
            {selectedIntegration.integration_info.name}
          </StyledHeader>
        </div>
        <div>
          <StyledSubHeader>
            Map a {selectedIntegration.integration_info.name} origin field to your target field.
            This mapping will apply to all of your {selectedIntegration.integration_info.name}{" "}
            Linked Accounts.
          </StyledSubHeader>
        </div>
        <div>
          <WarningCard showsIcon className="deprecated-mt-4">
            Remote Data must be enabled for a given common model to map fields for that model.
          </WarningCard>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <p>Your Merge target field:</p>
            <StyledCard>
              <CommonModelName>{fieldMappingTarget.common_model_name}</CommonModelName>
              <TargetFieldKey>{fieldMappingTarget.field_key}</TargetFieldKey>
              <TargetFieldDescription>
                {fieldMappingTarget.field_description}
              </TargetFieldDescription>
            </StyledCard>
          </div>
          <div className="align-self-center">
            <ArrowLeftRight className="align-items-center" size={18}></ArrowLeftRight>
          </div>
          <div className="d-flex flex-column">
            <p>
              Select the corresponding {selectedIntegration.integration_info.name}
              <strong> origin field</strong>:
            </p>
            <StyledCard>
              <MergeTypeahead
                id="typeahead"
                disabled={false}
                multiple={false}
                options={fieldMappingOptions || []}
                labelKey={(option: FieldMappingCreationAndEditDict) => option?.display_name ?? ""}
                inputProps={{ autoComplete: "none" }}
                isLoading={fieldMappingOptions === undefined}
                selected={originFields}
                placeholder="Search Fields..."
                onChange={(selectedOriginField) => {
                  setOriginFields(selectedOriginField);
                }}
              />
              {selectedIntegration.integration_info.api_documentation_url && (
                <DocsContainer>
                  <a
                    target="_blank"
                    href={selectedIntegration.integration_info.api_documentation_url}
                    rel="noreferrer"
                  >
                    {selectedIntegration.integration_info.name} docs
                  </a>
                </DocsContainer>
              )}
              <ToggleContainer className="d-flex align-items-center">
                <StatusToggle
                  isEnabled={isLinkedAccountOverridesEnabled}
                  onChange={() => {
                    setIsLinkedAccountOverridesEnabled(!isLinkedAccountOverridesEnabled);
                  }}
                  id="toggle"
                  hideSwitch={false}
                />
                <ToggleText>
                  Enable Linked Account-level overrides
                  <InfoIconWithTooltip
                    text="Allow overrides to this Field Mapping in Dashboard and Link for each Linked Account"
                    iconSize={12}
                    iconClassName="deprecated-ml-1 text-gray-50"
                  />
                </ToggleText>
              </ToggleContainer>
            </StyledCard>
          </div>
        </div>
        <SaveButtonContainer className="align-self-end">
          <CancelButton
            size="sm"
            variant="white"
            className="individual-issue-mute-button-align deprecated-mr-3"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </CancelButton>
          <SaveButton
            size="sm"
            variant="black"
            className="individual-issue-mute-button-align"
            onClick={() => {
              selectedIntegration.field_mapping_info
                ? editIntegrationFieldMapping()
                : createIntegrationFieldMapping();
            }}
          >
            Save
          </SaveButton>
        </SaveButtonContainer>
      </div>
    </div>
  ) : (
    <></>
  );
};
export default AddorEditIntegrationWideFieldMappingPage;
