import React, { useState, Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { Schemas } from "../../common-models/CommonModelToggleTypes";
import {
  APICategory,
  FieldMappingCommonModelConfiguration,
  FieldMappingTarget,
  OverriddenCommonModelTarget,
  OverrideModelAndFieldOptions,
} from "../../../../models/Entities";
import { capitalizeFirstLetter } from "../../../../services";
import EmptyStateWrapper from "../../../shared-components/EmptyStateWrapper";
import { deleteFieldMappingTarget } from "./utils/FieldMappingUtils";
import FieldMappingDeletionModal from "./ConfigurationFieldMappingDeletionModal";
import ConfigurationFieldMappingsFieldsList from "./ConfigurationFieldMappingsFieldsList";
import { showErrorToast } from "../../../shared-components/Toasts";
import ConfigurationCommonModelOverridesSection from "./ConfigurationCommonModelOverridesSection";
import useAppContext from "../../../context/useAppContext";
type Props = {
  commonModelOverrideOptions: OverrideModelAndFieldOptions | undefined;
  commonModelOverrideTargets: Array<OverriddenCommonModelTarget> | undefined;
  schemas: Schemas;
  setCommonModelOverrideTargets: Dispatch<
    SetStateAction<OverriddenCommonModelTarget[] | undefined>
  >;
  selectedCategory: APICategory;
  fieldMappingTargets: Array<FieldMappingTarget>;
  commonModelNames: Array<string>;
  searchOptions: Array<string>;
  fieldMappingConfigurations: FieldMappingCommonModelConfiguration[];
  isLoading: boolean;
  selectedSearchOptions: Array<string>;
  updateFieldMappingConfigurations: (
    updatedConfiguration: FieldMappingCommonModelConfiguration,
  ) => void;
  updateFieldMappingTargets: (updateFieldMappingTarget: FieldMappingTarget) => void;
  refreshFieldMappingsAndConfigurations: () => void;
  setSelectedCategory: (category: APICategory) => void;
  setIsLoading: (loadingState: boolean) => void;
  setHasError: (hasError: boolean) => void;
};

const ConfigurationFieldMappingsPage = ({
  commonModelOverrideOptions,
  commonModelOverrideTargets,
  schemas,
  setCommonModelOverrideTargets,
  selectedCategory,
  refreshFieldMappingsAndConfigurations,
  fieldMappingTargets,
  fieldMappingConfigurations,
  selectedSearchOptions,
  commonModelNames,
  updateFieldMappingConfigurations,
  updateFieldMappingTargets,
  isLoading,
  setIsLoading,
  setHasError,
}: Props) => {
  const { user } = useAppContext();
  const isOverrideCommonModelsEnabled = user.is_override_common_models_enabled || false;
  const [fieldMappingTargetToDelete, setFieldMappingTargetToDelete] = useState<
    FieldMappingTarget | undefined
  >();
  const isFieldMappingDeletionModalOpen = fieldMappingTargetToDelete != undefined;
  const filterCommonModelToTargetEntry = (modelName: string, mappings: FieldMappingTarget[]) => {
    if (selectedSearchOptions.length == 0) {
      return modelName;
    } else {
      const split = modelName.split(".");
      const commonModelName = capitalizeFirstLetter(split[1]);
      const fieldKeys = mappings.map((mapping) => mapping.field_key);
      return (
        selectedSearchOptions.includes(commonModelName) ||
        selectedSearchOptions.filter((option) => fieldKeys.includes(option)).length > 0
      );
    }
  };

  return (
    <>
      {fieldMappingTargetToDelete && (
        <FieldMappingDeletionModal
          isModalOpen={isFieldMappingDeletionModalOpen}
          deleteFieldMapping={() =>
            deleteFieldMappingTarget({
              fieldMappingTargetID: fieldMappingTargetToDelete.id,
              onResponse: () => {
                setFieldMappingTargetToDelete(undefined);
                refreshFieldMappingsAndConfigurations();
              },
              onError: () => {
                setFieldMappingTargetToDelete(undefined);
                showErrorToast("Looks like something went wrong, please try again");
              },
            })
          }
          closeModal={() => setFieldMappingTargetToDelete(undefined)}
        />
      )}
      <Row>
        <Col>
          {isLoading ? (
            <EmptyStateWrapper isSpinner />
          ) : (
            <>
              {isOverrideCommonModelsEnabled && (
                <ConfigurationCommonModelOverridesSection
                  commonModelOverrideOptions={commonModelOverrideOptions}
                  commonModelOverrideTargets={commonModelOverrideTargets}
                  schemas={schemas}
                  setCommonModelOverrides={setCommonModelOverrideTargets}
                />
              )}
              <ConfigurationFieldMappingsFieldsList
                key={selectedCategory}
                category={selectedCategory}
                updateFieldMappingConfigurations={updateFieldMappingConfigurations}
                updateFieldMappingTargets={updateFieldMappingTargets}
                fieldMappingsForAllCommonModels={
                  // What we want here is to create a Dict of {common_model_id: [customMappingTargets]}
                  // It is processed a bunch here inside the render function so that there's no weird side effects
                  // We write the custom mapping objects from the server into a Dict that has an empty list for each
                  // Common Model name, and then filter out keys if we have filtering options selected
                  Object.fromEntries(
                    Object.entries(
                      (fieldMappingTargets ?? [])
                        .sort(
                          (a, b) =>
                            b.weight - a.weight ||
                            a.common_model_name.localeCompare(b.common_model_name),
                        )
                        .reduce(
                          (
                            accum: { [common_model_id: string]: FieldMappingTarget[] },
                            mapping_target,
                          ) => {
                            if (commonModelNames.includes(mapping_target.common_model_name)) {
                              if (
                                !(
                                  `${selectedCategory}.${mapping_target.common_model_name}` in accum
                                ) &&
                                mapping_target.common_model_name
                              ) {
                                accum[`${selectedCategory}.${mapping_target.common_model_name}`] =
                                  [];
                              }
                              accum[`${selectedCategory}.${mapping_target.common_model_name}`].push(
                                mapping_target,
                              );
                            }

                            return accum;
                          },
                          commonModelNames.reduce(
                            (
                              acc: { [common_model_id: string]: FieldMappingTarget[] },
                              model_name,
                            ) => {
                              acc[`${selectedCategory}.${model_name}`] = [];
                              return acc;
                            },
                            {},
                          ),
                        ),
                    ).filter(([modelName, mappings]) =>
                      filterCommonModelToTargetEntry(modelName, mappings),
                    ),
                  )
                }
                commonModelsToMappingConfigs={fieldMappingConfigurations
                  .sort((a, b) => a.common_model_name.localeCompare(b.common_model_name))
                  .reduce(
                    (
                      accum: { [common_model_id: string]: FieldMappingCommonModelConfiguration },
                      mapping_target,
                    ) => {
                      accum[`${selectedCategory}.${mapping_target.common_model_name}`] =
                        mapping_target;
                      return accum;
                    },
                    {},
                  )}
                refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
                setIsLoading={setIsLoading}
                setFieldMappingTargetToDelete={setFieldMappingTargetToDelete}
                setHasError={setHasError}
              />
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationFieldMappingsPage;
