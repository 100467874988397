import React from "react";
import styled from "styled-components";

const WrapText = styled.div`
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 200px;
`;

type UserCellProps = {
  name?: string;
  email?: string;
};

const UserCell = ({ name, email }: UserCellProps) => {
  if (!name) {
    return <td>-</td>;
  }

  return (
    <td>
      <WrapText className="text-base font-medium">{name}</WrapText>
      <WrapText className="text-xs text-gray-50">{email}</WrapText>
    </td>
  );
};

export default UserCell;
