import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import IntegrationNameAndLogo from "../IntegrationNameAndLogo";
import { navigateToAddIntegrationFieldMapping } from "../../../../router/RouterUtils";
import { useHistory } from "react-router-dom";
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import DropdownToggle from "../../../shared-components/DropdownToggle";
import { MoreHorizontal } from "lucide-react";
import DeleteFieldMappingModal from "../../../integrations-management/linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal";
import useAppContext from "../../../context/useAppContext";
import {
  EditFieldMappingInstanceProps,
  NewIntegrationWideFieldMapping,
  createIntegrationWideFieldMapping,
  editFieldMappingInstance,
  fetchFieldMappingInstance,
  fetchIntegrationWideFieldMappingOptions,
  fetchWithAuth,
} from "../../../../api-client/APIClient";
import { showSuccessToast } from "../../../shared-components/Toasts";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";
import { Menu, MenuItem, TypeaheadMenu } from "react-bootstrap-typeahead";

import { FieldMappingCreationAndEditDict } from "../../../../models/Entities";
import useOnClickOutside from "use-onclickoutside";
import { FieldMappingSource } from "../../../../constants";
import { StyledDropdownItem } from "./IntegrationFieldMappingsHeader";
import FieldMappingDropdownChild from "./FieldMappingDropdownChild";
import DeleteFieldMappingModal_OLD from "../../../integrations-management/linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal_old";
import { Portal } from "react-portal";

export const AddFieldMappingButton = styled.button`
  background: #f5f8ff;
  color: #697387;
  border-radius: 4px;
  border: none;
  padding: 6px 10px;
`;

export const StyledDash = styled.span`
  color: #737982;
  height: 20px;
`;

const StyledEndpoint = styled.div`
  color: #737982;
`;

export const StyledEndpointV2 = styled.div`
  color: #737982;
  max-width: 220px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: fit-content;
`;

export const TruncatedOriginField = styled.div`
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  width: fit-content;
`;

const StyledText = styled.div`
  color: #1a9b87;
`;

const DisabledText = styled.div`
  color: #495160;
`;

export const StyledTypeaheadDiv = styled.div`
  input {
    height: 34px;
  }
`;

export const StyledMethodDiv = styled.div`
  color: var(--Gray60, #737982);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  border: none !important;
`;

export const FitDiv = styled.div`
  width: fit-content;
`;

export const MinHeightDiv = styled.div`
  min-height: 34px;
  align-items: center;
  display: flex;
`;

const ZIndexPortal = styled(Portal)`
  z-index: 2000;
`;

type FieldMappingByIntegrationRowProps = {
  fieldMappingTargetID: string;
  fieldMappingInstanceId: string | undefined;
  integrationID: string;
  name: string;
  squareImage: string | undefined;
  remoteEndpointPath: string | undefined;
  remoteEndpointMethod: string | undefined;
  originType: string | undefined;
  originField: string | undefined;
  isLinkedAccountOverrideEnabled: boolean | undefined;
  fieldMappingTargetCommonModelName: string;
  fieldMappingTargetCategory: string;
  fieldMappingTargetFieldKey: string;
  updateAvailableIntegrations?: (
    integrationID: string,
    fieldMappingInstanceId: string,
    originField: string,
    remoteEndpointPath: string,
    remoteEndpointMethod: string,
    originType: string,
    isLinkedAccountOverrideEnabled: boolean,
    traversalPath: Array<string>,
  ) => void;
  handleDelete?: () => void;
};

const FieldMappingByIntegrationRow = ({
  fieldMappingTargetID,
  fieldMappingInstanceId,
  integrationID,
  name,
  squareImage,
  remoteEndpointPath,
  remoteEndpointMethod,
  originField,
  originType,
  isLinkedAccountOverrideEnabled,
  fieldMappingTargetCommonModelName,
  fieldMappingTargetCategory,
  fieldMappingTargetFieldKey,
  updateAvailableIntegrations,
  handleDelete,
}: FieldMappingByIntegrationRowProps) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLinkedAccountOverrideEnabledState, setIsLinkedAccountOverridesEnabledState] = useState(
    isLinkedAccountOverrideEnabled,
  );
  const [fieldMappingOptions, setFieldMappingOptions] =
    useState<FieldMappingCreationAndEditDict[]>();
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict[]>();

  const ref = useRef<HTMLDivElement>(null);
  const [isAddingFieldMapping, setIsAddingFieldMapping] = useState(false);
  const { user } = useAppContext();
  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);

  const portalRef = document.getElementById("field-mapping-target-field-settings-page")!;

  useEffect(() => {
    setIsLinkedAccountOverridesEnabledState(isLinkedAccountOverrideEnabled);
  }, [isLinkedAccountOverrideEnabled]);

  const createIntegrationFieldMapping = (currOriginField: FieldMappingCreationAndEditDict) => {
    const body: NewIntegrationWideFieldMapping = {
      integration_id: integrationID,
      organization_id: user.organization.id,
      common_model_id: `${fieldMappingTargetCategory}.${fieldMappingTargetCommonModelName}`,
      field_key: fieldMappingTargetFieldKey,
      field_traversal_path: currOriginField.traversal_path.split("."),
      create_for_organization: true,
      field_mapping_target_id: fieldMappingTargetID,
      configured_by: FieldMappingSource.ORGANIZATION,
      api_endpoint_id: currOriginField.api_endpoint_id,
      display_name: currOriginField.display_name,
      enable_linked_account_level_overrides: isLinkedAccountOverrideEnabledState,
      origin_type: currOriginField.type,
    };
    createIntegrationWideFieldMapping(
      body,
      () => {
        showSuccessToast("Successfully Added Field Mapping");
      },
      (new_instance_id) => {
        fetchFieldMappingInstance(new_instance_id, (data) => {
          updateAvailableIntegrations!(
            integrationID,
            new_instance_id,
            currOriginField.display_name,
            data?.remote_endpoint_path,
            currOriginField.api_endpoint_method,
            currOriginField.type,
            isLinkedAccountOverrideEnabledState ?? false,
            currOriginField.traversal_path.split("."),
          );
          setIsAddingFieldMapping(false);
        });
      },
    );
  };

  const editIntegrationFieldMapping = (currOriginField: FieldMappingCreationAndEditDict) => {
    if (fieldMappingInstanceId) {
      const body: EditFieldMappingInstanceProps = {
        field_mapping_instance_id: fieldMappingInstanceId,
        field_traversal_path: currOriginField.traversal_path.split("."),
        api_endpoint_id: currOriginField.api_endpoint_id,
        display_name: currOriginField.display_name,
        enable_linked_account_level_overrides: isLinkedAccountOverrideEnabledState,
        origin_type: currOriginField.type,
      };
      editFieldMappingInstance(fieldMappingInstanceId, body, () => {
        showSuccessToast("Successfully edited Field Mapping");
        fetchFieldMappingInstance(fieldMappingInstanceId, (data) => {
          updateAvailableIntegrations!(
            integrationID,
            fieldMappingInstanceId,
            currOriginField.display_name,
            data?.remote_endpoint_path,
            currOriginField.api_endpoint_method,
            currOriginField.type,
            isLinkedAccountOverrideEnabledState ?? false,
            currOriginField.traversal_path.split("."),
          );
          setIsAddingFieldMapping(false);
        });
      });
    }
  };

  useEffect(() => {
    const optionallySelected = originField
      ? fieldMappingOptions?.find((option) => option.display_name === originField)
      : null;
    if (optionallySelected != null) {
      setOriginFields([optionallySelected]);
    }
  }, [fieldMappingOptions]);

  const toggleLinkedAccountOverrideEnabledState = () => {
    if (fieldMappingInstanceId) {
      const body: { enable_linked_account_level_overrides: boolean } = {
        enable_linked_account_level_overrides: !isLinkedAccountOverrideEnabledState,
      };
      fetchWithAuth({
        path: `integrations/linked-account/field-mapping-instance/update-linked-account-override/${fieldMappingInstanceId}`,
        body: body,
        method: "PATCH",
        onResponse: () => {
          setIsLinkedAccountOverridesEnabledState(!isLinkedAccountOverrideEnabledState);
          if (originField && remoteEndpointPath) {
            updateAvailableIntegrations!(
              integrationID,
              fieldMappingInstanceId,
              originField,
              remoteEndpointPath,
              remoteEndpointMethod ?? "GET",
              originType ?? "string",
              !(isLinkedAccountOverrideEnabledState ?? false),
              originField.split("."),
            );
          }
        },
      });
    }
  };

  const fieldMappingOptionsLoading = fieldMappingOptions === undefined;

  useOnClickOutside(ref, (event) => {
    if ((event.target as HTMLElement).className !== "custom-control-label") {
      setIsAddingFieldMapping(false);
      if (isLinkedAccountOverrideEnabledState && !originField) {
        toggleLinkedAccountOverrideEnabledState();
      }
      setOriginFields([]);
    }
  });

  return (
    <tr>
      <td>
        <div className="d-flex">
          <IntegrationNameAndLogo
            integration={{
              id: name,
              name: name,
              square_image: squareImage,
            }}
          />
        </div>
      </td>
      <td
        colSpan={isAddingFieldMapping ? 2 : 1}
        width={isAddingFieldMapping ? 470 : 192}
        height={isAddingFieldMapping ? 67 : undefined}
      >
        {isAddingFieldMapping ? (
          <StyledTypeaheadDiv ref={ref}>
            <MergeTypeahead
              id="typeahead"
              disabled={fieldMappingOptionsLoading}
              multiple={false}
              positionFixed
              renderMenuItemChildren={(option) => (
                <FieldMappingDropdownChild
                  originField={option.display_name}
                  originType={option.type}
                  remoteEndpointMethod={option.api_endpoint_method ?? "GET"}
                  remoteEndpointPath={option.api_endpoint_path ?? "/"}
                />
              )}
              options={fieldMappingOptions || []}
              includeChevronDown={!fieldMappingOptionsLoading}
              inputProps={{ autoComplete: "none" }}
              placeholder={"Search fields..."}
              selected={originFields}
              onChange={(selectedOriginFields) => {
                if (selectedOriginFields.length == 1) {
                  if (originField != null) {
                    // edit field
                    editIntegrationFieldMapping(selectedOriginFields[0]);
                  } else {
                    // create field
                    createIntegrationFieldMapping(selectedOriginFields[0]);
                  }
                } else {
                  setOriginFields(selectedOriginFields);
                }
              }}
              labelKey={(option: FieldMappingCreationAndEditDict) => option?.display_name ?? ""}
            />
          </StyledTypeaheadDiv>
        ) : originField ? (
          isFieldMappingV2Enabled ? (
            <MinHeightDiv>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{originField}</Tooltip>}
              >
                <TruncatedOriginField>{originField}</TruncatedOriginField>
              </OverlayTrigger>
            </MinHeightDiv>
          ) : (
            <>{originField}</>
          )
        ) : (
          <AddFieldMappingButton
            onClick={() => {
              if (isFieldMappingV2Enabled) {
                fetchIntegrationWideFieldMappingOptions(
                  integrationID,
                  fieldMappingTargetID,
                  fieldMappingTargetCommonModelName,
                  setFieldMappingOptions,
                );
                setIsAddingFieldMapping(true);
              } else {
                navigateToAddIntegrationFieldMapping(history, fieldMappingTargetID, integrationID);
              }
            }}
          >
            + Field Mapping
          </AddFieldMappingButton>
        )}
      </td>
      {!isAddingFieldMapping && (
        <td width={278}>
          {remoteEndpointPath ? (
            isFieldMappingV2Enabled ? (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">{remoteEndpointPath}</Tooltip>}
              >
                <FitDiv className="d-flex">
                  <StyledMethodDiv className="mr-1.5">
                    {" "}
                    {remoteEndpointMethod ?? "GET"}
                  </StyledMethodDiv>
                  <StyledEndpointV2>{remoteEndpointPath}</StyledEndpointV2>
                </FitDiv>
              </OverlayTrigger>
            ) : (
              <StyledEndpoint>{remoteEndpointPath}</StyledEndpoint>
            )
          ) : (
            <div className="text-gray-40">—</div>
          )}
        </td>
      )}

      <td>
        {originField || isAddingFieldMapping ? (
          isFieldMappingV2Enabled ? (
            <div className="field-mapping-overrides-toggle">
              <Form.Check
                type="switch"
                id={`field-mapping-overrides-toggle-for` + fieldMappingInstanceId}
                checked={isLinkedAccountOverrideEnabledState}
                className="small"
                onClick={toggleLinkedAccountOverrideEnabledState}
              />
            </div>
          ) : isLinkedAccountOverrideEnabled ? (
            <StyledText>Enabled</StyledText>
          ) : (
            <DisabledText>Disabled</DisabledText>
          )
        ) : (
          <div className="text-gray-40">—</div>
        )}
      </td>
      {originField ? (
        <td>
          {isFieldMappingV2Enabled ? (
            <DeleteFieldMappingModal
              showModal={showDeleteModal}
              fieldMappingID={fieldMappingInstanceId!}
              headerText={"Delete " + name + " Mapping"}
              onHide={() => {
                setShowDeleteModal(false);
              }}
              handlePostDelete={handleDelete}
              text={"This will delete all Field Mappings to this " + name + " origin field."}
              secondaryText={"Mapping overrides will not be deleted."}
            />
          ) : (
            <DeleteFieldMappingModal_OLD
              showModal={showDeleteModal}
              fieldMappingID={fieldMappingInstanceId!}
              onHide={() => {
                setShowDeleteModal(false);
              }}
              handlePostDelete={handleDelete}
              text={"This will delete all Field Mappings associated with this target field."}
            />
          )}
          <Dropdown>
            {isFieldMappingV2Enabled ? (
              <StyledDropdownToggle variant="white" size="sm" id="dropdown-custom-components">
                <MoreHorizontal size={16} />
              </StyledDropdownToggle>
            ) : (
              <DropdownToggle
                variant="white"
                className={"deprecated-mr-3"}
                size="sm"
                id="dropdown-custom-components"
              >
                <MoreHorizontal size={16} />
              </DropdownToggle>
            )}
            <ZIndexPortal node={portalRef}>
              <Dropdown.Menu align="right" className="dropdown-ellipses dropdown-toggle">
                {isFieldMappingV2Enabled ? (
                  <StyledDropdownItem
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => {
                      fetchIntegrationWideFieldMappingOptions(
                        integrationID,
                        fieldMappingTargetID,
                        fieldMappingTargetCommonModelName,
                        setFieldMappingOptions,
                      );
                      setIsAddingFieldMapping(true);
                    }}
                  >
                    Edit Mapping
                  </StyledDropdownItem>
                ) : (
                  <Dropdown.Item
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => {
                      navigateToAddIntegrationFieldMapping(
                        history,
                        fieldMappingTargetID,
                        integrationID,
                      );
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                )}
                {isFieldMappingV2Enabled ? (
                  <StyledDropdownItem
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete Mapping
                  </StyledDropdownItem>
                ) : (
                  <Dropdown.Item
                    className="ellipses-dropdown-item dropdown-item"
                    onSelect={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </ZIndexPortal>
          </Dropdown>
        </td>
      ) : (
        <td></td>
      )}
    </tr>
  );
};

export default FieldMappingByIntegrationRow;
