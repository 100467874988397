import classNames from "classnames";
import { Info } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import {
  CommonModelSyncStatus,
  LinkedAccount,
  LinkedAccountStatus,
  ManualSyncCreditPlans,
  PauseReason,
  SyncFrequencyPlan,
} from "../../../../../models/Entities";
import { spectrum } from "../../../../../styles/theme";
import useAppContext from "../../../../context/useAppContext";
import { PricingWarningBanner } from "../../../../shared-components/Banners";
import useInterval from "../../../../shared-components/hooks/useInterval";
import useTimeout from "../../../../shared-components/hooks/useTimeout";
import { showErrorToast, showSuccessToast } from "../../../../shared-components/Toasts";
import { formatDateToShortMonthDayYear } from "../../../../shared-components/utils/SharedComponentUtils";
import Stack from "../../shared/Stack";
import SyncHistoryTable from "./SyncHistoryTable";
import { UseSyncCreditModal } from "./UseSyncCreditModal";
import { Schemas } from "../../../../configuration/common-models/CommonModelToggleTypes";
import UpsellModal from "../../../../shared-components/UpsellModal";
import { ReactComponent as FDScopes } from "../../../../../assets/svg/feature-discovery-scopes.svg";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";

const SyncCreditsBanner = styled.div`
  background: ${spectrum.slate0};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px;
  gap: 60px;
`;

const SyncCreditsTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${spectrum.blue40};
`;

const SyncCreditsSubtitle = styled.div`
  font-size: 10px;
  line-height: 18px;
  color: ${spectrum.gray60};
`;

const Tooltip300pxWidth = styled(Tooltip)`
  & {
    .tooltip-inner {
      max-width: 300px;
    }
  }
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 32px;
`;
export interface Props {
  /**
   * If the linked account is null, use this to fetch data
   */
  id: string;

  /**
   * The account we have linked or nothing if we have only an id
   */
  linkedAccount: LinkedAccount | null;
  includeLinkedAccountScopes?: boolean;
  schemas?: Schemas | null;
}
const StyledPricingWarning = styled(PricingWarningBanner)`
  margin-top: 24px;
`;

const PageTitle = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 12px;
`;
/**
 * Shows a header + resync button for sync history. Resync just shortcircuits the
 * interval to fetch data now.
 */
const SyncHistory = ({
  id,
  linkedAccount,
  includeLinkedAccountScopes = false,
  schemas = null,
}: Props) => {
  const { user } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const [syncStatuses, setSyncStatuses] = useState<CommonModelSyncStatus[] | null>(null);
  const [isResyncing, setIsResyncing] = useState(false);
  const [isResyncingVisuallyOnly, setIsResyncingVisuallyOnly] = useState(false);
  const [showUseSyncCreditModal, setShowSyncCreditModal] = useState(false);
  const isOrganizationOnManualSyncPlan = useMemo(
    () =>
      (ManualSyncCreditPlans as (string | undefined)[]).includes(
        linkedAccount?.sync_frequency_plan,
      ),
    [linkedAccount],
  );

  const location = useLocation();
  const isOverviewPage = location.pathname.includes("overview");

  const MODAL_TOP_VALUE = "-4px";
  const MODAL_WIDTH_VALUE = "450px";
  /**
   * For the account id, fetch sync status with support for cancellation.
   */

  useEffect(() => {
    if (linkedAccount) {
      fetchSyncStatusForSelectedLinkedAccount();
    }
  }, [linkedAccount]);

  const fetchSyncStatusForSelectedLinkedAccount = () => {
    let isCancelled = false;
    fetchWithAuth({
      path: `/integrations/linked-accounts/${id}/sync-status`,
      method: "GET",
      onResponse: (data: CommonModelSyncStatus[]) => {
        if (isCancelled) {
          return;
        }
        setSyncStatuses(data);
      },
    });
    return () => {
      isCancelled = true;
    };
  };

  // Forces a resync of the data
  const forceResync = () => {
    if (!linkedAccount || !linkedAccount.completed_at) {
      setIsResyncing(false);
      return;
    }
    let isCancelled = false;
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}/force-resync`,
      method: "POST",
      onResponse: (data: CommonModelSyncStatus[]) => {
        if (isCancelled) {
          return;
        }
        if (linkedAccount.remaining_sync_credits !== undefined) {
          // Update FE status of sync credits, BE is source of truth
          linkedAccount.remaining_sync_credits--;
        }
        setIsResyncing(false);
        setSyncStatuses(data);
        showSuccessToast(
          `Resync queued for ${linkedAccount.end_user.organization_name} integration with ${linkedAccount.integration.name}.`,
        );

        if (user.is_demo) {
          setTimeout(() => {
            fetchSyncStatusForSelectedLinkedAccount();
          }, 2500);
        }
      },
      onError: () => {
        if (isCancelled) {
          return;
        }
        setIsResyncing(false);
        showErrorToast("Failed to force resync. Please try again.");
      },
    });
    return () => {
      isCancelled = true;
    };
  };

  // Polls for new sync statuses every 3 seconds if non-demo user. Needs to be inside an anon function
  useInterval(
    () => {
      if (syncStatuses != null) {
        fetchSyncStatusForSelectedLinkedAccount();
      }
    },
    user.is_demo ? null : 3000,
  );

  /**
   * This is funny - on a fast network, the resync is so fast there's no visual feedback,
   * so we use this to keep it spinning for just under a second for the user to see
   * one rotation of the animation when they click resync all. There's a visually
   * only state, and an actually syncing state to keep track of both.
   */
  useTimeout(
    () => {
      setIsResyncingVisuallyOnly(false);
    },
    isResyncingVisuallyOnly ? 990 : null,
  );

  // Fetches data on demand - the button spins at least for a second, or until the network call is finished
  const resyncButton = isOrganizationOnManualSyncPlan ? (
    <SyncCreditsBanner>
      <div>
        <SyncCreditsTitle>
          {linkedAccount?.remaining_sync_credits} of {linkedAccount?.total_sync_credits} Sync
          Credits Remaining
        </SyncCreditsTitle>
        <SyncCreditsSubtitle>
          <>
            Renews {formatDateToShortMonthDayYear(linkedAccount?.sync_credits_refresh_timestamp)}{" "}
            <OverlayTrigger
              overlay={
                <Tooltip300pxWidth id="resync-tooltip" className="resync-tooltip">
                  {linkedAccount?.sync_frequency_plan ===
                  SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_MONTHLY
                    ? "Your monthly data sync plan grants you 16 sync credits per year per Linked Account. Credits will only be spent if the sync was successful."
                    : "Your quarterly data sync plan grants you 6 sync credits per year per Linked Account. Credits will only be spent if the sync was successful."}
                </Tooltip300pxWidth>
              }
            >
              <Info size={10} />
            </OverlayTrigger>
          </>
        </SyncCreditsSubtitle>
      </div>
      <SmallWhiteButton
        disabled={
          !linkedAccount ||
          !linkedAccount.completed_at ||
          (linkedAccount.remaining_sync_credits ?? 0) <= 0
        }
        onClick={() => setShowSyncCreditModal(true)}
        leftIcon={
          <RefreshCw
            className={classNames("m-0 p-0", { rotating: isResyncingVisuallyOnly || isResyncing })}
            size={12}
          />
        }
      >
        Resync all
      </SmallWhiteButton>
    </SyncCreditsBanner>
  ) : (
    <SmallWhiteButton
      disabled={!linkedAccount || !linkedAccount.completed_at}
      onClick={() => {
        setIsResyncing(true);
        setIsResyncingVisuallyOnly(true);
        forceResync();
      }}
      leftIcon={
        <RefreshCw
          className={classNames("m-0 p-0", { rotating: isResyncingVisuallyOnly || isResyncing })}
          size={12}
        />
      }
    >
      Resync all
    </SmallWhiteButton>
  );

  const [showUpsellModal, setShowUpsellModal] = useState(false);

  return (
    <>
      <div className="flex flex-col">
        {!isOverviewPage && (
          <div className="flex align-items-center">
            <div className="flex-grow">
              {showUpsellModal && (
                <UpsellModal
                  modalTitle="Linked Account Scopes"
                  customImage={
                    <div className="flex justify-center mb-6">
                      <FDScopes className="object-contain" />
                    </div>
                  }
                  modalBodyText={
                    <>
                      <p>
                        Enhanced control to disable models or fields, only sync the data that you
                        need for each specific Linked Account.
                      </p>
                      <div className="text-sm text-gray-50 mt-3 mb-6">
                        Unlock this premium feature with our Professional or Enterprise plans.
                      </div>
                    </>
                  }
                  includeLearnMoreButton={false}
                  includeModalCloseOption={true}
                  showModal={showUpsellModal}
                  setShowModal={setShowUpsellModal}
                  modalTopValue={MODAL_TOP_VALUE}
                  modalWidthValue={MODAL_WIDTH_VALUE}
                  upsellButtonId="upsell_button_linked_account_scopes"
                  learnMoreButtonId="learn_more_button_linked_account_scopes"
                />
              )}
              <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
                <PageTitle className="mt-3">Data Sync</PageTitle>
                <Subtitle>
                  View and configure data sync and access settings for this Linked Account.
                  {/* are_toggles_enabled is the product restriction for scopes */}
                  {!productRestrictions?.are_toggles_enabled && (
                    <a
                      href="#"
                      onClick={() => {
                        setShowUpsellModal(true);
                      }}
                    >
                      {" "}
                      Customize scopes for this Linked Account.
                    </a>
                  )}
                </Subtitle>
              </div>
            </div>
            {resyncButton}
          </div>
        )}
        <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
          {linkedAccount?.status === LinkedAccountStatus.INCOMPLETE &&
            linkedAccount.pause_reason === PauseReason.PRICING_PLAN_LIMIT && (
              <StyledPricingWarning>
                Syncing for this account has been paused. Please upgrade your plan to continue
                syncing.
              </StyledPricingWarning>
            )}
          {linkedAccount?.status === LinkedAccountStatus.COMPLETE &&
            linkedAccount.pause_reason === PauseReason.PRICING_PLAN_LIMIT && (
              <>
                <StyledPricingWarning>
                  This account is linked but not syncing. Please upgrade your plan to continue
                  syncing.
                </StyledPricingWarning>
                <SyncHistoryTable
                  linkedAccount={linkedAccount}
                  syncStatuses={syncStatuses}
                  ignoreDemoData={false}
                  isOverviewPage={isOverviewPage}
                  includeLinkedAccountScopes={includeLinkedAccountScopes}
                  schemas={schemas}
                />
              </>
            )}
          {linkedAccount?.pause_reason !== PauseReason.PRICING_PLAN_LIMIT && (
            <SyncHistoryTable
              linkedAccount={linkedAccount}
              syncStatuses={syncStatuses}
              ignoreDemoData={false}
              resyncButton={resyncButton}
              isOverviewPage={isOverviewPage}
              includeLinkedAccountScopes={includeLinkedAccountScopes}
              schemas={schemas}
            />
          )}
        </div>
      </div>
      {showUseSyncCreditModal && (
        <UseSyncCreditModal
          onClickMethod={() => {
            setIsResyncing(true);
            setIsResyncingVisuallyOnly(true);
            forceResync();
            setShowSyncCreditModal(false);
          }}
          isShown={showUseSyncCreditModal}
          onHide={() => setShowSyncCreditModal(false)}
        />
      )}
    </>
  );
};

export default SyncHistory;
