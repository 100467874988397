import React, { useState, Dispatch, SetStateAction } from "react";
import { Box, Plus } from "lucide-react";
import ConfigurationCommonModelOverrideTargetRow from "./ConfigurationCommonModelOverrideTargetRow";
import MergeTypeahead from "../../../shared-components/MergeTypeahead";
import { Button, SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import CommonModelFieldDropdown from "../../../integrations-management/linked-accounts/detail-page/field-mappings/common-model-overrides/CommonModelFieldDropdown";
import { createOrgWideCommonModelOverrideTarget } from "./utils/FieldMappingUtils";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import {
  OverriddenCommonModelTarget,
  OverrideModelAndFieldOptions,
  MergeTypeaheadOption,
  FieldDefinition,
} from "../../../../models/Entities";
import { Schemas } from "../../common-models/CommonModelToggleTypes";
const getModelsForOverrides = (commonModelOverrideOptions: OverrideModelAndFieldOptions) => {
  return Object.keys(commonModelOverrideOptions).map((commonModelOverrideOption) => {
    return {
      id: commonModelOverrideOption,
      label: commonModelOverrideOptions[commonModelOverrideOption]["common_model_name"],
    };
  });
};

export const getFieldsWithExistingTargets = (
  commonModelOverrideTargets: OverriddenCommonModelTarget[],
  selectedModel: string,
) => {
  const fieldsWithExistingTargets = new Set();
  commonModelOverrideTargets.forEach((target) => {
    if (target.common_model_name == selectedModel) {
      fieldsWithExistingTargets.add(target.overridden_field);
    }
  });
  return fieldsWithExistingTargets;
};

const getSelectedModelAvailableFields = (
  selectedModel: MergeTypeaheadOption,
  commonModelOverrideOptions: OverrideModelAndFieldOptions,
  schemas: Schemas,
  fieldsWithExistingTargets: Set<string>,
) => {
  const commonModelID = selectedModel.id;
  const commonModelName = selectedModel.label;
  const modelSchemas = schemas[commonModelName];
  const availableFields = commonModelOverrideOptions[commonModelID]["available_fields"];
  return availableFields.reduce((acc: any, modelField: string) => {
    if (fieldsWithExistingTargets.has(modelField)) {
      return acc;
    }
    const parameters = modelSchemas?.parameters! || {};
    const isFieldInfoAvailable = modelField in parameters ? true : false;
    const fieldInfo = {
      description: isFieldInfoAvailable ? parameters[modelField].description : undefined,
      type: isFieldInfoAvailable ? parameters[modelField].type : undefined,
    };
    acc.push({
      title: modelField,
      type: fieldInfo.type,
      description: fieldInfo.description,
    });
    return acc;
  }, []);
};

type ConfigurationCommonModelOverridesSectionProps = {
  commonModelOverrideOptions: OverrideModelAndFieldOptions | undefined;
  commonModelOverrideTargets: OverriddenCommonModelTarget[] | undefined;
  schemas: Schemas;
  setCommonModelOverrides: Dispatch<SetStateAction<OverriddenCommonModelTarget[] | undefined>>;
};
const ConfigurationCommonModelOverridesSection = ({
  commonModelOverrideOptions,
  commonModelOverrideTargets,
  schemas,
  setCommonModelOverrides,
}: ConfigurationCommonModelOverridesSectionProps) => {
  const [isAddingTarget, setIsAddingTarget] = useState(false);
  const [selectedModel, setSelectedModel] = useState<MergeTypeaheadOption | undefined>(undefined);
  const [selectedField, setSelectedField] = useState<FieldDefinition | undefined>(undefined);

  const fieldsWithExistingTargets =
    selectedModel && commonModelOverrideTargets
      ? getFieldsWithExistingTargets(commonModelOverrideTargets, selectedModel.label)
      : new Set();

  const onTargetSaveSuccess = (data: any) => {
    setSelectedModel(undefined);
    setSelectedField(undefined);
    setIsAddingTarget(false);
    setCommonModelOverrides((prevOverrides) => {
      if (prevOverrides) {
        return [...prevOverrides, data];
      }
      return undefined;
    });
    showSuccessToast("Successfully created Common Model override Target");
  };
  return (
    <div className="d-flex flex-column mt-6 shadow-md rounded-lg">
      <div className="h-2 rounded-t-lg bg-blue-10" />
      <div className="rounded-b-xl bg-white">
        <div className="d-flex items-center border-b border-gray-10 text-base font-semibold pl-6 pr-6 pt-3 pb-5">
          <Box size={16} className="text-blue-40" />
          <div className="ml-2 text-[16px]">Common Model overrides</div>
        </div>
        <div className="d-flex flex-column">
          {commonModelOverrideTargets &&
            commonModelOverrideTargets.map(
              (commonModelOverrideTarget: OverriddenCommonModelTarget) => {
                return (
                  <ConfigurationCommonModelOverrideTargetRow
                    commonModelOverrideTarget={commonModelOverrideTarget}
                  />
                );
              },
            )}
        </div>
        {isAddingTarget && commonModelOverrideOptions && (
          <div className="pl-6 pr-6 pt-5">
            <div className="mb-5">
              <MergeTypeahead
                options={getModelsForOverrides(commonModelOverrideOptions)}
                multiple={false}
                placeholder="Select Model.."
                onChange={(selectedModel) => {
                  if (selectedModel.length > 0) {
                    setSelectedModel(selectedModel[0]);
                  } else {
                    setSelectedModel(undefined);
                  }
                }}
                renderMenuItemChildren={(commonModelOption) => {
                  return (
                    <div className="font-mono text-gray-90 font-medium ">
                      {commonModelOption.label}
                    </div>
                  );
                }}
              />
            </div>
            <div>
              <MergeTypeahead
                multiple={false}
                options={
                  selectedModel
                    ? getSelectedModelAvailableFields(
                        selectedModel,
                        commonModelOverrideOptions,
                        schemas,
                        fieldsWithExistingTargets as Set<string>,
                      )
                    : []
                }
                placeholder="Select Field..."
                onChange={(selectedField) => {
                  if (selectedField.length > 0) {
                    setSelectedField(selectedField[0]);
                  } else {
                    setSelectedField(undefined);
                  }
                }}
                labelKey={(field) => field.title}
                renderMenuItemChildren={(fieldOption: FieldDefinition) => {
                  return (
                    <CommonModelFieldDropdown
                      fieldName={fieldOption.title}
                      fieldType={fieldOption.type}
                      fieldDescription={fieldOption.description}
                    />
                  );
                }}
              />
            </div>
          </div>
        )}
        <div className="pl-6 pr-6 pt-5 pb-5">
          {isAddingTarget ? (
            <div className="d-flex items-center">
              <SmallWhiteButton
                disabled={!selectedModel || !selectedField}
                onClick={() => {
                  if (selectedModel && selectedField) {
                    const postBody = {
                      common_model_id: selectedModel.id,
                      overridden_field: selectedField.title,
                    };
                    createOrgWideCommonModelOverrideTarget(postBody, onTargetSaveSuccess, () => {
                      showErrorToast("Unable to save common model override target");
                    });
                  }
                }}
              >
                Save
              </SmallWhiteButton>
              <div
                className="ml-5 font-semibold text-sm cursor-pointer"
                onClick={() => {
                  setIsAddingTarget(false);
                }}
              >
                Cancel
              </div>
            </div>
          ) : (
            <Button
              size="sm"
              color="white"
              variant="plus"
              onClick={() => {
                setIsAddingTarget(true);
              }}
            >
              <span className="text-[12px]">Common Model field</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationCommonModelOverridesSection;
