import { useMergeLink } from "@mergeapi/react-merge-link";
import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { getTenantConfigForMergeLink } from "../../../integrations-management/linked-accounts/AddTestLinkedAccountButton";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  onNewLinkedAccount: () => void;
}

const StyledButton = styled(Button)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  padding: 12px 16px;
  background: #075ff7;
  box-shadow: 0px 1px 2px rgba(31, 45, 61, 0.05);
  border-radius: 6px;
  margin-bottom: 23px;
`;

const CustomAddTestLinkedAccountButton = ({ linkToken, onNewLinkedAccount }: Props) => {
  const [wasSuccess, setWasSuccess] = useState(false);

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => setWasSuccess(true),
    onExit: () => wasSuccess && onNewLinkedAccount(),
    ...getTenantConfigForMergeLink(),
    filePickerConfig: {
      onSubmit: () => {},
      allowMultiSelect: true,
    },
  });

  return (
    <StyledButton
      disabled={!isReady}
      onClick={() => {
        setWasSuccess(false);
        open();
      }}
    >
      Open Merge Link
    </StyledButton>
  );
};

export default CustomAddTestLinkedAccountButton;
