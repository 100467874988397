import React from "react";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";
import Select from "react-select";
import { palette } from "../../../../../styles/theme/colors";

const StyledSpinner = styled(Spinner)`
  color: ${palette.gray};
  margin-left: 14px;
  margin-right: 10px;
`;

const LoadingMessage = styled.div`
  color: ${palette.slate};
  font-weight: 400;
  font-size: 14px;
`;

type SelectiveSyncOption = {
  display_value: string;
  value: string;
};

type SelectiveSyncTypaheadProps = {
  options: SelectiveSyncOption[];
  styles?: React.CSSProperties;
  getOptionLabel?: () => string;
  onSelect: (option: any) => void;
  value: SelectiveSyncOption | undefined;
  isLoading: boolean;
  loadingMessage?: string;
};

const SelectiveSyncTypahead = ({
  loadingMessage,
  options,
  styles,
  onSelect,
  value,
  isLoading,
}: SelectiveSyncTypaheadProps) => {
  return (
    <>
      <Select
        value={value}
        isLoading={isLoading}
        onChange={onSelect}
        getOptionLabel={(option: any) => option.display_value}
        options={options}
        components={{
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
          LoadingMessage: () => (
            <div className="d-flex align-items-center deprecated-p-2">
              <StyledSpinner animation="border" size="sm" />
              <LoadingMessage className="flex-grow-1">
                {loadingMessage || "Loading..."}
              </LoadingMessage>
            </div>
          ),
        }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            ...styles,
            border: 0,
            boxShadow: "0 !important",
          }),
        }}
      />
    </>
  );
};

export default SelectiveSyncTypahead;
