import classNames from "classnames";
import React, { useContext } from "react";
import {
  AccordionContext,
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  OverlayTriggerProps,
  Row,
  Tooltip,
  useAccordionToggle,
} from "react-bootstrap";
import styled from "styled-components";
import { IntegrationIssue } from "../integrations-management/IntegrationsManagementEntities";
import ClickableContainer from "./ClickableContainer";
import { HeaderPretitle } from "./MergeText";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH3 from "../../deprecated/DeprecatedH3";
import { CLICKABLE } from "../integrations-management/linked-accounts/detail-page/selective-sync/SelectiveSyncStyles";

export const EllipsesToggle = React.forwardRef(
  (
    {
      children,
      onClick,
    }: {
      children: React.ReactNode;
      onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    },
    ref: React.Ref<HTMLSpanElement>,
  ) => (
    <ClickableContainer>
      <span
        className="dropdown-ellipses dropdown-toggle"
        ref={ref}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onClick(event);
        }}
      >
        {children}
      </span>
    </ClickableContainer>
  ),
);

type NavBarToggleProps = {
  children: React.ReactNode;
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const NavBarToggle = ({ children, eventKey, callback }: NavBarToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <span
      className={isCurrentEventKey ? "nav-link accordion-show" : "nav-link accordion-collapse"}
      onClick={decoratedOnClick}
    >
      {children}
    </span>
  );
};

type CardHeaderToggleProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
  category: string;
  enabledIntegrationsCount: number;
  categoryIntegrationsCount: number;
};

export const CardHeaderToggle = ({
  eventKey,
  callback,
  category,
  enabledIntegrationsCount,
  categoryIntegrationsCount,
}: CardHeaderToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header className="clickable-card" onClick={decoratedOnClick}>
      <Row>
        <Col>
          <DeprecatedH3 className="deprecated-mb-1 deprecated-ml-1">
            {category.toUpperCase()}
          </DeprecatedH3>
        </Col>
        <Col className="text-right text-gray-50 small">
          {enabledIntegrationsCount == categoryIntegrationsCount
            ? "All"
            : enabledIntegrationsCount == 0
            ? "0"
            : enabledIntegrationsCount}{" "}
          enabled
        </Col>
        <Col className="col-auto pl-0 text-gray-50 small">
          <span
            className={classNames(isCurrentEventKey ? "accordion-show" : "accordion-collapse")}
          />
        </Col>
      </Row>
    </Card.Header>
  );
};

type PlusMinusToggleProps = {
  children: React.ReactNode;
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const PlusMinusToggle = ({ children, eventKey, callback }: PlusMinusToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ClickableContainer>
      <Row onClick={decoratedOnClick}>
        <Col>
          <HeaderPretitle>{children}</HeaderPretitle>
        </Col>
        <Col className="col-auto">
          {isCurrentEventKey ? <span className="fe fe-minus" /> : <span className="fe fe-plus" />}
        </Col>
      </Row>
    </ClickableContainer>
  );
};

type SettingsButtonToggleProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
};

export const SettingsButtonToggle = ({ eventKey, callback }: SettingsButtonToggleProps) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <ClickableContainer>
      {isCurrentEventKey ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Collapse settings</Tooltip>}
        >
          <span className="fe fe-minus" onClick={decoratedOnClick} />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">View settings</Tooltip>}>
          <span className="fe fe-plus" onClick={decoratedOnClick} />
        </OverlayTrigger>
      )}
    </ClickableContainer>
  );
};

type IssueErrorDetailsProps = {
  callback?: (eventKey: string) => void;
  eventKey: string;
  integrationIssue: IntegrationIssue;
  issueDescription: string;
};
export const IssueErrorDetailToggle = ({
  eventKey,
  callback,
  integrationIssue,
  issueDescription,
}: IssueErrorDetailsProps) => {
  const currentEventKey = useContext(AccordionContext);
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header key={integrationIssue.id} className="mb-0 issue-detail-header">
      <Row>
        <Col className="deprecated-pt-2">
          <DeprecatedH2 className="mb-0">Description</DeprecatedH2>
          <p className="mb-0 deprecated-mt-2">{issueDescription}</p>
        </Col>
        <Col className="col-auto d-flex align-items-center">
          <Button variant="link" onClick={decoratedOnClick}>
            <DeprecatedH2 className="mb-0 gray">
              <span
                className={classNames(
                  isCurrentEventKey ? "recommendation-show" : "recommendation-collapse",
                )}
              />
            </DeprecatedH2>
          </Button>
        </Col>
      </Row>
    </Card.Header>
  );
};

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
`;

const StatusText = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-right: 24px;
`;

const Faded = styled.div`
  opacity: 0.5;
  transition: opacity 0.3s;
`;

type StatusToggleProps = {
  /**
   * Whether the toggle is currently set to a truthy status or not.
   */
  isEnabled: boolean;

  /**
   * Callback for handling status change.
   */
  onChange: (newIsEnabled: boolean) => void;

  /**
   * HTML id attribute of the element to create.
   */
  id: string;

  /**
   * `className` will be passed as a prop to the top level div in this component.
   */
  className?: string;

  /**
   * Will be added to the className for the status text component.
   * Useful for overriding margins or padding, eg "deprecated-mr-3" or "px-2".
   */
  statusTextClassName?: string;

  /**
   * Style to apply to the status text component. Useful for setting font size.
   */
  statusTextStyle?: React.CSSProperties;

  /**
   * Whether or not the switch component should be disabled.
   */
  disableSwitch?: boolean;

  /**
   * If true, hide the switch compoenent but keep the status text unmoved.
   */
  hideSwitch?: boolean;

  /**
   * If present, show `errorText` in red instead of showing status.
   */
  errorText?: string;

  /**
   * If present, show tooltip when hovering over the toggle switch.
   */
  tooltip?: OverlayTriggerProps["overlay"];
  /**
   * If present, show toggle but hide status text.
   */
  hideStatusText?: boolean;
};
export const StatusToggle = ({
  id,
  isEnabled,
  onChange,
  className,
  statusTextClassName,
  statusTextStyle,
  disableSwitch,
  hideSwitch,
  errorText,
  tooltip,
  hideStatusText = false,
}: StatusToggleProps) => {
  // When disableSwitch=true, disable the "onClick" function to effectively disable the toggle.
  const formSwitch = !disableSwitch ? (
    <Form>
      <Form.Check
        type="switch"
        id={`${id}-switch`}
        checked={isEnabled}
        onClick={() => onChange(!isEnabled)}
      />
    </Form>
  ) : (
    <Faded>
      <Form>
        <Form.Check type="switch" id={`${id}-switch`} checked={isEnabled} />
      </Form>
    </Faded>
  );
  return (
    <StatusRow className={className}>
      {!hideStatusText && (
        <div>
          {errorText ? (
            <StatusText className={classNames("red font-medium", statusTextClassName)}>
              <StatusText
                className="deprecated-mr-1 small fe fe-alert-circle red"
                style={statusTextStyle}
              />
              {errorText}
            </StatusText>
          ) : (
            <StatusText
              className={classNames(
                `${isEnabled ? "text-green" : "text-gray-60"} font-semibold`,
                statusTextClassName,
              )}
              style={statusTextStyle}
            >
              {isEnabled ? "Enabled" : "Disabled"}
            </StatusText>
          )}
        </div>
      )}
      <div style={{ minWidth: "56px" }}>
        {!hideSwitch &&
          (tooltip ? (
            <OverlayTrigger placement="top" delay={{ show: 100, hide: 0 }} overlay={tooltip!}>
              {formSwitch}
            </OverlayTrigger>
          ) : (
            formSwitch
          ))}
      </div>
    </StatusRow>
  );
};

type StyledClickableRowProps = {
  isClickable: boolean;
};
const StyledClickableRow = styled.tr<StyledClickableRowProps>`
  ${(props) => {
    if (props.isClickable) {
      return CLICKABLE;
    }
    return;
  }}
`;

type ScopesFieldToggle = {
  children: React.ReactNode;
  isClickable: boolean;
  callback?: () => void;
  eventKey: string;
};
export const ScopesFieldToggle = ({
  children,
  isClickable,
  callback,
  eventKey,
}: ScopesFieldToggle) => {
  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback());

  return (
    <StyledClickableRow onClick={decoratedOnClick} isClickable={isClickable}>
      {children}
    </StyledClickableRow>
  );
};
