import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import { ChevronRight } from "lucide-react";
import { API_KEYS_PATH, TEST_LINKED_ACCOUNTS_PATH } from "../../../../router/RouterUtils";
import { Dropdown, DropdownButton, Form } from "react-bootstrap";
import APIExplorerMetadata from "./APIExplorerMetadata";
import { LinkedAccount, LinkedAccountStatus } from "../../../../models/Entities";
import { FormErrorData, fetchWithAuth } from "../../../../api-client/APIClient";
import AccountTypeBadge from "../../../integrations-management/linked-accounts/detail-page/AccountTypeBadge";
import { CategoryPrimaryModelInfo, EndpointModelInfo, OptionConfig } from "../../utils/types";
import { APICategory } from "@merge-api/merge-javascript-shared";
import MergeCodeBlock from "../../../shared-components/MergeCodeBlock";
import { showErrorToast } from "../../../shared-components/Toasts";
import { GenericDropdownContainer } from "../../utils/OnboardingDropdown";
import APIExplorerSampleMetadata from "./APIExplorerSampleMetadata";
import SpinnerButton from "../../../shared-components/SpinnerButton";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";

const MarginTopBottomSpinner = styled(SpinnerButton)`
  padding: 12px 16px;
  margin: 32px 0px;
`;

const MainContainer = styled.div`
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  background: #ffffff;
  padding: 32px 32px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 32px;
  margin-bottom: 60px;
`;

const PrimaryContainer = styled.div`
  border-radius: 12px;
  border: 0.5px solid rgba(220, 226, 234, 1);
  background: #ffffff;
`;

const StyledH4 = styled(DeprecatedH3)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
  color: #121314;
`;

const StyledH5 = styled(DeprecatedH5)`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #111317;
`;

const LinkToTabText = styled.a`
  color: #075ff7;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
`;

const StyledChevron = styled(ChevronRight)`
  height: 10px;
  width: 10px;
`;
const PrependTextContainer = styled.div`
  width: 100%;
  max-width: fit-content;
  font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
    monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: var(--gray50);
  padding: 8px 12px;
  background-color: #f5f8ff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  border: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border: 0.5px solid var(--gray20);
  }
`;

const EndpointDropdown = styled.div`
  flex-grow: 1;
  && {
    button {
      width: calc(100%);
      background-color: #ffffff;
      font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
        monospace;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #111317;
      border: 1px solid #eaeef3;
      border-left: 0;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      border-radius: 0px 6px 6px 0px;
    }
    .dropdown-item {
      font-family: "SF Mono", SFMono-Regular, ui-monospace, "DejaVu Sans Mono", Menlo, Consolas,
        monospace;
      word-wrap: break-word;
      white-space: normal;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #111317;
      line-height: 24px;
    }

    .dropdown-item:hover {
      cursor: pointer;
      color: #075ff7;
      background-color: #f5f8ff;
    }

    .dropdown-menu.show {
      width: 100%;
      word-wrap: break-word;
      white-space: normal;
      max-height: 400px;
      overflow-y: auto;
    }

    button.dropdown-toggle.btn-default {
      display: flex;
      justify-content: space-between;
    }

    .btn:disabled {
      opacity: 0.35;
      cursor: not-allowed;
      pointer-events: all !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
      color: inherit !important;
      border: none;
    }
  }
`;

const StyledBadge = styled.div`
  width: auto;
  height: 17px;
  font-size: 15px;
  border-radius: 4px;
`;

const StyledSampleBadgeText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #111317;
`;

const SampleBadge = () => {
  return (
    <div className="d-flex flex-row align-items-center">
      Sample Test Linked Account
      <StyledBadge className="deprecated-ml-2 deprecated-mb-2">
        <StyledSampleBadgeText className="badge bg-secondary-soft">Sample</StyledSampleBadgeText>
      </StyledBadge>
    </div>
  );
};

const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 16px;
`;

const StyledMergeCodeBlock = styled(MergeCodeBlock)`
  && {
    pre.code-block.block {
      margin-bottom: 0px;
    }
  }
`;

const StyledDropdownContainer = styled(GenericDropdownContainer)`
  && {
    button {
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      border: 1px solid #eaeef3;
    }
  }
`;

const StyledCategoryImage = styled.img`
  border-radius: 50%;
`;

// Defines a width percentage for truncating text as some of the drop-downs have images
const TruncateText = styled.div<{ $widthPercentage: number }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(${({ $widthPercentage }) => $widthPercentage}%);
`;

const TitleTruncationDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;

const UnifiedAPIExplorerCol = styled(PaddingLessCol)`
  @media (min-width: 1000px) {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
`;

const optionConfigs: Array<OptionConfig<APICategory>> = (
  Object.keys(APICategory) as APICategory[]
).map((category) => ({
  key: category,
  displayEndpoint: CategoryPrimaryModelInfo[category].displayEndpointPath,
  testEndpoint: CategoryPrimaryModelInfo[category].testEndpointPath,
}));

/**
 * Interface used to parse APIKeyData
 */ interface APIKeyData {
  production_key?: {
    id: string;
    key_type: string;
    key: string;
  };
  remote_keys?: [
    {
      id: string;
      key_type: string;
      key: string;
      name: string;
      is_test: boolean;
    },
  ];
}

// not hard-coded incase Marketing wants to change in the future
export const UnifiedAPITitle = "Unified API Explorer";

// MergeBaseURL
const MergeBaseURL = process.env.REACT_APP_BASE_API_URL || "https://api.merge.dev";

const UnifiedAPIExplorer = () => {
  const [testEndpoint, setTestEndpoint] = useState(
    CategoryPrimaryModelInfo[APICategory.hris].testEndpointPath,
  );
  const [apiKeyData, setAPIKeyData] = useState<APIKeyData | undefined>();
  const [remoteKeyID, setRemoteKeyID] = useState("");
  const [responseBody, setResponseBody] = useState<string>("");
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [remoteKeyName, setRemoteKeyName] = useState("");
  const [linkedAccount, setLinkedAccount] = useState<LinkedAccount | null>(null);
  const [linkedAccounts, setLinkedAccounts] = useState<LinkedAccount[]>([]);
  const [linkedAccountAT, setLinkedAccountAT] = useState("");
  const [linkedAccountID, setLinkedAccountID] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<APICategory>(APICategory.hris);
  const [isTestLinkedAccountSelected, setIsTestLinkedAccountSelected] = useState(true);
  const [apiOptionsConfig, setApiOptionsConfig] =
    useState<Record<APICategory, EndpointModelInfo[]>>();
  const [displayEndpoint, setDisplayEndpoint] = useState<string>("");

  const codeBlockRef1 = useRef<HTMLInputElement>(null);
  const codeBlockRef2 = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLInputElement>(null);

  const updateWidth = () => {
    if (codeBlockRef1.current && containerRef.current && codeBlockRef2.current) {
      const refWidth = containerRef.current.getBoundingClientRect().width;
      codeBlockRef1.current.style.maxWidth = `${refWidth}px`;
      codeBlockRef2.current.style.maxWidth = `${refWidth}px`;
    }
  };

  const stripPathPrefix = (path: string) => {
    const path_array = path.split("/");
    const commonModelArray = path_array.splice(2);
    const commonModelPath = commonModelArray.join("/");
    return "/" + commonModelPath;
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [containerRef]);

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/keys",
      method: "GET",
      onResponse: (data) => {
        setAPIKeyData(data);
        const testKeys = data.remote_keys.filter(
          (remote_key: { is_test: any }) => remote_key.is_test,
        );
        setRemoteKeyID(testKeys[0].key);
        setRemoteKeyName(testKeys[0].name);
      },
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    fetchWithAuth({
      path: `organizations/test-data/categories-and-models-info`,
      method: "GET",
      onResponse: (data: any) => {
        if (!data) {
          showErrorToast("An unkown error has occurred. Please try again later.");
        } else {
          setApiOptionsConfig(data);
        }
        setIsLoading(false);
      },
      onError: (err: Response | undefined) => {
        if (err) {
          err.json().then((data: FormErrorData) => {
            let wasToastShown = false;
            for (const field_name in data) {
              if (field_name === "non_field_errors") {
                showErrorToast(data[field_name][0]);
                wasToastShown = true;
              }
            }
            if (!wasToastShown) {
              showErrorToast("An unknown error has occurred. Please try again later.");
            }
          });
        } else {
          showErrorToast("A network error has occurred. Please try again.");
        }
        setIsLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    setLinkedAccounts([]);
    fetchWithAuth({
      path: `/integrations/linked-accounts?status=${LinkedAccountStatus.COMPLETE}&is_test_account=true&ignore_demo_data=true&page_size=100`,
      method: "GET",
      onResponse: (data) => {
        setLinkedAccounts((linkedAccounts) => [...data.results, ...linkedAccounts]);
        const firstLinkedAccount = data.results?.[0];
        if (firstLinkedAccount == null) {
          /**
           * If there are no test Linked Accounts linked (e.g the user skips step one), we'll default to one of the dummy test Linked Accounts token
           * Lee made. That ID is below, and we'll set it as the default LinkedAccountID
           */
          setLinkedAccountAT("SAMPLE TEST LINKED ACCOUNT TOKEN");
          setLinkedAccountID("ec1270eb-5345-d871-6669-80e932ce802a");
          setIsTestLinkedAccountSelected(false);
        } else {
          setLinkedAccount(firstLinkedAccount);
          const firstLA: LinkedAccount = firstLinkedAccount;

          const commonModelPath = stripPathPrefix(
            CategoryPrimaryModelInfo[firstLA.category].displayEndpointPath,
          );
          setDisplayEndpoint(commonModelPath);

          setTestEndpoint(CategoryPrimaryModelInfo[firstLA.category].testEndpointPath);
          setIsTestLinkedAccountSelected(true);
        }
      },
    });
  }, []);

  /*
   ** Render function for all new Linked Accounts
   */

  useEffect(() => {
    if (linkedAccount && isTestLinkedAccountSelected) {
      setLinkedAccountAT(linkedAccount.account_token);
      setLinkedAccountID(linkedAccount.id);
      setSelectedCategory(linkedAccount.category);
      setTestEndpoint(CategoryPrimaryModelInfo[linkedAccount.category]?.testEndpointPath);
    }
  }, [linkedAccount, isTestLinkedAccountSelected]);

  /**
   * Sends a GET request using the selected test Linked Accounts Auth & X-Account-Token
   * setTimeout is used to show the spinner and give the user some feedback to improve the experience. Our API returns data too quickly
   */
  const onSubmit = () => {
    setIsLoading(true);
    fetchWithAuth({
      path: `/organizations/test-data/${testEndpoint}/${linkedAccountID}`,
      method: "GET",
      onResponse: (data) => {
        setTimeout(() => {
          setResponseSuccess(true);
          setIsLoading(false);
          setResponseBody(JSON.stringify(data, null, 2));
        }, 200);
      },
      onError: () => {
        showErrorToast(
          "An error occurred while trying to perform this API request. Please try again.",
        );
        setIsLoading(false);
      },
    });
  };

  // Dynamic display text used in Request codeblock below
  const displayText = `curl ${MergeBaseURL}/api/${
    isTestLinkedAccountSelected && displayEndpoint
      ? selectedCategory + "/v1" + displayEndpoint
      : CategoryPrimaryModelInfo[selectedCategory]?.displayEndpointPath
  } \\
-H "Authorization: Bearer ${remoteKeyID}" \\
-H "X-ACCOUNT-TOKEN: ${linkedAccountAT}"`;

  return (
    <>
      <UnifiedAPIExplorerCol>
        <MainContainer>
          <StyledH4>{UnifiedAPITitle}</StyledH4>
          <PrimaryContainer>
            <div className="rounded-t-2xl border-b border-gray-10 p-6" ref={containerRef}>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <StyledH5>Linked Account</StyledH5>
                <LinkToTabText href={TEST_LINKED_ACCOUNTS_PATH} target="_blank">
                  Test Linked Accounts <StyledChevron />
                </LinkToTabText>
              </div>
              <StyledFormGroup>
                {linkedAccounts && linkedAccount && (
                  <StyledDropdownContainer>
                    <DropdownButton
                      variant="default"
                      title={
                        isTestLinkedAccountSelected ? (
                          <TitleTruncationDiv className="d-flex flex-row align-items-center">
                            <StyledCategoryImage
                              height={25}
                              width={25}
                              src={linkedAccount.integration.square_image}
                              className="deprecated-mr-2"
                            />
                            <TruncateText $widthPercentage={90}>
                              {linkedAccount.end_user.organization_name}
                            </TruncateText>
                            <StyledBadge className="deprecated-ml-2 deprecated-mb-2">
                              <AccountTypeBadge account={linkedAccount} />
                            </StyledBadge>
                          </TitleTruncationDiv>
                        ) : (
                          <SampleBadge />
                        )
                      }
                    >
                      <>
                        {linkedAccounts.map((dropDownLinkedAccount) => (
                          <Dropdown.Item
                            key={dropDownLinkedAccount.id}
                            onClick={() => {
                              setLinkedAccount(dropDownLinkedAccount);

                              if (apiOptionsConfig && dropDownLinkedAccount) {
                                const commonModelPath = stripPathPrefix(
                                  apiOptionsConfig[dropDownLinkedAccount?.category][0]
                                    .displayEndpointPath,
                                );
                                setDisplayEndpoint(commonModelPath);
                              }

                              setTestEndpoint(
                                apiOptionsConfig && dropDownLinkedAccount
                                  ? apiOptionsConfig[dropDownLinkedAccount?.category][0]
                                      .testEndpointPath
                                  : "",
                              );
                              setIsTestLinkedAccountSelected(true);
                            }}
                          >
                            <div className="d-flex flex-row align-items-center">
                              <StyledCategoryImage
                                height={25}
                                width={25}
                                src={dropDownLinkedAccount.integration.square_image}
                                className="deprecated-mr-2"
                              />
                              {dropDownLinkedAccount.end_user.organization_name}
                              <StyledBadge className="deprecated-ml-2 deprecated-mb-2">
                                <AccountTypeBadge account={dropDownLinkedAccount} />
                              </StyledBadge>
                            </div>
                          </Dropdown.Item>
                        ))}
                        <Dropdown.Item
                          onClick={() => {
                            setLinkedAccountAT("SAMPLE TEST LINKED ACCOUNT TOKEN");
                            setLinkedAccountID("ec1270eb-5345-d871-6669-80e932ce802a");
                            setDisplayEndpoint(optionConfigs[0].displayEndpoint);
                            setTestEndpoint(optionConfigs[0].testEndpoint);
                            setIsTestLinkedAccountSelected(false);
                          }}
                        >
                          <SampleBadge />
                        </Dropdown.Item>
                      </>
                    </DropdownButton>
                  </StyledDropdownContainer>
                )}

                {!isTestLinkedAccountSelected && linkedAccount === null && (
                  <StyledDropdownContainer>
                    <DropdownButton title={<SampleBadge />} variant="default">
                      <Dropdown.Item
                        onClick={() => {
                          setLinkedAccountAT("SAMPLE TEST LINKED ACCOUNT TOKEN");
                          setLinkedAccountID("ec1270eb-5345-d871-6669-80e932ce802a");
                          setIsTestLinkedAccountSelected(false);
                        }}
                      >
                        <SampleBadge />
                      </Dropdown.Item>
                    </DropdownButton>
                  </StyledDropdownContainer>
                )}
              </StyledFormGroup>
              {isTestLinkedAccountSelected ? (
                <APIExplorerMetadata linkedAccount={linkedAccount} />
              ) : (
                <APIExplorerSampleMetadata />
              )}
            </div>
            <div className="p-6">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <StyledH5>API Key</StyledH5>
                <LinkToTabText href={API_KEYS_PATH} target="_blank">
                  Test Access Keys <StyledChevron />
                </LinkToTabText>
              </div>
              <Form.Group className="m-0">
                <StyledDropdownContainer>
                  <DropdownButton
                    variant="default"
                    title={
                      remoteKeyName ? (
                        <TruncateText $widthPercentage={98}>{remoteKeyName}</TruncateText>
                      ) : (
                        "Select..."
                      )
                    }
                  >
                    {apiKeyData?.remote_keys
                      ?.filter((remote_key) => remote_key.is_test)
                      ?.map((remote_key) => (
                        <Dropdown.Item
                          key={remote_key.name}
                          onClick={() => {
                            setRemoteKeyID(remote_key.key);
                            setRemoteKeyName(remote_key.name);
                          }}
                        >
                          {remote_key.name}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                </StyledDropdownContainer>
              </Form.Group>
            </div>
            <div className="rounded-b-2xl border-t border-gray-10 p-6">
              <StyledH5>Endpoint</StyledH5>
              <div className="m-0 d-flex flex-row">
                {isTestLinkedAccountSelected ? (
                  <>
                    <PrependTextContainer>
                      GET {MergeBaseURL}/api/{linkedAccount?.category}/v1{" "}
                    </PrependTextContainer>
                    <EndpointDropdown>
                      <DropdownButton variant="default" title={displayEndpoint}>
                        {linkedAccount?.category &&
                          apiOptionsConfig &&
                          apiOptionsConfig[linkedAccount.category].map((option) => {
                            const commonModelPath = stripPathPrefix(option.displayEndpointPath);

                            return (
                              <Dropdown.Item
                                key={commonModelPath}
                                onClick={() => {
                                  setTestEndpoint(option?.testEndpointPath);
                                  setDisplayEndpoint(commonModelPath);
                                  setSelectedCategory(linkedAccount.category);
                                }}
                              >
                                {commonModelPath}
                              </Dropdown.Item>
                            );
                          })}
                      </DropdownButton>
                    </EndpointDropdown>
                  </>
                ) : (
                  <>
                    <PrependTextContainer>GET {MergeBaseURL}/api/</PrependTextContainer>
                    <EndpointDropdown>
                      <DropdownButton
                        variant="default"
                        title={CategoryPrimaryModelInfo[selectedCategory]?.displayEndpointPath}
                      >
                        {optionConfigs.map((option) => (
                          <Dropdown.Item
                            key={option.key}
                            onClick={() => {
                              setTestEndpoint(option?.testEndpoint);
                              setSelectedCategory(option.key);
                            }}
                          >
                            {option.displayEndpoint}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </EndpointDropdown>
                  </>
                )}
              </div>
            </div>
          </PrimaryContainer>
          <MarginTopBottomSpinner
            onClick={() => onSubmit()}
            isLoading={isLoading}
            text="Send API Request"
            className="btn-primary btn-block"
          />

          <div ref={codeBlockRef1}>
            <StyledMergeCodeBlock
              className="deprecated-mb-5"
              codeBlockName="Request"
              showLineNumbers={false}
              language="bash"
              customStyle={{
                lineHeight: "20px",
                maxHeight: "150px",
                Width: `100%`,
              }}
              textToCopy={displayText}
            >
              {displayText}
            </StyledMergeCodeBlock>
          </div>
          <div ref={codeBlockRef2}>
            <StyledMergeCodeBlock
              codeBlockName="Response"
              showLineNumbers={false}
              language="json"
              customStyle={{
                lineHeight: "20px",
                Width: `100%`,
              }}
              textToCopy={responseBody}
            >
              {responseSuccess
                ? responseBody
                : `Press "Send API Request"
            `}
            </StyledMergeCodeBlock>
          </div>
        </MainContainer>
      </UnifiedAPIExplorerCol>
    </>
  );
};

export default UnifiedAPIExplorer;
