import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Webhook, Search, FileSearch, Info } from "lucide-react";
import { Button } from "react-bootstrap";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import {
  LinkedAccount,
  LinkedAccountWebhookReceivers,
  WebhookReceiver,
} from "../../../../models/Entities";
import {
  getLinkedAccountDetailFieldMappingsPathForId,
  getLinkedAccountIssuesPathForId,
  getLinkedAccountDetailAPIRequestLogsSettingsPathForId,
  getLinkedAccountDetailOverviewPathForId,
  getLinkedAccountDetailDataPathForId,
  getLinkedAccountDetailSettingsPathForId,
  getLinkedAccountDetailWebhooksPathForId,
  getSelectiveSyncCreateOrEditConditionPathForId,
  navigateToProductionLinkedAccountsTablePage,
  getLinkedAccountDetailWebhookLogsSettingsPathForId,
  getLinkedAccountDetailLogsSettingsPathForId,
} from "../../../../router/RouterUtils";
import useAppContext from "../../../context/useAppContext";
import PortalPageHeaderWrapper from "../../../portal/PortalPageHeaderWrapper";
import ErrorCard from "../../../shared-components/ErrorCard";
import { DuplicateAccountBadge } from "../shared/DuplicateLinkedAccountBanner";
import Stack from "../shared/Stack";
import FieldMappingsPage from "./field-mappings/FieldMappingsPage";
import OverviewPage from "./overview/OverviewPage";
import SelectiveSyncCreateOrEditPage from "./selective-sync/SelectiveSyncCreateOrEditConditionPage";
import SettingsPage from "./SettingsPage";
import WebhooksPage from "./webhooks/WebhooksPage";
import IssuesPage from "./issues/IssuesPage";
import { WarningCard } from "../../../shared-components/NoticeCards";
import useProductRestrictions from "../../../shared-components/hooks/useProductRestrictions";
import styled from "styled-components";
import LinkedAccountLogsTab from "../logs-page/LinkedAccountLogsTab";
import LinkedAccountDetailDataPage from "./data/LinkedAccountDetailDataPage";
import { Alert } from "@merge-api/merge-javascript-shared";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import { fetchCommonModelsFromCategoryForIntegration } from "../../../../api-client/categories/CommonModelTogglesAPIClient";
import { createSortedModelsMap } from "../../../configuration/common-models/CommonModelUtils";
import LinkedAccountPretitle from "./overview/portal/LinkedAccountPretitle";
import LinkedAccountTitleContainer from "./overview/portal/LinkedAccountTitleContainer";

// All we get in params is the id
type Props = Pick<LinkedAccount, "id">;

/**
 * Generates an API path for this integration, given an id
 */
const apiPath = (id: string) => `/integrations/linked-accounts/${id}`;

/**
 * Generates an API path for this integration's webhook data, given an id
 */
const webhookDataApiPath = (id: string) => `/integrations/webhook-receivers/${id}`;

const FileSearchMargin = styled(FileSearch)`
  margin-right: 6px;
`;

/**
 * Shows the detail page for one singular linked account. Has a header
 * plus tabs below, showing overview, webhooks, and settings for one
 * particular linked account.
 */
const LinkedAccountDetailPage = () => {
  const { user, isUserPrivileged } = useAppContext();
  const location = useLocation();
  const { id } = useParams<Props>();
  const [linkedAccount, setLinkedAccount] = useState<LinkedAccount | null>(null);
  const isTestAccount = useMemo(() => linkedAccount?.is_test_account ?? false, [linkedAccount]);
  const { productRestrictions } = useProductRestrictions();
  const [webhookReceivers, setWebhookReceivers] = useState<LinkedAccountWebhookReceivers | null>(
    null,
  );

  const [ongoingIntegrationIssue, setOngoingIntegrationIssue] = useState<IntegrationIssue | null>(
    null,
  );
  const [errorOccurred, setErrorOccurred] = useState(false);
  const history = useHistory();
  const firstDemoUserUpdate = useRef(true);

  const [categoryCommonModels, setCategoryCommonModels] = useState<Array<string>>([]);

  useEffect(() => {
    if (linkedAccount) {
      fetchCommonModelsFromCategoryForIntegration(
        linkedAccount.category,
        linkedAccount.integration,
      ).then((result) => {
        if (result.status === "success") {
          setCategoryCommonModels([...Object.keys(createSortedModelsMap(result.data))]);
        }
      });
    }
  }, [linkedAccount]);

  // Hide subtabs for Selective Sync edit page
  const showSubtabs = !useRouteMatch(
    "/linked-accounts/account/:id/overview/selective-sync/:model/:edit",
  )?.isExact;

  const isOnOverviewPage = useRouteMatch("/linked-accounts/account/:id/overview/")?.isExact;
  const showTestAccountBanner = isOnOverviewPage && isTestAccount;

  const isOnCreateFieldMappingsPage = location.pathname.includes("custom-mappings/create");
  const isOnIssuesPage = location.pathname.includes("/issues");

  /**
   * Updates one of the receivers if you make changes to it
   */
  const updateReceiverAtIndex = (receiver: WebhookReceiver, index: number) =>
    setWebhookReceivers((currentValue) => {
      if (!currentValue) {
        return null;
      }
      // Update the indexed receiver to a new value
      return {
        ...currentValue,
        webhook_receivers: currentValue.webhook_receivers.map((oldReceiver, currentIndex) => {
          if (currentIndex === index) {
            return receiver;
          }
          return oldReceiver;
        }),
      };
    });

  // Fetches data on command
  const fetchLinkedAccountData = useCallback(() => {
    let isCancelled = false;
    fetchWithAuth({
      path: apiPath(id),
      method: "GET",
      onResponse: (data) => {
        if (isCancelled) {
          return;
        }
        setLinkedAccount(data);
        setErrorOccurred(false);
      },
      onError: () => {
        if (isCancelled) {
          return;
        }
        setLinkedAccount(null);
        setErrorOccurred(true);
      },
    });
    return () => {
      isCancelled = true;
    };
  }, [id]);

  // Fetch main data when the id changes
  useEffect(fetchLinkedAccountData, [id]);

  // Fetch webhook data when the id changes
  useEffect(() => {
    let isCancelled = false;
    fetchWithAuth({
      path: webhookDataApiPath(id),
      method: "GET",
      onResponse: (data: LinkedAccountWebhookReceivers) => {
        if (isCancelled) {
          return;
        }
        setWebhookReceivers(data);
      },
    });
    return () => {
      isCancelled = true;
    };
  }, [id]);

  // fetch integration issues for linked account
  useEffect(() => {
    fetchWithAuth({
      path: `/integrations/issues?status=ONGOING&order_by=CREATED_DESC&linked_account_id=${id}`,
      method: "GET",
      onResponse: (data) => {
        const ongoingIntegrationIssues = data.results;
        if (ongoingIntegrationIssues.length > 0) {
          setOngoingIntegrationIssue(ongoingIntegrationIssues[0]);
        }
      },
    });
  }, [id]);

  // Shows an error card for the non demo users when there's an error
  const errorComponent =
    !user.is_demo && errorOccurred ? (
      <ErrorCard>Couldn&apos;t find a linked account with that id. Please try again.</ErrorCard>
    ) : null;

  // Prevent unneeded rerenders when unrelated data changes
  const overviewPageComponent = useMemo(
    () => () =>
      errorComponent ?? (
        <OverviewPage
          linkedAccount={linkedAccount}
          id={id}
          webhookReceivers={webhookReceivers}
          ongoingIntegrationIssue={ongoingIntegrationIssue}
          setOngoingIntegrationIssue={setOngoingIntegrationIssue}
        />
      ),
    [errorComponent, linkedAccount, id, webhookReceivers, ongoingIntegrationIssue],
  );
  const webhooksPageComponent = useMemo(() => {
    if (webhookReceivers && webhookReceivers.webhook_receivers.length === 0) {
      return null;
    }
    return () =>
      errorComponent ?? (
        <WebhooksPage
          linkedAccountID={id}
          linkedAccount={linkedAccount}
          accountReceivers={webhookReceivers}
          updateReceiverAtIndex={updateReceiverAtIndex}
        />
      );
  }, [webhookReceivers, errorComponent, linkedAccount]);

  const logsPageComponent = useMemo(
    () => () => errorComponent ?? <LinkedAccountLogsTab linkedAccount={linkedAccount} id={id} />,
    [errorComponent, linkedAccount, fetchLinkedAccountData],
  );

  const settingsPageComponent = useMemo(
    () => () =>
      errorComponent ?? (
        <SettingsPage linkedAccount={linkedAccount} updateLinkedAccount={fetchLinkedAccountData} />
      ),
    [errorComponent, linkedAccount, fetchLinkedAccountData],
  );

  const customMappingsPageComponent = useMemo(
    () => () =>
      errorComponent ?? (
        <FieldMappingsPage
          id={id}
          linkedAccount={linkedAccount}
          categoryCommonModels={categoryCommonModels}
        />
      ),
    [errorComponent, linkedAccount, fetchLinkedAccountData, categoryCommonModels],
  );

  const IssuesPageComponent = useMemo(
    () => () => errorComponent ?? <IssuesPage linkedAccount={linkedAccount} />,
    [errorComponent, linkedAccount, fetchLinkedAccountData],
  );

  const selectiveSyncCreateOrEditPageComponent = useMemo(
    () => () => linkedAccount && <SelectiveSyncCreateOrEditPage linkedAccount={linkedAccount} />,
    [linkedAccount],
  );

  const dataPageComponent = useMemo(
    () => () =>
      errorComponent ??
      (linkedAccount && <LinkedAccountDetailDataPage linkedAccount={linkedAccount} />),
    [linkedAccount],
  );

  // Nested route contents for the tabs
  const tabContent = (
    <Switch>
      <Route
        exact
        path={getLinkedAccountDetailOverviewPathForId(id)}
        component={overviewPageComponent}
      />
      <Route exact path={getLinkedAccountDetailDataPathForId(id)} component={dataPageComponent} />
      <Route
        exact
        path={getLinkedAccountDetailWebhooksPathForId(id)}
        component={webhooksPageComponent ?? undefined}
      />
      <Route
        path={getLinkedAccountDetailAPIRequestLogsSettingsPathForId(id)}
        component={logsPageComponent}
      />
      <Route path={getLinkedAccountDetailLogsSettingsPathForId(id)} component={logsPageComponent} />
      <Route
        path={getLinkedAccountDetailWebhookLogsSettingsPathForId(id)}
        component={logsPageComponent}
      />
      <Route
        exact
        path={getLinkedAccountDetailSettingsPathForId(id)}
        component={settingsPageComponent}
      />
      <Route
        exact
        path={getLinkedAccountDetailFieldMappingsPathForId(id)}
        component={customMappingsPageComponent}
      />
      <Route exact path={getLinkedAccountIssuesPathForId(id)} component={IssuesPageComponent} />
      <Route
        path={getSelectiveSyncCreateOrEditConditionPathForId(id, ":commonModel")}
        component={selectiveSyncCreateOrEditPageComponent}
      />
      <Redirect to={getLinkedAccountDetailOverviewPathForId(id)} />
    </Switch>
  );

  // Only shows webhooks subtab if there are webhook receivers
  const subtabs = useMemo(() => {
    const overview = {
      label: "Overview",
      destination: getLinkedAccountDetailOverviewPathForId(id),
      icon: <span className="fe fe-grid deprecated-mr-2" />,
    };
    const data = {
      label: "Data",
      destination: getLinkedAccountDetailDataPathForId(id),
      icon: <span className="fe fe-box deprecated-mr-2" />,
    };
    const webhooks = {
      label: "Webhooks",
      destination: getLinkedAccountDetailWebhooksPathForId(id),
      icon: <Webhook size={16} className="deprecated-mr-2" />,
    };
    const customMappings = {
      label: "Field Mapping",
      destination: getLinkedAccountDetailFieldMappingsPathForId(id),
      icon: <span className="fe fe-shuffle deprecated-mr-2" />,
    };
    const issues = {
      label: "Issues",
      destination: getLinkedAccountIssuesPathForId(id),
      icon: <span className="fe fe-alert-circle deprecated-mr-2" />,
    };
    const logs = {
      label: "Logs",
      destination: getLinkedAccountDetailLogsSettingsPathForId(id),
      icon: <FileSearchMargin size={16} />,
    };
    const settings = {
      label: "Settings",
      destination: getLinkedAccountDetailSettingsPathForId(id),
      icon: <span className="fe fe-settings deprecated-mr-2" />,
    };
    const tabs = [overview, data, webhooks, customMappings, issues, logs, settings]
      .filter(
        // Filter out webhooks tab if there are no webhooks
        (obj) => obj.label !== "Webhooks" || webhookReceivers?.webhook_receivers?.length,
      )
      .filter(
        (obj) =>
          // Filter out custom fields if user should not have access
          obj.label !== "Field Mapping" || isUserPrivileged,
      );
    return tabs;
  }, [id, webhookReceivers, linkedAccount]);

  // Make sure to navigate to Linked Accounts when our demo user status changes
  useEffect(() => {
    if (firstDemoUserUpdate.current) {
      firstDemoUserUpdate.current = false;
      return;
    }
    navigateToProductionLinkedAccountsTablePage(history);
  }, [user.is_demo]);

  return (
    <PortalPageHeaderWrapper
      preTitleContent={
        <LinkedAccountPretitle linkedAccount={linkedAccount} isTestAccount={isTestAccount} />
      }
      title={
        <LinkedAccountTitleContainer
          linkedAccount={linkedAccount}
          isOnOverviewPage={isOnOverviewPage}
          isOnIssuesPage={isOnIssuesPage}
          ongoingIntegrationIssue={ongoingIntegrationIssue}
        />
      }
      subtabs={subtabs}
      isMaxWidthEnabled
      showSubtabs={showSubtabs}
      showHeader={!isOnCreateFieldMappingsPage}
      isLinkedAccountPortalView
    >
      <>
        {showTestAccountBanner && (
          <Alert className="mt-2 mb-6" icon={<Info size={16} />} color="gray">
            Test Linked Accounts must be manually synced through this dashboard to capture updates
            from the third-party platform.
          </Alert>
        )}
        {linkedAccount?.is_duplicate && isOnOverviewPage && (
          <WarningCard showsIcon={false}>
            <DuplicateAccountBadge
              className="flex-shrink-0"
              size={16}
              style={{ marginRight: "16px" }}
            />
            <div>We've detected multiple Linked Accounts with the same credentials.</div>
            <Button
              className="ml-auto"
              color="white"
              variant="white"
              size="sm"
              onClick={() => {
                navigateToProductionLinkedAccountsTablePage(history, {
                  complete_production_duplicates_of: linkedAccount.id,
                });
              }}
            >
              <Stack className="align-items-center">
                <Search className="m-0 p-0" size={12} />
                <span className="deprecated-ml-2">Review duplicates</span>
              </Stack>
            </Button>
          </WarningCard>
        )}
        {tabContent}
      </>
    </PortalPageHeaderWrapper>
  );
};

export default LinkedAccountDetailPage;
