import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { AlertTriangle } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface LinkedAccountIssuesWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: SelectedWebhookType | undefined;
}

function LinkedAccountIssuesWebhook(props: LinkedAccountIssuesWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Linked Account issues"
        description="Receive an alert when a Linked Account issue is created, muted, or resolved."
        icon={<AlertTriangle size={16} />}
        onClick={() => onSelectedWebhookTypeChange(SelectedWebhookType.ISSUES)}
        selected={selectedWebhookType === SelectedWebhookType.ISSUES}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default LinkedAccountIssuesWebhook;
