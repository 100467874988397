import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import ScopesFieldRow from "./ScopesFieldRow";
import CenteredVerticalLineCol from "../../../../configuration/common-models/CenteredVerticalLineCol";
import { Schema } from "../../../../configuration/common-models/CommonModelToggleTypes";
import { CommonModelScopeMap } from "../../../../../models/Entities";
const ScopesTableContainer = styled.div``;

const ScopesTableHeaders = styled(Row).attrs({ className: "flex-nowrap" })`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
`;

const LeftMarginCol = styled(Col)`
  margin-left: ${(props) => props.leftMargin}px;
`;

const StyledCenteredVerticalLineCol = styled(CenteredVerticalLineCol)`
  background-image: linear-gradient(transparent, rgb(234, 238, 243));
`;
type ScopeTableProps = {
  linkedAccountScopes: CommonModelScopeMap;
  orgLevelScopes: CommonModelScopeMap;
  modelSchema: Schema;
  commonModelName: string;
  changeState: (
    commonModelName: string,
    fieldName: string,
    linkedAccountCommonModelActions: string[],
  ) => void;
};
const ScopesTable = ({
  linkedAccountScopes,
  orgLevelScopes,
  modelSchema,
  commonModelName,
  changeState,
}: ScopeTableProps) => {
  const content = Object.entries(orgLevelScopes.fields).map(
    (
      [fieldName, orgLevelCommonModelFieldScope]: [string, { isEnabled: boolean }],
      index: number,
      fields,
    ) => {
      const linkedAccountCommonModelFieldScope = linkedAccountScopes.fields[fieldName];
      const fieldDescription = modelSchema?.parameters?.[fieldName]?.description || null;
      const fieldType = modelSchema?.parameters?.[fieldName]?.type || undefined;
      return (
        <ScopesFieldRow
          key={fieldName}
          fieldName={fieldName}
          fieldType={fieldType}
          fieldDescription={fieldDescription}
          linkedAccountCommonModelActions={linkedAccountScopes.actions}
          orgLevelCommonModelActions={orgLevelScopes.actions}
          linkedAccountCommonModelFieldScope={linkedAccountCommonModelFieldScope}
          orgLevelCommonModelFieldScope={orgLevelCommonModelFieldScope}
          commonModelName={commonModelName}
          changeState={changeState}
          isLastRow={index === fields.length - 1}
        />
      );
    },
  );

  return (
    <ScopesTableContainer>
      <ScopesTableHeaders>
        <LeftMarginCol leftMargin={16} className="col-auto px-0">
          <Row className="mx-0 h-100">
            <StyledCenteredVerticalLineCol $verticalLineConfig="all" />
          </Row>
        </LeftMarginCol>
        <LeftMarginCol leftMargin={16} className="pr-0 deprecated-mr-3">
          <Row className="items-center flex-nowrap">
            <div className="flex-none py-2 pr-6 pl-0 my-auto min-w-fit xl:min-w-[400px] xl:w-1/4 w-1/2">
              Field
            </div>
            <div className="flex-none xl:w-1/4 w-1/2">Scope</div>
          </Row>
        </LeftMarginCol>
      </ScopesTableHeaders>

      <div>{content}</div>
    </ScopesTableContainer>
  );
};
export default ScopesTable;
