import React from "react";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import styled from "styled-components";

import { CONFIGURATION_WEBHOOKS_RECEIVERS_PATH } from "../../../router/RouterUtils";
import { OrganizationBillingPlan } from "../../settings/billing/BillingModels";
import { getBillingPlanTitle } from "../../settings/billing/BillingUtils";
import { IndigoAside, NoticeCard } from "../../shared-components/NoticeCards";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";

const FlexBetween = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AnchorAsButton = styled.a`
  && {
    color: inherit;
    text-decoration: inherit;
    font-weight: 600;

    &:hover {
      text-decoration: inherit;
    }
  }
`;

const LinkAsButton = styled(Link)`
  && {
    color: inherit;
    text-decoration: inherit;
    font-weight: 600;

    &:hover {
      text-decoration: inherit;
    }
  }
`;

export const ThirdPartyWebhooksUpsellNoticeCard = () => {
  return (
    <IndigoAside>
      <FlexBetween>
        <div>
          Your plan doesn't support <strong>Third-Party Webhooks</strong>. To add webhooks, upgrade
          to our <strong>Core</strong>, <strong>Professional</strong>, or{" "}
          <strong>Enterprise</strong> plan.
        </div>
        <AnchorAsButton href="https://merge.dev/pricing" className="btn btn-white btn-sm">
          View Plans
        </AnchorAsButton>
      </FlexBetween>
    </IndigoAside>
  );
};

interface MustUseAutoWebhookSetupNoticeCardProps {
  integrationName: string;
}

export const MustUseAutoWebhookSetupNoticeCard = ({
  integrationName,
}: MustUseAutoWebhookSetupNoticeCardProps) => {
  return (
    <IndigoAside>
      <FlexBetween>
        <div>
          Merge only supports <strong>Automatic Webhook Creation</strong> for {integrationName}.
          Enable this in{" "}
          <strong>
            Configuration {">"} Webhooks {">"} Receivers
          </strong>
          .
        </div>
        <LinkAsButton to={CONFIGURATION_WEBHOOKS_RECEIVERS_PATH} className="btn btn-white btn-sm">
          Go to Settings
        </LinkAsButton>
      </FlexBetween>
    </IndigoAside>
  );
};

const Title = styled(DeprecatedH4)`
  && {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

const NoticeCardText = styled.p`
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
`;

interface NewWebhooksDisabledNoticeCardProps {
  orgBillingPlan: OrganizationBillingPlan;
}
export const NewWebhooksDisabledNoticeCard = ({
  orgBillingPlan,
}: NewWebhooksDisabledNoticeCardProps) => {
  const { show } = useIntercom();
  const billingPlanTitle = getBillingPlanTitle(orgBillingPlan);
  return (
    <NoticeCard showsIcon={false}>
      <div>
        <Title>Your {billingPlanTitle} plan only supports first sync webhooks</Title>
        <NoticeCardText>
          As of July 7th, 2022, Merge&apos;s {billingPlanTitle} plan only supports{" "}
          <b>first sync webhooks</b>.
        </NoticeCardText>
        <NoticeCardText>
          Other forms of webhooks, such as notifications about changed data, are available to
          members of all our plans.
        </NoticeCardText>
        <NoticeCardText className="mb-0">
          <a onClick={() => show()} href="#">
            Contact sales
          </a>{" "}
          to upgrade your plan, or <a href="https://merge.dev/pricing">view our latest plans</a>.
        </NoticeCardText>
      </div>
    </NoticeCard>
  );
};
