import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FieldMappingCreationAndEditDict } from "../../../../../models/Entities";
import {
  DropdownItem,
  DropDownOptionColumn1,
  SFMonoText,
  OriginTypeBadge,
  StyledDropdownAPIEndpointPath,
} from "./FieldMappingInstanceCard";
import { capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
type FieldMappingDropdownItemProps = {
  fieldMapping: FieldMappingCreationAndEditDict;
};

const FieldMappingDropdownItem = ({ fieldMapping }: FieldMappingDropdownItemProps) => {
  let fieldMappingExample = fieldMapping.value ?? null;
  if (fieldMappingExample !== null) {
    if (typeof fieldMappingExample === "object") {
      fieldMappingExample = JSON.stringify(fieldMappingExample);
    } else {
      fieldMappingExample = String(fieldMappingExample);
    }
  }
  return (
    <DropdownItem
      key={fieldMappingExample}
      className="d-flex flex-column justify-content-between align-content-center w-100 dropdown-menu-options"
    >
      <div className="d-flex flex-row align-items-start">
        <DropDownOptionColumn1 className="d-flex align-items-center mt-0.5">
          field:
        </DropDownOptionColumn1>

        <div id="origin-field" className=" w-100 text-break white-space-wrap truncate-container">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip
                id="tooltip-top"
                style={{
                  width: "fit-content",
                  height: "fit-content",
                }}
              >
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row">
                    <div className="d-flex mr-1">
                      <span>field:</span>
                    </div>
                    <div className="d-flex text-break white-space-wrap text-align-start font-weight-bold">
                      <span>{fieldMapping.display_name}</span>
                    </div>
                  </div>
                  <div>
                    <div className="d-flex flex-row">
                      <div className="d-flex mr-1">
                        <span>type:</span>
                      </div>
                      <div className="d-flex font-weight-bold text-align-start">
                        {fieldMapping.type}
                      </div>
                    </div>
                  </div>
                </div>
              </Tooltip>
            }
          >
            <SFMonoText className="d-inline mr-2">{fieldMapping.display_name}</SFMonoText>
          </OverlayTrigger>

          {fieldMapping.type && (
            <OriginTypeBadge className="d-inline-block text-break px-1">
              {capitalizeFirstLetter(fieldMapping.type)}
            </OriginTypeBadge>
          )}
        </div>
      </div>
      <div className="d-flex flex-row">
        <DropDownOptionColumn1>example:</DropDownOptionColumn1>
        <div className="d-flex flex-row w-100 text-truncate">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">{fieldMappingExample}</Tooltip>}
          >
            <SFMonoText className="api-info text-truncate">{fieldMappingExample}</SFMonoText>
          </OverlayTrigger>
        </div>
      </div>
      <div className="d-flex flex-row">
        <DropDownOptionColumn1>endpoint:</DropDownOptionColumn1>
        <div className="d-flex flex-row w-100 text-truncate">
          <StyledDropdownAPIEndpointPath className="small api-info text-truncate">
            {fieldMapping.api_endpoint_path}
          </StyledDropdownAPIEndpointPath>
        </div>
      </div>
    </DropdownItem>
  );
};

export default FieldMappingDropdownItem;
