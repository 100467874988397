import React from "react";
import { Col } from "react-bootstrap";
import classNames from "classnames";
import Spinner from "./Spinner";
import styled from "styled-components";
import DeprecatedH2 from "../../deprecated/DeprecatedH2";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

interface Props {
  children?: JSX.Element;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  rowClassName?: string;
  colClassName?: string;
  isTable?: boolean;
  isSpinner?: boolean;
}

const TitleText = styled(DeprecatedH2)`
  font-size: 22px;
`;

const SubtitleText = styled(DeprecatedH4)`
  font-size: 12px;
`;

function EmptyStateWrapper(props: Props) {
  return (
    <div
      className={classNames(
        props.rowClassName ? props.rowClassName : "",
        props.isTable ? "" : "row",
      )}
    >
      <Col
        className={classNames(
          props.colClassName ? props.colClassName : "text-center align-middle",
          props.isTable ? "deprecated-mt-5 deprecated-mb-5" : "deprecated-m-5",
        )}
      >
        {props.title && (
          <TitleText
            className={classNames("empty-state-text", props.subtitle ? "deprecated-mb-2" : "mb-0")}
          >
            {props.title}
          </TitleText>
        )}
        {props.subtitle && (
          <SubtitleText className={classNames("empty-state-text", "mb-0")}>
            {props.subtitle}
          </SubtitleText>
        )}
        {props.isSpinner ? <Spinner /> : props.children}
      </Col>
    </div>
  );
}

export default EmptyStateWrapper;
