import classNames from "classnames";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import elixir from "react-syntax-highlighter/dist/cjs/languages/hljs/elixir";
import go from "react-syntax-highlighter/dist/cjs/languages/hljs/go";
import json from "react-syntax-highlighter/dist/cjs/languages/hljs/json";
import python from "react-syntax-highlighter/dist/cjs/languages/hljs/python";
import ruby from "react-syntax-highlighter/dist/cjs/languages/hljs/ruby";
import nightOwl from "react-syntax-highlighter/dist/cjs/styles/hljs/night-owl";
import styled from "styled-components";

const languages = {
  json,
  python,
  ruby,
  bash,
  elixir,
  go,
};
for (const [languageName, languageValue] of Object.entries(languages)) {
  SyntaxHighlighter.registerLanguage(languageName, languageValue);
}

export interface CodeBlockFileDownloadInfo {
  previewMessage: string;
  downloadLink: string;
}

type LanguageName = keyof typeof languages;

interface Props {
  children?: string | null;
  language?: LanguageName | null;
  showLineNumbers?: boolean | null;
  customStyle?: any;
  codeBlockName?: string;
  textToCopy?: string;
  downloadInfo?: CodeBlockFileDownloadInfo;
}

const MergeCodeBlock = ({
  className,
  textToCopy,
  codeBlockName,
  children,
  language,
  showLineNumbers,
  customStyle,
  downloadInfo,
}: Props & Pick<React.ComponentProps<"div">, "className">) => {
  const [hasCopiedToClipboard, setHasCopiedToClipboard] = useState(false);

  const additionalStyling = downloadInfo
    ? { padding: "10px 20px", overflow: "clip", marginBottom: "0px" }
    : { padding: "10px 20px" };

  const CodeBlock = () => {
    return (
      <SyntaxHighlighter
        className="code-block block"
        language={language || "json"}
        showLineNumbers={showLineNumbers ? true : false}
        style={nightOwl}
        customStyle={Object.assign(additionalStyling, customStyle)}
      >
        {children}
      </SyntaxHighlighter>
    );
  };

  const DownloadButtonWrapper = styled.div`
    padding: 0px 14px 14px;
  `;

  // @ts-ignore: TS6133
  const DownloadButtonLink = styled.a.attrs((props) => ({
    className: "btn btn-white",
  }))`
    & {
      width: 100%;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  `;

  const DownloadButton = () => {
    return (
      <DownloadButtonWrapper>
        <DownloadButtonLink href={downloadInfo?.downloadLink} download>
          {downloadInfo?.previewMessage}
        </DownloadButtonLink>
      </DownloadButtonWrapper>
    );
  };

  return (
    <div className={classNames("code-block-container", className)}>
      <Row
        className="align-items-center justify-content-between"
        style={{ padding: "0 10px", margin: 0 }}
      >
        <div className="align-self-start deprecated-mt-2" style={{ marginLeft: 10 }}>
          {codeBlockName ?? <></>}
        </div>
        <div className="deprecated-mt-2">
          {downloadInfo === undefined && (
            <div className="d-flex align-items-center align-self-end float-right">
              <OverlayTrigger
                placement="top"
                delay={{ show: 0, hide: 0 }}
                overlay={
                  <Tooltip id="tooltip-copy-">{hasCopiedToClipboard ? "Copied!" : "Copy"}</Tooltip>
                }
              >
                <CopyToClipboard
                  text=""
                  onCopy={() => {
                    copy(textToCopy ?? "");
                    setHasCopiedToClipboard(true);
                  }}
                >
                  <i className="fe fe-copy deprecated-mr-3 cursor-pointer" />
                </CopyToClipboard>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </Row>
      <CodeBlock />
      {downloadInfo && <DownloadButton />}
    </div>
  );
};

export default MergeCodeBlock;
