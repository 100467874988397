import React from "react";
import { Row } from "react-bootstrap";
import { OffsetCol, PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import TileStep, { TitleText } from "../helpers/TileStep";
import styled from "styled-components";
import SelectOneStep from "../helpers/SelectOneStep";
import { Rotate3d, BaggageClaim } from "lucide-react";
import {
  DOCS_REMOTE_DATA_PATH,
  DOCS_PASSTHROUGH_REQUESTS_PATH,
} from "../../../../router/RouterUtils";

const SelectOneContainer = styled.div`
  display: grid;
  grid-area: container;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 22px;
  justify-content: space-evenly;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "container";
  @media (max-width: 1000px) {
    grid-template-columns: 1fr minmax(1px, 4fr);
    grid-template-areas:
      "selectonesingle ..."
      "selectoneline container";
  }
`;

const MarginDiv = styled.div`
  margin-bottom: 60px;
`;

const Troubleshooting = () => {
  return (
    <>
      <OffsetCol>
        <Row>
          <PaddingLessCol>
            <TitleText>Supplemental Data</TitleText>
          </PaddingLessCol>
        </Row>
        <Row className="deprecated-mb-4">
          <TileStep
            title="Interact with original data from integrated third-party platforms"
            subtitle={
              <>
                <p>
                  Merge normalizes integration data from different third-party platforms - each with
                  its own data schema - into a standardized data model format ("Common Model") for a
                  unified integration experience.
                </p>
                <p>
                  While the Common Model covers most use cases, some third-parties may have uncommon
                  fields.
                </p>
                <p>
                  In these cases, you can leverage Merge's Unified API and SDK to interact with
                  original data from integrated third-parties.
                </p>
                <p className="mb-0">Explore our two advanced capabilities below:</p>
              </>
            }
            stepNumber={1}
          />
        </Row>

        <Row>
          <PaddingLessCol>
            <GridWrapper>
              <SelectOneContainer>
                <SelectOneStep
                  img={<BaggageClaim size={16} color="var(--blue40)" />}
                  title="Remote Data"
                  subtitle={
                    <>
                      Pull user data from integrated third-parties in its <b>original format</b>{" "}
                      (before normalization).
                    </>
                  }
                  buttonName="View Docs"
                  docsURL={DOCS_REMOTE_DATA_PATH}
                />
                <SelectOneStep
                  img={<Rotate3d size={16} color="var(--blue40)" />}
                  title="Passthrough Requests"
                  subtitle={
                    <>
                      Use our <strong>Passthrough Requests</strong> to make any supported request
                      (pre-authenticated) to an integration's API.
                    </>
                  }
                  buttonName="View Docs"
                  docsURL={DOCS_PASSTHROUGH_REQUESTS_PATH}
                />
              </SelectOneContainer>
            </GridWrapper>
          </PaddingLessCol>
        </Row>
      </OffsetCol>
      <MarginDiv />
    </>
  );
};

export default Troubleshooting;
