import React, { useEffect } from "react";
import {
  GET_IN_TOUCH_PATH,
  MERGE_BILLING_SELF_SERVE_AFTER_APRIL,
  MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH,
  MERGE_HELP_CENTER_PATH,
} from "../../../../router/RouterUtils";
import { BillingGuardrailsStatus, InvoiceNonpaymentStatus } from "../../../../models/Entities";
import { LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL } from "../BillingModels";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import {
  FreeBanner,
  FreeToLaunchBanner,
  InvoiceNonpaymentBanner,
  LaunchBanner,
  LaunchGuardRailsBanner,
  LaunchLimitBanner,
} from "../../../shared-components/Banners";

interface UserEventTrackerProps {
  event_name_input: string;
  impressions_input?: number;
  dismisses_input?: number;
  clicks_input?: number;
}

export function userEventTracker(props: UserEventTrackerProps) {
  const { event_name_input, impressions_input, dismisses_input, clicks_input } = props;
  fetchWithAuth({
    path: "/users/events",
    method: "PATCH",
    body: {
      event_name: event_name_input,
      impressions: impressions_input,
      dismisses: dismisses_input,
      clicks: clicks_input,
    },
    onResponse: () => {},
  });
}

interface BannerToShowProps {
  showAnyBanner: boolean;
  bannerStatus: boolean | boolean | BillingGuardrailsStatus | InvoiceNonpaymentStatus | null;
  showUpgradedBanner?: boolean;
  numberOfLinkedAccounts?: number;
  numberOfStaticLinkedAccounts?: number;
  isInvoiceDueDateNull?: boolean | undefined;
  invoiceDueDate?: string;
  pricingBannerRef: React.RefObject<HTMLInputElement>;
  dismiss?: () => void;
}

export const BannerToShow = ({
  showAnyBanner,
  bannerStatus,
  showUpgradedBanner,
  numberOfLinkedAccounts,
  numberOfStaticLinkedAccounts,
  invoiceDueDate,
  isInvoiceDueDateNull,
  pricingBannerRef,
  dismiss,
}: BannerToShowProps) => {
  function handleDismissClick(
    bannerStatus: BillingGuardrailsStatus,
    dismiss?: () => void,
  ): () => void {
    return () => {
      if (!dismiss) {
        return;
      }
      switch (bannerStatus) {
        case BillingGuardrailsStatus.BILLING_STATUS_FREE_AFTER_APRIL_2023:
          userEventTracker({
            event_name_input: "free_inform",
            impressions_input: 0,
            dismisses_input: 1,
            clicks_input: 0,
          });
          break;
        case BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT:
          userEventTracker({
            event_name_input: "launch_inform",
            impressions_input: 0,
            dismisses_input: 1,
            clicks_input: 0,
          });
          break;
        case BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT:
          userEventTracker({
            event_name_input: "launch_upsell",
            impressions_input: 0,
            dismisses_input: 1,
            clicks_input: 0,
          });
          break;
        default:
          break;
      }
      dismiss();
    };
  }

  function trackBannerClicks(bannerType: string): () => void {
    return () => {
      userEventTracker({
        event_name_input: `${bannerType}`,
        impressions_input: 0,
        dismisses_input: 0,
        clicks_input: 1,
      });
    };
  }

  useEffect(() => {
    if (
      showAnyBanner &&
      bannerStatus == BillingGuardrailsStatus.BILLING_STATUS_FREE_AFTER_APRIL_2023
    ) {
      userEventTracker({
        event_name_input: "free_inform",
        impressions_input: 1,
        dismisses_input: 0,
        clicks_input: 0,
      });
    }
    if (showAnyBanner && showUpgradedBanner) {
      userEventTracker({
        event_name_input: "launch_upgrade",
        impressions_input: 1,
        dismisses_input: 0,
        clicks_input: 0,
      });
    }
    if (
      showAnyBanner &&
      bannerStatus ==
        BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT
    ) {
      userEventTracker({
        event_name_input: "launch_inform",
        impressions_input: 1,
        dismisses_input: 0,
        clicks_input: 0,
      });
    }
    if (
      showAnyBanner &&
      bannerStatus == BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT
    ) {
      userEventTracker({
        event_name_input: "launch_upsell",
        impressions_input: 1,
        dismisses_input: 0,
        clicks_input: 0,
      });
    }
  }, []);

  return (
    <div ref={pricingBannerRef}>
      {showAnyBanner &&
        bannerStatus == InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT && (
          <InvoiceNonpaymentBanner showsIcon>
            <p>
              There was an issue with your most recent payment. Please resolve the issue and try
              again. Your organization will be blocked from Merge's API{" "}
              <b>after a 30 day grace period.</b>
            </p>
          </InvoiceNonpaymentBanner>
        )}
      {showAnyBanner &&
        bannerStatus ==
          InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD && (
          <InvoiceNonpaymentBanner showsIcon>
            <p>
              We are missing payment for your most recent invoice. Your organization will be blocked
              from Merge's API after a <b>30 day grace period.</b>
            </p>
          </InvoiceNonpaymentBanner>
        )}
      {showAnyBanner &&
        bannerStatus ==
          InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_FAILED_PAYMENT_PAST_30 && (
          <InvoiceNonpaymentBanner showsIcon>
            <p>
              There was an issue with your most recent payment. Please resolve the issue and try
              again. Your organization will be blocked from Merge's API until you resolve the issue
              and complete payment.
            </p>
          </InvoiceNonpaymentBanner>
        )}
      {showAnyBanner &&
        isInvoiceDueDateNull &&
        bannerStatus ==
          InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30 && (
          <InvoiceNonpaymentBanner showsIcon>
            <p>
              We are missing payment for your most recent invoice. Your organization will be blocked
              from Merge's API until you resolve the issue and complete payment.
            </p>
          </InvoiceNonpaymentBanner>
        )}
      {showAnyBanner &&
        !isInvoiceDueDateNull &&
        bannerStatus ==
          InvoiceNonpaymentStatus.BILLING_STATUS_OVERDUE_INVOICE_MISSING_PAYMENT_METHOD_PAST_30 && (
          <InvoiceNonpaymentBanner showsIcon>
            <p>
              We are missing payment for your most recent invoice <b>{invoiceDueDate}</b>. Your
              organization will be blocked from Merge's API until you resolve the issue and complete
              payment.
            </p>
          </InvoiceNonpaymentBanner>
        )}
      {showAnyBanner && bannerStatus == BillingGuardrailsStatus.BILLING_STATUS_FREE && (
        <FreeBanner showsIcon={false} link={MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH}>
          <p>
            You currently have <b>{numberOfLinkedAccounts} Production Linked Accounts</b> towards
            your <b>Free Plan</b>. You will automatically be upgraded to the <b>Launch Plan</b> when
            you exceed the Free Plan limit of 5 Production Linked Accounts.
          </p>
        </FreeBanner>
      )}

      {showAnyBanner &&
        bannerStatus == BillingGuardrailsStatus.BILLING_STATUS_LAUNCH &&
        !showUpgradedBanner && (
          <LaunchBanner showsIcon={false} link={GET_IN_TOUCH_PATH} buttonText="Contact us">
            <p>
              You currently have{" "}
              <b>
                {numberOfLinkedAccounts} out of{" "}
                {LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL} Production Linked
                Accounts
              </b>{" "}
              towards your <b>Launch Plan</b>. Contact our sales team to upgrade your plan to the
              next level.{" "}
              <a href={MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH} target="_blank" rel="noreferrer">
                Learn more
              </a>
            </p>
          </LaunchBanner>
        )}

      {showAnyBanner &&
        bannerStatus === BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_APPROACHING_GUARDRAILS && (
          <LaunchBanner showsIcon>
            <p>
              You currently have{" "}
              <b>
                {numberOfLinkedAccounts} out of{" "}
                {LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL} Production Linked
                Accounts
              </b>
              . Contact our sales team to upgrade your plan and prevent syncing blocks.{" "}
              <a href={MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH} target="_blank" rel="noreferrer">
                Learn more
              </a>
            </p>
          </LaunchBanner>
        )}

      {showAnyBanner &&
        (bannerStatus ===
          BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_GUARDRAILS ||
          bannerStatus ===
            BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_AT_LIMIT_NO_GUARDRAILS) && (
          <LaunchLimitBanner showsIcon>
            <p>
              You have reached the <b>Launch Plan</b> limit of{" "}
              <b>
                {LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL} Production Linked
                Accounts
              </b>
              . Contact our sales team to upgrade your plan and prevent syncing blocks.{" "}
              <a href={MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH} target="_blank" rel="noreferrer">
                Learn more
              </a>
            </p>
          </LaunchLimitBanner>
        )}

      {showAnyBanner &&
        bannerStatus ===
          BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_NO_GUARDRAILS && (
          <LaunchLimitBanner showsIcon>
            <p>
              You have exceeded the <b>Launch Plan</b> limit of{" "}
              <b>
                {LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL} Production Linked
                Accounts
              </b>
              . Contact our sales team to upgrade your plan and prevent syncing blocks.{" "}
              <a href={MERGE_HELP_CENTER_PATH} target="_blank" rel="noreferrer">
                Learn more
              </a>
            </p>
          </LaunchLimitBanner>
        )}

      {showAnyBanner &&
        bannerStatus ===
          BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_ACCOUNT_OVER_LIMIT_GUARDRAILS && (
          <LaunchGuardRailsBanner showsIcon>
            <p>
              You have exceeded the <b>Launch Plan</b> limit of{" "}
              <b>
                {LAUNCH_GUARDRAILS_LINKED_ACCOUNT_LIMIT__PRE_APRIL_2023_OVERHAUL} Production Linked
                Accounts
              </b>
              . Contact our sales team to upgrade your plan and unblock syncs.{" "}
              <a href={MERGE_BILLING_SELF_SERVE_HELP_ARTICLE_PATH} target="_blank" rel="noreferrer">
                Learn more
              </a>
            </p>
          </LaunchGuardRailsBanner>
        )}

      {/* // POST APRIL 1st, 2023 billing changes  */}

      {showAnyBanner &&
        bannerStatus == BillingGuardrailsStatus.BILLING_STATUS_FREE_AFTER_APRIL_2023 && (
          <FreeBanner
            showsIcon={false}
            dismissButton
            dismiss={handleDismissClick(
              BillingGuardrailsStatus.BILLING_STATUS_FREE_AFTER_APRIL_2023,
              dismiss,
            )}
            link={MERGE_BILLING_SELF_SERVE_AFTER_APRIL}
            onClickTracker={trackBannerClicks("free_inform")}
          >
            <p>
              You are currently using{" "}
              <b>{numberOfStaticLinkedAccounts} Production Linked Accounts</b> on the{" "}
              <b>Free Plan</b>. You will be automatically upgraded to the <b>Launch Plan</b> when
              you exceed the Free Plan limit of <b>3 Production Linked Accounts.</b>
            </p>
          </FreeBanner>
        )}

      {showAnyBanner && showUpgradedBanner && (
        <FreeToLaunchBanner
          showsIcon
          link={MERGE_BILLING_SELF_SERVE_AFTER_APRIL}
          buttonText="Learn more"
          onClickTracker={trackBannerClicks("launch_upgrade")}
        >
          <p>
            You have been automatically upgraded to the <b>Launch Plan</b>. You are currently using{" "}
            <b>{numberOfStaticLinkedAccounts} Production Linked Accounts</b> - refer to our pricing
            page for more information on the Launch Plan.
          </p>
        </FreeToLaunchBanner>
      )}

      {showAnyBanner &&
        bannerStatus ==
          BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT && (
          <LaunchBanner
            showsIcon
            dismissButton
            dismiss={handleDismissClick(
              BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_APPROACHING_CHARGE_LIMIT,
              dismiss,
            )}
            link={MERGE_BILLING_SELF_SERVE_AFTER_APRIL}
            buttonText="Learn more"
            onClickTracker={trackBannerClicks("launch_inform")}
          >
            <p>
              You are currently using{" "}
              <b>{numberOfStaticLinkedAccounts} out of 10 Production Linked Accounts.</b> You will
              automatically incur a $65/month prorated fee for every Production Linked Account past
              10.
            </p>
          </LaunchBanner>
        )}

      {showAnyBanner &&
        bannerStatus ==
          BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT && (
          <LaunchBanner
            showsIcon
            dismissButton
            dismiss={handleDismissClick(
              BillingGuardrailsStatus.BILLING_STATUS_LAUNCH_AFTER_APRIL_2023_UPSELL_MOMENT,
              dismiss,
            )}
            link={GET_IN_TOUCH_PATH}
            buttonText="Contact us"
            onClickTracker={trackBannerClicks("launch_upgrade")}
          >
            <p>
              You are currently using{" "}
              <b>{numberOfStaticLinkedAccounts} Production Linked Accounts</b> prorated at{" "}
              <b>$65 per account.</b> Contact us to see if an upgrade is right for you.
            </p>
          </LaunchBanner>
        )}
    </div>
  );
};
