import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { UserPlus } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface LinkedAccountLinkedWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: SelectedWebhookType | undefined;
}

function LinkedAccountLinkedWebhook(props: LinkedAccountLinkedWebhookProps) {
  const { onSelectedWebhookTypeChange, selectedWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Linked Account linked"
        description="Receive an alert when a new Linked Account is linked."
        icon={<UserPlus size={16} />}
        onClick={() => onSelectedWebhookTypeChange(SelectedWebhookType.LINKED)}
        selected={selectedWebhookType === SelectedWebhookType.LINKED}
        selectedWebhookType={selectedWebhookType}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default LinkedAccountLinkedWebhook;
