import React from "react";
import { Card } from "react-bootstrap";
import styled from "styled-components";
import { APICategory, SyncFrequencyPlan } from "../../../models/Entities";
import { firstLetterUpperCase, numberWithCommas } from "../../../services";
import { spectrum } from "../../../styles/theme";
import {
  BillingPlan,
  CategoryCharge,
  CurrentCategoryCharge,
  MergeInvoice,
  OrganizationBillingPlan,
  PaymentMethod,
  isCreditCard,
  BillingPlanTier,
} from "./BillingModels";

export const parseCategoryUnitCountsFromBillingPlan = (
  orgBillingPlan: OrganizationBillingPlan,
): CurrentCategoryCharge[] => {
  return Object.entries(orgBillingPlan.common_models_count)
    .map(([modelNameWithCategory, quantity]) => {
      const [category, modelName] = modelNameWithCategory.split(".") as [APICategory, string];
      const priceCents =
        orgBillingPlan.billing_plan.common_model_prices_cents[modelNameWithCategory];
      return {
        category,
        unit: modelName,
        quantity,
        priceCents,
      };
    })
    .concat(
      Object.entries(orgBillingPlan.linked_accounts_count).map(([category, quantity]) => {
        const priceCents =
          orgBillingPlan.billing_plan.linked_account_category_prices_cents[category];
        return { category: category as APICategory, unit: "Linked Account", quantity, priceCents };
      }),
    );
};

export const parseCategoryChargesFromInvoice = (invoice: MergeInvoice): CategoryCharge[] => {
  const categoryCharges: CategoryCharge[] = [];
  for (const [modelNameWithCategory, priceCents] of Object.entries(
    invoice.common_model_prices_cents,
  )) {
    const [category, modelName] = modelNameWithCategory.split(".") as [APICategory, string];
    const charge: CategoryCharge = {
      category,
      unit: modelName,
      priceCents,
      quantity: invoice.common_model_counts[modelNameWithCategory],
      totalCostCents: invoice.common_model_costs_cents[modelNameWithCategory],
    };
    categoryCharges.push(charge);
  }
  for (const [category, priceCents] of Object.entries(invoice.linked_account_prices_cents)) {
    const charge: CategoryCharge = {
      category: category as APICategory,
      unit: "Linked Account",
      priceCents,
      quantity: invoice.linked_account_counts[category],
      totalCostCents: invoice.linked_account_costs_cents[category],
    };
    categoryCharges.push(charge);
  }
  return categoryCharges;
};

/**
 * WARNING!!! These billing plan fields should not be used to gate features.
 *
 * Instead we should generally use Product Restriction (see useProductRestrictions hook).
 * This allows individually granting access to features
 */
export const isBillingPlanFreePlan = (billingPlan?: BillingPlan): boolean =>
  billingPlan?.plan_tier === BillingPlanTier.BILLING_PLAN_TIER_FREE;
export const isBillingPlanLaunchPlan = (billingPlan?: BillingPlan): boolean =>
  billingPlan?.plan_tier === BillingPlanTier.BILLING_PLAN_TIER_LAUNCH;
// feature set wise free and launch are the same (except free is only 3 LA and launch is > 3 LA)
export const isBillingPlanFreeLaunch = (billingPlan?: BillingPlan): boolean =>
  isBillingPlanFreePlan(billingPlan) || isBillingPlanLaunchPlan(billingPlan);
// feature set wise enterprise and expand are the same
export const isBillingPlanEnterpriseExpand = (billingPlan?: BillingPlan): boolean =>
  billingPlan?.plan_tier === BillingPlanTier.BILLING_PLAN_TIER_ENTERPRISE ||
  billingPlan?.plan_tier === BillingPlanTier.BILLING_PLAN_TIER_EXPAND;
// feature set wise professional and grow are the same
export const isBillingPlanProfessionalGrow = (billingPlan?: BillingPlan): boolean =>
  billingPlan?.plan_tier === BillingPlanTier.BILLING_PLAN_TIER_PROFESSIONAL ||
  billingPlan?.plan_tier === BillingPlanTier.BILLING_PLAN_TIER_PROFESSIONAL_STARTUP ||
  billingPlan?.plan_tier === BillingPlanTier.BILLING_PLAN_TIER_GROW;

export function getBillingPlanTitle(
  organizationBillingPlan:
    | OrganizationBillingPlan
    | Omit<OrganizationBillingPlan, "organization">
    | undefined,
) {
  switch (organizationBillingPlan?.billing_plan?.plan_tier) {
    case BillingPlanTier.BILLING_PLAN_TIER_CORE_STARTUP:
      return firstLetterUpperCase(BillingPlanTier.BILLING_PLAN_TIER_CORE);
    case BillingPlanTier.BILLING_PLAN_TIER_PROFESSIONAL_STARTUP:
      return firstLetterUpperCase(BillingPlanTier.BILLING_PLAN_TIER_PROFESSIONAL);
    default:
      return firstLetterUpperCase(organizationBillingPlan?.billing_plan?.plan_tier || "");
  }
}

export function formatSyncFrequncyPlans(syncFrequencyPlan: string) {
  switch (syncFrequencyPlan) {
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST:
      return "Highest";
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST_WITH_DELETED_DATA:
      return "Highest with Deleted Data";
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_HIGHEST_MANUAL:
      return "Highest Manual";
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_DAILY:
      return "Daily";
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_MONTHLY:
      return "Monthly";
    case SyncFrequencyPlan.SYNC_FREQUENCY_PLAN_QUARTERLY:
      return "Quarterly";
  }
  return "";
}

export function imageURLForCardBrand(paymentMethod: PaymentMethod) {
  if (isCreditCard(paymentMethod)) {
    switch (paymentMethod.brand.toLowerCase()) {
      case "amex":
        return "https://merge-fe-static-assets.s3.amazonaws.com/amex.svg";
      case "diners":
        return "https://merge-fe-static-assets.s3.amazonaws.com/diners_club.png";
      case "visa":
        return "https://merge-fe-static-assets.s3.amazonaws.com/visa.svg";
      case "mastercard":
        return "https://merge-fe-static-assets.s3.amazonaws.com/mastercard.png";
      case "discover":
        return "https://merge-fe-static-assets.s3.amazonaws.com/discover.png";
      case "jcb":
        return "https://merge-fe-static-assets.s3.amazonaws.com/jcb.png";
      default:
        return "#";
    }
  } else {
    return "https://merge-fe-static-assets.s3.amazonaws.com/bank.png";
  }
}

export const toTitleCase = (phrase: string) =>
  phrase
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const centsFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 10,
});

export const centsFormatterNearestCent = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 2,
});

const EM_DASH = <span className="text-gray-50">&mdash;</span>;

export const formatCentsOrDash = (cents?: number) =>
  cents ? centsFormatter.format(cents / 100) : EM_DASH;
export const formatCentsOrZero = (cents?: number) =>
  cents ? centsFormatter.format(cents / 100) : centsFormatter.format(0);
export const formatCentsNearestCent = (cents?: number) =>
  cents ? centsFormatterNearestCent.format(cents / 100) : centsFormatterNearestCent.format(0);
export const formatCentsNearestCentOrDash = (cents?: number) =>
  cents ? centsFormatterNearestCent.format(cents / 100) : EM_DASH;
export const formatNumberOrDash = (num?: number) => (num ? numberWithCommas(num) : EM_DASH);

/**
 * Shared Functions between the old and new Billing Plans.
 */
export const CurrentModelUsageCard = styled(Card)`
  && {
    background: ${spectrum.gray0}
    border-radius: 8px;
    border: 0px;
    margin-bottom: 0px;
    overflow: hidden;
    box-shadow: none;
  }
`;

export const CurrentModelUsageCardBody = styled(Card.Body)`
  && {
    background: #f9fafb;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const StyledLink = styled.a`
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
    text-decoration: underline;
  }
`;

/**
 * Get the date, in UTC, in format M/d/yyyy from the ISO string.
 * Do NOT let local tz affect the date-- so "2021-08-31T00:00:00Z" becomes 8/31/2021, NOT 8/30/2021,
 * when local tz offset is negative.
 * @param isoDate
 */
export const getFormattedDateFromISO = (isoDate: string) => {
  const date = new Date(isoDate);
  return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()}`;
};

export const getThirtyDaysLater = (isoDate: string) => {
  const originalDate = new Date(isoDate);
  const thirtyDaysLaterDate = new Date(
    originalDate.getFullYear(),
    originalDate.getMonth(),
    originalDate.getDate() + 30,
  );
  return getFormattedDateFromISO(thirtyDaysLaterDate.toISOString());
};

export const getDefaultPaymentMethod = (paymentMethods: PaymentMethod[] | null | undefined) => {
  if (!paymentMethods?.length) {
    return undefined;
  }
  const defaultPaymentMethods = paymentMethods.filter((method) => method.is_default_method);
  if (defaultPaymentMethods.length === 0) {
    return undefined;
  }
  return defaultPaymentMethods[0];
};
