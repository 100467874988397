import React, { useCallback, useEffect, useState } from "react";
import cx from "classnames";
import { Link, useHistory, Redirect } from "react-router-dom";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import MergeLogo from "../../../assets/svg/logos/merge/logo-large.svg";
import GemImage from "../../../assets/svg/logos/customers/gray/gem.svg";
import SemgrepImage from "../../../assets/svg/logos/customers/gray/semgrep.svg";
import ElectricImage from "../../../assets/svg/logos/customers/gray/electric.svg";
import BrexImage from "../../../assets/svg/logos/customers/gray/brex.svg";
import ApolloImage from "../../../assets/svg/logos/customers/gray/apollo.svg";
import GongImage from "../../../assets/svg/logos/customers/gray/gong.svg";
import CalendlyImage from "../../../assets/svg/logos/customers/gray/calendly.svg";
import AngellistImage from "../../../assets/svg/logos/customers/gray/angellist.svg";
import KornFerryImage from "../../../assets/svg/logos/customers/gray/kornferry.svg";
import RampImage from "../../../assets/svg/logos/customers/gray/ramp.svg";
import DrataImage from "../../../assets/svg/logos/customers/gray/drata.svg";
import WatershedImage from "../../../assets/svg/logos/customers/gray/watershed.svg";
import hubspotSignUp from "./HubspotSignUp";

import {
  fetchWithoutAuth,
  UserSuccessData,
  FormErrorData,
  setAuthTokenAndUserType,
  hasAuthToken,
  SSOLoginData,
  getTenancy,
  Tenancy,
} from "../../../api-client/APIClient";
import {
  LANDING_PAGE_PATH,
  LOGIN_PATH,
  navigateToHomePage,
  SUBSCRIBER_AGREEMENT_PATH,
  PRIVACY_POLICY_PATH,
  useQuery,
  navigateToSignUpPage,
  MERGE_APP_EU_PATH,
  SIGNUP_PATH,
  MERGE_APP_PATH,
} from "../../../router/RouterUtils";
import { User } from "../../../models/Entities";
import { AuthLayout } from "../AuthLayouts";
import SignInWithGoogleLink from "./SignInWithButtons";
import SpinnerButton from "../../shared-components/SpinnerButton";
import { REGEX_EMAIL_ADDRESS } from "../../shared-components/utils/SharedComponentUtils";
import FinishSocialSignUp from "./FinishSocialSignUp";
import { showErrorToast } from "../../shared-components/Toasts";

import {
  STANDARD_SIGNUP_FORM_ID,
  INVITE_SIGNUP_FORM_ID,
  EU_STANDARD_SIGNUP_FORM_ID,
  EU_INVITE_SIGNUP_FORM_ID,
} from "./HubspotForms";
import useSSOFeatureFlags from "../saml/SamlUtils";
import { Globe } from "lucide-react";
import HorizontalRuleWithText from "../../shared-components/HorizontalRuleWithText";
import DeprecatedH1 from "../../../deprecated/DeprecatedH1";
import DeprecatedH3 from "../../../deprecated/DeprecatedH3";

const Title = styled(DeprecatedH1)`
  font-size: 40px;
  font-weight: 700;
  line-height: 1.15;
`;

const StyledInput = styled.input`
  && {
    background-color: #ffffff;
    border: 0.9375px solid #111317;
    border-radius: 3.75px;

    :checked {
      background-color: #111317;
    }
    :checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }
`;

const GridContainer = styled.div`
  grid-column-gap: 45px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-auto-columns: minmax(0, 1fr);
  display: grid;

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

interface ImageProps {
  height?: number;
  width?: number;
}

const SetHeightWidthImageContainer = styled.img<ImageProps>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

interface OrgForInviteToken {
  has_saml_provider: boolean;
  saml_is_required: boolean;
  user_with_email_exists: boolean;
  name: string;
}

interface FormData {
  name: string;
  organization?: string;
  email: string;
  password?: string;
  agreed_to_terms_of_service_and_read_privacy_policy: boolean;
}

export interface SignUpUserData extends FormData {
  use_saml?: boolean;
  email_token?: string | null;
  social_auth_provider?: string | null;
  id_token?: string | null;
}

const parseJWT = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join(""),
    );

    return JSON.parse(jsonPayload);
  } catch (_) {
    return undefined;
  }
};

type Props = {
  setUser: (user: User) => void;
};

const SignUpScreen = ({ setUser }: Props) => {
  const history = useHistory();
  const query = useQuery();
  const idToken = query.get("id_token");
  const parsedJWT = idToken && parseJWT(idToken);
  const email = parsedJWT
    ? parsedJWT["email_verified"] && parsedJWT["email"] === query.get("email") && query.get("email")
    : query.get("email");
  const name = parsedJWT?.["name"];
  const inviteToken = query.get("token");
  const createUserToken = query.get("create_user_token");
  const socialAuthProvider = query.get("social_auth_provider");
  const [organizationName, setOrganizationName] = useState<string | null>(null);
  const [isErrorShown, setIsErrorShown] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [isSamlRequired, setIsSamlRequired] = useState<boolean>(false);
  const isEUMultiTenant = getTenancy() === Tenancy.EUMultiTenant;
  const isSingleTenant = getTenancy() == Tenancy.SingleTenant;
  const isDesktopOrTablet = useMediaQuery({ query: "(min-width: 768px)" });
  const hasSocialAuthData = Boolean(socialAuthProvider && idToken && email);
  const { isGoogleSSODisabled } = useSSOFeatureFlags();
  const hideSignInWithGoogle = Boolean(hasSocialAuthData || isSamlRequired || isGoogleSSODisabled);
  const [backendFieldValidationError, setBackendFieldValidationError] = useState<string>("");

  let createAccountButtonGTag = "";
  let hubspotSignupFormID = "";

  if (isEUMultiTenant) {
    if (inviteToken) {
      createAccountButtonGTag = "gtag-create-account-invite_eu";
    } else {
      if (isSamlRequired) {
        createAccountButtonGTag = "gtag-create-account-with-sso_eu";
      } else {
        createAccountButtonGTag = "gtag-create-account_eu";
      }
    }
    hubspotSignupFormID = EU_INVITE_SIGNUP_FORM_ID;
  } else {
    if (inviteToken) {
      createAccountButtonGTag = "gtag-create-account-invite";
    } else {
      if (isSamlRequired) {
        createAccountButtonGTag = "gtag-create-account-with-sso";
      } else {
        createAccountButtonGTag = "gtag-create-account";
      }
      hubspotSignupFormID = INVITE_SIGNUP_FORM_ID;
    }
  }

  const isUnknownBackendError = (errorMessage: string): boolean => {
    const fieldNames = ["company", "email", "password", "agree"];
    return !fieldNames.some((fieldName) => errorMessage.toLowerCase().includes(fieldName));
  };

  const { register, handleSubmit, setError, errors } = useForm();
  const handleSuccessfulSignUp = useCallback(
    (data: UserSuccessData, hubspotFormId: string) => {
      setAuthTokenAndUserType(data.token, data.user.type);
      setUser(data.user);
      navigateToHomePage(history);
      setIsLoading(false);
      hubspotSignUp(
        {
          name: data.user.name,
          organization: data.user.organization.name,
          email: data.user.email,
        },
        hubspotFormId,
      );
    },
    [history, setUser],
  );

  const performSignUp = (data: FormData) => {
    setIsLoading(true);
    if (inviteToken) {
      const userInfo: SignUpUserData = {
        name: data.name,
        organization: data.organization,
        email: data.email,
        password: data.password,
        email_token: inviteToken,
        agreed_to_terms_of_service_and_read_privacy_policy:
          data.agreed_to_terms_of_service_and_read_privacy_policy,
        use_saml: isSamlRequired,
      };
      fetchWithoutAuth({
        path: "/users/create",
        method: "POST",
        body: userInfo,
        onResponse: (data: Partial<UserSuccessData & SSOLoginData>) => {
          if (data.sso_url) {
            const ssoData = data as SSOLoginData;
            window.location.href = ssoData.sso_url;
            return;
          }
          handleSuccessfulSignUp(data as UserSuccessData, hubspotSignupFormID);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  if (isUnknownBackendError(data[field_name][0])) {
                    setIsErrorShown(data[field_name][0]);
                  } else {
                    setBackendFieldValidationError(data[field_name][0]);
                  }
                  continue;
                }
                setError(field_name, { message: data[field_name][0] });
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    } else {
      const userInfo: SignUpUserData = {
        name: data.name,
        organization: data.organization,
        email: data.email,
        password: data.password,
        agreed_to_terms_of_service_and_read_privacy_policy:
          data.agreed_to_terms_of_service_and_read_privacy_policy,
      };
      fetchWithoutAuth({
        path: "/users/create/send-token",
        method: "POST",
        body: userInfo,
        onResponse: () => {
          setIsLoading(false);
          setIsEmailSent(true);
        },
        onError: (err: Response | undefined) => {
          if (err) {
            err.json().then((data: FormErrorData) => {
              for (const field_name in data) {
                if (field_name === "non_field_errors") {
                  if (isUnknownBackendError(data[field_name][0])) {
                    setIsErrorShown(data[field_name][0]);
                  } else {
                    setBackendFieldValidationError(data[field_name][0]);
                  }
                  continue;
                }
                setError(field_name, { message: data[field_name][0] });
              }
            });
          } else {
            showErrorToast("A network error has occurred. Please try again.");
          }
          setIsLoading(false);
        },
      });
    }
  };

  const finishAccountCreation = useCallback(
    (createUserToken: string) => {
      fetchWithoutAuth({
        path: "/users/create/finish",
        method: "POST",
        body: { token: createUserToken },
        onResponse: (data: UserSuccessData) => {
          if (isEUMultiTenant) {
            handleSuccessfulSignUp(data, EU_STANDARD_SIGNUP_FORM_ID);
          } else {
            handleSuccessfulSignUp(data, STANDARD_SIGNUP_FORM_ID);
          }
        },
        onError: () => {
          navigateToSignUpPage(history);
          setIsErrorShown("Token was invalid, please try again.");
        },
      });
    },
    [handleSuccessfulSignUp, history, isEUMultiTenant],
  );

  useEffect(() => {
    if (createUserToken) {
      return finishAccountCreation(createUserToken);
    }
    if (inviteToken) {
      setIsLoading(true);
      fetchWithoutAuth({
        path: `/organizations/users/invites/tokens/${inviteToken}`,
        method: "GET",
        onResponse: (data: OrgForInviteToken) => {
          if (data.user_with_email_exists) {
            // If an existing user wants to accept an invite to join another org,
            // they need to login first and join from within the app.
            history.replace(LOGIN_PATH);
            return;
          }
          setOrganizationName(data.name);
          setIsSamlRequired(data.saml_is_required);
          setIsLoading(false);
        },
        onError: () => {
          setOrganizationName(null);
          setIsLoading(false);
          setIsErrorShown("Token was invalid, please try again.");
        },
      });
    }
  }, [createUserToken, finishAccountCreation, history, inviteToken]);

  if (hasAuthToken()) {
    return <Redirect to="/" />;
  }

  if (createUserToken) {
    return (
      <AuthLayout
        title="Creating your account..."
        description="Hold tight, this should only take a few seconds— If the page is stuck, then please refresh and try again."
      />
    );
  }

  if (isEmailSent) {
    return (
      <AuthLayout title="Check your email inbox">
        <>
          <p className="text-center">We've sent you an email to complete account creation.</p>
          <p className="text-center">
            If you don't see it immediately, please search your inbox for an email from
            hello@merge.dev, check your spam folder, and, for Gmail users, check autofilter
            locations like the Forums folder.
          </p>
        </>
      </AuthLayout>
    );
  }

  if (hasSocialAuthData) {
    return (
      <FinishSocialSignUp
        email={email as string}
        handleSuccessfulSignUp={handleSuccessfulSignUp}
        idToken={idToken as string}
        inviteToken={inviteToken}
        name={name}
        organizationName={organizationName}
        socialAuthProvider={socialAuthProvider as string}
      />
    );
  }

  return (
    <>
      <div className="SignUp">
        <Row>
          <div
            className={cx(
              "SignUp-header d-flex align-items-center",
              isDesktopOrTablet ? "justify-content-between" : "justify-content-center",
            )}
          >
            <a href={LANDING_PAGE_PATH}>
              <img src={MergeLogo} />
            </a>

            {isDesktopOrTablet && (
              <div>
                <a href="https://www.merge.dev/get-in-touch">
                  <button
                    className="btn-request-a-demo"
                    id="gtag-request-demo"
                    aria-label="Request a demo"
                  >
                    Request a demo
                  </button>
                </a>
                <Link to={LOGIN_PATH}>
                  <button className="btn-sign-in-instead">Sign in</button>
                </Link>
              </div>
            )}
          </div>
          {isDesktopOrTablet && <div className="deprecated-mb-4">&nbsp;</div>}
        </Row>

        <Container className="SignUp-form deprecated-px-4 d-flex align-items-center justify-content-center">
          <Row>
            <Col md={6} sm={12} className="flex-column justify-content-center form-group">
              <Title className={cx("deprecated-mb-5", !isDesktopOrTablet && "text-center")}>
                Create account
              </Title>
              <Form
                onSubmit={handleSubmit(performSignUp)}
                id={inviteToken ? "gtag-invite-form" : "gtag-signup-form"}
              >
                {!hideSignInWithGoogle && (
                  <>
                    <Form.Group>
                      <SignInWithGoogleLink text="Sign up with Google" />
                    </Form.Group>
                    <Form.Group>
                      <HorizontalRuleWithText text="OR" />
                    </Form.Group>
                  </>
                )}
                <Form.Group>
                  {isErrorShown && <Alert variant="dark">{isErrorShown}</Alert>}
                  <Form.Label htmlFor="name">Full name</Form.Label>
                  <Form.Control
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Full name"
                    className={cx({
                      "is-invalid": errors.name,
                    })}
                    ref={register({
                      required: true,
                    })}
                    defaultValue={name || undefined}
                    readOnly={!!name}
                    isInvalid={errors.name}
                  />
                  <Form.Control.Feedback type="invalid">Please enter a name.</Form.Control.Feedback>
                </Form.Group>
                {!inviteToken && (
                  <Form.Group>
                    <Form.Label htmlFor="organization">Company</Form.Label>
                    <Form.Control
                      name="organization"
                      id="organization"
                      type="text"
                      placeholder="Company"
                      className={cx({
                        "is-invalid": errors.organization,
                      })}
                      onChange={() => setBackendFieldValidationError("")}
                      isInvalid={
                        errors.organization ||
                        backendFieldValidationError.toLowerCase().includes("company")
                      }
                      ref={register({
                        required: true,
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {backendFieldValidationError.toLowerCase().includes("company")
                        ? backendFieldValidationError
                        : "Please enter a company name."}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label htmlFor="email">Email</Form.Label>
                  <Form.Control
                    name="email"
                    id="email"
                    type="email"
                    placeholder="you@email.com"
                    className={cx({ "is-invalid": errors.email })}
                    onChange={() => setBackendFieldValidationError("")}
                    isInvalid={
                      errors.email || backendFieldValidationError.toLowerCase().includes("email")
                    }
                    ref={register({
                      required: true,
                      pattern: REGEX_EMAIL_ADDRESS,
                    })}
                    defaultValue={email || undefined}
                    readOnly={!!email}
                  />

                  <Form.Control.Feedback type="invalid">
                    {backendFieldValidationError.toLowerCase().includes("email")
                      ? backendFieldValidationError
                      : "Please enter a valid email address."}
                  </Form.Control.Feedback>
                </Form.Group>
                {!isSamlRequired && (
                  <Form.Group>
                    <Form.Label htmlFor="password">Password</Form.Label>
                    <Form.Control
                      name="password"
                      type="password"
                      id="password"
                      placeholder="Password"
                      className={cx({
                        "is-invalid": errors.password,
                      })}
                      onChange={() => setBackendFieldValidationError("")}
                      isInvalid={
                        errors.password ||
                        backendFieldValidationError.toLowerCase().includes("password")
                      }
                      ref={register({
                        required: true,
                        minLength: 8,
                        maxLength: 100,
                      })}
                    />

                    <Form.Control.Feedback type="invalid">
                      {backendFieldValidationError.toLowerCase().includes("password")
                        ? backendFieldValidationError
                        : "Please enter a valid password at least 8 characters in length."}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}{" "}
                <Form.Group>
                  <Form.Check
                    onChange={() => setBackendFieldValidationError("")}
                    isInvalid={
                      errors.password || backendFieldValidationError.toLowerCase().includes("agree")
                    }
                  >
                    <StyledInput
                      name="agreed_to_terms_of_service_and_read_privacy_policy"
                      id="agreed_to_terms_of_service_and_read_privacy_policy"
                      type="checkbox"
                      className={cx("form-check-input", {
                        "is-invalid": errors.agreed_to_terms_of_service_and_read_privacy_policy,
                      })}
                      ref={register({
                        required: true,
                      })}
                    />
                    <Form.Check.Label
                      className="small"
                      htmlFor="agreed_to_terms_of_service_and_read_privacy_policy"
                    >
                      I agree to the{" "}
                      <a
                        className="font-semibold"
                        href={SUBSCRIBER_AGREEMENT_PATH}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Subscriber Agreement
                      </a>{" "}
                      and have read the{" "}
                      <a
                        className="font-semibold"
                        href={PRIVACY_POLICY_PATH}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                      .
                    </Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                      {backendFieldValidationError.includes("agree")
                        ? backendFieldValidationError
                        : "Please agree to the Subscriber Agreement and read the Privacy Policy."}
                    </Form.Control.Feedback>
                  </Form.Check>
                </Form.Group>
                <Form.Group>
                  {isSamlRequired ? (
                    <SpinnerButton
                      text="Create Account with SSO"
                      isLoading={isLoading}
                      className="primaryAction btn btn-primary btn-block"
                      id={createAccountButtonGTag}
                    />
                  ) : (
                    <SpinnerButton
                      text="Create account"
                      isLoading={isLoading}
                      className="primaryAction btn btn-primary btn-block"
                      id={createAccountButtonGTag}
                    />
                  )}
                </Form.Group>
                <Row
                  className={cx("justify-content-center", !isDesktopOrTablet && "deprecated-mb-4")}
                >
                  {!isEUMultiTenant && !isSingleTenant ? (
                    <a href={`${MERGE_APP_EU_PATH}${SIGNUP_PATH}`}>
                      <Globe className="deprecated-mr-1 deprecated-mb-1" size={16} /> Change region
                      to EU
                    </a>
                  ) : (
                    <a href={`${MERGE_APP_PATH}${SIGNUP_PATH}`}>
                      <Globe className="deprecated-mr-1 deprecated-mb-1" size={16} /> Change region
                      to US
                    </a>
                  )}
                  {isSingleTenant && (
                    <a href={`${MERGE_APP_PATH}${SIGNUP_PATH}`}>
                      <Globe className="deprecated-mr-1 deprecated-mb-1" size={16} /> Change region
                      to US
                    </a>
                  )}
                </Row>
                {!isDesktopOrTablet && (
                  <Row className="deprecated-mb-5">
                    <Col className="text-center">
                      <Link to={LOGIN_PATH}>Already have an account?</Link>
                    </Col>
                  </Row>
                )}
              </Form>
            </Col>
            <Col
              md={6}
              sm={12}
              className="selling-points deprecated-my-5 d-flex flex-column justify-content-center"
            >
              <h3 className="mb-6">Build better faster</h3>
              <p className="selling-point">
                <span className="selling-point-check">
                  <i className="fe fe-check" />
                </span>
                Turn on integrations with a click and let your engineers focus on the product.
              </p>
              <p className="selling-point">
                <span className="selling-point-check">
                  <i className="fe fe-check" />
                </span>
                Give your team the tools they need to easily manage user integrations.
              </p>
              <p className="selling-point">
                <span className="selling-point-check">
                  <i className="fe fe-check" />
                </span>
                Stop missing sales due to missing integrations.
              </p>
              <h3 className="mb-8 mt-9">Join companies already on Merge</h3>
              <GridContainer>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={SemgrepImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={ElectricImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={CalendlyImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={WatershedImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={GongImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={BrexImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={ApolloImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={AngellistImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={DrataImage} width={92} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={GemImage} width={80} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={KornFerryImage} width={120} />
                </div>
                <div className="w-100 justify-content-start align-items-center d-flex">
                  <SetHeightWidthImageContainer src={RampImage} width={90} />
                </div>
              </GridContainer>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SignUpScreen;
