import React from "react";
import { Webhook } from "../../../../../models/Entities";
import SkeletonLoader from "../../../../shared-components/SkeletonLoader";
import clsx from "clsx";
import { ChevronRight, HardDrive } from "lucide-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";

interface WebhookRowProps {
  webhook?: Webhook;
  isLoading: boolean;
  isLastWebhook: boolean;
  onClick?: () => void;
}
const MaxWidthTooltip = styled(Tooltip)`
  &&& .tooltip-inner {
    max-width: 300px !important;
  }
`;

const WebhookRow: React.FC<WebhookRowProps> = ({ webhook, isLoading, isLastWebhook, onClick }) => {
  return (
    <div
      className={clsx(
        "flex flex-row items-center cursor-pointer min-w-0 hover:bg-[#f9fbfd] px-6 py-4",
        isLastWebhook ? null : "border-b border-gray-10",
        isLoading ? null : "justify-between",
      )}
      onClick={onClick}
    >
      {isLoading ? (
        <SkeletonLoader width={160} height={12} borderRadius={4} />
      ) : (
        <div className="flex flex-row items-center justify-center min-w-0">
          <div>
            <HardDrive className="text-gray-40 mr-3" size={16} />
          </div>
          {webhook && (
            <OverlayTrigger
              placement="top"
              delay={{ show: 0, hide: 0 }}
              overlay={
                <MaxWidthTooltip id="show-full-webhook-target-url">
                  {webhook?.target}
                </MaxWidthTooltip>
              }
            >
              <div className="text-base text-left text-black truncate min-w-0">
                {webhook.target}
              </div>
            </OverlayTrigger>
          )}
        </div>
      )}
      {!isLoading && (
        <div>
          <ChevronRight className="text-gray-40 ml-1" size={16} />
        </div>
      )}
    </div>
  );
};

export default WebhookRow;
