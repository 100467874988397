import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { CommonModelBadge } from "../../../shared-components/MergeBadges";
import { displayNameForAPICategory } from "../../../../models/Helpers";
import { APICategory } from "../../../../models/Entities";
import { MoreHorizontal } from "react-feather";
import { Globe } from "lucide-react";
import { Badge, Dropdown } from "react-bootstrap";
import DropdownToggle from "../../../shared-components/DropdownToggle";
import DeleteFieldMappingModal from "../../../integrations-management/linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal";
import { navigateToConfigurationFieldMappingTargets } from "../../../../router/RouterUtils";
import { useState } from "react";
import useAppContext from "../../../context/useAppContext";
import DeleteFieldMappingModal_OLD from "../../../integrations-management/linked-accounts/detail-page/field-mappings/DeleteFieldMappingModal_old";
import MoreHorizontalPopover from "../../../shared-components/MoreHorizontalPopover";
const FieldMappingCommonModelName = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
`;

const FieldMappingKeyName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 9px;
`;

const FieldMappingKeyNameV2 = styled.div`
  color: var(--gray90, #121314);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  margin-top: 9px;
  margin-right: 12px;
`;

const FieldMappingKeyDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 850px;
`;

const CommonModelNameBadge = styled(Badge)`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-right: 8px;
`;

export const StyledDropdownItem = styled(Dropdown.Item)`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  height: 36px;
  color: #000000;
  &:hover {
    background: var(--gray0, #f2f4f7);
  }
`;

type IntegrationFieldMappingsHeaderProps = {
  commonModel: String;
  title: String;
  category: APICategory;
  description: String;
  fieldMappingTargetID: string;
  refreshFieldMappingsAndConfigurations: () => void;
};
const IntegrationFieldMappingsHeader = ({
  fieldMappingTargetID,
  commonModel,
  title,
  category,
  description,
  refreshFieldMappingsAndConfigurations,
}: IntegrationFieldMappingsHeaderProps) => {
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { user } = useAppContext();
  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);
  return (
    <>
      {isFieldMappingV2Enabled ? (
        <DeleteFieldMappingModal
          fieldMappingTargetID={fieldMappingTargetID}
          isFieldMappingTargetDelete
          handlePostDelete={() => {
            refreshFieldMappingsAndConfigurations();
            navigateToConfigurationFieldMappingTargets(history);
          }}
          showModal={showModal}
          onHide={() => setShowModal(false)}
          headerText={"Delete Target Field"}
          text="This will delete all Field Mappings associated with this target field."
        />
      ) : (
        <DeleteFieldMappingModal_OLD
          fieldMappingTargetID={fieldMappingTargetID}
          isFieldMappingTargetDelete
          handlePostDelete={() => {
            refreshFieldMappingsAndConfigurations();
            navigateToConfigurationFieldMappingTargets(history);
          }}
          showModal={showModal}
          onHide={() => setShowModal(false)}
          headerText={"Delete Mapping Target"}
          text="This will delete all Field Mappings associated with this target field."
        />
      )}
      <div className="h-2 bg-orange-20 rounded-t-lg"></div>
      <div className="bg-white shadow-md rounded-lg">
        <div className="d-flex pl-6 pr-6 pb-5 pt-3">
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex align-items-center">
              {isFieldMappingV2Enabled && (
                <div className="text-gray-90 mr-3 font-mono font-semibold text-[20px]">{title}</div>
              )}
            </div>
            <div className="mt-2">
              <Globe size={12} className="text-orange-40" />
              <span className="ml-1 text-[12px] font-semibold">Organization-wide target field</span>
              <CommonModelNameBadge
                className="ml-3"
                style={{ background: "#F2F4F7", color: "#50545B" }}
              >
                {commonModel}
              </CommonModelNameBadge>
              <CommonModelBadge commonModel={displayNameForAPICategory(category)} />
            </div>
            {!isFieldMappingV2Enabled && <FieldMappingKeyName>{title}</FieldMappingKeyName>}
            <div className="text-base mt-2 truncate max-w-[850px] text-gray-60 font-inter">
              {description}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <MoreHorizontalPopover
              menuOptions={
                <>
                  {isFieldMappingV2Enabled ? (
                    <StyledDropdownItem
                      className="ellipses-dropdown-item dropdown-item"
                      onSelect={() => {
                        setShowModal(true);
                      }}
                    >
                      Delete Target Field
                    </StyledDropdownItem>
                  ) : (
                    <Dropdown.Item
                      className="ellipses-dropdown-item dropdown-item"
                      onSelect={() => {
                        setShowModal(true);
                      }}
                    >
                      Delete Target Field
                    </Dropdown.Item>
                  )}
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntegrationFieldMappingsHeader;
