import React, { useEffect, useRef, useState } from "react";
import { LinkedAccountWebhookReceivers, WebhookReceiver } from "../../../../../models/Entities";
import WebhookReceiverRow from "./WebhookReceiverRow";
import { ChevronRight } from "lucide-react";
import SkeletonLoader from "../../../../shared-components/SkeletonLoader";
import MergeTable from "../../../../shared-components/MergeTable";

interface Props {
  /**
   * Name of the linked integration
   */
  integrationName: string;

  /**
   * The webhooks data source
   */
  accountReceivers: LinkedAccountWebhookReceivers | null;

  /**
   * The id of the linked account that shows these events
   */
  linkedAccountId: string;

  /**
   * Takes one receiver at an index and updates it
   */
  updateReceiverAtIndex: (receiver: WebhookReceiver, index: number) => void;

  /**
   * Present if we show prewritten instructions for setting up webhooks
   * for this integration.
   */
  webhookInstructions?: string | null;

  /**
   * True if the org has enabled auto webhook setup and the integration uses it.
   */
  isUsingAutoWebhookSetup?: boolean;
}

/**
 * Shows a table with rows for each event that has a webhook receiver for it right now.
 */
const WebhookEventsTable = ({
  integrationName,
  accountReceivers,
  linkedAccountId,
  updateReceiverAtIndex,
  webhookInstructions,
  isUsingAutoWebhookSetup,
}: Props) => {
  const receivers: WebhookReceiver[] = accountReceivers?.webhook_receivers || [];

  const integrationUsesWebhookHandshake =
    (accountReceivers && accountReceivers.integration_uses_webhook_handshake) || false;

  const [rowWidth, setRowWidth] = useState<number>(516);
  const tableRef = useRef<HTMLDivElement>(null);

  const updateWidth = () => {
    if (tableRef.current) {
      setRowWidth(tableRef.current.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, [tableRef, rowWidth]);

  const tableHeader = (
    <>
      <th scope="col">
        <div className="font-semibold">{integrationName} Event</div>
      </th>
      <th scope="col font-semibold">
        <div className="font-semibold">Merge Receiver</div>
      </th>
      <th scope="col font-semibold">
        <div className="font-medium">Last Received</div>
      </th>
      <th scope="col" key="expander" />
    </>
  );

  const skeletonRows = new Array(8).fill(null).map((_, index) => (
    <tr key={index}>
      <td>
        <SkeletonLoader width={280} height={12} borderRadius={4} />
      </td>
      <td>
        <SkeletonLoader width={106} height={12} borderRadius={4} />
      </td>
      <td>
        <SkeletonLoader width={120} height={12} borderRadius={4} />
      </td>
      <td className="d-flex justify-content-end">
        <ChevronRight size={12} className="text-gray-50" />
      </td>
    </tr>
  ));

  const receiverRows = accountReceivers
    ? receivers.map((receiver, index) => (
        <WebhookReceiverRow
          key={receiver.event}
          receiver={receiver}
          width={rowWidth}
          integrationName={integrationName}
          linkedAccountId={linkedAccountId}
          updateReceiver={(receiver) => updateReceiverAtIndex(receiver, index)}
          integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
          isUsingAutoWebhookSetup={isUsingAutoWebhookSetup}
          webhookInstructions={webhookInstructions}
        />
      ))
    : skeletonRows;

  return (
    <>
      <div ref={tableRef}>
        <MergeTable
          hasMorePaddingOnFirstElement
          header={tableHeader}
          content={receiverRows}
          hasMarginBottom={false}
        />
      </div>
    </>
  );
};

export default WebhookEventsTable;
