import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const ToggleLabel = styled.label`
  display: block;
  cursor: pointer;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 3px;

  // Customize the size of the bootstrap toggle switch.
  .custom-switch {
    transform: scale(calc(3 / 4));
    position: relative;
    top: 7px;
    left: -6px;
    width: 36px;
    margin: 0;
    height: 24px;
  }
`;

const ToggleLabelText = styled.span`
  margin-left: -4px;
`;

interface ToggleProps {
  checked: boolean;
  disabled?: boolean;
  id: string;
  label: string;
  onClick: () => void;
}

const Toggle = ({ checked, disabled, id, label, onClick }: ToggleProps) => {
  // When disabled=true, mark the Toggle as checked=false, but don't mark it as disabled=false because
  // that makes it visually disappear entirely for some reason. Instead, just disable the "onClick"
  // function to effectively disable the toggle.
  return (
    <ToggleLabel htmlFor={id}>
      <Form.Check
        type="switch"
        id={id}
        checked={!disabled && checked}
        onChange={() => null}
        onClick={() => !disabled && onClick()}
        inline
      />
      <ToggleLabelText>{label}</ToggleLabelText>
    </ToggleLabel>
  );
};

export default Toggle;
