import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import ModalButton from "./ModalButton";
import type { ModalProps } from "./ModalProps";

export type Props = {
  /**
   * Modal title
   */
  title: string;
} & ModalProps &
  Pick<React.ComponentProps<"div">, "children" | "className">;

const ModalTitle = styled(Modal.Title)`
  margin-bottom: 0;
`;

// Can't directly style this since it'll affect the whole thing, not just the modal contents
const StyledModal = styled(Modal)`
  & .modal-dialog {
    max-width: 360px;
  }
  & .modal-body {
    padding-bottom: 12px;
  }
`;

/**
 * Shows a generic modal with some contents - has a built in, bottom-aligned
 * cancel button.
 */
const SkinnyModal = ({ isOpen, closeModal, title, children, className }: Props) => (
  <StyledModal show={isOpen} onHide={closeModal} className={className}>
    <Modal.Header closeButton>
      <ModalTitle>{title}</ModalTitle>
    </Modal.Header>
    <Modal.Body>
      {children}
      <ModalButton className="deprecated-mt-2" $hasNoBorder variant="white" onClick={closeModal}>
        Cancel
      </ModalButton>
    </Modal.Body>
  </StyledModal>
);

export default SkinnyModal;
