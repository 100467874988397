import React from "react";
import SmallGreyBox from "../../../../shared-components/SmallGreyBox";
import { ChevronRight } from "lucide-react";
import ClickableContainer from "../../../../shared-components/ClickableContainer";
import LinkedAccountSettingToggle, {
  LinkedAccountSettingToggleProps,
} from "./LinkedAccountSettingToggle";

interface Props {
  cardTitle: string;
  cardSubtitle: JSX.Element | string;
  onClick?: () => void;
  includeRightChevron?: boolean;
  smallGreyBoxChildren?: string | number | JSX.Element;
  linkedAccountSettingToggleProps?: LinkedAccountSettingToggleProps;
}

const ConfigurationWidgetCard: React.FC<Props> = ({
  cardTitle,
  cardSubtitle,
  onClick,
  includeRightChevron,
  smallGreyBoxChildren,
  linkedAccountSettingToggleProps,
}: Props) => {
  const content = (
    <div className="flex flex-col bg-white shadow-sm rounded-md py-2.5 pl-4 pr-3">
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <div className="font-semibold text-base">{cardTitle}</div>
          {includeRightChevron && <ChevronRight className="ml-1" size={12} />}
        </div>
        {smallGreyBoxChildren !== undefined && (
          <div className="mr-3">
            <SmallGreyBox children={smallGreyBoxChildren} />
          </div>
        )}
      </div>
      {linkedAccountSettingToggleProps && (
        <div className="pb-2 pr-4 mb-2 border-b border-gray-10">
          <LinkedAccountSettingToggle {...linkedAccountSettingToggleProps} />
        </div>
      )}
      <div className="text-gray-50 text-xs font-normal">{cardSubtitle}</div>
    </div>
  );

  return onClick ? <ClickableContainer onClick={onClick}>{content}</ClickableContainer> : content;
};

export default ConfigurationWidgetCard;
