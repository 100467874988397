import { Globe, Plus, User } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingCreationAndEditDict,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../models/Entities";
import DeleteFieldMappingModal from "./DeleteFieldMappingModal";
import {
  DropDownOptionColumn1,
  FieldMappingInfoTopRow,
  OriginTypeBadge,
  SFMonoText,
  StyledDropdownAPIEndpointPath,
  StyledSmallTextMutedParagraph,
  TargetMessage,
  DropdownItem,
  hideTooltipOnScroll,
} from "./FieldMappingInstanceCard";
import { CodeText } from "../../../../shared-components/MergeText";
import { createFieldMappingOptions } from "./CreateLinkedAccountFieldMappingPage";
import MergeTypeahead from "../../../../shared-components/MergeTypeahead";
import {
  NewFieldMappingProps,
  createLinkedAccountFieldMapping,
  fetchFieldMappingInstance,
} from "../../../../../api-client/APIClient";
import { FieldMappingSource } from "../../../../../constants";
import { SmallWhiteButton, capitalizeFirstLetter } from "@merge-api/merge-javascript-shared";
import { Loader } from "react-bootstrap-typeahead";
import { FillerDiv } from "./LinkedAccountCommonModelFieldMappingOverview";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import { Tooltip } from "react-bootstrap";
import { spectrum } from "../../../../../styles/theme";
import { ArrowLeft } from "lucide-react";

type Props = {
  fieldMappingTarget: FieldMappingTarget;
  linkedAccount: LinkedAccount;
  fieldMappingConfig: FieldMappingCommonModelConfiguration;
  fieldMappingMetaResponse: FieldMappingMetaResponse | undefined;
  newIntegrationFieldMappingCreated: (
    target: FieldMappingTarget,
    instance: FieldMappingInstance,
  ) => void;
  cancelNewMapping: () => void;
  creatingNewOrgFieldMapping: () => void;
  originOptionsAvailable: boolean;
};

const LinkedAccountFieldMappingContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 0.5fr 4fr 0.1fr;
`;

const FieldMappingInfoCard = styled.div`
  box-shadow: 0px 1px 0px #eaeef3;
  border-radius: 8px 8px 0 0 !important;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 3px;
  border-radius: 8px;
`;

export const AddNewFieldMappingContainer = styled.div<{ disabled: boolean; loading: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 3px;
  ${(props) => (props.disabled || props.loading ? "font-weight: 400;" : "")};
  opacity: ${(props) => (props.disabled || props.loading ? "0.5" : "1")};
  background: ${spectrum.gray0};
  border-radius: 8px;
  color: ${spectrum.gray70};
  cursor: ${(props) => (props.disabled || props.loading ? "not-allowed" : "pointer")};
`;

const IconContainer = styled.div`
  justify-self: center;
  align-self: center;
  padding: 16px 24px;
`;

const FieldMappingTargetMessage = styled.div`
  background-color: #fff7ed;
  color: #f97316;
  font-weight: 400;
  font-size: 10px;
  padding: 10px 24px;
  border-radius: 0 0 8px 8px;
`;

export const AddNewFieldMappingText = styled.div<{ isMuted?: boolean }>`
  color: #697387;
  font-weight: ${(props) => (props.isMuted ? "400" : "600")};
  font-size: 14px;
`;

const FieldMappingTargetContainer = styled.div`
  border-radius: 8px 8px 8px 8px;
  border: 0.5px solid rgba(220, 226, 234, 1);
`;

const StyledCancelButton = styled.div`
  border-radius: 4px;
  background: var(--white, #fff);
  color: rgba(17, 19, 23, 1);
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
export const StyledSaveButton = styled.div<{ disabled: boolean }>`
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? 0.3499999940395355 : 1)};
  background: var(--white, #fff);
  color: rgba(17, 19, 23, 1);
  /* App/Small */
  box-shadow:
    0px 1.5px 4px -1px rgba(0, 0, 0, 0.16),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
  display: flex;
  padding: 4px 12px;
  align-items: center;
  gap: 8px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;

const ButtonText = styled.span`
  color: var(--lm-slate-90, #111317);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
`;

export const FieldMappingTargetCardElement = ({
  id,
  fieldKey,
  fieldDescription,
}: {
  id: string;
  fieldKey: string;
  fieldDescription: string;
}) => (
  <FieldMappingTargetContainer className="d-flex flex-column">
    <FieldMappingInfoCard className="mb-0 h-100">
      <div className="d-flex flex-column w-100">
        <FieldMappingInfoTopRow className="d-flex flex-row align-items-center flex-wrap mb-2">
          <CodeText isBold fontSize="14px">
            {fieldKey}
          </CodeText>
        </FieldMappingInfoTopRow>
        <div>
          <div className="small mb-0 text-[12px] text-gray-50 text-ellipsis overflow-hidden overflow-wrap-anywhere">
            {fieldDescription}
          </div>
        </div>
      </div>
    </FieldMappingInfoCard>
    <FieldMappingTargetMessage className="flex flex-row items-center justify-between pl-5 pr-5 text-[10px] leading-[14px]">
      <div className="d-flex flex-row align-items-center">
        <div className="d-flex mr-2">
          <Globe size={12} />
        </div>
        <div className="d-flex]">Organization-wide target field</div>
      </div>
      <div>
        <a href={`/configuration/field-mappings/target-fields/${id}/settings`}>View</a>
      </div>
    </FieldMappingTargetMessage>
  </FieldMappingTargetContainer>
);

const FieldMappingTargetCard = ({
  fieldMappingTarget: fieldMappingTarget,
  linkedAccount,
  fieldMappingMetaResponse,
  newIntegrationFieldMappingCreated,
  cancelNewMapping,
  creatingNewOrgFieldMapping,
  originOptionsAvailable,
}: Props) => {
  const { common_model_name, field_key, field_description, id } = fieldMappingTarget;

  const [isCreatingMapping, setIsCreatingMapping] = useState<boolean>(false);
  const [typeaheadLoading, setTypeaheadLoading] = useState<boolean>(false);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );
  const [originFields, setOriginFields] = useState<FieldMappingCreationAndEditDict[]>([]);
  const [fieldMappingInstance, setFieldMappingInstance] = useState<
    FieldMappingInstance | undefined
  >(undefined);
  const [fieldMappingInstanceID, setFieldMappingInstanceID] = useState<string | undefined>(
    undefined,
  );
  const history = useHistory();
  const integrationName = linkedAccount?.integration?.name || "";
  const fieldMappingMetaOptions = fieldMappingMetaResponse?.available_field_mappings;

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, common_model_name));
    }
  }, [fieldMappingMetaOptions]);

  const MAX_FIELD_ORIGIN_PATH_LENGTH = 70;

  const startNewIntegrationFieldMapping = () => {
    if (originOptionsAvailable && fieldMappingMetaResponse !== undefined) {
      creatingNewOrgFieldMapping();
      setIsCreatingMapping(true);
    }
  };

  const AddNewFieldMappingInstanceCard = () => {
    if (fieldMappingMetaResponse === undefined) {
      return (
        <>
          <div className="d-flex">
            <AddNewFieldMappingContainer
              className="w-100"
              disabled={!originOptionsAvailable || fieldMappingMetaResponse === undefined}
              loading={true}
            >
              <Loader bsSize="small" />
              <div className="ml-2">Loading origin fields</div>
            </AddNewFieldMappingContainer>
          </div>
          <FillerDiv className="ml-2"></FillerDiv>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex">
            <AddNewFieldMappingContainer
              className="w-100"
              onClick={startNewIntegrationFieldMapping}
              disabled={!originOptionsAvailable || fieldMappingMetaResponse === undefined}
              loading={false}
            >
              {originOptionsAvailable && fieldMappingMetaResponse ? (
                <div className="d-flex flex-column flex-grow-1">
                  <AddNewFieldMappingText className="d-flex align-items-center justify-content-center">
                    <Plus className="mr-[3px]" size={16} />
                    {integrationName} origin field
                  </AddNewFieldMappingText>
                </div>
              ) : (
                <AddNewFieldMappingText
                  isMuted={true}
                  className="d-flex align-items-center justify-content-center"
                >
                  No origin fields currently available
                </AddNewFieldMappingText>
              )}
            </AddNewFieldMappingContainer>
          </div>
          <FillerDiv className="ml-2"></FillerDiv>
        </>
      );
    }
  };

  const cancelNewFieldMapping = () => {
    setFieldMappingInstance(undefined);
    setFieldMappingInstanceID(undefined);
    setOriginFields([]);
    cancelNewMapping();
    setIsCreatingMapping(false);
  };

  const createFieldMapping = () => {
    if (originFields.length == 1) {
      const body: NewFieldMappingProps = {
        linked_account_id: linkedAccount.id,
        common_model_id: `${linkedAccount.category}.${common_model_name}`,
        field_key: fieldMappingTarget?.field_key,
        field_traversal_path: originFields[0].traversal_path.split("."),
        create_for_organization: false,
        configured_by: FieldMappingSource.ORGANIZATION,
        ...(fieldMappingTarget?.field_description
          ? {
              field_description: fieldMappingTarget?.field_description,
            }
          : {}),
        ...(fieldMappingTarget.id ? { field_mapping_target_id: fieldMappingTarget.id } : {}),
        api_endpoint_id: originFields[0].api_endpoint_id,
        display_name: originFields[0].display_name,
        origin_type: originFields[0].type,
      };
      createLinkedAccountFieldMapping(body, setandFetchFieldMappingInstance);
    }
  };

  useEffect(() => {
    if (fieldMappingInstance !== undefined) {
      newIntegrationFieldMappingCreated(fieldMappingTarget, fieldMappingInstance);
    }
  }, [fieldMappingInstance]);

  const setandFetchFieldMappingInstance = (id: string) => {
    setFieldMappingInstanceID(id);
    fetchFieldMappingInstance(id, setFieldMappingInstance);
  };

  const FieldMappingInstanceMessage = () => (
    <TargetMessage
      backgroundColor="#fff9e6"
      fontColor="#d9a800"
      className="d-flex flex-row align-items-center"
    >
      <div className="d-flex mr-2">
        <User size={12} />
      </div>
      <div className="d-flex">Linked Account-specific target field</div>
    </TargetMessage>
  );

  return (
    <LinkedAccountFieldMappingContainer>
      <DeleteFieldMappingModal
        fieldMappingID={id}
        showModal={showDeleteModal}
        linkedAccountID={linkedAccount.id}
        onHide={() => setShowDeleteModal(false)}
      />
      <FieldMappingTargetCardElement
        id={id}
        fieldKey={field_key}
        fieldDescription={field_description}
      />
      <IconContainer>
        <ArrowLeft size={20} />
      </IconContainer>
      {isCreatingMapping ? (
        <>
          <div className="d-flex w-100">
            <FieldMappingTargetContainer className="d-flex flex-column w-100">
              <div className="h-100">
                <div className="mx-5 my-4">
                  <MergeTypeahead
                    id="typeahead"
                    disabled={
                      fieldMappingMetaOptions === undefined || fieldMappingOptions.length === 0
                    }
                    multiple={false}
                    //@ts-ignore
                    selected={originFields}
                    options={fieldMappingOptions}
                    onMenuToggle={hideTooltipOnScroll}
                    labelKey={(option: FieldMappingCreationAndEditDict) =>
                      option?.display_name ?? ""
                    }
                    inputProps={{ autoComplete: "none" }}
                    isLoading={fieldMappingMetaOptions === undefined || typeaheadLoading}
                    placeholder={
                      fieldMappingMetaOptions === undefined
                        ? `Loading ${linkedAccount.integration.name} field info...`
                        : fieldMappingOptions.length > 0
                        ? "Search Fields..."
                        : "No fields found."
                    }
                    onChange={(selectedFieldMappings) => {
                      setOriginFields(selectedFieldMappings);
                    }}
                    isInvalid={false}
                    error={""}
                    includeChevronDown={
                      fieldMappingMetaOptions !== undefined && fieldMappingOptions.length > 0
                    }
                    renderMenuItemChildren={(fieldMapping) => {
                      let fieldMappingExample = fieldMapping.value ?? "null";
                      if (fieldMappingExample !== "null") {
                        if (typeof fieldMappingExample === "object") {
                          fieldMappingExample = JSON.stringify(fieldMappingExample);
                        } else {
                          fieldMappingExample = String(fieldMappingExample);
                        }
                      }
                      return (
                        <>
                          <DropdownItem className="d-flex flex-column justify-content-between align-content-center w-100 dropdown-menu-options">
                            <div className="d-flex flex-row align-items-start">
                              <DropDownOptionColumn1 className="d-flex align-items-center mt-0.5">
                                field:
                              </DropDownOptionColumn1>

                              <div
                                id="origin-field"
                                className=" w-100 text-break white-space-wrap truncate-container"
                              >
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip
                                      id="tooltip-top"
                                      style={{
                                        width: "fit-content",
                                        height: "fit-content",
                                      }}
                                    >
                                      <div className="d-flex flex-column">
                                        <div className="d-flex flex-row">
                                          <div className="d-flex mr-1">
                                            <span>field:</span>
                                          </div>
                                          <div className="d-flex text-break white-space-wrap text-align-start font-weight-bold">
                                            <span>{fieldMapping.display_name}</span>
                                          </div>
                                        </div>
                                        <div>
                                          <div className="d-flex flex-row">
                                            <div className="d-flex mr-1">
                                              <span>type:</span>
                                            </div>
                                            <div className="d-flex font-weight-bold text-align-start">
                                              {fieldMapping.type}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <SFMonoText className="d-inline mr-2">
                                    {fieldMapping.display_name}
                                  </SFMonoText>
                                </OverlayTrigger>

                                {fieldMapping.type && (
                                  <OriginTypeBadge className="d-inline-block text-break px-1">
                                    {capitalizeFirstLetter(fieldMapping.type)}
                                  </OriginTypeBadge>
                                )}
                              </div>
                            </div>
                            <div className="d-flex flex-row">
                              <DropDownOptionColumn1>example:</DropDownOptionColumn1>
                              <div className="d-flex flex-row w-100 text-truncate">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id="tooltip-top">{fieldMappingExample}</Tooltip>
                                  }
                                >
                                  <SFMonoText className="api-info text-truncate">
                                    {fieldMappingExample}
                                  </SFMonoText>
                                </OverlayTrigger>
                              </div>
                            </div>
                            <div className="d-flex flex-row">
                              <DropDownOptionColumn1>endpoint:</DropDownOptionColumn1>
                              <div className="d-flex flex-row w-100 text-truncate">
                                <StyledDropdownAPIEndpointPath className="small api-info text-truncate">
                                  {fieldMapping.api_endpoint_path}
                                </StyledDropdownAPIEndpointPath>
                              </div>
                            </div>
                          </DropdownItem>
                        </>
                      );
                    }}
                  />
                </div>
                <div className="d-flex flex-row justify-content-end mr-5 mb-3">
                  <StyledCancelButton onClick={cancelNewFieldMapping} className="mr-2">
                    <ButtonText>Cancel</ButtonText>
                  </StyledCancelButton>
                  <StyledSaveButton
                    disabled={originFields.length != 1}
                    onClick={createFieldMapping}
                  >
                    <ButtonText>Save</ButtonText>
                  </StyledSaveButton>
                </div>
              </div>

              <FieldMappingInstanceMessage />
            </FieldMappingTargetContainer>
          </div>
          <FillerDiv className="ml-2"></FillerDiv>
        </>
      ) : (
        <AddNewFieldMappingInstanceCard />
      )}
    </LinkedAccountFieldMappingContainer>
  );
};

export default FieldMappingTargetCard;
