import React, { useState, useEffect } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import AddorEditIntegrationWideFieldMappingPage from "./AddIntegrationWideFieldMappingPage";
import ConfigurationFieldMappingsTargetFieldSettingsPage from "./ConfigurationFieldMappingsTargetFieldSettingsPage";
import {
  CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_INTEGRATION_CREATE_PATH,
} from "../../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../../portal/SubLeftNavigationContainer";
import useAppContext from "../../../context/useAppContext";
import { getAvailableIntegrationsForFieldMapping } from "../field-mappings/utils/FieldMappingUtils";
type FieldMappingTargetPageContainerProps = {
  fieldMappingsData: any;
  refreshFieldMappingsAndConfigurations: any;
};
type Props = {
  id: string;
};
const FieldMappingTargetPageContainer = ({
  fieldMappingsData,
  refreshFieldMappingsAndConfigurations,
}: FieldMappingTargetPageContainerProps) => {
  const { id } = useParams<Props>();
  const [availableIntegrations, setAvailableIntegrations] = useState();
  const [fieldMappingTarget, setFieldMappingTarget] = useState();
  const [fetchCounter, setFetchCounter] = useState<number>(0);
  const { user, isUserPrivileged } = useAppContext();
  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);

  const refreshFieldMappings = () => {
    setFetchCounter(fetchCounter + 1);
  };
  const fetchAvailableIntegrations = (onComplete?: Function) => {
    getAvailableIntegrationsForFieldMapping({
      fieldMappingTargetID: id,
      onFetch: (avaialbleIntegrations: { integrations: any }) => {
        setAvailableIntegrations(avaialbleIntegrations.integrations);
        if (onComplete) {
          onComplete();
        }
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    fetchAvailableIntegrations();
  }, [fetchCounter]);

  useEffect(() => {
    setFieldMappingTarget(
      fieldMappingsData.find((mappingData: { id: string }) => mappingData.id === id),
    );
  }, []);

  return (
    <>
      {availableIntegrations && fieldMappingTarget && (
        <Switch>
          <Route
            exact
            path={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_INTEGRATION_CREATE_PATH}
            render={() => (
              <AddorEditIntegrationWideFieldMappingPage
                availableIntegrations={availableIntegrations}
                fieldMappingTarget={fieldMappingTarget}
                fetchAvailableIntegrations={refreshFieldMappings}
              />
            )}
          />
          <SubLeftNavigationContainer
            subtabs={
              isFieldMappingV2Enabled
                ? [
                    {
                      label: "Target Fields",
                      destination: CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
                    },
                    ...(isUserPrivileged
                      ? [
                          {
                            label: "Linked Accounts",
                            destination: CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH,
                          },
                        ]
                      : []),
                  ]
                : [
                    ...(isUserPrivileged
                      ? [
                          {
                            label: "Linked Accounts",
                            destination: CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH,
                          },
                        ]
                      : []),
                    {
                      label: "Target Fields",
                      destination: CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
                    },
                  ]
            }
          >
            <Route
              exact
              path={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH}
              render={() => (
                <ConfigurationFieldMappingsTargetFieldSettingsPage
                  availableIntegrations={availableIntegrations}
                  fieldMappingTarget={fieldMappingTarget}
                  refreshFieldMappings={refreshFieldMappings}
                  refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
                />
              )}
            />
          </SubLeftNavigationContainer>
        </Switch>
      )}
    </>
  );
};

export default FieldMappingTargetPageContainer;
