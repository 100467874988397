import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ChevronRight } from "lucide-react";
import { fetchWithAuth } from "../../api-client/APIClient";
import { ChangelogData } from "../../models/Entities";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

const ShadedArea = styled.div`
  background: #f5f7fa;
  border-radius: 12px;
  padding: 12px 16px;
`;

const Title = styled(DeprecatedH4)`
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  color: var(--gray90);
`;

const ListLink = styled.a`
  font-size: 14px;
  line-height: 24px;
  color: var(--gray90);

  &:hover,
  &:active {
    color: var(--gray90);
  }

  /**
   * TODO: Make this visited state persist in DB per user,
   * like a checklist for them to stay up to date
   */
  &:visited {
    color: var(--gray90);
  }
`;

const ViewMore = styled.p`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  color: var(--gray90);
`;

const VerticalLine = styled.div`
  width: 1px;
  height: 12px;
  background: var(--gray30);
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: var(--gray30);
  margin: 3px 0;
`;

const ChevronSmall = styled(ChevronRight)`
  transform: translate(2px, 1px);
`;

const ChevronLarge = styled(ChevronRight)`
  transform: translate(3px, 1px);
`;

const LineColumn = styled.div`
  margin: 0 16px 0 12px;
`;

const Container = styled.div`
  margin: 8px 0;
`;

const IntegrationIssuesScreen = () => {
  const [changelogData, setChangelogData] = useState<ChangelogData[]>([]);

  useEffect(() => {
    fetchWithAuth({
      path: `/logs/weekly-changelog`,
      method: "GET",
      onResponse: (data) => {
        setChangelogData(data);
      },
    });
  }, []);
  return (
    <ShadedArea>
      <a
        href="https://merge.dev/changelog"
        className="d-flex align-items-center"
        target="_blank"
        rel="noreferrer"
      >
        <Title>Changelog</Title>
        <ChevronLarge size="14" color="var(--gray90)" />
      </a>
      <Container>
        {changelogData.map(({ title, url }) => (
          <div className="d-flex align-items-center">
            <LineColumn className="d-flex flex-column align-items-center">
              <VerticalLine />
              <Dot />
              <VerticalLine />
            </LineColumn>
            {title && url && (
              <ListLink href={url} className="d-flex align-items-center" target="_blank">
                <p className="mb-0">{title}</p>
                <ChevronSmall size="12" color="var(--gray90)" />
              </ListLink>
            )}
          </div>
        ))}
      </Container>

      <a
        href="https://merge.dev/changelog"
        className="d-flex align-items-center"
        target="_blank"
        rel="noreferrer"
      >
        <ViewMore>View more</ViewMore>
        <ChevronSmall size="12" color="var(--gray90)" />
      </a>
    </ShadedArea>
  );
};

export default IntegrationIssuesScreen;
