import React, { useCallback, useEffect, useState } from "react";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import useAppContext from "../../context/useAppContext";
import { showErrorToast } from "../../shared-components/Toasts";
import { Card, Col, Row, Form } from "react-bootstrap";
import { DisableTwoFactorAuthRequireModal } from "./DisableTwoFactorAuthRequireModal";

function OrganizationTwoFactorAuth() {
  const { user, setUser, isUserPrivileged } = useAppContext();
  const [isMfaRequired, setMfaRequired] = useState(false);
  const [showConfirmMsg, setShowConfirmMsg] = useState(false);

  useEffect(() => {
    setMfaRequired(user.organization?.is_mfa_required);
  }, [user]);

  const disableTwoFactorAuthentication = useCallback(() => {
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: { is_mfa_required: false },
      onResponse: () => {
        setMfaRequired(false);
        fetchCurrentUser(setUser);
      },
      onError: () => {
        showErrorToast("Failed to disable two-factor authentication for your organization.");
        setMfaRequired(true);
      },
    });
    setShowConfirmMsg(false);
  }, [setShowConfirmMsg, setMfaRequired]);

  const handleConfirmMsg = useCallback(
    (e: React.ChangeEvent<HTMLElement>) => {
      if (isMfaRequired) {
        e.preventDefault();
        setShowConfirmMsg(true);
      } else {
        fetchWithAuth({
          path: "/organizations/me",
          method: "PATCH",
          body: { is_mfa_required: true },
          onResponse: () => {
            setMfaRequired(true);
            fetchCurrentUser(setUser);
          },
          onError: () => {
            showErrorToast("Failed to enable two-factor authentication for your organization.");
            setMfaRequired(false);
          },
        });
      }
    },
    [setShowConfirmMsg, setMfaRequired, isMfaRequired],
  );

  return (
    <>
      {isUserPrivileged && (
        <>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col>
                  <span className="font-semibold mb-0">
                    Enforce two-factor authentication for all organization members
                  </span>
                </Col>
                <Col className="col-auto">
                  <label className="mb-0" htmlFor="Mfa-toggle" style={{ cursor: "pointer" }}>
                    <Row>
                      <Col className="col-auto">
                        {isMfaRequired ? (
                          <span style={{ color: "var(--teal50)" }}>2FA Enforced</span>
                        ) : (
                          <span>Enforce 2FA</span>
                        )}
                      </Col>
                      <Col>
                        <span className="ml-auto demo-data-toggle">
                          <Form.Check
                            type="switch"
                            id="Mfa-toggle"
                            name="Mfa-toggle"
                            onChange={(e) => handleConfirmMsg(e)}
                            checked={isMfaRequired}
                          />
                        </span>
                      </Col>
                    </Row>
                  </label>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {showConfirmMsg && (
            <DisableTwoFactorAuthRequireModal
              onClickMethod={disableTwoFactorAuthentication}
              isShown={showConfirmMsg}
              onHide={() => setShowConfirmMsg(false)}
            />
          )}
        </>
      )}
    </>
  );
}

export default OrganizationTwoFactorAuth;
