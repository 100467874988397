import React, { useState, useEffect, useMemo } from "react";
import { fetchCurrentUser, fetchWithAuth } from "../../api-client/APIClient";
import HeaderBar from "../portal/HeaderBar";
import { IntegrationIssue } from "../integrations-management/IntegrationsManagementEntities";
import IntegrationIssuesScreen from "./IntegrationIssuesScreen";
import LinkedAccountsAnalyticsCard from "./LinkedAccountsAnalyticsCard";
import LinkedIntegrationsScreen from "./LinkedIntegrationsScreen";
import ChangelogPreview from "./ChangelogPreview";
import UpsellCard from "./UpsellCard";
import { LinkedIntegration } from "../../models/Entities";
import { Col, Row } from "react-bootstrap";
import APIRequestsAnalyticsCard from "./APIRequestsAnalyticsCard";
import { OrganizationInviteAlert } from "../shared-components/MergeAlerts";
import { isBillingPlanFreeLaunch } from "../../components/settings/billing/BillingUtils";
import useAppContext from "../context/useAppContext";
import MergeFlagWrapper from "../../components/shared-components/MergeFlagWrapper";
import { MergeFlagFeature } from "../../components/shared-components/hooks/useMergeFlag";

function Dashboard() {
  const [linkedAccountsCount, setLinkedAccountsCount] = useState<number>();
  const [apiRequestsCount, setApiRequestsCount] = useState<number>();
  const [apiRequestsByDay, setApiRequestsByDay] = useState();
  const [integrationIssues, setIntegrationIssues] = useState<Array<IntegrationIssue> | undefined>();
  const [linkedIntegrations, setLinkedIntegrations] = useState<Array<LinkedIntegration>>();
  const [linkedAccountsByDay, setLinkedAccountsByDay] = useState();
  const { user, setUser } = useAppContext();

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/linked-accounts/count",
      method: "GET",
      onResponse: (data) => {
        setLinkedAccountsCount(data.linked_accounts_count);
        setLinkedAccountsByDay(data.linked_accounts_chart_data);
        setLinkedIntegrations(data.linked_integrations);
      },
    });
    fetchWithAuth({
      path: "/integrations/analytics/api-requests",
      method: "GET",
      onResponse: (data) => {
        setApiRequestsCount(data.api_requests_count);
        setApiRequestsByDay(data.api_requests_by_day);
      },
    });
    fetchWithAuth({
      path: "/integrations/issues?include_muted=false",
      method: "GET",
      onResponse: (data) => {
        setIntegrationIssues(data.results);
      },
    });
    if (!user) {
      fetchCurrentUser(setUser);
    }
    if (user.show_upgrade_billing_plan_banner) {
      fetchWithAuth({
        path: "/users/me",
        method: "PATCH",
        body: { show_upgrade_billing_plan_banner: false },
        onResponse: () => {},
      });
    }
  }, []);

  return (
    <>
      <Row className="justify-content-center">
        <Col className="col-12 col-lg-10 col-xl-8">
          <OrganizationInviteAlert />
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col className="col-12 col-lg-10 col-xl-8">
          <HeaderBar title="Dashboard" />
          <Row>
            <Col className="col-12 col-xl-7">
              <Row>
                <Col>
                  <APIRequestsAnalyticsCard
                    apiRequestsCount={apiRequestsCount}
                    data={apiRequestsByDay}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <LinkedIntegrationsScreen integrations={linkedIntegrations} />
                </Col>
              </Row>
            </Col>
            <Col className="col-12 col-xl-5">
              <Row>
                <Col>
                  <LinkedAccountsAnalyticsCard
                    linkedAccountsCount={linkedAccountsCount}
                    data={linkedAccountsByDay}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <IntegrationIssuesScreen integrationIssues={integrationIssues} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ChangelogPreview />
                </Col>
              </Row>
              <MergeFlagWrapper
                feature={MergeFlagFeature.FEATURE_DISCOVERY_ENABLED}
                organizationId={user.organization.id}
              >
                {isBillingPlanFreeLaunch(
                  user.organization.organization_billing_plan?.billing_plan,
                ) && (
                  <Row>
                    <Col>
                      <UpsellCard />
                    </Col>
                  </Row>
                )}
              </MergeFlagWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
