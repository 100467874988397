import React, { useRef } from "react";
import MergeModal from "../../../../../shared-components/MergeModal";
type SaveOverrideModalProps = {
  text: string;
  title?: string;
  saveButtonText?: string;
  onHide: () => void;
  onConfirm: () => void;
};
const SaveOverrideModal = ({
  text,
  title,
  saveButtonText,
  onHide,
  onConfirm,
}: SaveOverrideModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <MergeModal
      centered
      show={true}
      onHide={onHide}
      title={title || "Override Common Model field"}
      ref={modalRef}
    >
      <p>{text}</p>
      {/* <p>Learn more about overriding Common Model fields in our Help Center.</p> */}
      <div className="d-flex items-center justify-end">
        <button
          className="text-gray-90 font-medium cursor-pointer bg-white"
          onClick={() => onHide()}
        >
          Cancel
        </button>
        <button
          className="ml-8 bg-red-50 font-semibold text-white cursor-pointer rounded-md p-2 pl-3.5 pr-3.5"
          onClick={() => onConfirm()}
        >
          {saveButtonText || "Override Field"}
        </button>
      </div>
    </MergeModal>
  );
};
export default SaveOverrideModal;
