import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import {
  PRIVACY_POLICY_PATH,
  SUBSCRIBER_AGREEMENT_PATH,
  TOS_PATH,
} from "../../../router/RouterUtils";
import useAppContext from "../../context/useAppContext";
import styles from "../../../styles/css/legal.module.css";
import { fetchWithAuth, onLogout } from "../../../api-client/APIClient";
import { showErrorToast } from "../../shared-components/Toasts";
import { isBillingPlanLaunchPlan } from "../../settings/billing/BillingUtils";

const Header = styled(Modal.Header)`
  padding: 18px 24px;
`;

const Title = styled(Modal.Title)`
  margin-bottom: 0;
  font-size: 18px;
  line-height: 26px;
`;

const Body = styled(Modal.Body)`
  display: table;
  padding: 24px;
`;

const Paragraph = styled.p`
  margin-bottom: 24px;
`;

const UL = styled.ul`
  margin-bottom: 24px;

  li:not(:last-child) {
    margin-bottom: 9px;
  }
`;

const PrimaryButton = styled(Button)`
  margin-bottom: 7px;

  background: #121314;
  border-radius: 6px;
  width: 100%;

  line-height: 24px;
  font-weight: 600;
`;

const BorderlessButton = styled.button`
  padding: 8px 12px;
  margin: auto;
  display: block;

  background: none;
  border: none;
  color: #121314;

  line-height: 24px;
`;

const MAR_2022_TOS_UPDATE_TIME = 1647885380000;
const NOV_2021_SECURITY_TIME = 1637001900000;

const UpdatedTermsModal = () => {
  const { user, setUser } = useAppContext();
  const shouldShowModal =
    (!user.terms_agreement_datetime ||
      new Date(user.terms_agreement_datetime).getTime() < MAR_2022_TOS_UPDATE_TIME) &&
    isBillingPlanLaunchPlan(user.organization.organization_billing_plan?.billing_plan);

  const shouldIncludeTOSInModal =
    (!user.terms_agreement_datetime ||
      new Date(user.terms_agreement_datetime).getTime() < NOV_2021_SECURITY_TIME) &&
    isBillingPlanLaunchPlan(user.organization.organization_billing_plan?.billing_plan);

  const acceptTermsAndAgreements = () => {
    fetchWithAuth({
      path: "/users/me",
      method: "PATCH",
      body: { terms_agreed: true },
      onResponse: (data) => {
        setUser(data.user);
      },
      onError: () => {
        showErrorToast("Failed to accept the updated Terms and Agreements. Please try again.");
      },
    });
  };

  const logout = () => {
    onLogout({ onError: () => showErrorToast(`Failed to logout. Please try again.`) });
  };

  return shouldShowModal ? (
    <Modal
      show
      centered
      backdropClassName={styles.legalBackdrop}
      contentClassName={styles.legalContent}
    >
      <Header>
        <Title>Updated Terms and Agreements</Title>
      </Header>

      <Body>
        <Paragraph>We&apos;ve updated the following legal agreements:</Paragraph>
        <UL>
          {shouldIncludeTOSInModal && (
            <li>
              <a href={TOS_PATH} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
            </li>
          )}
          <li>
            <a href={SUBSCRIBER_AGREEMENT_PATH} target="_blank" rel="noreferrer">
              Subscriber Agreement
            </a>
          </li>
          {shouldIncludeTOSInModal && (
            <li>
              <a href={PRIVACY_POLICY_PATH} target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </li>
          )}
        </UL>
        <Paragraph>
          {shouldIncludeTOSInModal
            ? "Please click the button below to indicate that you agree to the latest Terms of Use and Subscriber Agreement and have read the updated Privacy Policy."
            : "Please click the button below to indicate that you agree to the latest Subscriber Agreement."}
        </Paragraph>
        {!shouldIncludeTOSInModal && (
          <Paragraph>
            <i>
              Note: if your organization has signed a Master Services Agreement ("MSA") with Merge,
              that executed MSA will supersede the acceptance of this updated Subscriber Agreement.
            </i>
          </Paragraph>
        )}

        <PrimaryButton variant="dark" onClick={acceptTermsAndAgreements}>
          I accept the updated terms and agreements
        </PrimaryButton>
        <BorderlessButton onClick={logout}>Cancel</BorderlessButton>
      </Body>
    </Modal>
  ) : null;
};

export default UpdatedTermsModal;
