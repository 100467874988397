import React, { useState } from "react";
import { Accordion, Badge, Card, Col, Row } from "react-bootstrap";
import { OrganizationAvatar } from "../../../../../shared-components/MergeAvatars";
import { CodeBlockFileDownloadInfo } from "../../../../../shared-components/MergeCodeBlock";
import { LogSidePanelRequestSection } from "./LogSidePanelRequestSection";

import styled from "styled-components";
import { spectrum } from "../../../../../../styles/theme";
import RotatingChevronRight from "../../../../../shared-components/RotatingChevronRight";
import { LogSidePanelResponseSection } from "./LogSidePanelResponseSection";
import { LogType } from "../../../../IntegrationsManagementEntities";

const HoverableRow = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
  transition: background-color 0.2s;
  &:hover {
    background-color: ${spectrum.gray0};
  }
`;

const ExpandedRow = styled(Row)`
  margin: 16px 30px 0px 30px;
`;

const HoverableCol = styled(Col)`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 10px;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

interface CollapsableSectionProps {
  title: string;
  numberOfElements: number | undefined;
  children: JSX.Element | JSX.Element[];
  isCollapsedByDefault?: boolean;
}

export const CollapsableSection = ({
  title,
  numberOfElements,
  children,
  isCollapsedByDefault,
}: CollapsableSectionProps) => {
  const [isClosed, setIsClosed] = useState(isCollapsedByDefault);

  return (
    <Accordion key={title} defaultActiveKey={!isClosed ? "0" : undefined}>
      <Accordion.Toggle
        onClick={() => setIsClosed(!isClosed)}
        as={Card.Body}
        className="clickable"
        eventKey="0"
      >
        <HoverableRow className="align-items-center clickable">
          <HoverableCol>
            <span>
              <Title>{title}</Title>
              <Badge variant="light" className="deprecated-mb-1 deprecated-ml-2">
                {numberOfElements}
              </Badge>
            </span>
          </HoverableCol>
          <HoverableCol className="col-auto">
            <RotatingChevronRight isRotatedDown={!isClosed} />
          </HoverableCol>
        </HoverableRow>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="0" key={title}>
        <Card.Body className="deprecated-mt-1">
          <ExpandedRow>{children}</ExpandedRow>
        </Card.Body>
      </Accordion.Collapse>
    </Accordion>
  );
};

export const FullWidthCard = styled(Card)`
  .card-body {
    padding: 0rem;
  }
`;

type Props = {
  logEntry: LogType;
  hasDynamicResponseBody: boolean;
  responseBody: string | undefined;
  downloadInfo?: CodeBlockFileDownloadInfo;
};

export const LogSidePanelBody = ({
  logEntry,
  hasDynamicResponseBody,
  responseBody,
  downloadInfo,
}: Props) => {
  const OrganizationBodyImage = () => (
    <OrganizationAvatar
      organizationName={logEntry.linked_account.end_user.organization_name}
      className="log-card-header-image"
    />
  );

  const IntegrationImage = () => (
    <img
      src={logEntry.linked_account.integration.square_image}
      className="avatar-img log-rounded"
      alt="Logo for integration"
    />
  );

  return (
    <Row>
      <Col md="6">
        <LogSidePanelRequestSection
          logEntry={logEntry}
          organizationBodyImage={<OrganizationBodyImage />}
          integrationImage={<IntegrationImage />}
        />
      </Col>
      <Col md="6">
        <LogSidePanelResponseSection
          logEntry={logEntry}
          organizationBodyImage={<OrganizationBodyImage />}
          integrationImage={<IntegrationImage />}
          hasDynamicResponseBody={hasDynamicResponseBody}
          responseBody={responseBody}
          downloadInfo={downloadInfo}
        />
      </Col>
    </Row>
  );
};
