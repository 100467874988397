import { ConditionOperator, ConditionType } from "../../models/Entities";

const CONDITION_OPERATOR_STRING: Readonly<Record<ConditionOperator, string>> = {
  [ConditionOperator.EQUALS]: "is",
  [ConditionOperator.NOT_EQUALS]: "is not",
  [ConditionOperator.GREATER_THAN]: "is greater than",
  [ConditionOperator.GREATER_THAN_OR_EQUAL_TO]: "is greater than or equal to",
  [ConditionOperator.LESS_THAN]: "is less than",
  [ConditionOperator.LESS_THAN_OR_EQUAL_TO]: "is less than or equal to",
  [ConditionOperator.IS_ONE_OF]: "is one of",
  [ConditionOperator.HAS_ALL_OF]: "has all of",
  [ConditionOperator.HAS_NONE_OF]: "has none of",
};

const DATE_AND_DATE_TIME_OVERRIDES = {
  [ConditionOperator.GREATER_THAN]: "is after",
  [ConditionOperator.GREATER_THAN_OR_EQUAL_TO]: "is on or after",
  [ConditionOperator.LESS_THAN]: "is before",
  [ConditionOperator.LESS_THAN_OR_EQUAL_TO]: "is on or before",
};

const CONDITION_OPERATOR_OVERRIDES: Readonly<
  Partial<Record<ConditionType, Readonly<Partial<Record<ConditionOperator, string>>>>>
> = {
  [ConditionType.DATE]: DATE_AND_DATE_TIME_OVERRIDES,
  [ConditionType.DATE_TIME]: DATE_AND_DATE_TIME_OVERRIDES,
};

const isStringAConditionOperator = (
  maybeConditionOperator: string | undefined,
): maybeConditionOperator is ConditionOperator =>
  Object.values(ConditionOperator).includes(maybeConditionOperator as ConditionOperator);

export const getLocalizedConditionOperatorName = (
  conditionType: ConditionType | undefined,
  conditionOperator: ConditionOperator | string | undefined,
): string | undefined =>
  (isStringAConditionOperator(conditionOperator) &&
    ((conditionType && CONDITION_OPERATOR_OVERRIDES?.[conditionType]?.[conditionOperator]) ||
      CONDITION_OPERATOR_STRING[conditionOperator])) ||
  conditionOperator;
