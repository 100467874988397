import React, { useState } from "react";
import { Button, ButtonGroup, Dropdown, FormControl, InputGroup, Row } from "react-bootstrap";
import { Volume2, VolumeX } from "react-feather";
import MergeModal from "../../../shared-components/MergeModal";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import { IntegrationIssue } from "../../IntegrationsManagementEntities";
import { muteIntegrationIssue } from "../../utils/IntegrationsManagementAPIClient";
import { isIssueMuted } from "../../utils/IntegrationsManagementUtils";
import DatePicker from "react-datepicker";
import useAppContext from "../../../context/useAppContext";
import styled from "styled-components";

interface Props {
  integrationIssue: IntegrationIssue;
  onIssueUpdate: (issue: IntegrationIssue) => void;
}

const OverflowVisibleDropdownMenu = styled(Dropdown.Menu)`
  overflow: visible;
`;

const IndividualIssueMuteButton = ({ integrationIssue, onIssueUpdate }: Props) => {
  const { user } = useAppContext();
  const [issue, setIssue] = useState<IntegrationIssue>(integrationIssue);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [displayMuteDateModal, setDisplayMuteDateModal] = useState(false);
  const [displayCountModal, setDisplayCountModal] = useState(false);

  const isMuted = isIssueMuted(issue);

  function dateInXHours(x: number) {
    const now = new Date();
    now.setHours(now.getHours() + x);
    return now;
  }

  const muteIssue = ({
    muted_until,
    mute_incident_count,
  }: {
    muted_until?: Date;
    mute_incident_count?: number;
  }) => {
    if (user.is_demo) {
      const issue = JSON.parse(JSON.stringify(integrationIssue));
      issue.muted_until = muted_until;
      issue.incidents_until_unmuted = mute_incident_count;
      issue.last_muted_incident_selection = mute_incident_count;
      setIssue(issue);
      onIssueUpdate(issue);
      setUpdateLoading(false);
      showSuccessToast(`${isMuted ? "Unmuted" : "Muted"} issue.`);
    } else {
      setUpdateLoading(true);
      muteIntegrationIssue({
        integrationIssueID: integrationIssue.id,
        muted_until: muted_until,
        mute_incident_count: mute_incident_count,
        onSuccess: (updatedIssue) => {
          setIssue(updatedIssue);
          onIssueUpdate(updatedIssue);
          setUpdateLoading(false);
          showSuccessToast(`${isMuted ? "Unmuted" : "Muted"} issue.`);
        },
        onFailure: () => {
          setUpdateLoading(false);
          showErrorToast(`Failed to update issue`);
        },
      });
    }
  };

  function muteAndCloseModal(selected_date: Date | undefined | null) {
    setDisplayMuteDateModal(false);
    if (selected_date) {
      muteIssue({ muted_until: selected_date });
    }
  }

  return (
    <>
      {isMuted ? (
        <div className="float-right individual-issue-mute-button">
          <Button
            size="sm"
            variant="white"
            className="individual-issue-mute-button-muted"
            onClick={() => {
              muteIssue({ muted_until: undefined, mute_incident_count: undefined });
            }}
          >
            {updateLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm deprecated-mr-2"
                  role="status"
                  aria-hidden="true"
                />
                Updating
              </>
            ) : (
              <>
                <VolumeX className="deprecated-mr-2" size={16} />
                Muted
              </>
            )}
          </Button>
        </div>
      ) : (
        <>
          <Dropdown className="float-right" onClick={() => {}} as={ButtonGroup}>
            <Button
              size="sm"
              variant="white"
              className="individual-issue-mute-button-align"
              onClick={() => {
                muteIssue({ muted_until: dateInXHours(24 * 360 * 100) });
              }}
            >
              {updateLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm deprecated-mr-2"
                    role="status"
                    aria-hidden="true"
                  />
                  Updating
                </>
              ) : (
                <>
                  <Volume2 className="deprecated-mr-2" size={16} /> {" Mute Issue "}
                </>
              )}
            </Button>
            <Dropdown.Toggle
              split
              variant="white"
              size="sm"
              className="individual-issue-mute-button-no-border"
            ></Dropdown.Toggle>
            <OverflowVisibleDropdownMenu>
              <Dropdown drop="right">
                <Dropdown.Toggle
                  className="dropdown-toggle dropdown-item individual-issue-mute-button-select"
                  variant="white"
                  size="sm"
                  key="inner-toggle"
                  data-toggle="dropleft"
                >
                  For...
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      muteIssue({ muted_until: dateInXHours(1) });
                    }}
                  >
                    An hour
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      muteIssue({ muted_until: dateInXHours(24) });
                    }}
                  >
                    A day
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      muteIssue({ muted_until: dateInXHours(24 * 7) });
                    }}
                  >
                    A week
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDisplayMuteDateModal(true);
                    }}
                  >
                    Custom
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown drop="right">
                <Dropdown.Toggle
                  className="dropdown-item dropdown-toggle individual-issue-mute-button-select"
                  variant="white"
                  size="sm"
                  key="inner-toggle"
                  data-toggle="dropleft"
                >
                  Until this occurs...
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      muteIssue({ mute_incident_count: 1 });
                    }}
                  >
                    One time
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      muteIssue({ mute_incident_count: 100 });
                    }}
                  >
                    100 times
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      muteIssue({ mute_incident_count: 1000 });
                    }}
                  >
                    1000 times
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      setDisplayCountModal(true);
                    }}
                  >
                    Custom
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </OverflowVisibleDropdownMenu>
          </Dropdown>
          <CustomMuteDateModal
            isShown={displayMuteDateModal}
            setIsShown={setDisplayMuteDateModal}
            onSelect={(selectedDate) => {
              muteAndCloseModal(selectedDate);
            }}
          />
          <CustomIncidentCountModal
            isShown={displayCountModal}
            setIsShown={setDisplayCountModal}
            onSelect={(selectedCount) => muteIssue({ mute_incident_count: selectedCount })}
          />
        </>
      )}
    </>
  );
};

type countModalProps = {
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  onSelect: (selectedCount: number) => void;
};

const CustomIncidentCountModal = (props: countModalProps) => {
  const [count, setCount] = useState<number | undefined>(1000);
  const [validated, setValidated] = useState(true);

  const updateCount = ({ newVal }: { newVal: any }) => {
    if (newVal == undefined || newVal == "") {
      setCount(undefined);
      setValidated(false);
    } else {
      setValidated(true);
      setCount(Number(newVal));
    }
  };

  const submit = () => {
    if (count) {
      props.onSelect(count);
    }
    props.setIsShown(false);
  };

  return (
    <MergeModal
      bodyClassName="date-picker-body"
      title="Mute for Custom Incident Count"
      show={props.isShown}
      onHide={() => {
        props.setIsShown(false);
      }}
      fitContent
    >
      <Row>
        <InputGroup className="deprecated-mb-3">
          <FormControl
            type="number"
            name="clicks"
            isValid={validated}
            value={count}
            onChange={(event) => {
              updateCount({ newVal: event.target.value });
            }}
          />
        </InputGroup>
      </Row>
      <Row>
        <Button
          className="individual-issue-mute-button-custom-count-select"
          onClick={submit}
          disabled={!validated}
        >
          Mute
        </Button>
      </Row>
    </MergeModal>
  );
};

type dateModalProps = {
  isShown: boolean;
  setIsShown: (value: boolean) => void;
  onSelect: (selectedDate: Date | undefined | null) => void;
};
const CustomMuteDateModal = (props: dateModalProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [calendarDisplayMonth, setCalendarDisplayMonth] = useState(new Date().getMonth());

  const startDate = new Date();

  function onCalendarSelect(date: Date | [Date, Date] | null) {
    if (date == null) {
      setSelectedDate(startDate);
    } else if (date instanceof Array) {
      setSelectedDate(date[0]);
    } else {
      setSelectedDate(date);
    }
  }

  return (
    <>
      <MergeModal
        bodyClassName="date-picker-body"
        title="Select when to Mute"
        show={props.isShown}
        onHide={() => {
          props.setIsShown(false);
        }}
        fitContent
      >
        <Row className="d-flex align-items-center">
          <DatePicker
            calendarClassName="date-picker-font"
            className="input-group-text date-picker"
            placeholderText="Select date"
            selected={selectedDate}
            onChange={(date) => onCalendarSelect(date)}
            selectsEnd
            startDate={startDate}
            endDate={selectedDate}
            minDate={startDate}
            onMonthChange={(date) => setCalendarDisplayMonth(date.getMonth())}
            dayClassName={(date) =>
              date.getMonth() !== calendarDisplayMonth && date < startDate ? "day-disabled" : ""
            }
          />
        </Row>
        <Row className="deprecated-mt-3">
          <Button
            className="calender-set-button"
            disabled={selectedDate == undefined || selectedDate == null}
            onClick={() => {
              props.onSelect(selectedDate);
            }}
          >
            Mute
          </Button>
        </Row>
      </MergeModal>
    </>
  );
};

export default IndividualIssueMuteButton;
