import React, { useState, useEffect } from "react";
import { Dropdown, Card, Row, Col, ListGroup, Badge } from "react-bootstrap";

import { fetchWithAuth } from "../../../api-client/APIClient";
import { showSuccessToast } from "../../shared-components/Toasts";
import { EllipsesToggle } from "../../shared-components/MergeToggles";
import useAppContext from "../../context/useAppContext";
import { UserAvatar } from "../../shared-components/MergeAvatars";
import { PendingInvite } from "../../../models/Entities";
import { SmallTextMutedParagraph } from "../../shared-components/MergeText";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import DeprecatedH4 from "../../../deprecated/DeprecatedH4";
import InviteTeamMemberButton from "./InviteTeamMemberButton";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import TeamMembersSkeleton from "./TeamMembersSkeleton";

function PendingTeamMembers() {
  const { user, isUserPrivileged } = useAppContext();
  const [pendingOrganizationInvites, setPendingOrganizationInvites] = useState<
    PendingInvite[] | null
  >();
  const [isLoading, setIsLoading] = useState(false);

  function fetchPendingInvites() {
    setIsLoading(true);
    fetchWithAuth({
      path: "/organizations/users/invites",
      method: "GET",
      onResponse: (data) => {
        setPendingOrganizationInvites(data.results);
        setIsLoading(false);
      },
    });
  }

  function deletePendingInvites(pendingInvite: PendingInvite) {
    fetchWithAuth({
      path: "/organizations/users/invites/" + pendingInvite.id,
      method: "DELETE",
      onResponse: () => {
        showSuccessToast("Successfully deleted pending invite for " + pendingInvite.email + "!");
        fetchPendingInvites();
      },
    });
  }

  useEffect(() => {
    fetchPendingInvites();
  }, [user]);

  return (
    <>
      <Card>
        <Card.Body>
          <SectionHeaderWrapper
            title="Pending"
            titleHeading={TitleHeading.H2}
            button={<InviteTeamMemberButton fetchPendingInvites={fetchPendingInvites} />}
          />
        </Card.Body>

        <hr className="my-0" />
        <ListGroup variant="flush">
          {isLoading ? (
            <TeamMembersSkeleton length={1} />
          ) : pendingOrganizationInvites?.length ? (
            pendingOrganizationInvites.map((pendingInvite: PendingInvite) => (
              <ListGroup.Item className="deprecated-px-4" key={pendingInvite.id}>
                <Row className="align-items-center">
                  <Col className="col-1">
                    <UserAvatar email={pendingInvite.email} />
                  </Col>
                  <Col className="col-10 ml-n2">
                    <DeprecatedH4 className="deprecated-mb-1">
                      <Badge variant="secondary">Pending</Badge>
                    </DeprecatedH4>
                    <SmallTextMutedParagraph className="mb-0">
                      {pendingInvite.email}
                    </SmallTextMutedParagraph>
                  </Col>

                  {isUserPrivileged && (
                    <Col className="col-1">
                      <Dropdown>
                        <Dropdown.Toggle as={EllipsesToggle} id="dropdown-custom-components">
                          <i className="fe fe-more-vertical black" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="right" className="dropdown-ellipses dropdown-toggle">
                          <Dropdown.Item
                            className="ellipses-dropdown-item dropdown-item"
                            onSelect={() => deletePendingInvites(pendingInvite)}
                          >
                            Delete invite
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  )}
                </Row>
              </ListGroup.Item>
            ))
          ) : (
            <EmptyStateWrapper title="No Pending Invites" />
          )}
        </ListGroup>
      </Card>
    </>
  );
}

export default PendingTeamMembers;
