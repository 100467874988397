import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import type { match } from "react-router-dom";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import IntegrationNameAndLogo from "../../../configuration/integrations/IntegrationNameAndLogo";
import useAppContext from "../../../context/useAppContext";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import {
  HTTPMethodBadge,
  ResponseCodeBadge,
  StatusBadge,
} from "../../../shared-components/MergeBadges";
import type { APIRequestLogEntry } from "../../IntegrationsManagementEntities";
import DirectionCell from "./DirectionCell";
import { LinkedAccount } from "../../../../models/Entities";
import { formatDate } from "../../../../models/Helpers";

interface Props {
  log: APIRequestLogEntry;
  match: match;
  linkedAccount?: LinkedAccount | null;
  /**
   * Called when the route is pushed when clicking this row
   */
  onPush?: () => void;
}

const UrlRow = styled.td<{ $isLinkedAccountTab?: boolean }>`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({ $isLinkedAccountTab }) => ($isLinkedAccountTab ? "367px" : "250px")};
  font-size: 12px;
`;

const DateRow = styled.td`
  color: var(--gray50);
  font-size: 12px;
  line-height: 22px;
`;

const BadgeRow = styled.td`
  && .badge-component {
    font-size: 10px;
    line-height: 14px;
  }
`;

const LogsTableFont = styled.td`
  font-size: 12px;
`;

/**
 * Creates one table row for a log entry
 */
const LogEntryRow = ({ log, match, onPush, linkedAccount }: Props) => {
  const { user } = useAppContext();
  const history = useHistory();

  const isLinkedAccountTab = !!linkedAccount;

  const navigateToLogEntrySidePanel = (logId: string) => {
    onPush && onPush();
    history.push(`${match.path}/${logId}`, { from: window.location.pathname });
  };

  const methodCell = (
    <BadgeRow>
      {user.is_demo && <StatusBadge status="Demo" className="deprecated-mr-3 badge-component" />}
      {log.method === "FETCH" ? (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip-top">
              FETCH indicates that data was pulled via a method other than HTTP Request
            </Tooltip>
          }
        >
          <span className="text-truncate">
            <HTTPMethodBadge method={log.method} />
          </span>
        </OverlayTrigger>
      ) : (
        <HTTPMethodBadge method={log.method} />
      )}
    </BadgeRow>
  );

  const urlCell = (
    <UrlRow $isLinkedAccountTab={isLinkedAccountTab}>
      {log.url && (
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{log.url}</Tooltip>}>
          <div>
            <span className="text-truncate">{log.url}</span>
          </div>
        </OverlayTrigger>
      )}
    </UrlRow>
  );

  const statusCell = (
    <BadgeRow>
      <ResponseCodeBadge responseCode={log.response_code} className="badge-component" />
    </BadgeRow>
  );

  const integrationCell = !linkedAccount ? (
    <LogsTableFont>
      {log.linked_account?.integration && (
        <div className="d-flex align-items-center">
          <IntegrationNameAndLogo integration={log.linked_account.integration} />
        </div>
      )}
    </LogsTableFont>
  ) : null;

  const userCell = !linkedAccount ? (
    <LogsTableFont>{log.linked_account?.end_user?.organization_name}</LogsTableFont>
  ) : null;

  const dateCell = <DateRow>{formatDate(log.created_at)}</DateRow>;

  return (
    <tr className="table-link" onClick={() => navigateToLogEntrySidePanel(log.id)}>
      {methodCell}
      {urlCell}
      {statusCell}
      <DirectionCell log={log} />
      {integrationCell}
      {userCell}
      {dateCell}
      <td className="text-right">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">Review log details</Tooltip>}
        >
          <ClickableContainer>
            <span className="black fe fe-chevron-right" />
          </ClickableContainer>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default LogEntryRow;
