import React from "react";
import { Calculator, Webhook, Boxes } from "lucide-react";
import { Col, Row } from "react-bootstrap";
import {
  CONFIGURATION_LINK_PATH,
  CONFIGURATION_WEBHOOKS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_PATH,
  CONFIGURATION_CUSTOM_OBJECTS_PATH,
} from "../../router/RouterUtils";
import useAppContext from "../context/useAppContext";
import HeaderBar from "../portal/HeaderBar";
import useProductRestrictions from "../shared-components/hooks/useProductRestrictions";

type Props = { children: JSX.Element | JSX.Element[] };

const ConfigurationPageWrapper = ({ children }: Props) => {
  const { productRestrictions } = useProductRestrictions();
  const { isUserPrivileged } = useAppContext();
  return (
    <Row className="justify-content-center">
      <Col className="col-12 col-lg-10 col-xl-8">
        <Row>
          <Col>
            <HeaderBar
              title="Advanced Configuration"
              subtabs={[
                {
                  label: "Link",
                  destination: CONFIGURATION_LINK_PATH,
                  icon: <Calculator className="deprecated-mr-3" size="16px" />,
                },
                {
                  label: "Webhooks",
                  destination: CONFIGURATION_WEBHOOKS_PATH,
                  icon: <Webhook className="deprecated-mr-3" size="16px" />,
                },

                ...(isUserPrivileged
                  ? [
                      {
                        label: "Field Mapping",
                        destination: CONFIGURATION_FIELD_MAPPINGS_PATH,
                        icon: <span className="fe fe-shuffle deprecated-mr-3" />,
                      },
                    ]
                  : []),
                ...(isUserPrivileged && productRestrictions?.are_custom_objects_enabled
                  ? [
                      {
                        label: "Custom Objects",
                        destination: CONFIGURATION_CUSTOM_OBJECTS_PATH,
                        icon: <Boxes className="deprecated-mr-3" size="16px" />,
                      },
                    ]
                  : []),
              ]}
            />
          </Col>
        </Row>

        <Row>
          <Col>{children}</Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ConfigurationPageWrapper;
