import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { AlertTriangle, X } from "lucide-react";
import IntegrationIssueLogsRow from "./IntegrationIssueLogsRow";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { Integration, LinkedAccount } from "../../../../../models/Entities";
import IntegrationIssueDetailedErrorContainer from "./IntegrationIssueDetailedErrorContainer";
import { spectrum } from "../../../../../styles/theme";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";
import ImpactedModelsRow from "./ImpactedModelsRow";
import { getImpactedModels } from "../../../utils/IntegrationsManagementUtils";
import { dismissIntegrationIssue } from "../../../utils/IntegrationsManagementAPIClient";
import { showErrorToast, showSuccessToast } from "../../../../shared-components/Toasts";

const IntegrationsIssuesCard = styled.div`
  padding: 12px 16px 16px 16px;
  border-radius: 0 0 8px 8px;
  background: #ffffff;
  box-shadow:
    0px 3px 12px -3px rgba(0, 0, 0, 0.12),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
`;

type ErrorTextProps = {
  isMissingPermissionIssue: boolean;
};

const ErrorTitleText = styled.div<ErrorTextProps>`
  font-weight: 600;
  font-size: 16px;
  margin-left: 8px;
  color: ${(props) => (props.isMissingPermissionIssue ? spectrum.amber50 : spectrum.red50)};
`;

export const ErrorStripe = styled.div<ErrorTextProps>`
  height: 8px;
  border-radius: 8px 8px 0px 0px;
  background: ${(props) => (props.isMissingPermissionIssue ? spectrum.amber50 : spectrum.red50)};
`;

type IntegrationIssuesWidgetProps = {
  integrationIssue: IntegrationIssue;
  linkedAccount: LinkedAccount | null;
  showPreview: boolean;
  setIntegrationIssue: Dispatch<SetStateAction<IntegrationIssue | null>>;
};
const IntegrationIssueWidget = ({
  integrationIssue,
  linkedAccount,
  showPreview,
  setIntegrationIssue,
}: IntegrationIssuesWidgetProps) => {
  const [issueLogs, setIssueLogs] = useState([]);
  useEffect(() => {
    if (integrationIssue) {
      fetchWithAuth({
        path: `integrations/issues/${integrationIssue.id}/logs`,
        method: "GET",
        onResponse: (data) => {
          setIssueLogs(data?.recent_logs || []);
        },
      });
    }
  }, [integrationIssue.id]);

  const impactedModels = integrationIssue?.metadata
    ? getImpactedModels(integrationIssue.metadata)
    : {};
  const isMissingPermissionsIssue = integrationIssue.error_description == "Missing Permission";
  const hasImpactedModels = Object.keys(impactedModels).length > 0;
  return (
    <>
      <ErrorStripe isMissingPermissionIssue={isMissingPermissionsIssue} />
      <IntegrationsIssuesCard className="d-flex flex-column">
        <div className="d-flex">
          <div className="d-flex align-items-center">
            <AlertTriangle
              size={16}
              color={isMissingPermissionsIssue ? spectrum.amber50 : spectrum.red50}
              className={isMissingPermissionsIssue ? "merge-text-warn" : "merge-text-danger"}
            />
            <ErrorTitleText isMissingPermissionIssue={isMissingPermissionsIssue}>
              {integrationIssue.error_description}
            </ErrorTitleText>
          </div>
          <div className="clickable-icon ml-auto">
            <X
              size={12}
              color={`${spectrum.gray50}`}
              onClick={() => {
                dismissIntegrationIssue({
                  integrationIssueId: integrationIssue.id,
                  onSuccess: (data: IntegrationIssue) => {
                    showSuccessToast("Successfully Dismissed Issue!");
                    setIntegrationIssue(data);
                  },
                  onFailure: () => {
                    showErrorToast("Unable to Dismiss Issue");
                  },
                });
              }}
            />
          </div>
        </div>
        {isMissingPermissionsIssue && integrationIssue.metadata && hasImpactedModels && (
          <ImpactedModelsRow impactedModels={impactedModels} isOverviewPage={true} />
        )}
        <div className="mt-3 medium text-sm">
          Recommended remediation to share with your customer
        </div>
        {integrationIssue.metadata && (
          <IntegrationIssueDetailedErrorContainer
            issueMetadata={integrationIssue.metadata}
            integrationIssue={integrationIssue}
            showPreview={showPreview}
            isMissingPermissionIssue={isMissingPermissionsIssue}
          />
        )}
        {issueLogs.length > 0 && showPreview && linkedAccount && (
          <IntegrationIssueLogsRow logs={issueLogs} linkedAccountId={linkedAccount.id} />
        )}
      </IntegrationsIssuesCard>
    </>
  );
};
export default IntegrationIssueWidget;
