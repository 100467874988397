import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ChevronRight } from "lucide-react";
import WebhookSystemImg from "../../../../assets/img/webhook-system.png";

import {
  DOCS_WEBHOOK_EMITTERS_PATH,
  DOCS_WEBHOOK_RECEIVERS_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
} from "../../../../router/RouterUtils";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Button = styled.button`
  background: var(--blue40);
  border-radius: 6px;
  outline: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  padding: 5px 12px 5px 16px;
  gap: 2px;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus-visible {
    background: var(--blue30);
  }
`;

const Header = styled(DeprecatedH3)`
  font-size: 24px;
  line-height: 36px;
`;

const Subtitle = styled(DeprecatedH6)`
  font-size: 14px;
  line-height: 24px;
`;

const Webhooks = () => {
  return (
    <Container>
      <Card>
        <Header className="deprecated-mb-1">Webhooks</Header>
        <Subtitle className="deprecated-mb-4 gray font-normal">
          Use Merge webhooks to keep your application up to date in real-time.
        </Subtitle>

        <p>
          Merge offers webhooks to send you data when it is updated in connected third-party apps.
        </p>
        <p>
          Webhooks will allow you to get near real-time data updates from Merge, without having to
          wait for the next poll.
        </p>
        <aside className="deprecated-my-5">
          Refer to our <Link to={ONBOARDING_ADVANCED_SYNCING_DATA_PATH}>Syncing Data</Link> guide to
          learn how to effectively sync data using polling and webhooks.
        </aside>
        <p className="deprecated-mb-5">
          Merge offers two webhook systems to send data from third parties to your product:{" "}
          <strong>Webhook Emitters</strong> and <strong>Webhook Receivers</strong>.
        </p>
        <img src={WebhookSystemImg} width="100%" />
        <DeprecatedH3 style={{ marginBottom: 8 }}>Webhook Emitters</DeprecatedH3>
        <DeprecatedH5 className="deprecated-mb-4 gray font-normal">
          Data from Merge to You
        </DeprecatedH5>
        <p>
          Configure Merge Webhook Emitters to send updates to your product when Merge has received
          user data updates from third parties.
        </p>
        <p>Merge offers webhooks for other event types (such as sync completion notifications).</p>
        <p className="deprecated-mb-5">
          Learn more in our{" "}
          <a target="_blank" href={DOCS_WEBHOOK_EMITTERS_PATH} rel="noreferrer">
            Webhook Emitter docs
          </a>
          .
        </p>

        <ShadedArea style={{ marginBottom: 56 }}>
          <DeprecatedH4>Merge Webhook Emitters</DeprecatedH4>
          <p className="small">
            Configure Merge Webhook Emitters that send data payloads to your app.
          </p>
          <Button onClick={() => window.open(DOCS_WEBHOOK_EMITTERS_PATH, "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
        <DeprecatedH3 style={{ marginBottom: 8 }}>Webhook Receivers</DeprecatedH3>
        <DeprecatedH5 className="deprecated-mb-4 gray font-normal">
          Data from Third-Party Apps to Merge
        </DeprecatedH5>
        <p>
          When data is updated in third parties, third-party webhooks can send data to Merge Webhook
          Receivers and update your user data in Merge.
        </p>
        <p>
          Some integrations offer automatic third-party webhook creation when your user connects
          their account. Merge configures these by interacting with the third-party API.
        </p>
        <p>
          Integrations that do not offer automatic third-party webhook creation may have manual
          configuration options.
        </p>
        <p className="deprecated-mb-5">
          Learn more in our{" "}
          <a target="_blank" href={DOCS_WEBHOOK_RECEIVERS_PATH} rel="noreferrer">
            Webhook Receiver docs
          </a>
          .
        </p>
        <ShadedArea className="deprecated-mb-4">
          <DeprecatedH4>Merge Webhook Receivers</DeprecatedH4>
          <p className="small">
            Configure webhooks in integrated third parties that send data payloads to Merge Webhook
            Receivers.
          </p>
          <Button onClick={() => window.open(DOCS_WEBHOOK_RECEIVERS_PATH, "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
      </Card>
    </Container>
  );
};

export default Webhooks;
