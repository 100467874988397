import { Key } from "lucide-react";
import React from "react";
import styled from "styled-components";
import { palette } from "../../../../styles/theme";
import MetadataPair from "../../../integrations-management/shared/MetadataPair";
import DottedOutlineTextCard from "../../../shared-components/DottedOutlineTextCard";

const HalfSection = styled.div`
  width: 49%;
  padding: 0px 0px 0px 0px;
`;

const Section = styled.div`
  width: 100%;
  padding: 16px 0px 0px 0px;
`;

const APIExplorerSampleMetadata = () => {
  const sampleIntegration = (
    <HalfSection>
      <MetadataPair label="Integration" data="—" />
    </HalfSection>
  );

  const sampleLinkedDate = (
    <HalfSection>
      <MetadataPair label="Linked" data="—" />
    </HalfSection>
  );

  const sampleAccountToken = (
    <Section>
      <MetadataPair
        label="Account Token"
        labelHoverText="Use account tokens to interact with Merge's API"
        labelIcon={<Key size="12px" style={{ color: palette.gray }} />}
        data={
          <DottedOutlineTextCard
            className="deprecated-mb-2 deprecated-mt-3"
            text="SAMPLE TEST LINKED ACCOUNT TOKEN"
            isSecret
            isEmbedded
          />
        }
      />
    </Section>
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        {" "}
        {sampleIntegration} {sampleLinkedDate}
      </div>

      <div className="d-flex flex-row align-items-center">{sampleAccountToken}</div>
    </>
  );
};

export default APIExplorerSampleMetadata;
