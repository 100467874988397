import { ChevronDown } from "lucide-react";
import React, { useEffect, useState } from "react";
import Select, { SingleValue, ActionMeta, GroupBase, components } from "react-select";

/*
 * TO-DO:
 * Add this to merge-javascript-shared WITHOUT using react-select (or use it? idk opinioted, it kinda slaps)
 */

interface DropdownOption {
  value: string;
  label: string;
}

type ReactSelectDropdownProps = {
  dropDownOptions: readonly (DropdownOption | GroupBase<DropdownOption>)[];
  onChange: (
    newValue: SingleValue<DropdownOption | undefined>,
    actionMeta: ActionMeta<DropdownOption | undefined>,
  ) => void;
  placeholder?: string;
  value?: SingleValue<DropdownOption | undefined>;
  className?: string;
  chevronSize?: number;
};

const ReactSelectDropdown = ({
  dropDownOptions,
  onChange,
  placeholder,
  value,
  className,
  chevronSize = 12,
}: ReactSelectDropdownProps) => {
  const [menuPortalTarget, setMenuPortalTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setMenuPortalTarget(document.body);
  }, []);

  const DropdownIndicator = (props: any) => {
    return <ChevronDown className="mr-3" size={chevronSize} />;
  };

  return (
    <>
      <Select
        className={className}
        options={dropDownOptions}
        onChange={onChange}
        isSearchable={false}
        isClearable={false}
        styles={{
          indicatorSeparator: () => ({ display: "none" }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "var(--gray0)" : "inherit",
            color: "var(--gray90)",
            paddingBottom: "10px",
            paddingTop: "10px",
            fontSize: "14px",
            lineHeight: "24px",
            "&:hover": {
              backgroundColor: "var(--gray0)",
              cursor: "pointer",
            },
          }),
          menu: (provided) => ({
            ...provided,
            marginTop: "0",
          }),
          control: (provided) => ({
            ...provided,
            boxShadow:
              "0px 1.5px 4px -1px rgba(0, 0, 0, 0.16), 0px 0px 0px 0.5px rgba(220, 226, 234, 0.20)",
            border: "none",
            borderRadius: "6px",
            "&:hover": {
              cursor: "pointer",
            },
            height: "40px",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "var(--gray40)",
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingLeft: "12px",
          }),
        }}
        placeholder={placeholder ? placeholder : "Select..."}
        components={{ DropdownIndicator }}
        value={value}
        menuPortalTarget={menuPortalTarget}
        menuPosition={"absolute"}
        menuPlacement={"auto"}
      />
    </>
  );
};

export default ReactSelectDropdown;
