import { useEffect, useState } from "react";

/**
 * Debounces a value for you so that you don't call it too often. Use like so:
 * ```
 * const [value, setValue] = useState('');
 * const debouncedValue = useDebounce(value, 200);
 * ```
 *
 * Use it wherever you'd like to capture changes, but don't really care what it
 * is until the value settles. Use this with search requests that ping the
 * backend, for example.
 */
const useDebounce = <Value>(value: Value, delayInMs?: number): Value => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  // Updates the value after the specified delay
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delayInMs ?? 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, delayInMs]);

  return debouncedValue;
};

export default useDebounce;
