import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { fetchCurrentUser, fetchWithAuth } from "../../../api-client/APIClient";
import { DOCS_LINKING_FLOW_GET_STARTED_PATH } from "../../../router/RouterUtils";
import useAppContext from "../../context/useAppContext";
import { SectionHeaderWrapper, TitleHeading } from "../../shared-components/MergeLayouts";
import { StatusToggle } from "../../shared-components/MergeToggles";
import { showErrorToast } from "../../shared-components/Toasts";

const ConfigurationLinkIntegrationSettingsPage = () => {
  const { user, setUser } = useAppContext();
  const autoEnableNewIntegrations = user.organization?.auto_enable_new_integrations;

  const updateSettings = ({
    autoEnableNewIntegrations,
  }: {
    autoEnableNewIntegrations?: boolean;
  }) => {
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: {
        auto_enable_new_integrations: autoEnableNewIntegrations,
      },
      onResponse: () => {
        fetchCurrentUser(setUser);
      },
      onError: () => {
        showErrorToast("Something went wrong, please check your connection and try again.");
      },
    });
  };

  // Card for enabling new integrations by default
  const newIntegrationsByDefault = (
    <Card>
      <Card.Body>
        <SectionHeaderWrapper
          title="Enable New Integrations by Default"
          subtitle={
            <>
              When Merge releases new integrations, add them automatically to{" "}
              <a href={DOCS_LINKING_FLOW_GET_STARTED_PATH} target="_blank" rel="noreferrer">
                <b>Link</b>.
              </a>
            </>
          }
          titleHeading={TitleHeading.H3}
          button={
            <StatusToggle
              id="toggle-enable-new-integrations"
              isEnabled={autoEnableNewIntegrations}
              onChange={(newIsEnabled: boolean) =>
                updateSettings({ autoEnableNewIntegrations: newIsEnabled })
              }
              statusTextClassName="deprecated-mr-3"
              statusTextStyle={{ fontSize: "13px" }}
            />
          }
        />
      </Card.Body>
    </Card>
  );

  return (
    <>
      <Row>
        <Col>
          <SectionHeaderWrapper title="Integrations">
            {newIntegrationsByDefault}
          </SectionHeaderWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationLinkIntegrationSettingsPage;
