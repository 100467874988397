import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import giftcard from "../../styles/css/img/giftcard.png";
import styled from "styled-components";
import MergeText, { TextType } from "../shared-components/MergeText";

const ReferralCard = styled.div`
  max-width: 816px;
  background: linear-gradient(305.29deg, #c6d6ff 12.94%, #dce5ff 51.86%, #f4f7ff 81.64%);
  border-radius: 8px;
`;

const ImageFrame = styled.div`
  opacity: 0.75;
  margin: 0.375rem;
  @media (min-width: 992px) {
    margin-left: 2.25rem;
  }
`;

function Referrals() {
  return (
    <>
      <ReferralCard className="d-flex flex-row align-items-center justify-content-center deprecated-p-5">
        <Row>
          <Col xs={12} lg={6} className="d-flex align-items-center">
            <ImageFrame>
              <img src={giftcard} width="100%" />
            </ImageFrame>
          </Col>
          <Col xs={12} lg={6} className="deprecated-px-5">
            <div className="d-flex flex-column align-items-start deprecated-ml-3">
              <MergeText
                isBold
                fontSize="28px"
                lineHeight="32px"
                className="deprecated-mt-2 deprecated-mb-2"
              >
                Earn a
              </MergeText>
              <MergeText
                isBold
                fontSize="74px"
                lineHeight="80px"
                className="deprecated-mt-2 deprecated-mb-2"
              >
                $500
              </MergeText>
              <MergeText
                isBold
                fontSize="28px"
                lineHeight="32px"
                className="deprecated-mt-2 deprecated-mb-2"
              >
                Amazon gift card
              </MergeText>
              <MergeText fontSize="15px" lineHeight="24px" className="deprecated-my-5">
                Refer a friend to Merge and get $500 via Amazon gift card!
              </MergeText>
              <Button variant="white">Refer a friend</Button>
            </div>
          </Col>
        </Row>
      </ReferralCard>
      <MergeText className="deprecated-pt-2" type={TextType.MUTED}>
        Gift card is rewarded upon your referral’s first invoice payment.
      </MergeText>
    </>
  );
}

export default Referrals;
