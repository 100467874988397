import React, { useState } from "react";
import { format, add } from "date-fns";
import classNames from "classnames";
import { DATE_PICKER_OPTIONS } from "../../constants";
import { Dropdown, Row, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import MergeModal from "./MergeModal";
import "react-datepicker/dist/react-datepicker.css";
import { calculateDateFromPicker } from "./utils/SharedComponentUtils";

interface DatePickerProps {
  selectedStartDate: Date | null;
  selectedEndDate: Date | null;
  onStartDateSet: (date: Date | null) => void;
  onEndDateSet: (date: Date | null) => void;
}

const createDateOptionString = (startDate: Date, endDate: Date) => {
  const formatString = "M/d/yyyy";
  return `${format(startDate, formatString)} - ${format(endDate, formatString)}`;
};

const MergeDatePicker = ({
  selectedStartDate,
  selectedEndDate,
  onStartDateSet,
  onEndDateSet,
}: DatePickerProps) => {
  const today = new Date();

  const [selectedDateOption, setSelectedDateOption] = useState<string | undefined>(
    (selectedStartDate &&
      selectedEndDate &&
      createDateOptionString(selectedStartDate, selectedEndDate)) ??
      undefined,
  );
  const [isCalendarSelectorEnabled, setIsCalendarSelectorEnabled] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(selectedStartDate);
  const [endDate, setEndDate] = useState<Date | null>(selectedEndDate);
  const [calendarDisplayMonth, setCalendarDisplayMonth] = useState(new Date().getMonth());

  const allDateOptions: Array<any> = Object.values(DATE_PICKER_OPTIONS);

  function onDateChoiceSelection(dateOption: string | undefined) {
    if (dateOption == undefined || dateOption == DATE_PICKER_OPTIONS.ALL_TIME) {
      onStartDateSet(null);
      onEndDateSet(null);
      setSelectedDateOption(undefined);
    } else if (dateOption === DATE_PICKER_OPTIONS.CUSTOM) {
      setIsCalendarSelectorEnabled(true);
    } else {
      setStartDate(null);
      setEndDate(null);
      onEndDateSet(null);
      setSelectedDateOption(dateOption);
      onStartDateSet(calculateDateFromPicker(dateOption));
      onEndDateSet(today);
    }
  }

  function onCalendarStartSelect(date: Date | [Date, Date] | null) {
    if (date == null) {
      setStartDate(today);
    } else if (date instanceof Array) {
      setStartDate(date[0]);
    } else {
      setStartDate(date);
    }
  }
  function onCalendarEndSelect(date: Date | [Date, Date] | null) {
    if (date instanceof Array) {
      setEndDate(date[0]);
    } else {
      setEndDate(date);
    }
  }

  function dateRangeChosen() {
    if (startDate != null && endDate != null) {
      setSelectedDateOption(createDateOptionString(startDate, endDate));
    }
    onStartDateSet(startDate);

    // add 1 day here so that custom range is inclusive
    // i.e. if user selects 1/24/2020 and 1/24/2020 it will add a day
    // so that it goes from midnight of that day to midnight of the next
    onEndDateSet(endDate && add(endDate, { days: 1 }));
    setIsCalendarSelectorEnabled(false);
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          variant="white"
          block
          className={classNames(
            "shadow deprecated-p-3",
            selectedDateOption && "btn-filter-selected",
          )}
        >
          {selectedDateOption ? selectedDateOption : "All time"}
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100">
          {allDateOptions.map((dateOption) => (
            <Dropdown.Item
              key={dateOption}
              onClick={() => onDateChoiceSelection(dateOption)}
              className="dropdown-menu-options"
            >
              {dateOption}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <MergeModal
        bodyClassName="date-picker-body"
        title="Custom date range"
        show={isCalendarSelectorEnabled}
        onHide={() => {
          setIsCalendarSelectorEnabled(false);
        }}
        fitContent
      >
        <div className="d-flex align-items-center">
          <DatePicker
            calendarClassName="date-picker-font"
            className="input-group-text date-picker"
            placeholderText="Select date"
            selected={startDate}
            onChange={onCalendarStartSelect}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            maxDate={today}
            onMonthChange={(date) => setCalendarDisplayMonth(date.getMonth())}
            dayClassName={(date) =>
              date.getMonth() !== calendarDisplayMonth && date < today ? "day-disabled" : ""
            }
          />
          <div className="date-picker-dash deprecated-ml-2 deprecated-mr-2">–</div>
          <DatePicker
            className="input-group-text date-picker"
            placeholderText="Select date"
            selected={endDate}
            onChange={onCalendarEndSelect}
            selectsEnd
            maxDate={today}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>
        <div className="mt-6">
          <Button
            className="calender-set-button"
            disabled={startDate == undefined || endDate == undefined}
            onClick={dateRangeChosen}
          >
            Select date range
          </Button>
        </div>
      </MergeModal>
    </>
  );
};

export default MergeDatePicker;
