import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import React, { useState } from "react";
import { useMergeLink } from "../useMergeLink";

interface Props {
  /**
   * Called when a new account is linked via the linking flow.
   */
  linkToken: string;
  tenantConfig: {} | { tenantConfig: { apiBaseURL: string } };
  onNewLinkedAccount: () => void;
}

const DevFilePickerActionButton = ({ linkToken, tenantConfig, onNewLinkedAccount }: Props) => {
  const [wasSuccess, setWasSuccess] = useState(false);

  const { open, isReady } = useMergeLink({
    linkToken: linkToken,
    onSuccess: () => setWasSuccess(true),
    onExit: () => wasSuccess && onNewLinkedAccount(),
    ...tenantConfig,
    filePickerConfig: {
      onSubmit: (files: Object[]) => {
        console.log(files);
      },
      allowMultiSelect: true,
    },
  });

  return (
    <SmallWhiteButton
      onClick={() => {
        setWasSuccess(false);
        open();
      }}
      disabled={!isReady}
    >
      Launch Test Link
    </SmallWhiteButton>
  );
};

export default DevFilePickerActionButton;
