import React, { useState, useEffect } from "react";
import { Row, Col, Dropdown, Form } from "react-bootstrap";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { useLocation, useHistory } from "react-router-dom";
import { ISSUE_STATUSES, LOG_STATUSES } from "../../../constants";
import { convertStringToDate, snakeCaseToFirstLetterUpperCase } from "../../../services";
import { useQuery } from "../../../router/RouterUtils";
import classNames from "classnames";
import MergeDatePicker from "../../shared-components/MergeDatePicker";
import TypeaheadDropdown from "../../shared-components/TypeaheadDropdown";
import queryString from "query-string";
import styled from "styled-components";
import { LinkedAccountStatus } from "../../../models/Entities";

const FormColumn = styled(Col)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 0px;
  padding-right: 0px;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;

const InputRow = styled(Row)`
  row-gap: 12px;

  @media (min-width: 1200px) {
    row-gap: 16px;
  }
`;

export const InputCol = styled(Col)`
  padding-right: 0;

  @media (min-width: 768px) {
    &:not(:nth-child(even)) {
      padding-right: 12px;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @media (min-width: 992px) {
    &:not(:last-child) {
      padding-right: 12px;
    }
  }

  @media (min-width: 1200px) {
    &:not(:last-child) {
      padding-right: 16px;
    }
  }
`;

export const ToggleArea = styled.div`
  background: #f2f4f7;
  border-radius: 8px;
  height: 46px;
  width: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
`;

interface Props {
  isLinkedAccounts?: boolean;
  isFieldMappingsLinkedAccounts?: boolean;
  isIntegrationLogs?: boolean;
  isIntegrationIssues?: boolean;
  isTestAccounts?: boolean;
  setIsTestAccounts?: (isTestAccounts: boolean) => void;
  setParamsPath: (data: string) => void;
}

const IntegrationsManagementFilter = ({
  isLinkedAccounts,
  isFieldMappingsLinkedAccounts,
  isIntegrationIssues,
  setParamsPath,
  isTestAccounts,
  setIsTestAccounts,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const statusQuery = query.get("status");
  const showResolvedIssuesDefault =
    statusQuery?.toUpperCase() === ISSUE_STATUSES.ISSUE_STATUS_RESOLVED.toUpperCase(); // Don't show resolved issues by default
  const integrationQuery =
    query.get("integration__name") ?? query.get("linked_account__integration__name");
  const endUserOrganizationQuery =
    query.get("end_user__organization_name") ??
    query.get("linked_account__end_user__organization_name");
  const startDateQuery = convertStringToDate(query.get("start_date"));
  const endDateQuery = convertStringToDate(query.get("end_date"));
  const [allIntegrations, setAllIntegrations] = useState<Array<string>>([]);
  const [allEndUserOrganizations, setAllEndUserOrganizations] = useState<Array<string>>([]);

  const allLinkedAccountsStatusOptions = [
    snakeCaseToFirstLetterUpperCase(LinkedAccountStatus.COMPLETE),
    snakeCaseToFirstLetterUpperCase(LinkedAccountStatus.INCOMPLETE),
    snakeCaseToFirstLetterUpperCase(LinkedAccountStatus.RELINK_NEEDED),
  ];

  const allLogsStatusOptions = [
    snakeCaseToFirstLetterUpperCase(LOG_STATUSES.REQUEST_STATUS_SUCCEEDED),
    snakeCaseToFirstLetterUpperCase(LOG_STATUSES.REQUEST_STATUS_FAILED),
  ];

  const [selectedStatus, setSelectedStatus] = useState<string | null>(
    statusQuery ? snakeCaseToFirstLetterUpperCase(statusQuery) : null,
  );

  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(integrationQuery);
  const [selectedEndUserOrganization, setSelectedEndUserOrganization] = useState<string | null>(
    endUserOrganizationQuery,
  );

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(startDateQuery);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(endDateQuery);

  const [displayMutedIssues, setDisplayMutedIssues] = useState(false);
  const [displayResolvedIssues, setDisplayResolvedIssues] =
    useState<boolean>(showResolvedIssuesDefault);

  useEffect(() => {
    const paramsPath = getParamsPath();

    setParamsPath(paramsPath);

    history.replace({
      pathname: location.pathname,
      search: paramsPath,
    });
  }, [
    selectedStatus,
    selectedIntegration,
    selectedEndUserOrganization,
    selectedStartDate,
    selectedEndDate,
    displayMutedIssues,
    displayResolvedIssues,
  ]);

  function getParamsPath() {
    if (isLinkedAccounts || isFieldMappingsLinkedAccounts) {
      const completeProductionDuplicatesOf = query.get("complete_production_duplicates_of");
      return queryString.stringify({
        ...(isIntegrationIssues && !displayResolvedIssues
          ? { status: ISSUE_STATUSES.ISSUE_STATUS_ONGOING.toUpperCase() }
          : {}),
        ...(!isIntegrationIssues && selectedStatus
          ? { status: selectedStatus.toUpperCase().split(" ").join("_") }
          : {}),
        ...(selectedIntegration ? { integration__name: selectedIntegration } : {}),
        ...(selectedEndUserOrganization
          ? { end_user__organization_name: selectedEndUserOrganization }
          : {}),
        ...(selectedStartDate ? { start_date: new Date(selectedStartDate).toISOString() } : {}),
        ...(selectedEndDate ? { end_date: new Date(selectedEndDate).toISOString() } : {}),
        ...(completeProductionDuplicatesOf
          ? { complete_production_duplicates_of: completeProductionDuplicatesOf }
          : {}),
      });
    } else {
      return queryString.stringify({
        ...(isIntegrationIssues &&
          !displayResolvedIssues && { status: ISSUE_STATUSES.ISSUE_STATUS_ONGOING.toUpperCase() }),
        ...(!isIntegrationIssues && selectedStatus
          ? { status: selectedStatus.toUpperCase().split(" ").join("_") }
          : {}),
        ...(selectedIntegration ? { linked_account__integration__name: selectedIntegration } : {}),
        ...(selectedEndUserOrganization
          ? { linked_account__end_user__organization_name: selectedEndUserOrganization }
          : {}),
        ...(selectedStartDate ? { start_date: new Date(selectedStartDate).toISOString() } : {}),
        ...(selectedEndDate ? { end_date: new Date(selectedEndDate).toISOString() } : {}),
        ...(displayMutedIssues ? { include_muted: displayMutedIssues } : {}),
      });
    }
  }

  const orgFilterURIPath = `/integrations/linked-accounts/end-user-organization-names${
    isLinkedAccounts ? (isTestAccounts ? "?is_test_account=True" : "?is_test_account=False") : ""
  }`;

  useEffect(() => {
    fetchWithAuth({
      path: "/integrations/names",
      method: "GET",
      onResponse: (data) => {
        setAllIntegrations(data);
      },
    });
    fetchWithAuth({
      path: orgFilterURIPath,
      method: "GET",
      onResponse: (data) => {
        setAllEndUserOrganizations(data);
      },
    });
  }, [history]);

  return (
    <FlexContainer className="deprecated-mb-4 deprecated-mt-3">
      {!isIntegrationIssues && (
        <InputRow className="no-gutters align-items-center" style={{ minWidth: "100%" }}>
          {!isLinkedAccounts && (
            <InputCol lg="3" md="6" sm="12">
              {!isFieldMappingsLinkedAccounts ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    className={classNames(
                      "shadow deprecated-p-3",
                      selectedStatus && "btn-filter-selected",
                    )}
                    block
                  >
                    {isTestAccounts ? "Test" : "Production"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {selectedStatus && (
                      <Dropdown.Item
                        onClick={() => setSelectedStatus(null)}
                        className="dropdown-menu-options"
                      >
                        All statuses
                      </Dropdown.Item>
                    )}

                    {isLinkedAccounts
                      ? allLinkedAccountsStatusOptions.map((status) => (
                          <Dropdown.Item
                            key={status}
                            onClick={() => setSelectedStatus(status)}
                            className="dropdown-menu-options"
                          >
                            {status}
                          </Dropdown.Item>
                        ))
                      : allLogsStatusOptions.map((status) => (
                          <Dropdown.Item
                            key={status}
                            onClick={() => setSelectedStatus(status)}
                            className="dropdown-menu-options"
                          >
                            {status}
                          </Dropdown.Item>
                        ))}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <div className="custom-border-right">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="white"
                      className={classNames(
                        "shadow deprecated-p-3",
                        selectedStatus && "btn-filter-selected",
                      )}
                      block
                    >
                      {isTestAccounts ? "Test" : "Production"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        key="Production"
                        onClick={() => setIsTestAccounts!(false)}
                        className="dropdown-menu-options"
                      >
                        Production
                      </Dropdown.Item>
                      <Dropdown.Item
                        key="Test"
                        onClick={() => setIsTestAccounts!(true)}
                        className="dropdown-menu-options"
                      >
                        Test
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
            </InputCol>
          )}
          <InputCol lg={isLinkedAccounts ? "4" : "3"} md="6" sm="12">
            <MergeDatePicker
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              onStartDateSet={setSelectedStartDate}
              onEndDateSet={setSelectedEndDate}
            />
          </InputCol>
          <InputCol lg={isLinkedAccounts ? "4" : "3"} md="6" sm="12">
            <Dropdown>
              <Dropdown.Toggle
                variant="white"
                block
                className={classNames(
                  "shadow deprecated-p-3",
                  selectedIntegration && "btn-filter-selected",
                )}
              >
                {selectedIntegration || "All integrations"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-search">
                <div>
                  <TypeaheadDropdown
                    options={allIntegrations}
                    onChange={(integrations) => setSelectedIntegration(integrations[0])}
                  />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </InputCol>
          <InputCol lg={isLinkedAccounts ? "4" : "3"} md="6" sm="12">
            <Dropdown>
              <Dropdown.Toggle
                variant="white"
                block
                className={classNames(
                  "shadow deprecated-p-3",
                  selectedEndUserOrganization && "btn-filter-selected",
                )}
              >
                {selectedEndUserOrganization || "All organizations"}
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-search">
                <div>
                  <TypeaheadDropdown
                    options={allEndUserOrganizations}
                    onChange={(organizations) => setSelectedEndUserOrganization(organizations[0])}
                  />
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </InputCol>
        </InputRow>
      )}
      {isIntegrationIssues && (
        <InputRow className="no-gutters align-items-center" style={{ minWidth: "100%" }}>
          <InputCol lg="8" md="12" sm="12">
            <InputRow className="no-gutters">
              <InputCol lg="4" md="6" sm="12">
                <MergeDatePicker
                  selectedStartDate={selectedStartDate}
                  selectedEndDate={selectedEndDate}
                  onStartDateSet={setSelectedStartDate}
                  onEndDateSet={setSelectedEndDate}
                />
              </InputCol>
              <InputCol lg="4" md="6" sm="12">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    block
                    className={classNames(
                      "shadow deprecated-p-3",
                      selectedIntegration && "btn-filter-selected",
                    )}
                  >
                    {selectedIntegration || "All integrations"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu-search">
                    <div>
                      <TypeaheadDropdown
                        options={allIntegrations}
                        onChange={(integrations) => setSelectedIntegration(integrations[0])}
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </InputCol>
              <InputCol lg="4" md="12" sm="12">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    block
                    className={classNames(
                      "shadow deprecated-p-3",
                      selectedEndUserOrganization && "btn-filter-selected",
                    )}
                  >
                    {selectedEndUserOrganization || "All organizations"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu-search">
                    <div>
                      <TypeaheadDropdown
                        options={allEndUserOrganizations}
                        onChange={(organizations) =>
                          setSelectedEndUserOrganization(organizations[0])
                        }
                      />
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </InputCol>
            </InputRow>
          </InputCol>

          <InputCol lg="4" md="12" sm="12">
            <InputRow className="no-gutters">
              <InputCol md="6" sm="12">
                <ToggleArea>
                  <FormColumn className="filter-selected-text float-right issue-mute-toggle-small d-flex align-items-center justify-content-between">
                    <Form.Label className="deprecated-mr-3 mb-0 issue-mute-toggle-small-left">
                      Show muted
                    </Form.Label>
                    <Form.Check
                      className="issue-mute-toggle-small-right"
                      type="switch"
                      id="mute-issues-switch"
                      checked={displayMutedIssues}
                      onClick={() => {
                        setDisplayMutedIssues(!displayMutedIssues);
                      }}
                    />
                  </FormColumn>
                </ToggleArea>
              </InputCol>
              <InputCol md="6" sm="12">
                <ToggleArea>
                  <FormColumn className="filter-selected-text float-right issue-mute-toggle-small d-flex align-items-center justify-content-between">
                    <Form.Label className="deprecated-mr-3 mb-0 issue-mute-toggle-small-left">
                      Show resolved
                    </Form.Label>
                    <Form.Check
                      className="issue-mute-toggle-small-right"
                      type="switch"
                      id="resolved-issues-switch"
                      checked={displayResolvedIssues}
                      onClick={() => {
                        setDisplayResolvedIssues(!displayResolvedIssues);
                      }}
                    />
                  </FormColumn>
                </ToggleArea>
              </InputCol>
            </InputRow>
          </InputCol>
        </InputRow>
      )}
    </FlexContainer>
  );
};

export default IntegrationsManagementFilter;
