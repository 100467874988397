import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import MetadataColumn, {
  Props as MetadataColumnProps,
} from "../linked-accounts/detail-page/LinkedAccountsMetadataColumn";
import { LinkedAccount } from "../../../models/Entities";
import { ArrowUpRight, ChevronRight, Layers, Library } from "lucide-react";
import ClickableContainer from "../../shared-components/ClickableContainer";
import {
  DOCS_BASE_PATH,
  DOCS_SYNCING_DATA_PATH,
  MERGE_HELP_CENTER_PATH,
  getDocsPathForCategory,
  getDocsPathForIntegration,
} from "../../../router/RouterUtils";
import { link } from "fs";
import IntegrationNameAndLogo from "../../configuration/integrations/IntegrationNameAndLogo";

export type Props = Pick<React.ComponentProps<"div">, "children"> & MetadataColumnProps;

const HALF_GUTTER_WIDTH = 16;

type ReferenceColumnProps = {
  linkedAccount: LinkedAccount | null;
};

const OverviewSection = styled.div`
  padding: 12px 16px 16px;
  border: 0.5px solid #d4def2;
  border-radius: 6px;
  background: #f5f7f9;
  font-weight: 600;
  font-size: 12px;
`;

const SectionTitle = styled.div`
  margin-bottom: 6px;
  padding-left: 4px;
`;

const ReferenceSectionTitleContainer = styled.div`
  font-size: 16px;
  padding: 12px 16px;
  border-bottom: 0.5px solid #dce2ea;
`;

const StyledLibrary = styled(Library)`
  margin-bottom: 4px;
`;

type ReferenceSectionContainerProps = {
  showBorder: boolean;
};
const ReferenceSectionContainer = styled.div<ReferenceSectionContainerProps>`
  padding: 16px;
  border-bottom: ${(props) => (props.showBorder ? "0.5px solid #dce2ea" : "")};
`;

const MergeDocsTitleContainer = styled.div`
  margin-bottom: 8px;
`;
const IconContainer = styled.div`
  margin-right: 7px;
`;
const StyledChevronRight = styled(ChevronRight)`
  margin-left: 5px;
`;

/**
 * Applies negative margins to add a gutter, when combined with padding on the `Col`s
 */
const GutteredRow = styled(Row)`
  @media (min-width: 992px) {
    margin-left: -${HALF_GUTTER_WIDTH}px;
    margin-right: -${HALF_GUTTER_WIDTH}px;
  }
`;

/**
 * Applies padding to add a gutter, when combined with margin on the parent `Row`
 */
const GutteredCol = styled(Col)`
  @media (min-width: 992px) {
    padding-left: ${HALF_GUTTER_WIDTH}px;
    padding-right: ${HALF_GUTTER_WIDTH}px;
  }
`;

const StickyCol = styled(GutteredCol)`
  --offset: 36px;
  position: sticky;
  height: calc(100vh - var(--offset));
  overflow-y: auto;
`;

const ReferenceCol = ({ linkedAccount }: ReferenceColumnProps) => {
  if (!linkedAccount) {
    return <></>;
  }
  return (
    <OverviewSection className="p-0">
      <ReferenceSectionTitleContainer>Reference</ReferenceSectionTitleContainer>
      <ReferenceSectionContainer className="d-flex flex-column" showBorder={true}>
        <MergeDocsTitleContainer className="d-flex align-items-center">
          <IconContainer>
            <Layers size={12} />
          </IconContainer>
          <ClickableContainer
            onClick={() => {
              window.open(DOCS_BASE_PATH, "_blank");
            }}
          >
            <div className="d-flex align-items-center">
              <div>Merge Docs</div>
              <StyledChevronRight size={12} />
            </div>
          </ClickableContainer>
        </MergeDocsTitleContainer>
        <a
          className="text-[10px] leading-[24px] w-fit"
          href={getDocsPathForCategory(linkedAccount.category)}
          target="_blank"
        >
          API Reference
        </a>
        <a
          className="text-[10px] leading-[24px] w-fit"
          href={getDocsPathForIntegration(linkedAccount.integration?.slug, linkedAccount.category)}
          target="_blank"
        >
          {linkedAccount.integration?.name}
        </a>
        <a
          className="text-[10px] leading-[24px] w-fit"
          href={DOCS_SYNCING_DATA_PATH}
          target="_blank"
        >
          Syncing Data
        </a>
      </ReferenceSectionContainer>
      <ReferenceSectionContainer
        className="d-flex flex-column"
        showBorder={linkedAccount.integration?.api_documentation_url !== undefined}
      >
        <MergeDocsTitleContainer className="d-flex align-items-center mb-0">
          <IconContainer>
            <StyledLibrary size={14} />
          </IconContainer>
          <ClickableContainer
            onClick={() => {
              window.open(
                `${MERGE_HELP_CENTER_PATH}/en/?q=${linkedAccount.integration?.slug}`,
                "_blank",
              );
            }}
          >
            <div className="d-flex align-items-center mb-0">
              <div>Help Center</div>
              <StyledChevronRight size={12} />
            </div>
          </ClickableContainer>
        </MergeDocsTitleContainer>
      </ReferenceSectionContainer>
      {linkedAccount.integration?.api_documentation_url && (
        <ClickableContainer
          onClick={() => {
            window.open(linkedAccount.integration.api_documentation_url, "_blank");
          }}
        >
          <ReferenceSectionContainer
            className="d-flex justify-content-between align-items-center"
            showBorder={false}
          >
            <IntegrationNameAndLogo
              integration={linkedAccount.integration}
              customText={`${linkedAccount.integration?.name} Docs`}
            />

            <ArrowUpRight size={12} />
          </ReferenceSectionContainer>
        </ClickableContainer>
      )}
    </OverviewSection>
  );
};

/**
 * Shows some main content + some metadata about the linked account in question
 */
const ReferenceMetadataPageLayout = ({ children, ...columnProps }: Props) => {
  return (
    <GutteredRow className="justify-content-center">
      <GutteredCol lg={9} sm={12}>
        {children}
      </GutteredCol>
      <StickyCol lg={3} sm={12}>
        <ReferenceCol {...columnProps} />
      </StickyCol>
    </GutteredRow>
  );
};

export default ReferenceMetadataPageLayout;
