import React from "react";

import { UseMergeFlagProps, useMergeFlag } from "./hooks/useMergeFlag";

type MergeFlagWrapperProps = UseMergeFlagProps & {
  children: React.ReactNode;
};

/**
 * Wrapper for conditionally rendering content based on merge flags.
 * Requires one (and only one) of organizationId, linkedAccountId, or integrationId
 */
function MergeFlagWrapper({
  children,
  feature,
  linkedAccountId,
  organizationId,
  integrationId,
}: MergeFlagWrapperProps) {
  // hooks
  const { enabled } = useMergeFlag({ feature, linkedAccountId, organizationId, integrationId });
  // if not enabled (or loading) we show nothing
  if (!enabled) return null;

  return <>{children}</>;
}

export default MergeFlagWrapper;
