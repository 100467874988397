import React from "react";
import { useIntercom } from "react-use-intercom";
import { Row, Col } from "react-bootstrap";
import ClickableContainer from "../../../shared-components/ClickableContainer";
import { InfoAlert } from "../../../shared-components/MergeAlerts";
import useProductRestrictions from "../../../shared-components/hooks/useProductRestrictions";

/**
 * Creates a note about launch only saving data going back 30 days for non-admins.
 */
const NonAdminNote = () => {
  const { show } = useIntercom();
  const { productRestrictions } = useProductRestrictions();
  const log_retention_days = productRestrictions ? productRestrictions.log_retention_days : 30;
  return (
    <InfoAlert className="deprecated-mb-5">
      <Row className="align-items-center">
        <Col>
          Your <strong>Launch</strong> plan only allows you to search logs going back{" "}
          {log_retention_days} days. For search longer than {log_retention_days} days, upgrade to
          our <strong>Core</strong>, <strong>Professional</strong>, or <strong>Enterprise</strong>{" "}
          plans.
        </Col>
        <Col className="col-auto">
          <div className="deprecated-mr-3">
            <ClickableContainer onClick={show}>
              <strong>
                Speak to a salesperson
                <span className="fe fe-chevron-right deprecated-ml-2" />
              </strong>
            </ClickableContainer>
          </div>
        </Col>
      </Row>
    </InfoAlert>
  );
};

export default NonAdminNote;
