import React from "react";
import { ArrowLeftRight, Webhook } from "lucide-react";
import { match, Redirect, Route, Switch } from "react-router-dom";
import ComponentForFeature from "../../../router/ComponentForFeature";
import { API_REQUESTS_LOGS_PATH, WEBHOOKS_LOGS_PATH } from "../../../router/RouterUtils";
import { Subtab } from "../../portal/HeaderBar";
import PortalPageHeaderWrapper from "../../portal/PortalPageHeaderWrapper";
import WebhookLogsPage from "./pages/WebhooksLogsPage";

interface Props {
  match: match;
}

/**
 * Either shows a detail page for an individual linked account, the linked
 * accounts page for prod accounts, or the Linked Accounts page for test
 * accounts.
 */
const LogsTab = ({ match }: Props) => {
  if (match) {
    // added conditional so React stops complaining about unused "match" param
  }

  const subTabs: Subtab[] = [
    {
      label: "API Requests",
      destination: API_REQUESTS_LOGS_PATH,
      icon: <ArrowLeftRight className="deprecated-mr-3" size="16px" />,
    },
    {
      label: "Webhooks",
      destination: WEBHOOKS_LOGS_PATH,
      icon: <Webhook className="deprecated-mr-3" size="16px" />,
    },
  ];

  return (
    <PortalPageHeaderWrapper title="Logs" subtabs={subTabs} isMaxWidthEnabled>
      <Switch>
        <Route
          path={API_REQUESTS_LOGS_PATH}
          children={({ match }: { match: any }) => (
            <ComponentForFeature feature="is_log_search_enabled" match={match} />
          )}
        />

        <Route path={WEBHOOKS_LOGS_PATH} component={WebhookLogsPage} />

        <Redirect to={API_REQUESTS_LOGS_PATH} />
      </Switch>
    </PortalPageHeaderWrapper>
  );
};

export default LogsTab;
