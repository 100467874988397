import React from "react";
import { useIntercom } from "react-use-intercom";

import { AuthLayout } from "../AuthLayouts";

const SamlNoAccountFound = () => {
  const { show } = useIntercom();
  return (
    <AuthLayout
      title="No account found"
      description={
        <>
          <p>We couldn't find your account in your organization's SSO configuration.</p>
          <p>Please contact your Merge organization administrator and request an invite.</p>
        </>
      }
      finePrint={
        <>
          If you want SSO for your organization,{" "}
          <a href="#" onClick={() => show()}>
            contact our sales team
          </a>
          .
        </>
      }
    />
  );
};

export default SamlNoAccountFound;
