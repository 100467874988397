import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import { LinkedAccount } from "../../../../models/Entities";
import { spectrum } from "../../../../styles/theme";

type Props = Pick<React.ComponentPropsWithoutRef<"div">, "className"> & {
  /**
   * The account to render
   */
  account: LinkedAccount;
  isFullText?: boolean;
  isRelinkNeeded?: boolean;
};

const Badge = styled.div<{ $color: string; $backgroundColor: string; $isFullText?: boolean }>`
  color: ${({ $color }) => $color};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 0 6px;
  border-radius: 4px;
  font-size: ${({ $isFullText }) => ($isFullText ? "12px" : "14px")};
`;

/**
 * Either a pink or a blue badge, depending on the account type
 */
const AccountTypeBadge = ({ account, isFullText, className, isRelinkNeeded }: Props) => {
  let color = account.is_test_account ? spectrum.pink50 : spectrum.indigo50;
  let backgroundColor = account.is_test_account ? spectrum.pink0 : spectrum.indigo0;
  let fullText = `${account.is_test_account ? "Test" : "Production"} Linked Account`;

  if (isRelinkNeeded) {
    color = spectrum.red40;
    fullText = "Relink Needed";
    backgroundColor = spectrum.red0;
  }

  return (
    <Badge
      className={classNames(className, "font-semibold")}
      $color={color}
      $backgroundColor={backgroundColor}
      $isFullText={isFullText}
    >
      {isFullText ? fullText : fullText.split(" ")[0]}
    </Badge>
  );
};

export default AccountTypeBadge;
