import classNames from "classnames";
import { MinusCircle, X } from "lucide-react";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Dropdown, Form, OverlayTrigger, Row } from "react-bootstrap";
import {
  ConditionOperatorSchema,
  ConditionType,
  ConditionValueType,
  SelectiveSyncCondition,
  SelectiveSyncConditionSchema,
} from "../../../../../models/Entities";
import { palette } from "../../../../../styles/theme";
import {
  AddAdditionalValueRow,
  AdditionalValueRow,
  AdditionalValueRowContianer,
  AddValueButton,
  Container,
  ConditionColumn,
  ConditionDropdownRow,
  DateContainer,
  DeleteConditionButton,
  FlexDropdown,
  HelpTextDropdownFooter,
  OrBadge,
  popperConfig,
  StyledDropdownToggle,
  VerticalBarDiv,
  FieldNameDropdownItem,
  DeleteValueButton,
  StyledFormControl,
  HoverableSpans,
  StyledTooltip,
} from "./SelectiveSyncStyles";
import DatePicker from "react-datepicker";
import {
  toDateFromValue,
  isoValueForDateTimeLocalValue,
  isoValueForDateValue,
} from "./SelectiveSyncUtils";
import { getLocalizedConditionOperatorName } from "../../../../../config/localization/mappings";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import SelectiveSyncTypahead from "./SelectiveSyncTypeahead";

const ValueListContainer = ({
  FieldNameColumn,
  FieldOperatorColumn,
  currentValueList,
  updateValueList,
  DeleteRowButton,
}: {
  FieldNameColumn: JSX.Element;
  FieldOperatorColumn: JSX.Element;
  currentValueList: Array<string>;
  updateValueList: (value: Array<string>) => void;
  DeleteRowButton: JSX.Element;
}) => {
  const updateValue = useCallback(
    (newValue: string, index: number) => {
      const updatedValue = [...currentValueList];
      updatedValue.splice(index, 1, newValue);
      updateValueList(updatedValue);
    },
    [currentValueList, updateValueList],
  );
  const deleteValue = useCallback(
    (index: number) => {
      const updatedValue = [...currentValueList];
      updatedValue.splice(index, 1);
      updateValueList(updatedValue);
    },
    [currentValueList, updateValueList],
  );

  return (
    <Container>
      <Row className="w-100">
        <ConditionDropdownRow className="col-11">
          {FieldNameColumn}
          {FieldOperatorColumn}
          <ConditionColumn className="col-5">
            <StyledFormControl
              $isPlaceholder={!currentValueList}
              style={{ borderRadius: "0px 6px 6px 0px" }}
              value={currentValueList[0]}
              placeholder="Enter..."
              onChange={(e: any) => {
                updateValue(e.target.value, 0);
              }}
            />
          </ConditionColumn>
        </ConditionDropdownRow>
        {DeleteRowButton}
      </Row>
      {currentValueList?.map((value, index) =>
        index == 0 ? (
          <React.Fragment key={`condition-row-${index}`}></React.Fragment>
        ) : (
          <Row className="w-100" key={`condition-row-${index}`}>
            <AdditionalValueRowContianer className="col-11">
              <OrBadge className="col-7">OR</OrBadge>
              <AdditionalValueRow className="col-5 deprecated-mt-3 deprecated-mb-3">
                <StyledFormControl
                  $isPlaceholder={!currentValueList}
                  style={{ borderRadius: "0px 6px 6px 0px" }}
                  value={value}
                  placeholder="Enter..."
                  onChange={(e: any) => {
                    updateValue(e.target.value, index);
                  }}
                />
                <DeleteValueButton onClick={() => deleteValue(index)}>
                  <X width="15px" height="15px" />
                </DeleteValueButton>
              </AdditionalValueRow>
            </AdditionalValueRowContianer>
          </Row>
        ),
      )}

      <AddAdditionalValueRow>
        <div className="col-6" />
        <AddValueButton
          className="col-5 deprecated-mt-3 deprecated-ml-3"
          onClick={() => updateValueList([...currentValueList, ""])}
        >
          Add another value
        </AddValueButton>
      </AddAdditionalValueRow>
    </Container>
  );
};
export const EditConditionDropdowns = ({
  linkedAccountID,
  integrationName,
  condition,
  updateCondition,
  schemaMap,
  availableSchemaIDsToOperators,
  deleteCondition,
}: {
  linkedAccountID: string;
  integrationName: string;
  condition: SelectiveSyncCondition;
  updateCondition: (condition: SelectiveSyncCondition) => void;
  schemaMap: { [schemaId: string]: SelectiveSyncConditionSchema };
  availableSchemaIDsToOperators: Record<string, ConditionOperatorSchema[]>;
  deleteCondition: () => void;
}) => {
  const [isFetchOptionsLoading, setIsLoadingFetchOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    if (condition?.can_fetch_filter_options) {
      setIsLoadingFetchOptions(true);
      fetchWithAuth({
        path: `integrations/selective-sync/configurations/linked-account/${linkedAccountID}/condition-schema/${condition.condition_schema_id}/filter-options`,
        method: "GET",
        onResponse: (data) => {
          setFilterOptions(data?.filter_options || []);
          setIsLoadingFetchOptions(false);
        },
      });
    }
  }, [condition.condition_schema_id]);

  const getConditionSchemaOrUndefined = () => {
    return (
      (condition.condition_schema_id &&
        condition.condition_schema_id in schemaMap &&
        schemaMap[condition.condition_schema_id]) ||
      undefined
    );
  };

  const updateConditionSchemaID = useCallback(
    (schemaID: string) => {
      const schema = schemaMap[schemaID];
      const availableOperators = availableSchemaIDsToOperators[schemaID];

      updateCondition({
        ...condition,
        operator: availableOperators.length === 1 ? availableOperators[0].operator : undefined,
        value: undefined,
        condition_schema_id: schemaID,
        native_name: schema.native_name,
        field_name: schema.field_name,
        can_fetch_filter_options: schema.can_fetch_filter_options,
      });
    },
    [condition, updateCondition],
  );

  const updateConditionOperator = useCallback(
    (operator: string | undefined) => {
      updateCondition({ ...condition, operator: operator });
    },
    [condition, updateCondition],
  );

  const updateConditionValue = useCallback(
    (value: string | Array<string> | undefined) => {
      updateCondition({ ...condition, value: value || undefined });
    },
    [condition, updateCondition],
  );

  const areSchemaOperatorsAvailable = useMemo(() => {
    const operators: ConditionOperatorSchema[] | undefined =
      availableSchemaIDsToOperators[condition.condition_schema_id];
    return operators?.length > 0;
  }, [condition, availableSchemaIDsToOperators]);

  const getConditionSchemaFieldOptions = () => {
    const avaliableSchemas: Set<string> = new Set(Object.keys(availableSchemaIDsToOperators));
    return [
      ...Object.keys(schemaMap)
        .filter((id) => avaliableSchemas.has(id))
        .map((option) => {
          const doesMergeFieldExist = !!schemaMap[option]?.field_name;

          return (
            <Dropdown.Item
              key={option}
              onClick={() => {
                updateConditionSchemaID(option);
              }}
              className="dropdown-menu-options"
            >
              <HoverableSpans>
                <FieldNameDropdownItem>{schemaMap[option]?.field_name}</FieldNameDropdownItem>
                <FieldNameDropdownItem
                  $useAltText={doesMergeFieldExist}
                  className={doesMergeFieldExist ? "deprecated-ml-2" : ""}
                >
                  {schemaMap[option]?.native_name}
                </FieldNameDropdownItem>
                {!doesMergeFieldExist && (
                  <OverlayTrigger
                    overlay={
                      <StyledTooltip id={schemaMap[option]?.native_name + "tooltip"}>
                        {schemaMap[option]?.native_name} is a remote field that is not included in
                        Merge's Common Models
                      </StyledTooltip>
                    }
                  >
                    <i
                      className="fe fe-info deprecated-pr-2 deprecated-ml-2"
                      color={palette.graphite}
                    />
                  </OverlayTrigger>
                )}
              </HoverableSpans>
            </Dropdown.Item>
          );
        }),
      <HelpTextDropdownFooter key="footer">
        <i className="fe fe-info deprecated-pr-2" color={palette.graphite} />
        <span>
          {integrationName} fields in <b>small text</b>
        </span>
      </HelpTextDropdownFooter>,
    ];
  };

  const getConditionSchemaOperatorOptions = useMemo(() => {
    return (
      <>
        {(availableSchemaIDsToOperators[condition.condition_schema_id] || []).map((option) => (
          <Dropdown.Item
            key={option.operator}
            onClick={() => {
              updateConditionOperator(option.operator);
            }}
            className="dropdown-menu-options"
          >
            {getLocalizedConditionOperatorName(
              getConditionSchemaOrUndefined()?.condition_type,
              option.operator,
            )}
          </Dropdown.Item>
        ))}
      </>
    );
  }, [availableSchemaIDsToOperators]);

  const ConditionDate = ({
    currentValue,
    updateValue,
  }: {
    currentValue: ConditionValueType;
    updateValue: (value: ConditionValueType) => void;
  }) => {
    const [currentDate, setCurrentDate] = useState<Date | undefined>(toDateFromValue(currentValue));

    const updateDate = () => {
      updateValue(isoValueForDateValue(currentDate));
    };

    return (
      <DatePicker
        popperProps={{
          strategy: "fixed",
        }}
        onClickOutside={updateDate}
        selected={currentDate}
        onChange={(date: Date) => setCurrentDate(date)}
        placeholderText="MM/dd/yyyy"
        dateFormat="MM/dd/yyyy"
        shouldCloseOnSelect={false}
      />
    );
  };

  const ConditionDateTime = ({
    currentValue,
    updateValue,
  }: {
    currentValue: ConditionValueType;
    updateValue: (value: ConditionValueType) => void;
  }) => {
    const [currentDate, setCurrentDate] = useState<Date | undefined>(toDateFromValue(currentValue));

    const updateDate = () => {
      updateValue(isoValueForDateTimeLocalValue(currentDate));
    };

    return (
      <DatePicker
        popperProps={{
          strategy: "fixed",
        }}
        onClickOutside={updateDate}
        selected={currentDate}
        onChange={(date: Date) => setCurrentDate(date)}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy hh:mm aa"
        showTimeInput
        placeholderText="MM/dd/yyyy hh:mm aa"
        shouldCloseOnSelect={false}
      />
    );
  };

  const getFieldNameOrUndefined = () =>
    getConditionSchemaOrUndefined()?.field_name ||
    getConditionSchemaOrUndefined()?.native_name ||
    undefined;

  const FieldNameCol = (
    <ConditionColumn className="col-4">
      <FlexDropdown>
        <Dropdown style={{ flexGrow: 1 }}>
          <StyledDropdownToggle
            $isPlaceholder={getFieldNameOrUndefined() === undefined}
            variant="white"
            className={classNames("deprecated-p-3", "btn-filter-selected")}
            block
          >
            {getFieldNameOrUndefined() || "Select..."}
          </StyledDropdownToggle>
          <Dropdown.Menu popperConfig={popperConfig} className="pb-0">
            {getConditionSchemaFieldOptions()}
          </Dropdown.Menu>
        </Dropdown>
      </FlexDropdown>
      <VerticalBarDiv />
    </ConditionColumn>
  );

  const FieldOperatorCol = (
    <ConditionColumn className="col-3">
      <FlexDropdown>
        <Dropdown style={{ flexGrow: 1 }}>
          <StyledDropdownToggle
            disabled={!areSchemaOperatorsAvailable}
            variant="white"
            className={classNames("deprecated-p-3", "btn-filter-selected")}
            block
          >
            {getLocalizedConditionOperatorName(
              getConditionSchemaOrUndefined()?.condition_type,
              condition.operator,
            ) || "Select..."}
          </StyledDropdownToggle>
          <Dropdown.Menu popperConfig={popperConfig}>
            {getConditionSchemaOperatorOptions}
          </Dropdown.Menu>
        </Dropdown>
      </FlexDropdown>
      <VerticalBarDiv />
    </ConditionColumn>
  );

  const DeleteRowButton = (
    <DeleteConditionButton className="col-1" onClick={() => deleteCondition()}>
      <MinusCircle width="15px" height="15px" />
    </DeleteConditionButton>
  );

  return (() => {
    if (condition?.can_fetch_filter_options) {
      const selectedOption = filterOptions.find((filterOption: any) => {
        return filterOption.value == condition.value;
      });
      return (
        <Row style={{ width: "100%" }}>
          <ConditionDropdownRow className="col-11" style={{ overflow: "visible" }}>
            {FieldNameCol}
            {FieldOperatorCol}
            <div className="flex-grow-1 align-self-center">
              <SelectiveSyncTypahead
                isLoading={isFetchOptionsLoading}
                value={selectedOption}
                options={filterOptions}
                onSelect={(option: any) => {
                  updateConditionValue(option.value);
                }}
                loadingMessage={`Loading ${integrationName} filter options`}
              />
            </div>
          </ConditionDropdownRow>
          {DeleteRowButton}
        </Row>
      );
    }

    switch (getConditionSchemaOrUndefined()?.condition_type || "") {
      case ConditionType.DATE:
        return (
          <Row style={{ width: "100%" }}>
            <ConditionDropdownRow className="col-11">
              {FieldNameCol}
              {FieldOperatorCol}
              <ConditionColumn className="col-5">
                <FlexDropdown>
                  <DateContainer>
                    <ConditionDate
                      currentValue={condition.value}
                      updateValue={updateConditionValue}
                    />
                  </DateContainer>
                </FlexDropdown>
              </ConditionColumn>
            </ConditionDropdownRow>
            {DeleteRowButton}
          </Row>
        );
      case ConditionType.DATE_TIME:
        return (
          <Row style={{ width: "100%" }}>
            <ConditionDropdownRow className="col-11">
              {FieldNameCol}
              {FieldOperatorCol}
              <ConditionColumn className="col-5">
                <FlexDropdown>
                  <DateContainer>
                    <ConditionDateTime
                      currentValue={condition.value}
                      updateValue={updateConditionValue}
                    />
                  </DateContainer>
                </FlexDropdown>
              </ConditionColumn>
            </ConditionDropdownRow>
            {DeleteRowButton}
          </Row>
        );
      case ConditionType.LIST_OF_STRINGS:
        return (
          <ValueListContainer
            FieldNameColumn={FieldNameCol}
            FieldOperatorColumn={FieldOperatorCol}
            currentValueList={Array.isArray(condition.value) ? condition.value : []}
            updateValueList={updateConditionValue}
            DeleteRowButton={DeleteRowButton}
          />
        );
      default:
        return (
          <Row style={{ width: "100%" }}>
            <ConditionDropdownRow className="col-11">
              {FieldNameCol}
              {FieldOperatorCol}
              {getConditionSchemaOrUndefined()?.choices ? (
                <ConditionColumn className="col-5">
                  <FlexDropdown>
                    <Dropdown style={{ flexGrow: 1 }}>
                      <StyledDropdownToggle
                        $isPlaceholder={!condition.value}
                        variant="white"
                        className={classNames(
                          "deprecated-p-3",
                          condition.value && "btn-filter-selected",
                        )}
                        block
                      >
                        {condition.value || "Select..."}
                      </StyledDropdownToggle>
                      <Dropdown.Menu popperConfig={popperConfig}>
                        {(getConditionSchemaOrUndefined()?.choices || []).map((option) => (
                          <Dropdown.Item
                            key={option}
                            onClick={() => {
                              updateConditionValue(option);
                            }}
                            className="dropdown-menu-options"
                          >
                            {option}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </FlexDropdown>
                </ConditionColumn>
              ) : (
                <ConditionColumn className="col-5">
                  <Form.Control
                    value={condition.value || ""}
                    placeholder="Enter..."
                    onChange={(e) => {
                      updateConditionValue(e.target.value);
                    }}
                  />
                </ConditionColumn>
              )}
            </ConditionDropdownRow>
            {DeleteRowButton}
          </Row>
        );
    }
  })();
};
