import React, { useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { deleteLinkedAccountFieldMapping } from "../../../../../api-client/APIClient";
import MergeModal from "../../../../shared-components/MergeModal";
import SpinnerButton from "../../../../shared-components/SpinnerButton";
import useLinkedAccountCustomMappingsContext from "./context/useLinkedAccountFieldMappingsContext";
import { deleteFieldMappingTarget } from "../../../../configuration/integrations/field-mappings/utils/FieldMappingUtils";
import { showErrorToast, showSuccessToast } from "../../../../shared-components/Toasts";
import styled from "styled-components";
import useAppContext from "../../../../context/useAppContext";

type Props = {
  headerText?: string;
  linkedAccountID?: string;
  fieldMappingID?: string;
  onHide: () => void;
  showModal: boolean;
  handlePostDelete?: () => void;
  text?: string;
  secondaryText?: string;
  isFieldMappingTargetDelete?: boolean;
  fieldMappingTargetID?: string;
};

const DeleteFieldMappingModal = ({
  headerText,
  fieldMappingID,
  onHide,
  showModal,
  handlePostDelete,
  text,
  secondaryText,
  isFieldMappingTargetDelete = false,
  fieldMappingTargetID,
}: Props) => {
  const { user } = useAppContext();
  const [isFieldMappingDeletionConfirmationChecked, setIsFieldMappingDeletionConfirmationChecked] =
    useState(false);
  const { refreshFieldMappings: refreshCustomMappings } = useLinkedAccountCustomMappingsContext();

  const [isDeleting, setIsDeleting] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const isFieldMappingV2Enabled =
    (user?.field_mapping_v2_user_enabled ?? false) ||
    (user?.organization?.field_mapping_v2_org_enabled ?? false);

  const DeleteButton = styled(SpinnerButton)`
    width: ${isFieldMappingV2Enabled ? "100%" : "80%"};
    margin-bottom: 10px;
  `;
  const CancelButton = styled(Button)`
    width: ${isFieldMappingV2Enabled ? "100%" : "80%"};
  `;

  return (
    <MergeModal
      centered
      show={showModal}
      bodyClassName="modal-more-narrow overflow-hidden"
      dialogClassName="modal-more-narrow"
      onHide={onHide}
      title={headerText || "Delete Mapping"}
      ref={modalRef}
    >
      <span className="text-center">
        {text || "This action cannot be undone"}
        {secondaryText ? (
          <>
            <br /> <br /> {secondaryText}
          </>
        ) : (
          <></>
        )}
        <br />
        <br />
        Do you wish to continue?
        {!isFieldMappingV2Enabled && (
          <Form.Group className="d-flex deprecated-mt-4 deprecated-mb-4">
            <Form.Check
              type="checkbox"
              id="field-mapping-deletion-checkbox"
              checked={isFieldMappingDeletionConfirmationChecked}
              onChange={() =>
                setIsFieldMappingDeletionConfirmationChecked(
                  !isFieldMappingDeletionConfirmationChecked,
                )
              }
              label={<b>I understand that this is an irreversible action.</b>}
            />
          </Form.Group>
        )}
        <div className={`w-100 text-center ${isFieldMappingV2Enabled ? "mt-6" : ""}`}>
          <DeleteButton
            disabled={!isFieldMappingV2Enabled && !isFieldMappingDeletionConfirmationChecked}
            isLoading={isDeleting}
            className="modal-button btn-danger"
            text={
              isFieldMappingTargetDelete
                ? "Delete Target Field"
                : isFieldMappingV2Enabled
                ? "Delete Mapping"
                : "Delete Field Mapping"
            }
            type="submit"
            onClick={() => {
              setIsDeleting(true);
              if (isFieldMappingTargetDelete) {
                deleteFieldMappingTarget({
                  fieldMappingTargetID: fieldMappingTargetID!,
                  onResponse: () => showSuccessToast("successfully deleted Target"),
                  onError: () => showErrorToast("Could not delete Field Mapping Target"),
                });
              } else {
                deleteLinkedAccountFieldMapping(fieldMappingID!, refreshCustomMappings);
              }
              setIsDeleting(false);
              if (handlePostDelete) {
                handlePostDelete();
              }
              onHide();
            }}
          />
        </div>
        <div className="w-100 text-center">
          <CancelButton variant="white" className="w-100 border-white" onClick={onHide}>
            Cancel
          </CancelButton>
        </div>
      </span>
    </MergeModal>
  );
};

export default DeleteFieldMappingModal;
