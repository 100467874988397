import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { TEST_LINKED_ACCOUNTS_PATH } from "../../../router/RouterUtils";
import LinkedAccountsPageWrapper from "./LinkedAccountsPageWrapper";
import TestLinkedAccountsPage from "./TestLinkedAccountsPage";

const TestLinkedAccountsTab = () => {
  return (
    <LinkedAccountsPageWrapper>
      <Switch>
        <Route exact path={TEST_LINKED_ACCOUNTS_PATH} component={TestLinkedAccountsPage} />

        <Redirect to={TEST_LINKED_ACCOUNTS_PATH} />
      </Switch>
    </LinkedAccountsPageWrapper>
  );
};

export default TestLinkedAccountsTab;
