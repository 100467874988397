import React from "react";
import styled from "styled-components";
import { ChevronRight } from "lucide-react";

import {
  DOCS_WRITES_MERGE_WRITES,
  DOCS_WRITES_RELATED_AND_NESTED_WRITES,
  DOCS_WRITES_PROGRAMMATIC_WRITES,
  DOCS_WRITES_PROGRAMMATIC_NESTED_WRITES,
  DOCS_WRITES_TEMPLATES_AND_CONDITIONAL_FIELDS,
} from "../../../../router/RouterUtils";
import DeprecatedH3 from "../../../../deprecated/DeprecatedH3";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

const Container = styled.div`
  @media (min-width: 1330px) {
    margin: 32px auto;
    max-width: 75%;
    padding-bottom: 64px;
  }
`;

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 24px;
`;

const ShadedArea = styled.div`
  border-radius: 10px;
  width: 100%;
  display: block;
  background: #eff6ff;
  padding: 16px 20px;
`;

const Button = styled.button`
  background: var(--blue40);
  border-radius: 6px;
  outline: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  padding: 5px 12px 5px 16px;
  gap: 2px;
  border: none;
  box-shadow: none;

  &:hover,
  &:focus-visible {
    background: var(--blue30);
  }
`;

const Header = styled(DeprecatedH3)`
  font-size: 24px;
  line-height: 36px;
`;

const Subtitle = styled(DeprecatedH6)`
  font-size: 14px;
  line-height: 24px;
`;

const MergeWrites = () => {
  return (
    <Container>
      <Card>
        <Header className="deprecated-mb-1">Merge Writes</Header>
        <Subtitle className="deprecated-mb-4 gray font-normal">
          Push data to integrated third-parties.
        </Subtitle>

        <DeprecatedH3>Basic Writes</DeprecatedH3>
        <p className="deprecated-mb-5">
          In our Basic topics, we introduce universal concepts that allow you to execute writes for{" "}
          <strong>most</strong> — but not all — third-party platforms and Linked Accounts.
        </p>
        <ShadedArea className="deprecated-mb-4">
          <DeprecatedH4>Merge Writes</DeprecatedH4>
          <p className="small">
            Make POST requests to Merge to write data to third-party platforms.
          </p>
          <Button onClick={() => window.open(DOCS_WRITES_MERGE_WRITES, "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
        <ShadedArea>
          <DeprecatedH4>Related and Nested Writes</DeprecatedH4>
          <p className="small">
            Write data to third-party platforms with relations to existing and new Common Model
            instances.
          </p>
          <Button onClick={() => window.open(DOCS_WRITES_RELATED_AND_NESTED_WRITES, "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
        <DeprecatedH3 className="deprecated-mt-5">Advanced Writes</DeprecatedH3>
        <p>Writes are unified across all of our third-party platforms.</p>
        <p>
          However, some platforms require an additional piece of Merge&apos;s advanced functionality
          to support them.
        </p>
        <p className="deprecated-mb-5">
          In our Advanced topics, we introduce programmatic concepts that make heavy use of an
          endpoint we call <code>/meta</code> to handle writes for <strong>all</strong> third-party
          platforms and Linked Accounts.
        </p>
        <ShadedArea className="deprecated-mb-4">
          <DeprecatedH4>Programmatic Writes with /meta</DeprecatedH4>
          <p className="small">
            Use /meta to programmatically form and validate POST requests to write standardized or
            integration-specific / Linked Account-specific model fields.
          </p>
          <Button onClick={() => window.open(DOCS_WRITES_PROGRAMMATIC_WRITES, "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
        <ShadedArea className="deprecated-mb-4">
          <DeprecatedH4>Programmatic Nested Writes with /meta</DeprecatedH4>
          <p className="small">
            Use /meta to create related Common Model instances (through nesting) with standardized
            or integration-specific / Linked Account-specific fields.
          </p>
          <Button onClick={() => window.open(DOCS_WRITES_PROGRAMMATIC_NESTED_WRITES, "_blank")}>
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
        <ShadedArea>
          <DeprecatedH4>Templates and Conditional Fields</DeprecatedH4>
          <p className="small">
            Use /meta to determine which specific model fields are available based on your
            user&apos;s input in another field.
          </p>
          <Button
            onClick={() => window.open(DOCS_WRITES_TEMPLATES_AND_CONDITIONAL_FIELDS, "_blank")}
          >
            View docs <ChevronRight size={12} />
          </Button>
        </ShadedArea>
      </Card>
    </Container>
  );
};

export default MergeWrites;
