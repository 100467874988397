import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { WebhookReceiver } from "../../../../../models/Entities";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import RotatingChevronRight from "../../../../shared-components/RotatingChevronRight";
import { getCapitalizedTimeFromNowShort } from "../../../../shared-components/utils/SharedComponentUtils";
import WebhookReceiverDetails from "./WebhookReceiverDetails";
import { Badge } from "@merge-api/merge-javascript-shared";

export interface Props {
  /**
   * This is the content that shows in this row
   */
  receiver: WebhookReceiver;

  /**
   * Which integration this is for
   */
  integrationName: string;

  /**
   * The linked account id for the receiver parent
   */
  linkedAccountId: string;

  /**
   * Updates the receiver to a new value
   */
  updateReceiver: (receiver: WebhookReceiver) => void;

  /**
   * Whether or not the integration's webhooks are configured to use a handshake process. Determines display of handshake UX.
   */
  integrationUsesWebhookHandshake: boolean;

  /**
   * Present if we show prewritten instructions for setting up webhooks
   * for this integration.
   */
  webhookInstructions?: string | null;

  /**
   * True if the org has enabled auto webhook setup and the integration uses it.
   */
  isUsingAutoWebhookSetup?: boolean;

  /**
   * Max width of table
   */

  width: number;
}

const OneLineTooltip = styled(Tooltip)`
  & .tooltip-inner {
    max-width: 100%;
  }
`;

/**
 * Cell that expands its contents on click (with an
 * expand state). Shows a pointer for the contents,
 * so set the cursor as non-pointer in a child if needed.
 */
const ClickableRow = ({
  isExpanded,
  setIsExpanded,
  children,
  ...props
}: Omit<React.ComponentPropsWithoutRef<"tr">, "onClick"> & {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}) => {
  const { productRestrictions } = useProductRestrictions();
  const isReadonly = !productRestrictions?.webhooks_enabled;

  return (
    <tr
      {...props}
      onClick={
        isReadonly
          ? undefined
          : () => {
              setIsExpanded(!isExpanded);
            }
      }
    >
      {children}
    </tr>
  );
};

/**
 * Shows a single row for the WebhookEventsTable, where
 * each row shows one receiver itself.
 */
const WebhookReceiverRow = ({
  receiver,
  integrationName,
  linkedAccountId,
  updateReceiver,
  integrationUsesWebhookHandshake,
  webhookInstructions,
  isUsingAutoWebhookSetup,
  width,
}: Props) => {
  const { event, is_active, last_received_at, is_awaiting_handshake } = receiver;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const detailsElementRef = useRef<Pick<HTMLInputElement, "focus">>(null);
  const { productRestrictions } = useProductRestrictions();
  const isReadonly = !productRestrictions?.webhooks_enabled;

  // Resets focused state when closing the row
  useEffect(() => {
    if (!isExpanded) {
      setIsFocused(false);
    }
  }, [isExpanded]);

  const statusBadge =
    is_awaiting_handshake && integrationUsesWebhookHandshake ? (
      <Badge size="md" color="red">
        Handshake
      </Badge>
    ) : is_active ? (
      <Badge size="md" color="teal">
        Enabled
      </Badge>
    ) : (
      <OverlayTrigger
        placement="top"
        overlay={
          <OneLineTooltip id="not-listening">Add secret key to enable listener</OneLineTooltip>
        }
      >
        <Badge size="md" color="gray">
          Disabled
        </Badge>
      </OverlayTrigger>
    );

  const timestampContents = last_received_at ? (
    <div className="text-gray-90 text-base">{getCapitalizedTimeFromNowShort(last_received_at)}</div>
  ) : (
    <div className="text-base font-medium text-gray-30">—</div>
  );

  const row = (
    <>
      <ClickableRow
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        className={classNames(
          "hover:cursor-pointer active:cursor-pointer focus:cursor-pointer",
          isExpanded && "bg-lighter !border-b-0",
        )}
      >
        <td>
          <div className="text-base font-medium">{event}</div>
        </td>
        <td>{statusBadge}</td>
        <td>{timestampContents}</td>
        <td className="d-flex justify-content-end">
          <RotatingChevronRight
            isRotatedDown={isExpanded}
            onClick={
              isReadonly
                ? undefined
                : (event) => {
                    event.stopPropagation();
                    setIsExpanded(!isExpanded);
                  }
            }
          />
        </td>
      </ClickableRow>
      {isExpanded && (
        <td colSpan={6} className="bg-lighter border-t-0">
          <WebhookReceiverDetails
            integrationName={integrationName}
            receiver={receiver}
            isInitiallyFocused={isFocused}
            width={width}
            linkedAccountId={linkedAccountId}
            updateReceiver={updateReceiver}
            integrationUsesWebhookHandshake={integrationUsesWebhookHandshake}
            isUsingAutoWebhookSetup={isUsingAutoWebhookSetup}
            webhookInstructions={webhookInstructions}
            ref={detailsElementRef}
          />
        </td>
      )}
    </>
  );

  const restrictedTooltip = isReadonly && (
    <OverlayTrigger
      placement="top"
      delay={{ show: 0, hide: 0 }}
      overlay={
        <OneLineTooltip id="restriction-webhooks">
          To make edits, please contact sales to update your plan
        </OneLineTooltip>
      }
    >
      {row}
    </OverlayTrigger>
  );

  return <>{restrictedTooltip || row}</>;
};

export default WebhookReceiverRow;
