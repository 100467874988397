import { DashboardOnboardingStatus } from "../DashboardOnboardingWrapper";

export const hasOrgOnboardedSuccessfully = (
  dashboardOnboardingStatus?: DashboardOnboardingStatus,
) => {
  return (
    dashboardOnboardingStatus?.has_made_request &&
    dashboardOnboardingStatus?.has_successfully_linked_account &&
    dashboardOnboardingStatus?.has_pulled_common_model_data &&
    dashboardOnboardingStatus?.has_opened_production_link
  );
};
