import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { APICategory } from "../../../models/Entities";
import HeaderBar from "../../portal/HeaderBar";
import INTEGRATION_CATEGORY_LIST, {
  displayNameForAPICategory,
  lucideIconForAPICategory,
} from "../../../models/Helpers";
import {
  CONFIGURATION_FIELD_MAPPINGS_PATH,
  commonModelsConfigurationPathForIndividualCategory,
} from "../../../router/RouterUtils";
import SubLeftNavigationContainer from "../../portal/SubLeftNavigationContainer";
import { MergeFlagFeature, useMergeFlag } from "../../shared-components/hooks/useMergeFlag";
import useAppContext from "../../context/useAppContext";
import { ProductRestrictions } from "../../settings/billing/BillingModels";
import useProductRestrictions from "../../shared-components/hooks/useProductRestrictions";
import RedactUnmappedDataModal from "./RedactUnmappedDataModal";

type ConfigurationCommonModelsPageWrapperProps = {
  showUpsellModal: boolean;
  setShowUpsellModal: (isOpen: boolean) => void;
};

const _maybeRenderRedactionCard = (
  organizationId: string,
  isUserPrivileged: boolean,
  onToggleClick: (enabled: boolean) => void,
  productRestrictions?: ProductRestrictions,
  isRedactionEnabledForOrganization?: boolean,
) => {
  const redactionCardDetails = (
    <div>
      <div>
        {" "}
        Redact all fields in API logs and Remote Data that are not mapped to Merge Common Model
        fields or used in
        <a href={CONFIGURATION_FIELD_MAPPINGS_PATH} target="_blank">
          {" "}
          Field Mapping.
        </a>
      </div>
      <div className="mt-1">
        Visit our
        <a
          href="https://help.merge.dev/en/articles/8358419-redacting-unmapped-data"
          target="_blank"
        >
          {" "}
          Help Center{" "}
        </a>
        to learn more.
      </div>
    </div>
  );

  // Whether the frontend flag showing the redaction cards is enabled
  const { enabled: isRedactionFrontendEnabled } = useMergeFlag({
    feature: MergeFlagFeature.MERGE_FLAG_ENABLE_REDACT_UNMAPPED_DATA_FRONTEND,
    organizationId: organizationId,
  });

  // Whether redaction is turned on for this organization
  const isRedactionEnabled = isRedactionEnabledForOrganization;

  // Whether the entire redaction feature is allowed for this organization
  const isRedactionFeatureEnabled = productRestrictions?.redact_unmapped_data_enabled ?? false;

  return isRedactionFrontendEnabled && isRedactionFeatureEnabled && isUserPrivileged
    ? {
        cardTitle: "Redact Unmapped Data",
        cardDetails: redactionCardDetails,
        toggleId: "toggle-redact-unmapped-data",
        isToggleChecked: isRedactionEnabled,
        onToggleClick: onToggleClick,
      }
    : null;
};

/**
 * Creates a wrapper for the Common Models configuration page, with links to each category
 */
const ConfigurationCommonModelsPageWrapper = ({
  showUpsellModal,
  setShowUpsellModal,
  children,
}: ConfigurationCommonModelsPageWrapperProps & Pick<React.ComponentProps<"div">, "children">) => {
  const { user, isUserPrivileged } = useAppContext();
  const { productRestrictions } = useProductRestrictions();
  const [showModal, setShowModal] = useState(false);

  const _onToggleClick = (enabled: boolean) => {
    setShowModal(true);
  };

  const isRedactionEnabledForOrganization = user.organization.redact_unmapped_data_enabled;
  const organizationId = user.organization.id;

  const maybeRedactionCard = _maybeRenderRedactionCard(
    organizationId,
    isUserPrivileged,
    _onToggleClick,
    productRestrictions,
    isRedactionEnabledForOrganization,
  );
  const cards = maybeRedactionCard ? [maybeRedactionCard] : [];

  return (
    <>
      {isRedactionEnabledForOrganization !== undefined && (
        <RedactUnmappedDataModal
          isRedactionCurrentlyEnabledForOrg={isRedactionEnabledForOrganization}
          organizationId={organizationId}
          isModalOpen={showModal}
          onHide={() => setShowModal(false)}
          onConfirmResponse={() => setShowModal(false)}
        />
      )}
      <Row className="justify-content-center">
        <Col className="col-12 col-lg-10 col-xl-8">
          <HeaderBar
            title="Common Models"
            subtitle="Manage the standardized data models that you interact with via Merge's Unified API."
          />
          <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
            <SubLeftNavigationContainer
              subtabs={[
                ...INTEGRATION_CATEGORY_LIST.map((category: APICategory) => ({
                  label: displayNameForAPICategory(category),
                  destination: commonModelsConfigurationPathForIndividualCategory(category),
                  icon: lucideIconForAPICategory(category, "md", "mr-3 mb-1"),
                })),
              ]}
              cards={cards}
            >
              {children}
            </SubLeftNavigationContainer>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationCommonModelsPageWrapper;
