import { MoreHorizontal } from "lucide-react";
import React from "react";
import { Button, Col, Row, Dropdown } from "react-bootstrap";
import DropdownToggle from "../../../shared-components/DropdownToggle";
import { SmallTextMutedParagraph } from "../../../shared-components/MergeText";
import SpinnerButton from "../../../shared-components/SpinnerButton";
import { OrganizationBillingPlan, PaymentMethod, isCreditCard } from "../BillingModels";
import { imageURLForCardBrand as imageURLForPaymentMethod, toTitleCase } from "../BillingUtils";
import BillingAutoPayToggle from "./BillingAutoPayToggle";
import styled from "styled-components";
import { palette } from "../../../../styles/theme";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";

const StyledMoreHorizontal = styled(MoreHorizontal)`
  color: ${palette.slate};
`;

type Props = {
  paymentMethod: PaymentMethod;
  settingDefault: boolean;
  billingPlan: OrganizationBillingPlan | undefined;
  updateBillingPlan: (billingPlan: OrganizationBillingPlan) => void;
  onClickSetDefault: () => void;
  setPaymentMethodDeletionTarget: (paymentMethod: PaymentMethod) => void;
};

const PaymentMethodRow = ({
  paymentMethod,
  settingDefault,
  billingPlan,
  updateBillingPlan,
  onClickSetDefault,
  setPaymentMethodDeletionTarget,
}: Props) => {
  return (
    <div className="list-group-item">
      <Row className="row align-items-center">
        <Col className="col-auto">
          <img
            className="img-fluid"
            src={imageURLForPaymentMethod(paymentMethod)}
            alt="..."
            style={{ maxWidth: "38px" }}
          />
        </Col>
        <Col className="ml-n2">
          <DeprecatedH4 className="deprecated-mb-1">
            {isCreditCard(paymentMethod)
              ? toTitleCase(paymentMethod.brand) + " "
              : paymentMethod.bank_name + " account "}
            ending in {paymentMethod.last4}
          </DeprecatedH4>
          {isCreditCard(paymentMethod) && (
            <SmallTextMutedParagraph className="mb-0">
              Expires {paymentMethod.exp_month}/{paymentMethod.exp_year}
            </SmallTextMutedParagraph>
          )}
        </Col>
        <Col className="col-auto mr-n3" style={{ display: "flex", alignItems: "center" }}>
          {billingPlan && paymentMethod.is_default_method && (
            <>
              <BillingAutoPayToggle
                shouldAutoCharge={billingPlan.should_auto_charge}
                updateBillingPlan={updateBillingPlan}
              />
              <Button size="sm" variant="outline-secondary" disabled>
                Default
              </Button>
            </>
          )}
        </Col>
        <Col className="col-auto">
          {paymentMethod.is_default_method || (
            <SpinnerButton
              text="Make Default"
              onClick={() => onClickSetDefault()}
              isLoading={settingDefault}
              className="btn-outline-secondary btn-sm"
            />
          )}
        </Col>
        {!paymentMethod.is_default_method && (
          <Col className="col-auto">
            <Dropdown>
              <DropdownToggle
                variant="white"
                className="deprecated-mr-3"
                size="sm"
                id="dropdown-custom-components"
              >
                <StyledMoreHorizontal size={16} />
              </DropdownToggle>
              <Dropdown.Menu align="right" className="dropdown-ellipses dropdown-toggle">
                <Dropdown.Item
                  className="ellipses-dropdown-item dropdown-item"
                  onSelect={() => setPaymentMethodDeletionTarget(paymentMethod)}
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default PaymentMethodRow;
