import React from "react";
import RestrictedWebhookOptionWrapper from "../../RestrictedWebhookOptionWrapper";
import { SelectedWebhookType } from "../../../enums";
import { UserCheck } from "lucide-react";
import WebhookSelectCard from "./WebhookSelectCard";

interface LinkedAccountSyncedWebhookProps {
  onSelectedWebhookTypeChange: (selectedWebhookType: SelectedWebhookType) => void;
  selectedWebhookType: SelectedWebhookType | undefined;
}

function LinkedAccountSyncedWebhook(props: LinkedAccountSyncedWebhookProps) {
  const { selectedWebhookType } = props;

  return (
    <RestrictedWebhookOptionWrapper>
      <WebhookSelectCard
        title="Linked Account synced"
        description="Receive an alert when all Common Models on any Linked Account are done syncing."
        icon={<UserCheck size={16} />}
        selected={false}
        selectedWebhookType={selectedWebhookType}
        comingSoon={true}
      />
    </RestrictedWebhookOptionWrapper>
  );
}

export default LinkedAccountSyncedWebhook;
