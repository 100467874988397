import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  LINKED_ACCOUNT_DETAIL_ROUTE,
  PROD_LINKED_ACCOUNTS_PATH,
  TEST_LINKED_ACCOUNTS_PATH,
} from "../../../router/RouterUtils";
import LinkedAccountDetailPage from "./detail-page/LinkedAccountDetailPage";
import LinkedAccountsPage from "./LinkedAccountsPage";
import TestLinkedAccountsTab from "./TestLinkedAccountsTab";

/**
 * Either shows a detail page for an individual linked account, the linked
 * accounts page for prod accounts, or the Linked Accounts page for test
 * accounts.
 */
const LinkedAccountsTab = () => (
  <Switch>
    <Route path={LINKED_ACCOUNT_DETAIL_ROUTE} component={LinkedAccountDetailPage} />

    <Route exact path={PROD_LINKED_ACCOUNTS_PATH} component={LinkedAccountsPage} />

    <Route path={TEST_LINKED_ACCOUNTS_PATH} component={TestLinkedAccountsTab} />

    <Redirect to={PROD_LINKED_ACCOUNTS_PATH} />
  </Switch>
);

export default LinkedAccountsTab;
