import React from "react";
import { Button, Row } from "react-bootstrap";
import styled from "styled-components";
import { ChevronRight } from "lucide-react";
import DeprecatedH5 from "../../../../deprecated/DeprecatedH5";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";

export interface TileWithImageProps {
  img: React.ReactElement;
  title: string;
  subtitle?: JSX.Element;
  buttonName: string;
  docsURL: string;
}

//used for the header in each of the individual step sections
const TitleText = styled(DeprecatedH5)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111317;
  padding: 16px 20px;
  margin-bottom: 0px;
`;

const SubTitleText = styled(DeprecatedH6)`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #080808;
  padding: 0px 20px;
  margin-bottom: 0px;
`;

// used for the primary description container
const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.08);
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Image = styled.i`
  margin-right: 12px;
`;

const StyledButton = styled(Button)`
  gap: 2px;
  background: #075ff7;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 125px;
  height: 34px;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  padding: 6px 10px 6px 16px;
  margin-top: 12px;
  margin-right: 20px;
  margin-bottom: 20px;
  && {
    &:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
      background: #075ff7;
      border: #075ff7;
    }
  }
`;

const FinalRowContainer = styled(Row)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 0px;
  margin-left: 0px;
`;

const StyledChevron = styled(ChevronRight)`
  margin-top: 1px;
`;

const SelectOneStep = ({ img, title, subtitle, buttonName, docsURL }: TileWithImageProps) => (
  <Container>
    <TitleText>
      <Image>{img}</Image>
      {title}
    </TitleText>
    <SubTitleText>{subtitle}</SubTitleText>
    <FinalRowContainer>
      <StyledButton onClick={() => window.open(docsURL, "_blank")}>
        {buttonName} <StyledChevron size={12} />
      </StyledButton>
    </FinalRowContainer>
  </Container>
);

export default SelectOneStep;
