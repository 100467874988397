import classNames from "classnames";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import styled from "styled-components";
import { fetchWithAuth } from "../../../../api-client/APIClient";
import { CommonModelSyncStatus, LinkedAccount } from "../../../../models/Entities";
import useAppContext from "../../../context/useAppContext";
import AccountTypeBadge from "../../../integrations-management/linked-accounts/detail-page/AccountTypeBadge";
import SyncHistoryTable from "../../../integrations-management/linked-accounts/detail-page/overview/SyncHistoryTable";
import Stack from "../../../integrations-management/linked-accounts/shared/Stack";
import useInterval from "../../../shared-components/hooks/useInterval";
import useTimeout from "../../../shared-components/hooks/useTimeout";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";
import OnboardingDropdown, {
  GenericDropdownContainer,
  StyledBadge,
} from "../../utils/OnboardingDropdown";
import { PaddingLessCol } from "../helpers/LeftSideBarSpacing";
import SampleSyncHistoryTable from "./SampleSyncHistoryTable";
import DeprecatedH4 from "../../../../deprecated/DeprecatedH4";
import DeprecatedH6 from "../../../../deprecated/DeprecatedH6";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";

export interface Props {
  linkedAccount: LinkedAccount | null;
  linkedAccounts: LinkedAccount[] | null;
  setLinkedAccount: Dispatch<SetStateAction<LinkedAccount | null>>;
}

const StyledH6 = styled(DeprecatedH6)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #111317;
`;

const StyledCategoryText = styled(DeprecatedH4)`
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #c8cfda;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const StyledCategoryImage = styled.img`
  border-radius: 50%;
`;

const TruncateText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 260px;
`;

const TruncateTextResize = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(90%);
`;

const TitleTruncationDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
`;
const LargeScreenViewCol = styled(PaddingLessCol)`
  @media (max-width: 1012px) {
    display: none;
  }
`;
const LargeScreenViewDiv = styled.div`
  @media (max-width: 1012px) {
    display: none;
  }
`;
const MobileResponsiveView = styled(PaddingLessCol)`
  display: none;
  @media (max-width: 1012px) {
    display: flex;
    flex-direction: column;
  }
`;

const MobileResponsiveSpacing = styled.div`
  margin-top: 24px;
`;

/**
 * Shows a header + resync button for sync history. Resync just shortcircuits the
 * interval to fetch data now.
 */
const SyncHistoryView = ({ linkedAccount, linkedAccounts, setLinkedAccount }: Props) => {
  const { user } = useAppContext();
  const [syncStatuses, setSyncStatuses] = useState<CommonModelSyncStatus[] | null>(null);
  const [isResyncing, setIsResyncing] = useState(false);
  const [isResyncingVisuallyOnly, setIsResyncingVisuallyOnly] = useState(false);

  /**
   * For the account id, fetch sync status with support for cancellation.
   */

  useEffect(() => {
    if (linkedAccount) {
      fetchSyncStatusForSelectedLinkedAccount();
    }
  }, [linkedAccount]);

  /*
   ** Specifically uses a query parameter to ignore if demo data has been turned on
   */

  const fetchSyncStatusForSelectedLinkedAccount = () => {
    let isCancelled = false;
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount?.id}/sync-status?ignore_demo_data=true`,
      method: "GET",
      onResponse: (data: CommonModelSyncStatus[]) => {
        if (isCancelled) {
          return;
        }
        setSyncStatuses(data);
      },
    });
    return () => {
      isCancelled = true;
    };
  };

  // Forces a resync of the data
  const forceResync = () => {
    if (!linkedAccount || !linkedAccount.completed_at) {
      setIsResyncing(false);
      return;
    }
    let isCancelled = false;
    fetchWithAuth({
      path: `/integrations/linked-accounts/${linkedAccount.id}/force-resync`,
      method: "POST",
      onResponse: (data: CommonModelSyncStatus[]) => {
        if (isCancelled) {
          return;
        }
        setIsResyncing(false);
        setSyncStatuses(data);
        showSuccessToast(
          `Resync queued for ${linkedAccount.end_user.organization_name} integration with ${linkedAccount.integration.name}.`,
        );

        if (user.is_demo) {
          setTimeout(() => {
            fetchSyncStatusForSelectedLinkedAccount();
          }, 2500);
        }
      },
      onError: () => {
        if (isCancelled) {
          return;
        }
        setIsResyncing(false);
        showErrorToast("Failed to force resync. Please try again.");
      },
    });
    return () => {
      isCancelled = true;
    };
  };

  // Polls for new sync statuses every 3 seconds if non-demo user. Needs to be inside an anon function
  useInterval(
    () => {
      if (syncStatuses != null) {
        fetchSyncStatusForSelectedLinkedAccount();
      }
    },
    user.is_demo ? null : 3000,
  );

  /**
   * This is funny - on a fast network, the resync is so fast there's no visual feedback,
   * so we use this to keep it spinning for just under a second for the user to see
   * one rotation of the animation when they click resync all. There's a visually
   * only state, and an actually syncing state to keep track of both.
   */
  useTimeout(
    () => {
      setIsResyncingVisuallyOnly(false);
    },
    isResyncingVisuallyOnly ? 990 : null,
  );
  // Fetches data on demand - the button spins at least for a second, or until the network call is finished
  const resyncButton = (
    <SmallWhiteButton
      disabled={!linkedAccount || !linkedAccount.completed_at}
      onClick={() => {
        setIsResyncing(true);
        setIsResyncingVisuallyOnly(true);
        forceResync();
      }}
      leftIcon={
        <RefreshCw
          className={classNames("m-0 p-0", { rotating: isResyncingVisuallyOnly || isResyncing })}
          size={12}
        />
      }
    >
      Resync all
    </SmallWhiteButton>
  );

  return (
    <Stack $isVertical>
      <StyledH6>Test Linked Account</StyledH6>
      <MobileResponsiveView>
        {!linkedAccount && (
          <OnboardingDropdown title="Select..." disabled>
            <Dropdown.Item>Select</Dropdown.Item>
          </OnboardingDropdown>
        )}
        {linkedAccounts && linkedAccount && (
          <GenericDropdownContainer>
            <DropdownButton
              disabled={false}
              variant="default"
              title={
                <TitleTruncationDiv className="d-flex flex-row align-items-center">
                  <StyledCategoryImage
                    height={25}
                    width={25}
                    src={linkedAccount.integration.square_image}
                    className="deprecated-mr-2"
                  />
                  <TruncateTextResize>
                    {linkedAccount.end_user.organization_name}
                  </TruncateTextResize>
                  <StyledBadge className="deprecated-ml-2 deprecated-mb-2">
                    <AccountTypeBadge account={linkedAccount} />
                  </StyledBadge>
                </TitleTruncationDiv>
              }
            >
              {linkedAccounts.map((linkedAccount) => (
                <Dropdown.Item
                  key={linkedAccount.id}
                  onClick={() => {
                    setLinkedAccount(linkedAccount);
                  }}
                >
                  <TitleTruncationDiv className="d-flex flex-row align-items-center">
                    <StyledCategoryImage
                      height={25}
                      width={25}
                      src={linkedAccount.integration.square_image}
                      className="deprecated-mr-2"
                    />
                    <TruncateTextResize>
                      {linkedAccount.end_user.organization_name}
                    </TruncateTextResize>
                    <StyledBadge className="deprecated-ml-2 deprecated-mb-2">
                      <AccountTypeBadge account={linkedAccount} />
                    </StyledBadge>
                  </TitleTruncationDiv>
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </GenericDropdownContainer>
        )}
        <MobileResponsiveSpacing className="d-flex flex-row justify-content-between align-items-center mb-0">
          {linkedAccount && <StyledCategoryText>{linkedAccount.category}</StyledCategoryText>}
          {!linkedAccount && (
            <StyledCategoryText className="deprecated-ml-3">ATS</StyledCategoryText>
          )}
          {resyncButton}
        </MobileResponsiveSpacing>
      </MobileResponsiveView>
      <LargeScreenViewDiv className="d-flex flex-row justify-content-between align-items-center mb-0">
        <LargeScreenViewCol xs={7} className="p-0">
          <div className="d-flex flex-row justify-content-start align-items-center mb-0">
            {linkedAccounts && linkedAccount && (
              <OnboardingDropdown
                disabled={false}
                title={
                  <div className="d-flex flex-row align-items-center">
                    <StyledCategoryImage
                      height={25}
                      width={25}
                      src={linkedAccount.integration.square_image}
                      className="deprecated-mr-2"
                    />
                    <TruncateText>{linkedAccount.end_user.organization_name}</TruncateText>
                    <StyledBadge className="deprecated-ml-2 deprecated-mb-2">
                      <AccountTypeBadge account={linkedAccount} />
                    </StyledBadge>
                  </div>
                }
              >
                {linkedAccounts.map((linkedAccount) => (
                  <Dropdown.Item
                    key={linkedAccount.id}
                    onClick={() => {
                      setLinkedAccount(linkedAccount);
                    }}
                  >
                    <div className="d-flex flex-row align-items-center">
                      <StyledCategoryImage
                        height={25}
                        width={25}
                        src={linkedAccount.integration.square_image}
                        className="deprecated-mr-2"
                      />
                      {linkedAccount.end_user.organization_name}
                      <StyledBadge className="deprecated-ml-2 deprecated-mb-2">
                        <AccountTypeBadge account={linkedAccount} />
                      </StyledBadge>
                    </div>
                  </Dropdown.Item>
                ))}
              </OnboardingDropdown>
            )}
            {linkedAccount && (
              <StyledCategoryText className="deprecated-ml-3">
                {linkedAccount.category}
              </StyledCategoryText>
            )}
            {!linkedAccount && (
              <OnboardingDropdown title="Select..." disabled>
                <Dropdown.Item>Select</Dropdown.Item>
              </OnboardingDropdown>
            )}

            {!linkedAccount && (
              <StyledCategoryText className="deprecated-ml-3">ATS</StyledCategoryText>
            )}
          </div>
        </LargeScreenViewCol>
        <LargeScreenViewDiv>{resyncButton}</LargeScreenViewDiv>
      </LargeScreenViewDiv>
      <div className="deprecated-mt-4">
        {linkedAccount && (
          <SyncHistoryTable
            linkedAccount={linkedAccount}
            syncStatuses={syncStatuses}
            ignoreDemoData
          />
        )}
        {!linkedAccount && <SampleSyncHistoryTable />}
      </div>
    </Stack>
  );
};

export default SyncHistoryView;
