import React from "react";
import { Row, Col } from "react-bootstrap";
import { INVOICE_FE_DISABLED_STATUSES } from "../../models/Entities";
import {
  BILLING_PATH,
  NOTIFICATIONS_SETTINGS_PATH,
  ORGANIZATION_PATH,
  PROFILE_PATH,
  SECURITY_PATH,
  REFERRALS_PATH,
  AUDIT_TRAIL_PATH,
} from "../../router/RouterUtils";
import useAppContext from "../context/useAppContext";
import HeaderBar from "../portal/HeaderBar";
import { OrganizationInviteAlert } from "../shared-components/MergeAlerts";

type Props = { title?: string; children: JSX.Element | JSX.Element[] };

function SettingsPage(props: Props) {
  // hooks
  const { user } = useAppContext();

  const dashboardDisabled =
    user.organization.invoice_overdue_status &&
    INVOICE_FE_DISABLED_STATUSES.includes(user.organization.invoice_overdue_status);

  return (
    <Row className="justify-content-center">
      <Col className="col-12 col-lg-10 col-xl-8">
        <OrganizationInviteAlert />
        <HeaderBar
          title={props.title ?? "Settings"}
          subtabs={[
            {
              label: "Profile",
              destination: PROFILE_PATH,
              icon: <span className="fe fe-user mr-2" />,
              disabled: dashboardDisabled,
            },
            {
              label: "Security",
              destination: SECURITY_PATH,
              icon: <span className="fe fe-lock mr-2" />,
              disabled: dashboardDisabled,
            },
            {
              label: "Organization",
              destination: ORGANIZATION_PATH,
              icon: <span className="fe fe-database mr-2" />,
              disabled: dashboardDisabled,
            },
            {
              label: "Audit Trail",
              destination: AUDIT_TRAIL_PATH,
              icon: <span className="fe fe-file-text mr-2" />,
              disabled: dashboardDisabled,
            },
            {
              label: "Billing",
              destination: BILLING_PATH,
              icon: <span className="fe fe-dollar-sign mr-2" />,
            },
            {
              label: "Notifications",
              destination: NOTIFICATIONS_SETTINGS_PATH,
              icon: <span className="fe fe-bell mr-2" />,
              disabled: dashboardDisabled,
            },
            {
              label: "Referrals",
              destination: REFERRALS_PATH,
              icon: <span className="fe fe-user-plus mr-2" />,
              disabled: dashboardDisabled,
            },
          ]}
        />
        <Row>
          <Col>{props.children}</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default SettingsPage;
