import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Card, Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { match, Route, useHistory } from "react-router-dom";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { LOG_DIRECTIONS } from "../../../constants";
import { getCursorPath } from "../../../services";
import { spectrum } from "../../../styles/theme";
import IntegrationNameAndLogo from "../../configuration/integrations/IntegrationNameAndLogo";
import useAppContext from "../../context/useAppContext";
import ClickableContainer from "../../shared-components/ClickableContainer";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import { DemoDataAlert } from "../../shared-components/MergeAlerts";
import { ResponseCodeBadge, StatusBadge } from "../../shared-components/MergeBadges";
import PaginationFooter from "../../shared-components/PaginationFooter";
import { APIRequestLogEntry } from "../IntegrationsManagementEntities";
import IntegrationsManagementFilter from "../shared/IntegrationsManagementFilter";
import { fetchLogEntryByID } from "../utils/IntegrationsManagementAPIClient";
import APIRequestLogEntrySidePanel from "./side-panel/APIRequestLogSidePanel";

interface Props {
  match: match;
}

const IntegrationsManagementLogsPage = ({ match }: Props) => {
  const history = useHistory();
  const { user, isUserPrivileged } = useAppContext();
  const [logs, setLogs] = useState<Array<APIRequestLogEntry> | null>();
  const [previousPageURL, setPreviousPageURL] = useState<string>();
  const [nextPageURL, setNextPageURL] = useState<string>();
  const [paramsPath, setParamsPath] = useState<string | undefined>();

  //@ts-ignore
  useEffect(() => {
    let cancelled = false;
    setLogs(null);
    fetchWithAuth({
      path: "/logs/?" + (paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        if (!cancelled) {
          setNextPageURL(data.next);
          setPreviousPageURL(data.previous);
          setLogs(data.results);
        }
      },
    });

    return () => (cancelled = true);
  }, [paramsPath, user.is_demo]);

  function fetchLogsWithCursor(cursorPath?: string | undefined) {
    setLogs(null);
    fetchWithAuth({
      path: "/logs/?" + (cursorPath ? getCursorPath(cursorPath) : paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        setNextPageURL(data.next);
        setPreviousPageURL(data.previous);
        setLogs(data.results);
      },
    });
  }

  function navigateToLogEntrySidePanel(logID: string) {
    history.push(`${match.url}/${logID}`, { from: window.location.pathname });
  }

  return (
    <>
      <Row>
        <Col>
          <DemoDataAlert />
          <IntegrationsManagementFilter setParamsPath={setParamsPath} isIntegrationLogs />
          <Row>
            <Col>
              <Card>
                <div className="table-responsive rounded-table-corner">
                  <Table size="sm" hover className="table-nowrap mb-0">
                    <thead className="table-borderless">
                      <tr>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                        <th scope="col">Integration</th>
                        <th scope="col">Organization</th>
                        <th scope="col">URL</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {isUserPrivileged ? (
                        logs ? (
                          logs.length > 0 ? (
                            logs.map((log) => (
                              <tr
                                className="table-link"
                                key={log.id}
                                onClick={() => navigateToLogEntrySidePanel(log.id)}
                              >
                                <td>
                                  {user.is_demo && (
                                    <StatusBadge status="Demo" className="deprecated-mr-3" />
                                  )}
                                  <ResponseCodeBadge responseCode={log.response_code} />
                                </td>

                                <td>
                                  <b>{format(new Date(log.created_at), "MMM dd HH:mm:ss")}</b>
                                </td>
                                <td className="text-gray-50">
                                  {log.linked_account?.integration && (
                                    <div className="d-flex align-items-center">
                                      <IntegrationNameAndLogo
                                        integration={log.linked_account.integration}
                                      />
                                    </div>
                                  )}
                                </td>
                                <td className="text-gray-50">
                                  <div className="d-flex align-items-center">
                                    {log.linked_account?.end_user?.organization_name}
                                  </div>
                                </td>

                                <td style={{ width: "500px" }}>
                                  {log.url && (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={<Tooltip id="tooltip-top">{log.url}</Tooltip>}
                                    >
                                      <span
                                        title={log.url}
                                        className="text-truncate text-gray-50 d-inline-block"
                                        style={{ width: "500px", marginTop: "5px" }}
                                      >
                                        <span
                                          className={`fe fe-log-${
                                            log.direction == LOG_DIRECTIONS.LOG_INBOUND
                                              ? "in"
                                              : "out"
                                          } deprecated-mr-2`}
                                        />
                                        {log.url}
                                      </span>
                                    </OverlayTrigger>
                                  )}
                                </td>
                                <td className="text-right">
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-top">Review log details</Tooltip>}
                                  >
                                    <ClickableContainer>
                                      <span className="fe fe-chevron-right text-gray-50" />
                                    </ClickableContainer>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="p-0">
                                <EmptyStateWrapper isTable title="No logs" />
                              </td>
                            </tr>
                          )
                        ) : (
                          Array.from({ length: 25 }).map((_, i) => (
                            <tr key={`log-skel-row-${i}`}>
                              <td>
                                <ContentLoader
                                  speed={1.4}
                                  width={40}
                                  height={20}
                                  viewBox="0 0 40 20"
                                  backgroundColor={spectrum.gray0}
                                  foregroundColor={spectrum.gray10}
                                >
                                  <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
                                </ContentLoader>
                              </td>
                              <td>
                                <ContentLoader
                                  speed={1.4}
                                  width={64}
                                  height={20}
                                  viewBox="0 0 64 20"
                                  backgroundColor={spectrum.gray0}
                                  foregroundColor={spectrum.gray10}
                                >
                                  <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
                                </ContentLoader>
                              </td>
                              <td className="text-gray-50">
                                <div className="d-flex align-items-center">
                                  <i className="fe fe-link-2 deprecated-mr-3" />
                                  <ContentLoader
                                    speed={1.4}
                                    width={80}
                                    height={20}
                                    viewBox="0 0 80 20"
                                    backgroundColor={spectrum.gray0}
                                    foregroundColor={spectrum.gray10}
                                  >
                                    <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                                  </ContentLoader>
                                </div>
                              </td>
                              <td className="text-gray-50">
                                <ContentLoader
                                  speed={1.4}
                                  width={64}
                                  height={20}
                                  viewBox="0 0 64 20"
                                  backgroundColor={spectrum.gray0}
                                  foregroundColor={spectrum.gray10}
                                >
                                  <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
                                </ContentLoader>
                              </td>
                              <td style={{ width: "500px" }}>
                                <ContentLoader
                                  speed={1.4}
                                  width={240}
                                  height={20}
                                  viewBox="0 0 240 20"
                                  backgroundColor={spectrum.gray0}
                                  foregroundColor={spectrum.gray10}
                                >
                                  <rect x="0" y="3" rx="3" ry="3" width="240" height="14" />
                                </ContentLoader>
                              </td>
                              <td />
                            </tr>
                          ))
                        )
                      ) : (
                        <tr>
                          <td colSpan={7} className="deprecated-py-5">
                            <EmptyStateWrapper
                              isTable
                              title="You must be an admin or developer to view Logs"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
          <PaginationFooter
            hasPrevious={!!previousPageURL}
            hasNext={!!nextPageURL}
            onPreviousClick={() => fetchLogsWithCursor(previousPageURL)}
            onNextClick={() => fetchLogsWithCursor(nextPageURL)}
          />
        </Col>
      </Row>
      <Route
        exact
        path={`${match.url}/:logID`}
        render={() => <APIRequestLogEntrySidePanel fetchAPIRequestLog={fetchLogEntryByID} />}
      />
    </>
  );
};

export default IntegrationsManagementLogsPage;
