import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import FieldMappingTargetPageContainer from "./FieldMappingTargetPageContainer";
import {
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_PATH,
  CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
  CONFIGURATION_FIELD_MAPPINGS_COMMON_MODEL_OVERRIDES_PATH,
  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH,
  openInNewTab,
  DOCS_FIELD_MAPPINGS_PATH,
} from "../../../../router/RouterUtils";
import useAppContext from "../../../context/useAppContext";
import SubLeftNavigationContainer from "../../../portal/SubLeftNavigationContainer";
import ConfigurationFieldMappingsLinkedAccountTable from "./ConfigurationFieldMappingLinkedAccountTable";
import useCategoryComponentSchema from "../../../../hooks/useCategoryComponentSchema";
import {
  APICategory,
  FieldMappingCommonModelConfiguration,
  FieldMappingTarget,
  OverriddenCommonModelTarget,
  OverrideModelAndFieldOptions,
} from "../../../../models/Entities";
import INTEGRATION_CATEGORY_LIST from "../../../../models/Helpers";
import { BookOpen } from "lucide-react";
import { getFieldMappingConfigurations, getFieldMappingTargets } from "./utils/FieldMappingUtils";

import ConfigurationFieldMappingsTargetFieldsPage from "./ConfigurationFieldMappingsTargetFieldsPage";
import { SmallWhiteButton } from "@merge-api/merge-javascript-shared";
import FieldMappingUpsellModal from "./ConfigurationFieldMappingsUpsellModal";
import useProductRestrictions from "../../../shared-components/hooks/useProductRestrictions";
import {
  getAllOverrideModelAndFieldOptionsForCategory,
  getAllOrganizationCommonModelOverrideTargetsForCategory,
} from "./utils/FieldMappingUtils";
import ConfigurationCommonModelOverridesSettings from "./ConfigurationCommonModelOverrideSettings";

const MODAL_TOP_VALUE = "40px";
export const FieldMappingsDocsButton = (
  <SmallWhiteButton
    onClick={() => openInNewTab(DOCS_FIELD_MAPPINGS_PATH)}
    leftIcon={<BookOpen size={12} />}
  >
    Field Mapping Docs
  </SmallWhiteButton>
);

const ConfigurationFieldMappingsPageContainer = () => {
  const { user, isUserPrivileged } = useAppContext();
  const isOverrideCommonModelsEnabled = user?.is_override_common_models_enabled || false;
  const [selectedCategory, setSelectedCategory] = useState<APICategory>(
    INTEGRATION_CATEGORY_LIST[0],
  );

  const [fieldMappingTargets, setFieldMappingTargets] = useState<
    FieldMappingTarget[] | undefined
  >();
  const [fieldMappingConfigurations, setFieldMappingConfigurations] = useState<
    FieldMappingCommonModelConfiguration[]
  >([]);
  const [commonModelNames, setCommonModelNames] = useState<Array<string>>([]);
  const [hasError, setHasError] = useState(false);

  const [isFetchingFieldMappingTargets, setIsFetchingCustomMappingTargets] =
    useState<boolean>(false);
  const [isFetchingFieldMappingConfigurations, setIsFetchingFieldMappingConfigurations] =
    useState<boolean>(false);

  const [isFetchingAvailableCommonModelOverrides, setIsFetchingAvailableCommonModelOverrides] =
    useState<boolean>(false);

  const [commonModelOverrideOptions, setCommonModelOverrides] = useState<
    OverrideModelAndFieldOptions | undefined
  >(undefined);
  const [isFetchingCommonModelOverrideTargets, setIsFetchingCommonModelOverrideTargets] =
    useState<boolean>(false);
  const [commonModelOverrideTargets, setCommonModelOverrideTargets] = useState<
    OverriddenCommonModelTarget[] | undefined
  >(undefined);
  const [fetchCounter, setFetchCounter] = useState<number>(0);

  const [isLoading, setIsLoading] = useState(false);

  const { schemas } = useCategoryComponentSchema(selectedCategory);

  const refreshFieldMappingsAndConfigurations = () => {
    setFetchCounter(fetchCounter + 1);
  };

  const updateFieldMappingConfigurations = (
    updatedConfiguration: FieldMappingCommonModelConfiguration,
  ) => {
    const filteredConfigs = fieldMappingConfigurations.filter(
      (config) => config.id != updatedConfiguration.id,
    );

    setFieldMappingConfigurations([...filteredConfigs, updatedConfiguration]);
  };

  const updateFieldMappingTargets = (updatedFieldMappingTarget: FieldMappingTarget) => {
    const fieldFieldMappingTargets = fieldMappingTargets!.filter(
      (target) => target.id != updatedFieldMappingTarget.id,
    );
    setFieldMappingTargets([...fieldFieldMappingTargets, updatedFieldMappingTarget]);
  };

  const getModelNamesFromConfigs = (
    models: Array<FieldMappingCommonModelConfiguration>,
  ): Array<string> =>
    models
      .sort((a, b) => a.common_model_name.localeCompare(b.common_model_name))
      .reduce((accum: string[], model) => [...accum, model.common_model_name], []);

  useEffect(() => {
    setIsLoading(
      isFetchingFieldMappingTargets ||
        isFetchingFieldMappingConfigurations ||
        isFetchingAvailableCommonModelOverrides ||
        isFetchingCommonModelOverrideTargets,
    );
  }, [
    isFetchingFieldMappingTargets,
    isFetchingFieldMappingConfigurations,
    isFetchingAvailableCommonModelOverrides,
    isFetchingCommonModelOverrideTargets,
  ]);

  useEffect(() => {
    // Use a boolean indicator for each API Call so we know when we have finished fetching all data
    setIsFetchingCustomMappingTargets(true);
    setIsFetchingFieldMappingConfigurations(true);
    setIsFetchingAvailableCommonModelOverrides(true);
    setIsFetchingCommonModelOverrideTargets(true);
    getFieldMappingTargets({
      category: selectedCategory,
      onFetch: (response: FieldMappingTarget[]) => {
        setFieldMappingTargets(response);
        setIsFetchingCustomMappingTargets(false);
      },
      onError: () => {
        setHasError(true);
      },
    });

    getFieldMappingConfigurations({
      category: selectedCategory,
      onFetch: (response: FieldMappingCommonModelConfiguration[]) => {
        setFieldMappingConfigurations(response);
        setCommonModelNames(getModelNamesFromConfigs(response));
        setIsFetchingFieldMappingConfigurations(false);
      },
      onError: () => {
        setHasError(true);
      },
    });
    getAllOverrideModelAndFieldOptionsForCategory(
      selectedCategory,
      (data) => {
        setCommonModelOverrides(data);
        setIsFetchingAvailableCommonModelOverrides(false);
      },
      () => {
        setHasError(true);
      },
    );

    getAllOrganizationCommonModelOverrideTargetsForCategory(
      selectedCategory,
      (data) => {
        setCommonModelOverrideTargets(data);
        setIsFetchingCommonModelOverrideTargets(false);
      },
      () => {
        setHasError(true);
      },
    );
  }, [fetchCounter, selectedCategory]);

  const { productRestrictions } = useProductRestrictions();
  const [showUpsellModal, setShowUpsellModal] = useState(
    !productRestrictions?.are_custom_fields_enabled,
  );

  return (
    <>
      {!productRestrictions?.are_custom_fields_enabled && (
        <FieldMappingUpsellModal
          showUpsellModal={showUpsellModal}
          setShowUpsellModal={setShowUpsellModal}
          modalTopValue={MODAL_TOP_VALUE}
        />
      )}
      <div className={showUpsellModal ? "opacity-50" : "opacity-100"}>
        <Switch>
          {fieldMappingTargets && (
            <Route
              path={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_PATH}
              render={() => (
                <FieldMappingTargetPageContainer
                  fieldMappingsData={fieldMappingTargets}
                  refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
                />
              )}
            />
          )}
          <SubLeftNavigationContainer
            subtabs={[
              {
                label: "Target Fields",
                destination: CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH,
              },
              ...(isUserPrivileged
                ? [
                    {
                      label: "Linked Accounts",
                      destination: CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH,
                    },
                  ]
                : []),
            ]}
          >
            <Switch>
              <Route
                exact
                path={CONFIGURATION_FIELD_MAPPINGS_LINKED_ACCOUNTS_PATH}
                component={ConfigurationFieldMappingsLinkedAccountTable}
              />
              {isOverrideCommonModelsEnabled && (
                <Route
                  exact
                  path={CONFIGURATION_FIELD_MAPPINGS_COMMON_MODEL_OVERRIDES_PATH}
                  render={() => {
                    return (
                      <ConfigurationCommonModelOverridesSettings
                        refreshFieldMappingsAndConfigurations={
                          refreshFieldMappingsAndConfigurations
                        }
                      />
                    );
                  }}
                />
              )}
              <Route
                path={
                  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH ||
                  CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_ID_SETTINGS_PATH
                }
                render={() => {
                  return (
                    <ConfigurationFieldMappingsTargetFieldsPage
                      schemas={schemas}
                      commonModelOverrideTargets={commonModelOverrideTargets}
                      commonModelOverrideOptions={commonModelOverrideOptions}
                      setCommonModelOverrideTargets={setCommonModelOverrideTargets}
                      fieldMappingTargets={fieldMappingTargets}
                      fieldMappingConfigurations={fieldMappingConfigurations}
                      selectedCategory={selectedCategory}
                      setSelectedCategory={setSelectedCategory}
                      refreshFieldMappingsAndConfigurations={refreshFieldMappingsAndConfigurations}
                      commonModelNames={commonModelNames}
                      updateFieldMappingTargets={updateFieldMappingTargets}
                      updateFieldMappingConfigurations={updateFieldMappingConfigurations}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      hasError={hasError}
                      setHasError={setHasError}
                    />
                  );
                }}
              />
              <Redirect to={CONFIGURATION_FIELD_MAPPINGS_TARGET_FIELDS_PATH} />
            </Switch>
          </SubLeftNavigationContainer>
        </Switch>
      </div>
    </>
  );
};

export default ConfigurationFieldMappingsPageContainer;
