import React, { SetStateAction, Dispatch } from "react";
import { Col, Row } from "react-bootstrap";
import SyncHistory from "./SyncHistory";
import ConfigurationWidget from "./ConfigurationWidget";
import OverviewColumn from "./OverviewColumn";
import { LinkedAccount, LinkedAccountWebhookReceivers } from "../../../../../models/Entities";
import useAppContext from "../../../../context/useAppContext";
import useProductRestrictions from "../../../../shared-components/hooks/useProductRestrictions";
import IntegrationIssueWidget from "./IntegrationIssueWidget";
import { ISSUE_SYSTEM_V2 } from "../../../../../constants";
import { IntegrationIssue } from "../../../IntegrationsManagementEntities";
import styled from "styled-components";

// Prevents the page from wrapping too early, by setting the flex-basis to 0 and instead using flex-grow and flex-shrink to allocate space for each column.
const ModelsCol = styled(Col)`
  flex: 3;
`;

// Set a min-width so the card does not wrap.
const ThinCol = styled(Col)`
  min-width: 280px;
  flex: 1;
`;

// Places the configuration widget and overview column into the same column, so they stack when they wrap
const RightCol = styled(Col)`
  flex: 2;
`;

type OverviewPageProps = {
  id: string;
  linkedAccount: LinkedAccount | null;
  webhookReceivers: LinkedAccountWebhookReceivers | null;
  ongoingIntegrationIssue: IntegrationIssue | null;
  setOngoingIntegrationIssue: Dispatch<SetStateAction<IntegrationIssue | null>>;
};

// COMMENTING OUT INTEGRATIONS ISSUE WIDGET CODE UNTIL PRODUCT APPROVED TO RELEASE THIS FEATURE
const OverviewPage = ({
  id,
  linkedAccount,
  webhookReceivers,
  ongoingIntegrationIssue,
  setOngoingIntegrationIssue,
}: OverviewPageProps) => {
  const { isUserPrivileged } = useAppContext();
  const { productRestrictions } = useProductRestrictions();

  const showFieldMappingsRow = productRestrictions?.are_custom_fields_enabled && isUserPrivileged;
  const showWebhooksRow = webhookReceivers && webhookReceivers.webhook_receivers.length > 0;
  const showRedactUnmappedDataRow =
    productRestrictions?.redact_unmapped_data_enabled && isUserPrivileged;

  const showWidget = showFieldMappingsRow || showWebhooksRow || showRedactUnmappedDataRow;

  const shouldShowIntegrationIssueWidget =
    ongoingIntegrationIssue !== null &&
    ongoingIntegrationIssue.has_user_seen == false &&
    ongoingIntegrationIssue.issue_generated_by == ISSUE_SYSTEM_V2;

  return (
    <Row>
      <ModelsCol xl={showWidget ? 7 : 9}>
        {shouldShowIntegrationIssueWidget && ongoingIntegrationIssue !== null && (
          <Row>
            <Col xl={12}>
              <IntegrationIssueWidget
                integrationIssue={ongoingIntegrationIssue}
                linkedAccount={linkedAccount}
                showPreview
                setIntegrationIssue={setOngoingIntegrationIssue}
              ></IntegrationIssueWidget>
            </Col>
          </Row>
        )}
        <Row className={shouldShowIntegrationIssueWidget ? "mt-6 mb-6" : "mt-0"}>
          <Col xl={12}>
            <SyncHistory id={id} linkedAccount={linkedAccount}></SyncHistory>
          </Col>
        </Row>
      </ModelsCol>
      <RightCol xl={showWidget ? 5 : 3}>
        <Row>
          {" "}
          {showWidget && (
            <ThinCol className="mt-0">
              <ConfigurationWidget
                linkedAccount={linkedAccount}
                webhookReceivers={webhookReceivers}
                showFieldMappingsRow={showFieldMappingsRow ?? false}
                showWebhooksRow={showWebhooksRow ?? false}
                showRedactUnmappedDataRow={showRedactUnmappedDataRow ?? false}
              ></ConfigurationWidget>
            </ThinCol>
          )}
          <ThinCol>
            <OverviewColumn linkedAccount={linkedAccount} />
          </ThinCol>
        </Row>
      </RightCol>
    </Row>
  );
};

export default OverviewPage;
