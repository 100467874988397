import React from "react";
import { LOG_DIRECTIONS } from "../../../../../../constants";
import { MergeMarkAvatar } from "../../../../../shared-components/MergeAvatars";
import { CodeBlockFileDownloadInfo } from "../../../../../shared-components/MergeCodeBlock";
import {
  isScraperLogEntry,
  isWebhookLogEntry,
  LogType,
  ScraperLogEntry,
} from "../../../../IntegrationsManagementEntities";
import { ScraperLogSidePanelResponseSection } from "../../ScraperLogEntrySidePanel";
import { CollapsableSection, FullWidthCard } from "./LogSidePanelBody";
import { LogSidePanelBodySection } from "./LogSidePanelBodySection";
import { LogSidePanelBodySectionHeader } from "./LogSidePanelBodySectionHeader";
import Divider from "../../../../../shared-components/Divider";

type Props = {
  logEntry: LogType;
  organizationBodyImage: JSX.Element;
  integrationImage: JSX.Element;
  hasDynamicResponseBody: boolean;
  responseBody: string | undefined;
  downloadInfo?: CodeBlockFileDownloadInfo;
};

export const LogSidePanelResponseSection = ({
  logEntry,
  organizationBodyImage,
  integrationImage,
  hasDynamicResponseBody,
  responseBody,
  downloadInfo,
}: Props) => {
  const isOutbound = logEntry.direction === LOG_DIRECTIONS.LOG_OUTBOUND;
  const isWebhookLog = isWebhookLogEntry(logEntry);
  const isScraperLog = isScraperLogEntry(logEntry);

  let responseImage = <MergeMarkAvatar />;
  if (isOutbound) {
    responseImage = isWebhookLog ? organizationBodyImage : integrationImage;
  }

  const shouldShowResponseHeaders =
    !isScraperLog && logEntry.response_headers && Object.keys(logEntry.response_headers).length > 0;

  const shouldHideBorderBottom =
    !isScraperLog &&
    !(logEntry.response_headers && (logEntry.response_body || hasDynamicResponseBody));

  return (
    <FullWidthCard>
      <LogSidePanelBodySectionHeader
        title="Response"
        subtitle={logEntry.url}
        image={responseImage}
        response_status={logEntry.response_code}
        hideBorderBottom={shouldHideBorderBottom}
      />

      <FullWidthCard.Body className="log-card-body">
        {isScraperLog ? (
          <>
            <CollapsableSection title="Response Parameters" numberOfElements={undefined}>
              <ScraperLogSidePanelResponseSection logEntry={logEntry as ScraperLogEntry} />
            </CollapsableSection>
            <LogSidePanelBodySection
              title="Body"
              data={responseBody}
              isBody
              downloadInfo={downloadInfo}
            />
          </>
        ) : (
          <>
            {shouldShowResponseHeaders && (
              <LogSidePanelBodySection
                title="Headers"
                data={logEntry.response_headers}
                isCollapsedByDefault
              />
            )}
            {logEntry.response_headers && (logEntry.response_body || hasDynamicResponseBody) && (
              <Divider noMargin />
            )}
            {(logEntry.response_body || hasDynamicResponseBody) && (
              <LogSidePanelBodySection
                title="Body"
                data={responseBody}
                isBody
                downloadInfo={downloadInfo}
              />
            )}
          </>
        )}
      </FullWidthCard.Body>
    </FullWidthCard>
  );
};
