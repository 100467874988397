import React from "react";
import {
  ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH,
  ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH,
  ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH,
  ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH,
  ONBOARDING_ADVANCED_SYNCING_DATA_PATH,
  ONBOARDING_ADVANCED_MERGE_WRITES_PATH,
  ONBOARDING_ADVANCED_WEBHOOKS_PATH,
  ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH,
  ONBOARDING_ADVANCED_METADATA_PATH,
  ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH,
  ONBOARDING_ADVANCED_SENSITIVE_DATA_PATH,
  ONBOARDING_ADVANCED_TROUBLESHOOTING_PATH,
} from "../../router/RouterUtils";
import AddLinkToAppSection from "./components/AddLinkToApp/AddLinkToAppSection";
import APIRequestSection from "./components/SendAPIRequest/APIRequestSection";
import DashboardOnboardingWrapper from "./DashboardOnboardingWrapper";
import IntegrateSyncedDataSection from "./components/IntegrateSyncedData/IntegrateSyncedDataSection";
import LinkedAccountSection from "./components/SyncSandBox/LinkedAccountSection";
import SyncingData from "./components/Onboarding/SyncingData";
import MergeWrites from "./components/Onboarding/MergeWrites";
import Webhooks from "./components/Onboarding/Webhooks";
import CustomFields from "./components/Onboarding/CustomFields";
import Metadata from "./components/Onboarding/Metadata";
import SupplementalData from "./components/Onboarding/SupplementalData";
import SensitiveData from "./components/Onboarding/SensitiveData";
import Troubleshooting from "./components/Onboarding/Troubleshooting";
import { Redirect, Route, Switch } from "react-router-dom";

const DashboardOnboarding = () => {
  return (
    <DashboardOnboardingWrapper>
      <Switch>
        <Route
          exact
          path={ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH}
          component={LinkedAccountSection}
        />
        <Route
          exact
          path={ONBOARDING_GET_STARTED_SEND_API_REQUEST_PATH}
          component={APIRequestSection}
        />
        <Route
          exact
          path={ONBOARDING_GET_STARTED_INTEGRATE_SYNCED_DATA_PATH}
          component={IntegrateSyncedDataSection}
        />
        <Route
          exact
          path={ONBOARDING_GET_STARTED_ADD_LINK_TO_APP_PATH}
          component={AddLinkToAppSection}
        />
        <Route exact path={ONBOARDING_ADVANCED_SYNCING_DATA_PATH} component={SyncingData} />
        <Route exact path={ONBOARDING_ADVANCED_MERGE_WRITES_PATH} component={MergeWrites} />
        <Route exact path={ONBOARDING_ADVANCED_WEBHOOKS_PATH} component={Webhooks} />
        <Route exact path={ONBOARDING_ADVANCED_CUSTOM_FIELDS_PATH} component={CustomFields} />
        <Route exact path={ONBOARDING_ADVANCED_METADATA_PATH} component={Metadata} />
        <Route
          exact
          path={ONBOARDING_ADVANCED_SUPPLEMENTAL_DATA_PATH}
          component={SupplementalData}
        />
        <Route exact path={ONBOARDING_ADVANCED_SENSITIVE_DATA_PATH} component={SensitiveData} />
        <Route exact path={ONBOARDING_ADVANCED_TROUBLESHOOTING_PATH} component={Troubleshooting} />
        <Redirect to={ONBOARDING_GET_STARTED_SYNC_SANDBOX_DATA_PATH} />
      </Switch>
    </DashboardOnboardingWrapper>
  );
};

export default DashboardOnboarding;
