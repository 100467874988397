import React from "react";
import {
  LINKED_ACCOUNTS_PATH,
  TEST_LINKED_ACCOUNTS_PATH,
} from "../../../../../../router/RouterUtils";
import { ChevronLeft } from "lucide-react";
import { LinkedAccount } from "../../../../../../models/Entities";
import { Link } from "react-router-dom";
import SkeletonLoader from "../../../../../shared-components/SkeletonLoader";
import { LinkedAccountStatuses } from "../../../../../../constants";
import { formatDate } from "../../../../../../models/Helpers";

interface PretitleProps {
  isTestAccount: string | false;
  linkedAccount: LinkedAccount | null;
}

const LinkedAccountPretitle: React.FC<PretitleProps> = ({ isTestAccount, linkedAccount }) => {
  return (
    <div className="text-[12px] flex w-full justify-between items-center">
      <Link
        className="font-medium"
        to={isTestAccount ? TEST_LINKED_ACCOUNTS_PATH : LINKED_ACCOUNTS_PATH}
      >
        <ChevronLeft size={14} className="mb-0.5" />
        {isTestAccount ? "Test Linked Accounts" : "Production Linked Accounts"}
      </Link>
      {linkedAccount ? (
        <div className="text-gray-50">
          {linkedAccount.completed_at
            ? `${
                linkedAccount.status == LinkedAccountStatuses.RELINK_NEEDED
                  ? "Last Linked"
                  : "Linked"
              }
        ${formatDate(linkedAccount!.completed_at)}`
            : linkedAccount?.status}
        </div>
      ) : (
        <SkeletonLoader width={193} height={12} borderRadius={4} />
      )}
    </div>
  );
};

export default LinkedAccountPretitle;
