import React, { useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { palette } from "../../../../styles/theme";
import ModalButton from "./ModalButton";
import type { ModalProps } from "./ModalProps";
import SkinnyModal from "./SkinnyModal";

export type Props = ModalProps & {
  /**
   * The existing search name
   */
  searchName: string;

  /**
   * Deletes search async, returning a success value
   */
  deleteSearch: () => Promise<boolean>;
};

const Error = styled(Form.Text)`
  color: ${palette.red};
`;

/**
 * Shows a modal for deleting a search
 */
const DeleteSearchModal = ({ isOpen, closeModal, deleteSearch, searchName }: Props) => {
  const [hasExternalError, setHasExternalError] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);

  // Deletes, then closes the modal
  const deleteAndClose = async () => {
    if (!isButtonEnabled) {
      return;
    }
    setIsButtonEnabled(false);
    try {
      const success = await deleteSearch();
      setIsButtonEnabled(true);
      if (success) {
        closeModal();
      } else {
        setHasExternalError(true);
      }
    } catch {
      setIsButtonEnabled(true);
      setHasExternalError(true);
    }
  };

  return (
    <SkinnyModal isOpen={isOpen} closeModal={closeModal} title="Delete saved search">
      <p>
        Are you sure you want to delete “{searchName}”? This will affect all users in your
        organization.
      </p>
      {hasExternalError && (
        <Error className="deprecated-mt-2 deprecated-mb-3">
          Something went wrong deleting your search. Please try again.
        </Error>
      )}
      <ModalButton
        variant="danger"
        onClick={deleteAndClose}
        disabled={!isButtonEnabled}
        className=""
      >
        {isButtonEnabled ? "Delete saved search" : "Deleting..."}
      </ModalButton>
    </SkinnyModal>
  );
};

export default DeleteSearchModal;
