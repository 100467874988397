import React from "react";
import MergeTable from "../../../shared-components/MergeTable";
import TableSkeletonLoader from "../../../integrations-management/shared/TableSkeletonLoader";
import AuditLogsLoadingStateTable from "./AuditLogsLoadingStateTable";

const NoPermissions = () => {
  return (
    <>
      <div className="mb-6">
        <p className="mb-2">You do not have permission to access Audit Trail.</p>
        <p>
          Please contact your organization's administrator to be added as an Admin to view Audit
          Trail.
        </p>
      </div>
      <AuditLogsLoadingStateTable />
    </>
  );
};

export default NoPermissions;
