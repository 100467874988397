import React, { useState, useEffect } from "react";
import { Col, Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PaginationFooter from "../../shared-components/PaginationFooter";
import {
  IntegrationIssue,
  IntegrationIssueActionLogEntry,
  IntegrationIssueActionLogEntryAction,
  IntegrationIssueStatus,
} from "../IntegrationsManagementEntities";
import {
  getFormattedEventDistanceFromNow,
  getIssueUpdatedAt,
} from "../utils/IntegrationsManagementUtils";
import EmptyStateWrapper from "../../shared-components/EmptyStateWrapper";
import { StatusBadge } from "../../shared-components/MergeBadges";
import { fetchWithAuth } from "../../../api-client/APIClient";
import { getCursorPath, snakeCaseToFirstLetterUpperCase } from "../../../services";
import IntegrationsManagementFilter from "../shared/IntegrationsManagementFilter";
import isEmpty from "lodash/isEmpty";
import { DemoDataAlert } from "../../shared-components/MergeAlerts";
import useAppContext from "../../context/useAppContext";
import classNames from "classnames";
import { UserAvatar, UserAvatarSize } from "../../shared-components/MergeAvatars";
import ClickableContainer from "../../shared-components/ClickableContainer";
import { navigateToIndividualIssuePage } from "../../../router/RouterUtils";
import { VolumeX } from "react-feather";
import ContentLoader from "react-content-loader";
import { spectrum } from "../../../styles/theme";
import IntegrationNameAndLogo from "../../configuration/integrations/IntegrationNameAndLogo";
import MergeTable from "../../shared-components/MergeTable";
import MergeText, { TextType } from "../../shared-components/MergeText";
import { getResolvedBy } from "./detail-page/IntegrationsManagementIndividualIssuePage";
import RotatingChevronDown from "../../shared-components/RotatingChevronDown";
import { MessageSquare } from "lucide-react";
import styled from "styled-components";

const RowHeaderContainer = styled.div`
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
`;

const NoWrapHeader = styled(Row)`
  margin-left: 0px;
  margin-right: 0px;
  column-gap: 8px;
  flex-wrap: nowrap;
`;

const LargerFontTD = styled.td`
  &&& {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
  }
`;

const GrayTD = styled.td`
  &&& {
    color: #939aa5;
  }
`;

const GraySpan = styled.span`
  color: #939aa5;
`;

const BlackSpan = styled.span`
  color: #111317;
`;

type OrderByField = "CREATED" | "UPDATED";
type OrderByOrder = "ASC" | "DESC";

export const getLastActionMessage = (lastAction: undefined | IntegrationIssueActionLogEntry) => {
  if (!lastAction || isEmpty(lastAction)) {
    return "None";
  }
  const { user, action_type, created_at } = lastAction;

  switch (action_type) {
    case IntegrationIssueActionLogEntryAction.RESOLVE:
      if (user) {
        return (
          <>
            <UserAvatar user={user} size={UserAvatarSize.xs} className="deprecated-mr-2" /> Resolved{" "}
            <span className="small deprecated-ml-2">
              {getFormattedEventDistanceFromNow(created_at)}
            </span>
          </>
        );
      }
      return "Resolved (auto)";
    case IntegrationIssueActionLogEntryAction.REOPEN:
      if (user) {
        return (
          <>
            <UserAvatar user={user} size={UserAvatarSize.xs} className="deprecated-mr-2" /> Reopened{" "}
            <span className="text-gray-50 small deprecated-ml-2">
              {getFormattedEventDistanceFromNow(created_at)}
            </span>
          </>
        );
      }
      return "Reopened (auto)";
    case IntegrationIssueActionLogEntryAction.NOTIFY:
      return (
        <>
          <UserAvatar user={user} size={UserAvatarSize.xs} className="deprecated-mr-2" /> Notified
          end-user{" "}
          <span className="text-gray-50 small deprecated-ml-2">
            {getFormattedEventDistanceFromNow(created_at)}
          </span>
        </>
      );
    default:
      return "None";
  }
};

const IntegrationsManagementIssuesPage = () => {
  const history = useHistory();
  const { user } = useAppContext();
  const [integrationIssues, setIntegrationIssues] = useState<null | IntegrationIssue[]>();
  const [previousPageURL, setPreviousPageURL] = useState<string>();
  const [nextPageURL, setNextPageURL] = useState<string>();
  const [paramsPath, setParamsPath] = useState<string | undefined>();
  const [orderBy, setOrderBy] = useState<string>("CREATED_DESC");
  const [updatedOrder, setUpdatedOrder] = useState<OrderByOrder>("DESC");
  const [createdOrder, setCreatedOrder] = useState<OrderByOrder>("DESC");

  //@ts-ignore
  useEffect(() => {
    let cancelled = false;
    setIntegrationIssues(null);

    const searchParams = new URLSearchParams(paramsPath);
    searchParams.set("order_by", orderBy);
    const orderedParamsPath = orderBy !== undefined ? searchParams.toString() : "";

    fetchWithAuth({
      path: "/integrations/issues?" + orderedParamsPath,
      method: "GET",
      onResponse: (data) => {
        if (!cancelled) {
          setNextPageURL(data.next);
          setPreviousPageURL(data.previous);
          setIntegrationIssues(data.results);
        }
      },
    });

    return () => (cancelled = true);
  }, [orderBy, paramsPath, user.is_demo]);

  function fetchIssuesWithCursor(cursorPath: string | undefined) {
    setIntegrationIssues(null);

    fetchWithAuth({
      path:
        "/integrations/issues?" +
        (cursorPath ? getCursorPath(cursorPath) : paramsPath ? paramsPath : ""),
      method: "GET",
      onResponse: (data) => {
        setNextPageURL(data.next);
        setPreviousPageURL(data.previous);
        setIntegrationIssues(data.results);
      },
    });
  }

  const issueStatus = (issue: IntegrationIssue) => {
    const status = issue.is_muted ? issue.is_muted : issue.status;

    switch (status) {
      case IntegrationIssueStatus.RESOLVED:
        return (
          <MergeText type={TextType.SUCCESS} isBold>
            {snakeCaseToFirstLetterUpperCase(status)}
          </MergeText>
        );
      case IntegrationIssueStatus.ONGOING:
        return (
          <MergeText type={TextType.DANGER} isBold>
            {snakeCaseToFirstLetterUpperCase(status)}
          </MergeText>
        );
      default:
        return (
          <MergeText type={TextType.MUTED} isBold>
            {snakeCaseToFirstLetterUpperCase(issue.status)}
          </MergeText>
        );
    }
  };

  const updateOrderBy = (field: OrderByField, order: OrderByOrder) => {
    const flippedOrder = order === "ASC" ? "DESC" : "ASC";
    if (field === "UPDATED") {
      setOrderBy(`UPDATED_${flippedOrder}`);
      setUpdatedOrder(flippedOrder);
    } else if (field === "CREATED") {
      setOrderBy(`CREATED_${flippedOrder}`);
      setCreatedOrder(flippedOrder);
    }
  };

  const header = (
    <>
      <th scope="col">Issue</th>
      <th scope="col">Status</th>
      <th scope="col">Organization</th>
      <th scope="col">Integration</th>
      <th scope="col">Recent Activity</th>
      <th scope="col">
        <RowHeaderContainer
          onClick={() => {
            updateOrderBy("UPDATED", updatedOrder);
          }}
        >
          <NoWrapHeader>
            <span>Updated</span>
            <RotatingChevronDown isRotatedUp={updatedOrder === "ASC"} />
          </NoWrapHeader>
        </RowHeaderContainer>
      </th>
      <th scope="col">
        <RowHeaderContainer
          onClick={() => {
            updateOrderBy("CREATED", createdOrder);
          }}
        >
          <NoWrapHeader>
            <span>Created</span>
            <RotatingChevronDown isRotatedUp={createdOrder === "ASC"} />
          </NoWrapHeader>
        </RowHeaderContainer>
      </th>
      <th scope="col" />
    </>
  );

  const content = (
    <>
      {integrationIssues ? (
        integrationIssues.length > 0 ? (
          integrationIssues.map((issue) => (
            <tr
              className="table-link"
              key={issue.id}
              onClick={() => navigateToIndividualIssuePage(history, issue.id)}
            >
              <LargerFontTD>
                {user.is_demo && <StatusBadge status="Demo" className="deprecated-mr-3" />}
                {issue.error_description}
                {issue.is_muted && <VolumeX className="deprecated-ml-2 text-gray-50" size={16} />}
              </LargerFontTD>
              <td>
                {issue.status === IntegrationIssueStatus.RESOLVED ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="issue-status-tooltip">{getResolvedBy(issue)}</Tooltip>}
                  >
                    {issueStatus(issue)}
                  </OverlayTrigger>
                ) : (
                  issueStatus(issue)
                )}
              </td>

              <LargerFontTD>{issue.linked_account.end_user.organization_name}</LargerFontTD>
              <LargerFontTD>
                <IntegrationNameAndLogo integration={issue.linked_account.integration} />
              </LargerFontTD>

              <td
                className={classNames(
                  getLastActionMessage(issue.last_action) == "None" && "text-gray-50 font-medium",
                )}
              >
                {getLastActionMessage(issue.last_action) == "None"
                  ? "–"
                  : getLastActionMessage(issue.last_action)}
              </td>
              <GrayTD className="small">
                {getFormattedEventDistanceFromNow(getIssueUpdatedAt(issue))}
              </GrayTD>
              <GrayTD className="small">
                {getFormattedEventDistanceFromNow(issue.first_incident_time)}
              </GrayTD>
              <GrayTD className="text-right d-flex align-items-center justify-content-center">
                <GraySpan className="deprecated-mr-4">
                  <MessageSquare className="deprecated-mr-2" size={12} /> {issue.comment_count}
                </GraySpan>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-top">Review issue details</Tooltip>}
                >
                  <ClickableContainer>
                    <BlackSpan className="fe fe-chevron-right" />
                  </ClickableContainer>
                </OverlayTrigger>
              </GrayTD>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={8} className="p-0">
              <EmptyStateWrapper isTable title="No issues" />
            </td>
          </tr>
        )
      ) : (
        Array.from({ length: 25 }).map((_, i) => (
          <tr key={`issue-skel-row-${i}`}>
            <td>
              <ContentLoader
                speed={1.4}
                width={40}
                height={20}
                viewBox="0 0 40 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="40" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td className="text-gray-50">
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>

            <td className="text-gray-50">
              <div className="d-flex align-items-center">
                <i className="fe fe-link-2 deprecated-mr-3" />
                <ContentLoader
                  speed={1.4}
                  width={80}
                  height={20}
                  viewBox="0 0 80 20"
                  backgroundColor={spectrum.gray0}
                  foregroundColor={spectrum.gray10}
                >
                  <rect x="0" y="3" rx="3" ry="3" width="80" height="14" />
                </ContentLoader>
              </div>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td>
              <ContentLoader
                speed={1.4}
                width={64}
                height={20}
                viewBox="0 0 64 20"
                backgroundColor={spectrum.gray0}
                foregroundColor={spectrum.gray10}
              >
                <rect x="0" y="3" rx="3" ry="3" width="64" height="14" />
              </ContentLoader>
            </td>
            <td />
          </tr>
        ))
      )}
    </>
  );

  return (
    <Row>
      <Col>
        <DemoDataAlert />
        <IntegrationsManagementFilter isIntegrationIssues setParamsPath={setParamsPath} />
        <MergeTable header={header} content={content} hasMorePaddingOnFirstElement />
        <PaginationFooter
          hasPrevious={!!previousPageURL}
          hasNext={!!nextPageURL}
          onPreviousClick={() => fetchIssuesWithCursor(previousPageURL)}
          onNextClick={() => fetchIssuesWithCursor(nextPageURL)}
        />
      </Col>
    </Row>
  );
};

export default IntegrationsManagementIssuesPage;
