import { Button } from "react-bootstrap";
import React from "react";
import styled from "styled-components";
import DeprecatedH6 from "../../deprecated/DeprecatedH6";

interface Props {
  stepTitle: string;
  icon?: JSX.Element;
  lightIndicator?: JSX.Element;
  isSelected: boolean;
  onClick: () => void;
}

const Heading = styled(DeprecatedH6)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  white-space: nowrap;
  margin: 0;
  display: flex;
  align-items: center;
`;

// TO-DO: Fix First and Last Steps to be Right Trapezoids
const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  transition: all 0s ease-out;
  border: 0;
  background: #f3f4f6;
  height: 40px;
  width: 220px;

  &.btn-selected {
    background: #eff6ff;
    color: var(--blue40);
    box-shadow: none;
  }
`;

const LightIndicatorContainer = styled.i`
  height: 100%;
  display: flex;
  align-items: center;
`;

const DashboardOnboardingSteps = ({
  stepTitle,
  icon,
  lightIndicator,
  isSelected,
  onClick,
}: Props) => {
  return (
    <StyledButton variant="white" className={isSelected && "btn-selected"} onClick={onClick}>
      <Heading>
        <i className="deprecated-mr-3">{icon}</i>
        {stepTitle}
      </Heading>
      <LightIndicatorContainer>{lightIndicator}</LightIndicatorContainer>
    </StyledButton>
  );
};

export default DashboardOnboardingSteps;
