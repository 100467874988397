import React from "react";
import styled from "styled-components";

const Card = styled.div`
  background: #fff2f3;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 13px;
  line-height: 20px;
  color: #e62837;
  margin: 12px 0;
`;

interface Props {
  children?: string | JSX.Element;
}

const ErrorCard = ({ children }: Props) => {
  return <Card>{children}</Card>;
};

export default ErrorCard;
