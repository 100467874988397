import {
  InitializeProps,
  UseMergeLinkProps,
  UseMergeLinkResponse,
} from "@mergeapi/react-merge-link/dist/types";
import { useCallback, useEffect, useState } from "react";
import useScript from "react-script-hook";

const isLinkTokenDefined = (config: UseMergeLinkProps): config is InitializeProps =>
  config?.linkToken !== undefined;

/**
 *
 * WARNING
 *
 * This is all copied from the react-merge-link repo's useMergeLink code
 * (except for the src being devlop-cdn)
 *
 * Watch out for discrepencies if react-merge-link has been updated!
 *
 */
export const useMergeLink = ({
  shouldSendTokenOnSuccessfulLink = true,
  ...config
}: UseMergeLinkProps): UseMergeLinkResponse => {
  const [loading, error] = useScript({
    src: "https://develop-cdn.merge.dev/initialize.js",
    checkForExisting: true,
  });
  const [isReady, setIsReady] = useState(false);
  const isServer = typeof window === "undefined";
  const isReadyForInitialization =
    !isServer && !!window.MergeLink && !loading && !error && isLinkTokenDefined(config);

  useEffect(() => {
    if (isReadyForInitialization && window.MergeLink) {
      window.MergeLink.initialize({
        ...config,
        shouldSendTokenOnSuccessfulLink,
        onReady: () => setIsReady(true),
      });
    }
  }, [isReadyForInitialization, config]);

  const open = useCallback(() => {
    if (window.MergeLink && isLinkTokenDefined(config)) {
      window.MergeLink.openLink(config);
    }
  }, [config]);

  return { open, isReady, error };
};
