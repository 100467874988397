import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled, { css } from "styled-components";
import Stack from "../integrations-management/linked-accounts/shared/Stack";
import CopyComponentWithIcon from "./CopyComponentWithIcon";
import ExternalLinkWithIcon from "./ExternalLinkComponentWithIcon";
import useTimeout from "./hooks/useTimeout";
import MergeModal from "./MergeModal";
import { Key } from "lucide-react";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  textLength: number;
};

const OneLineStack = styled(Stack)`
  flex-wrap: nowrap;
  background: var(--lm-gray-0, #f2f4f7);
  margin-top: 0;
  margin-left: 16px;
  padding: 2px 8px;
  border-radius: 4px;
`;

const Text = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
`;

// Fade on left and right to indicate there's more
const TextContainer = styled.div`
  max-width: 100%;
  white-space: nowrap;
  overflow: auto;
  position: relative;
  flex-grow: 1;
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 10%;
    height: 80%;
    width: 4px;
    pointer-events: none;
  }
  ${() => css`
    &:before {
      left: 0;
      background: linear-gradient(to left);
    }
    &:after {
      right: 0;
      background: linear-gradient(to right);
    }
  `}
`;

const IconGrayTextCard = ({ textLength }: Props) => {
  return (
    <>
      <OneLineStack
        className={classNames("text-gray-700 align-items-center no-outline-rounded-container")}
        $gap="4px"
      >
        <div>
          <Key size={12} />
        </div>
        <TextContainer>
          <Text className="overflow-hidden">{"•".repeat(textLength)}</Text>
        </TextContainer>
      </OneLineStack>
    </>
  );
};

export default IconGrayTextCard;
