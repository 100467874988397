import React from "react";
import styled from "styled-components";
import { HeaderPretitle } from "../shared-components/MergeText";
import DeprecatedH1 from "../../deprecated/DeprecatedH1";
import DeprecatedH4 from "../../deprecated/DeprecatedH4";

type Props = Pick<React.ComponentProps<"div">, "className"> & {
  title: string | JSX.Element;
  pretitle?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  badge?: string | JSX.Element;
};
interface HeaderBarInterface {
  hasPretitle: boolean;
}

const Container = styled.div<HeaderBarInterface>`
  padding: 0;
  ${(props) => props.hasPretitle && `padding: 24px 0`}
`;

const Title = styled(DeprecatedH1)`
  font-size: 28px;
  line-height: 36px;
  margin: 0;
`;

const Subtitle = styled(DeprecatedH4)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
  margin: 16px 0 0;
`;

const TitleStack = styled.div.attrs({ className: "d-flex align-items-center" })`
  gap: 16px;
`;

const Spacer = styled.span`
  flex-grow: 1;
`;

const Badge = styled.text`
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #111317;
  top: 3px;
`;

const Vector = styled.span`
  width: 24px;
  height: 0px;
  border: 0.5px solid #bdc9e1;
`;

const HeaderBar = ({ className, pretitle, title, subtitle, badge }: Props) => {
  return (
    <Container className={className} hasPretitle={!!pretitle}>
      <div className="custom-header-body">
        <div>
          {pretitle && <HeaderPretitle>{pretitle}</HeaderPretitle>}
          {badge ? (
            <TitleStack>
              <Title>{title}</Title>
              <Vector></Vector>
              <Badge>{badge}</Badge>
              <Spacer />
            </TitleStack>
          ) : (
            <Title>{title}</Title>
          )}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </div>
      </div>
    </Container>
  );
};

export default HeaderBar;
