import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { fetchCurrentUser, fetchWithAuth } from "../../../../api-client/APIClient";
import {
  LinkingFlowAuthenticationMethodPreference,
  PreexistingProductionLinkedAccountPreference,
} from "../../../../models/Entities";
import { DOCS_LINKING_FLOW_GET_STARTED_PATH } from "../../../../router/RouterUtils";
import useAppContext from "../../../context/useAppContext";
import InfoIconWithTooltip from "../../../shared-components/InfoIconWithTooltip";
import { SectionHeaderWrapper, TitleHeading } from "../../../shared-components/MergeLayouts";
import { StatusToggle } from "../../../shared-components/MergeToggles";
import { showErrorToast } from "../../../shared-components/Toasts";

/**
 * Determines if a particular enum case is username + password auth
 */
const isUsernameAndPasswordAuth = (preference: LinkingFlowAuthenticationMethodPreference) => {
  switch (preference) {
    case LinkingFlowAuthenticationMethodPreference.LOGIN_DEFAULT_WITHOUT_MANUAL_FALLBACK:
    case LinkingFlowAuthenticationMethodPreference.LOGIN_DEFAULT_WITH_MANUAL_FALLBACK:
      return true;
    case LinkingFlowAuthenticationMethodPreference.MANUAL_DEFAULT_WITHOUT_LOGIN_FALLBACK:
    case LinkingFlowAuthenticationMethodPreference.MANUAL_DEFAULT_WITH_LOGIN_FALLBACK:
      return false;
  }
};

/**
 * Determines if a particular enum case is a fallback enabled case
 */
const isFallbackAuth = (preference: LinkingFlowAuthenticationMethodPreference) => {
  switch (preference) {
    case LinkingFlowAuthenticationMethodPreference.LOGIN_DEFAULT_WITH_MANUAL_FALLBACK:
    case LinkingFlowAuthenticationMethodPreference.MANUAL_DEFAULT_WITH_LOGIN_FALLBACK:
      return true;
    case LinkingFlowAuthenticationMethodPreference.LOGIN_DEFAULT_WITHOUT_MANUAL_FALLBACK:
    case LinkingFlowAuthenticationMethodPreference.MANUAL_DEFAULT_WITHOUT_LOGIN_FALLBACK:
      return false;
  }
};

/**
 * Maps from two boolean variables to a linking flow authentication method that combines the two
 */
const preferenceFromAuthVariables = (
  isUsernameAndPasswordAuthEnabled: boolean,
  isFallbackAuthEnabled: boolean,
): LinkingFlowAuthenticationMethodPreference => {
  const mapping: Record<string, Record<string, LinkingFlowAuthenticationMethodPreference>> = {
    true: {
      true: LinkingFlowAuthenticationMethodPreference.LOGIN_DEFAULT_WITH_MANUAL_FALLBACK,
      // TODO: change this to WITHOUT_MANUAL_FALLBACK when backend supports that
      false: LinkingFlowAuthenticationMethodPreference.LOGIN_DEFAULT_WITH_MANUAL_FALLBACK,
    },
    false: {
      true: LinkingFlowAuthenticationMethodPreference.MANUAL_DEFAULT_WITH_LOGIN_FALLBACK,
      false: LinkingFlowAuthenticationMethodPreference.MANUAL_DEFAULT_WITHOUT_LOGIN_FALLBACK,
    },
  };
  return mapping[String(isUsernameAndPasswordAuthEnabled)][String(isFallbackAuthEnabled)];
};

const ConfigurationIntegrationsSettings = () => {
  const { user, setUser } = useAppContext();
  const location = useLocation();
  const autoEnableNewIntegrations = user.organization?.auto_enable_new_integrations;
  const preexistingProductionLinkedAccountPreference =
    user.organization?.preexisting_production_linked_account_preference;

  // These combined make up the integration authentication preference, easier to use two variables
  const [isUsernameAndPasswordAuthEnabled, setIsUsernameAndPasswordAuthEnabled] = useState(
    isUsernameAndPasswordAuth(user.organization?.linking_flow_authentication_method_preference),
  );
  const [isFallbackAuthEnabled, setIsFallbackAuthEnabled] = useState(
    isFallbackAuth(user.organization?.linking_flow_authentication_method_preference),
  );

  // When either auth preference changes, update the backend
  useEffect(() => {
    const preference = preferenceFromAuthVariables(
      isUsernameAndPasswordAuthEnabled,
      isFallbackAuthEnabled,
    );
    updateSettings({
      currentAuthPreference: preference,
    });
  }, [isUsernameAndPasswordAuthEnabled, isFallbackAuthEnabled]);

  const updateSettings = ({
    autoEnableNewIntegrations,
    currentAuthPreference,
    preexistingProductionLinkedAccountPreference,
  }: {
    autoEnableNewIntegrations?: boolean;
    currentAuthPreference?: LinkingFlowAuthenticationMethodPreference;
    preexistingProductionLinkedAccountPreference?: PreexistingProductionLinkedAccountPreference;
  }) => {
    fetchWithAuth({
      path: "/organizations/me",
      method: "PATCH",
      body: {
        auto_enable_new_integrations: autoEnableNewIntegrations,
        linking_flow_authentication_method_preference: currentAuthPreference,
        preexisting_production_linked_account_preference:
          preexistingProductionLinkedAccountPreference,
      },
      onResponse: () => {
        fetchCurrentUser(setUser);
      },
      onError: () => {
        showErrorToast("Something went wrong, please check your connection and try again.");
      },
    });
  };

  // Card for enabling new integrations by default
  const newIntegrationsByDefault = location.pathname.includes("link") ? null : (
    <Card>
      <Card.Body>
        <SectionHeaderWrapper
          title="Enable New Integrations by Default"
          subtitle={
            <>
              When Merge releases new integrations, add them automatically to{" "}
              <a href={DOCS_LINKING_FLOW_GET_STARTED_PATH} target="_blank" rel="noreferrer">
                <b>Link</b>
              </a>
            </>
          }
          titleHeading={TitleHeading.H3}
          button={
            <StatusToggle
              id="toggle-enable-new-integrations"
              isEnabled={autoEnableNewIntegrations}
              onChange={(newIsEnabled) =>
                updateSettings({ autoEnableNewIntegrations: newIsEnabled })
              }
              statusTextClassName="deprecated-mr-3"
              statusTextStyle={{ fontSize: "13px" }}
            />
          }
        />
      </Card.Body>
    </Card>
  );

  const usernameAndPasswordAuth = (
    <>
      <Form.Check
        className="deprecated-mb-3"
        type="radio"
        id="loginDefault"
        value="loginDefault"
        label={
          <div className="text-body">
            Strongly prefer <strong>username- and password-based</strong> authentication
          </div>
        }
        onChange={() => setIsUsernameAndPasswordAuthEnabled(true)}
        checked={isUsernameAndPasswordAuthEnabled}
      />
      {/* TODO: enable this when 4th case is supported on BE */}
      {/* <Form.Check
        className="deprecated-mb-3 deprecated-ml-4"
        type="checkbox"
        disabled={!isUsernameAndPasswordAuthEnabled}
        id="loginDefaultFallback"
        value="loginDefaultFallback"
        label={
          <div className={`text-body ${!isUsernameAndPasswordAuthEnabled ? "text-gray-50" : ""}`}>
            Allow user to opt for API key-based authentication
          </div>
        }
        onChange={() => setIsFallbackAuthEnabled(!isFallbackAuthEnabled)}
        checked={isFallbackAuthEnabled && isUsernameAndPasswordAuthEnabled}
      /> */}
    </>
  );

  const apiAuth = (
    <>
      <Form.Check
        className="deprecated-mb-3"
        type="radio"
        id="manualDefault"
        value="manualDefault"
        label={
          <div className="text-body">
            Strongly prefer <strong>API key-based</strong> authentication
          </div>
        }
        onChange={() => setIsUsernameAndPasswordAuthEnabled(false)}
        checked={!isUsernameAndPasswordAuthEnabled}
      />
      <Form.Check
        className="deprecated-ml-4"
        type="checkbox"
        disabled={isUsernameAndPasswordAuthEnabled}
        id="manualDefaultFallback"
        value="manualDefaultFallback"
        label={
          <div className={`text-body ${isUsernameAndPasswordAuthEnabled ? "text-gray-50" : ""}`}>
            Allow user to opt for username- and password-based authentication
          </div>
        }
        onChange={() => setIsFallbackAuthEnabled(!isFallbackAuthEnabled)}
        checked={isFallbackAuthEnabled && !isUsernameAndPasswordAuthEnabled}
      />
    </>
  );

  const duplicateProductionLinkedAccountDetectionCard = (
    <Card>
      <Card.Body>
        <SectionHeaderWrapper
          title="Duplicate Production Linked Account Detection"
          subtitle={
            <>
              <>
                Define what Merge should do when your end user attempts to integrate using
                credentials (e.g. API Key, OAuth credentials, etc.) matching an existing Production
                Linked Account.
              </>
              <Form style={{ marginTop: "20px" }}>
                <Form.Check
                  className="deprecated-mb-3"
                  type="radio"
                  id="create-new-linked-account"
                  label={
                    <div className="text-body">
                      <strong>Create a new linked account</strong> with the previously used
                      credentials
                      <InfoIconWithTooltip
                        iconClassName="deprecated-ml-2 text-gray-50"
                        text="Duplicate Production Linked Accounts are prone to rate limiting issues due to the increase in outbound API requests made for the same account."
                      />
                    </div>
                  }
                  onChange={() =>
                    updateSettings({
                      preexistingProductionLinkedAccountPreference:
                        PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER,
                    })
                  }
                  checked={
                    preexistingProductionLinkedAccountPreference ===
                    PreexistingProductionLinkedAccountPreference.CREATE_AS_NEW_END_USER
                  }
                />
                <Form.Check
                  className="deprecated-mb-3"
                  type="radio"
                  id="use-preexisting"
                  label={
                    <div className="text-body">
                      <strong>Show a success message in Link</strong> to the end user but{" "}
                      <strong>do not create a new Linked Account</strong> with the previously used
                      credentials
                      <p className="mb-0 text-gray-50">
                        Note: This option should only be used after you've defined logic to
                        associate multiple of your users with the same account token in your Merge
                        Link implementation.
                      </p>
                    </div>
                  }
                  onChange={() =>
                    updateSettings({
                      preexistingProductionLinkedAccountPreference:
                        PreexistingProductionLinkedAccountPreference.USE_PREEXISTING_END_USER,
                    })
                  }
                  checked={
                    preexistingProductionLinkedAccountPreference ===
                    PreexistingProductionLinkedAccountPreference.USE_PREEXISTING_END_USER
                  }
                />
                <Form.Check
                  className="deprecated-mb-3"
                  type="radio"
                  id="block-duplicates"
                  label={
                    <div className="text-body">
                      <strong>Show an error message in Link</strong> to the end user and{" "}
                      <strong>do not create a new Linked Account</strong> with the previously used
                      credentials
                    </div>
                  }
                  onChange={() =>
                    updateSettings({
                      preexistingProductionLinkedAccountPreference:
                        PreexistingProductionLinkedAccountPreference.BLOCK,
                    })
                  }
                  checked={
                    preexistingProductionLinkedAccountPreference ===
                    PreexistingProductionLinkedAccountPreference.BLOCK
                  }
                />
              </Form>
            </>
          }
          titleHeading={TitleHeading.H3}
        />
      </Card.Body>
    </Card>
  );

  return (
    <>
      <Row>
        <Col>
          <SectionHeaderWrapper
            title={location.pathname.includes("link") ? "Authentication" : "Settings"}
          >
            <>
              <Card>
                <Card.Body>
                  <SectionHeaderWrapper
                    title="Integration Authentication"
                    titleHeading={TitleHeading.H3}
                    subtitle={
                      <>
                        <>Customize integration authentication in Link.</>
                        <Form style={{ marginTop: "20px" }}>
                          {usernameAndPasswordAuth}
                          {apiAuth}
                        </Form>
                        <p className="small mb-0 deprecated-mt-4">
                          Some integration options may not allow customization. Contact Merge for
                          more information.
                        </p>
                      </>
                    }
                  />
                </Card.Body>
              </Card>
              {newIntegrationsByDefault}
              {duplicateProductionLinkedAccountDetectionCard}
            </>
          </SectionHeaderWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ConfigurationIntegrationsSettings;
