import { Plus } from "lucide-react";
import React, { useDebugValue, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import {
  FieldMappingCommonModelConfiguration,
  FieldMappingCreationAndEditDict,
  FieldMappingInstance,
  FieldMappingMetaResponse,
  FieldMappingTarget,
  LinkedAccount,
} from "../../../../../models/Entities";
import { CONFIGURATION_COMMON_MODELS_PATH } from "../../../../../router/RouterUtils";
import { RemoteDataDisabledText } from "../../../../configuration/integrations/field-mappings/ConfigurationFieldMappingCard";
import MergeText from "../../../../shared-components/MergeText";
import FieldMappingInstanceCard from "./FieldMappingInstanceCard";
import FieldMappingTargetCard from "./FieldMappingTargetCard";
import { CustomBadge } from "../../../../shared-components/MergeBadges";
import { OrganizationAvatar, UserAvatarSize } from "../../../../shared-components/MergeAvatars";
import { createFieldMappingOptions } from "./CreateLinkedAccountFieldMappingPage";
import * as uuid from "uuid";
import { Loader } from "react-bootstrap-typeahead";
import SkeletonLoader from "../../../../shared-components/SkeletonLoader";
import { spectrum } from "../../../../../styles/theme";
import MergeLogoMark from "../../../logs/table/MergeLogoMark";
import MergeToIntegrationMappingBar from "./MergeToIntegrationMappingBar";
import { Badge } from "@merge-api/merge-javascript-shared";

type Props = {
  commonModelName: string;
  linkedAccount: LinkedAccount;
  fieldMappings: Array<FieldMappingInstance | FieldMappingTarget>;
  fieldMappingConfig: FieldMappingCommonModelConfiguration | null;
  customMappingMetaResponse: FieldMappingMetaResponse | undefined;
  hasFieldMappingsLoaded: boolean;
};

const LinkedAccountCommonModelFieldMappingContainer = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow:
    0px 3px 12px -3px rgba(0, 0, 0, 0.12),
    0px 0px 0px 0.5px rgba(220, 226, 234, 0.2);
`;

const LinkedAccountFieldMappingsContainer = styled.div`
  display: grid;
`;

const LinkedAccountFieldMappingsCustomButtonGridContainer = styled.div`
  display: grid;
  grid-template-columns: 9fr 0.1fr;
  margin-right: -12px;
`;

const LinkedAccountFieldMappingsHeaderContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr 4fr 0.1fr;
  margin-left: -6px;
`;

const LinkedAccountFieldMappingsHeaderText = styled.div`
  font-weight: 600;
`;

const SpaceHolder = styled.div``;

const EmptyFieldMappings = styled(MergeText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

const AddNewFieldMappingContainer = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  gap: 3px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  width: 100%;
  color: ${spectrum.gray70};
  background: ${spectrum.gray0};
  border-radius: 6px;
`;

const CommonModelNameTitle = styled.div`
  color: var(--lm-gray-90, #121314);
  /* App + Docs/Semibold/H4 */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

const RemoteDataDisabledSubtitleContainer = styled.div`
  font-family: "Inter";
  font-style: normal;
`;

const AddFieldMappingBtnText = styled.div`
  color: ${spectrum.gray70}
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
`;

export const FillerDiv = styled.div`
  width: 17px;
`;

interface CustomMappingButtonProps {
  inGrid: boolean;
}

const isLinkedAccountInstance = (
  customMapping: FieldMappingInstance | FieldMappingTarget,
): customMapping is FieldMappingInstance => {
  // TODO: Better way of detemrmining this
  return !("is_mappable_in_link" in customMapping);
};

const LinkedAccountCommonModelFieldMappingOverview = ({
  commonModelName,
  linkedAccount,
  fieldMappings,
  customMappingMetaResponse,
  fieldMappingConfig,
  hasFieldMappingsLoaded,
}: Props) => {
  const fieldMappingsEnabled = fieldMappingConfig?.has_remote_data_enabled;

  const LoadingAddNewCustomMappingButton = ({ inGrid }: CustomMappingButtonProps) =>
    inGrid ? (
      <LinkedAccountFieldMappingsCustomButtonGridContainer className="w-100 mt-6">
        <AddNewFieldMappingContainer disabled={true}>
          <Loader bsSize="small" />
          <div className="ml-2">Loading origin fields</div>
        </AddNewFieldMappingContainer>
        <FillerDiv className="ml-2"></FillerDiv>
      </LinkedAccountFieldMappingsCustomButtonGridContainer>
    ) : (
      <div className="w-100 mt-2 d-flex">
        <AddNewFieldMappingContainer disabled={true}>
          <Loader bsSize="small" />
          <div className="ml-2">Loading origin fields</div>
        </AddNewFieldMappingContainer>
      </div>
    );

  const CustomMappingButton = ({ inGrid }: CustomMappingButtonProps) =>
    customMappingMetaResponse === undefined && fieldMappingOptions.length === 0 ? (
      <LoadingAddNewCustomMappingButton inGrid={inGrid} />
    ) : (
      <AddNewCustomMappingButton inGrid={inGrid} />
    );

  const AddNewCustomMappingButton = ({ inGrid }: CustomMappingButtonProps) => {
    const child = (
      <AddNewFieldMappingContainer
        disabled={
          isCreatingNewFieldMapping ||
          customMappingMetaResponse === undefined ||
          fieldMappingOptions.length == 0
        }
        onClick={() => {
          if (
            !isCreatingNewFieldMapping &&
            customMappingMetaResponse !== undefined &&
            fieldMappingOptions.length > 0
          ) {
            setIsCreatingNewFieldMapping(true);
            const newMapping: FieldMappingInstance = {
              field_key: "",
              id: "",
              field_description: "",
              configured_by: "",
              field_traversal_path: [],
              common_model_name: "string",
              field_mapping_target: null,
              display_name: "",
              is_integration_wide: false,
              enable_linked_account_level_overrides: false,
              remote_endpoint_path: "",
              remote_endpoint_method: "",
              origin_type: "",
            };

            setFieldMappingInstances((prevState) => [...prevState, newMapping]);
          }
        }}
      >
        {fieldMappingOptions.length == 0 && customMappingMetaResponse !== undefined ? (
          <div>No origin fields currently available</div>
        ) : (
          <div className="d-flex flex-row align-items-center">
            <Plus size={16} />
            <AddFieldMappingBtnText className="ml-[3px]">Field Mapping</AddFieldMappingBtnText>
          </div>
        )}
      </AddNewFieldMappingContainer>
    );

    return inGrid ? (
      <LinkedAccountFieldMappingsCustomButtonGridContainer className="w-100 mt-6">
        {child}
        <FillerDiv className="ml-2"></FillerDiv>
      </LinkedAccountFieldMappingsCustomButtonGridContainer>
    ) : (
      <div className="w-100 mt-4 d-flex">{child}</div>
    );
  };

  const newFieldMappingIsSaved = (newInstance: FieldMappingInstance) => {
    if (fieldMappingInstances.length > 0) {
      setFieldMappingInstances((prevState) => [
        ...prevState.splice(0, prevState.length - 1),
        newInstance,
      ]);
      setIsCreatingNewFieldMapping(false);
    }
  };

  const createNewIntegrationFieldMapping = (
    target: FieldMappingTarget,
    newInstance: FieldMappingInstance,
  ) => {
    const targetIndex = fieldMappingInstances.indexOf(target);

    if (targetIndex !== -1) {
      const tempFieldMappingInstance = fieldMappingInstances;
      tempFieldMappingInstance[targetIndex] = newInstance;
      setFieldMappingInstances(tempFieldMappingInstance);
      setIsCreatingNewFieldMapping(false);
    }
  };

  const cancelNewFieldMapping = () => {
    setFieldMappingInstances((prevState) => [...prevState.slice(0, -1)]);
    setIsCreatingNewFieldMapping(false);
  };

  const cancelNewIntegrationFieldMapping = () => {
    setIsCreatingNewFieldMapping(false);
  };

  const [fieldMappingInstances, setFieldMappingInstances] =
    useState<Array<FieldMappingInstance | FieldMappingTarget>>(fieldMappings);

  const [isCreatingNewFieldMapping, setIsCreatingNewFieldMapping] = useState<boolean>(false);

  const creatingNewOrgFieldMapping = () => setIsCreatingNewFieldMapping(true);

  useEffect(() => {
    setFieldMappingInstances(fieldMappings);
  }, [fieldMappings]);

  const num_mappings = fieldMappingInstances.filter((customMapping) =>
    isLinkedAccountInstance(customMapping),
  ).length;

  const [fieldMappingOptions, setFieldMappingOptions] = useState<FieldMappingCreationAndEditDict[]>(
    [],
  );

  const fieldMappingMetaOptions = customMappingMetaResponse?.available_field_mappings;

  useEffect(() => {
    if (fieldMappingMetaOptions !== undefined) {
      setFieldMappingOptions(createFieldMappingOptions(fieldMappingMetaOptions, commonModelName));
    }
  }, [fieldMappingMetaOptions]);

  return (
    <LinkedAccountCommonModelFieldMappingContainer className="my-6">
      <div className="h-2 bg-gray-10 rounded-t-lg" />
      <div className="border-b border-gray-10 p-6 pt-3 d-flex align-items-center justify-content-between">
        <CommonModelNameTitle>{commonModelName} custom fields</CommonModelNameTitle>
        {fieldMappingsEnabled && num_mappings === 0 && <Badge color="gray">0 Mappings</Badge>}
        {fieldMappingsEnabled && num_mappings > 0 && (
          <Badge color="gray">{num_mappings == 1 ? "1 Mapping" : `${num_mappings} Mappings`}</Badge>
        )}
      </div>
      {hasFieldMappingsLoaded ? (
        <div
          className={`py-5 pb-6 ${
            fieldMappingInstances.length === 0 || !fieldMappingsEnabled ? "px-6" : "pl-6"
          }`}
        >
          {fieldMappingInstances.length === 0 ? (
            fieldMappingsEnabled && (
              <>
                <EmptyFieldMappings>
                  This Common Model has no{" "}
                  <a href="/configuration/field-mappings/target-fields">target fields</a> or Field
                  Mappings.
                </EmptyFieldMappings>
                <CustomMappingButton inGrid={false} />
              </>
            )
          ) : (
            <>
              <LinkedAccountFieldMappingsContainer>
                {fieldMappingsEnabled && (
                  <div className="pr-6 mb-4">
                    <MergeToIntegrationMappingBar
                      integrationName={linkedAccount.integration.name}
                      integrationSquareImage={linkedAccount.integration.square_image}
                      text="Merge target fields"
                    />
                  </div>
                )}

                {fieldMappingsEnabled && (
                  <div className="d-flex flex-column gap-6">
                    {fieldMappingInstances.map((customMapping) =>
                      isLinkedAccountInstance(customMapping) && customMapping.field_key == "" ? (
                        <FieldMappingInstanceCard
                          fieldMappingMetaResponse={customMappingMetaResponse}
                          common_model={commonModelName}
                          key={uuid.v4()}
                          fieldMapping={customMapping}
                          linkedAccount={linkedAccount}
                          fieldMappingConfig={fieldMappingConfig}
                          newMapping={true}
                          cancelNewMapping={cancelNewFieldMapping}
                          newMappingSaved={newFieldMappingIsSaved}
                        />
                      ) : isLinkedAccountInstance(customMapping) ? (
                        <FieldMappingInstanceCard
                          fieldMappingMetaResponse={customMappingMetaResponse}
                          common_model={commonModelName}
                          key={customMapping.id}
                          fieldMapping={customMapping}
                          linkedAccount={linkedAccount}
                          fieldMappingConfig={fieldMappingConfig}
                          originOptionsAvailable={fieldMappingOptions.length > 0}
                        />
                      ) : (
                        <>
                          {fieldMappingsEnabled && (
                            <FieldMappingTargetCard
                              fieldMappingMetaResponse={customMappingMetaResponse}
                              key={customMapping.id}
                              fieldMappingTarget={customMapping}
                              linkedAccount={linkedAccount}
                              fieldMappingConfig={fieldMappingConfig}
                              cancelNewMapping={cancelNewIntegrationFieldMapping}
                              newIntegrationFieldMappingCreated={createNewIntegrationFieldMapping}
                              creatingNewOrgFieldMapping={creatingNewOrgFieldMapping}
                              originOptionsAvailable={fieldMappingOptions.length > 0}
                            />
                          )}
                        </>
                      ),
                    )}
                  </div>
                )}
                {fieldMappingsEnabled && <CustomMappingButton inGrid={true} />}
              </LinkedAccountFieldMappingsContainer>
            </>
          )}
          {!fieldMappingsEnabled && (
            <RemoteDataDisabledSubtitleContainer>
              <RemoteDataDisabledText>
                <a
                  href={
                    CONFIGURATION_COMMON_MODELS_PATH +
                    `/${linkedAccount.category}` +
                    `/#${commonModelName}`
                  }
                >
                  Enable Remote Data
                </a>{" "}
                to create Field Mappings for this Common Model.
              </RemoteDataDisabledText>
            </RemoteDataDisabledSubtitleContainer>
          )}
        </div>
      ) : (
        <div className="pt-5 pb-6 px-6 flex flex-row gap-x-[68px]">
          <SkeletonLoader fullWidth={true} height={24} borderRadius={4} />{" "}
          <SkeletonLoader fullWidth={true} height={24} borderRadius={4} />{" "}
        </div>
      )}
    </LinkedAccountCommonModelFieldMappingContainer>
  );
};

export default LinkedAccountCommonModelFieldMappingOverview;
