import classNames from "classnames";
import { format } from "date-fns";
import { ChevronDown, ChevronRight, User } from "lucide-react";
import React, { useEffect, useMemo, useState, useContext } from "react";
import isEqual from "lodash/isEqual";
import { Dropdown, OverlayTrigger, Tooltip, Accordion, AccordionContext } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { fetchWithAuth } from "../../../../../api-client/APIClient";
import { getLocalizedConditionOperatorName } from "../../../../../config/localization/mappings";
import {
  CateogryScopeMap,
  CommonModelToggle,
  CommonModelSyncStatus,
  CommonModelSyncStatusType,
  PauseReason,
  LinkedAccountSelectiveSyncCondition,
  LinkedAccount,
  SelectiveSyncConditionSchema,
  CommonModelScopeMap,
} from "../../../../../models/Entities";
import { isFeatureEnabled } from "../../../../../router/ComponentForFeature";
import {
  navigateToSelectiveSyncCreateOrEditConditionPage,
  navigateToLinkedAccountDetailDataPage,
} from "../../../../../router/RouterUtils";
import useAppContext from "../../../../context/useAppContext";
import EmptyStateWrapper from "../../../../shared-components/EmptyStateWrapper";
import InfoIconWithTooltip from "../../../../shared-components/InfoIconWithTooltip";
import { DemoDataAlert } from "../../../../shared-components/MergeAlerts";
import MergeTable from "../../../../shared-components/MergeTable";
import RotatingChevronRight from "../../../../shared-components/RotatingChevronRight";
import Stack from "../../shared/Stack";
import {
  ClickableRow,
  ClickableCell,
  NoHoverRow,
  StyledTooltip,
  SyncConditionRowContainer,
  SyncSelectionContainer,
  SyncSelectionHeader,
  TableIconColumn,
  TooltipParagraph,
} from "../selective-sync/SelectiveSyncStyles";
import { formatValueForDisplay } from "../selective-sync/SelectiveSyncUtils";
import type { Props as SyncHistoryProps } from "./SyncHistory";
import {
  getLinkedAccountCommonModelTogglesMap,
  getOrgCommonModelTogglesMap,
  createScopeMap,
} from "../../../../../api-client/APIClient";
import { showSuccessToast } from "../../../../shared-components/Toasts";
import ClickableContainer from "../../../../shared-components/ClickableContainer";
import ScopesTable from "../data/ScopesTable";
import ScopesDropdown from "../data/ScopesDropdown";
import { Schema, Schemas } from "../../../../configuration/common-models/CommonModelToggleTypes";
import { ScopeStatuses } from "../../../../../constants";
import { ScopesFieldToggle } from "../../../../shared-components/MergeToggles";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import { SmallWhiteButton, Badge } from "@merge-api/merge-javascript-shared";
const WhiteSpaceNormalDiv = styled.div`
  white-space: normal;
`;

type TableWrapperProps = {
  isOverviewPage: boolean;
};
const TableWrapper = styled.div<TableWrapperProps>`
  background-color: #ffffff;
  box-shadow: ${(props) =>
    props.isOverviewPage
      ? "0px 0px 0px 0.5px rgba(220, 226, 234, 0.35), 0px 3px 12px -3px rgba(0, 0, 0, 0.12);"
      : "0px 4px 20px -4px rgba(0, 0, 0, 0.08);"} 
  border-radius: 10px;
  margin-bottom: ${(props) => (props.isOverviewPage ? "" : "108px")};
`;

const TableTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 12px 16px;
`;

const StyledChevronRight = styled(ChevronRight)`
  margin-left: 6px;
  margin-top: 2px;
`;

const TitleBorder = styled.div`
  border-bottom: 0.5px solid #edf2f9;
`;

const ViewMoreContainer = styled.div`
  border-top: 0.5px solid #edf2f9;
  font-weight: 500;
  padding: 10px;
`;

const ScopeText = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-right: 7px;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  font-size: 12px;
  font-weight: 400;
  &:hover {
    background-color: #eaeef3 !important;
  }
`;

const StyledUser = styled(User)`
  margin-left: 9px;
`;
const conditionsToFormattedString = (
  linkedAccount: LinkedAccount,
  conditions: LinkedAccountSelectiveSyncCondition[],
  schemaMap: { [schemaID: string]: SelectiveSyncConditionSchema },
) => {
  return conditions.map((condition, index, array) => (
    <React.Fragment key={index}>
      {index === 0
        ? ""
        : index === array.length - 1
        ? (array.length > 2 ? "," : "") + " and "
        : ", "}
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={
          <StyledTooltip id={`selective-sync-${index}-tooltip`}>
            {condition.field_name && (
              <TooltipParagraph>
                <strong>{condition.field_name}</strong> is Merge's Common Model field.
              </TooltipParagraph>
            )}
            {condition.native_name && (
              <TooltipParagraph>
                <strong>{condition.native_name}</strong> is the {linkedAccount.integration.name}{" "}
                field.
              </TooltipParagraph>
            )}
          </StyledTooltip>
        }
      >
        <span style={{ textDecoration: "underline" }}>
          {condition.field_name || condition.native_name}
        </span>
      </OverlayTrigger>

      {" " +
        getLocalizedConditionOperatorName(
          schemaMap[condition.condition_schema_id].condition_type,
          condition.operator,
        ) +
        " "}
      <b>
        {formatValueForDisplay(
          schemaMap[condition.condition_schema_id].condition_type,
          condition.value,
        )}
      </b>
    </React.Fragment>
  ));
};

const SelectiveSyncConditionRow = ({
  linkedAccount,
  conditions,
  schemaMap,
  onClick,
}: {
  linkedAccount: LinkedAccount;
  conditions: LinkedAccountSelectiveSyncCondition[];
  schemaMap: { [schemaID: string]: SelectiveSyncConditionSchema };
  onClick: () => void;
}) => {
  return (
    <SyncConditionRowContainer onClick={onClick}>
      <WhiteSpaceNormalDiv>
        {conditionsToFormattedString(linkedAccount, conditions, schemaMap)}
      </WhiteSpaceNormalDiv>
      <ChevronRight color="#697387" width={12} height={12} />
    </SyncConditionRowContainer>
  );
};

const SelectiveSyncRowContainer = ({
  linkedAccount,
  commonModel,
  selectiveSyncConditions,
  schemaMap,
}: {
  linkedAccount: LinkedAccount | null;
  commonModel: string;
  selectiveSyncConditions: LinkedAccountSelectiveSyncCondition[];
  schemaMap: { [schemaID: string]: SelectiveSyncConditionSchema };
}) => {
  const history = useHistory();

  return linkedAccount ? (
    <SyncSelectionContainer>
      {selectiveSyncConditions && selectiveSyncConditions.length > 0 ? (
        <>
          <SyncSelectionHeader>Selective Sync</SyncSelectionHeader>
          <SelectiveSyncConditionRow
            linkedAccount={linkedAccount}
            conditions={selectiveSyncConditions}
            schemaMap={schemaMap}
            onClick={() =>
              navigateToSelectiveSyncCreateOrEditConditionPage(history, linkedAccount, commonModel)
            }
          />
        </>
      ) : (
        <>
          <div className="max-w-full whitespace-normal mt-3">
            This model has no Selective Sync configured. Merge will sync all <b>{commonModel}</b>{" "}
            data from {linkedAccount.end_user.organization_name} and{" "}
            {linkedAccount.integration.name}.
          </div>
          <SmallWhiteButton
            variant="plus"
            onClick={() =>
              navigateToSelectiveSyncCreateOrEditConditionPage(history, linkedAccount, commonModel)
            }
          >
            Selective Sync
          </SmallWhiteButton>
        </>
      )}
    </SyncSelectionContainer>
  ) : (
    <></>
  );
};

const getSyncStatusBadge = (
  syncStatus: CommonModelSyncStatusType,
  pauseReason?: PauseReason | null,
  syncReason?: string | null,
) => {
  switch (syncStatus) {
    case CommonModelSyncStatusType.SYNCING:
      return (
        <Badge color="blue">
          <div className="inline-flex items-center justify-center">
            <RefreshCw className="rotating m-0 p-0" size={10} />
            <span className="ml-1.5">Syncing</span>
            {syncReason && (
              <InfoIconWithTooltip text={syncReason} iconClassName="ml-1.5 text-gray-50" />
            )}
          </div>
        </Badge>
      );
    case CommonModelSyncStatusType.DONE:
      return <Badge color="teal">Done</Badge>;

    case CommonModelSyncStatusType.PARTIALLY_SYNCED:
      return (
        <Badge color="yellow">
          <div className="inline-flex items-center justify-center">
            <span className="ml-0">Partially Synced</span>
            <InfoIconWithTooltip
              iconClassName="ml-1.5 text-gray-50"
              text="One or more fields for this model failed to sync."
            />
          </div>
        </Badge>
      );

    case CommonModelSyncStatusType.FAILED:
      return <Badge color="red">Failed</Badge>;
    case CommonModelSyncStatusType.DISABLED:
      return <Badge color="gray">Disabled</Badge>;
    case CommonModelSyncStatusType.PAUSED:
      if (pauseReason && pauseReason === PauseReason.PRICING_PLAN_LIMIT) {
        return (
          <Badge color="gray">
            <div className="inline-flex items-center justify-center">
              Paused
              <InfoIconWithTooltip
                iconClassName="ml-1.5 text-gray-50"
                text="Please upgrade your plan to continue syncing data from this account."
              />
            </div>
          </Badge>
        );
      } else {
        return (
          <Badge color="gray">
            <div className="inline-flex items-center justify-center">
              Paused
              <InfoIconWithTooltip
                iconClassName="ml-1.5 text-gray-50"
                text="Syncing for this account has been paused due to inactivity or unsuccessful sync attempts. To resume automated syncing, ensure that the account’s credentials are valid and press 'Resync all'"
              />
            </div>
          </Badge>
        );
      }
    default:
      return null;
  }
};

const getScopesText = (scopes: string[]) => {
  if (!scopes || scopes.length == 0) {
    return ScopeStatuses.DISABLED;
  } else if (scopes.includes("ENABLED_ACTION_WRITE") && scopes.includes("ENABLED_ACTION_READ")) {
    return ScopeStatuses.READ_WRITE;
  } else if (scopes.includes("ENABLED_ACTION_WRITE")) {
    return ScopeStatuses.WRITE;
  } else {
    return ScopeStatuses.READ;
  }
};

const SyncHistoryExpandableRow = ({
  syncStatus,
  linkedAccount,
  selectiveSyncConditions,
  schemaMap,
  scopes,
  isOverviewPage,
}: {
  syncStatus: CommonModelSyncStatus;
  linkedAccount: LinkedAccount | null;
  selectiveSyncConditions: LinkedAccountSelectiveSyncCondition[];
  schemaMap: { [schemaID: string]: SelectiveSyncConditionSchema };
  scopes: CommonModelScopeMap;
  isOverviewPage: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user } = useAppContext();

  const doesModelHaveConditionSchema = useMemo(() => {
    for (const schema of Object.values(schemaMap)) {
      if (schema.common_model === syncStatus.model_name) {
        return true;
      }
    }
    return false;
  }, [syncStatus, schemaMap]);

  return isFeatureEnabled("is_selective_sync_enabled", user) && doesModelHaveConditionSchema ? (
    <>
      <ClickableRow key={syncStatus.model_id} onClick={() => setIsOpen(!isOpen)}>
        <StyledTdLeft isOverviewPage={isOverviewPage}>
          <b>{syncStatus.model_name}</b>
        </StyledTdLeft>
        <td className="gray">{getScopesText(scopes.actions)}</td>
        <td>
          {getSyncStatusBadge(
            syncStatus.status,
            linkedAccount?.pause_reason,
            syncStatus.sync_reason,
          )}
        </td>
        <td>
          {syncStatus.last_sync_start ? (
            format(new Date(syncStatus.last_sync_start), "MMM dd, hh:mm a")
          ) : (
            <div className="text-sm font-medium text-gray-30">—</div>
          )}
        </td>
        <td>
          {!(linkedAccount?.is_test_account ?? false) && syncStatus.next_sync_start ? (
            format(new Date(syncStatus.next_sync_start), "MMM dd, hh:mm a")
          ) : (
            <div className="text-sm font-medium text-gray-30">—</div>
          )}
        </td>
        <TableIconColumn>
          <RotatingChevronRight isRotatedDown={isOpen} />
        </TableIconColumn>
      </ClickableRow>
      <tr className={classNames("collapse", isOpen ? "show" : "")}>
        <NoHoverRow colSpan={5}>
          <SelectiveSyncRowContainer
            linkedAccount={linkedAccount}
            commonModel={syncStatus.model_name}
            selectiveSyncConditions={selectiveSyncConditions}
            schemaMap={schemaMap}
          />
        </NoHoverRow>
      </tr>
    </>
  ) : (
    <>
      <tr key={syncStatus.model_id}>
        <StyledTdLeft isOverviewPage={isOverviewPage}>
          <b>{syncStatus.model_name}</b>
        </StyledTdLeft>
        <td className="gray">{getScopesText(scopes.actions)}</td>
        <td>
          {getSyncStatusBadge(
            syncStatus.status,
            linkedAccount?.pause_reason,
            syncStatus.sync_reason,
          )}
        </td>
        <td>
          {syncStatus.last_sync_start ? (
            format(new Date(syncStatus.last_sync_start), "MMM dd, hh:mm a")
          ) : (
            <div className="text-sm font-medium text-gray-30">—</div>
          )}
        </td>
        <td>
          {!(linkedAccount?.is_test_account ?? false) && syncStatus.next_sync_start ? (
            format(new Date(syncStatus.next_sync_start), "MMM dd, hh:mm a")
          ) : (
            <div className="text-sm font-medium text-gray-30">—</div>
          )}
        </td>
        <StyledTdRight isOverviewPage={isOverviewPage}></StyledTdRight>
      </tr>
    </>
  );
};

type RowModelNameProps = {
  isOpen: boolean;
};
const RowModelName = styled.td<RowModelNameProps>`
  font-size: ${(props) => (props.isOpen ? "18px !important" : "12px !important")};
  overflow: hidden;
  max-width: 100px;
  padding-left: 24px !important;
  transition: font-size 0.5s;
`;

type StyledThProps = {
  isOverviewPage?: boolean;
};
const StyledTh = styled.th<StyledThProps>`
  padding-left: ${(props) => (props.isOverviewPage ? "" : "24px !important")};
`;

const StyledThRight = styled.th`
  padding-right: 24px !important;
`;

type StyledTdLeftProps = {
  isOverviewPage?: boolean;
};
const StyledTdLeft = styled.td<StyledTdLeftProps>`
  padding-left: ${(props) => (props.isOverviewPage ? "" : "24px !important")};
`;

type StyledTdRigthProps = {
  isOverviewPage?: boolean;
};
const StyledTdRight = styled.td<StyledTdRigthProps>`
  padding-right: ${(props) => (props.isOverviewPage ? "" : "24px !important")};
`;

const getEnabledActions = (scopeKey: string) => {
  switch (scopeKey) {
    case "Read":
      return ["ENABLED_ACTION_READ"];
    case "Write":
      return ["ENABLED_ACTION_WRITE"];
    case "R+W":
      return ["ENABLED_ACTION_READ", "ENABLED_ACTION_WRITE"];
    case "Disable":
    default:
      return [];
  }
};
const SyncHistoryExpandableRowWithFieldScopes = ({
  syncStatus,
  linkedAccount,
  selectiveSyncConditions,
  schemaMap,
  scopes,
  orgLevelScopes,
  modelSchema,
  commonModelName,
  updateEnabledStatus,
}: {
  syncStatus: CommonModelSyncStatus;
  linkedAccount: LinkedAccount | null;
  selectiveSyncConditions: LinkedAccountSelectiveSyncCondition[];
  schemaMap: { [schemaID: string]: SelectiveSyncConditionSchema };
  scopes: CommonModelScopeMap;
  orgLevelScopes: CommonModelScopeMap;
  modelSchema: Schema;
  commonModelName: string;
  updateEnabledStatus: (
    modelName: string,
    fieldName: string | null,
    enabledActions: string[],
    onFetch: () => void,
  ) => void;
}) => {
  const { user } = useAppContext();
  const currentEventKey = useContext(AccordionContext);

  const doesModelHaveConditionSchema = useMemo(() => {
    for (const schema of Object.values(schemaMap)) {
      if (schema.common_model === syncStatus.model_name) {
        return true;
      }
    }
    return false;
  }, [syncStatus, schemaMap]);
  const changeState = (modelName: string, fieldName: string | null, enabledActions: string[]) => {
    const onFetch = () => {
      showSuccessToast("Successfully updated scope");
    };
    updateEnabledStatus(modelName, fieldName, enabledActions, onFetch);
  };

  const clickHandler = (scopeKey: string) => {
    const enabledActions = getEnabledActions(scopeKey);
    changeState(commonModelName, null, enabledActions);
  };

  const scopeDropDownMenuOptions = (
    <>
      <StyledDropdownItem
        eventKey={ScopeStatuses.READ}
        onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
          e.stopPropagation();
          clickHandler(ScopeStatuses.READ);
        }}
      >
        {ScopeStatuses.READ}
      </StyledDropdownItem>
      {scopes.capabilities.includes("ENABLED_ACTION_WRITE") && (
        <>
          <StyledDropdownItem
            eventKey="R+W"
            onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              e.stopPropagation();
              clickHandler("R+W");
            }}
          >
            {ScopeStatuses.READ_WRITE}
          </StyledDropdownItem>
          <StyledDropdownItem
            eventKey={ScopeStatuses.WRITE}
            onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
              e.stopPropagation();
              clickHandler(ScopeStatuses.WRITE);
            }}
          >
            {ScopeStatuses.WRITE}
          </StyledDropdownItem>
        </>
      )}
      <StyledDropdownItem
        eventKey="Disable"
        onClick={(e: any) => {
          e.stopPropagation();
          clickHandler("Disable");
        }}
      >
        Disable
      </StyledDropdownItem>
    </>
  );

  const isDisabled = !scopes.actions || scopes.actions.length == 0;
  const isOpen = currentEventKey == syncStatus.model_id;
  return (
    <>
      <ScopesFieldToggle isClickable={!isDisabled} eventKey={syncStatus.model_id}>
        <RowModelName isOpen={!isDisabled && isOpen}>
          <b>{syncStatus.model_name}</b>
        </RowModelName>
        <ClickableCell>
          <div className="d-flex align-items-center">
            <ScopesDropdown menuOptions={scopeDropDownMenuOptions}>
              <div className="d-flex align-items-center">
                <ScopeText className={`${isDisabled ? "text-gray-30" : ""}`}>
                  {getScopesText(scopes.actions)}
                </ScopeText>
                <ChevronDown size={12} />
              </div>
            </ScopesDropdown>
            {!isEqual(scopes.actions, orgLevelScopes.actions) && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="overridden-scope-tooltip">
                    Override of your organization's default setting
                  </Tooltip>
                }
              >
                <StyledUser size={14} />
              </OverlayTrigger>
            )}
          </div>
        </ClickableCell>
        <td>
          {getSyncStatusBadge(
            syncStatus.status,
            linkedAccount?.pause_reason,
            syncStatus.sync_reason,
          )}
        </td>
        <td>
          {syncStatus.last_sync_start ? (
            format(new Date(syncStatus.last_sync_start), "MMM dd, hh:mm a")
          ) : (
            <div className="text-sm font-medium text-gray-30">—</div>
          )}
        </td>
        <td>
          {!(linkedAccount?.is_test_account ?? false) && syncStatus.next_sync_start ? (
            format(new Date(syncStatus.next_sync_start), "MMM dd, hh:mm a")
          ) : (
            <div className="text-sm font-medium text-gray-30">—</div>
          )}
        </td>
        <TableIconColumn>
          <RotatingChevronRight isRotatedDown={isOpen} />
        </TableIconColumn>
      </ScopesFieldToggle>
      {/* dont render scopes table if model is disabled */}
      {!isDisabled && (
        <NoHoverRow colSpan={6}>
          <Accordion.Collapse eventKey={syncStatus.model_id}>
            <ScopesTable
              key={commonModelName}
              orgLevelScopes={orgLevelScopes}
              linkedAccountScopes={scopes}
              modelSchema={modelSchema}
              commonModelName={commonModelName}
              changeState={changeState}
            />
          </Accordion.Collapse>
        </NoHoverRow>
      )}
      {isFeatureEnabled("is_selective_sync_enabled", user) && doesModelHaveConditionSchema && (
        <tr className="deprecated-mt-1">
          <NoHoverRow colSpan={6}>
            <Accordion.Collapse eventKey={syncStatus.model_id}>
              <SelectiveSyncRowContainer
                linkedAccount={linkedAccount}
                commonModel={syncStatus.model_name}
                selectiveSyncConditions={selectiveSyncConditions}
                schemaMap={schemaMap}
              />
            </Accordion.Collapse>
          </NoHoverRow>
        </tr>
      )}
    </>
  );
};

type Props = Pick<SyncHistoryProps, "linkedAccount"> & {
  /**
   * Data for the table - array of sync statuses, either fetched and
   * empty or full, or unfetched and represented by `null`.
   */
  syncStatuses: CommonModelSyncStatus[] | null;
  ignoreDemoData: boolean;
  resyncButton?: any;
  isOverviewPage?: boolean;
  includeLinkedAccountScopes?: boolean;
  schemas?: Schemas | null;
};
const SYNC_STATUS_OVERVIEW_PAGE_PRIORITY_MAP: Record<string, number> = {
  [CommonModelSyncStatusType.FAILED]: 1,
  [CommonModelSyncStatusType.PARTIALLY_SYNCED]: 2,
  [CommonModelSyncStatusType.SYNCING]: 3,
  [CommonModelSyncStatusType.DONE]: 4,
  [CommonModelSyncStatusType.PAUSED]: 5,
  [CommonModelSyncStatusType.DISABLED]: 6,
};

/**
 * Shows a table of the syncs that have happened in the past - automatically polls for new
 * data once every three seconds to keep it up to date.
 */
const SyncHistoryTable = ({
  linkedAccount,
  syncStatuses,
  ignoreDemoData,
  resyncButton,
  isOverviewPage = false,
  includeLinkedAccountScopes = false,
  schemas = null,
}: Props) => {
  const isTestAccount = useMemo(() => linkedAccount?.is_test_account ?? false, [linkedAccount]);
  const isFirstSyncComplete = useMemo(() => linkedAccount?.completed_at ?? false, [linkedAccount]);
  const alphabetizedSyncStatuses = useMemo(() => {
    syncStatuses?.sort((a, b) => a.model_name.localeCompare(b.model_name));
    return syncStatuses;
  }, [syncStatuses]);

  const predicate = (a?: CommonModelSyncStatus, b?: CommonModelSyncStatus) => {
    if (a?.status && b?.status) {
      if (
        SYNC_STATUS_OVERVIEW_PAGE_PRIORITY_MAP[a.status] <
        SYNC_STATUS_OVERVIEW_PAGE_PRIORITY_MAP[b.status]
      ) {
        return -1;
      } else if (
        SYNC_STATUS_OVERVIEW_PAGE_PRIORITY_MAP[a.status] >
        SYNC_STATUS_OVERVIEW_PAGE_PRIORITY_MAP[b.status]
      ) {
        return 1;
      } else {
        return a.status.localeCompare(b.status);
      }
    }

    return 0;
  };

  const syncReasonSortedSyncStatuses = useMemo(() => {
    return syncStatuses?.slice(0).sort(predicate);
  }, [syncStatuses]);

  const [linkedAccountLevelCommonModelScopes, setLinkedAccountLevelCommonModelScopes] = useState<
    CateogryScopeMap | undefined
  >(undefined);

  const [orgLevelCommonModelScopes, setOrgLevelCommonModelScopes] = useState<
    CateogryScopeMap | undefined
  >(undefined);

  const [selectiveSyncConditionsMap, setSelectiveSyncConditionsMap] = useState<{
    [commonModel: string]: LinkedAccountSelectiveSyncCondition[];
  }>({});

  const [schemaMap, setSchemaMap] = useState<{ [schemaID: string]: SelectiveSyncConditionSchema }>(
    {},
  );
  const history = useHistory();

  useEffect(() => {
    if (linkedAccount) {
      // Get existing conditions
      fetchWithAuth({
        path: `integrations/selective-sync/configurations/linked-account/${linkedAccount.id}`,
        method: "GET",
        onResponse: (
          data: Array<{
            id: string;
            linked_account_conditions: LinkedAccountSelectiveSyncCondition[];
          }>,
        ) => {
          // API returns list of condition groups, which each are a list of conditions
          const mapObj: { [commonModel: string]: LinkedAccountSelectiveSyncCondition[] } = {};
          for (const conditionGroup of data) {
            for (const condition of conditionGroup.linked_account_conditions) {
              if (!(condition.common_model in mapObj)) {
                mapObj[condition.common_model] = [];
              }
              mapObj[condition.common_model].push(condition);
            }
          }
          setSelectiveSyncConditionsMap(mapObj);
        },
      });
      // Get schema meta
      fetchWithAuth({
        path: `integrations/selective-sync/meta/${linkedAccount.integration.slug}/${linkedAccount.category}`,
        method: "GET",
        onResponse: (data: { results: Array<SelectiveSyncConditionSchema> }) => {
          const results = data.results;
          setSchemaMap(Object.fromEntries(results.map((schema) => [schema.id, schema])));
        },
      });
    }
  }, [linkedAccount]);

  useEffect(() => {
    if (!linkedAccount) {
      return;
    }
    getLinkedAccountCommonModelTogglesMap(linkedAccount, setLinkedAccountLevelCommonModelScopes);
  }, [linkedAccount]);

  useEffect(() => {
    if (!linkedAccount) {
      return;
    }
    getOrgCommonModelTogglesMap(linkedAccount.category, setOrgLevelCommonModelScopes);
  }, [linkedAccount]);

  const updateEnabledStatus = (
    modelName: string,
    fieldName: string | null,
    enabledActions: string[],
    onFetch: () => void,
  ) => {
    if (!linkedAccount) {
      return;
    }
    const updatedScope = {
      enabled_actions: enabledActions,
      ...(fieldName !== null && { field: fieldName }),
      name: modelName,
    };
    fetchWithAuth({
      path: `integrations/common-model-toggles/${linkedAccount.category}?linked_account_id=${linkedAccount.id}`,
      method: "PATCH",
      body: updatedScope,
      onResponse: (data: CommonModelToggle[]) => {
        setLinkedAccountLevelCommonModelScopes(createScopeMap(data));
        onFetch();
      },
    });
  };

  const syncStatusRows = includeLinkedAccountScopes
    ? linkedAccountLevelCommonModelScopes &&
      orgLevelCommonModelScopes &&
      schemas &&
      alphabetizedSyncStatuses?.map((syncStatus) => (
        <SyncHistoryExpandableRowWithFieldScopes
          key={syncStatus.model_id}
          syncStatus={syncStatus}
          linkedAccount={linkedAccount}
          selectiveSyncConditions={selectiveSyncConditionsMap[syncStatus.model_name]}
          schemaMap={schemaMap}
          scopes={linkedAccountLevelCommonModelScopes![syncStatus.model_name] || []}
          orgLevelScopes={orgLevelCommonModelScopes[syncStatus.model_name]}
          modelSchema={schemas[syncStatus.model_name]}
          commonModelName={syncStatus.model_name}
          updateEnabledStatus={updateEnabledStatus}
        />
      ))
    : linkedAccountLevelCommonModelScopes &&
      (isOverviewPage
        ? syncReasonSortedSyncStatuses
            ?.slice(0, 8)
            ?.map((syncStatus) => (
              <SyncHistoryExpandableRow
                key={syncStatus.model_id}
                syncStatus={syncStatus}
                linkedAccount={linkedAccount}
                selectiveSyncConditions={selectiveSyncConditionsMap[syncStatus.model_name]}
                schemaMap={schemaMap}
                scopes={linkedAccountLevelCommonModelScopes[syncStatus.model_name] || []}
                isOverviewPage={isOverviewPage}
              />
            ))
        : alphabetizedSyncStatuses?.map((syncStatus) => (
            <SyncHistoryExpandableRow
              key={syncStatus.model_id}
              syncStatus={syncStatus}
              linkedAccount={linkedAccount}
              selectiveSyncConditions={selectiveSyncConditionsMap[syncStatus.model_name]}
              schemaMap={schemaMap}
              scopes={linkedAccountLevelCommonModelScopes[syncStatus.model_name] || []}
              isOverviewPage={isOverviewPage}
            />
          )));

  const header = (
    <>
      <StyledTh isOverviewPage={isOverviewPage} scope="col">
        Model
      </StyledTh>
      <th scope="col">Scope</th>
      <th scope="col">Status</th>
      <th scope="col" colSpan={isTestAccount ? 2 : 1}>
        Last Sync Start
      </th>
      {!isTestAccount && (
        <th scope="col">
          Next Sync Start{" "}
          <InfoIconWithTooltip
            text="This is an approximation. Many factors affect the next start time."
            iconStyle={{ paddingBottom: 2 }}
          />
        </th>
      )}
      <StyledThRight scope="col"></StyledThRight>
    </>
  );

  const content = (
    <>
      {linkedAccount && !isFirstSyncComplete ? (
        <tr>
          <td colSpan={12} className="p-0">
            <EmptyStateWrapper isTable title="Account not yet linked" />
          </td>
        </tr>
      ) : syncStatuses === null ? (
        <TableSkeletonLoader cols={5} />
      ) : (
        (!!syncStatuses.length && syncStatusRows) || (
          <tr>
            <td colSpan={12} className="p-0">
              <EmptyStateWrapper isTable title="No sync events" />
            </td>
          </tr>
        )
      )}
    </>
  );

  return (
    <Stack $isVertical>
      <TableWrapper className="d-flex flex-column g-2 mb-9" isOverviewPage={isOverviewPage}>
        {isOverviewPage ? (
          <TitleBorder>
            <TableTitle className="d-flex justify-content-between">
              <ClickableContainer
                onClick={() => {
                  if (linkedAccount) {
                    navigateToLinkedAccountDetailDataPage(history, linkedAccount.id);
                  }
                }}
              >
                <div className="d-flex align-items-center flex-grow-1">
                  Data Sync
                  <StyledChevronRight size={16} />
                </div>
              </ClickableContainer>

              <div>{resyncButton}</div>
            </TableTitle>
          </TitleBorder>
        ) : (
          <></>
        )}
        {!ignoreDemoData && <DemoDataAlert />}
        <Accordion>
          <MergeTable
            header={header}
            content={content}
            isOverviewPage={isOverviewPage}
            hasMarginBottom={false}
          />
        </Accordion>
        {isOverviewPage && (isFirstSyncComplete || syncStatusRows) && (
          <ClickableContainer
            onClick={() => {
              if (linkedAccount) {
                navigateToLinkedAccountDetailDataPage(history, linkedAccount.id);
              }
            }}
          >
            <ViewMoreContainer className="d-flex justify-content-center align-items-center ">
              View more
              <StyledChevronRight size={14} className="mt-0"></StyledChevronRight>
            </ViewMoreContainer>
          </ClickableContainer>
        )}
      </TableWrapper>
    </Stack>
  );
};

export default SyncHistoryTable;
