import React, { useState } from "react";

import MergeModal from "../../../shared-components/MergeModal";
import { PaymentMethod, isCreditCard } from "../BillingModels";
import { toTitleCase } from "../BillingUtils";
import ModalButton from "../../../integrations-management/logs/modals/ModalButton";
import SpinnerButton from "../../../shared-components/SpinnerButton";
import MergeText from "../../../shared-components/MergeText";
import { deletePaymentMethod } from "../BillingAPIClient";
import { showErrorToast, showSuccessToast } from "../../../shared-components/Toasts";

type Props = {
  paymentMethod?: PaymentMethod;
  onHide: () => void;
};

export default function DeletePaymentMethodModal({ paymentMethod, onHide }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  if (!paymentMethod) {
    return null;
  }

  return (
    <MergeModal
      title="Delete Payment Method"
      show={paymentMethod !== undefined}
      onHide={onHide}
      size="sm"
    >
      <div className="deprecated-mb-2">
        <MergeText>
          This will remove your{" "}
          <MergeText isBold>
            {isCreditCard(paymentMethod) ? toTitleCase(paymentMethod.brand) : "Account"} ending in{" "}
            {paymentMethod.last4}.
          </MergeText>
        </MergeText>
      </div>
      <div className="deprecated-mb-4">
        <MergeText>Do you wish to continue?</MergeText>
      </div>

      <SpinnerButton
        className="btn-danger btn-block"
        text="Delete payment method"
        isLoading={isLoading}
        onClick={() => {
          setIsLoading(true);
          deletePaymentMethod({
            paymentMethod: paymentMethod,
            onSuccess: () => {
              setIsLoading(false);
              showSuccessToast("Successfully deleted payment method!");
              onHide();
            },
            onError: () => {
              setIsLoading(false);
              showErrorToast(
                "Something went wrong deleting your payment method. Please try again later or contact support.",
              );
              onHide();
            },
          });
        }}
      />
      <ModalButton className="deprecated-mt-2" $hasNoBorder variant="white" onClick={onHide}>
        Cancel
      </ModalButton>
    </MergeModal>
  );
}
