import React from "react";
export interface LinkedAccountFieldMappingsContextState {
  refreshFieldMappings: () => void;
}

const LinkedAccountFieldMappingsContext =
  React.createContext<LinkedAccountFieldMappingsContextState>({
    refreshFieldMappings: () => {},
  });

export default LinkedAccountFieldMappingsContext;
